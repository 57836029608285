import React, { useContext } from 'react';

import WidgetContainer from '../components/WidgetContainer';
import { MyBookingsContext } from '../context-providers';
import { useAPI } from '../../../api/api';
import LoadingOverlay from '../components/LoadingOverlay';
import ErrorOverlay from '../components/ErrorOverlay';

interface WelcomeBackProps {
  first_name: string;
  last_name: string;
  destination: {
    id: string;
    name: string;
  };
  id_photo_url: string;
}

const WelcomeBack: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const bookingId = activeBooking?.id;
  const { data, isValidating, error } = useAPI<WelcomeBackProps>(
    bookingId
      ? `/api/my-trip/alumni/${bookingId}/alumni-name-destination-and-id-photo/`
      : null
  );

  return (
    <WidgetContainer className="space-y-6" title="Welcome back">
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      {data && (
        <div className="flex flex-row gap-4 text-gray-500">
          <div className="w-1/4">
            <img
              src={data.id_photo_url || '/static/unknown_N.png'}
              alt={`ID photo of ${data.first_name}`}
              className="rounded-md"
            />
          </div>
          <div className="w-3/4 space-y-6">
            <p>Dear {`${data.first_name} ${data.last_name}`},</p>
            <p>
              Firstly, welcome back. I hope you had a great experience in {''}
              {data.destination.name} and love the country as much as we all do
              here at Work the World. I really appreciate you filling in the
              post placement survey. Please rest assured that the feedback you
              have given will be taken on board - we are constantly improving
              the service we offer and the only way we can do so is by hearing
              directly from the participants on the programme.
            </p>
            <p>Thank you again and I wish you all the best for the future.</p>
            <p>Tim Milnes</p>
            <p>Operations Manager - {data.destination.name}</p>
          </div>
        </div>
      )}
    </WidgetContainer>
  );
};

export default WelcomeBack;
