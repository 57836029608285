import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Spinner from '../spinner';

interface Props {
  name: string;
  title: string;
  checked?: boolean;
  description: string;
  onClick: () => Promise<void>;
  className?: string;
}

const InstantSaveCheckbox: React.FC<Props> = ({
  name,
  title,
  description,
  onClick,
  checked = false,
  className,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const toggleChecked = () => {
    setIsChecked(!isChecked);
  };

  const handleClick = async () => {
    setIsLoading(true);

    const initialCheckedState = isChecked;

    onClick()
      .then(() => toggleChecked())
      .catch(() => setIsChecked(initialCheckedState))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className={classNames(
        'flex space-x-3',
        { 'items-start': !isLoading },
        { 'items-start': isLoading },
        className
      )}
    >
      <div>
        {isLoading ? (
          <Spinner className="w-4 h-4 mt-1.5" />
        ) : (
          <input
            id={name}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-indigo-600"
            onChange={handleClick}
            checked={isChecked}
          />
        )}
      </div>
      <div className="flex flex-col">
        <label className="text-xs-bold -mb-1.5" htmlFor={name}>
          {title}
        </label>
        <p className="text-xs text-gray-500">{description}</p>
      </div>
    </div>
  );
};

export default InstantSaveCheckbox;
