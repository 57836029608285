import React from 'react';

import AdminWidgetContainer from '../../../../../framework-components/card/AdminWidgetContainer';
import ManageSingleObject from '../../../../manage-single-object/ManageSingleObject';
import { AccountCodes as AccountCodesType } from './types';
import { updateAccountCodes } from './actions';
import { useAPI } from '../../../../../api/api';
import LoadingOverlay from '../../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../../common/error/ErrorOverlay';
import { Option } from '../../../../../types';

interface Props {
  destinationId: string;
}

const AccountCodes: React.FC<Props> = ({ destinationId }) => {
  const {
    data: accountCodeOptions,
    isValidating,
    error,
  } = useAPI<Option[]>(
    `/api/admin/destination/destination-profile/xero-account-codes/options/`
  );
  return (
    <AdminWidgetContainer title="Account Codes">
      {error && !isValidating && <ErrorOverlay />}
      {isValidating && <LoadingOverlay />}
      {accountCodeOptions && (
        <ManageSingleObject<AccountCodesType>
          getUrl={`/api/admin/destination/destination-profile/${destinationId}/xero-account-codes/get/`}
          submitItem={(values) => updateAccountCodes(destinationId, values)}
          descriptionFields={[
            {
              label: 'Reg fee',
              render: (data) => data.xero_registration_fee_code?.label || '',
            },
            {
              label: 'Project fee',
              render: (data) => data.xero_project_fee_code?.label || '',
            },
            {
              label: 'Admin fee',
              render: (data) => data.xero_admin_fee_code?.label || '',
            },
            {
              label: 'Sales',
              render: (data) => data.xero_sales_fee_code?.label || '',
            },
          ]}
          formFields={[
            {
              type: 'local-dropdown',
              name: 'xero_registration_fee_code',
              label: 'Reg fee',
              options: accountCodeOptions,
            },
            {
              type: 'local-dropdown',
              name: 'xero_project_fee_code',
              label: 'Project fee',
              options: accountCodeOptions,
            },
            {
              type: 'local-dropdown',
              name: 'xero_admin_fee_code',
              label: 'Admin fee',
              options: accountCodeOptions,
            },
            {
              type: 'local-dropdown',
              name: 'xero_sales_fee_code',
              label: 'Sales',
              options: accountCodeOptions,
            },
          ]}
          buttonLabel="Update"
          insideCard={false}
        />
      )}
    </AdminWidgetContainer>
  );
};

export default AccountCodes;
