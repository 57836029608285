import React from 'react';
import { useFormikContext } from 'formik';

import { FormValues } from './types';
import TextArea from '../../../framework-components/textarea/TextArea';

const OtherDetails: React.FC = () => {
  const formik = useFormikContext<FormValues>();
  return (
    <div className="space-y-8" id="other">
      <h2 className="text-mob-h3 xs:text-h3">Other details</h2>
      <TextArea
        label="Please add anything else you wish to mention here"
        name="notes"
        id="notes"
        onChange={formik.handleChange}
        placeholder="Enter details"
        value={formik.values.notes}
        autoGrow
      />
    </div>
  );
};

export default OtherDetails;
