import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';

import Modal from '../../../modal';
import QuickForm from '../../../../framework-components/quickform/QuickForm';
import { apiRequest } from '../../../../api/api';
import { CurrencyType } from '../../../booking/booking-form/types';
import { CURRENCY_TO_SYMBOL } from '../../../booking/booking-form/constants';
import { Option } from '../../../../types';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  bookingId: string;
  reloadData: () => void;
  currency: CurrencyType;
}

interface FormValues {
  description: string;
  amount: number | '';
  payment_date: Date | null;
  credit_note: Option | null;
  account: Option | null;
}

const RecordRefundModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  bookingId,
  reloadData,
  currency,
}) => {
  const focusRef = React.useRef(null);
  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 flex flex-col ml-4 mr-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Record BACS refund
        </Dialog.Title>
        <QuickForm
          initialValues={{
            description: '',
            amount: '',
            payment_date: null,
            credit_note: null,
            account: null,
          }}
          fields={[
            {
              type: 'datetime',
              name: 'payment_date',
              label: 'Payment Date',
            },
            {
              type: 'smalltext',
              name: 'amount',
              label: `Amount(${CURRENCY_TO_SYMBOL[currency]})`,
            },
            {
              type: 'smalltext',
              name: 'description',
              label: 'Description',
            },
            {
              type: 'remote-dropdown',
              name: 'credit_note',
              label: 'Credit Note',
              path: `/api/admin/bookings/${bookingId}/get-credit-note-options/`,
            },
            {
              type: 'remote-dropdown',
              name: 'account',
              label: 'Account',
              path: `/api/admin/bookings/get-xero-accounts/`,
            },
          ]}
          onSubmit={(data: FormValues) => {
            return apiRequest<FormValues>(
              `/api/admin/bookings/${bookingId}/record-bacs-refund/`,
              {
                method: 'POST',
                body: JSON.stringify({
                  ...data,
                  credit_note: data.credit_note?.value,
                  account: data.account?.value,
                }),
              }
            );
          }}
          onSubmitSuccess={() => {
            toast.success('Successfully recorded refund');
            closeModal();
            reloadData();
          }}
          buttonLabel="Submit"
        />
      </div>
    </Modal>
  );
};

export default RecordRefundModal;
