import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import AdminStandardPage from '../../common/AdminStandardPage';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import { apiRequest, useAPI } from '../../../../api/api';
import { EnquiryListingCountData } from '../../dashboard/types';
import { EnquiryTableData } from './types';
import Spinner from '../../../spinner';
import Table from '../../../../framework-components/table/Table';
import {
  CombinedFilters,
  FilterOption,
  TableData,
} from '../../../../framework-components/table/types';
import QuickSetEnquiryPriority from '../common/QuickSetEnquiryPriority';
import { formatDateToReadableStringShorthandMonth } from '../../../../utils/dates';
import { getHighlightRowClass } from './utils';
import Tooltip from '../../../../framework-components/tooltip/Tooltip';
import { truncate } from '../../../../utils/text';

const DashboardNewEnquiryPage: React.FC = () => {
  const { data, error, isValidating } = useAPI<EnquiryListingCountData>(
    '/api/admin/enquiries/count/'
  );

  const getFilterOptions = useCallback(
    (
      selectedFilters: CombinedFilters
    ): Promise<Record<string, FilterOption[]>> => {
      return fetch(
        '/api/admin/enquiries/enquiries-table-filters/?' +
          new URLSearchParams({
            ...selectedFilters,
            type: 'new',
          }).toString()
      ).then((response) => response.json());
    },
    []
  );

  const getDataPromise = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<TableData<EnquiryTableData>>(
      '/api/admin/enquiries/?' +
        new URLSearchParams({
          ...selectedFilters,
          type: 'new',
        }).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const renderTabs = useCallback(() => {
    if (isValidating) {
      return <Spinner className="h-6 w-6" />;
    }

    if (error || !data) {
      return (
        <div className="p-4 text-center">
          There was an error getting your order
        </div>
      );
    }

    return (
      <BlockTabs
        tabs={[
          {
            label: `New (${data.new})`,
            href: '/admin/enquiries/new',
            current: true,
          },
          {
            label: `Ongoing (${data.ongoing})`,
            href: '/admin/enquiries/ongoing',
          },
          {
            label: `Archived (${data.archived})`,
            href: '/admin/enquiries/archived',
          },
        ]}
        variant="pilled_tab"
      />
    );
  }, [isValidating, error, data]);

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Enquiries',
          href: '/admin/enquiries/new',
        },
        {
          label: 'New enquiries',
          href: null,
        },
      ]}
      title={'New enquiries'}
      enableHome={false}
    >
      <div className="w-full">{renderTabs()}</div>

      <Table<EnquiryTableData>
        columnsConfig={{
          name: {
            key: 'name',
            header: 'Name',
            enableSort: true,
            renderCell: (enquiry) => {
              if (enquiry.id) {
                return (
                  <a
                    href={`/admin/enquiries/${enquiry.id}/enquiry-profile/`}
                    title={enquiry.name}
                  >
                    {enquiry.name}
                  </a>
                );
              }

              return enquiry.name;
            },
          },
          created: {
            key: 'created',
            header: 'Date',
            enableSort: true,
            filterType: 'calendar',
            renderCell: (enquiry) =>
              formatDateToReadableStringShorthandMonth(enquiry.created),
          },
          university: {
            key: 'university',
            header: 'University',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (enquiry) => {
              return enquiry.university ? (
                <a
                  href={`/admin/directories/universities/${enquiry.university.id}/`}
                  title={enquiry.university.name}
                >
                  {enquiry.university.name.length > 25
                    ? truncate(enquiry.university.name, 25)
                    : enquiry.university.name}
                </a>
              ) : (
                ''
              );
            },
          },
          university_course: {
            key: 'university_course',
            header: 'Course title',
            enableSort: true,
            filterType: 'local-dropdown',
          },
          destination: {
            key: 'destination',
            header: 'Destination',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (enquiry) => enquiry.destination?.name,
          },
          source: {
            key: 'source',
            header: 'Source',
            enableSort: true,
            filterType: 'local-dropdown',
          },
          assignee: {
            key: 'assignee',
            header: 'Assignee',
            enableSort: true,
            filterType: 'local-dropdown',
          },
          start_date: {
            key: 'start_date',
            header: 'Travel date',
            additionalText: "The date you're looking to travel on.",
            enableSort: true,
            filterType: 'calendar',
            renderCell: (enquiry) =>
              enquiry.start_date
                ? formatDateToReadableStringShorthandMonth(enquiry.start_date)
                : '',
          },
          priority: {
            key: 'priority',
            header: 'Priority',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (row: EnquiryTableData) => (
              <QuickSetEnquiryPriority
                enquiryId={row.id}
                initialValue={row.priority || undefined}
              />
            ),
          },
          capacity_details: {
            key: 'capacity_details',
            header: 'Capacity Details',
            renderCell: (enquiry) => {
              const tooltipId = `my-tooltip-${enquiry.id}`;
              return (
                <div
                  className="flex justify-center"
                  data-tooltip-id={tooltipId}
                  data-tooltip-content={enquiry.capacity_details}
                >
                  <FontAwesomeIcon
                    icon={faHouse}
                    className={classNames({
                      'text-red-400':
                        enquiry.capacity_conflict_status === 'ERROR',
                      'text-amber-500':
                        enquiry.capacity_conflict_status === 'WARN',
                    })}
                  />
                  <Tooltip id={tooltipId} />
                </div>
              );
            },
          },
        }}
        getFilterOptions={getFilterOptions}
        getData={getDataPromise}
        enablePagination
        enableSearch
        additionalFiltersConfig={{
          show_clashes: {
            key: 'show_clashes',
            label: 'Show clashes',
            filterType: 'toggle',
          },
        }}
        getHighlightRowClass={getHighlightRowClass}
      />
    </AdminStandardPage>
  );
};

export default DashboardNewEnquiryPage;
