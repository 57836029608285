import React from 'react';
import { useSearchParams } from 'react-router-dom';

import AdminStandardPage from '../../common/AdminStandardPage';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import HouseVillageCapacityCalendar from './HouseVillageCapacityCalendar';
import { useDestinationTabs } from '../../common/hooks';

const HouseAndVillageCapacities: React.FC = () => {
  const [searchParams] = useSearchParams();
  const currentDate = new Date();

  const day = searchParams.has('day')
    ? searchParams.get('day')
    : currentDate.getDate().toString();

  const month = searchParams.has('month')
    ? searchParams.get('month')
    : currentDate.getMonth().toString();

  const year = searchParams.has('year')
    ? searchParams.get('year')
    : currentDate.getFullYear().toString();

  const period = searchParams.get('period') ?? '31';

  const { destination, destinationTabs, isValidating, error } =
    useDestinationTabs();

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Capacities',
          href: null,
        },
        {
          label: 'House & Village Capacities',
          href: null,
        },
      ]}
      title="House & Village Capacities"
      enableHome={false}
      isLoading={isValidating}
      error={error && 'Failed to load destinations'}
    >
      <BlockTabs
        tabs={destinationTabs}
        variant="pilled_tab_with_border_bottom"
      />
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: 'House/Village',
              href: `/admin/capacities/house-village-capacities?destination=${destination?.value}&year=${year}&month=${month}&day=${day}&period=${period}`,
              current: true,
            },
            {
              label: 'Room',
              href: `/admin/capacities/room-capacities?destination=${destination?.value}&year=${year}&month=${month}&day=${day}&period=${period}`,
              current: false,
            },
            {
              label: 'Department',
              href: `/admin/capacities/department-capacities?destination=${destination?.value}&year=${year}&month=${month}&day=${day}&period=${period}`,
              current: false,
            },
          ]}
          variant="pilled_secondary_action_tab"
        />
      </div>
      <HouseVillageCapacityCalendar
        dayQueryParam={searchParams.get('day')}
        monthQueryParam={searchParams.get('month')}
        yearQueryParam={searchParams.get('year')}
        period={period}
        destination={destination}
      />
    </AdminStandardPage>
  );
};

export default HouseAndVillageCapacities;
