import React, { useState } from 'react';

import AdminStandardPage from '../common/AdminStandardPage';
import AdminInfoPackSection from './AdminInfoPackSection';
import BlockTabs from '../../../framework-components/blocktabs/BlockTabs';
import { useAPI } from '../../../api/api';
import { useDestinationTabs } from '../common/hooks';
import AdminEditInfoPackArticle from './AdminEditInfoPackArticle';
import { Article, InfoPackSection } from './types';
import AdminWidgetContainer from '../../../framework-components/card/AdminWidgetContainer';

type InfoPackSections = InfoPackSection[] | undefined;

const InformationPackAdmin: React.FC = () => {
  const [editingArticle, setEditingArticle] = useState<Article | null>(null);

  const {
    destination,
    destinationTabs,
    isValidating: isLoadingDestinations,
    error,
  } = useDestinationTabs();

  const { data, isValidating: isLoadingInfoPack } = useAPI<InfoPackSections>(
    destination ? `/api/admin/info-pack/list/${destination?.value}/` : null
  );

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Documents',
          href: null,
        },
        {
          label: 'Information Pack',
          href: null,
        },
      ]}
      title="Information Pack"
      enableHome={false}
      isLoading={isLoadingDestinations || isLoadingInfoPack}
      error={error ? 'Failed to load page data' : undefined}
    >
      <BlockTabs
        tabs={[
          {
            label: 'Documents',
            href: '/admin/directories/documents',
            current: false,
          },
          {
            label: 'Information Pack',
            href: '/admin/directories/info-pack',
            current: true,
          },
          {
            label: 'Templated Resources',
            href: '/admin/directories/templated-resources',
            current: false,
          },
        ]}
        variant="pilled_tab"
      />
      <div>
        {destination && (
          <>
            {editingArticle ? (
              <AdminEditInfoPackArticle
                article={editingArticle}
                setEditingArticle={setEditingArticle}
                destinationId={destination?.value}
              />
            ) : (
              <div>
                <div className="mt-4">
                  <div className="mt-4">
                    <BlockTabs
                      tabs={destinationTabs}
                      variant="pilled_tab_with_border_bottom"
                    />
                  </div>
                </div>
                <AdminWidgetContainer>
                  <div className=" px-4 mt-6 mb-4">
                    <p>
                      Create new sub-sections, or edit existing ones, to update
                      the Student Information Pack.
                    </p>
                  </div>
                  {data &&
                    data.map((item, index) => {
                      return (
                        <div key={index} className="space-y-4 mb-4">
                          <AdminInfoPackSection
                            sectionId={item.section.id}
                            title={item.section.title}
                            articles={item.articles}
                            destinationId={destination.value}
                            setEditingArticle={setEditingArticle}
                          />
                        </div>
                      );
                    })}
                </AdminWidgetContainer>
              </div>
            )}
          </>
        )}
      </div>
    </AdminStandardPage>
  );
};

export default InformationPackAdmin;
