import React, { useContext } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import LoadingOverlay from '../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../components/ErrorOverlay';
import { MyBookingsContext } from '../context-providers';
import useModalState from '../../../hooks/modals';
import ContactOpsRepModal from './ContactOpsRepModal';

export interface MyTripStandardPageProps {
  title: string;
  isLoading?: boolean;
  error?: string;
  showDashLink?: boolean;
}

const MyTripStandardPage: React.FC<
  React.PropsWithChildren<MyTripStandardPageProps>
> = (props: React.PropsWithChildren<MyTripStandardPageProps>) => {
  const { activeBooking } = useContext(MyBookingsContext);
  const { isOpen, openModal, closeModal } = useModalState();

  const operations_manager = activeBooking?.destination.operations_manager;

  return (
    <>
      {props.isLoading || props.error ? (
        <>
          {props.isLoading && <LoadingOverlay />}
          {props.error && !props.isLoading && (
            <ErrorOverlay detail={props.error} />
          )}
        </>
      ) : (
        <div className="w-full min-w-0">
          <div className="flex justify-between gap-4 flex-wrap mb-8">
            <div className="flex flex-col self-end justify-between space-y-2">
              {props.showDashLink && (
                <Link to="/my-trip">
                  <div className="inline-flex items-center gap-1 cursor-pointer">
                    <ChevronLeftIcon className="h-5 w-5" />
                    <p className="text-sm">Back to dash</p>
                  </div>
                </Link>
              )}
            </div>
            <div className="flex flex-col-reverse md:flex-row justify-between md:items-center w-full">
              <h1 className="text-mob-h4 md:text-h2">{props.title}</h1>
              <div className="flex flex-col md:text-right space-y-2">
                {activeBooking && (
                  <div className="text-mob-sh3 md:text-sh3 pb-8 md:pb-0">
                    <p>My trip to {activeBooking.destination.name}</p>
                    <p className="text-mob-sh4">
                      {format(activeBooking.start_date, 'dd/MM/yyyy')} -{' '}
                      {format(activeBooking.end_date, 'dd/MM/yyyy')}
                    </p>
                    {operations_manager && (
                      <button
                        className="text-wtw-logo-blue hover:text-wtw-dark-blue text-md"
                        onClick={() => openModal()}
                      >
                        Contact Ops Representative
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {props.children}
          <ContactOpsRepModal
            isOpen={isOpen}
            closeModal={closeModal}
            title={operations_manager?.title || ''}
            firstName={operations_manager?.first_name || ''}
            lastName={operations_manager?.last_name || ''}
            position={operations_manager?.position || ''}
            email={operations_manager?.email || ''}
            telephone={operations_manager?.telephone || ''}
          />
        </div>
      )}
    </>
  );
};

export default MyTripStandardPage;
