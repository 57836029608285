import { apiRequest } from '../../../../api/api';
import { Image, EntityType } from './types';

export const createImage = async (
  entityType: EntityType,
  entityId: string,
  fileId: string
) => {
  const url = `/api/admin/photo-gallery/${entityType}/${entityId}/image/create/`;
  return apiRequest<Image>(url, {
    method: 'POST',
    body: JSON.stringify({ file: fileId }),
  });
};

export const getImages = async (entityType: EntityType, entityId: string) => {
  const url = `/api/admin/photo-gallery/${entityType}/${entityId}/image/list/`;
  return apiRequest<Image[]>(url, {
    method: 'GET',
  });
};

export const setMainImage = (
  entityType: EntityType,
  entityId: string,
  imageId: string
) => {
  const url = `/api/admin/photo-gallery/${entityType}/${entityId}/image/${imageId}/set-main/`;
  return apiRequest(url, {
    method: 'POST',
  });
};

export const deleteImage = (
  entityType: EntityType,
  entityId: string,
  imageId: string
) => {
  const url = `/api/admin/photo-gallery/${entityType}/${entityId}/image/${imageId}/delete/`;
  return apiRequest(url, {
    method: 'DELETE',
  });
};
