import React from 'react';

import Modal from '../../modal';
import { SecondaryButton } from '../components/Button';
import { useAPI } from '../../../api/api';
import ErrorOverlay from '../components/ErrorOverlay';
import LoadingOverlay from '../components/LoadingOverlay';

interface BacsDetails {
  bank: string;
  account_name: string;
  account_number: string;
  sort_code: string;
  reference: string;
  swift: string;
  iban: string;
  bank_address: string;
}

interface Props {
  bookingId: string;
  isOpen: boolean;
  closeModal: () => void;
}

const BacsModal: React.FC<Props> = ({ bookingId, isOpen, closeModal }) => {
  const focusRef = React.useRef(null);
  const { data, isValidating, error } = useAPI<BacsDetails>(
    `/api/my-trip/my-finances/${bookingId}/get-wtw-bacs-details/`
  );
  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="flex flex-col gap-4 w-full relative">
        <div className="p-6 max-w-3xl mx-auto space-y-4">
          <h2 className="text-lg font-semibold" ref={focusRef}>
            BACS payments / Wire transfers:
          </h2>
          {isValidating && <LoadingOverlay />}
          {error && !isValidating && <ErrorOverlay />}
          {data && (
            <div className="space-y-4">
              <div className="border rounded-lg overflow-hidden shadow-md">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr className="border-b">
                      <td className="p-2 font-semibold bg-gray-100">Bank:</td>
                      <td className="p-2">{data.bank}</td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2 font-semibold bg-gray-100">
                        Account Name:
                      </td>
                      <td className="p-2">{data.account_name}</td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2 font-semibold bg-gray-100">
                        Account Number:
                      </td>
                      <td className="p-2">{data.account_number}</td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2 font-semibold bg-gray-100">
                        Sort Code:
                      </td>
                      <td className="p-2">{data.sort_code}</td>
                    </tr>
                    <tr>
                      <td className="p-2 font-semibold bg-gray-100">
                        Reference:
                      </td>
                      <td className="p-2">{data.reference}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="italic text-sm-bold">
                If you are making this payment from an account held outside of
                the UK, you will also need the details below:
              </p>

              <div className="border rounded-lg overflow-hidden shadow-md">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr className="border-b">
                      <td className="p-2 font-semibold bg-gray-100">
                        Swift Code:
                      </td>
                      <td className="p-2">{data.swift}</td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2 font-semibold bg-gray-100">IBAN:</td>
                      <td className="p-2">{data.iban}</td>
                    </tr>
                    <tr>
                      <td className="p-2 font-semibold bg-gray-100">
                        Bank Address:
                      </td>
                      <td className="p-2">{data.bank_address}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="italic text-sm">
                Please ask your bank if there are charges for the transfer as
                you will be responsible for these charges and they must be added
                to the total amount sent.
              </p>
              <p className="italic text-sm">
                It is essential that bank transfers are made in the currency
                displayed in the table at the top of this page and into the
                above stated account. Your bank may impose fees or deductions
                for payments that are unnecessarily converted into GBP or for
                payments that are made to the wrong account. In such instances
                you will be liable to cover any shortfall in payments received
                by us. If in doubt we strongly advise that you contact your bank
                directly to ensure payment is made in the correct currency and
                to the correct account.
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <SecondaryButton label="Close" onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default BacsModal;
