import React from 'react';

import Anchor from '../../common/Anchor';
import { BasicBookingApplicantProfileInfo } from './types';
import { BOOKING_STATUS_LABELS } from '../../../../constants';

interface Props {
  data: BasicBookingApplicantProfileInfo;
}

const ApplicantStatus: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex gap-x-3 items-center">
      <div className="flex gap-x-2">
        <p>Status:</p>
        {data && data.status ? (
          <p className="text-md-bold">{BOOKING_STATUS_LABELS[data.status]}</p>
        ) : (
          '-'
        )}
      </div>

      {data.enquiry_set.length ? (
        <div className="flex gap-x-4">
          <Anchor
            href={
              data.enquiry_set[0]
                ? `/admin/enquiries/${data.enquiry_set[0].id}/enquiry-profile`
                : '#'
            }
          >
            <strong data-testid="enquiry-profile-link">
              See student enquiry
            </strong>
          </Anchor>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ApplicantStatus;
