import React, { useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { mutate } from 'swr';

import Modal from '../../modal';
import { setHasLoggedIn } from './actions';

interface Props {
  bookingId: string;
  isOpen: boolean;
  closeModal: () => void;
}

const WelcomeModal: React.FC<Props> = ({ isOpen, closeModal, bookingId }) => {
  useEffect(() => {
    const handleSetHasLoggedIn = async () => {
      await setHasLoggedIn(bookingId);
      mutate(
        `/api/my-trip/to-do/${bookingId}/show-welcome-modal/`,
        {
          show_welcome_modal: false,
        },
        false
      );
    };
    if (isOpen) {
      handleSetHasLoggedIn();
    }
  }, [isOpen, bookingId]);
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="space-y-4 relative">
        <Dialog.Title className="text-2xl font-bold">
          Welcome to the new MyTrip
        </Dialog.Title>
        <p>
          We have improved just about everything. And, if you’ve already started
          progressing through tasks on your timeline, you can pick up exactly
          where you left off, as everything has been carried over to the new
          planner.
        </p>
        <p>
          The new online placement planner makes accessing important information
          about your trip even easier.
        </p>
        <p>
          If you need help using MyTrip, please email{' '}
          <a className="text-blue-500" href="mailto:team@worktheworld.com">
            team@worktheworld.com
          </a>
          .
        </p>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
