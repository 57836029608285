import React from 'react';

import { useAPI } from '../../../../api/api';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import Table from '../../../manage-multiple-objects/Table';

interface Props {
  id: string;
  name: string;
}

interface Discipline {
  name: string;
  num_bookings: number;
}

interface BookingCounts {
  total_bookings: number;
  disciplines: Array<Discipline>;
}

const UniversityBookingTable: React.FC<Props> = ({ id, name }) => {
  const { data, isValidating, error } = useAPI<BookingCounts>(
    `/api/admin/university/${id}/get-confirmed-booking-counts/`
  );
  const filteredUni = JSON.stringify({
    university: { label: name, value: id },
  });
  const encodedUni = encodeURIComponent(filteredUni);

  return (
    <div className="relative min-h-[250px]">
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      <AdminWidgetContainer title="Bookings" hasBorder hasShadow>
        <div className="space-y-6">
          <p className="mt-6">This is all confirmed bookings.</p>
          <div className="flex items-center justify-between flex-wrap gap-4">
            <p>
              Total number of bookings:{' '}
              {data && <strong>{data.total_bookings}</strong>}
            </p>
            <a href={`/admin/bookings/confirmed?filters=${encodedUni}`}>
              <PrimaryButton label="View all bookings" />
            </a>
          </div>
          {data && (
            <Table
              data={data.disciplines}
              tableFields={[
                {
                  name: 'department',
                  header: 'Department',
                  render: (item: Discipline) => item['name'],
                },
                {
                  name: 'num_bookings',
                  header: 'Number of Bookings',
                  render: (item: Discipline) => item['num_bookings'],
                },
              ]}
            />
          )}
        </div>
      </AdminWidgetContainer>
    </div>
  );
};

export default UniversityBookingTable;
