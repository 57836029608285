import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import Modal from '../../modal';
import QuickForm from '../../../framework-components/quickform/QuickForm';
import { SET_STAFF_PERMISSIONS_OPTIONS } from './constants';
import { apiRequest } from '../../../api/api';
import { LabelValue } from '../../../types';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  reloadData: () => void;
}

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: LabelValue<string> | undefined;
}

const AddNewStaffMemberModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  reloadData,
}) => {
  const focusRef = React.useRef(null);
  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 flex flex-col ml-4 mr-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Add new staff member
        </Dialog.Title>
        <QuickForm
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            role: undefined,
          }}
          fields={[
            {
              type: 'smalltext',
              name: 'first_name',
              label: 'First name',
            },
            {
              type: 'smalltext',
              name: 'last_name',
              label: 'Last name',
            },
            {
              type: 'email',
              name: 'email',
              label: 'Email',
            },
            {
              type: 'password',
              name: 'password',
              label: `Password`,
            },
            {
              type: 'local-dropdown',
              options: SET_STAFF_PERMISSIONS_OPTIONS,
              name: 'role',
              label: 'Role',
            },
          ]}
          onSubmit={(data: FormValues) => {
            return apiRequest<FormValues>(`/api/admin/staff/add/`, {
              method: 'POST',
              body: JSON.stringify(data),
            });
          }}
          onSubmitSuccess={async () => {
            toast.success('Successfully created staff member');
            await mutate(`api/admin/staff/list/`);
            reloadData();
            closeModal();
          }}
          buttonLabel="Create staff member"
        />
      </div>
    </Modal>
  );
};

export default AddNewStaffMemberModal;
