import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import WidgetContainer from '../components/WidgetContainer';
import { apiRequest, useAPI } from '../../../api/api';
import LoadingOverlay from '../components/LoadingOverlay';
import ErrorOverlay from '../components/ErrorOverlay';
import { ClinicalExperienceData } from './types';
import QuickForm from '../../../framework-components/quickform/QuickForm';
import { FieldType } from '../../../framework-components/quickform/Field';
import { MyBookingsContext } from '../context-providers';

const fields: FieldType[] = [
  {
    type: 'largetext',
    name: 'clinical_experience',
    extraProps: {
      placeholder: 'Enter clinical experience',
      rows: 8,
      grayBorderless: true,
    },
  },
];

const ClinicalExperienceForm: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const bookingId = activeBooking ? activeBooking.id : null;
  const { data, isValidating, error } = useAPI<ClinicalExperienceData>(
    activeBooking ? `/api/my-trip/clinical-experience/${bookingId}/get/` : null
  );

  const updateClinicalExperience = (values: {
    clinical_experience: string;
  }): Promise<{ clinical_experience: string }> => {
    return apiRequest(`/api/my-trip/clinical-experience/${bookingId}/set/`, {
      method: 'POST',
      body: JSON.stringify(values),
    });
  };

  const renderContent = () => {
    if (isValidating) {
      return <LoadingOverlay />;
    }

    if (error) {
      return <ErrorOverlay />;
    }

    const initialValues = {
      clinical_experience: data?.clinical_experience ?? '',
    };

    return (
      <>
        {data && (
          <QuickForm
            fields={fields}
            onSubmit={updateClinicalExperience}
            initialValues={initialValues}
            buttonLabel="Save"
            hideButtonOnClean
            cleanOnSubmitSuccess
            onSubmitSuccess={() =>
              toast.success('Clinical experience updated successfully.')
            }
          />
        )}
        <p className="text-mob-sm sm:text-sm">
          Please note, students with limited clinical experience should include
          relevant modules or courses from your studies. This will demonstrate
          the theoretical knowledge you have gained as part of your course.
        </p>
      </>
    );
  };

  return (
    <WidgetContainer title="Clinical experience" className="space-y-6 px-7">
      {renderContent()}
    </WidgetContainer>
  );
};

export default ClinicalExperienceForm;
