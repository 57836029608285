import React, { useContext } from 'react';

import ManageSingleObject from '../components/ManageSingleObject/ManageSingleObject';
import {
  ContactDetails,
  Education,
  EmergencyContact,
  TShirtSizeData,
  DietaryRequirementsData,
  Notes,
} from './types';
import {
  updateDietaryRequirements,
  updateContactDetails,
  updateEducation,
  updateEmergencyContact,
  updateTShirtSize,
  updateNotes,
} from './actions';
import {
  getYearOfGraduationOptions,
  yearOfStudyOptions,
} from '../../booking/booking-form/constants';
import { DIETARY_REQUIREMENT_OPTIONS, TSHIRT_SIZE_OPTIONS } from './constants';
import MyPhotos from './MyPhotos';
import MyPassportScan from './MyPassportScan';
import ThreeColumns from '../../admin/common/layout/ThreeColumns';
import MyTripStandardPage from '../layout/MyTripStandardPage';
import ChangePassword from './ChangePassword';
import UploadDocuments from './UploadDocuments';
import { MyBookingsContext } from '../context-providers';
import Loading from '../components/Loading';

const yearOfGraduationOptions = getYearOfGraduationOptions();

const LeftHandSideColumn: React.FC<{ bookingId: string }> = ({ bookingId }) => {
  return (
    <>
      <ManageSingleObject<ContactDetails>
        title="My contact details"
        getUrl={`/api/my-trip/about-me/${bookingId}/contact-details/get/`}
        submitItem={(values) => updateContactDetails(bookingId, values)}
        formFields={[
          {
            type: 'smalltext',
            name: 'first_name',
            label: 'First name',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter First Name',
            },
          },
          {
            type: 'smalltext',
            name: 'last_name',
            label: 'Last name',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Last Name',
            },
          },
          {
            type: 'smalltext',
            name: 'address.line1',
            label: 'Address line 1',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Address Line 1',
            },
          },
          {
            type: 'smalltext',
            name: 'address.line2',
            label: 'Address line 2',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Address Line 2',
            },
          },
          {
            type: 'smalltext',
            name: 'address.city',
            label: 'City',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter City',
            },
          },
          {
            type: 'smalltext',
            name: 'address.county',
            label: 'County',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter County',
            },
          },
          {
            type: 'remote-dropdown',
            name: 'address.country',
            label: 'Country',
            path: '/api/booking/countries/',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Select Country',
            },
          },
          {
            type: 'smalltext',
            name: 'address.postcode',
            label: 'Postcode',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Postcode',
            },
          },
          {
            type: 'smalltext',
            name: 'email',
            label: 'Email',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Email',
            },
          },
          {
            type: 'smalltext',
            name: 'phone',
            label: 'Phone',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Phone',
            },
          },
          {
            type: 'smalltext',
            name: 'local_phone',
            label: 'Local phone',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Local Phone',
            },
          },
        ]}
        buttonLabel="Update"
      />
      <ManageSingleObject<Notes>
        title="Notes"
        getUrl={`/api/my-trip/about-me/${bookingId}/notes/get/`}
        submitItem={(values) => updateNotes(bookingId, values)}
        formFields={[
          {
            type: 'largetext',
            name: 'notes',
            extraProps: {
              rows: 5,
              placeholder: 'Enter notes here',
              grayBorderless: true,
            },
          },
        ]}
        buttonLabel="Update"
      />
    </>
  );
};

const MiddleColumn: React.FC<{ bookingId: string }> = ({ bookingId }) => {
  return (
    <>
      <ManageSingleObject<Education>
        title="Education"
        getUrl={`/api/my-trip/about-me/${bookingId}/education/get/`}
        submitItem={(values) => updateEducation(bookingId, values)}
        formFields={[
          {
            type: 'remote-dropdown',
            name: 'university',
            label: 'University',
            path: '/api/booking/universities/',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Select University',
            },
          },
          {
            type: 'smalltext',
            name: 'university_course',
            label: 'Course',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Course',
            },
          },
          {
            type: 'local-dropdown',
            name: 'year_of_university_study',
            label: 'Year of study',
            options: yearOfStudyOptions,
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Select Year of Study',
            },
          },
          {
            type: 'local-dropdown',
            name: 'year_of_graduation',
            label: 'Year of graduation',
            options: yearOfGraduationOptions,
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Select Year of Graduation',
            },
          },
        ]}
        conditionalFields={[
          {
            name: 'year_of_university_study',
            condition: (data) => {
              return Boolean(data.year_of_university_study);
            },
          },
          {
            name: 'year_of_graduation',
            condition: (data) => {
              return Boolean(data.year_of_graduation);
            },
          },
        ]}
        buttonLabel="Update"
      />
      <ManageSingleObject<EmergencyContact>
        title="My emergency contact details"
        getUrl={`/api/my-trip/about-me/${bookingId}/emergency-contact/get/`}
        submitItem={(values) => updateEmergencyContact(bookingId, values)}
        formFields={[
          {
            type: 'smalltext',
            name: 'first_name',
            label: 'First name',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter First Name',
            },
          },
          {
            type: 'smalltext',
            name: 'last_name',
            label: 'Last name',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Last Name',
            },
          },
          {
            type: 'smalltext',
            name: 'relationship',
            label: 'Relationship',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Relationship',
            },
          },
          {
            type: 'smalltext',
            name: 'address.line1',
            label: 'Address line 1',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Address Line 1',
            },
          },
          {
            type: 'smalltext',
            name: 'address.line2',
            label: 'Address line 2',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Address Line 2',
            },
          },
          {
            type: 'smalltext',
            name: 'address.city',
            label: 'City',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter City',
            },
          },
          {
            type: 'smalltext',
            name: 'address.county',
            label: 'County',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter County',
            },
          },
          {
            type: 'remote-dropdown',
            name: 'address.country',
            label: 'Country',
            path: '/api/booking/countries/',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Select Country',
            },
          },
          {
            type: 'smalltext',
            name: 'address.postcode',
            label: 'Postcode',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Postcode',
            },
          },
          {
            type: 'smalltext',
            name: 'email',
            label: 'Email',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Email',
            },
          },
          {
            type: 'smalltext',
            name: 'telephone',
            label: 'Phone',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter Phone',
            },
          },
        ]}
        buttonLabel="Update"
      />
      <MyPassportScan />
    </>
  );
};

const RightSideColumn: React.FC<{ bookingId: string }> = ({ bookingId }) => {
  return (
    <>
      <MyPhotos />
      <ManageSingleObject<DietaryRequirementsData>
        title="Dietary requirements"
        getUrl={`/api/my-trip/about-me/${bookingId}/dietary-requirements/get/`}
        submitItem={(values) => updateDietaryRequirements(bookingId, values)}
        formFields={[
          {
            type: 'local-dropdown',
            name: 'dietary_requirements_option',
            label: 'Dietary requirements',
            options: DIETARY_REQUIREMENT_OPTIONS,
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Select requirement',
            },
          },
          {
            type: 'largetext',
            name: 'dietary_requirements_extra_info',
            label: 'Please specify:',
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Enter requirements here',
            },
          },
        ]}
        buttonLabel="Update"
      />
      <ManageSingleObject<TShirtSizeData>
        title="T-shirt size"
        getUrl={`/api/my-trip/about-me/${bookingId}/tshirt-size/get/`}
        submitItem={(values) => updateTShirtSize(bookingId, values)}
        formFields={[
          {
            type: 'local-dropdown',
            name: 'tshirt_size',
            label: 'T-shirt size (unisex)',
            options: TSHIRT_SIZE_OPTIONS,
            extraProps: {
              row: true,
              grayBorderless: true,
              placeholder: 'Select T-shirt Size',
            },
          },
        ]}
        buttonLabel="Update"
      />
      <ChangePassword />
      <UploadDocuments />
    </>
  );
};

const AboutMe: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const bookingId = activeBooking?.id || '';

  if (!bookingId) {
    return <Loading />;
  }

  return (
    <MyTripStandardPage title="About Me" showDashLink>
      <ThreeColumns
        leftColumn={<LeftHandSideColumn bookingId={bookingId} />}
        middleColumn={<MiddleColumn bookingId={bookingId} />}
        rightColumn={<RightSideColumn bookingId={bookingId} />}
      />
    </MyTripStandardPage>
  );
};

export default AboutMe;
