import React from 'react';
import { mutate } from 'swr';

import { CapacitiesAndStaffData } from './types';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateCapacitiesAndStaff } from './actions';

interface Props {
  hospitalId: string;
}

const CapacitiesAndStaff: React.FC<Props> = ({ hospitalId }) => {
  return (
    <ManageSingleObject<CapacitiesAndStaffData>
      title="Capacities/staff"
      modalTitle="Capacities/staff"
      getUrl={`/api/admin/hospital/${hospitalId}/capacities-and-staff/get/`}
      descriptionFields={[
        {
          label: 'Number of beds',
          render: (item) => item.num_beds,
        },
        {
          label: 'Number of staff',
          render: (item) => item.num_doctors + item.num_nurses,
        },
        {
          label: 'Average daily outpatients',
          render: (item) => item.num_outpatients,
        },
      ]}
      formFields={[
        {
          type: 'smalltext',
          name: 'num_beds',
          label: 'Number of beds',
          extraProps: { type: 'number' },
        },
        {
          type: 'smalltext',
          name: 'num_doctors',
          label: 'Number of doctors',
          extraProps: { type: 'number' },
        },
        {
          type: 'smalltext',
          name: 'num_nurses',
          label: 'Number of nurses',
          extraProps: { type: 'number' },
        },
        {
          type: 'smalltext',
          name: 'num_outpatients',
          label: 'Average daily outpatients',
          extraProps: { type: 'number' },
        },
      ]}
      submitItem={(data) => updateCapacitiesAndStaff(data, hospitalId)}
      onSubmitSuccess={() => {
        mutate(`/api/admin/hospital/${hospitalId}/capacities-and-staff/get/`);
      }}
    />
  );
};

export default CapacitiesAndStaff;
