import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../../modal';
import { CurrencyType } from '../../../booking/booking-form/types';
import CreateInvoiceForm from './CreateInvoiceForm';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  bookingId: string;
  currency: CurrencyType;
}

const CreateInvoiceModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  bookingId,
  currency,
}) => {
  const focusRef = React.useRef(null);
  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 flex flex-col ml-4 mr-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Create Invoice
        </Dialog.Title>
        <CreateInvoiceForm
          bookingId={bookingId}
          currency={currency}
          closeModal={closeModal}
        />
      </div>
    </Modal>
  );
};

export default CreateInvoiceModal;
