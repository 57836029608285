import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  FastField,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FieldInputProps,
  FormikProps,
  FieldArrayRenderProps,
} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { mutate } from 'swr';

import { CurrencyType } from '../../../booking/booking-form/types';
import { CURRENCY_TO_SYMBOL } from '../../../booking/booking-form/constants';
import Input from '../../../../framework-components/input/Input';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import LocalDropdown from '../../../../framework-components/dropdown/LocalDropdown';
import Checkbox from '../../../../framework-components/select/Checkbox';
import { useSubmitFormToAPI } from '../../../../api/forms';
import { getFormikErrors } from '../../../../utils/forms';
import { Option } from '../../../../types';

interface Props {
  bookingId: string;
  currency: CurrencyType;
  closeModal: () => void;
}

type LineItemType = 'REGISTRATION_FEE' | 'HOLIDAY' | 'VILLAGE' | 'ADDON';

interface FormValues {
  xero_invoice_id: string;
  xero_invoice_number: string;
  amount: number;
  line_items: {
    type: Option<LineItemType>;
    amount: number;
  }[];
  current_holiday_invoice: boolean;
  current_village_invoice: boolean;
  current_addon_invoice: boolean;
}

function useOnSubmit(
  bookingId: string | null,
  closeModal: () => void
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI(
    `/api/admin/bookings/${bookingId}/create-invoice/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const lineItems = data.line_items.map((lineItem) => ({
        type: lineItem.type.value,
        amount: lineItem.amount,
      }));
      const [hasSucceeded] = await submitForm(
        { ...data, line_items: lineItems },
        formHelpers
      );
      if (hasSucceeded) {
        toast.success('Invoice created successfully');
        mutate(
          `/api/admin/bookings/${bookingId}/get-invoice-and-credit-note-list/`
        );
        closeModal();
      }
      if (!hasSucceeded) {
        toast.error('Failed to create invoice');
      }
    },
    [submitForm, bookingId, closeModal]
  );
}

const lineItemOptions: Option<LineItemType>[] = [
  {
    value: 'REGISTRATION_FEE',
    label: 'Registration Fee',
  },
  {
    value: 'HOLIDAY',
    label: 'Holiday',
  },
  {
    value: 'VILLAGE',
    label: 'Village',
  },
  {
    value: 'ADDON',
    label: 'Addon',
  },
];

const LineItem = ({
  index,
  setFieldValue,
  values,
  arrayHelpers,
}: {
  index: number;
  setFieldValue: FormikHelpers<FormValues>['setFieldValue'];
  values: FormValues;
  arrayHelpers: FieldArrayRenderProps;
}) => {
  return (
    <div className="mb-4">
      <div className="flex flex-col w-full gap-2 sm:flex-row sm:items-center">
        <LocalDropdown
          label="Type"
          name={`line_items${[index]}.type`}
          options={lineItemOptions}
          onSelect={(option: Option<string> | null) => {
            setFieldValue(`line_items[${[index]}].type`, option);
          }}
          value={values.line_items[index].type}
        />
        <FastField name={`line_items[${[index]}].amount`}>
          {({
            field,
            form,
          }: {
            field: FieldInputProps<string>;
            form: FormikProps<FormValues>;
          }) => (
            <Input
              id={`line_items[${[index]}].amount`}
              label="Amount"
              name={field.name}
              defaultValue={field.value}
              onChange={field.onChange}
              errors={getFormikErrors(form, field.name)}
              placeholder="Enter amount"
            />
          )}
        </FastField>
        <div className="flex align-center justify-center">
          {values.line_items.length > 1 && (
            <FontAwesomeIcon
              className="text-wtw-logo-blue mt-6 hover:cursor-pointer"
              icon={faTrashAlt}
              onClick={() => {
                arrayHelpers.remove(index);
              }}
              data-testid="delete-icon"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const CreateInvoiceForm: React.FC<Props> = ({
  bookingId,
  currency,
  closeModal,
}) => {
  const onSubmit = useOnSubmit(bookingId, closeModal);
  const submitForm = useCallback(
    (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        xero_invoice_id: '',
        xero_invoice_number: '',
        amount: 0,
        line_items: [
          {
            type: lineItemOptions[0],
            amount: 0,
          },
        ],
        current_holiday_invoice: false,
        current_village_invoice: false,
        current_addon_invoice: false,
      }}
      onSubmit={submitForm}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="space-y-4">
            <FastField name="xero_invoice_id">
              {({
                field,
                form,
              }: {
                field: FieldInputProps<string>;
                form: FormikProps<FormValues>;
              }) => (
                <Input
                  id="xero_invoice_id"
                  label="Invoice ID"
                  name={field.name}
                  defaultValue={field.value}
                  onChange={field.onChange}
                  errors={getFormikErrors(form, field.name)}
                  placeholder="Enter invoice ID"
                />
              )}
            </FastField>
            <FastField name="xero_invoice_number">
              {({
                field,
                form,
              }: {
                field: FieldInputProps<string>;
                form: FormikProps<FormValues>;
              }) => (
                <Input
                  id="xero_invoice_number"
                  label="Invoice number"
                  name={field.name}
                  defaultValue={field.value}
                  onChange={field.onChange}
                  errors={getFormikErrors(form, field.name)}
                  placeholder="Enter invoice number"
                />
              )}
            </FastField>
            <FastField name="amount">
              {({
                field,
                form,
              }: {
                field: FieldInputProps<string>;
                form: FormikProps<FormValues>;
              }) => (
                <Input
                  id="amount"
                  label={`Amount(${CURRENCY_TO_SYMBOL[currency]})`}
                  name={field.name}
                  defaultValue={field.value}
                  onChange={field.onChange}
                  errors={getFormikErrors(form, field.name)}
                  placeholder="Enter amount"
                  type="number"
                />
              )}
            </FastField>
            <FieldArray
              name="line_items"
              render={(arrayHelpers) => (
                <div>
                  <label
                    htmlFor="line_items"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Line Items
                  </label>
                  {values.line_items.map((_section, index) => (
                    <LineItem
                      key={index}
                      index={index}
                      setFieldValue={setFieldValue}
                      values={values}
                      arrayHelpers={arrayHelpers}
                    />
                  ))}
                  <div
                    className="flex items-center gap-2 hover:cursor-pointer"
                    onClick={() => {
                      arrayHelpers.push({
                        type: { label: '', value: '' },
                        amount: 0,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      className="text-wtw-logo-blue text-center"
                      icon={faPlus}
                    />
                    <div className="text-sm">Add Line Item</div>
                  </div>
                </div>
              )}
            />
            <Checkbox
              name="current_holiday_invoice"
              label="Current Holiday Invoice"
              option={{
                value: 'current_holiday_invoice',
                label:
                  'Check this box if this invoice is the current active invoice containing the main holiday line items',
              }}
              checked={values.current_holiday_invoice}
              onChange={(name, checked) => setFieldValue(name, checked)}
            />
            <Checkbox
              name="current_village_invoice"
              label="Current Village Invoice"
              option={{
                value: 'current_village_invoice',
                label:
                  'Check this box if this invoice is the current active invoice containing the village experience line item',
              }}
              checked={values.current_village_invoice}
              onChange={(name, checked) => setFieldValue(name, checked)}
            />
            <Checkbox
              name="current_addon_invoice"
              label="Current Addon Invoice"
              option={{
                value: 'current_addon_invoice',
                label:
                  'Check this box if this invoice is the current active invoice containing the addons (e.g. safari) line item',
              }}
              checked={values.current_addon_invoice}
              onChange={(name, checked) => setFieldValue(name, checked)}
            />
            <PrimaryButton label="Submit" type="submit" className="w-full" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateInvoiceForm;
