import React, { useCallback } from 'react';
import classNames from 'classnames';

import { Option } from '../../types';

interface Props {
  name: string;
  label?: string;
  option: Option;
  checked: boolean;
  onChange: (value: string, checked: boolean) => void;
  disabled?: boolean;
  fontWeight?: 'normal' | 'medium' | 'bold';
}

const Checkbox: React.FC<Props> = ({
  name,
  label,
  option,
  checked,
  onChange,
  disabled,
  fontWeight = 'medium',
}) => {
  const handleChange = useCallback(() => {
    onChange(name, !checked);
  }, [onChange, name, checked]);

  return (
    <fieldset>
      <div className="space-y-2">
        {label && (
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
        )}
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id={name}
              aria-describedby={`${name}-description`}
              name={name}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-wtw-logo-blue focus:ring-wtw-logo-blue"
              onChange={handleChange}
              checked={checked}
              disabled={disabled}
            />
          </div>
          <div className="ml-2 text-sm leading-6">
            <label
              htmlFor={name}
              className={classNames('text-gray-900', {
                'font-normal': fontWeight === 'normal',
                'font-medium': fontWeight === 'medium',
                'font-bold': fontWeight === 'bold',
              })}
            >
              {option.label}
            </label>
            {option.description && (
              <p id={`${name}-description`} className="text-gray-500">
                {option.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default Checkbox;
