import { apiRequest } from '../../../../../api/api';
import {
  LocationPrice,
  GetPriceForWeeks,
  Capacity,
  AccountCodes,
} from './types';

export const createLocationPrice = (
  destinationId: string,
  values: LocationPrice
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/location-prices/create/`;
  return apiRequest<LocationPrice>(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};

export const updateLocationPrice = (
  destinationId: string,
  values: LocationPrice
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/location-prices/${values.weeks_at_price}/update/`;
  return apiRequest<LocationPrice>(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};

export const deleteLocationPrice = (
  destinationId: string,
  values: LocationPrice
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/location-prices/${values.weeks_at_price}/delete/`;
  return apiRequest<LocationPrice>(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};

export const getPriceForWeeks = (
  destinationId: string,
  numberOfWeeks: string
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/location-prices/${numberOfWeeks}/prices/`;
  return apiRequest<GetPriceForWeeks>(url, {
    method: 'GET',
  });
};

export const updateCapacity = (data: Capacity) => {
  const url = `/api/admin/destination/destination-profile/capacities/${data.id}/update/`;
  return apiRequest<Capacity>(url, {
    method: 'POST',
    body: JSON.stringify({ capacity: data.capacity }),
  });
};

export const updateAccountCodes = (
  destinationId: string,
  values: AccountCodes
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/xero-account-codes/set/`;
  return apiRequest<AccountCodes>(url, {
    method: 'POST',
    body: JSON.stringify({
      xero_registration_fee_code: values.xero_registration_fee_code?.value,
      xero_project_fee_code: values.xero_project_fee_code?.value,
      xero_admin_fee_code: values.xero_admin_fee_code?.value,
      xero_sales_fee_code: values.xero_sales_fee_code?.value,
    }),
  });
};
