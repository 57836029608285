import { apiRequest } from '../../../../api/api';
import { Option } from '../../../../types';
import {
  UpdateBasicInfoFormData,
  ConflictResolution,
  ArrivalAndDepatureDetails,
  MonitoringFormUpload,
  TripDetailsData,
  AddonDetailsData,
  ContactDetailsData,
  TravelInsuranceDetailsData,
  IndemnityInsuranceDetailsData,
  ApprovalStatus,
  EmergencyContact,
  ClinicalExperienceData,
  CheckTripDetailsData,
  TShirtSizeData,
  DietaryRequirementsData,
  AccomodationDetailsRequestData,
} from './types';

export const updateApplicantBasicInfo = (
  bookingId: string,
  basicInfo: UpdateBasicInfoFormData
) => {
  const url = `/api/admin/bookings/${bookingId}/basic-profile-page-info/update/`;
  const data = {
    ...basicInfo,
    gender: basicInfo.gender?.value,
    nationality: basicInfo.nationality?.value,
    university: basicInfo.university?.value,
    year_of_university_study: basicInfo.year_of_university_study?.value,
    year_of_graduation: basicInfo.year_of_graduation?.value,
  };
  return apiRequest<UpdateBasicInfoFormData>(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const updateFlightArrivalAndDepartureDetails = (
  bookingId: string,
  values: ArrivalAndDepatureDetails
) => {
  const url = `/api/admin/bookings/${bookingId}/update-flight-arrival-and-departure-details/`;
  return apiRequest<ArrivalAndDepatureDetails>(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};

export const updateFlightConflictResolution = (
  url: string,
  values: ConflictResolution
) => {
  return apiRequest<ConflictResolution>(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};

export const uploadMonitoringForm = (
  bookingId: string,
  values: MonitoringFormUpload
) => {
  const url = `/api/admin/bookings/${bookingId}/upload-monitoring-form/`;
  return apiRequest(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};

export const updateTripWithPositiveFeedback = (
  bookingId: string,
  positiveExperience: boolean
) => {
  const url = `/api/admin/bookings/${bookingId}/set-positive-feedback/`;
  return apiRequest(url, {
    method: 'POST',
    body: JSON.stringify({ has_positive_feedback: positiveExperience }),
  });
};

export const updateTripAsContactedByMarketing = (
  bookingId: string,
  contactedByMarketing: boolean
) => {
  const url = `/api/admin/bookings/${bookingId}/set-contacted-by-marketing/`;
  return apiRequest(url, {
    method: 'POST',
    body: JSON.stringify({
      contacted_for_marketing_purposes: contactedByMarketing,
    }),
  });
};

export const updateTripDetails = (
  bookingId: string,
  tripDetails: TripDetailsData
) => {
  const url = `/api/admin/bookings/${bookingId}/update-trip-details/`;
  return apiRequest<TripDetailsData>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...tripDetails,
      destination: tripDetails.destination.value,
    }),
  });
};

export const updateAddonDetails = (
  bookingId: string,
  addonDetails: AddonDetailsData
) => {
  const url = `/api/admin/bookings/${bookingId}/update-addon-details/`;
  return apiRequest<AddonDetailsData>(url, {
    method: 'POST',
    body: JSON.stringify({
      wildlife_safari_added: addonDetails.wildlife_safari_added,
    }),
  });
};

export const addVillageExperience = (bookingId: string) => {
  const url = `/api/admin/bookings/${bookingId}/add-village-experience/`;
  return apiRequest(url, {
    method: 'POST',
  });
};

export const removeVillageExperience = (bookingId: string) => {
  const url = `/api/admin/bookings/${bookingId}/remove-village-experience/`;
  return apiRequest<{ id: string }>(url, {
    method: 'POST',
  });
};

export const updateContactDetailsAdmin = (
  bookingId: string,
  contactDetails: ContactDetailsData
) => {
  const url = `/api/admin/bookings/${bookingId}/set-contact-details/`;
  return apiRequest<ContactDetailsData>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...contactDetails,
      address: {
        ...contactDetails.address,
        country: contactDetails.address.country?.value,
      },
    }),
  });
};

export const updateEmergencyContact = (
  contact_details: EmergencyContact,
  bookingId: string | undefined
) => {
  const url = `/api/admin/bookings/${bookingId}/emergency-contact/set/`;
  return apiRequest<EmergencyContact>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...contact_details,
      address: {
        ...contact_details.address,
        country: contact_details.address.country?.value,
      },
    }),
  });
};

export const updateTravelInsuranceDetails = (
  bookingId: string,
  travelInsuranceDetails: TravelInsuranceDetailsData
) => {
  const url = `/api/admin/bookings/${bookingId}/set-travel-insurance/`;
  return apiRequest<TravelInsuranceDetailsData>(url, {
    method: 'POST',
    body: JSON.stringify(travelInsuranceDetails),
  });
};

export const updateIndemnityInsuranceDetails = (
  bookingId: string,
  indemnityInsuranceDetails: IndemnityInsuranceDetailsData
) => {
  const url = `/api/admin/bookings/${bookingId}/set-indemnity-insurance/`;
  return apiRequest<IndemnityInsuranceDetailsData>(url, {
    method: 'POST',
    body: JSON.stringify(indemnityInsuranceDetails),
  });
};

export const setApplicantFile = (
  url: string,
  field: string,
  fileId: string
) => {
  return apiRequest<void>(url, {
    method: 'POST',
    body: JSON.stringify({ [field]: fileId }),
  });
};

export const updateFileStatus = (fileId: string, status: ApprovalStatus) => {
  const url = `/api/admin/bookings/${fileId}/update-file-status/`;
  return apiRequest<void>(url, {
    method: 'POST',
    body: JSON.stringify({ approval_status: status }),
  });
};

export const deleteFile = (fileId: string) => {
  const url = `/api/admin/bookings/${fileId}/delete-file/`;
  return apiRequest<void>(url, {
    method: 'DELETE',
  });
};

export const deleteMonitoringForm = (bookingId: string, formType: string) => {
  const url = `/api/admin/bookings/${bookingId}/delete-monitoring-form/?form_type=${formType}`;
  return apiRequest<void>(url, {
    method: 'DELETE',
  });
};

export const updateClinicalExperience = (
  bookingId: string,
  clinicalExperience: ClinicalExperienceData
) => {
  const url = `/api/admin/bookings/${bookingId}/set-clinical-experience/`;
  return apiRequest<ClinicalExperienceData>(url, {
    method: 'POST',
    body: JSON.stringify(clinicalExperience),
  });
};

export const postponeBooking = (bookingId: string) => {
  const url = `/api/admin/bookings/${bookingId}/postponed/set/`;
  return apiRequest<void>(url, { method: 'POST' });
};

export const unPostponeBooking = (bookingId: string) => {
  const url = `/api/admin/bookings/${bookingId}/postponed/unset/`;
  return apiRequest<void>(url, { method: 'POST' });
};

export const archiveBooking = (bookingId: string) => {
  const url = `/api/admin/bookings/${bookingId}/archive/set/`;
  return apiRequest<void>(url, { method: 'POST' });
};

export const unArchiveBooking = (bookingId: string) => {
  const url = `/api/admin/bookings/${bookingId}/archive/unset/`;
  return apiRequest<void>(url, { method: 'POST' });
};

export const cancelBooking = (bookingId: string) => {
  const url = `/api/admin/bookings/${bookingId}/cancel-booking/`;
  return apiRequest<void>(url, {
    method: 'POST',
  });
};

export const checkTripDetails = (bookingId: string, data: TripDetailsData) => {
  const url = `/api/admin/bookings/${bookingId}/check-trip-details/`;
  return apiRequest<CheckTripDetailsData>(url, {
    method: 'POST',
    body: JSON.stringify({ ...data, destination: data.destination.value }),
  });
};

export const updateTShirtSize = (bookingId: string, data: TShirtSizeData) => {
  const url = `/api/admin/bookings/${bookingId}/tshirt-size/set/`;
  return apiRequest<TShirtSizeData>(url, {
    method: 'POST',
    body: JSON.stringify({
      tshirt_size: data.tshirt_size?.value,
    }),
  });
};

export const updateDietaryRequirements = (
  bookingId: string,
  data: DietaryRequirementsData
) => {
  const url = `/api/admin/bookings/${bookingId}/dietary-requirements/set/`;
  return apiRequest<DietaryRequirementsData>(url, {
    method: 'POST',
    body: JSON.stringify({
      dietary_requirements_option: data.dietary_requirements_option?.value,
      dietary_requirements_extra_info:
        data.dietary_requirements_extra_info || '',
    }),
  });
};

export const updateVillageDates = (
  bookingHouseId: string,
  start_date: string,
  end_date: string
) => {
  const url = `/api/admin/bookings/${bookingHouseId}/update-village-dates/`;
  return apiRequest<AccomodationDetailsRequestData>(url, {
    method: 'POST',
    body: JSON.stringify({ start_date, end_date }),
  });
};

export const extendBookingHouseDuration = (
  bookingHouseId: string,
  number_of_weeks: number
) => {
  const url = `/api/admin/bookings/booking-houses/${bookingHouseId}/extend/`;
  return apiRequest<{ number_of_weeks: number }>(url, {
    method: 'POST',
    body: JSON.stringify({
      number_of_weeks,
    }),
  });
};

export const reduceBookingHouseDuration = (
  bookingHouseId: string,
  number_of_weeks: number
) => {
  const url = `/api/admin/bookings/booking-houses/${bookingHouseId}/reduce/`;
  return apiRequest<{ number_of_weeks: number }>(url, {
    method: 'POST',
    body: JSON.stringify({
      number_of_weeks,
    }),
  });
};

export const removeBookingHouse = (bookingHouseId: string) => {
  const url = `/api/admin/bookings/booking-houses/${bookingHouseId}/remove/`;
  return apiRequest(url, {
    method: 'POST',
  });
};

export const changeDestination = (bookingId: string, destination: Option) => {
  const url = `/api/admin/bookings/${bookingId}/change-destination/`;
  return apiRequest<{ destination: Option }>(url, {
    method: 'POST',
    body: JSON.stringify({
      destination: destination.value,
    }),
  });
};

export const changeTripStartDate = (bookingId: string, start_date: string) => {
  const url = `/api/admin/bookings/${bookingId}/change-start-date/`;
  return apiRequest<{ start_date: string }>(url, {
    method: 'POST',
    body: JSON.stringify({
      start_date: start_date,
    }),
  });
};
