import React from 'react';
import classNames from 'classnames';

interface StandardContainerProps {
  children: React.ReactNode;
  className?: string;
  variant?: 'white' | 'gray';
}

const StandardContainer: React.FC<StandardContainerProps> = ({
  children,
  className,
  variant = 'white',
}) => {
  return (
    <div
      className={classNames(
        'rounded-xl p-4',
        {
          'bg-white': variant === 'white',
          'bg-wtw-gray-1': variant === 'gray',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default StandardContainer;
