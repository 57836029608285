import React, { useCallback, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

import { Event } from './types';
import { calculateDaysBetweenDates } from '../../utils/dates';

interface ContextMenu {
  visible: boolean;
  x: number;
  y: number;
}

interface Props<T> {
  event: Event<T>;
  contextMenu: ContextMenu;
  setContextMenu: React.Dispatch<React.SetStateAction<ContextMenu>>;
}

const RightClickMenu = <T,>({
  event,
  contextMenu,
  setContextMenu,
}: Props<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hideContextMenu = useCallback(() => {
    setContextMenu((prevState) => ({ ...prevState, visible: false }));
  }, [setContextMenu]);

  const handleViewByDates = () => {
    const year = event.start_date.getFullYear().toString();
    const month = (event.start_date.getMonth() + 1).toString();
    const day = event.start_date.getDate().toString();
    const period = (
      calculateDaysBetweenDates(event.start_date, event.end_date) + 1
    ).toString();

    searchParams.set('year', year);
    searchParams.set('month', month);
    searchParams.set('day', day);
    searchParams.set('period', period);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const handleClickOutside = () => hideContextMenu();
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [hideContextMenu]);

  return (
    <>
      {contextMenu.visible && (
        <div
          className="absolute w-[200px] bg-white border border-gray-300 shadow-md z-[9999]"
          style={{
            top: contextMenu.y,
            left: contextMenu.x,
          }}
        >
          <Link to={`/admin/bookings/${event.bookingId}/applicant-profile/`}>
            <div className="cursor-pointer p-2 hover:bg-gray-100">
              Go to profile
            </div>
          </Link>
          <div
            onClick={handleViewByDates}
            className="cursor-pointer p-2 hover:bg-gray-100"
          >
            View by these dates
          </div>
        </div>
      )}
    </>
  );
};

export default RightClickMenu;
