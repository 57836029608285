import React, { useContext } from 'react';
import { mutate } from 'swr';

import ManageSingleObject from '../../manage-single-object/ManageSingleObject';
import { SET_STAFF_PERMISSIONS_OPTIONS } from './constants';
import { apiRequest } from '../../../api/api';
import { hasPermission } from '../../../utils/permissions';
import { LoggedInUserContext } from '../../../context-providers/logged-in-user';

interface Props {
  userId: string;
  role: string;
}

interface PermissionSubmissionData {
  value: string;
  label: string;
}

interface FormValues {
  role: {
    value: string;
    label: string;
  };
  is_active: boolean;
}

const PermissionsComponent: React.FC<Props> = ({ userId }) => {
  const updatePermissions = (
    role: PermissionSubmissionData,
    is_active: boolean
  ) => {
    const url = `/api/admin/staff/${userId}/permission/set/`;
    return apiRequest<FormValues>(url, {
      method: 'POST',
      body: JSON.stringify({
        role: role.value,
        is_active: is_active,
      }),
    });
  };

  const loggedInUser = useContext(LoggedInUserContext);

  return (
    <div className="mt-6">
      {loggedInUser &&
        hasPermission(loggedInUser, 'Staff.READ_STAFF_PERMISSIONS') && (
          <ManageSingleObject
            title="Edit permissions"
            modalTitle="Edit permissions"
            getUrl={`/api/admin/staff/${userId}/permission/get/`}
            formFields={[
              {
                type: 'local-dropdown',
                name: 'role',
                label: 'User level',
                options: SET_STAFF_PERMISSIONS_OPTIONS,
                extraProps: {
                  row: true,
                },
              },
              {
                type: 'checkbox',
                name: 'is_active',
                option: { value: 'true', label: 'Active' },
                extraProps: {
                  row: true,
                },
              },
            ]}
            descriptionFields={[
              {
                label: 'User level',
                render: (data: FormValues) => data.role.label,
              },
            ]}
            editButton={
              loggedInUser
                ? loggedInUser &&
                  hasPermission(loggedInUser, 'General.ACCESS_ADMIN') &&
                  hasPermission(loggedInUser, 'Staff.SET_STAFF_PERMISSIONS')
                : false
            }
            submitItem={(data: FormValues) => {
              return updatePermissions(data.role, data.is_active);
            }}
            onSubmitSuccess={() => {
              mutate(`/api/admin/staff/${userId}/permission/get/`);
              mutate(`/api/admin/staff/${userId}/summary/get/`);
            }}
          />
        )}
    </div>
  );
};

export default PermissionsComponent;
