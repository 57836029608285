import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface NewNotesButtonProps {
  onClick: () => void;
}

const NewNotesButton: React.FC<NewNotesButtonProps> = ({ onClick }) => {
  return (
    <div>
      <button
        className="text-sm-semibold py-2.5 px-3 flex gap-2.5 items-center rounded-lg w-[48px] h-[48px] border border-gray-300 hover:bg-gray-300 cursor-pointer"
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={faAdd}
          className="w-full h-full text-wtw-logo-blue"
        />
      </button>
    </div>
  );
};

export default NewNotesButton;
