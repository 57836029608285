import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import Modal from '../../../modal';
import { Option } from '../../../../types';
import { changeDestination } from './actions';
import QuickForm from '../../../../framework-components/quickform/QuickForm';
import useModalState from '../../../../hooks/modals';
import { PrimaryButton } from '../../../../framework-components/button/Button';

interface Props {
  bookingId: string;
  initialDestination: Option;
  destinationOptions: readonly Option[] | undefined;
}

const ChangeDestination = ({
  bookingId,
  initialDestination,
  destinationOptions,
}: Props) => {
  const focusRef = React.useRef(null);
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <>
      <PrimaryButton className="w-full" onClick={openModal}>
        Change destination
      </PrimaryButton>
      <Modal
        isOpen={isOpen ?? false}
        onClose={closeModal}
        initialFocus={focusRef}
      >
        <div className="space-y-6 flex flex-col items-center">
          <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
            Change destination
          </Dialog.Title>
          <Dialog.Description>
            <div className="flex flex-col gap-2 text-sm">
              <p>
                Note: If the destination is changed, all flight & travel details
                will be removed.
              </p>
            </div>
          </Dialog.Description>
          <Dialog.Description>
            <div className="flex flex-col gap-2 text-sm">
              <QuickForm
                onSubmit={(data) => {
                  return changeDestination(bookingId, data.destination);
                }}
                onSubmitSuccess={() => {
                  toast.success('Trip destination changed successfully');
                  closeModal();
                  mutate(`/api/admin/bookings/${bookingId}/get-trip-details/`);
                  mutate(
                    `/api/admin/bookings/${bookingId}/get-accomodation-details/`
                  );
                  mutate(
                    `/api/admin/bookings/${bookingId}/get-flight-details/`
                  );
                  mutate(`/api/admin/bookings/${bookingId}/get-addon-details/`);
                }}
                onSubmitFailure={() => {
                  toast.error('Unable to change destination');
                }}
                initialValues={{
                  destination: initialDestination,
                }}
                fields={[
                  {
                    type: 'local-dropdown',
                    name: 'destination',
                    label: `Destination:`,
                    options: destinationOptions,
                  },
                ]}
              />
            </div>
          </Dialog.Description>
        </div>
      </Modal>
    </>
  );
};

export default ChangeDestination;
