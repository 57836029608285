import React from 'react';

import MyTripStandardPage from '../layout/MyTripStandardPage';
import TwoColumns from '../../admin/common/layout/TwoColumns';
import ClinicalInterestText from './ClinicalInterestText';
import ClinicalInterests from './ClinicalInterests';

const ClinicalInterest: React.FC = () => {
  return (
    <MyTripStandardPage title="Clinical interest" showDashLink>
      <TwoColumns
        leftColumn={<ClinicalInterestText />}
        rightColumn={<ClinicalInterests />}
      />
    </MyTripStandardPage>
  );
};

export default ClinicalInterest;
