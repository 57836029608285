import React from 'react';
import { ErrorMessage } from 'formik';

import ErrorText from '../error-text';

interface Props extends React.HTMLProps<HTMLDivElement> {
  label: string;
  fieldName: string;
}

const FieldWrapper: React.FC<Props> = ({
  label,
  fieldName,
  children,
  ...rest
}) => (
  <div {...rest}>
    <label
      htmlFor={fieldName}
      className="block mb-2 text-sm font-medium leading-4 text-gray-700"
    >
      {label}
    </label>
    {children}
    <ErrorMessage
      name={fieldName}
      component={ErrorText}
      className="mt-1 text-sm"
    />
  </div>
);

export default FieldWrapper;
