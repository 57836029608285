import React, { useMemo, useCallback } from 'react';
import { FormikValues } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { mutate } from 'swr';

import { useAPI } from '../../api/api';
import Table from './Table';
import { TableField } from './types';
import { FieldType } from '../../framework-components/quickform/Field';
import { PrimaryButton } from '../../framework-components/button/Button';
import useModalState from '../../hooks/modals';
import ManageModal from './ManageModal';
import LoadingOverlay from '../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../admin/common/error/ErrorOverlay';
import AdminWidgetContainer from '../../framework-components/card/AdminWidgetContainer';

interface Props<T> {
  title?: string;
  titleClassName?: string;
  tableFields: ReadonlyArray<TableField<T>>;
  quickFormFields: ReadonlyArray<FieldType>;
  getUrl?: string;
  prefetchedData?: Array<T>;
  mutateUrl?: string;
  addHandler?: (values: T) => Promise<T>;
  editHandler?: (values: T) => Promise<T>;
  deleteHandler?: (values: T) => Promise<T>;
  onAddSuccess?: (item: T) => void;
  onEditSuccess?: (item: T) => void;
  onDeleteSuccess?: (item: T) => void;
  hideManageButton?: boolean;
  isFixedTableLayout?: boolean;
  renderRowColor?: (data: T) => string;
  noDataFoundMessage: string;
  insideCard?: boolean;
  readonlyEditFields?: ReadonlyArray<string>;
  revalidateToMutate?: boolean;
  manageButtonText?: string;
  justifyCenter?: boolean;
}

const ManageMultipleObjects = <T extends FormikValues>({
  title,
  titleClassName = 'text-sh2',
  tableFields,
  quickFormFields,
  getUrl,
  prefetchedData,
  mutateUrl,
  addHandler,
  editHandler,
  deleteHandler,
  onAddSuccess,
  onEditSuccess,
  onDeleteSuccess,
  hideManageButton = false,
  isFixedTableLayout = false,
  noDataFoundMessage,
  renderRowColor,
  insideCard = true,
  readonlyEditFields,
  revalidateToMutate = false,
  manageButtonText = 'Manage',
  justifyCenter = true,
}: Props<T>) => {
  const {
    data: fetchedData,
    isValidating,
    error,
  } = useAPI<Array<T>>(getUrl ?? null);

  const data = prefetchedData ?? fetchedData;
  const url = mutateUrl ?? getUrl;

  const { isOpen, openModal, closeModal } = useModalState();

  const onEditSuccessHandler = useCallback(
    (values: T) => {
      if (data) {
        if (revalidateToMutate) {
          mutate(url);
          return;
        }

        const updatedData = [...data];
        const itemIdx = data.findIndex((item) => item.id === values.id);
        if (itemIdx === -1 || !values.id) {
          return;
        }
        updatedData.splice(itemIdx, 1, values);
        mutate(url, () => updatedData, {
          revalidate: false,
        });
        if (onEditSuccess) {
          onEditSuccess(values);
        }
      }
    },
    [url, data, onEditSuccess, revalidateToMutate]
  );

  const onAddSuccessHandler = useCallback(
    (values: T) => {
      if (data) {
        if (revalidateToMutate) {
          mutate(url);
          return;
        }

        const updatedData = [...data, values];
        mutate(url, () => updatedData, {
          revalidate: false,
        });
        if (onAddSuccess) {
          onAddSuccess(values);
        }
      }
    },
    [url, data, onAddSuccess, revalidateToMutate]
  );

  const onDeleteSuccessHandler = useCallback(
    (values: T) => {
      if (data) {
        if (revalidateToMutate) {
          mutate(url);
          return;
        }

        const updatedData = [...data];
        const itemIdx = data.findIndex((item) => item.id === values.id);
        if (itemIdx === -1 || !values.id) {
          return;
        }
        updatedData.splice(itemIdx, 1);
        mutate(url, () => updatedData, {
          revalidate: false,
        });
        if (onDeleteSuccess) {
          onDeleteSuccess(values);
        }
      }
    },
    [url, data, onDeleteSuccess, revalidateToMutate]
  );

  const actionField = useMemo(
    () => ({
      name: 'actions',
      render: (_: T, editAction: () => void, deleteAction: () => void) => (
        <div className="flex gap-2 justify-end">
          {editHandler && (
            <button className="p-1 rounded-md" onClick={editAction}>
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}
          {deleteHandler && (
            <button className="p-1 rounded-md" onClick={deleteAction}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          )}
        </div>
      ),
    }),
    [editHandler, deleteHandler]
  );

  const tableFieldsWithActions = [...tableFields, actionField];

  const hasData = data && data.length > 0;

  const render = () => {
    return (
      <div className="relative space-y-6 grid">
        {isValidating && <LoadingOverlay />}
        {error && !isValidating && <ErrorOverlay />}
        <h1 className={titleClassName}>{title}</h1>
        {hasData ? (
          <Table
            data={data}
            tableFields={tableFields.filter(
              (field) => !field.renderInModalOnly
            )}
            quickFormFields={quickFormFields}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            onEditSuccess={onEditSuccessHandler}
            onDeleteSuccess={onDeleteSuccessHandler}
            isFixedTableLayout={isFixedTableLayout}
            renderRowColor={renderRowColor}
          />
        ) : (
          <p className="text-sm">{noDataFoundMessage}</p>
        )}
        {!hideManageButton && (
          <PrimaryButton
            label={manageButtonText}
            onClick={openModal}
            className="w-fit"
          />
        )}
        {data && (
          <ManageModal
            isOpen={isOpen}
            closeModal={closeModal}
            data={data}
            tableFields={tableFieldsWithActions}
            quickFormFields={quickFormFields}
            addHandler={addHandler}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            onAddSuccess={onAddSuccessHandler}
            onEditSuccess={onEditSuccessHandler}
            onDeleteSuccess={onDeleteSuccessHandler}
            readonlyEditFields={readonlyEditFields}
            justifyCenter={justifyCenter}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {insideCard ? (
        <AdminWidgetContainer hasBorder hasShadow>
          {render()}
        </AdminWidgetContainer>
      ) : (
        render()
      )}
    </>
  );
};

export default ManageMultipleObjects;
