import React, { useEffect } from 'react';
import { FormikValues } from 'formik';
import { mutate } from 'swr';

import { useAPI } from '../../api/api';
import { FieldType } from '../../framework-components/quickform/Field';
import AdminWidgetContainer from '../../framework-components/card/AdminWidgetContainer';
import useModalState from '../../hooks/modals';
import { PencilEditButton } from '../../framework-components/button/PencilEditButton';
import EditDetailsModal from './EditDetailsModal';
import LoadingOverlay from '../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../admin/common/error/ErrorOverlay';

interface DescriptionField<T> {
  label: string;
  render: (item: T) => React.ReactNode;
}

interface Props<T> {
  title?: string | React.ReactNode;
  titleClassName?: string;
  getUrl: string | null;
  submitItem?: (data: T) => Promise<T>;
  onSubmitSuccess?: (data: T) => void;
  descriptionFields?: ReadonlyArray<DescriptionField<T>>;
  formFields: ReadonlyArray<FieldType>;
  simple?: boolean;
  insideCard?: boolean;
  alternativeRender?: (data: T) => React.ReactNode;
  modalTitle?: string;
  buttonLabel?: string;
  onInitialize?: (data: T) => void;
  editButton?: boolean;
  disableForm?: (data: T) => boolean;
  modalMaxWidth?: string;
  preSubmitModal?: JSX.Element;
}

export const Item = <T,>({
  item,
  data,
}: {
  item: DescriptionField<T>;
  data: T;
}) => {
  return (
    <div className="flex gap-6 min-h-[44px] items-center py-1">
      <dt className="text-xs-bold w-1/2 break-words">{item.label}</dt>
      <dd className="text-gray-900 text-xs w-1/2">{item.render(data)}</dd>
    </div>
  );
};

const ManageSingleObject = <T extends FormikValues>({
  title,
  titleClassName = 'text-sh2',
  getUrl,
  submitItem,
  onSubmitSuccess,
  descriptionFields = [],
  formFields,
  simple,
  insideCard = true,
  alternativeRender,
  modalTitle,
  buttonLabel,
  onInitialize,
  editButton = true,
  disableForm,
  preSubmitModal,
}: Props<T>) => {
  const { data, isValidating, error } = useAPI<T>(getUrl);
  const { isOpen, openModal, closeModal } = useModalState();
  const [hasInitialized, setHasInitialized] = React.useState(false);

  const onSubmitSuccessHandler = (values: T) => {
    mutate(getUrl, values, false);
    if (onSubmitSuccess) {
      onSubmitSuccess(values);
    }
  };

  const disable = data && disableForm ? disableForm(data) : false;

  const render = () => {
    return (
      <div className="grid space-y-4 relative w-full h-fit py-2">
        {error && !isValidating && <ErrorOverlay />}
        {isValidating && <LoadingOverlay />}
        {title && <h4 className={titleClassName}>{title}</h4>}
        {alternativeRender && data ? (
          alternativeRender(data)
        ) : (
          <>
            {simple ? (
              <div className="text-md">
                {data &&
                  descriptionFields.length > 0 &&
                  descriptionFields[0].render(data)}
              </div>
            ) : (
              <dl className="px-3 py-1 bg-wtw-gray-2 w-full rounded-lg">
                {data &&
                  descriptionFields.map((item) => (
                    <Item key={item.label} item={item} data={data} />
                  ))}
              </dl>
            )}
          </>
        )}
        {editButton ? <PencilEditButton onClick={openModal} /> : <></>}
        {data && submitItem && (
          <EditDetailsModal<T>
            isOpen={isOpen}
            closeModal={closeModal}
            submitItem={submitItem}
            onSubmitSuccess={onSubmitSuccessHandler}
            data={data}
            fields={formFields}
            title={modalTitle}
            buttonLabel={buttonLabel}
            disable={disable}
            preSubmitModal={preSubmitModal}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (data && !hasInitialized) {
      if (onInitialize) {
        onInitialize(data);
      }
      setHasInitialized(true);
    }
  }, [data, hasInitialized, onInitialize]);

  return (
    <>
      {insideCard ? (
        <AdminWidgetContainer hasBorder hasShadow>
          {render()}
        </AdminWidgetContainer>
      ) : (
        render()
      )}
    </>
  );
};

export default ManageSingleObject;
