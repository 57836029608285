import classNames from 'classnames';
import React from 'react';

interface SpanningContainerProps {
  children: React.ReactNode | React.ReactNode[];
  variant: 'white' | 'gray' | 'dark-gray' | 'clear';
}

const SpanningContainer: React.FC<SpanningContainerProps> = ({
  children,
  variant,
}) => {
  return (
    <div
      className={classNames(
        'px-[10000px] -mx-[10000px] py-8 flex flex-col space-y-8',
        {
          'bg-white': variant === 'white',
          'bg-wtw-gray-1': variant === 'gray',
          'bg-wtw-gray-3': variant === 'dark-gray',
          'bg-transparent': variant === 'clear',
        }
      )}
    >
      {children}
    </div>
  );
};
export default SpanningContainer;
