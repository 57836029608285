import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { format } from 'date-fns';

import HeadingTwo from '../components/typography/HeadingTwo';

interface TimelineDateProps {
  date: string;
  hasIcon: boolean;
  selected: boolean;
  onClick?: () => void;
}
const TimelineDate: React.FC<TimelineDateProps> = ({
  date,
  hasIcon,
  selected,
  onClick,
}) => {
  const chevronStyle = {
    clipPath: 'polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%)',
  };
  return (
    <div
      className={classNames(
        'flex items-center justify-center cursor-pointer shadow-m h-[25px] w-[100px] text-mob-xs md:h-[40px] md:w-[150px] md:text-md',
        {
          'bg-wtw-logo-blue text-white': selected,
          'bg-wtw-gray-3': !selected,
        }
      )}
      style={chevronStyle}
      onClick={onClick}
    >
      {date}
      {hasIcon && <FontAwesomeIcon icon={faPlane} />}
    </div>
  );
};

interface Props {
  dates: string[];
  selectedDateIndex: number;
  setSelectedDateIndex: (index: number) => void;
}

const Timeline: React.FC<Props> = ({
  dates,
  selectedDateIndex,
  setSelectedDateIndex,
}) => {
  return (
    <div className="flex flex-col space-y-6">
      <HeadingTwo text="Timeline" />
      <p>
        Click on the timeline to see what tasks need to be completed by each
        date.
      </p>
      <div className="flex flex-wrap">
        {dates.map((date, index) => {
          return (
            <TimelineDate
              key={date}
              date={format(new Date(date), 'MMM do')}
              hasIcon={index === dates.length - 1}
              selected={selectedDateIndex === index}
              onClick={() => {
                if (selectedDateIndex === index) {
                  setSelectedDateIndex(-1);
                } else {
                  setSelectedDateIndex(index);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;
