import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile as faFreeSolidFaFile,
  faMobileScreenButton,
} from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { Dialog } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import { apiRequest } from '../../../../../api/api';
import Table from '../../../../../framework-components/table/Table';
import {
  CombinedFilters,
  TableData,
} from '../../../../../framework-components/table/types';
import {
  formatDateTimeShorthandMonthDayYear,
  formatDateToReadableStringShorthandMonth,
} from '../../../../../utils/dates';
import { Option } from '../../../../../types';
import Tooltip from '../../../../../framework-components/tooltip/Tooltip';
import CheckListIcon from './CheckListIcon';
import Modal from '../../../../modal';
import useModalState from '../../../../../hooks/modals';
import { SecondaryButton } from '../../../../../framework-components/button/Button';
import DietaryReqsModal from './DietaryReqsModal';

const DIETARY_REQS_LABELS = {
  VEGETARIAN: 'Vegetarian',
  VEGAN: 'Vegan',
  PESCATARIAN: 'Pescatarian',
  HALAL: 'Halal',
  GLUTEN_FREE: 'Gluten Free',
  FOOD_ALLERGY: 'Food Allergy',
};

type DietaryRequirements = keyof typeof DIETARY_REQS_LABELS;

interface ArrivalListData {
  id: string;
  applicant_name: string;
  applicant: {
    dietary_requirements_option: DietaryRequirements;
    dietary_requirements_extra_info: string;
    local_phone: string;
  };
  destination: {
    id: string;
    name: string;
  };
  start_date: string;
  flight_arrival: string;
  flight_arrival_number: string;
  independant_travel_arrival: string;
  independant_travel_arrival_notes: string;
  booking_houses: {
    accomodation_type: {
      name: string;
    };
    start_date: string;
    end_date: string;
  }[];
  discipline: {
    id: string;
    name: string;
  };
  wildlife_safari_added: boolean;
  placement_types: string[];
  cv_url?: string;
  letter_of_employment_url?: string;
}

interface ArrivalFilters extends Record<string, Option[]> {
  destination: Option[];
  discipline: Option[];
  placement_type: Option[];
  house_arrival: Option[];
  has_wildlife_safari: Option[];
}

const DietaryReqsModalButton: React.FC<{
  dietary_requirements_extra_info: string;
}> = ({ dietary_requirements_extra_info }) => {
  const { isOpen, openModal, closeModal } = useModalState();
  return (
    <>
      <p
        className="text-sm text-blue-500 font-semibold cursor-pointer print:hidden"
        onClick={openModal}
      >
        View details
      </p>
      <DietaryReqsModal
        isOpen={isOpen}
        onClose={closeModal}
        dietary_requirements_extra_info={dietary_requirements_extra_info}
      />
    </>
  );
};

const ApplicantArrivals: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, openModal, closeModal } = useModalState();
  const getArrivalListData = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<TableData<ArrivalListData>>(
      '/api/admin/bookings/arrivals/list/?' +
        new URLSearchParams(selectedFilters).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const getFilterOptions = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<ArrivalFilters>(
      '/api/admin/bookings/arrivals/filters/?' +
        new URLSearchParams(selectedFilters).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const renderLetterOfEmploymentAction = (data: ArrivalListData) => {
    return data.letter_of_employment_url ? (
      <a
        href={data.letter_of_employment_url}
        title="Download Letter of Employment"
      >
        <FontAwesomeIcon icon={faFreeSolidFaFile} className="is-enabled" />
      </a>
    ) : (
      <FontAwesomeIcon icon={faFile} />
    );
  };

  const renderDownloadableCVAction = (data: ArrivalListData) => {
    const icon = <CheckListIcon checked={Boolean(data.cv_url)} />;

    if (!data.cv_url) {
      return icon;
    }

    return (
      <a href={data.cv_url} title="Download CV">
        {icon}
      </a>
    );
  };

  const renderLocalPhoneNumberAction = (data: ArrivalListData) => {
    const props = Object.assign({
      title: undefined,
      icon: faMobileScreenButton,
      className: data.applicant.local_phone ? 'is-enabled' : '',
    });

    return (
      <>
        <FontAwesomeIcon {...props} onClick={openModal} />
        {data.applicant.local_phone && (
          <Modal isOpen={isOpen} onClose={closeModal}>
            <div className="space-y-4">
              <Dialog.Title className="text-2xl font-bold">
                Local Mobile Number
              </Dialog.Title>
              <div className="inline-flex w-full justify-items-center justify-between gap-4 mt-4 mb-4">
                <div className="place-content-center">
                  {data.applicant.local_phone}
                </div>
                <SecondaryButton
                  label="Close"
                  onClick={() => {
                    closeModal();
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  };

  const renderAdditionalActions = (data: ArrivalListData) => {
    return (
      <div className="flex space-x-4 arrivals-actions items-center">
        {renderLocalPhoneNumberAction(data)}
        {renderDownloadableCVAction(data)}
        {renderLetterOfEmploymentAction(data)}
      </div>
    );
  };

  const navigateToHospitalBadges = (rows: ReadonlyArray<ArrivalListData>) => {
    const bookingIds = rows.map((item) => item.id);
    navigate(
      `/hospital-badges?bookingIds=${encodeURIComponent(bookingIds.join(','))}`
    );
  };

  const renderTableActions = (rows: ReadonlyArray<ArrivalListData>) => {
    return (
      <SecondaryButton
        label="Print Hospital Badges"
        onClick={() => navigateToHospitalBadges(rows)}
      />
    );
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: '/admin/bookings/pending',
        },
        {
          label: 'Arrivals',
          href: null,
        },
      ]}
      title="Arrivals"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: 'Arrivals',
              href: '/admin/bookings/arrivals',
              current: true,
            },
            {
              label: 'Departures',
              href: '/admin/bookings/departures',
              current: false,
            },
          ]}
          variant="pilled_tab"
        />
      </div>
      <Table
        getData={getArrivalListData}
        getFilterOptions={getFilterOptions}
        enablePagination
        enableSearch
        canPrint
        columnsConfig={{
          applicant: {
            key: 'applicant',
            header: 'Name',
            enableSort: true,
            renderCell: (data) => (
              <a
                href={`/admin/bookings/${data.id}/applicant-profile/`}
                title={data.applicant_name}
              >
                {data.applicant_name}
              </a>
            ),
          },
          destination: {
            key: 'destination',
            header: 'Destination',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (data) => data.destination.name,
          },
          flight_details: {
            key: 'flight_arrival',
            header: 'Flight details',
            enableSort: true,
            renderCell: (data) => {
              const arrival = data.flight_arrival
                ? formatDateTimeShorthandMonthDayYear(data.flight_arrival)
                : '';
              const number = data.flight_arrival_number
                ? data.flight_arrival_number
                : '';
              const tooltipId = `flight-arrival-${data.id}`;
              return data.independant_travel_arrival ? (
                <p
                  data-tooltip-id={tooltipId}
                  data-tooltip-content={data.independant_travel_arrival_notes}
                >
                  Alternative transport
                  <Tooltip id={tooltipId} />
                </p>
              ) : (
                `${arrival} ${number}`
              );
            },
          },
          house_arrival: {
            key: 'house_arrival',
            header: 'House arrival',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (data) => {
              const house =
                data.booking_houses && data.booking_houses.length > 0
                  ? data.booking_houses[0]
                  : null;
              return house
                ? formatDateToReadableStringShorthandMonth(house.start_date)
                : '';
            },
          },
          placement_types: {
            key: 'placement_type',
            header: 'Placement type',
            filterType: 'local-dropdown',
            renderCell: (data) => (
              <div>
                {data.placement_types.map((placement) => (
                  <p key={placement} title={placement}>
                    {placement}
                  </p>
                ))}
              </div>
            ),
          },
          discipline: {
            key: 'discipline',
            header: 'Discipline',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (data) => data.discipline.name,
          },
          wildlife_safari_added: {
            key: 'wildlife_safari_added',
            header: 'Wildlife Safari Added',
            filterType: 'local-dropdown',
            renderCell: (data) => (
              <div>
                <p>{data.wildlife_safari_added ? 'Yes' : 'No'}</p>
              </div>
            ),
          },
          dietary_requirements_option: {
            key: 'dietary_requirements_option',
            header: 'Dietary requirements',
            renderCell: (data) => {
              return (
                <div className="flex flex-col">
                  {data.applicant.dietary_requirements_option
                    ? DIETARY_REQS_LABELS[
                        data.applicant.dietary_requirements_option
                      ]
                    : ''}
                  {data.applicant.dietary_requirements_extra_info && (
                    <DietaryReqsModalButton
                      dietary_requirements_extra_info={
                        data.applicant.dietary_requirements_extra_info
                      }
                    />
                  )}
                  <div className="hidden print:block text-xs">
                    <p>{data.applicant.dietary_requirements_extra_info}</p>
                  </div>
                </div>
              );
            },
          },
          actions: {
            key: 'actions',
            header: 'Actions',
            renderCell: renderAdditionalActions,
          },
        }}
        additionalFiltersConfig={{
          year: {
            key: 'year',
            label: 'Year',
            filterType: 'local-dropdown',
          },
          month: {
            key: 'month',
            label: 'Month',
            filterType: 'local-dropdown',
          },
        }}
        renderTableActions={renderTableActions}
        pageSize={8}
      />
    </AdminStandardPage>
  );
};

export default ApplicantArrivals;
