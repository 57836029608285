import React, { useCallback } from 'react';

import { apiRequest, useAPI } from '../../../api/api';
import LoadingOverlay from '../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../common/error/ErrorOverlay';
import Table from '../../../framework-components/table/Table';
import {
  CombinedFilters,
  TableData,
} from '../../../framework-components/table/types';
import { formatDateTimeShorthandMonthDayYear } from '../../../utils/dates';
import EmailMessageModal from './EmailMessageModal';
import useModalState from '../../../hooks/modals';

interface EmailLogTableData {
  id: string;
  subject: string;
  recipients: string;
  message_sent_at: string;
  message: string;
}

const SubjectCell: React.FC<{ email: EmailLogTableData }> = ({ email }) => {
  const { isOpen, openModal, closeModal } = useModalState();
  return (
    <>
      <EmailMessageModal
        isOpen={isOpen}
        closeModal={closeModal}
        message={email.message}
      />
      <button onClick={openModal} className="text-blue-500 underline">
        {email.subject}
      </button>
    </>
  );
};

const EmailLogTable: React.FC = () => {
  const { data, error, isValidating } = useAPI<EmailLogTableData>(
    '/api/admin/emails/outgoing-emails'
  );

  const getEmailLogData = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<TableData<EmailLogTableData>>(
      '/api/admin/emails/outgoing-emails?' +
        new URLSearchParams(selectedFilters).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  return (
    <div>
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      {data && (
        <Table<EmailLogTableData>
          columnsConfig={{
            name: {
              key: 'subject',
              header: 'Subject',
              enableSort: true,
              renderCell: (email) => <SubjectCell email={email} />,
            },
            recipients: {
              key: 'recipients',
              header: 'Recipients',
              enableSort: true,
              renderCell: (email) => email.recipients,
            },
            message_sent_at: {
              key: 'message_sent_at',
              header: 'Timestamp',
              enableSort: true,
              renderCell: (email) =>
                formatDateTimeShorthandMonthDayYear(email.message_sent_at),
            },
          }}
          getData={getEmailLogData}
          enablePagination
          enableSearch
        />
      )}
    </div>
  );
};

export default EmailLogTable;
