import React from 'react';
import { Link } from 'react-router-dom';

import ForgotPasswordForm from './forgot-password-form';
import LoginWidgetContainer from '../my-trip/components/LoginWidgetContainer';

const ForgotPassword: React.FC = () => (
  <div className="flex items-center justify-center sm:mt-6">
    <LoginWidgetContainer>
      <div className="space-y-8 w-full md:w-1/2 px-6 py-8">
        <ForgotPasswordForm />
        <p className="text-center">
          <Link
            className="text-blue-600 underline hover:text-blue-900"
            to="/login"
          >
            Back to login
          </Link>
        </p>
      </div>
    </LoginWidgetContainer>
  </div>
);

export default ForgotPassword;
