import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import ManageMultipleObjects from '../../../manage-multiple-objects/ManageMultipleObjects';

interface Props {
  bookingId?: string;
}

interface Document {
  category: string;
  name: string;
  downloaded: boolean;
}

const ApplicantDownloadDocumentsSection: React.FC<Props> = ({ bookingId }) => {
  return (
    <ManageMultipleObjects<Document>
      title="Documents"
      getUrl={`/api/admin/bookings/${bookingId}/get-documents/`}
      tableFields={[
        {
          name: 'category',
          header: 'Category',
          render: (item) => item.category,
        },
        {
          name: 'name',
          header: 'Name',
          render: (item) => item.name,
        },
        {
          name: 'downloaded',
          header: 'Downloaded?',
          render: (item) => {
            if (item.downloaded) {
              return <FontAwesomeIcon icon={faCheck} />;
            }

            return <FontAwesomeIcon icon={faXmark} />;
          },
        },
      ]}
      quickFormFields={[]}
      hideManageButton
      noDataFoundMessage="No documents found"
    />
  );
};

export default ApplicantDownloadDocumentsSection;
