import React from 'react';

import ManageSingleObject from '../../manage-single-object/ManageSingleObject';
import { apiRequest } from '../../../api/api';
import HtmlRenderer from '../../../framework-components/html-renderer/HtmlRenderer';

interface Props {
  canEdit: boolean | null;
  userId: string;
  role: string;
}

interface PublicDescriptionData {
  description: string;
}

const PublicDescription: React.FC<Props> = ({ canEdit, userId }) => {
  const updateDescription = (description_to_submit: string) => {
    const url = `/api/admin/staff/${userId}/description/set/`;
    return apiRequest<PublicDescriptionData>(url, {
      method: 'POST',
      body: JSON.stringify({ description: description_to_submit }),
    });
  };

  return (
    <ManageSingleObject
      title="Public Description"
      titleClassName="pb-4 text-sh2"
      modalTitle="Edit public description"
      getUrl={`/api/admin/staff/${userId}/description/get/`}
      simple
      descriptionFields={[
        {
          label: 'Description',
          render: (data) => <HtmlRenderer htmlString={data.description} />,
        },
      ]}
      formFields={[
        {
          type: 'richtext',
          name: 'description',
          label: 'Description',
        },
      ]}
      editButton={canEdit ? canEdit : false}
      submitItem={(data: PublicDescriptionData) => {
        return updateDescription(data.description);
      }}
    />
  );
};

export default PublicDescription;
