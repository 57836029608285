import React from 'react';
import classNames from 'classnames';

import WidgetContainer from '../components/WidgetContainer';
import { Article } from './types';
import ArticleButton from './ArticleButton';

interface Props {
  bookingId: string;
  title: string;
  articles: Article[];
}

const InfoPackSection: React.FC<Props> = ({ bookingId, title, articles }) => {
  return (
    <WidgetContainer hasBorder hasShadow className="pb-7 mb-3">
      <h4 className="text-mob-h4 lg:text-sh2 pt-5 lg:pt-4 pb-3 text-center">
        {title}
      </h4>
      <div
        className={classNames('flex flex-wrap gap-4 mt-4', {
          'justify-center': articles.length === 1,
          'justify-center sm:justify-start': articles.length !== 1,
        })}
      >
        {articles.map((article) => (
          <ArticleButton
            key={article.pk}
            bookingId={bookingId}
            article={article}
            sectionTitle={title}
          />
        ))}
      </div>
    </WidgetContainer>
  );
};

export default InfoPackSection;
