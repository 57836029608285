import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { mutate } from 'swr';

import { Option } from '../../../../types';
import { useAPI } from '../../../../api/api';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import LoadingOverlay from '../../../my-trip/components/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import { PrimaryButton } from '../../../my-trip/components/Button';
import { useSubmitFormToAPI } from '../../../../api/forms';
import LocalDropdown from '../../../../framework-components/dropdown/LocalDropdown';

interface FormValues {
  sections: Option<string>[];
}

function useOnSubmit(
  enquiryId: string
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI(
    `/api/admin/enquiries/${enquiryId}/set-desired-destinations/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data.sections, formHelpers);
      if (hasSucceeded) {
        toast.success('Desired destinations updated');
        mutate(`/api/admin/enquiries/${enquiryId}/get-desired-destinations/`);
      }
      if (!hasSucceeded) {
        toast.error('Failed to update desired destinations');
      }
    },
    [submitForm, enquiryId]
  );
}

interface Props {
  enquiryId: string;
}

interface SectionProps {
  index: number;
  setFieldValue: (
    field: string,
    value: Option<string>,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>;
  enquiryId: string;
  arrayHelpers: FieldArrayRenderProps;
  values: FormValues;
  dropdownData: Option<string>[] | undefined;
  isDropdownValidating: boolean;
}

const Section: React.FC<SectionProps> = ({
  index,
  setFieldValue,
  enquiryId,
  arrayHelpers,
  values,
  dropdownData,
  isDropdownValidating,
}) => {
  return (
    <div key={index} className="mb-4">
      <div className="flex flex-col w-full sm:flex-row">
        <div className="pb-3 md:pb-0 w-full pr-3">
          <label
            htmlFor="desiredDestination"
            className="block text-sm font-medium leading-6 text-gray-900 sm:w-1/2"
          >
            Destination:
          </label>
          <LocalDropdown
            isLoading={isDropdownValidating}
            options={dropdownData}
            onSelect={(option: Option<string> | null) => {
              if (option && dropdownData) {
                setFieldValue(`sections[${index}]`, option);
                mutate(
                  `/api/admin/enquiries/${enquiryId}/get-desired-destinations-options/`,
                  (data: Option[]) => {
                    const oldValue = values.sections[index];
                    const newData = oldValue.value
                      ? [...data, oldValue]
                      : [...data];
                    return newData
                      .filter((item) => item.value !== option.value)
                      .sort((a, b) => {
                        if (a.label < b.label) {
                          return -1;
                        }
                        if (a.label > b.label) {
                          return 1;
                        }
                        return 0;
                      });
                  },
                  false
                );
              }
            }}
            name={`sections[index]`}
            value={values.sections[index]}
          />
        </div>
        <div className="pr-3 sm:align-items-center sm:inline-flex">
          <div className="flex align-center justify-center place-items-center">
            <FontAwesomeIcon
              className="text-wtw-logo-blue mt-3 pl-2 pr-2 hover:cursor-pointer"
              icon={faTrashAlt}
              onClick={() => {
                if (dropdownData) {
                  mutate(
                    `/api/admin/enquiries/${enquiryId}/get-desired-destinations-options/`,
                    (data: Option[]) =>
                      [...data, values.sections[index]].sort((a, b) => {
                        if (a.label < b.label) {
                          return -1;
                        }
                        if (a.label > b.label) {
                          return 1;
                        }
                        return 0;
                      }),
                    false
                  );
                }
                arrayHelpers.remove(index);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DesiredDestinations: React.FC<Props> = ({ enquiryId }) => {
  const {
    data: desiredDestinations,
    isValidating: desiredDestinationsValidating,
    error: desiredDestinationsError,
  } = useAPI<Option<string>[]>(
    `/api/admin/enquiries/${enquiryId}/get-desired-destinations/`
  );

  const { data: dropdownData, isValidating: isDropdownValidating } = useAPI<
    Option<string>[]
  >(`/api/admin/enquiries/${enquiryId}/get-desired-destinations-options/`);

  const onSubmit = useOnSubmit(enquiryId);
  const submitForm = useCallback(
    (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );

  return (
    <AdminWidgetContainer title="Desired Destinations" className="space-y-6">
      {desiredDestinationsValidating && <LoadingOverlay />}
      {desiredDestinationsError && <ErrorOverlay />}

      {desiredDestinations && (
        <Formik<FormValues>
          initialValues={{
            sections: desiredDestinations.length
              ? desiredDestinations
              : [{ label: '', value: '' }],
          }}
          onSubmit={submitForm}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div>
                <FieldArray
                  name="sections"
                  render={(arrayHelpers) => (
                    <div>
                      {values.sections.map((_section, index) => (
                        <Section
                          key={index}
                          index={index}
                          setFieldValue={setFieldValue}
                          enquiryId={enquiryId}
                          arrayHelpers={arrayHelpers}
                          values={values}
                          dropdownData={dropdownData}
                          isDropdownValidating={isDropdownValidating}
                        />
                      ))}
                      <div
                        className="inline-flex justify-items-end my-4 hover:cursor-pointer"
                        onClick={() => {
                          arrayHelpers.push({ label: '', value: '' });
                        }}
                      >
                        <FontAwesomeIcon
                          className="text-wtw-logo-blue text-center pt-1 pr-2"
                          icon={faPlus}
                        />
                        <span className=" flex font-semibold text-wtw-logo-blue">
                          Destination
                        </span>
                      </div>
                    </div>
                  )}
                />
                <div className="flex justify-end w-full">
                  <PrimaryButton label="Save" type="submit" className="mt-4 " />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </AdminWidgetContainer>
  );
};

export default DesiredDestinations;
