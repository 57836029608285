import React from 'react';
import classNames from 'classnames';

import {
  LedgerData,
  LedgerItem,
} from '../bookings/applicant-profile/LedgerTable';
import { formatDateToReadableStringShorthandMonth } from '../../../utils/dates';
import { CURRENCY_TO_SYMBOL } from '../../booking/booking-form/constants';
import { CurrencyType } from '../../booking/booking-form/types';

const Row = ({ item }: { item: LedgerItem }) => {
  const itemType = item.tx_type === 'CREDIT' ? 'charge' : 'payment';
  return (
    <tr>
      <td
        className={classNames(
          {
            'text-green-600': itemType === 'payment',
            'text-red-600': itemType === 'charge',
          },
          'border border-gray-300 px-4 py-2'
        )}
      >
        {itemType}
      </td>
      <td className="border border-gray-300 px-4 py-2">
        {formatDateToReadableStringShorthandMonth(item.created)}
      </td>
      <td className="border border-gray-300 px-4 py-2">{item.reference}</td>
      <td className="border border-gray-300 px-4 py-2 text-right">
        {`${CURRENCY_TO_SYMBOL[item.currency]} ${(
          item.amount_pence / 100
        ).toFixed(2)}`}
      </td>
    </tr>
  );
};

interface Props {
  data: LedgerData;
  currency: CurrencyType;
}

const LedgerTable: React.FC<Props> = ({ data, currency }) => {
  return (
    <table className="w-full border-collapse border border-gray-300 mb-8">
      <thead>
        <tr className="bg-gray-100">
          <th className="border border-gray-300 px-4 py-2 text-left">TYPE</th>
          <th className="border border-gray-300 px-4 py-2 text-left">DATE</th>
          <th className="border border-gray-300 px-4 py-2 text-left">
            DESCRIPTION
          </th>
          <th className="border border-gray-300 px-4 py-2 text-right">
            AMOUNT
          </th>
        </tr>
      </thead>
      <tbody>
        {data.ledger_items.map((item) => (
          <Row key={item.id} item={item} />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td
            className="border border-gray-300 px-4 py-2 font-bold text-right"
            colSpan={3}
          >
            Balance due
          </td>
          <td className="border border-gray-300 px-4 py-2 text-right">
            {`${CURRENCY_TO_SYMBOL[currency]} ${(
              data.remaining_balance / 100
            ).toFixed(2)}`}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default LedgerTable;
