import React from 'react';

import Toggles from './Toggles';
import BlockTabsExample from './BlockTabsExample';
import Navbars from './Navbars';
import ExampleButtons from './ExampleButtons';
import DescriptionList from './DescriptionList';
import PaginationBar from './PaginationBarExample';
import Inputs from './Inputs';
import TextAreas from './TextArea';
import Tables from './Tables';
import Dropdowns from './Dropdowns';
import Alerts from './Alerts';
import BreadCrumbs from './Breadcrumbs';
import Selects from './Selects';
import StatsExample from './StatsExample';
import QuickFormExample from './QuickFormExample';
import ProgressBars from './ProgressBars';
import FileUploadExample from './FileUploadExample';
import QuickSetDropDownExample from './QuickSetDropdownExample';
import ExpandableRowExample from './ExpandableRowExample';
import ManageSingleObjectExample from './ManageSingleObjectExample';
import ManageMultipleObjectsExample from './ManageMultipleObjectsExample';
import TypographyExample from './TypographyExample';
import ImageGalleryExample from './ImageGalleryExample';
import SearchButtonExample from './SearchButtonExample';
import CardDetailExample from './CardDetailExample';
import NotesPanelExample from './NotesPanelExample';
import InstantSaveCheckboxExample from './InstantSaveCheckboxExample';
import ManageLongTextExample from './ManageLongTextExample';
import Hospitals from './Hospitals';
import ProfileBrowser from '../my-trip/components/profile-browser/ProfileBrowser';
import TabsWithImages from '../my-trip/components/TabsWithImages/TabsWithImages';
import PropertySummary from '../my-trip/components/PropertySummary/PropertySummary';

const StyleGuide: React.FC = () => {
  const tabsData = [
    {
      id: '1',
      label: 'Profile One',
      imageUrl: 'https://picsum.photos/200?1',
      content: () => <div>Content for Profile 1</div>,
    },
    {
      id: '2',
      label: 'Profile Two',
      imageUrl: 'https://picsum.photos/200?2',
      content: () => <div>Content for Profile 2</div>,
    },
    {
      id: '3',
      label: 'Profile Three',
      imageUrl: 'https://picsum.photos/200?3',
      content: () => <div>Content for Profile 3</div>,
    },
    {
      id: '4',
      label: 'Profile Four',
      imageUrl: 'https://picsum.photos/200?4',
      content: () => <div>Content for Profile 4</div>,
    },
  ];

  const galleryImageUrls = () => [
    'https://picsum.photos/200?1',
    'https://picsum.photos/200?2',
    'https://picsum.photos/200?3',
    'https://picsum.photos/200?4',
    'https://picsum.photos/200?5',
    'https://picsum.photos/200?6',
    'https://picsum.photos/200?7',
    'https://picsum.photos/200?8',
  ];

  const contentNextToGallery = (
    <div>
      <h1 className="text-sm-bold mb-2">House details:</h1>
      <p>
        Postal address:
        <strong> 7 Alley Le Lol Street, Hue City, Vietnam</strong>
      </p>
      <p>
        Max occupants:<strong> 44</strong>
      </p>
      <p>
        Bedroom sharing:<strong> 2-6 (max)</strong>
      </p>
      <p>
        Link:<strong> linktosomething.com</strong>{' '}
      </p>
    </div>
  );

  const contentUnderGallery = (
    <div>
      <h1 className="text-sm-bold mb-2">House overview</h1>
      <p>
        Our house in Hue is situated in the hub of the city, only a few minutes
        walk away from the famous Perfume River and Royal Palance. There are
        bars, restaurants, and shops around every corner, and it's only a short
        walk into the centre of Hue. Your placement hospitals are only 20
        minutes away by foot, 5 minutes in a tuk tuk.
      </p>
    </div>
  );

  return (
    <div className="mx-auto max-w-[1000px] my-8 px-8">
      <h1 className="text-4xl mb-8 ">StyleGuide</h1>
      <Navbars />
      <ExampleButtons />
      <Toggles />
      <BlockTabsExample />
      <DescriptionList />
      <PaginationBar />
      <TextAreas />
      <Inputs />
      <Tables />
      <Dropdowns />
      <Selects />
      <Alerts />
      <BreadCrumbs />
      <StatsExample />
      <QuickFormExample />
      <ProgressBars />
      <FileUploadExample />
      <QuickSetDropDownExample />
      <ManageSingleObjectExample />
      <ManageMultipleObjectsExample />
      <TypographyExample />
      <ExpandableRowExample />
      <ImageGalleryExample />
      <SearchButtonExample />
      <CardDetailExample />
      <NotesPanelExample />
      <ManageLongTextExample />
      <InstantSaveCheckboxExample />
      <Hospitals />
      <ProfileBrowser
        title="Profile Browser"
        data={[]}
        defaultImageUrl="https://picsum.photos/200?1"
      />
      <TabsWithImages tabs={tabsData} />
      <PropertySummary
        name="Hue House"
        startDate="2024-6-12"
        endDate="2024-8-12"
        galleryImageUrls={galleryImageUrls}
        contentNextToGallery={contentNextToGallery}
        contentUnderGallery={contentUnderGallery}
      />
    </div>
  );
};

export default StyleGuide;
