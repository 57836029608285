import { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Option } from '../../../types';
import { useAPI } from '../../../api/api';

export const useOutsideAlerter = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void
) => {
  useEffect(() => {
    const handleClickEventHandler = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as HTMLDivElement)
      ) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickEventHandler);
    return () => {
      document.removeEventListener('mousedown', handleClickEventHandler);
    };
  }, [ref, callback]);
};

export const useDestinationTabs = () => {
  const {
    data: destinations,
    isValidating,
    error,
  } = useAPI<ReadonlyArray<Option>>('/api/booking/destinations/');
  const [searchParams, setSearchParams] = useSearchParams();

  const [destination, setDestination] = useState<Option | null>(null);

  useEffect(() => {
    if (destination) {
      searchParams.set('destination', destination.value);
      setSearchParams(searchParams);
    }
    if (!destination && destinations && destinations.length > 0) {
      if (!searchParams.get('destination')) {
        setDestination(destinations[0]);
      } else {
        const dest = destinations.find(
          (d) => d.value === searchParams.get('destination')
        );
        if (dest) {
          setDestination(dest);
        }
      }
    }
  }, [
    destinations,
    setDestination,
    destination,
    searchParams,
    setSearchParams,
  ]);

  const destinationTabs = useMemo(() => {
    if (!destinations) {
      return [];
    }
    return destinations.map((dest: Option) => {
      return {
        label: dest.label,
        onClick: () => setDestination(dest),
        current: dest?.value === destination?.value,
      };
    });
  }, [destinations, destination]);

  return {
    destination,
    setDestination,
    destinationTabs,
    destinations,
    isValidating,
    error,
  };
};
