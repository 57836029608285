import { Option, BookingStatus } from '../../../../types';
import {
  EducationStatusType,
  GenderType,
} from '../../../booking/booking-form/types';

export type EnquiryStatus = 'NEW' | 'ONGOING' | 'ARCHIVED' | BookingStatus;
export type EnquiryPriority = 'LOW' | 'MEDIUM' | 'HIGH';

export const ENQUIRY_STATUS_LABELS: Record<EnquiryStatus, string> = {
  NEW: 'New',
  ONGOING: 'Ongoing',
  ARCHIVED: 'Archived',
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  ON_TRIP: 'On Trip',
  ALUMNI: 'Alumni',
  CANCELLED: 'Cancelled',
  POSTPONED: 'Postponed',
};

export const ENQUIRY_PRIORITY_LABELS: Record<EnquiryPriority, string> = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
};

export interface Archive {
  is_active: boolean;
}

export interface Contacted {
  contacted_at: string | null;
}

export interface GeneralInfo {
  discipline: Option | null;
  start_date: string | null;
  hospital_duration: number | null;
  experience_duration: number | null;
  education_status: Option<EducationStatusType> | null;
}

export interface Assignee {
  id: string;
  first_name: string;
  last_name: string;
}

export interface BasicInfo {
  booking: {
    id: string;
  } | null;
  order_reference: string;
  status: EnquiryStatus;
  first_name: string;
  last_name: string | null;
  contacted_at: string | null;
  priority: EnquiryPriority | null;
  is_active: boolean;
  assignee: Assignee | null;
}

export interface Summary {
  id: string;
  first_name: string;
  last_name: string | null;
  status: EnquiryStatus;
  gender: Option<GenderType> | null;
  country: Option | null;
  phone: string | null;
  email: string | null;
  university: Option | null;
  university_course: string | null;
  year_of_university_study: Option | null;
  year_of_graduation: Option | null;
}
