import { Basis, DateType } from './types';
import { BOOKING_STATUS_LABELS } from '../../../../constants';
import { Option } from '../../../../types';

export const dateTypeOptions: Option<DateType>[] = [
  { value: 'START_DATE', label: 'Placement start' },
  { value: 'CREATED_DATE', label: 'Booking created' },
];

export const statusOptions = Object.entries(BOOKING_STATUS_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const basisOptions: Option<Basis>[] = [
  { value: 'PROFESSIONAL', label: 'Professional' },
  { value: 'WORK_EXPERIENCE', label: 'Work Experience' },
  { value: 'ELECTIVE', label: 'Elective' },
  { value: 'OTHER', label: 'Other' },
];

export const outputColumnOptions: Option<string>[] = [
  { value: 'AGE_AT_BOOKING', label: 'Age at booking' },
  { value: 'AGE_AT_START', label: 'Age at start' },
  { value: 'ARRIVAL_IN_COUNTRY', label: 'Arrival in country' },
  { value: 'BASIS', label: 'Basis' },
  { value: 'COUNTRY_OF_STUDY', label: 'Country of study' },
  { value: 'COURSE', label: 'Course' },
  { value: 'CURRENCY', label: 'Currency' },
  { value: 'DATE_REG_FEE_PAID', label: 'Date Reg. Fee paid' },
  { value: 'DATE_OF_BIRTH', label: 'Date of birth' },
  { value: 'DATE_OF_ENQUIRY', label: 'Date of enquiry' },
  { value: 'DEPARTURE_FROM_COUNTRY', label: 'Departure from country' },
  { value: 'DESTINATION', label: 'Destination' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'FIRST_NAME', label: 'First Name' },
  { value: 'LAST_NAME', label: 'Last Name' },
  { value: 'LENGTH', label: 'Length (wks)' },
  { value: 'MARKET', label: 'Market' },
  { value: 'PLACEMENT_END', label: 'Placement end' },
  { value: 'PLACEMENT_START', label: 'Placement start' },
  { value: 'SELLER', label: 'Seller' },
  { value: 'SOURCE', label: 'Source' },
  { value: 'STATUS', label: 'Status' },
  { value: 'TELEPHONE', label: 'Telephone' },
  { value: 'TOTAL_COST', label: 'Total Cost' },
  { value: 'TOTAL_PAID', label: 'Total Paid' },
  { value: 'TOTAL_DAYS_IN_COUNTRY', label: 'Total days in country' },
  { value: 'UNIVERSITY', label: 'University' },
  { value: 'YEAR_OF_STUDY', label: 'Year of study' },
];
