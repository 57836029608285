import React from 'react';

import TwoColumns from '../../../common/layout/TwoColumns';
import LocalOfficeDetails from './LocalOfficeDetails';
import LocalContacts from './LocalContacts';
import LocalMedicalDetails from './LocalMedicalDetails';

interface Props {
  destinationId?: string;
}

const LocalDetails: React.FC<Props> = ({ destinationId }) => {
  return (
    <TwoColumns
      leftColumn={
        <>
          <LocalOfficeDetails destinationId={destinationId} />
          <LocalContacts destinationId={destinationId} />
        </>
      }
      rightColumn={
        <>
          <LocalMedicalDetails destinationId={destinationId} />
        </>
      }
    />
  );
};

export default LocalDetails;
