import React from 'react';

const EditIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.414 0.835817C15.0389 0.460875 14.5303 0.250244 14 0.250244C13.4697 0.250244 12.9611 0.460875 12.586 0.835817L5 8.42182V11.2498H7.828L15.414 3.66382C15.7889 3.28876 15.9996 2.78015 15.9996 2.24982C15.9996 1.71949 15.7889 1.21087 15.414 0.835817Z"
        fill="#2568EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.24982C0 3.71938 0.210714 3.21068 0.585786 2.8356C0.960859 2.46053 1.46957 2.24982 2 2.24982H6C6.26522 2.24982 6.51957 2.35517 6.70711 2.54271C6.89464 2.73025 7 2.9846 7 3.24982C7 3.51503 6.89464 3.76939 6.70711 3.95692C6.51957 4.14446 6.26522 4.24982 6 4.24982H2V14.2498H12V10.2498C12 9.9846 12.1054 9.73025 12.2929 9.54271C12.4804 9.35517 12.7348 9.24982 13 9.24982C13.2652 9.24982 13.5196 9.35517 13.7071 9.54271C13.8946 9.73025 14 9.9846 14 10.2498V14.2498C14 14.7802 13.7893 15.289 13.4142 15.664C13.0391 16.0391 12.5304 16.2498 12 16.2498H2C1.46957 16.2498 0.960859 16.0391 0.585786 15.664C0.210714 15.289 0 14.7802 0 14.2498V4.24982Z"
        fill="#2568EB"
      />
    </svg>
  );
};

export default EditIcon;
