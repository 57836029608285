import React, { useState } from 'react';

import Profile from './Profile';
import { HouseStaff, Housemate } from '../../trip-summary/types';

interface ProfileBrowser {
  title: string;
  data: (HouseStaff | Housemate)[] | undefined;
  defaultImageUrl: string;
}

const ProfileBrowser: React.FC<ProfileBrowser> = ({
  title,
  data,
  defaultImageUrl,
}) => {
  const [renderRemainingProfiles, setRenderRemainingProfiles] = useState(false);
  const [viewAllButtonVisible, setViewAllButtonVisible] = useState(true);

  const DEFAULT_PROFILES_TO_SHOW_LIMIT = 7;

  const displayedProfiles = data?.slice(0, DEFAULT_PROFILES_TO_SHOW_LIMIT);

  const remainingProfiles = data?.slice(DEFAULT_PROFILES_TO_SHOW_LIMIT);

  const profileIsStaff = (
    profile: HouseStaff | Housemate
  ): profile is HouseStaff => {
    return 'role' in profile;
  };

  const profileIsHousemate = (
    profile: HouseStaff | Housemate
  ): profile is Housemate => {
    return !('role' in profile);
  };

  const renderProfile = (profile: HouseStaff | Housemate) => {
    const isStaff = profileIsStaff(profile);
    const isHousemate = profileIsHousemate(profile);

    let imageUrl = defaultImageUrl;

    if (isStaff && profile.public_facing_picture) {
      imageUrl = profile.public_facing_picture;
    } else if (!isStaff && isHousemate) {
      imageUrl = profile.house_photo ? profile.house_photo : defaultImageUrl;
    }

    return (
      <div key={profile.name} className="w-[180px] md:w-auto">
        <Profile
          key={profile.name}
          name={profile.name}
          role={isStaff ? profile.role : null}
          imageUrl={imageUrl}
          description={isStaff ? profile.description : null}
          isStaff={isStaff}
          isHousemate={isHousemate}
        />
      </div>
    );
  };

  return (
    <div className="relative flex flex-col mt-6">
      <h1 className="text-sh4 mb-4">{title}</h1>
      <div className="flex flex-wrap gap-6">
        {displayedProfiles?.length === 0 && (
          <p>
            You haven't got any housemates yet, check back later for updates
          </p>
        )}
        {displayedProfiles?.map((profile) => renderProfile(profile))}
        {renderRemainingProfiles &&
        remainingProfiles &&
        remainingProfiles?.length > 0 ? (
          remainingProfiles?.map((profile) => renderProfile(profile))
        ) : (
          <></>
        )}
      </div>
      <div>
        {displayedProfiles &&
          displayedProfiles.length > 0 &&
          remainingProfiles &&
          remainingProfiles.length > 0 &&
          viewAllButtonVisible && (
            <button
              onClick={() => {
                setRenderRemainingProfiles(true);
                setViewAllButtonVisible(false);
              }}
              className="text-md-bold text-blue-600 mt-4"
            >
              View all
            </button>
          )}
      </div>
    </div>
  );
};

export default ProfileBrowser;
