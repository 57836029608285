import classNames from 'classnames';
import React from 'react';

import { CurrencyType } from '../../booking/booking-form/types';
import { CURRENCY_TO_SYMBOL } from '../../booking/booking-form/constants';
import { TxType } from '../../../types';

export interface FinancesToDateRowProps {
  id: string;
  reference: string;
  amount_pence: string;
  currency: CurrencyType;
  tx_type: TxType;
}

const FinancesToDateRow: React.FC<FinancesToDateRowProps> = ({
  reference,
  amount_pence,
  tx_type,
  currency,
}) => {
  const amountInPounds = (Number(amount_pence) / 100).toFixed(2);

  return (
    <div
      className={classNames('rounded-lg mb-4 flex justify-between px-5 py-2', {
        'bg-red-200': tx_type === 'CREDIT',
        'bg-green-200': tx_type === 'DEBIT',
      })}
    >
      <h1 className="text-sm">{reference}</h1>
      <h1 className="text-sm">
        {tx_type === 'CREDIT'
          ? `${CURRENCY_TO_SYMBOL[currency]}${amountInPounds}`
          : `- ${CURRENCY_TO_SYMBOL[currency]}${amountInPounds}`}
      </h1>
    </div>
  );
};

export default FinancesToDateRow;
