import React, { useState, useMemo } from 'react';
import classNames from 'classnames';

import { PrimaryButton } from '../Button';
import { Booking } from '../../context-providers';
import {
  CURRENCY_TO_SYMBOL,
  SAFARI_PRICE,
} from '../../../booking/booking-form/constants';
import SafariContent from './SafariContent';
import useModalState from '../../../../hooks/modals';
import { Addon } from './types';
import AddModal from './AddModal';
import { addSafari } from './actions';
import StandardContainer from '../../layout/StandardContainer';

interface AvailableAddonProps {
  activeBooking: Booking;
}

const AvailableAddons: React.FC<AvailableAddonProps> = ({ activeBooking }) => {
  const [selectedAddon, setSelectedAddon] = useState(0);
  const { isOpen, openModal, closeModal } = useModalState();

  const price = `${CURRENCY_TO_SYMBOL[activeBooking.currency]}${
    SAFARI_PRICE[activeBooking.currency]
  }`;

  const addons: Addon[] = useMemo(
    () => [
      {
        id: '1',
        label: 'Safari',
        imageUrl: 'https://picsum.photos/200?2',
        content: <SafariContent />,
        price: `Price: ${price}`,
        addToBooking: addSafari,
      },
    ],
    [price]
  );

  if (!activeBooking.destination.has_wildlife_safari) {
    return null;
  }

  return (
    <div>
      <h1 className="text-mob-sh2 mb-4 md:text-sh2">Available add-ons</h1>
      <div className="flex flex-col md:flex-row gap-8">
        {addons.map((addon, idx) => (
          <div
            key={`addon-${addon.id}`}
            onClick={() => setSelectedAddon(idx)}
            className={classNames(
              'flex flex-col items-center justify-start gap-2 w-[500px]',
              {
                'opacity-50': selectedAddon !== idx,
              }
            )}
          >
            <img
              src={addon.imageUrl}
              className={classNames('rounded-lg border-2 h-[160px] w-[200px]', {
                'border-2 border-blue-500': selectedAddon === idx,
              })}
            />
            <h3 className="font-bold">{addon.label}</h3>
            {selectedAddon === idx && (
              <PrimaryButton
                label={
                  activeBooking.wildlife_safari_added
                    ? 'Already added'
                    : 'Add to booking'
                }
                type="button"
                onClick={() => {
                  if (!activeBooking.wildlife_safari_added) {
                    openModal();
                  }
                }}
                isDisabled={activeBooking.wildlife_safari_added}
              />
            )}
            <AddModal
              isOpen={isOpen}
              closeModal={closeModal}
              addon={addon}
              bookingId={activeBooking.id}
              price={price}
            />
          </div>
        ))}
        <StandardContainer variant="white">
          {addons[selectedAddon].content}
        </StandardContainer>
      </div>
    </div>
  );
};

export default AvailableAddons;
