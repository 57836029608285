import { apiRequest } from '../../../api/api';
import {
  DietaryRequirementsData,
  ContactDetails,
  Education,
  EmergencyContact,
  TShirtSizeData,
  Notes,
  MyHousePhoto,
  MyIdPhoto,
  MyPassportPhoto,
} from './types';
import { FileUpload, Image } from '../common/types';

export const updateDietaryRequirements = (
  bookingId: string,
  data: DietaryRequirementsData
) => {
  const url = `/api/my-trip/about-me/${bookingId}/dietary-requirements/set/`;
  return apiRequest<DietaryRequirementsData>(url, {
    method: 'POST',
    body: JSON.stringify({
      dietary_requirements_option:
        typeof data.dietary_requirements_option === 'object'
          ? data.dietary_requirements_option?.value
          : data.dietary_requirements_option,
      dietary_requirements_extra_info:
        data.dietary_requirements_extra_info || '',
    }),
  });
};

export const updateContactDetails = (
  bookingId: string,
  contact_details: ContactDetails
) => {
  const url = `/api/my-trip/about-me/${bookingId}/contact-details/set/`;
  return apiRequest<ContactDetails>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...contact_details,
      address: {
        ...contact_details.address,
        country: contact_details.address.country?.value,
      },
    }),
  });
};

export const updateEducation = (bookingId: string, education: Education) => {
  const url = `/api/my-trip/about-me/${bookingId}/education/set/`;
  return apiRequest<Education>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...education,
      university: education.university?.value,
      year_of_university_study:
        typeof education.year_of_university_study === 'object'
          ? education.year_of_university_study?.value
          : education.year_of_university_study,
      year_of_graduation:
        typeof education.year_of_graduation === 'object'
          ? education.year_of_graduation?.value
          : education.year_of_graduation,
    }),
  });
};

export const updateEmergencyContact = (
  bookingId: string,
  contact_details: EmergencyContact
) => {
  const url = `/api/my-trip/about-me/${bookingId}/emergency-contact/set/`;
  return apiRequest<EmergencyContact>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...contact_details,
      address: {
        ...contact_details.address,
        country: contact_details.address?.country?.value,
      },
    }),
  });
};

export const updateTShirtSize = (bookingId: string, data: TShirtSizeData) => {
  const url = `/api/my-trip/about-me/${bookingId}/tshirt-size/set/`;
  return apiRequest<TShirtSizeData>(url, {
    method: 'POST',
    body: JSON.stringify({
      tshirt_size:
        typeof data.tshirt_size === 'object'
          ? data.tshirt_size?.value
          : data.tshirt_size,
    }),
  });
};

export const updateNotes = (bookingId: string, data: Notes) => {
  const url = `/api/my-trip/about-me/${bookingId}/notes/set/`;
  return apiRequest<Notes>(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const setIdPhotoImage = async (bookingId: string, fileId: string) => {
  const url = `/api/applicant/${bookingId}/my-account/set-id-photo/`;
  return apiRequest<Image>(url, {
    method: 'POST',
    body: JSON.stringify({ file: fileId }),
  });
};

export const setHousePhoto = async (bookingId: string, fileId: string) => {
  const url = `/api/applicant/${bookingId}/my-account/set-house-photo/`;
  return apiRequest<Image>(url, {
    method: 'POST',
    body: JSON.stringify({ file: fileId }),
  });
};

export const getMyIdPhoto = async (bookingId: string) => {
  return apiRequest<MyIdPhoto>(
    `/api/applicant/${bookingId}/my-account/get-id-photo/`
  );
};

export const getMyHousePhoto = async (bookingId: string) => {
  return apiRequest<MyHousePhoto>(
    `/api/applicant/${bookingId}/my-account/get-house-photo/`
  );
};

export const getMyPassportPhoto = async (bookingId: string) => {
  return apiRequest<MyPassportPhoto>(
    `/api/applicant/${bookingId}/my-account/get-passport-photo/`
  );
};

export const setMyPassportPhoto = async (bookingId: string, fileId: string) => {
  const url = `/api/applicant/${bookingId}/my-account/set-passport-photo/`;
  return apiRequest<Image>(url, {
    method: 'POST',
    body: JSON.stringify({ file: fileId }),
  });
};

export const setCVUpload = async (bookingId: string, fileId: string) => {
  const url = `/api/my-trip/about-me/${bookingId}/documents/cv/set/`;
  return apiRequest<FileUpload>(url, {
    method: 'POST',
    body: JSON.stringify({ file: fileId }),
  });
};

export const setLetterOfEmploymentUpload = async (
  bookingId: string,
  fileId: string
) => {
  const url = `/api/my-trip/about-me/${bookingId}/documents/letter/set/`;
  return apiRequest<FileUpload>(url, {
    method: 'POST',
    body: JSON.stringify({ file: fileId }),
  });
};
