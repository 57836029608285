import React from 'react';
import classNames from 'classnames';

import AdminWidgetContainer, { CardProps } from './AdminWidgetContainer';
import withEditableProps from '../../context-providers/withEditableProps';

type PickedCardProps = Pick<
  CardProps,
  'className' | 'hasShadow' | 'backgroundColor'
>;

interface CardDetailProps extends PickedCardProps {
  data?: Record<string, string | React.ReactNode | undefined>;
  imageSource?: string;
  imageAlt?: string;
  labelWidth?: string;
  title?: string;
}

const CardDetail: React.FC<CardDetailProps> = (props) => {
  const { data, labelWidth, ...cardProps } = props;

  const renderData = () => {
    if (!data) {
      return null;
    }

    return (
      <div
        className={classNames(
          'flex flex-col w-full h-full lg:flex-row lg:items-center',
          { 'pt-3': props.title && !props.imageSource },
          { 'pt-6': props.title && props.imageSource }
        )}
      >
        {props.imageSource && (
          <img
            src={props.imageSource}
            alt={props.imageAlt || 'Card image'}
            className=" aspect-square w-[90px] h-[90px] rounded-full object-cover"
          />
        )}
        <div
          className={classNames('flex flex-col w-full', {
            'lg:pl-6': props.imageSource,
          })}
        >
          {Object.entries(data).map(([key, value]) => {
            return (
              <div key={key} className="flex mb-1">
                <div
                  className={classNames('text-sm flex-shrink-0', labelWidth)}
                >
                  {key}:
                </div>
                <div className="text-sm-bold pl-2 break-all">
                  {value || '-'}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <AdminWidgetContainer
      fullHeight
      className={classNames({
        'h-full': !props.title,
      })}
      {...cardProps}
    >
      {renderData()}
    </AdminWidgetContainer>
  );
};

type EditableProps = Pick<
  CardDetailProps,
  'data' | 'imageSource' | 'hasShadow'
>;

export const StyleGuideCardDetail = withEditableProps<EditableProps>(
  CardDetail,
  {
    imageSource: {
      displayName: 'Image',
      type: 'text',
      defaultValue: 'https://picsum.photos/id/237/200/300',
    },
    hasShadow: {
      displayName: 'hasShadow',
      type: 'boolean',
      defaultValue: true,
    },
  }
);
export default CardDetail;
