import React from 'react';

import ManageMultipleObjects from '../manage-multiple-objects/ManageMultipleObjects';
import { FieldType } from '../../framework-components/quickform/Field';

interface Item {
  id: string;
  name: string;
  location: string;
  capacity: number;
  description: string;
}

const tableFields = [
  {
    name: 'name',
    header: 'Name',
    render: (item: Item) => item['name'],
  },
  {
    name: 'location',
    header: 'Location',
    render: (item: Item) => item['location'],
  },
  {
    name: 'capacity',
    header: 'Capacity',
    render: (item: Item) => item['capacity'],
  },
];

const quickformFields: ReadonlyArray<FieldType> = [
  {
    type: 'smalltext',
    name: 'name',
    label: 'Name',
  },
  {
    type: 'smalltext',
    name: 'location',
    label: 'Location',
  },
  {
    type: 'smalltext',
    name: 'capacity',
    label: 'Capacity',
    extraProps: { type: 'number' },
  },
  {
    type: 'largetext',
    name: 'description',
    label: 'Description',
    extraProps: { placeholder: 'Enter a description', rows: 6 },
  },
];

const addHandler = async (values: Item) => {
  console.log('adding', values); // eslint-disable-line no-console
  return Promise.resolve(values);
};

const editHandler = async (values: Item) => {
  console.log('editing', values); // eslint-disable-line no-console
  return Promise.resolve(values);
};

const deleteHandler = async (values: Item) => {
  console.log('deleting', values); // eslint-disable-line no-console
  return Promise.resolve(values);
};

const ManageMultipleObjectsExample: React.FC = () => {
  return (
    <div className="space-y-4 my-8">
      <h2 className="text-2xl font-bold">Manage Multiple Objects</h2>
      <div className="p-4 border border-gray-200 rounded-md">
        <ManageMultipleObjects<Item>
          title="Rooms"
          tableFields={tableFields}
          quickFormFields={quickformFields}
          getUrl="/api/example_managemultipleobjects/"
          addHandler={addHandler}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          noDataFoundMessage="No rooms found"
        />
      </div>
    </div>
  );
};

export default ManageMultipleObjectsExample;
