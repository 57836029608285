import React from 'react';

import MyTripStandardPage from '../layout/MyTripStandardPage';
import TwoColumns from '../../admin/common/layout/TwoColumns';
import ClinicalExperienceLeftSideText from './ClinicalExperienceLeftSideText';
import ClinicalExperienceForm from './ClinicalExperienceForm';

const ClinicalExperience: React.FC = () => {
  return (
    <MyTripStandardPage title="Clinical Experience" showDashLink>
      <TwoColumns
        leftColumn={<ClinicalExperienceLeftSideText />}
        rightColumn={<ClinicalExperienceForm />}
      />
    </MyTripStandardPage>
  );
};

export default ClinicalExperience;
