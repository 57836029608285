import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

import { Item } from './types';
import HeadingTwo from '../components/typography/HeadingTwo';

const STATUS_COLOR_MAP = {
  due_soon: '#FFDBA5',
  overdue: '#FF979D',
  completed: '#C6F6CA',
};
const CIRCLE_COLOR_MAP = {
  due_soon: '#FF9900',
  overdue: '#EE341B',
  completed: '#00DB3D',
};
const CIRCLE_ICON_MAP = {
  due_soon: '!',
  overdue: '!',
  completed: '✓',
};

interface LegendItemProps {
  color: string;
  text: string;
}
const LegendItem: React.FC<LegendItemProps> = ({ color, text }) => {
  return (
    <div className="flex items-center gap-2 text-mob-xs md:text-md">
      <div
        className="w-6 h-6 rounded-full"
        style={{ backgroundColor: color }}
      />
      <p>{text}</p>
    </div>
  );
};

interface ListItemProps {
  item: Item;
  active: boolean;
}
const ListItem: React.FC<ListItemProps> = ({ item, active }) => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        'relative flex items-center justify-center p-2 text-mob-xs text-center cursor-pointer shadow-md border h-[95px] w-[108px] sm:h-[106px] sm:w-[157px] md:text-md',
        {
          'opacity-50': !active,
        }
      )}
      style={{
        backgroundColor: STATUS_COLOR_MAP[item.status],
        borderRadius: '20px',
      }}
      onClick={() => navigate(item.url)}
    >
      {item.text}
      <div
        className="absolute bottom-2 right-2 rounded-full h-5 w-5 text-mob-xs text-white flex items-center justify-center md:text-md"
        style={{ backgroundColor: CIRCLE_COLOR_MAP[item.status] }}
      >
        <p>{CIRCLE_ICON_MAP[item.status]}</p>
      </div>
    </div>
  );
};

interface Props {
  items: Array<Item>;
  selectedDate: Date | undefined;
}
const ToDoList: React.FC<Props> = ({ items, selectedDate }) => {
  const activeItems = selectedDate
    ? items
        .filter((item) => new Date(item.due_date) <= selectedDate)
        .map((item) => item.text)
    : items.map((item) => item.text);
  return (
    <div className="space-y-4">
      <HeadingTwo text="My to do list:" />
      <p>These are the items that need to be completed before your trip:</p>
      <div className="flex gap-4 flex-wrap">
        <LegendItem color={STATUS_COLOR_MAP['due_soon']} text="Due soon" />
        <LegendItem color={STATUS_COLOR_MAP['overdue']} text="Overdue" />
        <LegendItem color={STATUS_COLOR_MAP['completed']} text="Completed" />
      </div>
      <div className="flex gap-6 flex-wrap">
        {items
          .sort((a, b) => {
            if (a.due_date < b.due_date) {
              return -1;
            }
            return 1;
          })
          .map((item, index) => (
            <ListItem
              key={index}
              item={item}
              active={activeItems.includes(item.text)}
            />
          ))}
      </div>
    </div>
  );
};

export default ToDoList;
