import React from 'react';
import { format } from 'date-fns';

import ImageGallery from '../ImageGallery/ImageGallery';
import AdaptiveText from '../AdaptiveText';

interface PropertySummaryProps {
  name?: string | undefined;
  startDate: string;
  endDate: string;
  galleryImageUrls: () => ReadonlyArray<string> | undefined;
  contentNextToGallery: React.ReactNode;
  contentUnderGallery: React.ReactNode;
}

const PropertySummary: React.FC<PropertySummaryProps> = (
  props: PropertySummaryProps
) => {
  return (
    <div className="mt-10">
      <div className="md:flex flex-row">
        <div className="hidden pr-4 md:flex">
          <ImageGallery imageUrls={props.galleryImageUrls()} />
        </div>
        <div>
          <h1 className="text-sh3">{props.name}</h1>
          <p className="mb-4">
            {format(props.startDate, 'MMM d')} -{' '}
            {format(props.endDate, 'MMM d')}
          </p>
          <p className="text-sm">{props.contentNextToGallery}</p>
        </div>
      </div>
      {props.contentUnderGallery && (
        <div className="mt-4">
          <AdaptiveText>
            <p className="text-sm">{props.contentUnderGallery}</p>
          </AdaptiveText>
        </div>
      )}
      <div className="w-full mt-4 place-items-center md:hidden">
        <ImageGallery imageUrls={props.galleryImageUrls()} />
      </div>
    </div>
  );
};

export default PropertySummary;
