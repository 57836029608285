import React from 'react';

import MyTripStandardPage from '../layout/MyTripStandardPage';
import PhotosAndVideos from './PhotosAndVideos';
import ShareYourJourney from './ShareYourJourney';
import WelcomeBack from './WelcomeBack';
import Documents from './Documents';
import TwoColumns from '../../admin/common/layout/TwoColumns';

const LeftHandColumn: React.FC = () => {
  return (
    <div className="space-y-8">
      <WelcomeBack />
      <Documents />
    </div>
  );
};

const RightHandColumn: React.FC = () => {
  return (
    <div className="space-y-8">
      <ShareYourJourney />
      <PhotosAndVideos />
    </div>
  );
};

const Alumni: React.FC = () => {
  return (
    <MyTripStandardPage title="Alumni">
      <TwoColumns
        leftColumn={<LeftHandColumn />}
        rightColumn={<RightHandColumn />}
      />
    </MyTripStandardPage>
  );
};

export default Alumni;
