import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children: React.ReactNode | React.ReactNode[] | ReactNode;
  className?: string;
  title?: string;
}

const getTitleFromChild = (child: React.ReactNode | React.ReactNode[]) => {
  const contentIsString = ['string', 'number'].includes(typeof child);

  if (Array.isArray(child) || !contentIsString) {
    return undefined;
  }

  return String(child);
};

const TableCell: React.FC<Props> = ({ children, className, title }) => {
  const hoverOverDescription = title ? title : getTitleFromChild(children);
  return (
    <td
      className={classNames(
        'p-[6.6px] text-sm text-black text-wrap break-words',
        className
      )}
      title={hoverOverDescription}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as ReactElement, {
            className: `${child.props.className || ''} text-wrap`,
          });
        }
        return child;
      })}
    </td>
  );
};

export default TableCell;
