import React from 'react';

type DescriptionListValue = string | number | (() => React.JSX.Element);

interface DescriptionItem {
  label: string;
  value: DescriptionListValue;
}

export interface DescriptionListProps {
  items: ReadonlyArray<DescriptionItem>;
  title: string;
  subtitle?: string;
}

const DescriptionList: React.FC<DescriptionListProps> = ({
  items,
  title,
  subtitle,
}) => {
  const renderSubtitle = () => {
    if (!subtitle) return;

    return (
      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
        {subtitle}
      </p>
    );
  };

  const renderItems = () => {
    return items.map(({ label, value }, index) => (
      <div
        key={index}
        className="py-6 @xs:grid @xs:grid-cols-2 @xs:gap-4 @xs:px-0"
      >
        <dt className="text-sm-bold font-medium leading-6 text-gray-900">
          {label}
        </dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 @xs:col-span-1 @xs:mt-0">
          {value && (typeof value === 'function' ? value() : value)}
          {!value && '-'}
        </dd>
      </div>
    ));
  };

  return (
    <div className="@container">
      <div className="px-0">
        <h3 className="text-mob-h3 xs:text-h3 text-gray-900">{title}</h3>
        {renderSubtitle()}
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">{renderItems()}</dl>
      </div>
    </div>
  );
};

export default DescriptionList;
