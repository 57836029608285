import React, { useState } from 'react';
import { format } from 'date-fns';
import { faPlane } from '@fortawesome/pro-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import { ArrivalAndDepatureDetails } from './types';
import {
  updateFlightConflictResolution,
  updateFlightArrivalAndDepartureDetails,
} from './actions';
import TextArea from '../../../../framework-components/textarea/TextArea';
import { RoundedSmallButtonOutline } from '../../../../framework-components/button/Button';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import CopyToClipboardButton from '../../../CopyClipboardButton';

interface Props {
  bookingId: string;
  setHasTravelConflict: React.Dispatch<React.SetStateAction<boolean>>;
  canEdit: boolean;
}

interface TravelDetailsProps {
  label: string;
  flight_date: string | null;
  flight_number: string | null;
  flight_conflict_resolution_comment: string;
  has_alternative_transport: boolean;
  flight_conflict_resolved: boolean;
  has_conflict: boolean;
}

export const TravelDetails: React.FC<TravelDetailsProps> = ({
  label,
  flight_date,
  flight_number,
  flight_conflict_resolution_comment,
  flight_conflict_resolved,
  has_alternative_transport,
  has_conflict,
}) => {
  const flightDateAndNumberCopyText = (
    <CopyToClipboardButton
      textToCopy={
        flight_date && flight_number ? `${flight_date} ${flight_number}` : ' '
      }
    />
  );

  return (
    <div>
      <div className="flex justify-between items-center px-4 py-2 bg-wtw-gray-3 text-xs rounded-lg">
        <p>{label}</p>
        <div className="flex items-center gap-2">
          <p>
            {flight_date ? format(flight_date, 'do MMMM yyyy - HH:mm') : '-'}
          </p>
          <p>{flight_number ? <>({flight_number})</> : ''}</p>
          {flightDateAndNumberCopyText}
        </div>
        {has_alternative_transport ? (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="text-red-500"
            data-testid="exclamation-icon"
          />
        ) : (
          <FontAwesomeIcon
            icon={faPlane}
            className={has_conflict ? 'text-red-500' : ''}
            data-testid="plane-icon"
          />
        )}
      </div>

      {flight_conflict_resolved && (
        <p className="mt-4" data-testid="conflict-resolution-comment">
          <span className="font-bold">Conflict resolved with comment: </span>
          {flight_conflict_resolution_comment}
        </p>
      )}
    </div>
  );
};

const TravelDetailsSection: React.FC<Props> = ({
  bookingId,
  setHasTravelConflict,
  canEdit,
}) => {
  const [arrivalConflictResolutionNotes, setArrivalConflictResolutionNotes] =
    useState<string | undefined>(undefined);

  const [
    departureConflictResolutionNotes,
    setDepartureConflictResolutionNotes,
  ] = useState<string | undefined>(undefined);

  const handleUpdateFlightConflictResolution = async (
    url: string,
    conflictResolutionNotes: string | undefined,
    type: 'arrival' | 'departure'
  ) => {
    if (!conflictResolutionNotes) {
      return;
    }
    try {
      await updateFlightConflictResolution(url, {
        conflict_resolution: conflictResolutionNotes,
      });
      toast.success('Conflict resolution notes saved successfully');

      const commentField =
        type === 'arrival'
          ? 'flight_arrival_conflict_resolution_comment'
          : 'flight_departure_conflict_resolution_comment';
      const resolvedField =
        type === 'arrival'
          ? 'flight_arrival_conflict_resolved'
          : 'flight_departure_conflict_resolved';
      mutate(
        `/api/admin/bookings/${bookingId}/get-flight-details/`,
        (data: ArrivalAndDepatureDetails) => ({
          ...data,
          [commentField]: conflictResolutionNotes,
          [resolvedField]: true,
        }),
        false
      );
    } catch {
      toast.error('An error occurred while saving conflict resolution notes');
    }
  };

  const initializeAndOnSubmitSuccess = (values: ArrivalAndDepatureDetails) => {
    setArrivalConflictResolutionNotes(
      values.flight_arrival_conflict_resolution_comment
    );
    setDepartureConflictResolutionNotes(
      values.flight_departure_conflict_resolution_comment
    );
    setHasTravelConflict(
      values.has_arrival_conflict || values.has_departure_conflict
    );
  };

  return (
    <AdminWidgetContainer className="space-y-2" title="Flight details:">
      <ManageSingleObject<ArrivalAndDepatureDetails>
        getUrl={`/api/admin/bookings/${bookingId}/get-flight-details/`}
        submitItem={(values) =>
          updateFlightArrivalAndDepartureDetails(bookingId, values)
        }
        onSubmitSuccess={initializeAndOnSubmitSuccess}
        onInitialize={initializeAndOnSubmitSuccess}
        editButton={canEdit}
        alternativeRender={(data) => (
          <>
            <div className="space-y-2">
              <h1 className="text-mob-sh2">Arrival:</h1>
              {data.independant_travel_arrival_notes && (
                <p className="text-sm">
                  {data.independant_travel_arrival_notes}
                </p>
              )}
              <TravelDetails
                label="Outbound:"
                flight_date={data.flight_arrival}
                flight_number={data.flight_arrival_number}
                flight_conflict_resolution_comment={
                  data.flight_arrival_conflict_resolution_comment
                }
                flight_conflict_resolved={data.flight_arrival_conflict_resolved}
                has_alternative_transport={data.independant_travel_arrival}
                has_conflict={
                  data.has_arrival_conflict &&
                  !data.flight_arrival_conflict_resolved
                }
              />
              {canEdit &&
                data.has_arrival_conflict &&
                !data.flight_arrival_conflict_resolved && (
                  <div className="relative w-full">
                    <TextArea
                      name="conflict_details"
                      placeholder="Enter notes here..."
                      containerClassName="w-full"
                      value={arrivalConflictResolutionNotes}
                      onChange={(e) =>
                        setArrivalConflictResolutionNotes(e.target.value)
                      }
                    />
                    <RoundedSmallButtonOutline
                      className="absolute bottom-2 right-2"
                      onClick={() =>
                        handleUpdateFlightConflictResolution(
                          `/api/admin/bookings/${bookingId}/resolve-arrival-conflict/`,
                          arrivalConflictResolutionNotes,
                          'arrival'
                        )
                      }
                    >
                      Save notes
                    </RoundedSmallButtonOutline>
                  </div>
                )}
            </div>

            <div className="space-y-2">
              <h1 className="text-mob-sh2">Departure:</h1>
              {data.independant_travel_departure_notes && (
                <p className="text-sm">
                  {data.independant_travel_departure_notes}
                </p>
              )}
              <TravelDetails
                label="Return:"
                flight_date={data.flight_departure}
                flight_number={data.flight_departure_number}
                flight_conflict_resolution_comment={
                  data.flight_departure_conflict_resolution_comment
                }
                flight_conflict_resolved={
                  data.flight_departure_conflict_resolved
                }
                has_alternative_transport={data.independant_travel_departure}
                has_conflict={
                  data.has_departure_conflict &&
                  !data.flight_departure_conflict_resolved
                }
              />
              {canEdit &&
                data.has_departure_conflict &&
                !data.flight_departure_conflict_resolved && (
                  <div className="relative w-full">
                    <TextArea
                      name="conflict_details"
                      placeholder="Enter notes here..."
                      containerClassName="w-full"
                      value={departureConflictResolutionNotes}
                      onChange={(e) =>
                        setDepartureConflictResolutionNotes(e.target.value)
                      }
                    />
                    <RoundedSmallButtonOutline
                      className="absolute bottom-2 right-2"
                      onClick={() =>
                        handleUpdateFlightConflictResolution(
                          `/api/admin/bookings/${bookingId}/resolve-departure-conflict/`,
                          departureConflictResolutionNotes,
                          'departure'
                        )
                      }
                    >
                      Save notes
                    </RoundedSmallButtonOutline>
                  </div>
                )}
            </div>
          </>
        )}
        formFields={[
          {
            name: 'flight_arrival',
            label: 'Arrival flight time',
            type: 'datetime',
            extraProps: {
              showTimeSelect: true,
              dateFormat: 'yyyy-MM-dd HH:mm',
            },
          },
          {
            name: 'flight_arrival_number',
            label: 'Arrival flight number',
            type: 'smalltext',
          },
          {
            name: 'flight_arrival_conflict_resolution_comment',
            label: 'Arrival conflict resolution comment',
            type: 'largetext',
          },
          {
            name: 'flight_arrival_conflict_resolved',
            type: 'checkbox',
            option: {
              label: 'Arrival conflict resolved',
              value: 'true',
            },
          },
          {
            name: 'flight_departure',
            label: 'Departure flight time',
            type: 'datetime',
            extraProps: {
              showTimeSelect: true,
              dateFormat: 'yyyy-MM-dd HH:mm',
            },
          },
          {
            name: 'flight_departure_number',
            label: 'Departure flight number',
            type: 'smalltext',
          },
          {
            name: 'flight_departure_conflict_resolution_comment',
            label: 'Departure conflict resolution comment',
            type: 'largetext',
          },
          {
            name: 'flight_departure_conflict_resolved',
            type: 'checkbox',
            option: {
              label: 'Departure conflict resolved',
              value: 'true',
            },
          },
        ]}
        insideCard={false}
      />
    </AdminWidgetContainer>
  );
};

export default TravelDetailsSection;
