import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  Bars2Icon,
  XMarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { Fragment, useContext } from 'react';
import { redirect } from 'react-router';

import { SearchButtonProps } from '../search-button/types';
import SearchEverywhereButton from '../search-everywhere-button/SearchEverywhereButton';
import { useAPI } from '../../api/api';
import Spinner from '../../components/spinner';
import { MyBookingsContext } from '../../components/my-trip/context-providers';

export type Navigation = ReadonlyArray<{
  name: string;
  href: string;
  openInNewTab?: boolean;
  current?: boolean;
  children?: ReadonlyArray<{
    name: string;
    href: string;
    openInNewTab?: boolean;
  }>;
}>;

export type ProfileActions = ReadonlyArray<{
  label: string;
  href?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface NavbarProps<SearchButtonPropsTyping = any> {
  navigation: Navigation;
  appLogo?: React.ReactNode;
  profileActions?: ProfileActions;
  className?: string;
  search?: SearchButtonProps<SearchButtonPropsTyping>;
}

const RenderProfileImage: React.FC<{
  bookingId: string | undefined;
}> = ({ bookingId }) => {
  const {
    data: profilePicture,
    error: profilePictureError,
    isValidating: isProfilePictureValidating,
  } = useAPI<{ id_photo_url: string; profile_picture_url: string }>(
    bookingId ? `/api/applicant/${bookingId}/my-account/get-id-photo/` : ''
  );

  if (isProfilePictureValidating) {
    return (
      <div className="relative bg-wtw-gray-2 place-content-center place-items-center h-[112px] w-0 sm:w-1/3">
        <Spinner className="w-5 h-5" />
      </div>
    );
  }

  if (profilePictureError) {
    return (
      <img
        className={`rounded-full w-[36px] h-[36px]`}
        src={'/static/unknown_N.png'}
      />
    );
  }

  if (profilePicture) {
    return (
      <img
        className="aspect-square object-cover rounded-full w-[36px] h-[36px]"
        src={
          profilePicture?.id_photo_url ||
          profilePicture?.profile_picture_url ||
          '/static/unknown_N.png'
        }
      />
    );
  }
  return (
    <img
      className={`rounded-full w-[36px] h-[36px]`}
      src={'/static/unknown_N.png'}
    />
  );
};

const renderNavbarItems = (navigation: Navigation) => {
  return navigation.map((item, index) => (
    <div key={index} className="relative group z-10">
      <a
        href={item.href}
        target={item.openInNewTab ? '_blank' : '_self'}
        className={classNames('inline-flex items-center text-md', {
          'border-indigo-500 text-wtw-black': item.current,
          'border-transparent text-wtw-black group-hover:text-wtw-blue-primary hover:text-wtw-blue-primary':
            !item.current,
        })}
      >
        {item.name}
      </a>
      {item.children && item.children.length > 0 && (
        <div className="absolute left-0 hidden pt-2 group-hover:block w-max">
          <div className="bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            {item.children.map((child, childIndex) => (
              <a
                key={childIndex}
                href={child.href}
                className="block px-4 py-2 text-sm text-gray-700 hover:text-wtw-blue-primary hover:bg-wtw-table-blue"
              >
                {child.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  ));
};

const renderProfileActionsDropdown = (
  profileActions: ProfileActions,
  bookingId: string | undefined
) => {
  return (
    <div className="absolute top-0 bottom-0 left-0 flex items-center pr-xs sm:static sm:inset-auto sm:ml-2xl sm:pr-0">
      <Menu as="div" className="relative ml-2">
        <div>
          <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="absolute -inset-md" />
            <span className="sr-only">Open user menu</span>
            <RenderProfileImage bookingId={bookingId} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 sm:left-auto sm:right-0 z-10 mt-md w-[192px] origin-top-right rounded-md bg-white py-[4px] ring-1 ring-black ring-opacity-5 focus:outline-none">
            {profileActions.map((item, index) => (
              <Menu.Item key={index}>
                {({ active }) => {
                  return (
                    <a
                      href={item.href}
                      target={item.openInNewTab ? '_blank' : '_self'}
                      onClick={item.onClick}
                      className={classNames(
                        { 'bg-gray-100': active },
                        'block px-4 py-2 text-sm text-gray-700 hover:text-wtw-blue-primary hover:bg-wtw-table-blue hover:cursor-pointer'
                      )}
                    >
                      {item.label}
                    </a>
                  );
                }}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const renderMobileNavbarItems = (navigation: Navigation) =>
  navigation.map((item, index) => (
    <div key={index}>
      {item.children && item.children.length > 0 ? (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="w-full text-left py-2 pl-4 pr-4 text-base font-medium text-gray-700 active:text-wtw-blue-primar flex gap-2 items-center">
                {item.name}{' '}
                {open ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </Disclosure.Button>
              {item.children && (
                <Disclosure.Panel className="space-y-1">
                  {item.children.map((child, childIndex) => (
                    <Disclosure.Button
                      key={childIndex}
                      as="a"
                      href={child.href}
                      className="block py-2 pl-10 pr-4 text-base font-medium text-gray-500 active:text-wtw-blue-primary"
                    >
                      {child.name}
                    </Disclosure.Button>
                  ))}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      ) : (
        <Disclosure.Button
          as="a"
          href={item.href}
          target={item.openInNewTab ? '_blank' : '_self'}
          className="block border-l-4 py-2 pl-3 pr-4 text-base font-medium border-transparent text-gray-700 active:text-wtw-blue-primary"
        >
          {item.name}
        </Disclosure.Button>
      )}
    </div>
  ));

const Navbar: React.FC<NavbarProps> = ({
  navigation,
  appLogo,
  profileActions,
  className,
  search,
}) => {
  const hasProfileActions = profileActions && profileActions.length > 0;
  const { activeBooking } = useContext(MyBookingsContext);
  return (
    <Disclosure
      as="nav"
      className={classNames('bg-white shadow-sm', className)}
    >
      {({ open }) => (
        <>
          <div className="w-full mx-auto max-w-[1440px] px-2 sm:px-12">
            <div className="relative flex md:h-[120px] py-8 justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {navigation.length > 0 &&
                    (open ? (
                      <XMarkIcon
                        className="block h-8 w-8 text-gray-700"
                        aria-hidden="true"
                      />
                    ) : (
                      <Bars2Icon
                        className="block h-8 w-8 text-gray-700"
                        aria-hidden="true"
                      />
                    ))}
                </Disclosure.Button>
              </div>
              <div
                className={classNames(
                  'flex flex-1 items-center justify-center sm:items-stretch sm:justify-start',
                  { 'sm:justify-between': !profileActions },
                  { 'sm:justify-start': profileActions }
                )}
              >
                {appLogo && (
                  <div
                    className="flex flex-shrink-0 items-center cursor-pointer hidden sm:flex"
                    onClick={() => redirect('/')}
                  >
                    {appLogo}
                  </div>
                )}
                <div className="hidden w-full justify-end sm:ml-6 sm:flex sm:items-center sm:space-x-6">
                  {renderNavbarItems(navigation)}
                </div>
              </div>
              <div className="flex items-center justify-center mr-12 md:mr-0 md:pl-2 md:ml-2">
                {search && <SearchEverywhereButton {...search} />}
              </div>
              {hasProfileActions &&
                renderProfileActionsDropdown(profileActions, activeBooking?.id)}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden w-full">
            <div className="space-y-1 pb-10 pt-2">
              {renderMobileNavbarItems(navigation)}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
