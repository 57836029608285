import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

import { Option } from '../../types';
import Spinner from '../../components/spinner';
import { useOutsideAlerter } from '../../components/admin/common/hooks';

interface Props<T> {
  query: string;
  setQuery: (query: string) => void;
  filteredOptions: ReadonlyArray<Option<T>> | undefined | null;
  selectedOption: Option<T> | null;
  selectHandler: (option: Option<T> | null) => void;
  id?: string;
  name: string;
  label?: string | React.ReactNode;
  isLoading: boolean;
  row?: boolean;
  placeholder?: string;
  labelClassName?: string;
  inputClassNameOverride?: string;
  zIndex?: number;
  isDisabled?: boolean;
  ignoreOutsideClick?: boolean;
  inputWidth?: string;
}

const DropdownInner = <T,>({
  query,
  setQuery,
  filteredOptions,
  selectedOption,
  selectHandler,
  id,
  name,
  label,
  isLoading,
  row,
  placeholder,
  labelClassName,
  inputClassNameOverride,
  zIndex,
  isDisabled,
  ignoreOutsideClick,
  inputWidth,
}: Props<T>) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setIsDropdownOpen(true);
  };

  useOutsideAlerter(ref, () => {
    if (!ignoreOutsideClick) {
      setIsDropdownOpen(false);
    }
  });

  const clearSelectedOption = () => {
    selectHandler(null);
    setQuery('');
  };
  useEffect(() => {
    if (selectedOption) {
      setIsDropdownOpen(false);
    }
  }, [selectedOption]);

  return (
    <div
      ref={ref}
      className={classNames('flex', {
        'flex-col': !row,
        'flex-col sm:flex-row sm:gap-2 sm:items-center': row,
      })}
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames(
            'block text-sm leading-6 text-gray-900',
            { 'w-1/2': row },
            labelClassName
          )}
        >
          {label}
        </label>
      )}

      <div className="relative">
        <input
          className={classNames(
            `${
              inputWidth || 'w-full'
            } rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`,
            inputClassNameOverride,
            {
              'bg-white': !isDisabled,
              'bg-gray-50 cursor-not-allowed': isDisabled,
            }
          )}
          onChange={handleChange}
          onClick={() => setIsDropdownOpen(true)}
          value={selectedOption?.label || query}
          name={name}
          id={id}
          placeholder={placeholder || 'Please select'}
          role="combobox"
          disabled={isDisabled}
          autoComplete="off"
        />
        {!selectedOption || selectedOption.value === '' ? (
          <button
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            type="button"
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen);
            }}
          >
            {isLoading ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}
          </button>
        ) : (
          <button
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            onClick={clearSelectedOption}
            type="button"
            disabled={isDisabled}
          >
            <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        )}

        {isDropdownOpen && filteredOptions && filteredOptions.length > 0 && (
          <ul
            style={{ zIndex: zIndex ? zIndex : 10 }}
            className="absolute max-h-[140px] min-w-full max-w-[330px] sm:w-max overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {filteredOptions.map((option) => (
              <li
                key={`option-${option.value}`}
                onClick={() => selectHandler(option)}
                className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white"
              >
                <span
                  className={classNames(
                    'block truncate',
                    selectedOption?.value === option.value && 'font-semibold'
                  )}
                >
                  {option.label}
                </span>

                {selectedOption?.value === option.value && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropdownInner;
