import React, { useState } from 'react';

import AdminWidgetContainer from '../../../../../framework-components/card/AdminWidgetContainer';
import { DepartmentAndSupervisorData, DisciplineCapacity } from '../types';
import { PrimaryButton } from '../../../../../framework-components/button/Button';
import DeleteConfirmationModal from '../../../common/modal/DeleteConfirmationModal';
import useModalState from '../../../../../hooks/modals';
import { useDeleteDepartment } from './hooks';
import EditDepartmentForm from './EditDepartmentForm';
import { Option } from '../../../../../types';
import ManageMultipleObjects from '../../../../manage-multiple-objects/ManageMultipleObjects';
import {
  updateDisciplineCapacity,
  deleteDisciplineCapacity,
  addDisciplineCapacity,
} from '../actions';

const Item = ({
  label,
  value,
}: {
  label: string;
  value: string | number | null;
}) => {
  return (
    <div className="flex flex-col gap-2 sm:flex-row sm:gap-4 py-1">
      <dt className="text-xs-bold w-full sm:w-1/4 break-words">{label}</dt>
      <dd className="text-gray-900 text-xs w-full sm:w-3/4">{value}</dd>
    </div>
  );
};

interface Props {
  hospitalId: string;
  department: DepartmentAndSupervisorData;
  disciplines: Array<Option>;
}

const DepartmentDetail: React.FC<Props> = ({
  hospitalId,
  department,
  disciplines,
}) => {
  const {
    isOpen: deleteModalIsOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModalState();

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { removeDepartmentHandler } = useDeleteDepartment(
    hospitalId,
    setLoading
  );

  return (
    <AdminWidgetContainer>
      {isEditing ? (
        <EditDepartmentForm
          hospitalId={hospitalId}
          department={department}
          setShowForm={setIsEditing}
        />
      ) : (
        <>
          <div className="flex justify-end gap-4">
            <PrimaryButton onClick={() => setIsEditing(true)}>
              Edit
            </PrimaryButton>
            <PrimaryButton onClick={openDeleteModal} isDisabled={loading}>
              Delete
            </PrimaryButton>
          </div>
          <div>
            <Item label="Name" value={department.name} />
            <Item label="Main supervisor" value={department.supervisor} />
            <Item label="Capacity" value={department.capacity} />
            <Item label="Description" value={department.description ?? null} />
          </div>
          <ManageMultipleObjects<DisciplineCapacity>
            prefetchedData={department.discipline_capacities}
            mutateUrl={`/api/admin/hospital/${hospitalId}/departments-and-supervisors/get/`}
            revalidateToMutate
            tableFields={[
              {
                header: 'Discipline',
                name: 'discipline',
                render: (item) => item.discipline.label,
              },
              {
                header: 'Capacity',
                name: 'capacity',
                render: (item) => item.capacity,
              },
            ]}
            quickFormFields={[
              {
                name: 'discipline',
                label: 'Discipline',
                type: 'local-dropdown',
                options: disciplines,
              },
              {
                name: 'capacity',
                label: 'Capacity',
                type: 'smalltext',
                extraProps: {
                  type: 'number',
                },
              },
            ]}
            addHandler={(values) =>
              addDisciplineCapacity(department.id, values)
            }
            editHandler={updateDisciplineCapacity}
            deleteHandler={deleteDisciplineCapacity}
            noDataFoundMessage="No disciplines found"
            insideCard={false}
            manageButtonText="Manage disciplines"
            justifyCenter={false}
          />
          <DeleteConfirmationModal
            isOpen={deleteModalIsOpen}
            closeModal={closeDeleteModal}
            handleDelete={() => removeDepartmentHandler(department.id)}
          />
        </>
      )}
    </AdminWidgetContainer>
  );
};

export default DepartmentDetail;
