import React, { useState } from 'react';
import classNames from 'classnames';

interface Tab {
  id: string;
  label: string;
  imageUrl: string | undefined;
  content?: () => JSX.Element;
}

interface TabsWithImages<Tabs extends ReadonlyArray<Tab>> {
  tabs: Tabs;
  onTabChange?: (tabId: Tabs[number]['id']) => void;
}

const TabsWithImages = <Tabs extends ReadonlyArray<Tab>>({
  tabs,
  onTabChange,
}: TabsWithImages<Tabs>) => {
  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id);

  const selectTab = (id: Tabs[number]['id']) => {
    setSelectedTabId(id);
    if (onTabChange) {
      onTabChange(id);
    }
  };

  return (
    <div className="flex gap-4 pt-4 bg-white">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          onClick={() => selectTab(tab.id)}
          className={classNames({ 'opacity-50': selectedTabId !== tab.id })}
        >
          <img
            src={tab.imageUrl}
            className={classNames(
              'w-[120px] h-[120px] rounded-lg mb-2 border-2',
              {
                'border-2 border-blue-700': selectedTabId === tab.id,
              }
            )}
          />
          <p>{tab.label}</p>
          {tab.content && <p>{tab.content()}</p>}
        </div>
      ))}
    </div>
  );
};

export default TabsWithImages;
