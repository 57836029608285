import React from 'react';

import { useAPI } from '../../../../api/api';
import AdminStandardPage from '../../common/AdminStandardPage';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import Table from '../../../manage-multiple-objects/Table';
import useModalState from '../../../../hooks/modals';
import TemplateModal from './TemplateModal';

interface Template {
  name: string;
  content: string;
}

const TemplateRow: React.FC<Template> = ({ name, content }) => {
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <div>
      <p onClick={openModal} className="text-blue-500 cursor-pointer">
        {name}
      </p>
      <TemplateModal
        name={name}
        content={content}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </div>
  );
};

const TemplatedResourcesPage: React.FC = () => {
  const { data, isValidating, error } = useAPI<ReadonlyArray<Template>>(
    '/api/admin/document/templated-resources/'
  );

  return (
    <div>
      <AdminStandardPage
        breadcrumbs={[
          {
            label: 'Directories',
            href: null,
          },
          {
            label: 'Templated Resources',
            href: null,
          },
        ]}
        title="Templated Resources"
        enableHome={false}
        isLoading={isValidating}
        error={error ? 'Failed to load templates' : undefined}
      >
        <BlockTabs
          tabs={[
            {
              label: 'Documents',
              href: '/admin/directories/documents',
              current: false,
            },
            {
              label: 'Information Pack',
              href: '/admin/directories/info-pack',
              current: false,
            },
            {
              label: 'Templated Resources',
              href: '/admin/directories/templated-resources',
              current: true,
            },
          ]}
          variant="pilled_tab"
        />
        <div className="mt-8">
          {data && (
            <Table<Template>
              data={data}
              tableFields={[
                {
                  header: 'Name',
                  name: 'name',
                  render: (template) => <TemplateRow {...template} />,
                },
              ]}
            />
          )}
        </div>
      </AdminStandardPage>
    </div>
  );
};

export default TemplatedResourcesPage;
