import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import Modal from '../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';
import { cancelBooking } from './actions';
import { BasicBookingApplicantProfileInfo } from './types';

interface Props {
  bookingId: string;
  isOpen: boolean;
  closeModal: () => void;
}

const CancelBookingModal: React.FC<Props> = ({
  bookingId,
  isOpen,
  closeModal,
}) => {
  const focusRef = React.useRef(null);

  const handleCancel = async () => {
    try {
      await cancelBooking(bookingId);
      toast.success('Booking cancelled successfully');
      mutate(
        `/api/admin/bookings/${bookingId}/basic-profile-page-info/`,
        (data: BasicBookingApplicantProfileInfo) => ({
          ...data,
          is_cancelled: true,
        }),
        false
      );
      mutate(`/api/admin/bookings/${bookingId}/get-ledger-list/`);
      setTimeout(() => {
        mutate(
          `/api/admin/bookings/${bookingId}/get-invoice-and-credit-note-list/`
        );
      }, 1000);
      closeModal();
    } catch (error) {
      toast.error('Failed to cancel booking');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4" data-testid="delete-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Cancel booking
        </Dialog.Title>
        <p>Are you sure you want to cancel the booking?</p>
        <p>
          Note: this cannot be reversed. This may cause a negative balance on
          the ledger if the customer has already made payments. In this case
          please process any refunds via Stripe/Paypal, or add a BACS refund to
          the ledger to zero the balance.
        </p>
        <div className="flex justify-end space-x-4">
          <SecondaryButton onClick={closeModal}>Back</SecondaryButton>
          <PrimaryButton onClick={handleCancel}>Cancel booking</PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default CancelBookingModal;
