import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import { apiRequest } from '../../../../api/api';
import Modal from '../../../modal';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import { BasicBookingApplicantProfileInfo } from './types';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../utils/dates';

export const sendReminder = async (bookingId: string) => {
  const response = await apiRequest(
    `/api/admin/bookings/${bookingId}/reminder-email/send/`,
    {
      method: 'POST',
    }
  );
  return response;
};

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  bookingId: string;
  lastSent: string | null;
}

const SendReminderModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  bookingId,
  lastSent,
}) => {
  const focusRef = React.useRef(null);

  const handleSendReminder = () => {
    try {
      sendReminder(bookingId);
      toast.success('Reminder sent');
      mutate(
        `/api/admin/bookings/${bookingId}/basic-profile-page-info/`,
        (data: BasicBookingApplicantProfileInfo) => ({
          ...data,
          last_reminder_sent_at: new Date().toISOString(),
        }),
        false
      );
      closeModal();
    } catch (error) {
      toast.error('Error sending reminder');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 flex flex-col items-center">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Send reminder
        </Dialog.Title>
        <Dialog.Description>
          Last sent:{' '}
          {lastSent ? formatDateToLongDateFormatWithOridinalDay(lastSent) : '-'}
        </Dialog.Description>
        <PrimaryButton onClick={handleSendReminder}>
          Send reminder
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default SendReminderModal;
