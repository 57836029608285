import React from 'react';

import SpanningContainer from '../layout/SpanningContainer';
import { formatDateToDayDateMonthYear } from '../../../utils/dates';
import HtmlRenderer from '../../../framework-components/html-renderer/HtmlRenderer';
import AdaptiveText from '../components/AdaptiveText';

interface HospitalDepartmentSummaryProps {
  hospitalData: {
    main_contact: string;
    location_html: string;
    hospital_departments: {
      display_name: string;
      hospital_department_bookings: { start_date: string; end_date: string }[];
      description_html: string;
      primary_contact: string;
    }[];
  };
}

const HospitalDepartmentSummary: React.FC<HospitalDepartmentSummaryProps> = ({
  hospitalData,
}) => {
  return (
    <SpanningContainer variant="gray">
      <div className="space-y-8 pb-8">
        {hospitalData?.hospital_departments.map((department, index) => (
          <div key={index} className="space-y-4">
            <header className="space-y-1">
              <h1 className="text-sh2">My department</h1>
              <p className="text-lg">{department.display_name} </p>
              {department.hospital_department_bookings.map((booking, i) => (
                <p key={i}>
                  {'('}
                  {formatDateToDayDateMonthYear(booking.start_date)} to{' '}
                  {formatDateToDayDateMonthYear(booking.end_date)}
                  {')'}
                </p>
              ))}
            </header>
            <h1 className="text-md-bold">Primary contact:</h1>
            <p>{department.primary_contact || 'No primary contact assigned'}</p>

            <h1 className="text-md-bold">General info:</h1>
            <AdaptiveText>
              <HtmlRenderer htmlString={department.description_html} />
            </AdaptiveText>
          </div>
        ))}
      </div>
    </SpanningContainer>
  );
};

export default HospitalDepartmentSummary;
