import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../../modal';
import { Image } from './types';
import ImageTile from './ImageTile';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import UploadModal from '../UploadModal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  images: Image[];
  setMainImage: (imageId: string) => void;
  deleteImage: (imageId: string) => void;
  onSuccessfulUpload: (file_id: string) => void;
}

const ImageGalleryModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  images,
  setMainImage,
  deleteImage,
  onSuccessfulUpload,
}) => {
  const focusRef = React.useRef(null);

  const {
    isOpen: isOpenUploadModal,
    openModal: openUploadModal,
    closeModal: closeUploadModal,
  } = useModalState();

  const handleSuccessfulUpload = (file_id: string) => {
    onSuccessfulUpload(file_id);
    closeUploadModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 place-items-center">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Image Gallery
        </Dialog.Title>
        <div className="grid gap-4 grid-cols-1 xs:grid-cols-2 md:grid-cols-3">
          {images.map((image) => (
            <ImageTile
              key={image.id}
              image={image}
              setMainImage={setMainImage}
              deleteImage={deleteImage}
            />
          ))}
        </div>
        <PrimaryButton onClick={openUploadModal}>Upload image</PrimaryButton>
        <UploadModal
          resizeImage
          title="Upload Image"
          isOpen={isOpenUploadModal}
          closeModal={closeUploadModal}
          onSuccessfulUpload={handleSuccessfulUpload}
        />
      </div>
    </Modal>
  );
};

export default ImageGalleryModal;
