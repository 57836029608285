import React from 'react';

import Modal from '../../../modal';
import PrimaryButton from '../../../../framework-components/button/Button';

type Field =
  | 'monitoring_form_first'
  | 'monitoring_form_second'
  | 'monitoring_form_debrief';

interface MonitoringFormDeleteModalProps {
  isDeleteModalOpen: boolean;
  onClose: () => void;
  onDelete?: (
    field: Field | undefined,
    source: string | undefined
  ) => Promise<void>;
  source?: string;
  field?: Field;
}

const MonitoringFormDeleteModal: React.FC<MonitoringFormDeleteModalProps> = ({
  isDeleteModalOpen,
  onClose,
  onDelete,
  field,
  source,
}) => {
  const handleConfirmDelete = () => {
    if (source && field && onDelete) {
      onDelete(field, source);
    }
  };

  return (
    <Modal isOpen={isDeleteModalOpen} onClose={onClose} variant="small">
      <div className="flex flex-col justify-center items-center gap-6">
        <h1 className="text-center">
          Are you sure you want to delete this monitoring form?
        </h1>
        <PrimaryButton onClick={handleConfirmDelete}>Delete</PrimaryButton>
      </div>
    </Modal>
  );
};

export default MonitoringFormDeleteModal;
