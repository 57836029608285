import React, { ReactNode, useContext } from 'react';

import { PermissionName } from '../../../auto/permissions';
import { LoggedInUserContext } from '../../../context-providers/logged-in-user';
import { hasPermission } from '../../../utils/permissions';

interface Props {
  permissionName: PermissionName;
  children: ReactNode;
}

const RequirePermission: React.FC<Props> = (props: Props) => {
  const loggedInUser = useContext(LoggedInUserContext);
  if (
    loggedInUser &&
    !loggedInUser.is_impersonate &&
    hasPermission(loggedInUser, 'General.ACCESS_ADMIN') &&
    hasPermission(loggedInUser, props.permissionName)
  ) {
    return <>{props.children}</>;
  }

  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white">
      <div className="flex flex-col gap-y-4 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-24 h-24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
        <h1 className="text-h4">You do not have permission to view this.</h1>
      </div>
    </div>
  );
};

export default RequirePermission;
