import React from 'react';
import { mutate } from 'swr';

import { GeneralHospitalInfoData } from './types';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateGeneralHospitalInfo } from './actions';

interface Props {
  hospitalId: string;
}

const GeneralHospitalInfo: React.FC<Props> = ({ hospitalId }) => {
  return (
    <ManageSingleObject<GeneralHospitalInfoData>
      title="General hospital info:"
      modalTitle="General hospital info"
      getUrl={`/api/admin/hospital/${hospitalId}/general-hospital-info/get/`}
      descriptionFields={[
        {
          label: 'Hospital name',
          render: (item) => item.display_name,
        },
        {
          label: 'Main contact',
          render: (item) => item.main_contact,
        },
        {
          label: 'Main contact position',
          render: (item) => item.main_contact_role,
        },
      ]}
      formFields={[
        {
          type: 'smalltext',
          name: 'display_name',
          label: 'Hospital name',
        },
        {
          type: 'smalltext',
          name: 'main_contact',
          label: 'Main contact',
        },
        {
          type: 'smalltext',
          name: 'main_contact_role',
          label: 'Main contact position',
        },
      ]}
      submitItem={(data) => updateGeneralHospitalInfo(data, hospitalId)}
      onSubmitSuccess={() => {
        mutate(`/api/admin/hospital/${hospitalId}/general-hospital-info/get/`);
      }}
    />
  );
};

export default GeneralHospitalInfo;
