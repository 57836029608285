import React from 'react';
import { mutate } from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { useAPI } from '../../../../api/api';
import Table from '../../../manage-multiple-objects/Table';
import { formatDateToReadableStringShorthandMonth } from '../../../../utils/dates';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import RecordPaymentModal from './RecordPaymentModal';
import RecordRefundModal from './RecordRefundModal';
import RecordChargeModal from './RecordChargeModal';
import RecordAdjustmentModal from './RecordAdjustmentModal';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import {
  CURRENCY,
  CURRENCY_TO_SYMBOL,
} from '../../../booking/booking-form/constants';
import { TxType, PaymentType, ChargeType } from '../../../../types';
import { CHARGE_TYPE_LABELS, PAYMENT_TYPE_LABELS } from '../../../../constants';
import { CurrencyType } from '../../../booking/booking-form/types';
import { ConfirmedBookingTableDataBalanceStatus } from '../booking-list/types';
import { balanceStatusToIcon } from '../utils';

export interface LedgerItem {
  id: string;
  created: string;
  currency: CurrencyType;
  amount_pence: number;
  tx_type: TxType;
  payment_type: PaymentType;
  charge_type: ChargeType;
  reference: string;
}

interface Props {
  bookingId: string;
}

export interface LedgerData {
  ledger_items: Array<LedgerItem>;
  remaining_balance: number;
  balance_status: ConfirmedBookingTableDataBalanceStatus;
  days: number | null;
  due_date: string;
}

const renderRowColor = (item: LedgerItem) => {
  if (item.amount_pence === 0) {
    return 'bg-wtw-gray-3';
  }
  if (item.tx_type === 'CREDIT') {
    return 'bg-wtw-trafficlight-red';
  } else if (item.tx_type === 'DEBIT') {
    return 'bg-wtw-trafficlight-green';
  }
  return 'bg-wtw-gray-1';
};

const LedgerTable: React.FC<Props> = ({ bookingId }) => {
  const {
    isOpen: paymentModalIsOpen,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModalState();

  const {
    isOpen: refundModalIsOpen,
    openModal: openRefundModal,
    closeModal: closeRefundModal,
  } = useModalState();

  const {
    isOpen: chargeModalIsOpen,
    openModal: openChargeModal,
    closeModal: closeChargeModal,
  } = useModalState();

  const {
    isOpen: adjustmentModalIsOpen,
    openModal: openAdjustmentModal,
    closeModal: closeAdjustmentModal,
  } = useModalState();

  const { data, isValidating, error } = useAPI<LedgerData>(
    `/api/admin/bookings/${bookingId}/get-ledger-list/`
  );

  const renderTable = () => {
    if (isValidating) {
      return <LoadingOverlay />;
    }

    if (error) {
      return <ErrorOverlay />;
    }

    if (data?.ledger_items) {
      return (
        <Table<LedgerItem>
          data={data.ledger_items}
          tableFields={[
            {
              header: 'Type',
              name: 'type',
              render: (item) => {
                if (item.tx_type === 'CREDIT') {
                  return CHARGE_TYPE_LABELS[item.charge_type];
                }
                return PAYMENT_TYPE_LABELS[item.payment_type];
              },
            },
            {
              header: 'Description',
              name: 'reference',
              render: (item) => item.reference,
            },
            {
              header: 'Amount',
              name: 'amount_pence',
              render: (item) =>
                `${CURRENCY_TO_SYMBOL[item.currency]}${(
                  item.amount_pence / 100
                ).toFixed(2)}`,
            },
            {
              header: 'Date',
              name: 'created',
              render: (item) =>
                formatDateToReadableStringShorthandMonth(item.created),
            },
          ]}
          renderRowColor={renderRowColor}
        />
      );
    }
  };

  const amountInPounds = data ? (data.remaining_balance / 100).toFixed(2) : '';

  return (
    <AdminWidgetContainer hasBorder hasShadow title="Ledger">
      <div className="flex flex-col gap-4 mt-4 min-h-[200px] overflow-x-scroll">
        <div className="flex justify-end p-2">
          <Link to={`/${bookingId}/finance-statement/`}>
            <SecondaryButton containerClassName="space-x-2.5">
              <span>View statement</span>
              <FontAwesomeIcon icon={faEye} />
            </SecondaryButton>
          </Link>
        </div>
        {renderTable()}
        <p>
          Remaining balance:{' '}
          {data
            ? `${
                CURRENCY_TO_SYMBOL[data.ledger_items[0].currency]
              }${amountInPounds}`
            : ''}
        </p>
        <div className="flex gap-2">
          <p>
            Status: {data?.balance_status}{' '}
            {balanceStatusToIcon(data?.balance_status)}
          </p>
        </div>
        {data &&
          data.balance_status !==
            ConfirmedBookingTableDataBalanceStatus.COMPLETE && (
            <div className="flex gap-2">
              <p>
                Due date:{' '}
                {formatDateToReadableStringShorthandMonth(data.due_date)}
              </p>
              {data.balance_status ===
                ConfirmedBookingTableDataBalanceStatus.OVERDUE &&
                data.days && (
                  <p className="text-wtw-secondary-text">
                    {' '}
                    (due {data.days} days ago)
                  </p>
                )}
              {data.balance_status ===
                ConfirmedBookingTableDataBalanceStatus.PENDING &&
                data.days && (
                  <p className="text-wtw-secondary-text">
                    {' '}
                    (due in {data.days} days)
                  </p>
                )}
            </div>
          )}
        <div className="flex flex-col sm:flex-row gap-2 w-full">
          <PrimaryButton
            label="Record BACS Payment"
            type="button"
            onClick={openPaymentModal}
            className="sm:w-1/2"
          />
          <PrimaryButton
            label="Record BACS Refund"
            type="button"
            onClick={openRefundModal}
            className="sm:w-1/2"
          />
          <PrimaryButton
            label="Record Charge"
            type="button"
            onClick={openChargeModal}
            className="sm:w-1/2"
          />
          <PrimaryButton
            label="Record Adjustment"
            type="button"
            onClick={openAdjustmentModal}
            className="sm:w-1/2"
          />
        </div>
        <RecordPaymentModal
          bookingId={bookingId}
          isOpen={paymentModalIsOpen}
          closeModal={closePaymentModal}
          reloadData={() => {
            mutate(`/api/admin/bookings/${bookingId}/get-finance-tab-warning/`);
            mutate(`/api/admin/bookings/${bookingId}/get-ledger-list/`);
          }}
          currency={data?.ledger_items[0].currency || CURRENCY.GBP}
        />
        <RecordRefundModal
          bookingId={bookingId}
          isOpen={refundModalIsOpen}
          closeModal={closeRefundModal}
          reloadData={() => {
            mutate(`/api/admin/bookings/${bookingId}/get-finance-tab-warning/`);
            mutate(`/api/admin/bookings/${bookingId}/get-ledger-list/`);
          }}
          currency={data?.ledger_items[0].currency || CURRENCY.GBP}
        />
        <RecordChargeModal
          bookingId={bookingId}
          isOpen={chargeModalIsOpen}
          closeModal={closeChargeModal}
          reloadData={() => {
            mutate(`/api/admin/bookings/${bookingId}/get-finance-tab-warning/`);
            mutate(`/api/admin/bookings/${bookingId}/get-ledger-list/`);
          }}
          currency={data?.ledger_items[0].currency || CURRENCY.GBP}
        />
        <RecordAdjustmentModal
          bookingId={bookingId}
          isOpen={adjustmentModalIsOpen}
          closeModal={closeAdjustmentModal}
          reloadData={() => {
            mutate(`/api/admin/bookings/${bookingId}/get-finance-tab-warning/`);
            mutate(`/api/admin/bookings/${bookingId}/get-ledger-list/`);
          }}
          currency={data?.ledger_items[0].currency || CURRENCY.GBP}
        />
      </div>
    </AdminWidgetContainer>
  );
};

export default LedgerTable;
