import React from 'react';

interface LabelProps {
  htmlFor: string;
  children: React.ReactNode;
}

const FormLabel: React.FC<LabelProps> = ({ htmlFor, children }) => (
  <label htmlFor={htmlFor} className=" text-sm leading-6 text-gray-900 w-1/2">
    {children}
  </label>
);

export default FormLabel;
