import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../Logo';
import Navbar from '../../../framework-components/navbar/Navbar';

interface BookingFormContainerProps {
  children?: React.ReactNode;
}

const BookingFormContainer: React.FC<BookingFormContainerProps> = ({
  children,
}) => {
  return (
    <>
      <Navbar
        appLogo={
          <div className="flex flex-row">
            <Link to="https://www.worktheworld.co.uk">
              <Logo width={200} height={48} />
            </Link>
          </div>
        }
        navigation={[
          {
            name: '01273 974634',
            href: 'tel:01273974634',
          },
          {
            name: 'MyTrip Login',
            href: '/admin/enquiries/new',
          },
        ]}
      />
      <div>{children}</div>
      <footer className="w-full mx-auto bg-white">
        <div className="flex flex-col md:flex md:flex-row p-4 justify-around">
          <div className="flex flex-col">
            <h1 className="text-md-bold">Contact Us</h1>
            <p>
              Call: <a href="tel:01273974634"> +44 (0) 1273974634</a>
            </p>
            <p>
              Email:{' '}
              <a
                href="mailto:info@worktheworld.com"
                className="text-wtw-logo-blue hover:text-wtw-dark-blue"
              >
                info@worktheworld.com
              </a>
            </p>
          </div>
          <div className="flex flex-col items-left md:items-end md:space-x-2">
            <p>
              <a
                href="https://www.worktheworld.co.uk/cookie-policy"
                className="text-wtw-logo-blue hover:text-wtw-dark-blue"
              >
                Cookie Policy
              </a>
            </p>
            <p>
              <a
                href="https://www.worktheworld.co.uk/privacy-policy"
                className="text-wtw-logo-blue hover:text-wtw-dark-blue"
              >
                Privacy Notice
              </a>
            </p>
            <p>
              <a
                href="https://www.worktheworld.co.uk/terms-and-conditions"
                className="text-wtw-logo-blue hover:text-wtw-dark-blue"
              >
                Terms & Conditions
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default BookingFormContainer;
