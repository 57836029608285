import React from 'react';

export const handlePrint = (ref: React.RefObject<HTMLDivElement>) => {
  const printContents = ref.current?.innerHTML;

  // Get the Tailwind CSS styles
  const styleSheets = Array.from(document.styleSheets)
    .map((styleSheet) => {
      let cssRules = '';
      try {
        cssRules = Array.from(styleSheet.cssRules)
          .map((rule) => rule.cssText)
          .join('');
      } catch (e) {
        console.warn('Could not access CSS rules', e); // eslint-disable-line no-console
      }
      return cssRules;
    })
    .join('');

  const printWindow = window.open('', '', 'height=500,width=800');
  if (!printWindow || !printContents) {
    return;
  }

  printWindow.document.write('<html><head><title>Print</title>');
  printWindow.document.write(`<style>${styleSheets}</style>`);
  printWindow.document.write('</head><body>');
  printWindow.document.write(printContents);
  printWindow.document.write('</body></html>');
  printWindow.document.close();

  printWindow.onload = () => {
    printWindow.focus();

    printWindow.onafterprint = () => {
      printWindow.close();
    };

    printWindow.print();
  };
};
