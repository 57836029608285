import React, { useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { Option } from '../../../types';
import AdminWidgetContainer from '../../../framework-components/card/AdminWidgetContainer';
import { useAPI } from '../../../api/api';
import { Booking, MyBookingsContext } from '../context-providers';
import { ClinicalInterestData } from './types';
import LoadingOverlay from '../components/LoadingOverlay';
import ErrorOverlay from '../components/ErrorOverlay';
import { calculateDaysBetweenDates } from '../../../utils/dates';
import TextArea from '../components/TextArea';
import { PrimaryButton } from '../components/Button';
import { useSubmitFormToAPI } from '../../../api/forms';
import RemoteDropdown from '../components/dropdown/RemoteDropdown';
import LocalDropdown from '../components/dropdown/LocalDropdown';
import ErrorText from '../../error-text';

interface FormValues {
  sections: {
    hospital_department: Option<string>;
    num_weeks: Option<number> | null;
  }[];

  clinicalInterestDetails: string;
}

const validationSchema = Yup.object({
  sections: Yup.array().of(
    Yup.object({
      hospital_department: Yup.object({
        label: Yup.string().required('Interest is required'),
        value: Yup.string().required('Interest is required'),
      }),
      num_weeks: Yup.object({
        value: Yup.number().required('Duration is required'),
      }),
    })
  ),
});

function useOnSubmit(
  bookingId: string | null
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI(
    `/api/my-trip/clinical-interests/${bookingId}/update/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const clinicalInterestList = [];
      for (let i = 0; i < data.sections.length; i++) {
        clinicalInterestList.push({
          hospital_department: data.sections[i].hospital_department.value,
          num_weeks: data.sections[i].num_weeks?.value,
        });
      }
      const submissionData = {
        booking_id: bookingId,
        clinical_interests: clinicalInterestList,
        details: data.clinicalInterestDetails,
      };
      const [hasSucceeded] = await submitForm(submissionData, formHelpers);
      if (hasSucceeded) {
        toast.success('Clinical interests updated');
      }
      if (!hasSucceeded) {
        toast.error('Failed to update clinical interests');
      }
    },
    [submitForm, bookingId]
  );
}

const ClinicalInterests: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const bookingId = activeBooking ? activeBooking.id : null;

  const {
    data: selectedClinicalInterests,
    isValidating: selectedClinicalInterestsIsValidating,
    error: selectedClinicalInterestsError,
  } = useAPI<ClinicalInterestData[]>(
    activeBooking
      ? `/api/my-trip/clinical-interests/${activeBooking.id}/`
      : null
  );

  const isValidatingClinicalInterests = selectedClinicalInterestsIsValidating;
  const hasError = selectedClinicalInterestsError;

  const getNumWeeks = (
    booking: Booking | null
  ): ReadonlyArray<Option<number>> | null => {
    if (booking) {
      const startDate = new Date(booking.start_date);
      const endDate = new Date(booking.end_date);
      const weeksBetween = Math.round(
        calculateDaysBetweenDates(startDate, endDate) / 7
      );
      const numWeeks = [];
      for (let i = 0; i < weeksBetween; i++) {
        numWeeks.push({
          label: (i + 1).toString() + (i === 0 ? ' week' : ' weeks'),
          value: i + 1,
        });
      }

      return numWeeks;
    }
    return null;
  };

  const onSubmit = useOnSubmit(bookingId);
  const submitForm = useCallback(
    (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );
  return (
    <AdminWidgetContainer title="Clinical Interests" className="space-y-6">
      {isValidatingClinicalInterests && <LoadingOverlay />}
      {hasError && <ErrorOverlay />}

      {activeBooking && selectedClinicalInterests && (
        <Formik<FormValues>
          initialValues={{
            sections:
              selectedClinicalInterests.length !== 0
                ? selectedClinicalInterests.map((clinicalInterest) => {
                    return {
                      hospital_department: {
                        label:
                          clinicalInterest.hospital_department.display_name,
                        value: clinicalInterest.hospital_department.id,
                        description: clinicalInterest.hospital_department
                          .hospital.display_name
                          ? clinicalInterest.hospital_department.hospital
                              .display_name
                          : clinicalInterest.hospital_department.hospital
                              .formal_name,
                      },
                      num_weeks: {
                        label: `${clinicalInterest.num_weeks.toString()}${
                          clinicalInterest.num_weeks === 1 ? ' week' : ' weeks'
                        }`,
                        value: clinicalInterest.num_weeks,
                      },
                    };
                  })
                : [
                    {
                      hospital_department: { label: '', value: '' },
                      num_weeks: null,
                    },
                  ],

            clinicalInterestDetails:
              selectedClinicalInterests.length >= 1
                ? selectedClinicalInterests[0].details
                : '',
          }}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                {activeBooking && selectedClinicalInterests ? (
                  <div>
                    <FieldArray
                      name="sections"
                      render={(arrayHelpers) => (
                        <div>
                          {values.sections.map((_section, index) => (
                            <div key={index} className="mb-4">
                              <div className="flex flex-col w-full sm:flex-row">
                                <div className="pb-3 md:pb-0 w-full pr-3">
                                  <RemoteDropdown
                                    name={`sections[index].hospital_department.label`}
                                    path={`/api/my-trip/clinical-interests/${activeBooking.id}/get-dropdown-options/`}
                                    onSelect={(
                                      option: Option<string> | null
                                    ) => {
                                      setFieldValue(
                                        `sections[${index}].hospital_department`,
                                        option
                                      );
                                    }}
                                    value={
                                      values.sections[index].hospital_department
                                    }
                                    label="Interest:"
                                  />
                                </div>
                                <div className="w-full sm:w-2/5">
                                  <div className="pr-3 sm:align-items-center inline-flex w-full">
                                    <LocalDropdown
                                      name="sections[index].num_weeks"
                                      onSelect={(
                                        option: Option<number> | null
                                      ) => {
                                        setFieldValue(
                                          `sections[${index}].num_weeks`,
                                          option
                                        );
                                      }}
                                      options={getNumWeeks(activeBooking)}
                                      value={values.sections[index].num_weeks}
                                      placeholder="Select"
                                      label="Duration:"
                                    />
                                    <div className="flex align-center justify-center items-end pl-2 pb-4 ">
                                      {values.sections.length > 1 && (
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                          }}
                                          data-testid="delete-icon"
                                          className="text-wtw-logo-blue hover:cursor-pointer"
                                        >
                                          <path
                                            d="M15.8335 5.83333L15.1107 15.9521C15.0484 16.8243 14.3227 17.5 13.4483 17.5H6.55203C5.67763 17.5 4.9519 16.8243 4.8896 15.9521L4.16683 5.83333M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M12.5002 5.83333V3.33333C12.5002 2.8731 12.1271 2.5 11.6668 2.5H8.3335C7.87326 2.5 7.50016 2.8731 7.50016 3.33333V5.83333M3.3335 5.83333H16.6668"
                                            stroke="#2568EB"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="text-wtw-logo-blue hover:cursor-pointer"
                                          />
                                        </svg>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div
                            className="inline-flex justify-items-end my-4 hover:cursor-pointer"
                            onClick={() => {
                              arrayHelpers.push({
                                hospital_department: { label: '', value: '' },
                                num_weeks: null,
                              });
                            }}
                          >
                            <FontAwesomeIcon
                              className="text-wtw-logo-blue text-center pt-1 pr-2"
                              icon={faPlus}
                            />
                            <span className=" flex font-semibold text-wtw-logo-blue">
                              add clinical interest
                            </span>
                          </div>
                        </div>
                      )}
                    />

                    <div>
                      <TextArea
                        name="clinicalInterestDetails"
                        value={values.clinicalInterestDetails}
                        onChange={(e) => {
                          setFieldValue(
                            'clinicalInterestDetails',
                            e.target.value
                          );
                        }}
                        placeholder="Enter anything else here"
                        classNameOverride="block bg-wtw-gray-2 resize-none w-full border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:resize-none"
                      />
                    </div>
                    <div className="flex justify-end w-full">
                      <PrimaryButton
                        label="Save"
                        type="submit"
                        className="mt-4 "
                      />
                    </div>
                  </div>
                ) : (
                  <div>Loading</div>
                )}
                {Object.keys(errors).length !== 0 &&
                  !errors.clinicalInterestDetails && (
                    <div className="text-red-400 text-sm text-center pt-1">
                      Please complete all required fields
                    </div>
                  )}
                {errors.clinicalInterestDetails && (
                  <ErrorText className="text-sm">
                    {errors.clinicalInterestDetails}
                  </ErrorText>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </AdminWidgetContainer>
  );
};

export default ClinicalInterests;
