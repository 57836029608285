import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import SearchDropdownOptions from './SearchDropdownOptions';
import { SearchButtonProps } from './types';
import Loading from '../loading/Loading';
import LocalDropdown from '../dropdown/LocalDropdown';

const SearchButton = <T,>({
  placeholder = 'Find anything...',
  onSearch,
  renderAction,
  debounceTime = 250,
  onSelectItem,
  showBackgroundColor = true,
  filters,
  getNotes,
  setSelectedNote,
  setSelectedFilters,
}: SearchButtonProps<T>) => {
  const [options, setOptions] = React.useState<ReadonlyArray<T>>([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [hasInitialized, setHasInitialized] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const searchAndSetOptions = useMemo(() => {
    return debounce((query: string) => {
      onSearch(query).then((searchableOptions) => {
        setOptions(searchableOptions);
        setIsLoading(false);
      });
    }, debounceTime);
  }, [debounceTime, setIsLoading, onSearch]);

  useEffect(() => {
    if (!hasInitialized) {
      setHasInitialized(true);
      setIsLoading(false);
    }
    if (modalVisible) {
      searchAndSetOptions('');
    }
  }, [
    modalVisible,
    searchAndSetOptions,
    hasInitialized,
    setHasInitialized,
    setIsLoading,
  ]);

  const modalBackgroundRef = React.useRef<HTMLDivElement>(null);

  const handleInputKeyDownEventHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        setModalVisible(false);
      }
    },
    [setModalVisible]
  );

  const handleInputChangeEventHandler = useMemo(() => {
    return debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      searchAndSetOptions(e.target.value);
    }, debounceTime);
  }, [debounceTime, searchAndSetOptions]);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const onModalBackgroundClickEventHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === modalBackgroundRef.current) {
        if (setSelectedNote) {
          setSelectedNote(null);
        }
        if (getNotes) {
          getNotes({
            author: { label: '', value: '' },
            tag: { label: '', value: '' },
          });
        }
        setModalVisible(false);
      }
    },
    [setModalVisible, setSelectedNote, getNotes]
  );

  const renderSearchResults = () => {
    if (isLoading) {
      return (
        <div className="w-full bg-slate-50 px-[64px] py-[80px] flex justify-center">
          <Loading />
        </div>
      );
    }

    const hasSearchResults = options && options.length > 0;

    if (!hasSearchResults)
      return (
        <div className="bg-slate-50 px-[64px] py-[80px] text-center">
          <h2 className="font-semibold text-slate-900">No results found</h2>
          <p className="mt-md text-sm leading-6 text-slate-600">
            We can't find anything with that term at the moment, try searching
            something else.
          </p>
        </div>
      );

    const onSearchDropOptionSelectedHandler = (option: T) => {
      onSelectItem(option);
      setModalVisible(false);
    };

    return (
      <SearchDropdownOptions
        onClick={onSearchDropOptionSelectedHandler}
        options={options}
        renderAction={renderAction}
      />
    );
  };

  const renderAdditionalFilters = () => {
    if (!filters) return null;

    return (
      <div className="w-full flex flex-wrap bg-gray-50">
        {filters.map((filter, index) => (
          <div key={index} className="w-1/2 relative shadow-sm p-2.5">
            <LocalDropdown
              label={filter.label}
              options={filter.options}
              value={filter.value}
              onSelect={filter.onSelect}
              name={filter.name}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <button
        className={classNames('flex p-sm rounded-md h-[48px]', {
          'bg-wtw-gray-1': showBackgroundColor,
        })}
        onClick={() => {
          if (setSelectedFilters) {
            setSelectedFilters({
              author: {
                label: '',
                value: '',
              },
              tag: {
                label: '',
                value: '',
              },
            });
          }
          showModal();
        }}
      >
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="h-xl w-xl wtw-gray-2"
          aria-hidden="true"
        />
      </button>

      {modalVisible && (
        <div className="fixed top-0 left-0 w-full h-full inset-0 bg-gray-900 bg-opacity-50 flex justify-center pt-[64px] sm:pt-[96px] z-50">
          <div
            ref={modalBackgroundRef}
            onClick={onModalBackgroundClickEventHandler}
            className="fixed inset-0 bg-slate-900/25 backdrop-blur"
          />
          <div className="fixed w-full max-w-lg transform pl-sm pr-sm">
            <div className="overflow-hidden flex flex-wrap rounded-lg bg-white">
              <div className="w-full relative shadow-sm">
                <input
                  id="search"
                  type="text"
                  name="search"
                  placeholder={placeholder}
                  className="block w-full appearance-none bg-transparent py-sm pl-sm pr-xl text-base text-slate-900 placeholder:text-slate-600 border-transparent focus:border-transparent focus:ring-0 sm:text-sm sm:leading-6"
                  onChange={handleInputChangeEventHandler}
                  onKeyDown={handleInputKeyDownEventHandler}
                  autoComplete="off"
                  autoFocus
                />
                <div className="pointer-events-none absolute right-xl top-xl h-2xl w-2xl fill-slate-400">
                  <MagnifyingGlassIcon
                    className="h-full w-full text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {renderAdditionalFilters()}
              {renderSearchResults()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchButton;
