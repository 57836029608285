import React, { useRef, useState } from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../framework-components/button/Button';
import { DepartmentAndSupervisorData } from '../types';
import SelectDepartments from './SelectDepartments';
import UpdateDepartments from './UpdateDepartments';
import { Option } from '../../../../../types';

type Stage = 0 | 1;

interface Props {
  hospitalId: string;
  departments: Array<DepartmentAndSupervisorData>;
  isOpen: boolean;
  closeModal: () => void;
  disciplines: Array<Option>;
}

const ManageDepartmentsModal: React.FC<Props> = ({
  hospitalId,
  departments,
  isOpen,
  closeModal,
  disciplines,
}) => {
  const focusRef = useRef<HTMLDivElement>(null);
  const [stage, setStage] = useState<Stage>(0);

  const setStageAndScrollToTop = (newStage: Stage) => {
    setStage(newStage);
    if (focusRef.current) {
      focusRef.current.scrollIntoView();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="space-y-4" data-testid="delete-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Manage departments
        </Dialog.Title>
        {stage === 0 ? (
          <SelectDepartments
            hospitalId={hospitalId}
            departments={departments}
          />
        ) : (
          <UpdateDepartments
            hospitalId={hospitalId}
            departments={departments}
            disciplines={disciplines}
          />
        )}
        <div className="flex justify-end space-x-4">
          <SecondaryButton
            onClick={stage === 0 ? closeModal : () => setStageAndScrollToTop(0)}
          >
            {stage === 0 ? 'Close' : 'Back'}
          </SecondaryButton>
          <PrimaryButton
            onClick={stage === 1 ? closeModal : () => setStageAndScrollToTop(1)}
          >
            {stage === 0 ? 'Next' : 'Close'}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ManageDepartmentsModal;
