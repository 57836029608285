import React, { useCallback, useContext } from 'react';

import { Option } from '../../../../types';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import Calendar from '../../../calendar/Calendar';
import SelectDates from '../SelectDates';
import EditSaveButtons from '../EditSaveButtons';
import { useCapacityCalendar } from '../hooks';
import { EventMetadata, SectionMetadata } from './types';
import { checkIsSectionDisabled, getOverlayColour } from './utils';
import { getEventForDay, onDropOnDay, getIsLocked } from '../utils';
import { CalendarLock } from '../types';
import { CALENDAR_TYPES } from '../constants';
import { acquireCalendarLock } from '../actions';
import { LoggedInUserContext } from '../../../../context-providers/logged-in-user';

interface Props {
  dayQueryParam: string | null;
  monthQueryParam: string | null;
  yearQueryParam: string | null;
  period: string;
  destination: Option | null;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  calendarLock: CalendarLock | undefined;
}

const DepartmentCapacityCalendar: React.FC<Props> = ({
  dayQueryParam,
  monthQueryParam,
  yearQueryParam,
  period,
  destination,
  isEditing,
  setIsEditing,
  calendarLock,
}) => {
  const {
    selectedDates,
    setSelectedDates,
    events,
    setEvents,
    sections,
    errors,
    startDate,
    endDate,
    handleCancelEditing,
    handleSaveEvents,
    selectedEvent,
    setSelectedEvent,
  } = useCapacityCalendar<EventMetadata, SectionMetadata>(
    destination,
    dayQueryParam,
    monthQueryParam,
    yearQueryParam,
    period,
    `/api/admin/calendar/hospital-department-bookings/${destination?.value}/`,
    `/api/admin/calendar/hospital-department-sections/${destination?.value}/`,
    true,
    '/api/admin/calendar/update-hospital-department-bookings/',
    setIsEditing
  );

  const user = useContext(LoggedInUserContext);

  const handleAcquireLock = useCallback(async () => {
    return acquireCalendarLock(CALENDAR_TYPES.hospital_department);
  }, []);

  if (errors.events || errors.sections) {
    return <ErrorOverlay detail={errors.events || errors.sections} />;
  }

  const isLocked = getIsLocked(calendarLock, user);

  return (
    <div className="border border-gray-300 bg-white rounded-lg w-fit">
      <div className="flex flex-wrap justify-start items-end gap-4 p-4">
        <SelectDates
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          isDisabled={isEditing}
        />
        {calendarLock && (
          <EditSaveButtons
            startDate={startDate}
            endDate={endDate}
            destination={destination}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            handleCancelEditing={handleCancelEditing}
            handleSaveEvents={handleSaveEvents}
            isLocked={isLocked}
            acquireLock={handleAcquireLock}
            calendarType={CALENDAR_TYPES.hospital_department}
          />
        )}
      </div>
      {startDate && endDate && destination && (
        <div className="p-4">
          <Calendar<EventMetadata, SectionMetadata>
            isEditing={isEditing}
            startDate={startDate}
            endDate={endDate}
            events={events}
            setEvents={setEvents}
            sections={sections}
            getEventForDay={getEventForDay}
            onDropOnDay={onDropOnDay}
            checkIsSectionDisabled={checkIsSectionDisabled}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            getOverlayColour={getOverlayColour}
            splitEndOffset={-2}
          />
        </div>
      )}
      {!destination && (
        <div className="px-4 pb-4">Please select a location.</div>
      )}
      {(!startDate || !endDate) && (
        <div className="px-4 pb-4">Please select a year, month and period.</div>
      )}
    </div>
  );
};

export default DepartmentCapacityCalendar;
