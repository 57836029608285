import { apiRequest } from '../../../api/api';
import { IndemnityInsurance, TravelInsurance } from './types';

export const updateTravelInsurance = (
  bookingId: string,
  data: TravelInsurance
) => {
  const url = `/api/my-trip/travel-details/${bookingId}/set-travel-insurance/`;
  return apiRequest<TravelInsurance>(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const updateIndemnityInsurance = (
  bookingId: string,
  data: IndemnityInsurance
) => {
  const url = `/api/my-trip/travel-details/${bookingId}/set-indemnity-insurance/`;
  return apiRequest<IndemnityInsurance>(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};
