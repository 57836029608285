import React, { useCallback } from 'react';
import { mutate } from 'swr';

import ManageSingleObject from '../../manage-single-object/ManageSingleObject';
import Anchor from '../common/Anchor';
import EditDetailsModal from '../../manage-single-object/EditDetailsModal';
import { apiRequest, useAPI } from '../../../api/api';
import ErrorOverlay from '../common/error/ErrorOverlay';
import useModalState from '../../../hooks/modals';
import { FieldType } from '../../../framework-components/quickform/Field';
import { PencilEditButton } from '../../../framework-components/button/PencilEditButton';
import UploadImageModal from '../common/UploadModal';
import { setStaffProfilePhoto } from './actions';
import Spinner from '../../spinner';
import StaffProfilePhoto from './StaffProfilePhoto';

interface Props {
  canEdit: boolean | null;
  userId: string;
}

const fields: Array<FieldType> = [
  {
    type: 'smalltext',
    name: 'first_name',
    label: 'First name',
  },
  {
    type: 'smalltext',
    name: 'last_name',
    label: 'Last name',
  },
  {
    type: 'smalltext',
    name: 'personal_telephone_number',
    label: 'Personal phone',
  },
  {
    type: 'smalltext',
    name: 'personal_email',
    label: 'Personal email',
  },
];

interface GetStaffSummaryFormData {
  name: string;
  personal_telephone_number: string;
  personal_email: string;
}

interface UpdateStaffSummaryFormData {
  first_name: string;
  last_name: string;
  personal_telephone_number: string;
  personal_email: string;
}

const StaffSummary: React.FC<Props> = ({ canEdit, userId }) => {
  const getUrl = `/api/admin/staff/${userId}/summary/get/`;
  const getProfilePictureUrl = `/api/admin/staff/${userId}/profile-picture/get/`;
  const { isOpen, openModal, closeModal } = useModalState();
  const {
    isOpen: isImageModalOpen,
    openModal: openImageModal,
    closeModal: closeImageModal,
  } = useModalState();

  const { data, error, isValidating } = useAPI<GetStaffSummaryFormData>(getUrl);

  const {
    data: profilePicture,
    error: profilePictureError,
    isValidating: isProfilePictureValidating,
  } = useAPI<{ profile_picture_url: string }>(getProfilePictureUrl);

  const renderProfileImage = () => {
    if (isProfilePictureValidating) {
      return (
        <div className="relative bg-wtw-gray-2 place-content-center place-items-center h-[112px] w-0 sm:w-1/3">
          <Spinner className="w-5 h-5" />
        </div>
      );
    }

    if (profilePictureError) {
      return (
        <div className="relative bg-wtw-gray-2 place-content-center place-items-center h-[112px] w-0 sm:w-1/3">
          Error
        </div>
      );
    }

    if (profilePicture) {
      return (
        <StaffProfilePhoto
          userId={userId}
          profilePicture={profilePicture}
          openImageModal={openImageModal}
        />
      );
    }
    return null;
  };

  const onStaffPhotoSuccessfulUpload = useCallback(
    async (fileId: string) => {
      await setStaffProfilePhoto(fileId, userId)
        .then(() => {
          mutate(getProfilePictureUrl);
        })
        .then(() => {
          closeImageModal();
        });
    },
    [closeImageModal, userId, getProfilePictureUrl]
  );
  const updateStaffSummary = (values: UpdateStaffSummaryFormData) => {
    const url = `/api/admin/staff/${userId}/summary/set/`;
    return apiRequest<UpdateStaffSummaryFormData>(url, {
      method: 'POST',
      body: JSON.stringify(values),
    });
  };

  if (!data && (isValidating || error)) {
    return (
      <div className="p-4 space-y-8">
        <>
          {isValidating && <Spinner className="w-5 h-5" />}
          {error && (
            <ErrorOverlay
              detail={error ? 'Failed to load page data' : undefined}
            />
          )}
        </>
      </div>
    );
  } else if (!data) {
    return null;
  }

  const initialValues: UpdateStaffSummaryFormData = {
    first_name: data.name.split(' ')[0],
    last_name: data.name.split(' ')[1],
    personal_telephone_number: data.personal_telephone_number,
    personal_email: data.personal_email,
  };

  return (
    <div>
      <div className="flow-root bg-wtw-gray-2 mb-4">
        <div className="flex w-full flex-col sm:flex-row">
          <div className="inline-flex  grow h-full w-full">
            {renderProfileImage()}
            <div className="h-full w-full">
              <ManageSingleObject
                editButton={false}
                insideCard={false}
                getUrl={getUrl}
                descriptionFields={[
                  {
                    label: 'Name',
                    render: (item) => {
                      return item.name;
                    },
                  },
                  {
                    label: 'Status',
                    render: (item) => {
                      return item.is_active ? 'Active' : 'Inactive';
                    },
                  },
                ]}
                formFields={[]}
              />
            </div>
          </div>
          <div className="grow h-full w-full">
            <ManageSingleObject
              insideCard={false}
              editButton={false}
              getUrl={getUrl}
              descriptionFields={[
                {
                  label: 'Personal email',
                  render: (item) => (
                    <Anchor href={`mailto:${item.personal_email}`}>
                      {item.personal_email}
                    </Anchor>
                  ),
                },
                {
                  label: 'Personal phone',
                  render: (item) => {
                    return item.personal_telephone_number;
                  },
                },
              ]}
              formFields={[]}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center sm:justify-end ">
        {canEdit && <PencilEditButton onClick={openModal} />}
      </div>
      {userId && (
        <EditDetailsModal<UpdateStaffSummaryFormData>
          isOpen={isOpen}
          closeModal={closeModal}
          submitItem={(values) => updateStaffSummary(values)}
          onSubmitSuccess={() => mutate(getUrl)}
          data={initialValues}
          fields={fields}
        />
      )}
      <UploadImageModal
        isOpen={isImageModalOpen}
        closeModal={closeImageModal}
        onSuccessfulUpload={onStaffPhotoSuccessfulUpload}
        title="Staff Profile Picture"
      />
    </div>
  );
};

export default StaffSummary;
