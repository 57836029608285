import React from 'react';

import ManageSingleObject from '../components/ManageSingleObject/ManageSingleObject';
import { IndemnityInsurance } from './types';
import { updateIndemnityInsurance } from './actions';

interface Props {
  booking_id: string;
}

const IndemnityInsuranceForm: React.FC<Props> = ({ booking_id }) => {
  return (
    <ManageSingleObject<IndemnityInsurance>
      submitItem={(values) => updateIndemnityInsurance(booking_id, values)}
      subtitle={
        <p className="mb-4">
          You must have professional indemnity cover for many of our placements.
          Please see your{' '}
          <a href="/my-trip/info-pack/" className="underline">
            information pack
          </a>{' '}
          for details.
        </p>
      }
      insideCard={false}
      buttonLabel="Update"
      title="Indemnity insurance"
      getUrl={`/api/my-trip/travel-details/${booking_id}/get-indemnity-insurance/`}
      formFields={[
        {
          type: 'smalltext',
          name: 'indemnity_insurance_provider',
          label: 'Indemnity provider',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'checkbox',
          name: 'indemnity_insurance_cover_confirmation',
          option: {
            value: 'true',
            label:
              'The indemnity provider has confirmed you are covered for this placement',
          },
        },
      ]}
    />
  );
};

export default IndemnityInsuranceForm;
