import React from 'react';

const DownloadIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.65625 13.5156L5.71875 10.0156C5.52734 9.87891 5.52734 9.57812 5.69141 9.41406C5.82812 9.22266 6.12891 9.22266 6.29297 9.35938L9.51953 12.2305V3.5625C9.51953 3.34375 9.71094 3.125 9.95703 3.125C10.1758 3.125 10.3945 3.34375 10.3945 3.5625V12.2305L13.5938 9.38672C13.7578 9.22266 14.0586 9.22266 14.1953 9.41406C14.3594 9.57812 14.3594 9.87891 14.168 10.0156L10.2305 13.5156C10.1484 13.5977 10.0391 13.625 9.95703 13.625C9.84766 13.625 9.73828 13.5977 9.65625 13.5156ZM15.207 11.875C16.1641 11.875 16.957 12.668 16.957 13.625V15.375C16.957 16.3594 16.1641 17.125 15.207 17.125H4.70703C3.72266 17.125 2.95703 16.3594 2.95703 15.375V13.625C2.95703 12.668 3.72266 11.875 4.70703 11.875H6.01953C6.23828 11.875 6.45703 12.0938 6.45703 12.3125C6.45703 12.5586 6.23828 12.75 6.01953 12.75H4.70703C4.21484 12.75 3.83203 13.1602 3.83203 13.625V15.375C3.83203 15.8672 4.21484 16.25 4.70703 16.25H15.207C15.6719 16.25 16.082 15.8672 16.082 15.375V13.625C16.082 13.1602 15.6719 12.75 15.207 12.75H13.8945C13.6484 12.75 13.457 12.5586 13.457 12.3125C13.457 12.0938 13.6484 11.875 13.8945 11.875H15.207ZM14.9883 14.5C14.9883 14.8828 14.6875 15.1562 14.332 15.1562C13.9492 15.1562 13.6758 14.8828 13.6758 14.5C13.6758 14.1445 13.9492 13.8438 14.332 13.8438C14.6875 13.8438 14.9883 14.1445 14.9883 14.5Z"
        fill="#2568EB"
      />
    </svg>
  );
};

export default DownloadIcon;
