import React, { useContext } from 'react';

import { MyBookingsContext } from '../context-providers';

const ClinicalInterestText: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  return (
    <>
      <p className="text-md">
        This page provides the opportunity to let us understand the specific
        areas you would like to focus on during your placement
        {activeBooking ? ` in ${activeBooking.destination.name}` : ''}.
      </p>
      <p className="text-md">
        The information you provide here will be discussed in full with your
        Operations Manager during the clinical interest call. It is therefore
        important you complete this section ahead of the call, to ensure the
        discussion is focused on your aims for the placement.
      </p>
      <p className="text-md">
        The list of departments is specific to those that are available in your
        destination. In the rare cases where a placement department is not
        available, you will be able to discuss this further during the clinical
        interest call.
      </p>
      <p className="text-md">
        Please note, the department you will be placed in will not be confirmed
        until after the clinical interest call. At this stage, the information
        you provide is only provisional.
        <br />
        Further information on the placement and options
        {activeBooking ? ` in ${activeBooking.destination.name} ` : ' '}
        can be found in the '
        <a
          className="text-wtw-logo-blue hover:text-wtw-dark-blue"
          href="/my-trip/info-pack/"
        >
          Information Pack
        </a>
        ' tab of your MyTrip timeline.
      </p>
    </>
  );
};

export default ClinicalInterestText;
