import React, { useContext } from 'react';

import WidgetContainer from '../components/WidgetContainer';
import { SecondaryButton } from '../components/Button';
import DownloadIcon from '../../icons/DownloadIcon';
import { download } from '../../../utils/download';
import { useAPI } from '../../../api/api';
import { MyBookingsContext } from '../context-providers';
import Loading from '../components/Loading';
import ErrorOverlay from '../components/ErrorOverlay';

interface AlumniDocumentType {
  name: string;
  link: string;
  description: string;
}

const Documents: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const bookingId = activeBooking ? activeBooking.id : null;
  const { data, isValidating, error } = useAPI<
    ReadonlyArray<AlumniDocumentType>
  >(
    activeBooking ? `/api/my-trip/alumni/${bookingId}/alumni-documents/` : null
  );

  if (isValidating && !error) {
    return <Loading />;
  }
  if (error) {
    return <ErrorOverlay />;
  }
  if (data && data.length === 0) {
    return (
      <WidgetContainer className="h-full space-y-6" title="Documents">
        <div className="space-y-4">
          <div className="flex flex-row justify-between bg-gray-100 p-2 items-center">
            <p className="text-gray-500">There are no documents to view.</p>
          </div>
        </div>
      </WidgetContainer>
    );
  }
  return (
    <WidgetContainer className="h-full space-y-6" title="Documents">
      {data?.map((doc) => (
        <div key={doc.name} className="space-y-4">
          <div className="flex flex-row justify-between bg-gray-100 p-2 items-center">
            <p>{doc.name}</p>
            <SecondaryButton
              paddingClassName="px-4 py-1"
              onClick={() => download(doc.link, doc.name)}
            >
              <DownloadIcon />
              Download
            </SecondaryButton>
          </div>
          <p className="text-gray-500">{doc.description}</p>
        </div>
      ))}
    </WidgetContainer>
  );
};

export default Documents;
