import { apiRequest } from '../../../../../api/api';
import {
  LocalContactCreateEditData,
  LocalMedicalExperienceCreateEditData,
  LocalOfficeCreateEditData,
} from './types';

export const updateLocalOfficeDetails = (
  destinationId: string,
  officeId: string,
  localOfficeDetails: LocalOfficeCreateEditData
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/local-office/${officeId}/update/`;
  return apiRequest<LocalOfficeCreateEditData>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...localOfficeDetails,
      primary_contact: localOfficeDetails.primary_contact?.value,
    }),
  });
};

export const addMedicalExperienceDetails = (
  destinationId: string,
  medicalExperienceDetails: LocalMedicalExperienceCreateEditData
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/medical-experience/create/`;
  return apiRequest<LocalMedicalExperienceCreateEditData>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...medicalExperienceDetails,
      discipline: medicalExperienceDetails.discipline?.value,
    }),
  });
};

export const updateMedicalExperienceDetails = (
  destinationId: string,
  localContactDetails: LocalMedicalExperienceCreateEditData
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/medical-experience/${localContactDetails.id}/update/`;
  return apiRequest<LocalMedicalExperienceCreateEditData>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...localContactDetails,
      discipline: localContactDetails.discipline?.value,
    }),
  });
};

export const removeMedicalExperienceDetails = (
  destinationId: string,
  experienceId: string
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/medical-experience/${experienceId}/delete/`;
  return apiRequest<LocalMedicalExperienceCreateEditData>(url, {
    method: 'POST',
  });
};

export const updateLocalContactDetails = (
  destinationId: string,
  localContactDetails: LocalContactCreateEditData
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/local-contacts/${localContactDetails.id}/update/`;
  return apiRequest<LocalContactCreateEditData>(url, {
    method: 'POST',
    body: JSON.stringify(localContactDetails),
  });
};

export const addLocalContact = (
  destinationId: string,
  contactDetails: LocalContactCreateEditData
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/local-contacts/create/`;
  return apiRequest<LocalContactCreateEditData>(url, {
    method: 'POST',
    body: JSON.stringify(contactDetails),
  });
};

export const removeLocalContact = (destinationId: string, staffId: string) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/local-contacts/${staffId}/delete/`;
  return apiRequest<LocalContactCreateEditData>(url, {
    method: 'POST',
  });
};
