import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

import { useAPI } from '../../../../api/api';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import { SecondaryButton } from '../../../../framework-components/button/Button';
import { CurrencyType } from '../../../booking/booking-form/types';
import { CURRENCY_TO_SYMBOL } from '../../../booking/booking-form/constants';

interface RowProps {
  index: number;
  title: string;
  value: ReactNode;
}

const Row: React.FC<RowProps> = ({ index, title, value }) => {
  return (
    <div
      className={classNames('p-4 flex items-center justify-between text-xs', {
        'bg-wtw-gray-1': index % 2 === 0,
        'bg-wtw-gray-3': index % 2 === 1,
      })}
    >
      <p>{title}</p>
      {value}
    </div>
  );
};

interface CancellationData {
  days_until_start: number;
  refund_due: number;
  refund_due_currency: CurrencyType;
}

interface Props {
  bookingId: string;
}

const CancellationSection: React.FC<Props> = ({ bookingId }) => {
  const { data, isValidating, error } = useAPI<CancellationData>(
    `/api/admin/bookings/${bookingId}/get-cancellation-data/`
  );

  const refundDue = data ? (data.refund_due / 100).toFixed(2) : '';

  return (
    <AdminWidgetContainer title="Cancellation">
      {isValidating && <LoadingOverlay />}
      {error && !isValidating && <ErrorOverlay />}
      {data && (
        <div className="mt-4">
          <Row
            index={0}
            title="Days until start"
            value={<p className="pr-4">{data.days_until_start}</p>}
          />
          <Row
            index={1}
            title="Refund due"
            value={
              <p className="pr-4">
                {`${CURRENCY_TO_SYMBOL[data.refund_due_currency]}${refundDue}`}
              </p>
            }
          />
          <Row
            index={2}
            title="Cancellation confirmation document - no refund"
            value={
              <Link
                reloadDocument
                to={`/letters/${bookingId}/cancelled-no-refund/`}
              >
                <SecondaryButton containerClassName="space-x-2.5">
                  <span>View</span>
                  <FontAwesomeIcon icon={faEye} />
                </SecondaryButton>
              </Link>
            }
          />
          <Row
            index={3}
            title="Cancellation confirmation document - 50% refund"
            value={
              <Link
                reloadDocument
                to={`/letters/${bookingId}/cancelled-partial-refund/`}
              >
                <SecondaryButton containerClassName="space-x-2.5">
                  <span>View</span>
                  <FontAwesomeIcon icon={faEye} />
                </SecondaryButton>
              </Link>
            }
          />
        </div>
      )}
    </AdminWidgetContainer>
  );
};

export default CancellationSection;
