import React from 'react';
import { useParams } from 'react-router';

import AdminStandardPage from '../../common/AdminStandardPage';
import { useAPI } from '../../../../api/api';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import { HospitalBasicInfo } from './types';
import BasicInfo from './BasicInfo';
import TwoColumns from '../../common/layout/TwoColumns';
import ImageGallery from '../../common/ImageGallery/ImageGallery';
import {
  createImage,
  getImages,
  setMainImage,
  deleteImage,
} from '../../common/ImageGallery/actions';
import QuickFacts from './QuickFacts';
import GeneralHospitalInfo from './GeneralHospitalInfo';
import CapacitiesAndStaff from './CapacitiesAndStaff';
import DepartmentsAndSupervisors from './DepartmentsAndSupervisors';
import PublicDescription from './PublicDescription';
import HospitalActions from './HospitalActions';
import HospitalStatus from './HospitalStatus';
import Location from './Location';

const HospitalDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isValidating, error } = useAPI<HospitalBasicInfo>(
    `/api/admin/hospital/${id}/basic-info/`
  );

  const HospitalName = data?.formal_name ?? '';

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Hospitals',
          href: '/admin/directories/hospitals',
        },
        {
          label: HospitalName,
          href: null,
        },
      ]}
      title={HospitalName}
      enableHome={false}
    >
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      {data && (
        <div className="space-y-6">
          <HospitalStatus active={data.active} />
          <BasicInfo data={data} />
        </div>
      )}
      <TwoColumns
        leftColumn={
          <>
            {id && <DepartmentsAndSupervisors hospitalId={id} />}
            {id && <QuickFacts hospitalId={id} />}
            {id && <GeneralHospitalInfo hospitalId={id} />}
            {id && <CapacitiesAndStaff hospitalId={id} />}
          </>
        }
        rightColumn={
          <>
            {id && <Location hospitalId={id} />}
            {id && <PublicDescription hospitalId={id} />}
            {id && (
              <ImageGallery
                title="Main hospital photo:"
                getImages={() => getImages('hospital_id', id)}
                setMainImage={(imageId: string) =>
                  setMainImage('hospital_id', id, imageId)
                }
                deleteImage={(imageId: string) =>
                  deleteImage('hospital_id', id, imageId)
                }
                onSuccessfulUpload={(file_id: string) =>
                  createImage('hospital_id', id, file_id)
                }
              />
            )}
            {id && data && (
              <HospitalActions hospitalId={id} isArchived={!data.active} />
            )}
          </>
        }
      />
    </AdminStandardPage>
  );
};

export default HospitalDetail;
