import { Option } from '../../../types';

export const SET_STAFF_PERMISSIONS = {
  ADMIN: 'ADMIN' as const,
  SUPER_USER: 'SUPER_USER' as const,
};

export const SET_STAFF_DATA = {
  ADMIN: 'ADMIN' as const,
  SUPER_USER: 'SUPER_USER' as const,
  OMAR_LEVEL: 'OMAR_LEVEL' as const,
};

export const adminRoleOptions: ReadonlyArray<Option<string>> = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'SALES', label: 'Sales' },
  { value: 'SALES_MANAGER', label: 'Sales Manager' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'MARKETING_MANAGER', label: 'Marketing Manager' },
  { value: 'FINANCE', label: 'Finance' },
  { value: 'OPERATIONS', label: 'Operations' },
  { value: 'PROGRAMME_MANAGER', label: 'Programme Manager {Overseas Team}' },
  { value: 'OVERSEAS_TEAM', label: 'Overseas Team' },
  { value: 'AIRPORT_REP', label: 'Airport Rep' },
];
export const jobTitleOptions: ReadonlyArray<Option<string>> = [
  { value: 'Destination Rep', label: 'Destination Rep' },
  { value: 'Destination Manager', label: 'Destination Manager' },
  { value: 'Office User', label: 'Office User' },
  { value: 'Office Admin', label: 'Office Admin' },
  { value: 'Senior Office Admin', label: 'Senior Office Admin' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Sales Manager', label: 'Sales Manager' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Marketing Manager', label: 'Marketing Manager' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Operations', label: 'Operations' },
  {
    value: 'Programme Manager {Overseas Team}',
    label: 'Programme Manager {Overseas Team}',
  },
  { value: 'Overseas Team', label: 'Overseas Team' },
  { value: 'Airport Rep', label: 'Airport Rep' },
];

export const SET_STAFF_PERMISSIONS_OPTIONS = [
  {
    label: 'Sales',
    value: 'SALES',
  },
  {
    label: 'Sales manager',
    value: 'SALES_MANAGER',
  },
  {
    label: 'Marketing',
    value: 'MARKETING',
  },
  {
    label: 'Marketing manager',
    value: 'MARKETING_MANAGER',
  },
  {
    label: 'Finance',
    value: 'FINANCE',
  },
  {
    label: 'Operations',
    value: 'OPERATIONS',
  },
  {
    label: 'Programme manager',
    value: 'PROGRAMME_MANAGER',
  },
  {
    label: 'Overseas team',
    value: 'OVERSEAS_TEAM',
  },
  {
    label: 'Airport rep',
    value: 'AIRPORT_REP',
  },
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Super user',
    value: 'SUPER_USER',
  },
];

export const SET_STAFF_DESCRIPTION = {
  ADMIN: 'ADMIN' as const,
  SUPER_USER: 'SUPER_USER' as const,
};
