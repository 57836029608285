import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateAddonDetails } from './actions';
import { AddonDetailsData } from './types';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const AddonDetailsSection: React.FC<Props> = ({ bookingId, canEdit }) => {
  const [hasWildlifeSafari, setHasWildlifeSafari] = useState(false);
  return (
    <ManageSingleObject<AddonDetailsData>
      title="Add-Ons:"
      titleClassName="text-mob-sh2"
      getUrl={`/api/admin/bookings/${bookingId}/get-addon-details/`}
      descriptionFields={[
        {
          label: 'Wildlife Safari',
          render: (item) => {
            if (!item.destination_has_wildlife_safari) {
              setHasWildlifeSafari(false);
              return 'The destination for this booking does not have the wildlife safari';
            }
            setHasWildlifeSafari(true);
            return item.wildlife_safari_added ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            );
          },
        },
      ]}
      formFields={[
        {
          type: 'checkbox',
          name: 'wildlife_safari_added',
          option: { value: 'true', label: 'Wildlife safari' },
        },
      ]}
      editButton={hasWildlifeSafari && canEdit}
      insideCard={false}
      submitItem={(values) => updateAddonDetails(bookingId, values)}
      disableForm={(data: AddonDetailsData) =>
        !data.destination_has_wildlife_safari
      }
    />
  );
};

export default AddonDetailsSection;
