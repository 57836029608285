import React from 'react';

const PlacementObjectivesLeftSideText: React.FC = () => {
  return (
    <div className="space-y-6 text-md mb-10">
      <p>
        This page provides the opportunity to outline the learning objectives
        you have for your placement in Hue. The information you provide here
        will be submitted to the supervising staff in the department(s) you
        choose for your placement.
      </p>
      <p>
        Whilst the extent objectives are fulfilled can never be guaranteed,
        noting down the objectives you have provide supervising staff an insight
        into the focus you have for your placement. It is important you note
        down these objectives ahead of the clinical interest call with the
        Operations Manager, to ensure they can be reviewed as suitable and
        realistic.
      </p>
      <p>
        It is not essential to add objectives for your placement. However, over
        the many years of arranging placements, returning students confirm they
        benefitted in three ways:
      </p>
      <ul className="list-disc list-inside">
        <li>
          It created a talking point with the supervising staff at the start of
          the placement
        </li>
        <li>
          It provided the student a focus for their placement, both prior to and
          during the experience
        </li>
        <li>
          Supervising staff gained insight into the reasons for the placement
          ahead of the student's arrival
        </li>
      </ul>
      <p>
        Please also include university placement hour requirements, if you have
        these. It is important you note here the average weekly hours that need
        completing, not total hours.
      </p>
    </div>
  );
};

export default PlacementObjectivesLeftSideText;
