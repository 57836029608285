import React from 'react';

const DesktopLogo: React.FC = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.8541 2.05878C40.9723 1.72274 39.4769 1.85716 38.5528 2.09239C36.2173 2.69726 34.8395 5.16716 34.7051 6.99858C34.4363 10.9135 36.7886 15.786 41.3755 20.3898C45.7945 24.7919 54.7332 28.774 59.0681 25.5648C59.2025 25.464 59.3369 25.3464 59.4713 25.2288C59.4713 25.2288 59.4545 25.2456 59.4377 25.2456C59.3873 25.3128 59.3369 25.38 59.2697 25.4304C58.3624 26.3545 57.1862 26.8754 55.8589 27.1106C53.893 27.5475 51.608 27.3962 49.3565 26.6906C45.3744 25.548 41.3083 23.1453 38.7376 20.5578C33.6298 15.45 30.9919 9.6533 31.5967 5.25117C31.9328 2.84848 32.9409 1.25229 34.2347 0.244165C33.4954 0.193759 32.7561 0.160156 32 0.160156C28.8412 0.160156 25.7833 0.630612 22.8933 1.50432C20.2722 3.15092 19.1801 5.21757 18.7264 7.09939C17.1134 13.8202 21.7844 24.3719 30.0678 32C36.7214 38.1159 44.1311 42.0308 50.4486 43.0389C51.3559 43.1733 52.2464 43.2574 53.1202 43.2742C53.1202 43.2742 53.1033 43.2742 53.0865 43.2742H53.1202C52.2128 43.3918 51.2551 43.4422 50.2974 43.4086C43.3246 43.2238 34.3355 39.4265 26.9426 32.6385C18.4408 24.8423 15.0636 18.6592 14.8115 9.88853C14.7611 7.90589 14.9459 6.25929 15.366 4.86472C11.7536 7.08259 8.61158 10.0229 6.14169 13.4506C5.15037 17.0294 4.94874 22.1204 7.73787 28.5724C16.7942 49.5917 40.2666 56.3461 40.2666 56.3461C40.2666 56.3461 13.8874 52.2632 3.89021 27.6147C2.9829 25.128 2.41163 22.9773 2.07559 21.1123C0.832236 24.5231 0.160156 28.1859 0.160156 32C0.160156 32.0672 0.160156 32.1344 0.160156 32.2016C1.40351 39.7121 6.19209 51.0367 22.0364 59.7738C22.0364 59.7738 12.2576 56.7494 5.11676 49.0373C10.779 57.9255 20.709 63.8398 32 63.8398C49.5581 63.8398 63.8398 49.5581 63.8398 32C63.8398 18.2391 55.0692 6.49452 42.8205 2.05878"
        fill="white"
      />
    </svg>
  );
};

export default DesktopLogo;
