import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../modal';
import FileUpload from '../../../framework-components/fileUpload/FileUpload';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onSuccessfulUpload: (file_id: string) => void;
  title: string;
  acceptedExtensions?: string[];
  maxFileSize?: number;
  resizeImage?: boolean;
}

const UploadModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onSuccessfulUpload,
  title,
  resizeImage,
  acceptedExtensions = ['png', 'jpg', 'webp'],
  maxFileSize = 10,
}) => {
  const focusRef = React.useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          {title}
        </Dialog.Title>
        <FileUpload
          acceptedExtensions={acceptedExtensions}
          maxFileSize={maxFileSize}
          resizeImage={resizeImage}
          onSuccessfulUpload={onSuccessfulUpload}
        />
      </div>
    </Modal>
  );
};

export default UploadModal;
