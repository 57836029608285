import React from 'react';

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

const Logo: React.FC<Props> = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 270 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2645_6629)">
        <path
          d="M18.33 20.09L15.75 34.08H15.7L13.22 20.09H8.83L6.35 34.08H6.3L3.93 20.09H0L4.19 39.95H8.27L10.95 26.22H11L13.66 39.95H17.74L22.26 20.09H18.33Z"
          fill="#2568EB"
        />
        <path
          d="M27.13 30.02C27.13 32.57 27.82 37.12 31.24 37.12C34.66 37.12 35.35 32.58 35.35 30.02C35.35 27.46 34.66 22.92 31.24 22.92C27.82 22.92 27.13 27.46 27.13 30.02ZM39.43 30.02C39.43 35.02 36.9 40.25 31.24 40.25C25.58 40.25 23.05 35.02 23.05 30.02C23.05 25.02 25.58 19.79 31.24 19.79C36.9 19.79 39.43 25.02 39.43 30.02Z"
          fill="#2568EB"
        />
        <path
          d="M47.5 28.33C49.24 28.33 51.89 28.43 51.89 25.96C51.89 23.23 49.21 23.46 47.24 23.46H45.76V28.33H47.49H47.5ZM49.62 20.09C54.19 20.09 55.97 23.36 55.97 25.88C55.97 28.05 54.62 30.07 52.6 30.88L56.56 39.94H52.37L48.8 31.69H45.76V39.94H41.78V20.08H49.62V20.09Z"
          fill="#2568EB"
        />
        <path
          d="M68.66 20.09H73.25L67.33 27.85L73.99 39.95H69.45L64.65 31.35L62.58 33.98V39.95H58.6V20.09H62.58V28.39L68.66 20.09Z"
          fill="#2568EB"
        />
        <path
          d="M150.87 21.88H145.53V39.95H143.44V21.88H138.1V20.09H150.87V21.88Z"
          fill="#2568EB"
        />
        <path
          d="M163.42 28.44V20.09H165.52V39.95H163.42V30.23H154.95V39.95H152.86V20.09H154.95V28.44H163.42Z"
          fill="#2568EB"
        />
        <path
          d="M179.45 21.88H170.77V28.64H176.98V30.43H170.77V38.16H180.04V39.95H168.68V20.09H179.45V21.88Z"
          fill="#2568EB"
        />
        <path
          d="M198.88 39.95H194.79L192.14 26.22H192.09L189.41 39.95H185.32L181.13 20.09H185.07L187.44 34.08H187.49L189.97 20.09H194.36L196.83 34.08H196.88L199.46 20.09H203.39L198.88 39.95Z"
          fill="#2568EB"
        />
        <path
          d="M208.27 30.02C208.27 32.57 208.96 37.12 212.38 37.12C215.8 37.12 216.49 32.58 216.49 30.02C216.49 27.46 215.8 22.92 212.38 22.92C208.96 22.92 208.27 27.46 208.27 30.02ZM220.57 30.02C220.57 35.02 218.04 40.25 212.38 40.25C206.72 40.25 204.18 35.02 204.18 30.02C204.18 25.02 206.71 19.79 212.38 19.79C218.05 19.79 220.57 25.02 220.57 30.02Z"
          fill="#2568EB"
        />
        <path
          d="M228.64 28.33C230.38 28.33 233.03 28.43 233.03 25.96C233.03 23.23 230.35 23.46 228.39 23.46H226.91V28.33H228.65H228.64ZM230.76 20.09C235.33 20.09 237.12 23.36 237.12 25.88C237.12 28.05 235.77 30.07 233.75 30.88L237.71 39.94H233.52L229.95 31.69H226.91V39.94H222.93V20.08H230.76V20.09Z"
          fill="#2568EB"
        />
        <path
          d="M243.72 36.58H251.99V39.95H239.74V20.09H243.72V36.58Z"
          fill="#2568EB"
        />
        <path
          d="M259.88 36.58C263.04 36.58 264.96 34.59 264.96 29.56C264.96 25.4 263.17 23.46 259.45 23.46H257.94V36.58H259.88ZM258.81 20.09C266.36 20.09 269.04 24.38 269.04 30.02C269.04 35.66 266.11 39.95 260.18 39.95H253.95V20.09H258.8H258.81Z"
          fill="#2568EB"
        />
        <path
          d="M76.64 29.97C76.64 13.41 90.06 0 106.61 0C120.06 0 131.44 8.86 135.24 21.07C135.24 21.07 135.63 23.31 133.57 23.31C130.42 23.31 128.63 21.05 128.63 21.05C126.04 18.44 123.43 15.8 123.43 15.8C121.45 13.83 119.39 11.83 118.76 11.31C109.31 3.6 95.38 4.16 86.57 12.96C77.18 22.35 77.18 37.58 86.57 46.97C95.34 55.74 109.19 56.32 118.63 48.72C119.3 48.18 121.24 46.31 123.42 44.13L128.37 39.14C128.37 39.14 130.26 36.67 133.56 36.67C135.39 36.67 135.22 38.91 135.15 39.12C131.28 51.19 119.96 59.93 106.61 59.93C90.05 59.93 76.64 46.51 76.64 29.96"
          fill="#2568EB"
        />
        <path
          d="M109.54 12.15C108.42 11.95 107.53 12.03 106.98 12.17C105.59 12.53 104.77 14 104.69 15.09C104.53 17.42 105.93 20.32 108.66 23.06C111.29 25.68 116.61 28.05 119.19 26.14C119.27 26.08 119.35 26.01 119.43 25.94C119.43 25.94 119.42 25.95 119.41 25.95C119.38 25.99 119.35 26.03 119.31 26.06C118.77 26.61 118.07 26.92 117.28 27.06C116.11 27.32 114.75 27.23 113.41 26.81C111.04 26.13 108.62 24.7 107.09 23.16C104.05 20.12 102.48 16.67 102.84 14.05C103.04 12.62 103.64 11.67 104.41 11.07C103.97 11.04 103.53 11.02 103.08 11.02C101.2 11.02 99.38 11.3 97.66 11.82C96.1 12.8 95.45 14.03 95.18 15.15C94.22 19.15 97 25.43 101.93 29.97C105.89 33.61 110.3 35.94 114.06 36.54C114.6 36.62 115.13 36.67 115.65 36.68C115.65 36.68 115.64 36.68 115.63 36.68H115.65C115.11 36.75 114.54 36.78 113.97 36.76C109.82 36.65 104.47 34.39 100.07 30.35C95.01 25.71 93 22.03 92.85 16.81C92.82 15.63 92.93 14.65 93.18 13.82C91.03 15.14 89.16 16.89 87.69 18.93C87.1 21.06 86.98 24.09 88.64 27.93C94.03 40.44 108 44.46 108 44.46C108 44.46 92.3 42.03 86.35 27.36C85.81 25.88 85.47 24.6 85.27 23.49C84.53 25.52 84.13 27.7 84.13 29.97C84.13 30.01 84.13 30.05 84.13 30.09C84.87 34.56 87.72 41.3 97.15 46.5C97.15 46.5 91.33 44.7 87.08 40.11C90.45 45.4 96.36 48.92 103.08 48.92C113.53 48.92 122.03 40.42 122.03 29.97C122.03 21.78 116.81 14.79 109.52 12.15"
          fill="#2568EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2645_6629">
          <rect width="269.04" height="59.95" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
