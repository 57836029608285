import React, { useContext } from 'react';

import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { EmergencyContact } from './types';
import { updateEmergencyContact } from './actions';
import { LoggedInUserContext } from '../../../../context-providers/logged-in-user';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const EmergencyContactDetails: React.FC<Props> = ({ bookingId, canEdit }) => {
  const user = useContext(LoggedInUserContext);

  return (
    <ManageSingleObject<EmergencyContact>
      title="My emergency contact details"
      getUrl={`/api/admin/bookings/${bookingId}/emergency-contact/get/`}
      submitItem={(values) => updateEmergencyContact(values, bookingId)}
      editButton={
        (user &&
          canEdit &&
          user.permissionsGranted.includes(
            'StudentProfile.EDIT_STUDENT_PROFILE'
          )) ??
        false
      }
      descriptionFields={[
        {
          label: 'Name',
          render: (data: EmergencyContact) =>
            data.first_name && data.last_name
              ? `${data.first_name} ${data.last_name}`
              : '',
        },
        {
          label: 'Relationship',
          render: (data: EmergencyContact) =>
            data.relationship ? `${data.relationship}` : '',
        },
        {
          label: 'Email',
          render: (data: EmergencyContact) =>
            data.email ? `${data.email}` : '',
        },
        {
          label: 'Telephone',
          render: (data: EmergencyContact) =>
            data.telephone ? `${data.telephone}` : '',
        },
        {
          label: 'Address',
          render: (data: EmergencyContact) => {
            if (!data.address || !data.address.line1) {
              return '';
            }

            if (!data.address.line2) {
              return `${data.address.line1}`;
            }
            return (
              <>
                <p>{data.address.line1}</p> <p>{data.address.line2}</p>
              </>
            );
          },
        },
        {
          label: 'City',
          render: (data: EmergencyContact) =>
            data.address && data.address.city ? `${data.address.city}` : '',
        },
        {
          label: 'County',
          render: (data: EmergencyContact) =>
            data.address && data.address.county ? `${data.address.county}` : '',
        },
        {
          label: 'Country',
          render: (data: EmergencyContact) =>
            data.address && data.address.country && data.address.country.value
              ? `${data.address.country.label}`
              : '',
        },
        {
          label: 'Postcode',
          render: (data: EmergencyContact) =>
            data.address && data.address.postcode
              ? `${data.address.postcode}`
              : '',
        },
      ]}
      formFields={[
        {
          type: 'smalltext',
          name: 'first_name',
          label: 'First name',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'last_name',
          label: 'Last name',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'relationship',
          label: 'Relationship',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'address.line1',
          label: 'Address line 1',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'address.line2',
          label: 'Address line 2',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'address.city',
          label: 'City',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'address.county',
          label: 'County',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'remote-dropdown',
          name: 'address.country',
          label: 'Country',
          path: '/api/booking/countries/',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'address.postcode',
          label: 'Postcode',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'email',
          label: 'Email',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'telephone',
          label: 'Phone',
          extraProps: {
            row: true,
          },
        },
      ]}
      buttonLabel="Update"
    />
  );
};

export default EmergencyContactDetails;
