import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateIndemnityInsuranceDetails } from './actions';
import { IndemnityInsuranceDetailsData } from './types';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const IndemnityInsuranceDetails: React.FC<Props> = ({ bookingId, canEdit }) => {
  return (
    <ManageSingleObject<IndemnityInsuranceDetailsData>
      title="Indemnity insurance:"
      titleClassName="text-mob-sh2"
      getUrl={`/api/admin/bookings/${bookingId}/get-indemnity-insurance/`}
      descriptionFields={[
        {
          label: 'Insurance Company',
          render: (item) => item.indemnity_insurance_provider,
        },
        {
          label: 'Confirmation for cover',
          render: (item) => {
            return item.indemnity_insurance_cover_confirmation ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            );
          },
        },
      ]}
      formFields={[
        {
          type: 'smalltext',
          name: 'indemnity_insurance_provider',
          label: 'Insurance Company',
        },
        {
          type: 'checkbox',
          name: 'indemnity_insurance_cover_confirmation',
          option: {
            value: 'true',
            label:
              'The indemnity provider has confirmed they are covered for this placement',
          },
        },
      ]}
      insideCard={false}
      submitItem={(values) =>
        updateIndemnityInsuranceDetails(bookingId, values)
      }
      editButton={canEdit}
    />
  );
};

export default IndemnityInsuranceDetails;
