import React, { useContext } from 'react';

import FlightDetailForm from './FlightDetailForm';
import { useAPI } from '../../../api/api';
import { MyBookingsContext } from '../context-providers';
import MyTripStandardPage from '../layout/MyTripStandardPage';
import SpanningContainer from '../layout/SpanningContainer';
import WidgetContainer from '../components/WidgetContainer';
import TravelInsuranceForm from './TravelInsuranceForm';
import IndemnityInsuranceForm from './IndemnityInsuranceForm';
import Loading from '../components/Loading';
import TwoColumns from '../../admin/common/layout/TwoColumns';

interface FlightDetailsData {
  id: string;
  flight_arrival: string | null;
  flight_arrival_number: string | null;
  independant_travel_arrival_notes: string | null;
  flight_departure: string | null;
  flight_departure_number: string | null;
  independant_travel_departure_notes: string | null;
}

const TravelDetails: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const { data, isValidating, error } = useAPI<FlightDetailsData>(
    activeBooking ? `/api/my-trip/travel-details/${activeBooking.id}/` : null
  );
  const booking_id = activeBooking?.id;

  if (!booking_id) {
    return <Loading />;
  }
  return (
    <div className="relative">
      <MyTripStandardPage
        title="Travel Details"
        isLoading={isValidating}
        error={error && 'Error loading flight details'}
        showDashLink
      >
        <>
          <h1 className="text-sh2 mb-6">Flights:</h1>
          {data && booking_id && (
            <div className="flex gap-4 flex-wrap w-full">
              <WidgetContainer hasBorder hasShadow className="space-y-4">
                <FlightDetailForm
                  formId="Outbound"
                  title="Outbound"
                  url={`/api/my-trip/travel-details/${booking_id}/set-details/outbound/`}
                  initialValues={{
                    date: data.flight_arrival
                      ? new Date(data.flight_arrival)
                      : null,
                    time: data.flight_arrival
                      ? new Date(data.flight_arrival)
                      : null,
                    flight_number: data.flight_arrival_number ?? '',
                    alternative_transport:
                      data.independant_travel_arrival_notes,
                  }}
                  bookingId={booking_id}
                />
              </WidgetContainer>
              <WidgetContainer hasBorder hasShadow className="space-y-4">
                <FlightDetailForm
                  formId="Return"
                  title="Return"
                  url={`/api/my-trip/travel-details/${booking_id}/set-details/return/`}
                  initialValues={{
                    date: data.flight_departure
                      ? new Date(data.flight_departure)
                      : null,
                    time: data.flight_departure
                      ? new Date(data.flight_departure)
                      : null,
                    flight_number: data.flight_departure_number ?? '',
                    alternative_transport:
                      data.independant_travel_departure_notes,
                  }}
                  bookingId={booking_id}
                />
              </WidgetContainer>
            </div>
          )}
        </>
        <SpanningContainer variant="gray">
          <div className="text-sm">
            <h1 className="text-sh2 mb-6">Insurance:</h1>
            <TwoColumns
              className="w-full"
              leftColumn={
                <WidgetContainer
                  hasBorder
                  hasShadow
                  className="w-full space-y-4"
                >
                  <TravelInsuranceForm booking_id={booking_id} />
                </WidgetContainer>
              }
              rightColumn={
                <WidgetContainer
                  hasBorder
                  hasShadow
                  className="w-full space-y-4"
                >
                  <IndemnityInsuranceForm booking_id={booking_id} />
                </WidgetContainer>
              }
            />
          </div>
        </SpanningContainer>
      </MyTripStandardPage>
    </div>
  );
};

export default TravelDetails;
