import { apiRequest } from '../../../api/api';
import { DocumentDownloadedData } from './types';

export const setDocumentAsDownloaded = (
  bookingId: string,
  documentId: string
) => {
  const url = `/api/my-trip/downloads/${documentId}/downloaded-by/${bookingId}/set/`;
  const data = {
    booking_id: bookingId,
    document_id: documentId,
  };
  return apiRequest<DocumentDownloadedData>(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};
