import React from 'react';

import Anchor from '../../common/Anchor';
import { BasicInfo, ENQUIRY_STATUS_LABELS } from './types';

interface Props {
  data: BasicInfo;
}

const Status: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex gap-x-3 items-center">
      <div className="flex gap-x-2">
        <p>Status:</p>
        {data && data.status ? (
          <p className="text-md-bold">{ENQUIRY_STATUS_LABELS[data.status]}</p>
        ) : (
          '-'
        )}
      </div>

      {data.booking && (
        <Anchor
          href={
            data.booking
              ? `/admin/bookings/${data.booking.id}/applicant-profile`
              : '#'
          }
        >
          <strong data-testid="booking-link">See booking</strong>
        </Anchor>
      )}
    </div>
  );
};

export default Status;
