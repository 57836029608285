import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDash } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import { BookingCount, OnTripBookingTableData } from '../types';
import { apiRequest, useAPI } from '../../../../../api/api';
import Table from '../../../../../framework-components/table/Table';
import {
  CombinedFilters,
  PartialFilterOptions,
  TableData,
} from '../../../../../framework-components/table/types';
import TableCell from '../../../../../framework-components/table/TableCell';
import { formatDateToDayDateMonthYear } from '../../../../../utils/dates';
import GreenCircleIcon from '../../../../icons/GreenCircleIcon';
import DocumentOverdueIcon from '../../../../icons/DocumentOverdueIcon';
import DocumentPositiveIcon from '../../../../icons/DocumentPositiveIcon';
import DocumentNegativeIcon from '../../../../icons/DocumentNegativeIcon';
import { truncate } from '../../../../../utils/text';

const OnTripBookings: React.FC = () => {
  const { data: count } = useAPI<BookingCount>('/api/admin/bookings/count/');

  const getData = useCallback((selectedFilters: CombinedFilters) => {
    return new Promise<TableData<OnTripBookingTableData>>((resolve, reject) => {
      apiRequest<TableData<OnTripBookingTableData>>(
        '/api/admin/bookings/on-trip-bookings/?' +
          new URLSearchParams(selectedFilters).toString()
      )
        .then((response) => {
          resolve(response);
        })
        .catch(() => reject());
    });
  }, []);

  const getFilterOptions = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<PartialFilterOptions<OnTripBookingTableData>>(
      '/api/admin/bookings/on-trip-bookings-filters/?' +
        new URLSearchParams(selectedFilters).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const renderMonitoringFormIcon = useCallback(
    (
      booking: OnTripBookingTableData,
      tooltipId: string,
      field:
        | 'monitoring_form_first'
        | 'monitoring_form_second'
        | 'monitoring_form_debrief'
    ) => {
      return (
        (booking[`${field}_status`] === 'OVERDUE' && (
          <div
            data-tooltip-id={tooltipId}
            data-tooltip-content={
              booking.legacy_id
                ? 'Legacy booking, check student notes'
                : `${field.replace(/_/g, ' ')} is overdue`
            }
          >
            <DocumentOverdueIcon />
            <Tooltip id={tooltipId} />
          </div>
        )) ||
        (booking[field]?.id && (
          <div
            data-tooltip-id={tooltipId}
            data-tooltip-content={`${field.replace(
              /_/g,
              ' '
            )} has been uploaded`}
          >
            <DocumentPositiveIcon />
            <Tooltip id={tooltipId} />
          </div>
        )) ||
        (!booking[field]?.id && (
          <div
            data-tooltip-id={tooltipId}
            data-tooltip-content={
              booking.legacy_id
                ? 'Legacy booking, check student notes'
                : `${field.replace(
                    /_/g,
                    ' '
                  )} has not been uploaded and is not overdue`
            }
          >
            <DocumentNegativeIcon />
            <Tooltip id={tooltipId} />
          </div>
        ))
      );
    },
    []
  );

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: '/admin/bookings/pending',
        },
        {
          label: 'On Trip',
          href: null,
        },
      ]}
      title="On Trip"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: `Pending ${count ? `(${count.pending})` : ''}`,
              href: '/admin/bookings/pending',
              current: false,
            },
            {
              label: `Confirmed ${count ? `(${count.confirmed})` : ''}`,
              href: '/admin/bookings/confirmed',
              current: false,
            },
            {
              label: `On Trip ${count ? `(${count.on_trip})` : ''}`,
              href: '/admin/bookings/on-trip',
              current: true,
            },
            {
              label: `Postponed & archived ${
                count ? `(${count.postponed_and_archived})` : ''
              }`,
              href: '/admin/bookings/postponed',
              current: false,
            },
          ]}
          variant="pilled_tab"
        />
      </div>

      <Table<OnTripBookingTableData>
        columnsConfig={{
          applicant_name: {
            header: 'Name',
            enableSort: true,
            key: 'applicant_name',
            renderCell: (booking) => (
              <a
                href={`/admin/bookings/${booking.id}/applicant-profile/`}
                title={booking.applicant_name}
              >
                {booking.applicant_name}
              </a>
            ),
          },
          university: {
            header: 'University',
            key: 'university',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (booking) => (
              <a
                href={`/admin/directories/universities/${booking.applicant.university.id}/`}
                title={booking.applicant.university.name}
              >
                {booking.applicant.university.name.length > 38
                  ? truncate(booking.applicant.university.name, 38)
                  : booking.applicant.university.name}
              </a>
            ),
          },
          destination: {
            header: 'Destination',
            key: 'destination',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (booking) => (
              <a
                href={`/admin/directories/destinations?destination_id=${booking.destination.id}`}
                title={booking.destination.name}
              >
                {booking.destination.name}
              </a>
            ),
          },
          discipline: {
            header: 'Discipline',
            key: 'discipline',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (booking) => booking.discipline.name,
          },
          house_arrival: {
            header: 'House Arrival',
            key: 'house_arrival',
            enableSort: true,
            filterType: 'calendar',
            renderCell: (booking) => (
              <TableCell>
                {formatDateToDayDateMonthYear(booking.house_arrival)}
              </TableCell>
            ),
          },
          house_departure: {
            header: 'House Departure',
            key: 'house_departure',
            enableSort: true,
            filterType: 'calendar',
            renderCell: (booking) => (
              <TableCell>
                {formatDateToDayDateMonthYear(booking.house_departure)}
              </TableCell>
            ),
          },
          monitoring_forms: {
            header: 'Monitoring forms',
            key: 'monitoring_forms',
            filterType: 'local-dropdown',
            renderCell: (booking) => {
              const tooltipIdFirst = `${booking.id}-1`;
              const tooltipIdSecond = `${booking.id}-2`;
              const tooltipIdDebrief = `${booking.id}-d`;
              return (
                <TableCell>
                  <div className="flex gap-2">
                    {renderMonitoringFormIcon(
                      booking,
                      tooltipIdFirst,
                      'monitoring_form_first'
                    )}

                    {renderMonitoringFormIcon(
                      booking,
                      tooltipIdSecond,
                      'monitoring_form_second'
                    )}

                    {renderMonitoringFormIcon(
                      booking,
                      tooltipIdDebrief,
                      'monitoring_form_debrief'
                    )}
                  </div>
                </TableCell>
              );
            },
          },
          has_positive_feedback: {
            header: 'Positive feedback',
            key: 'has_positive_feedback',
            filterType: 'local-dropdown',
            enableSort: true,
            renderCell: (booking) => (
              <div className="flex justify-center">
                {booking.has_positive_feedback ? (
                  <GreenCircleIcon />
                ) : (
                  <FontAwesomeIcon icon={faDash} className="text-lg" />
                )}
              </div>
            ),
          },
        }}
        enablePagination
        enableSearch
        getData={getData}
        getFilterOptions={getFilterOptions}
      />
    </AdminStandardPage>
  );
};

export default OnTripBookings;
