import React, { useCallback, useContext } from 'react';

import WidgetContainer from '../components/WidgetContainer';
import ManageSingleImage from '../components/ManageSingleImage/ManageSingleImage';
import { getMyPassportPhoto, setMyPassportPhoto } from './actions';
import { MyBookingsContext } from '../context-providers';
import { GENDER } from '../../booking/booking-form/constants';

const MyPassportScan: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const bookingId = activeBooking?.id || '';

  const onIdPhotoSuccessfulUpload = useCallback(
    async (fileId: string) => {
      await setMyPassportPhoto(bookingId, fileId);
    },
    [bookingId]
  );

  return (
    <WidgetContainer title="My Passport scan">
      <div className="pt-4 place-items-center">
        <ManageSingleImage
          alternatePlaceHolderImageSrc={
            activeBooking?.applicant.gender === GENDER.MALE
              ? '/static/unknown_M.gif'
              : '/static/unknown_F.gif'
          }
          resizeImage={false}
          getCurrentImageUrl={() =>
            getMyPassportPhoto(bookingId).then(
              (data) => data.passport_photo_url
            )
          }
          onUploadNewImage={onIdPhotoSuccessfulUpload}
        />
      </div>
    </WidgetContainer>
  );
};

export default MyPassportScan;
