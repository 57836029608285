import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import useModalState from '../../../../../hooks/modals';
import Modal from '../../../../modal';
import QuickForm from '../../../../../framework-components/quickform/QuickForm';
import { reduceBookingHouseDuration } from '../actions';

interface Props {
  bookingId: string;
  bookingHouseId: string;
}

const ReduceBookingHouse: React.FC<Props> = ({ bookingId, bookingHouseId }) => {
  const focusRef = React.useRef(null);

  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <>
      <a onClick={openModal} className="text-table-xs cursor-pointer">
        Reduce
      </a>
      <Modal
        isOpen={isOpen ?? false}
        onClose={closeModal}
        initialFocus={focusRef}
      >
        <div className="space-y-6 flex flex-col items-center">
          <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
            Reduce accomodation duration
          </Dialog.Title>
          <Dialog.Description>
            <QuickForm
              onSubmit={(data) =>
                reduceBookingHouseDuration(bookingHouseId, data.number_of_weeks)
              }
              onSubmitSuccess={() => {
                toast.success('Accomodation reduced successfully');
                closeModal();
                mutate(`/api/admin/bookings/${bookingId}/get-trip-details/`);
                mutate(
                  `/api/admin/bookings/${bookingId}/get-accomodation-details/`
                );
              }}
              onSubmitFailure={() => {
                toast.error('Unable to reduce accomodation duration');
              }}
              initialValues={{ number_of_weeks: 1 }}
              fields={[
                {
                  type: 'smalltext',
                  name: 'number_of_weeks',
                  label: 'Number of Weeks',
                  extraProps: {
                    type: 'number',
                  },
                },
              ]}
            />
          </Dialog.Description>
        </div>
      </Modal>
    </>
  );
};

export default ReduceBookingHouse;
