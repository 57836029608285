import React from 'react';

import SpanningContainer from '../layout/SpanningContainer';

const UsefulInformation: React.FC = () => {
  return (
    <SpanningContainer variant="dark-gray">
      <div className="space-y-6 text-md pb-10 md:w-[780px]">
        <h1 className="text-sh2">Useful Information</h1>
        <p>
          Below is a list of some common objectives students choose to include.
          You are welcome to use these, elements of them, or to create your own:
        </p>
        <ul className="list-disc list-inside">
          <li>
            To learn more about the healthcare differences between Hue and your
            home country
          </li>
          <li>
            To learn about diseases and conditions that are unique or prevalent
            in Hue
          </li>
          <li>
            To observe a range of medical conditions different to those in your
            home country
          </li>
          <li>To improve my communication skills</li>
          <li>
            To share knowledge and ideas with healthcare professionals in Hue
          </li>
          <li>
            To gain confidence in supporting patients who do not speak English
          </li>
          <li>To improve interpretation skills</li>
          <li>
            To gain insight into the impact different cultures and beliefs have
            on healthcare in Hue
          </li>
          <li>To use the learning gained to date, in an unfamiliar setting</li>
          <li>To understand the role that family plays in patient care</li>
          <li>To understand the impact resources have on patient care</li>
          <li>
            To have the opportunity to for clinical practice, relevant to
            existing experience
          </li>
          <li>
            To observe practical procedures, and learn techniques from hospital
            staff in Hue
          </li>
        </ul>
        <p>
          Once on placement in Hue, it will be your responsibility to cover
          these again with the supervising staff. Whilst we endeavour to ensure
          supervising staff have reviewed your information prior to your
          arrival, with the heavy workload hospital staff have, it is always
          wise to revisit these points once your begin placement.
        </p>
        <p>
          Relating to placement hours required your university for this
          placement, it will be your responsibility to ensure you meet these by
          attending placement when required. Supervising staff will only sign
          off hours that you attend.
        </p>
      </div>
    </SpanningContainer>
  );
};

export default UsefulInformation;
