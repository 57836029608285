import * as queryString from 'query-string';

export const CHARGE_TYPE = {
  REGISTRATION_FEE: 'REGISTRATION_FEE' as const,
  HOLIDAY: 'HOLIDAY' as const,
  VILLAGE: 'VILLAGE' as const,
  ADDON: 'ADDON' as const,
  OTHER: 'OTHER' as const,
  ADMIN: 'ADMIN' as const,
  INSURANCE: 'INSURANCE' as const,
  REFUND: 'REFUND' as const,
};

export const XERO_CREATE_STATUS = {
  NOT_STARTED: 'NOT_STARTED' as const,
  IN_PROGRESS: 'IN_PROGRESS' as const,
  COMPLETED: 'COMPLETED' as const,
  FAILED: 'FAILED' as const,
};

export type URLParams = queryString.ParsedQuery<string | number | boolean>;

export type LabelValue<T = string> = {
  label: string;
  value: T;
};

export type Option<T = string> = LabelValue<T> & {
  description?: string;
};

export type Uuid = string;

export type XeroCreateStatus = keyof typeof XERO_CREATE_STATUS;

export type BookingStatus =
  | 'PENDING'
  | 'CONFIRMED'
  | 'ON_TRIP'
  | 'ALUMNI'
  | 'ARCHIVED'
  | 'CANCELLED'
  | 'POSTPONED';

export type TxType = 'DEBIT' | 'CREDIT';

export type PaymentType = 'BACS' | 'STRIPE' | 'PAYPAL' | 'CHARGE_REMOVED';

export type ChargeType = keyof typeof CHARGE_TYPE;
