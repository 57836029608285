import React from 'react';

const MobileLogo: React.FC = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.9596 1.89863C41.0778 1.56259 39.5824 1.697 38.6583 1.93223C36.3228 2.53711 34.945 5.007 34.8106 6.83843C34.5418 10.7533 36.8941 15.6259 41.481 20.2296C45.8999 24.6318 54.8386 28.6139 59.1735 25.4047C59.308 25.3039 59.4424 25.1862 59.5768 25.0686C59.5768 25.0686 59.56 25.0854 59.5432 25.0854C59.4928 25.1526 59.4424 25.2198 59.3752 25.2702C58.4678 26.1944 57.2917 26.7152 55.9643 26.9505C53.9985 27.3873 51.7134 27.2361 49.462 26.5304C45.4799 25.3879 41.4138 22.9852 38.8431 20.3977C33.7353 15.2898 31.0973 9.49314 31.7022 5.09101C32.0383 2.68832 33.0464 1.09213 34.3401 0.0840089C33.6009 0.0336028 32.8616 0 32.1055 0C28.9467 0 25.8887 0.470456 22.9988 1.34416C20.3777 2.99076 19.2855 5.05741 18.8319 6.93924C17.2189 13.66 21.8898 24.2117 30.1732 31.8398C36.8268 37.9558 44.2365 41.8707 50.5541 42.8788C51.4614 43.0132 52.3519 43.0972 53.2256 43.114C53.2256 43.114 53.2088 43.114 53.192 43.114H53.2256C52.3183 43.2316 51.3606 43.282 50.4029 43.2484C43.43 43.0636 34.441 39.2663 27.0481 32.4783C18.5462 24.6822 15.169 18.499 14.917 9.72837C14.8666 7.74573 15.0514 6.09913 15.4715 4.70457C11.859 6.92243 8.71705 9.86279 6.24716 13.2904C5.25584 16.8692 5.05421 21.9603 7.84334 28.4122C16.8996 49.4316 40.3721 56.186 40.3721 56.186C40.3721 56.186 13.9929 52.1031 3.99568 27.4545C3.08837 24.9678 2.5171 22.8172 2.18106 20.9521C0.937705 24.3629 0.265625 28.0258 0.265625 31.8398C0.265625 31.907 0.265625 31.9743 0.265625 32.0415C1.50898 39.552 6.29756 50.8765 22.1419 59.6136C22.1419 59.6136 12.3631 56.5892 5.22223 48.8771C10.8845 57.7654 20.8145 63.6797 32.1055 63.6797C49.6636 63.6797 63.9453 49.398 63.9453 31.8398C63.9453 18.079 55.1747 6.33436 42.926 1.89863"
        fill="#2568EB"
      />
    </svg>
  );
};

export default MobileLogo;
