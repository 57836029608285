import React, { useCallback } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';

import ManageSingleObject, {
  Item,
} from '../../../../manage-single-object/ManageSingleObject';
import { DestinationRequirements } from './types';
import { updateDestinationRequirements } from './actions';
import { FieldType } from '../../../../../framework-components/quickform/Field';

interface Props {
  destinationId?: string;
}

const boolToIcon = (value: boolean) => {
  if (value === true) {
    return <CheckIcon className="h-4 w-4" />;
  }

  return <XMarkIcon className="h-4 w-4" />;
};

const formFields: ReadonlyArray<FieldType> = [
  {
    type: 'checkbox',
    name: 'visa_required',
    option: {
      value: 'visa_required',
      label: 'Visa required?',
    },
  },
  {
    type: 'checkbox',
    name: 'visa',
    option: {
      value: 'visa_letter_required',
      label: 'Visa letter required?',
    },
  },
  {
    type: 'checkbox',
    name: 'require_dates',
    option: {
      value: 'require_dates',
      label: 'Require country arrival & departure dates?',
    },
  },
  {
    type: 'checkbox',
    name: 'suppress_university_name',
    option: {
      value: 'suppress_university_name',
      label: 'Hide university name from hospital sheet?',
    },
  },
  {
    type: 'checkbox',
    name: 'download_photos',
    option: {
      value: 'download_photos',
      label: 'Show photos in download section?',
    },
  },
  {
    type: 'checkbox',
    name: 'visa_scan',
    option: {
      value: 'visa_scan',
      label: 'Visa scan required?',
    },
  },
  {
    type: 'checkbox',
    name: 'additional_visa_requirements',
    option: {
      value: 'additional_visa_requirements',
      label:
        'Additional visa requirements? (i.e. needs more than a single letter of support)',
    },
  },
  {
    type: 'checkbox',
    name: 'need_pp_scan',
    option: {
      value: 'need_pp_scan',
      label: 'Require passport scan?',
    },
  },
  {
    type: 'checkbox',
    name: 'certified_scan',
    option: {
      value: 'certified_scan',
      label: 'Certified scan required?',
    },
  },
  {
    type: 'checkbox',
    name: 'students_can_apply',
    option: {
      value: 'students_can_apply',
      label: 'Students can apply?',
    },
  },
  {
    type: 'checkbox',
    name: 'cv_required',
    option: {
      value: 'cv_required',
      label: 'CV required?',
    },
  },
  {
    type: 'checkbox',
    name: 'need_indemnity',
    option: {
      value: 'need_indemnity',
      label: 'Indemnity certificate required?',
    },
  },
];

const fields: ReadonlyArray<{
  name: keyof DestinationRequirements;
  label: string;
  render: (data: DestinationRequirements) => React.ReactNode;
}> = [
  {
    name: 'visa_required',
    label: 'Visa required?',
    render: (data: DestinationRequirements) => boolToIcon(data.visa_required),
  },
  {
    name: 'visa',
    label: 'Visa letter required?',
    render: (data: DestinationRequirements) => boolToIcon(data.visa),
  },
  {
    name: 'require_dates',
    label: 'Require country arrival & departure dates?',
    render: (data: DestinationRequirements) => boolToIcon(data.require_dates),
  },
  {
    name: 'suppress_university_name',
    label: 'Hide university name from hospital sheet?',
    render: (data: DestinationRequirements) =>
      boolToIcon(data.suppress_university_name),
  },
  {
    name: 'download_photos',
    label: 'Show photos in download section?',
    render: (data: DestinationRequirements) => boolToIcon(data.download_photos),
  },
  {
    name: 'visa_scan',
    label: 'Visa scan required?',
    render: (data: DestinationRequirements) => boolToIcon(data.visa_scan),
  },
  {
    name: 'additional_visa_requirements',
    label: 'Additional visa requirements?',
    render: (data: DestinationRequirements) =>
      boolToIcon(data.additional_visa_requirements),
  },
  {
    name: 'need_pp_scan',
    label: 'Require passport scan?',
    render: (data: DestinationRequirements) => boolToIcon(data.need_pp_scan),
  },
  {
    name: 'certified_scan',
    label: 'Certified scan required?',
    render: (data: DestinationRequirements) => boolToIcon(data.certified_scan),
  },
  {
    name: 'students_can_apply',
    label: 'Students can apply?',
    render: (data: DestinationRequirements) =>
      boolToIcon(data.students_can_apply),
  },
  {
    name: 'cv_required',
    label: 'CV required?',
    render: (data: DestinationRequirements) => boolToIcon(data.cv_required),
  },
  {
    name: 'need_indemnity',
    label: 'Indemnity certificate required?',
    render: (data: DestinationRequirements) => boolToIcon(data.need_indemnity),
  },
];

const alwaysShowFields = ['students_can_apply', 'cv_required'];

const DestinationRequirements: React.FC<Props> = ({ destinationId }) => {
  const render = useCallback((data: DestinationRequirements) => {
    const filteredFields = fields.filter(
      (item) => data[item.name] || alwaysShowFields.includes(item.name)
    );
    return (
      <dl className="px-3 py-1 bg-wtw-gray-2 w-full rounded-lg">
        {filteredFields.map((item) => (
          <Item key={item.label} item={item} data={data} />
        ))}
      </dl>
    );
  }, []);

  if (!destinationId) {
    return null;
  }

  return (
    <ManageSingleObject<DestinationRequirements>
      title="Destination requirements:"
      modalTitle="Destination Requirements"
      getUrl={`/api/admin/destination/destination-profile/${destinationId}/requirements/details/`}
      submitItem={(values) =>
        updateDestinationRequirements(destinationId, values)
      }
      alternativeRender={render}
      formFields={formFields}
    />
  );
};

export default DestinationRequirements;
