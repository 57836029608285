import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { Formik, Field, FieldInputProps, Form, FormikHelpers } from 'formik';

import AdminStandardPage from '../common/AdminStandardPage';
import TextArea from '../../../framework-components/textarea/TextArea';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';
import ErrorText from '../../error-text';
import { useSubmitFormToAPI } from '../../../api/forms';

interface FormData {
  title: string;
  content: string;
  destination: string;
  section: string;
}

export function useOnSubmit(): (
  data: FormData,
  formHelpers: Pick<
    FormikHelpers<FormData>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const navigate = useNavigate();
  const submitForm = useSubmitFormToAPI<FormData, FormData>(
    `/api/admin/info-pack/create-article/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        toast.success('Article created');
        navigate(
          `/admin/directories/info-pack/?destination=${data.destination}`
        );
      } else {
        toast.error('Failed to create article');
      }
    },
    [submitForm, navigate]
  );
}

const AdminNewInfoPackArticle: React.FC = () => {
  const onSubmit = useOnSubmit();

  const submitForm = useCallback(
    (values: FormData, formHelpers: FormikHelpers<FormData>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );
  const { sectionId, destinationId } = useParams();
  const navigate = useNavigate();

  return sectionId && destinationId ? (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Create Article',
          href: null,
        },
      ]}
      title="Create Article"
      enableHome={false}
    >
      <Formik<FormData>
        initialValues={{
          title: '',
          content: '',
          destination: destinationId,
          section: sectionId,
        }}
        onSubmit={submitForm}
      >
        {({ errors, values, isValid, isSubmitting }) => (
          <Form>
            <div className="space-y-6 mx-auto mt-6">
              <Field name="title">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <div className="space-y-2">
                    <TextArea
                      id="title"
                      label="Article Title"
                      placeholder="Title"
                      rows={1}
                      containerClassName="w-60"
                      name={field.name}
                      onChange={field.onChange}
                    />
                    <ErrorText className="text-sm">{errors.title}</ErrorText>
                  </div>
                )}
              </Field>
              <Field name="content">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <div className="space-y-2">
                    <TextArea
                      id="content"
                      placeholder="Enter content here"
                      name={field.name}
                      onChange={field.onChange}
                    />
                    <ErrorText className="text-sm">{errors.content}</ErrorText>
                  </div>
                )}
              </Field>
              <div className="flex flex-row justify-end gap-4 space-x-2 mt-3">
                <SecondaryButton
                  label="Cancel"
                  onClick={() => {
                    navigate(
                      `/admin/directories/info-pack?destination=${destinationId}`
                    );
                  }}
                />
                <PrimaryButton
                  label="Create article"
                  type="submit"
                  isDisabled={
                    !values.title || !values.content || isSubmitting || !isValid
                  }
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AdminStandardPage>
  ) : (
    <div>Loading</div>
  );
};

export default AdminNewInfoPackArticle;
