import React from 'react';

interface Props {
  active: boolean;
}

const HospitalStatus: React.FC<Props> = ({ active }) => {
  return (
    <div className="flex gap-x-2 text-lg">
      <p className="font-semibold">Status:</p>
      {active ? 'Active' : 'Archived'}
    </div>
  );
};

export default HospitalStatus;
