import React from 'react';

import Modal from '../../../modal';
import EditDetailsForm from './EditDetailsForm';

interface EditDetailsFormProps {
  onClose: () => void;
  documentId: string | null;
  reloadData: () => void;
}

const EditDetailsModal: React.FC<EditDetailsFormProps> = ({
  onClose,
  documentId,
  reloadData,
}) => {
  return (
    <div>
      <Modal isOpen onClose={onClose}>
        <div>
          <EditDetailsForm
            reloadData={reloadData}
            onClose={onClose}
            documentId={documentId}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditDetailsModal;
