import React, { useCallback } from 'react';
import { Dialog } from '@headlessui/react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, Form, FormikHelpers } from 'formik';

import Modal from '../../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../framework-components/button/Button';
import { useSubmitFormToAPI } from '../../../../../api/forms';
import ErrorText from '../../../../error-text';
import UKFormattedDatePicker from '../../../../../framework-components/date-picker/UKFormattedDatePicker';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onPaid?: (paymentDate: Date) => void;
  bookingId: string;
  reloadData: () => void;
}

interface FormValues {
  registration_fee_paid_at: Date | null;
}

const validationSchema = Yup.object({
  registration_fee_paid_at: Yup.date().required('Please select a payment date'),
});

export function useOnSubmit(
  bookingId: string,
  closeModal: () => void,
  reloadData: () => void,
  onPaid?: (paymentDate: Date) => void
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI(
    `/api/admin/bookings/${bookingId}/mark-paid/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        toast.success('Successfully marked as paid');
        if (onPaid) {
          reloadData();
          onPaid(data.registration_fee_paid_at as Date);
        }
        closeModal();
      }
      if (!hasSucceeded) {
        toast.error('Failed to submit booking');
      }
    },
    [submitForm, closeModal, onPaid, reloadData]
  );
}

const MarkPaidModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onPaid,
  bookingId,
  reloadData,
}) => {
  const onSubmit = useOnSubmit(bookingId, closeModal, reloadData, onPaid);
  const submitForm = useCallback(
    (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="space-y-4">
        <Dialog.Title className="text-2xl font-bold">Mark as paid</Dialog.Title>
        <p>
          Add or update the payment date as needed, then confirm mark as paid.
        </p>
        <Formik<FormValues>
          initialValues={{
            registration_fee_paid_at: null,
          }}
          validationSchema={validationSchema}
          onSubmit={submitForm}
        >
          {({ values, setFieldValue, touched, errors }) => (
            <Form>
              <label
                htmlFor="registration_fee_paid_at"
                className="block text-sm font-medium leading-6 text-gray-900 sm:w-1/2"
              >
                Payment date
              </label>
              <UKFormattedDatePicker
                id="registration_fee_paid_at"
                selected={values.registration_fee_paid_at}
                onChange={(date: Date) => {
                  setFieldValue('registration_fee_paid_at', date);
                }}
                placeholderText="Please select"
                dropdownMode="select"
              />
              <div className="flex justify-end gap-4">
                <SecondaryButton label="Cancel" onClick={closeModal} />
                <PrimaryButton label="Mark as paid" type="submit" />
              </div>
              {touched.registration_fee_paid_at &&
                errors.registration_fee_paid_at && (
                  <ErrorText className="text-sm">
                    {errors.registration_fee_paid_at}
                  </ErrorText>
                )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default MarkPaidModal;
