import React from 'react';

import { QuickFacts as QuickFactsType } from './types';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateQuickFacts } from './actions';

interface Props {
  hospitalId: string;
}

const QuickFacts: React.FC<Props> = ({ hospitalId }) => {
  return (
    <ManageSingleObject<QuickFactsType>
      title="Capacities & Quick facts:"
      getUrl={`/api/admin/hospital/${hospitalId}/quick-facts/`}
      submitItem={(values) => updateQuickFacts(hospitalId, values)}
      descriptionFields={[
        {
          label: 'Capacity (for students)',
          render: (item) => item.capacity,
        },
        {
          label: 'Number of beds',
          render: (item) => item.num_beds,
        },
        {
          label: 'Number of doctors',
          render: (item) => item.num_doctors,
        },
        {
          label: 'Number of nurses',
          render: (item) => item.num_nurses,
        },
        {
          label: 'Other staff',
          render: (item) => item.other_staff,
        },
      ]}
      formFields={[
        {
          type: 'smalltext',
          name: 'capacity',
          label: 'Capacity (for students)',
          extraProps: { type: 'number' },
        },
        {
          type: 'smalltext',
          name: 'num_beds',
          label: 'Number of beds',
          extraProps: { type: 'number' },
        },
        {
          type: 'smalltext',
          name: 'num_doctors',
          label: 'Number of doctors',
          extraProps: { type: 'number' },
        },
        {
          type: 'smalltext',
          name: 'num_nurses',
          label: 'Number of nurses',
          extraProps: { type: 'number' },
        },
        {
          type: 'smalltext',
          name: 'other_staff',
          label: 'Other staff',
        },
      ]}
    />
  );
};

export default QuickFacts;
