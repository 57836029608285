import classNames from 'classnames';
import React from 'react';

import DesktopLogo from '../../icons/DesktopLogo';
import MobileLogo from '../../icons/MobileLogo';

export interface LoginWidgetContainerProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  hasShadow?: boolean;
  hasBorder?: boolean;
  backgroundColor?: string;
}

const LoginWidgetContainer: React.FC<LoginWidgetContainerProps> = ({
  children,
  className,
  hasShadow = true,
  hasBorder = true,
  backgroundColor,
}) => {
  return (
    <div
      className={classNames(
        'rounded-2xl p-4 relative grid w-full h-full overflow-hidden md:w-1/2',
        { 'md:shadow-md': hasShadow },
        { 'bg-white': !backgroundColor },
        { 'md:border md:border-wtw-gray-4': hasBorder },
        backgroundColor,
        className
      )}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-white rounded-l-xl md:w-1/2">
        <div className="block md:hidden flex justify-center mt-6">
          <MobileLogo />
        </div>
      </div>
      <div className="absolute top-0 right-0 md:w-1/2 h-full bg-wtw-logo-blue rounded-r-xl flex items-center justify-center">
        <div className="hidden md:block flex flex-col justify-items-center space-y-3">
          <DesktopLogo />
          <h1 className="text-h4 text-white">Welcome</h1>
        </div>
      </div>
      <div className="relative z-10">{children}</div>
    </div>
  );
};

export default LoginWidgetContainer;
