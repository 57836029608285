import React, { useCallback } from 'react';

import {
  getStaffPublicFacingPicture,
  setStaffPublicFacingPicture,
} from './actions';
import ManageSingleImage from '../../my-trip/components/ManageSingleImage/ManageSingleImage';
import WidgetContainer from '../../my-trip/components/WidgetContainer';

interface Props {
  canEdit: boolean | null;
  userId: string;
}

const StaffPhoto: React.FC<Props> = ({ canEdit, userId }) => {
  const onStaffPhotoSuccessfulUpload = useCallback(
    async (fileId: string) => {
      await setStaffPublicFacingPicture(fileId, userId);
    },
    [userId]
  );

  return (
    <WidgetContainer
      title="Public facing photo"
      className="mt-6 max-w-16 h-full w-full overflow-auto "
    >
      <div className="flex flex-col justify-center text-center lg:h-[278px] m-6">
        <ManageSingleImage
          canEdit={canEdit}
          resizeImage
          getCurrentImageUrl={() =>
            getStaffPublicFacingPicture(userId).then(
              (data) => data.public_facing_picture_url
            )
          }
          onUploadNewImage={onStaffPhotoSuccessfulUpload}
        />
      </div>
    </WidgetContainer>
  );
};

export default StaffPhoto;
