import React, { useCallback, useRef, useState } from 'react';

import { apiRequest, useAPI } from '../../../../api/api';
import {
  CombinedFilters,
  FilterOption,
  TableData,
} from '../../../../framework-components/table/types';
import Table from '../../../../framework-components/table/Table';
import LoadingOverlay from '../../../../framework-components/loading/Loading';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import { formatDateToDayDateMonthYear } from '../../../../utils/dates';
import { download } from '../../../../utils/download';
import ArchiveDocument from './ArchiveDocument';
import UnarchiveDocument from './UnarchiveDocument';
import EditDetails from './EditDetailsModal';
import ReopenIcon from '../../../icons/ReopenIcon';
import DownloadIcon from '../../../icons/DownloadIcon';
import ArchiveIcon from '../../../icons/ArchiveIcon';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';
import EditIcon from '../../../icons/EditIcon';
import AddNewDocumentForm from './AddNewDocumentModal';
import AddNewIconOutlined from '../../common/AddNewIconOutlined';

interface DocumentsListingTableData {
  id: string;
  name: string;
  description: string;
  category: string;
  modified: string;
  download_link: string;
  section: string;
  available_to_all_destinations: string;
  is_archived: boolean;
}

const DocumentsListingTable: React.FC = () => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [isAddNewDocumentModalOpen, setIsAddNewDocumentModalOpen] =
    useState(false);

  const { data, error, isValidating } = useAPI<DocumentsListingTableData>(
    '/api/admin/document/list/'
  );

  const getFilterOptions = useCallback((): Promise<
    Record<string, FilterOption[]>
  > => {
    return fetch('/api/admin/document/filters/').then((response) =>
      response.json()
    );
  }, []);

  const getDocumentsListData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<DocumentsListingTableData>>(
        '/api/admin/document/list/?' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const openArchiveModal = (documentId: string) => {
    setIsArchiveModalOpen(true);
    setSelectedDocumentId(documentId);
  };

  const openUnarchiveModal = (documentId: string) => {
    setIsUnarchiveModalOpen(true);
    setSelectedDocumentId(documentId);
  };

  const openEditDetailsModal = (documentId: string) => {
    setIsEditDetailsModalOpen(true);
    setSelectedDocumentId(documentId);
  };

  const openAddNewDocumentModal = () => {
    setIsAddNewDocumentModalOpen(true);
  };
  const tableRef = useRef<{ reloadData: () => void }>(null);

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.reloadData();
    }
  };

  return (
    <div>
      <div className="flex justify-end">
        <PrimaryButton label="Add new" onClick={openAddNewDocumentModal}>
          <span className="flex items-center">
            <AddNewIconOutlined className="mr-3" />
            Add new
          </span>
        </PrimaryButton>
      </div>
      <div className="mt-5 w-full">
        {isValidating && <LoadingOverlay />}
        {error && <ErrorOverlay />}
        {data && (
          <Table<DocumentsListingTableData>
            ref={tableRef}
            columnsConfig={{
              name: {
                key: 'name',
                header: 'Name',
                enableSort: true,
                renderCell: (document) => document.name,
                filterType: 'calendar',
              },
              category: {
                key: 'category',
                header: 'Category',
                enableSort: true,
                renderCell: (document) => document.category,
              },
              description: {
                key: 'description',
                header: 'Description',
                enableSort: true,
                renderCell: (document) => document.description,
              },
              modified: {
                key: 'modified',
                header: 'Last edited',
                enableSort: true,
                renderCell: (document) =>
                  formatDateToDayDateMonthYear(document.modified),
              },
              download_link: {
                key: 'download_link',
                header: 'Actions',
                enableSort: false,
                renderCell: (document) => (
                  <div className="flex gap-2 items-center flex-wrap">
                    <SecondaryButton
                      paddingClassName="px-3 py-1"
                      onClick={() => openEditDetailsModal(document.id)}
                    >
                      <div className="flex gap-2 place-items-center">
                        <EditIcon />
                        <p className="text-sm">Edit details</p>
                      </div>
                    </SecondaryButton>

                    <SecondaryButton
                      paddingClassName="px-3 py-1"
                      onClick={() =>
                        download(document.download_link, document.name)
                      }
                    >
                      <DownloadIcon />
                    </SecondaryButton>

                    {document.is_archived ? (
                      <SecondaryButton
                        className="h-full"
                        paddingClassName="px-3 py-1"
                        onClick={() => openUnarchiveModal(document.id)}
                      >
                        <ReopenIcon />
                      </SecondaryButton>
                    ) : (
                      <SecondaryButton
                        paddingClassName="px-3 py-1"
                        onClick={() => openArchiveModal(document.id)}
                      >
                        <ArchiveIcon />
                      </SecondaryButton>
                    )}
                  </div>
                ),
              },
            }}
            getFilterOptions={getFilterOptions}
            getData={getDocumentsListData}
            enablePagination
            enableSearch
            additionalFiltersConfig={{
              category: {
                key: 'category',
                label: 'Category',
                filterType: 'local-dropdown',
              },
              destination: {
                key: 'destination',
                label: 'Destination',
                filterType: 'local-dropdown',
              },
              is_archived: {
                key: 'is_archived',
                label: 'Active/Archived',
                filterType: 'local-dropdown',
              },
            }}
          />
        )}
        {isArchiveModalOpen && (
          <ArchiveDocument
            reloadData={reloadData}
            documentId={selectedDocumentId}
            onClose={() => setIsArchiveModalOpen(false)}
          />
        )}
        {isUnarchiveModalOpen && (
          <UnarchiveDocument
            reloadData={reloadData}
            documentId={selectedDocumentId}
            onClose={() => setIsUnarchiveModalOpen(false)}
          />
        )}
        {isEditDetailsModalOpen && (
          <EditDetails
            reloadData={reloadData}
            documentId={selectedDocumentId}
            onClose={() => setIsEditDetailsModalOpen(false)}
          />
        )}
        {isAddNewDocumentModalOpen && (
          <AddNewDocumentForm
            reloadData={reloadData}
            onClose={() => setIsAddNewDocumentModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentsListingTable;
