import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/solid';

import { XeroCreateStatus } from '../../../../types';
import { useAPI } from '../../../../api/api';
import Table from '../../../manage-multiple-objects/Table';
import { formatDateToReadableStringShorthandMonth } from '../../../../utils/dates';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import UpdateInvoiceModal from './UpdateInvoiceModal';
import useModalState from '../../../../hooks/modals';
import { XERO_CREATE_STATUS_LABELS } from '../../../../constants';
import CreateInvoiceModal from './CreateInvoiceModal';
import CreateCreditNoteModal from './CreateCreditNoteModal';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import { CurrencyType } from '../../../booking/booking-form/types';
import { InvoiceType } from './types';

interface Invoice {
  id: string;
  created: string;
  xero_id: string;
  xero_number: string;
  xero_create_status: XeroCreateStatus;
  type: InvoiceType;
  xero_app_id: string;
  current_holiday_invoice?: boolean;
  current_village_invoice?: boolean;
  current_addon_invoice?: boolean;
}

interface Props {
  bookingId: string;
  currency: CurrencyType;
}

const StatusPill = ({ create_status }: { create_status: XeroCreateStatus }) => {
  switch (create_status) {
    case 'NOT_STARTED':
      return <span className="font-semibold text-amber-500">Not Started</span>;
    case 'IN_PROGRESS':
      return <span className="font-semibold text-green-500">In Progress</span>;
    case 'FAILED':
      return <span className="font-semibold text-red-400">Failed</span>;
    default:
      return <span>{create_status}</span>;
  }
};

const EditInvoiceButton = ({
  bookingId,
  invoice,
}: {
  bookingId: string;
  invoice: Invoice;
}) => {
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <>
      <PencilSquareIcon
        className="h-5 w-5 cursor-pointer"
        onClick={openModal}
      />
      <UpdateInvoiceModal
        invoiceId={invoice.id}
        type={invoice.type}
        bookingId={bookingId}
        isOpen={isOpen}
        closeModal={closeModal}
        initialValues={{
          xero_invoice_id: invoice.xero_id,
          xero_invoice_number: invoice.xero_number,
          xero_create_status: {
            value: invoice.xero_create_status,
            label: XERO_CREATE_STATUS_LABELS[invoice.xero_create_status],
          },
          current_holiday_invoice: invoice.current_holiday_invoice,
          current_village_invoice: invoice.current_village_invoice,
          current_addon_invoice: invoice.current_addon_invoice,
        }}
      />
    </>
  );
};

const InvoiceTable: React.FC<Props> = ({ bookingId, currency }) => {
  const { data, isValidating, error } = useAPI<Array<Invoice>>(
    `/api/admin/bookings/${bookingId}/get-invoice-and-credit-note-list/`
  );

  const {
    isOpen: isInvoiceModalOpen,
    openModal: openInvoiceModal,
    closeModal: closeInvoiceModal,
  } = useModalState();

  const {
    isOpen: isCreditNoteModalOpen,
    openModal: openCreditNoteModal,
    closeModal: closeCreditNoteModal,
  } = useModalState();

  const render = () => {
    if (isValidating) {
      return <LoadingOverlay />;
    }

    if (error) {
      return <ErrorOverlay />;
    }

    if (data) {
      return (
        <Table<Invoice>
          data={data}
          tableFields={[
            {
              header: 'Date',
              name: 'created',
              render: (item) =>
                formatDateToReadableStringShorthandMonth(item.created),
            },
            {
              header: 'Status',
              name: 'xero_create_status',
              render: (item) =>
                item.xero_create_status === 'COMPLETED' ? (
                  <a
                    href={`https://go.xero.com/app/${item.xero_app_id}/invoicing/view/${item.xero_id}`}
                    className="text-xs font-semibold"
                  >
                    View in Xero
                  </a>
                ) : (
                  <StatusPill create_status={item.xero_create_status} />
                ),
            },
            {
              header: 'Invoice Number',
              name: 'xero_number',
              render: (item) => item.xero_number,
            },
            {
              header: 'Edit',
              name: 'edit',
              render: (item) => (
                <EditInvoiceButton bookingId={bookingId} invoice={item} />
              ),
            },
          ]}
        />
      );
    }
  };

  return (
    <AdminWidgetContainer hasBorder hasShadow title="Invoices">
      <div className="mt-4 min-h-[200px] overflow-x-scroll">
        {render()}
        <CreateInvoiceModal
          bookingId={bookingId}
          isOpen={isInvoiceModalOpen}
          closeModal={closeInvoiceModal}
          currency={currency}
        />
        <CreateCreditNoteModal
          bookingId={bookingId}
          isOpen={isCreditNoteModalOpen}
          closeModal={closeCreditNoteModal}
        />
        <div className="mt-2 flex gap-2">
          <PrimaryButton onClick={openInvoiceModal}>
            Create Invoice
          </PrimaryButton>
          <PrimaryButton onClick={openCreditNoteModal}>
            Create Credit Note
          </PrimaryButton>
        </div>
      </div>
    </AdminWidgetContainer>
  );
};

export default InvoiceTable;
