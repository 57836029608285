import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { PrimaryButton } from '../../../../../framework-components/button/Button';
import { getPriceForWeeks } from './actions';
import ErrorText from '../../../../error-text';
import { GetPriceForWeeks } from './types';
import Table from '../../../../manage-multiple-objects/Table';

interface Props {
  destinationId: string;
}

const TestPrice: React.FC<Props> = ({ destinationId }) => {
  const [numberOfWeeks, setNumberOfWeeks] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<GetPriceForWeeks | null>(null);

  useEffect(() => {
    setData(null);
    setError(null);
    setLoading(false);
  }, [destinationId]);

  const getPriceForWeeksHandlers = useCallback(async () => {
    if (!numberOfWeeks) {
      return;
    }
    setLoading(true);
    try {
      const response = await getPriceForWeeks(destinationId, numberOfWeeks);
      setData(response);
    } catch (e) {
      setError('There was an error fetching the prices.');
    } finally {
      setLoading(false);
    }
  }, [destinationId, numberOfWeeks]);

  const tableData = useMemo(() => {
    if (!data || !numberOfWeeks) {
      return [];
    }
    return [{ ...data, numberOfWeeks }];
  }, [data, numberOfWeeks]);

  return (
    <div className="mt-6 space-y-4">
      <div className="h-[1px] w-full bg-wtw-gray-4" />
      <h5 className="text-md-bold">Test price:</h5>
      <div className="flex gap-4 items-center">
        <p className="text-sm">Number of weeks:</p>
        <select
          className="py-4 border border-wtw-gray-4 rounded-md"
          onChange={(e) => setNumberOfWeeks(e.target.value)}
        >
          <option value="">Select</option>
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
        <PrimaryButton
          label={loading ? 'Loading...' : 'Test Prices'}
          type="button"
          onClick={getPriceForWeeksHandlers}
          isDisabled={loading || !numberOfWeeks}
        />
      </div>
      {error && <ErrorText>{error}</ErrorText>}
      {data && numberOfWeeks && (
        <Table
          data={tableData}
          tableFields={[
            {
              name: 'numberOfWeeks',
              header: 'Number of weeks',
              render: (item) => item.numberOfWeeks,
            },
            {
              name: 'GBP',
              header: 'GBP £',
              render: (item) => (item.GBP / 100).toFixed(2),
            },
            {
              name: 'USD',
              header: 'USD $',
              render: (item) => (item.USD / 100).toFixed(2),
            },
            {
              name: 'EUR',
              header: 'EUR €',
              render: (item) => (item.EUR / 100).toFixed(2),
            },
            {
              name: 'AUD',
              header: 'AUD $',
              render: (item) => (item.AUD / 100).toFixed(2),
            },
            {
              name: 'CAD',
              header: 'CAD $',
              render: (item) => (item.CAD / 100).toFixed(2),
            },
          ]}
        />
      )}
    </div>
  );
};

export default TestPrice;
