import React, { useState } from 'react';

import TextArea from '../textarea/TextArea';
import { RoundedSmallButtonOutline } from '../button/Button';

export interface NotesPanelProps {
  showTitle?: boolean;
  placeholder?: string;
  buttonText?: string;
  onClickAction: (message: string) => Promise<void>;
}

const NotesPanel: React.FC<NotesPanelProps> = ({
  placeholder = 'Enter notes here',
  buttonText = 'Mark as resolved',
  showTitle = true,
  onClickAction,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');

  const enableButtonClick = () => setIsLoading(false);

  const handleClickEvent = () => {
    if (isLoading) return;

    setIsLoading(true);

    onClickAction(note).then(enableButtonClick).catch(enableButtonClick);
  };

  return (
    <div className="rounded-md py-3 px-4 bg-wtw-gray-1 border border-wtw-gray-4 space-y-1">
      {showTitle && <h1 className="text-table-xs-md wtw-black">Notes:</h1>}
      <TextArea
        name="note"
        placeholder={placeholder}
        containerClassName="table-xs"
        textAreaClassNameOverride="block w-full p-0 rounded-md border-0 text-gray-900 placeholder:text-gray-400 focus:ring-transparent bg-transparent sm:text-xs sm:leading-6 disabled:bg-wtw-gray-1 disabled:cursor-not-allowed disabled:resize-none"
        onChange={(e) => setNote(e.target.value.trim())}
        autoGrow
        disableTextAreaMargin
      />
      <div className="flex flex-wrap justify-end w-full">
        <RoundedSmallButtonOutline
          isLoading={isLoading}
          onClick={handleClickEvent}
        >
          {buttonText}
        </RoundedSmallButtonOutline>
      </div>
    </div>
  );
};

export default NotesPanel;
