import React from 'react';

import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import PostponeBooking from './PostponeBooking';
import ArchiveBooking from './ArchiveBooking';
import CancelBooking from './CancelBooking';

interface Props {
  bookingId: string;
  registrationFeePaid: boolean;
  isPostponed: boolean;
  isArchived: boolean;
  isCancelled: boolean;
}

const BookingActions: React.FC<Props> = ({
  bookingId,
  registrationFeePaid,
  isPostponed,
  isArchived,
  isCancelled,
}) => {
  return (
    <AdminWidgetContainer className="bg-white" title="Actions:">
      <div className="flex flex-wrap gap-4 mt-6">
        {!registrationFeePaid ? (
          <ArchiveBooking bookingId={bookingId} isArchived={isArchived} />
        ) : (
          <>
            <PostponeBooking bookingId={bookingId} isPostponed={isPostponed} />
            <CancelBooking bookingId={bookingId} isCancelled={isCancelled} />
          </>
        )}
      </div>
    </AdminWidgetContainer>
  );
};

export default BookingActions;
