import React, { ReactNode } from 'react';

interface Props {
  href: string;
  children: ReactNode;
  classNameOverride?: string;
}

const Anchor: React.FC<Props> = ({
  href,
  children,
  classNameOverride = '',
}) => {
  return (
    <a
      onClick={() => {
        if (href.slice(0, 7) === 'mailto:') {
          return (window.location.href = href);
        }
        window.location.href = href;
      }}
      className={
        classNameOverride
          ? classNameOverride
          : 'text-wtw-logo-blue underline visited:text-purple-600  no-underline hover:cursor-pointer hover:text-wtw-dark-blue'
      }
    >
      {children}
    </a>
  );
};

export default Anchor;
