import React from 'react';
import { useParams } from 'react-router';

import { useAPI } from '../../../api/api';
import { LedgerData } from '../bookings/applicant-profile/LedgerTable';
import { formatDateToReadableStringShorthandMonth } from '../../../utils/dates';
import LedgerTable from './LedgerTable';
import { CurrencyType } from '../../booking/booking-form/types';

interface Address {
  line1: string;
  line2: string | null;
  city: string;
  county: string | null;
  country: string | null;
  postcode: string;
}

interface FinanceStatementData {
  ledger: LedgerData;
  currency: CurrencyType;
  statement_date: string;
  applicant: {
    name: string;
    address: Address;
    reference: string;
  };
}

const Address = ({ address }: { address: Address }) => (
  <>
    <p>{address.line1}</p>
    {address.line2 && <p>{address.line2}</p>}
    <p>{address.city}</p>
    {address.county && <p>{address.county}</p>}
    {address.country && <p>{address.country}</p>}
    <p>{address.postcode}</p>
  </>
);

const FinanceStatement: React.FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>();

  const { data, error, isValidating } = useAPI<FinanceStatementData>(
    bookingId
      ? `/api/finance-statement/${bookingId}/get-finance-statement/`
      : null
  );

  if (isValidating) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return <div>There was an error fetching the finance statement.</div>;
  }
  return (
    <div className="w-[190mm] mx-auto p-8 bg-white text-black font-sans">
      <div className="flex justify-between mb-8">
        <div>
          <h2 className="text-lg font-bold">STATEMENT TO</h2>
          <p>{data.applicant.name}</p>
          <Address address={data.applicant.address} />
        </div>
        <div className="text-right">
          <p>
            <span className="font-bold">Statement date:</span>{' '}
            {formatDateToReadableStringShorthandMonth(data.statement_date)}
          </p>
          <p>
            <span className="font-bold">Your reference:</span>{' '}
            {data.applicant.reference}
          </p>
        </div>
      </div>

      <LedgerTable data={data.ledger} currency={data.currency} />

      <div className="mt-8">
        {data.ledger.remaining_balance === 0 && (
          <p className="text-lg text-green-600 font-bold mb-4">
            ✅ The fee for your placement has been settled in full – Thank you!
          </p>
        )}
        <div className="text-sm space-y-1">
          <p>Tel: +44 (0)1273 974634</p>
          <p>Email: info@worktheworld.co.uk</p>
          <p>Web: worktheworld.co.uk</p>
          <p>Work the World</p>
          <p>3rd Floor, 15 West Street</p>
          <p>Brighton, BN1 2RL</p>
          <p>Work the World is registered in England and Wales No. 05657211</p>
        </div>
      </div>
    </div>
  );
};

export default FinanceStatement;
