import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { mutate } from 'swr';

import { Option } from '../../../../types';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import { useAPI } from '../../../../api/api';
import { ClinicalInterestData } from './types';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import RemoteDropdown from '../../../../framework-components/dropdown/RemoteDropdown';
import LocalDropdown from '../../../../framework-components/dropdown/LocalDropdown';
import { calculateDaysBetweenDates } from '../../../../utils/dates';
import TextArea from '../../../../framework-components/textarea/TextArea';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import { useSubmitFormToAPI } from '../../../../api/forms';

interface FormValues {
  sections: {
    hospital_department: Option<string>;
    num_weeks: Option<number>;
  }[];

  clinicalInterestDetails: string;
  non_field_error?: string;
}

const validationSchema = Yup.object({
  sections: Yup.array().of(
    Yup.object({
      hospital_department: Yup.object({
        label: Yup.string().required('Interest is required'),
        value: Yup.string().required('Interest is required'),
      }),
      num_weeks: Yup.object({
        value: Yup.number().required('Duration is required'),
      }),
    })
  ),
});

function useOnSubmit(
  bookingId: string | null
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI(
    `/api/admin/bookings/${bookingId}/update-clinical-interests/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const clinicalInterestList = [];
      for (let i = 0; i < data.sections.length; i++) {
        clinicalInterestList.push({
          hospital_department: data.sections[i].hospital_department.value,
          num_weeks: data.sections[i].num_weeks.value,
        });
      }
      const submissionData = {
        booking_id: bookingId,
        clinical_interests: clinicalInterestList,
        details: data.clinicalInterestDetails,
      };
      const [hasSucceeded] = await submitForm(submissionData, formHelpers);
      if (hasSucceeded) {
        toast.success('Clinical interests updated');
        mutate(`/api/admin/bookings/${bookingId}/get-education-tab-warning/`);
      } else {
        formHelpers.setErrors({
          non_field_error:
            'Unable to save. You have selected more weeks than you have booked.',
        });
        toast.error('Failed to update clinical interests');
      }
    },
    [submitForm, bookingId]
  );
}

interface Props {
  bookingId: string;
  startDate: string;
  endDate: string;
  canEdit: boolean;
}

const ClinicalInterests: React.FC<Props> = ({
  bookingId,
  startDate,
  endDate,
  canEdit,
}) => {
  const {
    data: selectedClinicalInterests,
    isValidating: selectedClinicalInterestsIsValidating,
    error: selectedClinicalInterestsError,
  } = useAPI<ClinicalInterestData[]>(
    `/api/admin/bookings/${bookingId}/get-clinical-interests/`
  );

  const isValidatingClinicalInterests = selectedClinicalInterestsIsValidating;
  const hasError = selectedClinicalInterestsError;

  const getNumWeeks = useMemo(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const weeksBetween = Math.round(calculateDaysBetweenDates(start, end) / 7);
    const numWeeks = [];
    for (let i = 0; i < weeksBetween; i++) {
      numWeeks.push({
        label: (i + 1).toString() + (i === 0 ? ' week' : ' weeks'),
        value: i + 1,
      });
    }
    return numWeeks;
  }, [startDate, endDate]);

  const onSubmit = useOnSubmit(bookingId);
  const submitForm = useCallback(
    (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );

  return (
    <AdminWidgetContainer title="Clinical Interests" className="space-y-6">
      {isValidatingClinicalInterests && <LoadingOverlay />}
      {hasError && <ErrorOverlay />}

      {selectedClinicalInterests && (
        <Formik<FormValues>
          initialValues={{
            sections:
              selectedClinicalInterests.length !== 0
                ? selectedClinicalInterests.map((clinicalInterest) => {
                    return {
                      hospital_department: {
                        label:
                          clinicalInterest.hospital_department.display_name,
                        value: clinicalInterest.hospital_department.id,
                      },
                      num_weeks: {
                        label: `${clinicalInterest.num_weeks.toString()}${
                          clinicalInterest.num_weeks === 1 ? ' week' : ' weeks'
                        }`,
                        value: clinicalInterest.num_weeks,
                      },
                    };
                  })
                : [
                    {
                      hospital_department: { label: '', value: '' },
                      num_weeks: { label: '', value: 0 },
                    },
                  ],

            clinicalInterestDetails:
              selectedClinicalInterests.length >= 1
                ? selectedClinicalInterests[0].details
                : '',
          }}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {selectedClinicalInterests ? (
                <div>
                  <FieldArray
                    name="sections"
                    render={(arrayHelpers) => (
                      <div>
                        {values.sections.map((_section, index) => (
                          <div key={index} className="mb-4">
                            <div className="flex flex-col w-full sm:flex-row">
                              <div className="pb-3 md:pb-0 w-full pr-3">
                                <label
                                  htmlFor="clinicalInterest"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:w-1/2"
                                >
                                  Interest:
                                </label>

                                <RemoteDropdown
                                  name={`sections[index].hospital_department.label`}
                                  path={`/api/admin/bookings/${bookingId}/get-clinical-interest-options/`}
                                  onSelect={(option: Option<string> | null) => {
                                    setFieldValue(
                                      `sections[${index}].hospital_department`,
                                      option
                                    );
                                  }}
                                  value={
                                    values.sections[index].hospital_department
                                  }
                                  isDisabled={!canEdit}
                                />
                              </div>
                              <div className="w-full sm:w-2/5">
                                <label
                                  htmlFor="numWeeks"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:w-1/2"
                                >
                                  Duration:
                                </label>
                                <div className="pr-3 sm:align-items-center sm:inline-flex">
                                  <LocalDropdown
                                    name="sections[index].num_weeks"
                                    onSelect={(
                                      option: Option<number> | null
                                    ) => {
                                      setFieldValue(
                                        `sections[${index}].num_weeks.label`,
                                        option?.label
                                      );
                                      setFieldValue(
                                        `sections[${index}].num_weeks.value`,
                                        option?.value
                                      );
                                    }}
                                    options={getNumWeeks}
                                    value={values.sections[index].num_weeks}
                                    placeholder="Select"
                                    isDisabled={!canEdit}
                                  />
                                  <div className="flex align-center justify-center">
                                    {values.sections.length > 1 && (
                                      <FontAwesomeIcon
                                        className="text-wtw-logo-blue mt-3 pl-2 pr-2 cursor-pointer"
                                        icon={faTrashAlt}
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <button
                          className={classNames(
                            'inline-flex justify-items-end my-4',
                            {
                              'cursor-pointer': canEdit,
                              'cursor-not-allowed': !canEdit,
                            }
                          )}
                          onClick={() => {
                            arrayHelpers.push({
                              hospital_department: { label: '', value: '' },
                              num_weeks: { label: '', value: 0 },
                            });
                          }}
                          disabled={!canEdit}
                        >
                          <FontAwesomeIcon
                            className="text-wtw-logo-blue text-center pt-1 pr-2"
                            icon={faPlus}
                          />
                          <span className=" flex font-semibold text-wtw-logo-blue">
                            Clinical interest
                          </span>
                        </button>
                      </div>
                    )}
                  />

                  <div>
                    <TextArea
                      name="clinicalInterestDetails"
                      value={values.clinicalInterestDetails}
                      onChange={(e) => {
                        setFieldValue(
                          'clinicalInterestDetails',
                          e.target.value
                        );
                      }}
                      placeholder="Enter anything else here"
                      disabled={!canEdit}
                      textAreaClassNameOverride="block bg-wtw-gray-2 resize-none w-full border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:resize-none"
                    />
                  </div>
                  {errors.non_field_error && (
                    <div className="mt-4 text-center">
                      <p className="text-sm text-red-400">
                        {errors.non_field_error}
                      </p>
                    </div>
                  )}
                  <div className="flex justify-end w-full">
                    <PrimaryButton
                      label="Save"
                      type="submit"
                      className="mt-4 "
                      isDisabled={!canEdit}
                    />
                  </div>
                </div>
              ) : (
                <div>Loading</div>
              )}
              {Object.keys(errors).length !== 0 && (
                <div className="text-red-400 text-sm text-center pt-1">
                  Please complete all required fields
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </AdminWidgetContainer>
  );
};

export default ClinicalInterests;
