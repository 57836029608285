import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../modal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  source?: string;
}

const UploadModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  title,
  source,
}) => {
  const focusRef = React.useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          {title}
        </Dialog.Title>
        <img
          className="rounded-lg h-full w-full object-cover"
          src={source}
          alt="preview"
        />
      </div>
    </Modal>
  );
};

export default UploadModal;
