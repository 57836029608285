import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  handleDelete: () => void;
}

const DeleteConfirmationModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  handleDelete,
}) => {
  const focusRef = React.useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4" data-testid="delete-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Delete Confirmation
        </Dialog.Title>
        <p>Are you sure you want to delete?</p>
        <div className="flex justify-end space-x-4">
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleDelete}>Delete</PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
