import React from 'react';
import { mutate } from 'swr';

import ManageSingleObject from '../../../../manage-single-object/ManageSingleObject';
import { updateLocalOfficeDetails } from './actions';
import { LocalOfficeCreateEditData } from './types';

interface Props {
  destinationId?: string;
}

const LocalOfficeDetails: React.FC<Props> = ({ destinationId }) => {
  const getLocalOfficeDetailsURL = `/api/admin/destination/destination-profile/${destinationId}/local-office/details/`;
  const onSubmitSuccess = (localOfficeDetails: LocalOfficeCreateEditData) => {
    mutate(getLocalOfficeDetailsURL, localOfficeDetails, false);
  };

  if (!destinationId) {
    return null;
  }

  return (
    <ManageSingleObject<LocalOfficeCreateEditData>
      title="Local Office Details:"
      getUrl={getLocalOfficeDetailsURL}
      submitItem={(values) =>
        updateLocalOfficeDetails(destinationId, values.id, values)
      }
      onSubmitSuccess={onSubmitSuccess}
      descriptionFields={[
        {
          label: 'Address',
          render: (data: LocalOfficeCreateEditData) => data.address,
        },

        {
          label: 'Contact numbers',
          render: (data: LocalOfficeCreateEditData) => (
            <div className="flex flex-col">
              {[data.phone_1, data.phone_2].map(
                (phone) => phone && <a href={`tel:${phone}`}>{phone}</a>
              )}
            </div>
          ),
        },
        {
          label: 'Emails',
          render: (data: LocalOfficeCreateEditData) => (
            <div className="flex flex-col">
              {[data.email_1, data.email_2].map(
                (email) =>
                  email && (
                    <a
                      className="text-table-xs-bold text-wtw-logo-blue"
                      href={`mailto:${email}`}
                      title={email}
                    >
                      {email}
                    </a>
                  )
              )}
            </div>
          ),
        },
        {
          label: 'Primary contact',
          render: (data: LocalOfficeCreateEditData) =>
            data.primary_contact?.label ?? '',
        },
      ]}
      formFields={[
        {
          type: 'largetext',
          name: 'address',
          label: 'Address',
        },
        {
          type: 'smalltext',
          name: 'phone_1',
          label: 'Phone 1',
        },
        {
          type: 'smalltext',
          name: 'phone_2',
          label: 'Phone 2',
        },
        {
          type: 'smalltext',
          name: 'email_1',
          label: 'Email 1',
        },
        {
          type: 'smalltext',
          name: 'email_2',
          label: 'Email 2',
        },
        {
          type: 'remote-dropdown',
          name: 'primary_contact',
          label: 'Primary Contact',
          path: `/api/admin/destination/destination-profile/${destinationId}/primary-contact-options/list/`,
        },
      ]}
    />
  );
};

export default LocalOfficeDetails;
