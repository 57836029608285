import React from 'react';

interface Props {
  text: string;
}

const HeadingTwo: React.FC<Props> = ({ text }) => {
  return <h2 className="text-sh3 md:text-sh2">{text}</h2>;
};

export default HeadingTwo;
