import React from 'react';
import classNames from 'classnames';
import { useDroppable } from '@dnd-kit/core';

import { CalendarSection, Event, Droppable } from './types';
import { getPartiallyVisibleEvent } from '../admin/capacities/utils';
import EventOverlay from './EventOverlay';
import { CELL_WIDTH } from './constants';

interface Props<T, U> {
  isEditing: boolean;
  parentSectionId: string;
  events: ReadonlyArray<Event<T>>;
  section: CalendarSection<U>;
  date: Date;
  rowIndex: number;
  getEventForDay: (
    section: CalendarSection<U>,
    date: Date,
    events: ReadonlyArray<Event<T>>,
    rowIndex: number
  ) => Event<T> | null;
  sectionStartDate: Date;
  sectionEndDate: Date;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
  activeEvent: Event<T> | null;
  selectedEvent: Event<T> | null;
  setSelectedEvent: React.Dispatch<React.SetStateAction<Event<T> | null>>;
}

const Cell = <T,>({
  children,
  setNodeRef,
  partiallyVisibleEvent,
  isWeekend,
  overlayColour,
  sectionStartDate,
  selectedEvent,
  setSelectedEvent,
  isEditing,
}: {
  children: React.ReactNode;
  setNodeRef?: (element: HTMLElement | null) => void;
  partiallyVisibleEvent: Event<T> | null;
  isWeekend: boolean;
  overlayColour: string;
  sectionStartDate?: Date | undefined;
  selectedEvent?: Event<T> | null;
  setSelectedEvent?: React.Dispatch<React.SetStateAction<Event<T> | null>>;
  isEditing?: boolean;
}) => {
  return (
    <div
      style={{ width: CELL_WIDTH }}
      className={classNames('h-full relative', {
        'bg-gray-200 border-x border-gray-300':
          isWeekend && !partiallyVisibleEvent,
        [overlayColour]: partiallyVisibleEvent,
        'overflow-hidden':
          partiallyVisibleEvent &&
          sectionStartDate &&
          partiallyVisibleEvent.end_date.toString() ===
            sectionStartDate.toString(),
      })}
      ref={setNodeRef}
      onClick={
        isEditing && selectedEvent && setSelectedEvent
          ? () => {
              setSelectedEvent(null);
            }
          : undefined
      }
    >
      {children}
    </div>
  );
};

const DroppableWrapper = <T, U>({
  droppable,
  date,
  section,
  rowIndex,
  partiallyVisibleEvent,
  isWeekend,
  overlayColour,
  children,
}: {
  droppable: Droppable<U>;
  date: Date;
  section: CalendarSection<U>;
  rowIndex: number;
  partiallyVisibleEvent: Event<T> | null;
  isWeekend: boolean;
  overlayColour: string;
  children: React.ReactNode;
}) => {
  const { setNodeRef } = useDroppable({
    id: `${date.toISOString()}|${section.id}|${rowIndex}`,
    data: { droppable },
  });

  return (
    <Cell
      setNodeRef={setNodeRef}
      partiallyVisibleEvent={partiallyVisibleEvent}
      isWeekend={isWeekend}
      overlayColour={overlayColour}
    >
      {children}
    </Cell>
  );
};

const CalendarDay = <T, U>({
  isEditing,
  parentSectionId,
  section,
  date,
  events,
  rowIndex,
  getEventForDay,
  sectionStartDate,
  sectionEndDate,
  getOverlayColour,
  activeEvent,
  selectedEvent,
  setSelectedEvent,
}: Props<T, U>) => {
  // Event that starts on this date
  const eventOnDay = getEventForDay(section, date, events, rowIndex);

  // Event that started before the start of this section
  const isFirstDayInSection =
    date.toISOString().split('T')[0] ===
    sectionStartDate.toISOString().split('T')[0];

  const partiallyVisibleEvent = isFirstDayInSection
    ? getPartiallyVisibleEvent(
        sectionStartDate,
        section,
        date,
        events,
        rowIndex
      )
    : null;

  const event = eventOnDay || partiallyVisibleEvent;

  const isWeekend = date.getDay() === 0 || date.getDay() === 6;

  const data: { droppable: Droppable<U> } = {
    droppable: {
      date,
      parentSectionId,
      section,
      rowIndex,
      metadata: section.metadata,
    },
  };

  const overlayColour =
    getOverlayColour && partiallyVisibleEvent
      ? getOverlayColour(partiallyVisibleEvent, true)
      : 'bg-blue-100';

  const renderChildren = () => {
    return (
      <>
        {event && (
          <EventOverlay
            isEditing={isEditing}
            event={event}
            sectionStartDate={sectionStartDate}
            sectionEndDate={sectionEndDate}
            getOverlayColour={getOverlayColour}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        )}
      </>
    );
  };

  return (
    <>
      {isEditing &&
      date.toISOString() === activeEvent?.start_date?.toISOString() ? (
        <DroppableWrapper
          droppable={data.droppable}
          date={date}
          section={section}
          rowIndex={rowIndex}
          partiallyVisibleEvent={partiallyVisibleEvent}
          isWeekend={isWeekend}
          overlayColour={overlayColour}
        >
          {renderChildren()}
        </DroppableWrapper>
      ) : (
        <Cell
          partiallyVisibleEvent={partiallyVisibleEvent}
          isWeekend={isWeekend}
          overlayColour={overlayColour}
          sectionStartDate={sectionStartDate}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          isEditing={isEditing}
        >
          {renderChildren()}
        </Cell>
      )}
    </>
  );
};

export default CalendarDay;
