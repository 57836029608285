import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AdminStandardPage from '../../common/AdminStandardPage';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import RoomCapacityCalendar from './RoomCapacityCalendar';
import { useDestinationTabs } from '../../common/hooks';
import { useAPI } from '../../../../api/api';
import { CalendarLock } from '../types';
import { CALENDAR_TYPES } from '../constants';
import LockBanner from '../LockBanner';
const RoomCapacity: React.FC = () => {
  const [searchParams] = useSearchParams();
  const currentDate = new Date();

  const day = searchParams.get('day') ?? currentDate.getDate().toString();

  const month = searchParams.get('month') ?? currentDate.getMonth().toString();

  const year = searchParams.get('year') ?? currentDate.getFullYear().toString();

  const period = searchParams.get('period') ?? '31';

  const [isEditing, setIsEditing] = useState(false);

  const { destination, destinationTabs, isValidating, error } =
    useDestinationTabs();

  const { data: calendarLock } = useAPI<CalendarLock>(
    `/api/admin/calendar/lock/${CALENDAR_TYPES.room}/`,
    {},
    {
      refreshInterval: 10000,
    }
  );

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Capacities',
          href: null,
        },
        {
          label: 'Room Capacities',
          href: null,
        },
      ]}
      title={`Room allocation ${destination ? `- ${destination.label}` : ''}`}
      enableHome={false}
      isLoading={isValidating}
      error={error && 'Failed to load destinations'}
      banner={<LockBanner calendarLock={calendarLock} />}
    >
      <BlockTabs
        tabs={destinationTabs}
        variant="pilled_tab_with_border_bottom"
      />
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: 'House/Village',
              href: `/admin/capacities/house-village-capacities?destination=${destination?.value}&year=${year}&month=${month}&day=${day}&period=${period}`,
              current: false,
            },
            {
              label: 'Room',
              href: `/admin/capacities/room-capacities?destination=${destination?.value}&year=${year}&month=${month}&day=${day}&period=${period}`,
              current: true,
            },
            {
              label: 'Department',
              href: `/admin/capacities/department-capacities?destination=${destination?.value}&year=${year}&month=${month}&day=${day}&period=${period}`,
              current: false,
            },
          ]}
          variant="pilled_secondary_action_tab"
        />
      </div>
      <RoomCapacityCalendar
        dayQueryParam={searchParams.get('day')}
        monthQueryParam={searchParams.get('month')}
        yearQueryParam={searchParams.get('year')}
        period={period}
        destination={destination}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        calendarLock={calendarLock}
      />
    </AdminStandardPage>
  );
};

export default RoomCapacity;
