import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form, Field, FieldInputProps, FormikHelpers } from 'formik';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import Input from '../../framework-components/input/Input';
import { PrimaryButton } from '../../framework-components/button/Button';
import ErrorText from '../error-text';
import { useSubmitFormToAPI } from '../../api/forms';

interface FormData {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

export function useOnSubmit(): (
  data: FormData,
  formHelpers: Pick<
    FormikHelpers<FormData>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI<FormData, FormData>(
    '/api/auth/password/change/'
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        toast.success('Password changed');
      } else {
        toast.error('Failed to change password');
      }
    },
    [submitForm]
  );
}

const validate = (values: FormData) => {
  if (values.new_password1 !== values.new_password2) {
    return {
      new_password1: 'Passwords do not match',
      new_password2: 'Passwords do not match',
    };
  }
};

const ChangePasswordForm: React.FC = () => {
  const onSubmit = useOnSubmit();

  const submitForm = useCallback(
    (values: FormData, formHelpers: FormikHelpers<FormData>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );

  return (
    <Formik<FormData>
      initialValues={{
        old_password: '',
        new_password1: '',
        new_password2: '',
      }}
      onSubmit={submitForm}
      validate={validate}
    >
      {({ errors, values, isValid, isSubmitting }) => (
        <Form>
          <div className="space-y-6 max-w-lg mx-auto mt-6">
            {window.location.pathname === '/my-trip/change-password/' && (
              <Link to="/my-trip/about-me/">
                <div className="inline-flex items-center gap-1 cursor-pointer">
                  <ChevronLeftIcon className="h-5 w-5" />
                  <p className="text-sm">Back to profile</p>
                </div>
              </Link>
            )}
            <h1 className="text-sh2">Change Password</h1>
            <Field name="old_password">
              {({ field }: { field: FieldInputProps<string> }) => (
                <div className="space-y-2">
                  <Input
                    type="password"
                    id="old_password"
                    label="Current password"
                    name={field.name}
                    defaultValue={field.value}
                    onChange={field.onChange}
                  />
                  <ErrorText className="text-sm">
                    {errors.old_password}
                  </ErrorText>
                </div>
              )}
            </Field>
            <Field name="new_password1">
              {({ field }: { field: FieldInputProps<string> }) => (
                <div className="space-y-2">
                  <Input
                    type="password"
                    id="new_password1"
                    label="New password"
                    name={field.name}
                    defaultValue={field.value}
                    onChange={field.onChange}
                  />
                  <ErrorText className="text-sm">
                    {errors.new_password1}
                  </ErrorText>
                </div>
              )}
            </Field>
            <Field name="new_password2">
              {({ field }: { field: FieldInputProps<string> }) => (
                <div className="space-y-2">
                  <Input
                    type="password"
                    id="new_password2"
                    label="Confirm new password"
                    name={field.name}
                    defaultValue={field.value}
                    onChange={field.onChange}
                  />
                  <ErrorText className="text-sm">
                    {errors.new_password2}
                  </ErrorText>
                </div>
              )}
            </Field>
            <PrimaryButton
              label="Change Password"
              type="submit"
              isDisabled={
                isSubmitting ||
                !isValid ||
                !values.old_password ||
                !values.new_password1 ||
                !values.new_password2
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
