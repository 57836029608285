import React, { useCallback } from 'react';
import { FormikProps } from 'formik';
import DatePicker from 'react-datepicker';

import { Option } from '../../types';
import Input from '../input/Input';
import TextArea from '../textarea/TextArea';
import LocalDropdown from '../dropdown/LocalDropdown';
import RemoteDropdown from '../dropdown/RemoteDropdown';
import Checkbox from '../select/Checkbox';
import MultiChoice from '../select/MultiChoice';
import Radio from '../select/Radio';
import ErrorText from '../../components/error-text';
import RichTextEditor from '../../components/text-editor/RichTextEditor';

export interface FieldType {
  type:
    | 'smalltext'
    | 'largetext'
    | 'local-dropdown'
    | 'remote-dropdown'
    | 'checkbox'
    | 'multi-choice'
    | 'radio'
    | 'datetime'
    | 'email'
    | 'password'
    | 'richtext';
  name: string;
  label?: string;
  option?: Option;
  options?:
    | ReadonlyArray<Option | Option<number> | Option<string>>
    | Array<Option | Option<number> | Option<string>>;
  path?: string;
  onSelect?: (option: Option) => void;
  extraProps?: Record<string, unknown>;
  disabled?: boolean;
}

interface Props<T> extends FieldType {
  value: string | number | boolean | string[] | Option | Date | null;
  formik: FormikProps<T>;
  errors: string | string[];
}

function Field<T>({
  type,
  name,
  label,
  value,
  options,
  option,
  path,
  extraProps,
  formik,
  errors,
}: Props<T>) {
  const { handleChange } = formik;

  const dropdownSelect = useCallback(
    (item: Option | null) => {
      formik.setFieldValue(name, item);
    },
    [formik, name]
  );

  const checkboxSelect = useCallback(
    (_: string, checked: boolean) => {
      formik.setFieldValue(name, checked);
    },
    [formik, name]
  );

  const multiChoiceSelect = useCallback(
    (values: string[]) => {
      formik.setFieldValue(name, values);
    },
    [formik, name]
  );

  const radioSelect = useCallback(
    (radioValue: string | number) => {
      formik.setFieldValue(name, radioValue);
    },
    [formik, name]
  );

  const renderField = () => {
    switch (type) {
      case 'smalltext':
        return (
          <Input
            label={label}
            id={name}
            name={name}
            type="text"
            onChange={handleChange}
            defaultValue={value as string}
            {...extraProps}
          />
        );
      case 'largetext':
        return (
          <TextArea
            label={label}
            id={name}
            name={name}
            value={value as string}
            {...extraProps}
            onChange={handleChange}
          />
        );
      case 'local-dropdown':
        if (!options) {
          return null;
        }
        return (
          <LocalDropdown
            label={label}
            value={value as Option}
            id={name}
            name={name}
            options={options as Option[]}
            onSelect={dropdownSelect}
            ignoreOutsideClick={
              extraProps?.ignoreOutsideClick as boolean | undefined
            }
            {...extraProps}
          />
        );
      case 'remote-dropdown':
        if (!path) {
          return null;
        }
        return (
          <RemoteDropdown
            label={label}
            value={value as Option}
            id={name}
            name={name}
            path={path}
            onSelect={dropdownSelect}
            {...extraProps}
          />
        );
      case 'checkbox':
        if (!option) {
          return null;
        }
        const val = value as boolean; // eslint-disable-line
        return (
          <Checkbox
            name={name}
            label={label}
            option={option}
            checked={val}
            onChange={checkboxSelect}
            {...extraProps}
          />
        );
      case 'multi-choice':
        if (!options) {
          return null;
        }
        const vals = value as string[]; // eslint-disable-line
        return (
          <MultiChoice
            name={name}
            label={label}
            options={options as Option[]}
            onChange={multiChoiceSelect}
            currentValues={vals}
            {...extraProps}
          />
        );
      case 'radio':
        if (!options) {
          return null;
        }
        return (
          <Radio
            name={name}
            label={label}
            options={options as Option[]}
            onChange={radioSelect}
            {...extraProps}
          />
        );
      case 'datetime':
        if (typeof value === 'string') {
          value = new Date(value);
        }
        if (value !== null && value !== undefined && !(value instanceof Date)) {
          return null;
        }
        return (
          <>
            <label
              htmlFor={name}
              className="block text-sm font-medium text-gray-900 -mb-2"
            >
              {label}
            </label>
            <DatePicker
              popperPlacement="bottom-end"
              autoComplete="off"
              id={name}
              selected={value}
              onChange={(date: Date) => {
                formik.setFieldValue(name, date);
              }}
              placeholderText="Please select"
              dateFormat={(extraProps?.dateFormat as string) ?? 'dd/MM/yyyy'}
              {...extraProps}
            />
          </>
        );
      case 'password':
        return (
          <Input
            label={label}
            id={name}
            name={name}
            type="password"
            onChange={handleChange}
            defaultValue={value as string}
            {...extraProps}
          />
        );
      case 'email':
        return (
          <Input
            label={label}
            id={name}
            name={name}
            type="email"
            onChange={handleChange}
            defaultValue={value as string}
            {...extraProps}
          />
        );
      case 'richtext':
        return (
          <div>
            <RichTextEditor
              content={value as string}
              setContent={(newValue) => formik.setFieldValue(name, newValue)}
            />
            <ErrorText className="text-sm">{errors}</ErrorText>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderField()}
      <ErrorText className="text-sm">{errors}</ErrorText>
    </>
  );
}

export default Field;
