import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import AdminStandardPage from '../common/AdminStandardPage';
import { useAPI } from '../../../api/api';
import { BasicStaffInfo } from './types';
import TwoColumns from '../common/layout/TwoColumns';
import KeyDetails from './KeyDetails';
import Loading from '../../../framework-components/loading/Loading';
import PermissionsComponent from './PermissionsComponent';
import PublicDescription from './PublicDescription';
import StaffPhoto from './StaffPhoto';
import StaffSummary from './StaffSummary';
import { LoggedInUserContext } from '../../../context-providers/logged-in-user';
import { hasPermission } from '../../../utils/permissions';

export interface Permission {
  role: string;
  role_display: string;
}

const StaffProfilePage = () => {
  const { userId } = useParams<{ userId: string }>();

  const { data, error, isValidating } = useAPI<BasicStaffInfo>(
    `/api/admin/staff/${userId}/profile-basic-info/get/`
  );

  const staffNameOrBlank = (function () {
    if (!data) {
      return '';
    }

    return `${data.name}`;
  })();

  const loggedInUser = useContext(LoggedInUserContext);

  const canEdit =
    loggedInUser &&
    (hasPermission(loggedInUser, 'Staff.SET_STAFF_DATA') ||
      loggedInUser.id === userId);

  return (
    <AdminStandardPage
      isLoading={isValidating}
      error={error ? 'Failed to load page data' : undefined}
      title={staffNameOrBlank}
      breadcrumbs={[
        { label: 'Directories', href: '/admin' },
        { label: 'Staff', href: '/admin/staff/staff-listing' },
        { label: `${staffNameOrBlank}`, href: null },
      ]}
    >
      <div className="mb-8">
        {userId ? (
          <StaffSummary canEdit={canEdit} userId={userId} />
        ) : (
          <Loading />
        )}
      </div>
      <TwoColumns
        leftColumn={
          <div>
            {userId && data ? (
              <div>
                <KeyDetails canEdit={canEdit} userId={userId} />
                <PermissionsComponent userId={userId} role={data.role} />
              </div>
            ) : (
              <Loading />
            )}
          </div>
        }
        rightColumn={
          <div>
            {userId && data ? (
              <div>
                <PublicDescription
                  canEdit={canEdit}
                  userId={userId}
                  role={data.role}
                />
                <StaffPhoto canEdit={canEdit} userId={userId} />
              </div>
            ) : (
              <Loading />
            )}
          </div>
        }
      />
    </AdminStandardPage>
  );
};
export default StaffProfilePage;
