import React, { useContext } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

import { LoggedInUserContext } from '../../../context-providers/logged-in-user';
import { hasPermission } from '../../../utils/permissions';

interface Props {
  userId: string;
  profilePicture: { profile_picture_url: string } | null;
  openImageModal: () => void;
}

const StaffProfilePhoto: React.FC<Props> = ({
  userId,
  profilePicture,
  openImageModal,
}) => {
  const loggedInUser = useContext(LoggedInUserContext);

  const canEdit =
    loggedInUser &&
    (hasPermission(loggedInUser, 'Staff.SET_STAFF_DATA') ||
      loggedInUser.id === userId);
  return (
    <div
      className={classNames(
        'relative bg-wtw-gray-2 place-content-center place-items-center h-[112px] w-0 sm:w-1/3',
        { 'hover:opacity-50 cursor-pointer': canEdit }
      )}
      onClick={canEdit ? () => openImageModal() : () => {}}
    >
      <div className="relative w-0 sm:w-[86px] ">
        <img
          className="aspect-square rounded-full w-[86px] h-[86px] object-cover"
          src={profilePicture?.profile_picture_url ?? '/static/unknown_N.png'}
        />
        {canEdit && (
          <div className="absolute z-20 right-0 bottom-0  bg-white p-1 rounded-full place-content-center w-0 sm:h-5 sm:w-5">
            <PencilSquareIcon className="w-fit text-md-bold text-wtw-logo-blue hover:text-wtw-dark-blue" />
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffProfilePhoto;
