import React from 'react';
import { Link } from 'react-router-dom';

import ManageMultipleObjects from '../../../../manage-multiple-objects/ManageMultipleObjects';
import { LocationStaffDetailsTableData } from './types';

interface Props {
  destinationId?: string;
}

const tableFields = [
  {
    name: 'name',
    header: 'Name',
    render: (data: LocationStaffDetailsTableData) => (
      <Link className="text-table-xs" to={`/admin/staff/${data.id}/profile`}>
        {data.first_name + ' ' + data.last_name}
      </Link>
    ),
  },
  {
    name: 'role',
    header: 'Role',
    render: (data: LocationStaffDetailsTableData) => data?.admin_role_display,
  },
  {
    name: 'email',
    header: 'Email',
    render: (data: LocationStaffDetailsTableData) => data.email,
  },
  {
    name: 'operations_administrator',
    header: 'Operations Administrator',
    render: (data: LocationStaffDetailsTableData) => {
      if (!data.destination || !data.destination.operations_administrator) {
        return '-';
      }

      return `${data.destination.operations_administrator.first_name} ${data.destination.operations_administrator.last_name}`;
    },
  },
  {
    name: 'operations_manager',
    header: 'Operations Manager',
    render: (data: LocationStaffDetailsTableData) => {
      if (!data.destination || !data.destination.operations_manager) {
        return '-';
      }

      return `${data.destination.operations_manager.first_name} ${data.destination.operations_manager.last_name}`;
    },
  },
];

const LocationStaff: React.FC<Props> = ({ destinationId }) => {
  return (
    <ManageMultipleObjects<LocationStaffDetailsTableData>
      title="Location staff:"
      tableFields={tableFields}
      quickFormFields={[]}
      getUrl={`/api/admin/destination/destination-profile/${destinationId}/location-staff/list/`}
      hideManageButton
      isFixedTableLayout
      noDataFoundMessage="There are currently no location staff for this destination."
    />
  );
};

export default LocationStaff;
