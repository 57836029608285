import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../modal';
import { PrimaryButton } from '../../../framework-components/button/Button';
import HtmlRenderer from '../../../framework-components/html-renderer/HtmlRenderer';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  message: string;
}

const EmailMessageModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  message,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="space-y-4 flex flex-col items-center">
        <Dialog.Title className="text-2xl font-bold">Email</Dialog.Title>
        <HtmlRenderer htmlString={message} />
        <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
      </div>
    </Modal>
  );
};

export default EmailMessageModal;
