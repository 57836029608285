import React from 'react';
import { FormikValues } from 'formik';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { truncate } from 'lodash';

import useModalState from '../../hooks/modals';
import EditDetailsModal from '../manage-single-object/EditDetailsModal';
import DeleteConfirmationModal from '../admin/common/modal/DeleteConfirmationModal';
import { TableField } from './types';
import { FieldType } from '../../framework-components/quickform/Field';

interface Props<T> {
  item: T;
  tableFields: ReadonlyArray<TableField<T>>;
  quickFormFields?: ReadonlyArray<FieldType>;
  editHandler?: (values: T) => Promise<T>;
  deleteHandler?: (values: T) => Promise<T>;
  onEditSuccess?: (data: T) => void;
  onDeleteSuccess?: (data: T) => void;
  renderRowColor?: (data: T) => string;
}

const Row = <T extends FormikValues>({
  item,
  tableFields,
  quickFormFields,
  editHandler,
  deleteHandler,
  onEditSuccess,
  onDeleteSuccess,
  renderRowColor,
}: Props<T>) => {
  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModalState();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModalState();

  const onDelete = async () => {
    if (!deleteHandler) {
      return;
    }
    try {
      await deleteHandler(item);
      if (onDeleteSuccess) {
        onDeleteSuccess(item);
      }
      toast.success('Item deleted successfully');
      closeDeleteModal();
    } catch {
      toast.error('Failed to delete item');
    }
  };

  const numberOfTableFields = tableFields.length - 1;

  const rowColor = renderRowColor ? renderRowColor(item) : '';

  return (
    <tr
      className={classNames(
        { [rowColor]: rowColor },
        { 'even:bg-wtw-gray-1 odd:bg-wtw-gray-3': !rowColor }
      )}
    >
      {tableFields.map(({ render, name }, index) => {
        const content = render(item, openEditModal, openDeleteModal);
        const title = ['string', 'number'].includes(typeof content)
          ? String(content)
          : undefined;

        return (
          <td
            key={name}
            title={title}
            className={classNames(
              'min-w-[150px] max-w-[250px] py-2 text-table-xs text-ellipsis table-cell overflow-hidden line-clamp-2 align-center',
              { 'pl-3': index === 0 },
              { 'pr-3': index === numberOfTableFields },
              { 'pr-4': index !== numberOfTableFields }
            )}
          >
            {title
              ? truncate(title, {
                  length: 50,
                })
              : content}
          </td>
        );
      })}
      {editHandler && quickFormFields && (
        <EditDetailsModal
          isOpen={isEditModalOpen}
          closeModal={closeEditModal}
          submitItem={editHandler}
          onSubmitSuccess={onEditSuccess}
          fields={quickFormFields}
          data={item}
        />
      )}
      {deleteHandler && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          closeModal={closeDeleteModal}
          handleDelete={onDelete}
        />
      )}
    </tr>
  );
};

export default Row;
