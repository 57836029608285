import React, { useContext } from 'react';
import classNames from 'classnames';

import MyTripStandardPage from '../layout/MyTripStandardPage';
import InfoPackSection from './InfoPackSection';
import { useAPI } from '../../../api/api';
import { MyBookingsContext } from '../context-providers';
import { InfoPackData } from './types';

const InfoPack: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const { data, isValidating, error } = useAPI<InfoPackData>(
    activeBooking ? `/api/my-trip/info-pack/${activeBooking.id}/list/` : null
  );
  return (
    <MyTripStandardPage
      title="Info pack"
      showDashLink
      isLoading={isValidating}
      error={error ? "Couldn't load info pack" : undefined}
    >
      {data &&
        activeBooking &&
        Object.entries(data).map(([title, articles], index) => (
          <div
            key={title}
            className={classNames('pb-4 pl-4 md:inline-block', {
              'md:w-1/3': index === 0,
              'md:w-2/3': index === 1,
              'lg:w-3/4': index === 2,
              'lg:w-2/3': index === 3,
              'lg:w-1/2': index === 4,
            })}
          >
            <InfoPackSection
              key={title}
              bookingId={activeBooking.id}
              title={title}
              articles={articles}
            />
          </div>
        ))}
    </MyTripStandardPage>
  );
};

export default InfoPack;
