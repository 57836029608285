import React from 'react';

import { Item } from '../../../manage-single-object/ManageSingleObject';
import { formatDateToLongDateFormatWithOridinalDay } from '../../../../utils/dates';
import { useAPI } from '../../../../api/api';
import { TripDetailsData } from './types';
import { Option } from '../../../../types';
import ChangeDestination from './ChangeDestination';
import Loading from '../../../../framework-components/loading/Loading';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import MoveTripStartDate from './MoveTripStartDate';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const TripDetailsSection: React.FC<Props> = ({ bookingId, canEdit }) => {
  const { data: destinations } = useAPI<ReadonlyArray<Option>>(
    `/api/booking/destinations/`
  );

  const { data, isValidating, error } = useAPI<TripDetailsData>(
    `/api/admin/bookings/${bookingId}/get-trip-details/`
  );
  const descriptionFields = [
    {
      label: 'Destination',
      render: (item: TripDetailsData) => item.destination.label,
    },
    {
      label: 'Start Date',
      render: (item: TripDetailsData) =>
        formatDateToLongDateFormatWithOridinalDay(item.start_date),
    },
    {
      label: 'Number of Weeks',
      render: (item: TripDetailsData) => item.number_of_weeks,
    },
  ];
  if (isValidating && !error) {
    return <Loading />;
  }
  if (error) {
    return <ErrorOverlay />;
  }
  if (data && destinations && !isValidating && !error) {
    return (
      <>
        <AdminWidgetContainer
          titleClassName="text-mob-sh2 text-[28px]/[40px] font-600"
          hasBorder={false}
          hasShadow={false}
          title="Trip Details"
          className="px-0 w-full pt-0 pb-0"
        >
          {isValidating && <LoadingOverlay />}
          {error && <ErrorOverlay />}
          <dl className="px-3 py-1 bg-wtw-gray-2 w-full rounded-lg mt-4">
            {data &&
              descriptionFields.map((item) => (
                <Item key={item.label} item={item} data={data} />
              ))}
          </dl>
        </AdminWidgetContainer>
        {canEdit && data && data.start_date && (
          <div className="flex flex-col sm:flex-row gap-2 w-full justify-around mt-0">
            <ChangeDestination
              bookingId={bookingId}
              initialDestination={data.destination}
              destinationOptions={destinations}
            />
            <MoveTripStartDate
              bookingId={bookingId}
              currentStartDate={data.start_date}
            />
          </div>
        )}
      </>
    );
  }
  return <></>;
};

export default TripDetailsSection;
