import React from 'react';
import { FastField, FieldInputProps, FormikProps } from 'formik';

import { FormLabel } from './FormLabel';
import { Option } from '../../../types';
import {
  PAYMENT_METHOD,
  CURRENCY_TO_SYMBOL,
  REGISTRATION_FEE,
} from './constants';
import { PAYMENT_METHOD_TO_DISPLAY_NAME } from './labels';
import { FormValues, PaymentMethodType, CurrencyType } from './types';
import LocalDropdown from '../../../framework-components/dropdown/LocalDropdown';
import { getFormikErrors } from '../../../utils/forms';

const paymentOptions: ReadonlyArray<Option<PaymentMethodType>> = [
  {
    value: PAYMENT_METHOD.BANK_TRANSFER,
    label: PAYMENT_METHOD_TO_DISPLAY_NAME[PAYMENT_METHOD.BANK_TRANSFER],
  },
  {
    value: PAYMENT_METHOD.CARD,
    label: PAYMENT_METHOD_TO_DISPLAY_NAME[PAYMENT_METHOD.CARD],
  },
];

interface Props {
  currency: CurrencyType;
}

const PaymentDetails: React.FC<Props> = ({ currency }) => {
  return (
    <div className="space-y-8" id="payment">
      <h2 className="text-mob-h3 xs:text-h3">Payment preference</h2>
      <FastField name="payment_method">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<Option>;
          form: FormikProps<FormValues>;
        }) => (
          <LocalDropdown
            id="payment_method"
            label={<FormLabel label="How would you prefer to pay?" required />}
            options={paymentOptions}
            errors={getFormikErrors(form, field.name)}
            name={field.name}
            onSelect={(option: Option | null) => {
              form.setFieldValue(field.name, option?.value);
            }}
            value={
              form.values.payment_method
                ? paymentOptions.find(
                    (option) => option.value === form.values.payment_method
                  )
                : null
            }
            row
            labelClassName="font-medium"
            inputWidth="w-booking-form-input"
          />
        )}
      </FastField>
      <div className="space-y-2">
        <p className="text-wtw-secondary-text text-sm">
          Upon completion of this form your registration fee of{' '}
          {CURRENCY_TO_SYMBOL[currency]}
          {REGISTRATION_FEE[currency]} is due, which will secure your booking.
          The remainder of the balance will be due before you depart.
        </p>
      </div>
    </div>
  );
};

export default PaymentDetails;
