import React, { useCallback, useState } from 'react';
import { faPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, FormikHelpers } from 'formik';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { faChevronDown, faXmark } from '@fortawesome/pro-light-svg-icons';
import { format } from 'date-fns';

import { useSubmitFormToAPI } from '../../../api/forms';
import { PrimaryButton } from '../components/Button';
import SubTitle from '../components/SubTitle';
import Input from '../components/Input';
import UKFormattedDatePicker from '../components/UKFormattedDatePicker';
import ErrorText from '../../error-text';
import Checkbox from '../components/Checkbox';
import TextArea from '../components/TextArea';
import FormLabel from '../components/FormLabel';
import { formatDateToReadableStringShorthandMonth } from '../../../utils/dates';

interface FormValues {
  date: Date | null;
  time: Date | null;
  flight_number: string;
  alternative_transport: string | null;
}

interface Props {
  title: string;
  url: string;
  initialValues: FormValues;
  formId: string;
  bookingId: string;
}

export function useOnSubmit(
  url: string,
  bookingId: string
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI<FormValues, unknown>(url);

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        toast.success('Flight details submitted successfully');
        mutate(`/api/my-trip/travel-details/${bookingId}/`);
      } else {
        toast.error('Error submitting flight details');
      }
    },
    [submitForm, bookingId]
  );
}

const FlightDetailForm: React.FC<Props> = ({
  title,
  url,
  initialValues,
  formId,
  bookingId,
}) => {
  const onSubmit = useOnSubmit(url, bookingId);
  const [alternativeTransport, setAlternativeTransport] = useState(false);

  const submitForm = useCallback(
    (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );

  return (
    <div>
      <SubTitle title={title} icon={<FontAwesomeIcon icon={faPlane} />} />
      <Formik<FormValues> initialValues={initialValues} onSubmit={submitForm}>
        {({ values, touched, errors, setFieldValue }) => {
          return (
            <Form>
              <div className="space-y-4">
                <div className="flex flex-col space-y-1">
                  <FormLabel htmlFor="date">Date:</FormLabel>
                  <UKFormattedDatePicker
                    customInput={
                      <div className="relative h-12">
                        <input
                          type="text"
                          value={
                            values.date
                              ? formatDateToReadableStringShorthandMonth(
                                  values.date.toDateString()
                                )
                              : 'Select'
                          }
                          readOnly
                          className="h-12 "
                        />
                        {values.date ? (
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="absolute right-0 top-4 text-wtw-secondary-text cursor-pointer px-4"
                            onClick={() => setFieldValue('date', null)}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="absolute right-0 top-4 text-wtw-secondary-text cursor-pointer px-4"
                          />
                        )}
                      </div>
                    }
                    id="date"
                    selected={values.date}
                    onChange={(date: Date) => {
                      setFieldValue('date', date);
                    }}
                    placeholderText="Select"
                    showYearDropdown
                    dropdownMode="select"
                    disabled={
                      alternativeTransport || values.alternative_transport
                        ? true
                        : false
                    }
                  />
                </div>
                <div>
                  {touched.date && errors.date && (
                    <ErrorText className="text-sm">{errors.date}</ErrorText>
                  )}
                </div>
                <div className="flex flex-col">
                  <FormLabel htmlFor="time">Time:</FormLabel>
                  <DatePicker
                    customInput={
                      <div className="relative h-12">
                        <input
                          type="text"
                          value={
                            values.time
                              ? format(values.time, 'HH:mm')
                              : 'Select'
                          }
                          className="h-12"
                        />
                        {values.time ? (
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="absolute right-0 top-4 text-wtw-secondary-text cursor-pointer px-4"
                            onClick={() => setFieldValue('time', null)}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="absolute right-0 top-4 text-wtw-secondary-text cursor-pointer px-4"
                          />
                        )}
                      </div>
                    }
                    className="h-12"
                    id="time"
                    selected={values.time}
                    onChange={(date: Date) => {
                      setFieldValue('time', date);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    disabled={
                      alternativeTransport || values.alternative_transport
                        ? true
                        : false
                    }
                  />
                </div>
                <div>
                  {touched.time && errors.time && (
                    <ErrorText className="text-sm">{errors.time}</ErrorText>
                  )}
                </div>
                <div className="flex flex-col">
                  <FormLabel htmlFor="flight_number">Flight number:</FormLabel>
                  <Input
                    id="flight_number"
                    name="flight_number"
                    value={values.flight_number}
                    onChange={(e) =>
                      setFieldValue('flight_number', e.target.value)
                    }
                    errors={
                      touched.flight_number && errors.flight_number
                        ? ([errors.flight_number] as string[])
                        : []
                    }
                    placeholder="Enter flight number"
                    disabled={
                      alternativeTransport || values.alternative_transport
                        ? true
                        : false
                    }
                  />
                </div>

                <Checkbox
                  name={`${formId}-alternative-transport-checkbox`}
                  option={{
                    value: 'alternative_transport',
                    label: 'Arriving by alternative form of transport',
                  }}
                  checked={
                    alternativeTransport || values.alternative_transport
                      ? true
                      : false
                  }
                  onChange={(_, checked) => {
                    if (checked) {
                      setFieldValue('date', null);
                      setFieldValue('time', null);
                      setFieldValue('flight_number', '');
                    } else {
                      setFieldValue('alternative_transport', null);
                    }
                    setAlternativeTransport(checked);
                  }}
                />
                {(alternativeTransport || values.alternative_transport) && (
                  <>
                    <TextArea
                      id="alternative_transport"
                      name="alternative_transport"
                      value={values.alternative_transport ?? ''}
                      onChange={(e) =>
                        setFieldValue('alternative_transport', e.target.value)
                      }
                      placeholder="Enter details about your alternative transport"
                    />
                    <div>
                      {touched.alternative_transport &&
                        errors.alternative_transport && (
                          <ErrorText className="text-sm">
                            {errors.alternative_transport}
                          </ErrorText>
                        )}
                    </div>
                  </>
                )}
                <PrimaryButton
                  label="SAVE"
                  type="submit"
                  fullWidth
                  isDisabled={
                    !(values.date && values.time && values.flight_number) &&
                    !values.alternative_transport
                  }
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FlightDetailForm;
