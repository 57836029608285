import React, { useCallback } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import { useSubmitFormToAPI } from '../../api/forms';
import WrappedField from '../forms/wrapped-field';
import ErrorText from '../error-text';
import { PrimaryButton } from '../../framework-components/button/Button';

interface Values {
  new_password1: string;
  new_password2: string;
}

export function useOnSubmit(): (
  data: Values,
  formHelpers: Pick<
    FormikHelpers<Values>,
    'setSubmitting' | 'setErrors' | 'setStatus'
  >
) => void {
  const params = useParams();
  const submitForm = useSubmitFormToAPI<Values, null>(
    '/api/auth/password/reset/confirm/'
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(
        { ...data, ...params },
        formHelpers
      );
      if (hasSucceeded) {
        window.location.reload();
      }
    },
    [params, submitForm]
  );
}

const ResetPasswordForm: React.FC = () => {
  const onSubmit = useOnSubmit();

  return (
    <Formik<Values>
      initialValues={{
        new_password1: '',
        new_password2: '',
      }}
      onSubmit={onSubmit}
    >
      {({ isValid, values, isSubmitting, status }) => (
        <Form>
          <div className="space-y-8 flex items-center flex-col sm:px-4 justify-around">
            <h1 className="text-h4 sm:text-h3 text-center mt-12 md:mt-0 sm:mb-2 mb-6">
              Reset Password
            </h1>
            <div className="w-full md:w-full space-y-6 pb-4">
              <WrappedField
                name="new_password1"
                type="password"
                label="Password:"
                placeholder="Enter new password"
              />
              <WrappedField
                name="new_password2"
                type="password"
                label="Confirm Password:"
                placeholder="Confirm new password"
              />
            </div>
            <PrimaryButton
              type="submit"
              className="w-full px-4 py-2 border rounded-full focus:outline-none focus:ring-1 hover:opacity-80 bg-slate-200"
              isDisabled={
                isSubmitting ||
                !isValid ||
                !values.new_password1 ||
                !values.new_password2
              }
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </PrimaryButton>
            {status && <ErrorText>{status}</ErrorText>}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
