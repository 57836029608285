import React from 'react';

import MyTripStandardPage from '../layout/MyTripStandardPage';
import TwoColumns from '../../admin/common/layout/TwoColumns';
import PlacementObjectivesLeftSideText from './PlacementObjectivesLeftSideText';
import PlacementObjectivesForm from './PlacementObjectivesForm';
import SpanningContainer from '../layout/SpanningContainer';
import UsefulInformation from './UsefulInformationText';

const PlacementObjectives: React.FC = () => {
  return (
    <MyTripStandardPage title="Placement Objectives" showDashLink>
      <SpanningContainer variant="gray">
        <TwoColumns
          leftColumn={<PlacementObjectivesLeftSideText />}
          rightColumn={<PlacementObjectivesForm />}
        />
      </SpanningContainer>
      <SpanningContainer variant="dark-gray">
        <UsefulInformation />
      </SpanningContainer>
    </MyTripStandardPage>
  );
};

export default PlacementObjectives;
