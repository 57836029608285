import React from 'react';

import { SecondaryButton } from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import PostPoneBookingModal from './PostponeBookingModal';

interface Props {
  bookingId: string;
  isPostponed: boolean;
}

const PostponeBooking: React.FC<Props> = ({ bookingId, isPostponed }) => {
  const { isOpen, openModal, closeModal } = useModalState();
  return (
    <>
      <SecondaryButton paddingClassName="py-2 px-4" onClick={openModal}>
        {isPostponed ? 'Unpostpone trip' : 'Postpone trip'}
      </SecondaryButton>
      <PostPoneBookingModal
        isPostponed={isPostponed}
        bookingId={bookingId}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default PostponeBooking;
