import React, { useCallback, useState } from 'react';
import {
  Formik,
  Form,
  FastField,
  Field,
  FieldInputProps,
  FormikProps,
  FormikHelpers,
} from 'formik';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { useAPI } from '../../../../api/api';
import AdminStandardPage from '../../common/AdminStandardPage';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import { Option, BookingStatus } from '../../../../types';
import RemoteDropdown from '../../../../framework-components/dropdown/RemoteDropdown';
import { getFormikErrors } from '../../../../utils/forms';
import MultiChoice from '../../../../framework-components/select/MultiChoice';
import Radio from '../../../../framework-components/select/Radio';
import { Basis, DateType } from './types';
import {
  dateTypeOptions,
  statusOptions,
  basisOptions,
  outputColumnOptions,
} from './constants';
import ErrorText from '../../../error-text';

interface FormValues {
  date_type: DateType | null;
  start_date: Date | null;
  end_date: Date | null;
  university: Option | null;
  country: Option | null;
  status: BookingStatus[];
  basis: Basis[];
  discipline: string[];
  destination: string[];
  output_columns: string[];
}

function useOnSubmit(
  setIsSubmitting: (isSubmitting: boolean) => void
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  return useCallback(
    async (data, formHelpers) => {
      data.output_columns.sort();
      const formData = {
        ...data,
        university: data.university?.value,
        country: data.country?.value,
      };

      try {
        const csrfToken = Cookies.get('csrftoken');
        const headers = {
          'Content-Type': 'application/json',
          ...(csrfToken ? { 'X-CSRFTOKEN': csrfToken } : {}),
        };
        setIsSubmitting(true);
        const response = await fetch(
          '/api/admin/reports/generate-booking-report/',
          {
            method: 'POST',
            headers: { ...headers },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'booking_report.csv';
          document.body.appendChild(a);
          a.click();
          a.remove();
          toast.success('Report created successfully');
        } else {
          const errorData = await response.json();
          formHelpers.setErrors(errorData || {});
          toast.error('Failed to create report');
        }
      } catch (error) {
        toast.error('An unexpected error occurred');
      } finally {
        formHelpers.setSubmitting(false);
        setIsSubmitting(false);
      }
    },
    [setIsSubmitting]
  );
}

const BookingReport = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: disciplines, isValidating: isDisciplinesLoading } = useAPI<
    Option[]
  >('/api/admin/reports/disciplines/');
  const { data: destinations, isValidating: isDestinationsLoading } = useAPI<
    Option[]
  >('/api/admin/reports/destinations/');

  const initialValues: FormValues = {
    date_type: null,
    start_date: null,
    end_date: null,
    university: null,
    country: null,
    status: [],
    basis: [],
    discipline: [],
    destination: [],
    output_columns: [],
  };

  const onSubmit = useOnSubmit(setIsSubmitting);

  return (
    <AdminStandardPage
      title="Booking Report"
      breadcrumbs={[{ label: 'Reporting', href: null }]}
      isLoading={isDisciplinesLoading || isDestinationsLoading}
    >
      <div className="flex flex-col gap-4 min-h-screen">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values }) => (
            <Form className="flex flex-col gap-4">
              <div className="flex gap-4 flex-wrap">
                <FastField name="date_type">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<DateType>;
                    form: FormikProps<FormValues>;
                  }) => (
                    <div className="flex flex-col">
                      <Radio
                        name={field.name}
                        label="Date type"
                        options={dateTypeOptions}
                        onChange={(val: string | number) => {
                          form.setFieldValue(field.name, val as DateType);
                        }}
                        fontWeight="normal"
                      />
                      {form.errors.date_type && (
                        <ErrorText className="text-sm mt-2">
                          {form.errors.date_type}
                        </ErrorText>
                      )}
                    </div>
                  )}
                </FastField>
                <Field name="start_date">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<Date | null>;
                    form: FormikProps<FormValues>;
                  }) => (
                    <div className="flex flex-col">
                      <label
                        htmlFor="startDate"
                        className="block text-sm font-medium text-gray-900 mb-1"
                      >
                        From date
                      </label>
                      <DatePicker
                        id="startDate"
                        selected={field.value}
                        onChange={(date: Date) =>
                          form.setFieldValue(field.name, date)
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                        autoComplete="off"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        maxDate={values.end_date}
                      />
                      {form.errors.start_date && (
                        <ErrorText className="text-sm mt-2">
                          {form.errors.start_date}
                        </ErrorText>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="end_date">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<Date | null>;
                    form: FormikProps<FormValues>;
                  }) => (
                    <div className="flex flex-col">
                      <label
                        htmlFor="endDate"
                        className="block text-sm font-medium text-gray-900 mb-1"
                      >
                        To date
                      </label>
                      <DatePicker
                        id="endDate"
                        selected={field.value}
                        onChange={(date: Date) =>
                          form.setFieldValue(field.name, date)
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                        autoComplete="off"
                        minDate={values.start_date}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                      {form.errors.end_date && (
                        <ErrorText className="text-sm mt-2">
                          {form.errors.end_date}
                        </ErrorText>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <h2 className="text-lg font-bold">Optional filters:</h2>
                <p className="text-sm">
                  For all optional filters, if none are selected, all data will
                  be included.
                </p>
                <div className="flex flex-wrap gap-4">
                  <FastField name="university">
                    {({
                      field,
                      form,
                    }: {
                      field: FieldInputProps<Option>;
                      form: FormikProps<FormValues>;
                    }) => (
                      <div className="flex flex-col">
                        <RemoteDropdown
                          id="university"
                          label="University"
                          path="/api/admin/reports/universities/"
                          errors={getFormikErrors(form, field.name)}
                          name={field.name}
                          value={field.value}
                          onSelect={(option: Option | null) => {
                            form.setFieldValue(field.name, option);
                          }}
                          placeholder="Any university"
                        />
                        {form.errors.university && (
                          <ErrorText className="text-sm mt-2">
                            {form.errors.university}
                          </ErrorText>
                        )}
                      </div>
                    )}
                  </FastField>
                  <FastField name="country">
                    {({
                      field,
                      form,
                    }: {
                      field: FieldInputProps<Option>;
                      form: FormikProps<FormValues>;
                    }) => (
                      <div className="flex flex-col">
                        <RemoteDropdown
                          id="country"
                          label="Country"
                          path="/api/admin/reports/countries/"
                          errors={getFormikErrors(form, field.name)}
                          name={field.name}
                          value={field.value}
                          onSelect={(option: Option | null) => {
                            form.setFieldValue(field.name, option);
                          }}
                          placeholder="Any country"
                        />
                        {form.errors.country && (
                          <ErrorText className="text-sm mt-2">
                            {form.errors.country}
                          </ErrorText>
                        )}
                      </div>
                    )}
                  </FastField>
                </div>
                <hr className="my-2" />
                <FastField name="status">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<BookingStatus[]>;
                    form: FormikProps<FormValues>;
                  }) => (
                    <div className="flex flex-col">
                      <MultiChoice
                        name={field.name}
                        label="Status"
                        options={statusOptions}
                        onChange={(vals: string[]) => {
                          form.setFieldValue(field.name, vals);
                        }}
                        currentValues={field.value}
                        row
                        fontWeight="normal"
                        selectAll
                      />
                      {form.errors.status && (
                        <ErrorText className="text-sm mt-2">
                          {form.errors.status}
                        </ErrorText>
                      )}
                    </div>
                  )}
                </FastField>
                <hr className="my-2" />
                <FastField name="basis">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<Basis[]>;
                    form: FormikProps<FormValues>;
                  }) => (
                    <div className="flex flex-col">
                      <MultiChoice
                        name={field.name}
                        label="Participation basis"
                        options={basisOptions}
                        onChange={(vals: string[]) => {
                          form.setFieldValue(field.name, vals);
                        }}
                        currentValues={field.value}
                        row
                        fontWeight="normal"
                        selectAll
                      />
                      {form.errors.basis && (
                        <ErrorText className="text-sm mt-2">
                          {form.errors.basis}
                        </ErrorText>
                      )}
                    </div>
                  )}
                </FastField>
                <hr className="my-2" />
                {disciplines && (
                  <FastField name="discipline">
                    {({
                      field,
                      form,
                    }: {
                      field: FieldInputProps<string[]>;
                      form: FormikProps<FormValues>;
                    }) => (
                      <div className="flex flex-col">
                        <MultiChoice
                          name={field.name}
                          label="Discipline"
                          options={disciplines}
                          onChange={(vals: string[]) => {
                            form.setFieldValue(field.name, vals);
                          }}
                          currentValues={field.value}
                          row
                          fontWeight="normal"
                          selectAll
                        />
                        {form.errors.discipline && (
                          <ErrorText className="text-sm mt-2">
                            {form.errors.discipline}
                          </ErrorText>
                        )}
                      </div>
                    )}
                  </FastField>
                )}
                <hr className="my-2" />
                {destinations && (
                  <FastField name="destination">
                    {({
                      field,
                      form,
                    }: {
                      field: FieldInputProps<string[]>;
                      form: FormikProps<FormValues>;
                    }) => (
                      <div className="flex flex-col">
                        <MultiChoice
                          name={field.name}
                          label="Destination"
                          options={destinations}
                          onChange={(vals: string[]) => {
                            form.setFieldValue(field.name, vals);
                          }}
                          currentValues={field.value}
                          row
                          fontWeight="normal"
                          selectAll
                        />
                        {form.errors.destination && (
                          <ErrorText className="text-sm mt-2">
                            {form.errors.destination}
                          </ErrorText>
                        )}
                      </div>
                    )}
                  </FastField>
                )}
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <h2 className="text-lg font-bold">Output columns:</h2>
                <FastField name="output_columns">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<string[]>;
                    form: FormikProps<FormValues>;
                  }) => (
                    <div className="flex flex-col">
                      <MultiChoice
                        name={field.name}
                        options={outputColumnOptions}
                        onChange={(vals: string[]) => {
                          form.setFieldValue(field.name, vals);
                        }}
                        currentValues={field.value}
                        row
                        fontWeight="normal"
                        selectAll
                      />
                      {form.errors.output_columns && (
                        <ErrorText className="text-sm mt-2">
                          {form.errors.output_columns}
                        </ErrorText>
                      )}
                    </div>
                  )}
                </FastField>
              </div>
              <PrimaryButton
                className="mt-2"
                type="submit"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Generate Report
              </PrimaryButton>
            </Form>
          )}
        </Formik>
      </div>
    </AdminStandardPage>
  );
};

export default BookingReport;
