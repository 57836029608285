import React from 'react';

import PageWrapper from '../booking-form/PageWrapper';
import ReviewBookingForm from './ReviewBookingForm';
import { SECTIONS } from '../booking-form/constants';
import BookingFormContainer from '../../admin/common/BookingFormContainer';

const BookingReview: React.FC = () => {
  return (
    <BookingFormContainer>
      <PageWrapper
        title="Booking review"
        subtitle={
          "Check you've entered your details correctly, before proceeding to the next step."
        }
        section={SECTIONS.REVIEW}
      >
        <ReviewBookingForm />
      </PageWrapper>
    </BookingFormContainer>
  );
};

export default BookingReview;
