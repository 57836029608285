import React, { useState } from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';

interface ImageGallery {
  imageUrls: ReadonlyArray<string> | undefined;
}

interface Arrow {
  direction: 'left' | 'right';
  onClick: () => void;
}

const Arrow: React.FC<Arrow> = ({ direction, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="h-9 w-9 rounded-full bg-[rgba(0,0,0,0.28)] flex items-center justify-center cursor-pointer"
      aria-label={`${direction}-chevron`}
    >
      {direction === 'left' ? (
        <ChevronLeftIcon className="h-7 w-7 stroke-white fill-white" />
      ) : (
        <ChevronRightIcon className="h-7 w-7 stroke-white fill-white" />
      )}
    </button>
  );
};

const ImageGallery: React.FC<ImageGallery> = ({ imageUrls }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!imageUrls) {
    return <div>No images available</div>;
  }

  return (
    <div className="w-[292px] h-[244px] overflow-hidden relative rounded-lg">
      <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
        <Arrow
          direction="left"
          onClick={() =>
            setCurrentImageIndex((idx) =>
              idx === 0 ? imageUrls.length - 1 : idx - 1
            )
          }
        />
      </div>
      <img
        src={imageUrls[currentImageIndex]}
        className="w-full h-full object-cover"
        alt="Image"
      />
      <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
        <Arrow
          direction="right"
          onClick={() =>
            setCurrentImageIndex((idx) =>
              idx === imageUrls.length - 1 ? 0 : idx + 1
            )
          }
        />
      </div>
    </div>
  );
};

export default ImageGallery;
