import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Formik, Field, FieldInputProps, Form, FormikHelpers } from 'formik';
import { mutate } from 'swr';

import RichTextEditor from '../../text-editor/RichTextEditor';
import Input from '../../../framework-components/input/Input';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';
import ErrorText from '../../error-text';
import { useSubmitFormToAPI } from '../../../api/forms';
import { Article } from './types';

interface FormData {
  title: string;
  content: string;
}

interface Props {
  article: Article;
  setEditingArticle: (article: Article | null) => void;
  destinationId: string;
}

export function useOnSubmit(
  id: string,
  destinationId: string,
  setEditingArticle: (article: Article | null) => void
): (
  data: FormData,
  formHelpers: Pick<
    FormikHelpers<FormData>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI<FormData, FormData>(
    `/api/admin/info-pack/update-article/${id}/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        toast.success('Article updated');
        mutate(`/api/admin/info-pack/list/${destinationId}/`);
        setEditingArticle(null);
      } else {
        toast.error('Failed to update article');
      }
    },
    [submitForm, destinationId, setEditingArticle]
  );
}

const AdminEditInfoPackArticle: React.FC<Props> = ({
  article,
  setEditingArticle,
  destinationId,
}) => {
  const onSubmit = useOnSubmit(article.id, destinationId, setEditingArticle);
  const submitForm = useCallback(
    (values: FormData, formHelpers: FormikHelpers<FormData>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );

  return (
    <Formik<FormData>
      initialValues={{
        title: article.title,
        content: article.content,
      }}
      onSubmit={submitForm}
    >
      {({ errors, values, isSubmitting, setFieldValue }) => (
        <Form>
          <div className="space-y-6 mx-auto mt-6">
            <Field name="title">
              {({ field }: { field: FieldInputProps<string> }) => (
                <div className="space-y-2">
                  <Input
                    id="title"
                    label="Article Title"
                    name={field.name}
                    onChange={field.onChange}
                    value={values.title}
                  />
                  <ErrorText className="text-sm">{errors.title}</ErrorText>
                </div>
              )}
            </Field>
            <div>
              <RichTextEditor
                content={values.content}
                setContent={(value) => setFieldValue('content', value)}
              />
              <ErrorText className="text-sm">{errors.content}</ErrorText>
            </div>
            <div className="flex flex-row justify-end gap-4 space-x-2 mt-3">
              <SecondaryButton
                label="Cancel"
                onClick={() => {
                  setEditingArticle(null);
                }}
              />
              <PrimaryButton
                label="Update article"
                type="submit"
                isDisabled={!values.title || !values.content || isSubmitting}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AdminEditInfoPackArticle;
