import React from 'react';

import Accomodation from './Accomodation';
import TwoColumns from '../../../common/layout/TwoColumns';
import Hospital from './Hospital';
import LocationStaff from './LocationStaff';
import KeyInfo from './KeyInfo';
import DestinationRequirements from './DestinationRequirements';
import AdminWidgetContainer from '../../../../../framework-components/card/AdminWidgetContainer';
import { PrimaryButton } from '../../../../../framework-components/button/Button';

interface Props {
  destinationId?: string;
}

const DestinationListingKeyInfo: React.FC<Props> = ({ destinationId }) => {
  if (!destinationId) {
    return null;
  }

  return (
    <>
      <Accomodation destinationId={destinationId} />
      <TwoColumns
        leftColumn={
          <>
            <Hospital destinationId={destinationId} />
            <LocationStaff destinationId={destinationId} />
            <AdminWidgetContainer hasBorder hasShadow>
              <div className="flex items-center justify-between flex-wrap gap-4">
                <h4 className="text-sh2">Link to all Capacity Calendars:</h4>

                <PrimaryButton
                  onClick={() =>
                    (window.location.href = `/admin/capacities/department-capacities?destination=${destinationId}`)
                  }
                  label="View Calendars"
                />
              </div>
            </AdminWidgetContainer>
          </>
        }
        rightColumn={
          <>
            <DestinationRequirements destinationId={destinationId} />
            <KeyInfo destinationId={destinationId} />
          </>
        }
      />
    </>
  );
};

export default DestinationListingKeyInfo;
