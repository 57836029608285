import React from 'react';
import { Link } from 'react-router-dom';

import ManageMultipleObjects from '../../../../manage-multiple-objects/ManageMultipleObjects';
import { HospitalDetailsTableData } from './types';

interface Props {
  destinationId?: string;
}

const tableFields = [
  {
    name: 'name',
    header: 'Name',
    render: (data: HospitalDetailsTableData) => (
      <Link
        className="text-table-xs"
        to={`/admin/directories/hospitals/${data.id}/`}
      >
        {data.formal_name}
      </Link>
    ),
  },
  {
    name: 'city',
    header: 'Town',
    render: (data: HospitalDetailsTableData) => data.address?.city,
  },
  {
    name: 'main_contact',
    header: 'Main Contact',
    render: (data: HospitalDetailsTableData) => data.main_contact,
  },
  {
    name: 'telephone',
    header: 'Telephone',
    render: (data: HospitalDetailsTableData) => data.telephone,
  },
  {
    name: 'email',
    header: 'Email',
    render: (data: HospitalDetailsTableData) => {
      return (
        <a
          className="text-table-xs"
          href={`mailto:${data.email}`}
          title={data.email}
        >
          {data.email}
        </a>
      );
    },
  },
];

const Hospital: React.FC<Props> = ({ destinationId }) => {
  return (
    <ManageMultipleObjects<HospitalDetailsTableData>
      title="Hospitals:"
      tableFields={tableFields}
      quickFormFields={[]}
      getUrl={`/api/admin/destination/destination-profile/${destinationId}/hospital/list/`}
      hideManageButton
      isFixedTableLayout
      noDataFoundMessage="There are currently no hospitals for this destination."
    />
  );
};

export default Hospital;
