import React, { useContext, useState } from 'react';

import MyTripStandardPage from '../layout/MyTripStandardPage';
import TabsWithImages from '../components/TabsWithImages/TabsWithImages';
import PropertySummary from '../components/PropertySummary/PropertySummary';
import SpanningContainer from '../layout/SpanningContainer';
import ProfileBrowser from '../components/profile-browser/ProfileBrowser';
import { useAPI } from '../../../api/api';
import { MyBookingsContext } from '../context-providers';
import { formatDateToDayDateMonthYear } from '../../../utils/dates';
import LoadingOverlay from '../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../components/ErrorOverlay';
import HospitalDepartmentSummary from './HospitalDepartmentSummary';
import { HouseData, HospitalData } from './types';
import BlockTabs from '../../../framework-components/blocktabs/BlockTabs';
import HtmlRenderer from '../../../framework-components/html-renderer/HtmlRenderer';

interface HouseAndHospitalData {
  house: { HOUSE: HouseData; VILLAGE: HouseData };
  hospital: HospitalData[];
}

const TripSummaryPage: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const [activeTab, setActiveTab] = useState<string>('1');

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };

  const bookingId = activeBooking ? activeBooking.id : null;

  const { data, isValidating, error } = useAPI<HouseAndHospitalData>(
    bookingId
      ? `/api/my-trip/booking/${bookingId}/house-hospital-village/`
      : null
  );

  const houseData = data?.house.HOUSE;
  const hospitalData = data?.hospital[0];
  const villageData = data?.house.VILLAGE;

  const tabsData = [
    {
      id: '1',
      label: 'House',
      imageUrl: houseData?.house_photos.main_photo
        ? houseData?.house_photos.main_photo
        : '/static/neutral-house.jpg',
    },
    {
      id: '2',
      label: 'Hospital',
      imageUrl: hospitalData?.hospital_photos.main_photo
        ? hospitalData?.hospital_photos.main_photo
        : '/static/neutral-hospital.jpg',
    },
    ...(data?.house.VILLAGE
      ? [
          {
            id: '3',
            label: 'Village',
            imageUrl: villageData?.house_photos.main_photo
              ? villageData?.house_photos.main_photo
              : '/static/neutral-village.jpg',
          },
        ]
      : []),
  ];

  const activeDatesMap = {
    '1': {
      startDate: houseData?.start_date || '2024-6-12',
      endDate: houseData?.end_date || '2024-8-12',
    },
    '2': {
      startDate: hospitalData?.start_date || '2024-6-12',
      endDate: hospitalData?.end_date || '2024-8-12',
    },
    '3': {
      startDate: villageData?.start_date || '2024-6-12',
      endDate: villageData?.end_date || '2024-8-12',
    },
  };

  const { startDate, endDate } = activeDatesMap[activeTab as '1' | '2' | '3'];
  const formattedStartDate = formatDateToDayDateMonthYear(startDate);
  const formattedEndDate = formatDateToDayDateMonthYear(endDate);

  const galleryImageUrls = () => {
    if (activeTab === '1') {
      return houseData?.house_photos.gallery_photos;
    } else if (activeTab === '2') {
      const galleryPhotos =
        hospitalData?.hospital_photos.gallery_photos &&
        hospitalData?.hospital_photos.gallery_photos.length > 0
          ? hospitalData?.hospital_photos.gallery_photos
          : ['/static/neutral-hospital.jpg'];
      return galleryPhotos;
    } else if (activeTab === '3') {
      return villageData?.house_photos.gallery_photos;
    }
    return ['No image gallery available'];
  };

  const houseContentNextToGallery = (
    <div>
      <p className="mb-2">
        <strong>House details:</strong>
      </p>
      <p>
        Postal address:
        <strong> {houseData?.address}</strong>
      </p>
      <p>
        Max occupants: <strong>{houseData?.maximum_occupants}</strong>
      </p>
      <p>
        Bedroom sharing:{' '}
        <strong>
          {houseData?.bedroom_capacity || 'Figures not yet provided'}
        </strong>
      </p>
      <p>
        Link: <strong>{houseData?.house_link || 'Not yet provided'}</strong>{' '}
      </p>
    </div>
  );
  const houseContentUnderGallery = houseData && (
    <div>
      <h1 className="text-sm-bold mb-2">House overview</h1>
      {<HtmlRenderer htmlString={houseData?.description} />}
    </div>
  );

  const hospitalContentNextToGallery = (
    <div>
      <p>
        No. of beds: <strong>{hospitalData?.num_beds}</strong>
      </p>
      <p>
        No. of staff: <strong>{hospitalData?.num_staff}</strong>
      </p>
      <p>
        Address:
        <strong>
          {hospitalData?.address.line1},
          {hospitalData?.address.line2 ? `${hospitalData.address.line2},` : ''}{' '}
          {hospitalData?.address.city}, {hospitalData?.address.county}{' '}
          {hospitalData?.address.postcode}
        </strong>
      </p>
      <p>
        Main contact: <strong>{hospitalData?.main_contact}</strong>
      </p>
    </div>
  );

  const hospitalContentUnderGallery = hospitalData && (
    <div>
      <h1 className="text-sm-bold mb-2">General hospital information</h1>
      {<HtmlRenderer htmlString={hospitalData?.description_html} />}
      <h1 className="text-sm-bold my-2">Getting to the hospital</h1>
      {<HtmlRenderer htmlString={hospitalData?.location_html} />}
    </div>
  );

  const villageContentNextToGallery = (
    <div>
      <p>
        Address: <strong>{villageData?.address}</strong>
      </p>
      <p>
        Maxiumum occupants: <strong>{villageData?.maximum_occupants}</strong>{' '}
      </p>
      <p>
        Bedroom capacity:{' '}
        <strong>{villageData?.bedroom_capacity || 'Not yet provided'}</strong>
      </p>
      <p>
        Link: <strong>{villageData?.house_link || 'Not yet provided'}</strong>
      </p>
    </div>
  );

  const villageContentUnderGallery = villageData && (
    <div>
      <h1 className="text-sm-bold mb-2">Village overview</h1>
      {<HtmlRenderer htmlString={villageData?.description} />}
    </div>
  );
  const houseName = houseData?.name;
  const hospitalName = hospitalData?.display_name;
  const villageName = villageData?.name;

  const activeContentMap = {
    '1': {
      contentNextToGallery: houseContentNextToGallery,
      contentUnderGallery: houseContentUnderGallery,
      name: houseName,
    },
    '2': {
      contentNextToGallery: hospitalData ? (
        hospitalContentNextToGallery
      ) : (
        <div>
          <p>Your hospital has not yet been assigned</p>
        </div>
      ),
      contentUnderGallery: hospitalData ? hospitalContentUnderGallery : null,
      name: hospitalData ? hospitalName : 'Hospital not yet assigned',
    },
    '3': {
      contentNextToGallery: villageContentNextToGallery,
      contentUnderGallery: villageContentUnderGallery,
      name: villageName,
    },
  };

  const { contentNextToGallery, contentUnderGallery, name } =
    activeContentMap[activeTab as '1' | '2' | '3'];

  let content;

  if (activeTab === '1') {
    content = (
      <div>
        <SpanningContainer variant="gray">
          <ProfileBrowser
            title="My team"
            data={houseData?.destination.user_set || []}
            defaultImageUrl="/static/unknown_N.png"
          />
        </SpanningContainer>
        <div className="px-12 -mx-12 bg-white py-8">
          <ProfileBrowser
            title="My housemates"
            data={houseData?.housemates || []}
            defaultImageUrl="/static/unknown_N.png"
          />
        </div>
      </div>
    );
  } else if (hospitalData && activeTab === '2') {
    content = <HospitalDepartmentSummary hospitalData={hospitalData} />;
  } else if (activeTab === '3') {
    content = (
      <div>
        <SpanningContainer variant="gray">
          <ProfileBrowser
            title="Village staff"
            data={villageData?.destination.user_set || []}
            defaultImageUrl="/static/unknown_N.png"
          />
        </SpanningContainer>
        <div className="px-12 -mx-12 bg-white py-8">
          <ProfileBrowser
            title="My housemates"
            data={villageData?.housemates || []}
            defaultImageUrl="/static/unknown_N.png"
          />
        </div>
      </div>
    );
  } else {
    <p>No data available</p>;
  }

  return (
    <div>
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      <MyTripStandardPage title="My account" />
      <BlockTabs
        tabs={[
          {
            label: 'Tasks',
            href: '/my-trip/',
            current: false,
          },
          {
            label: 'My Trip Details',
            href: '/my-trip/trip-summary/',
            current: true,
          },
        ]}
        variant="pilled_tab_with_border_bottom"
      />
      <div className="px-12 -mx-12 bg-white py-8">
        <TabsWithImages tabs={tabsData} onTabChange={handleTabChange} />
        <PropertySummary
          name={name}
          startDate={formattedStartDate}
          endDate={formattedEndDate}
          galleryImageUrls={galleryImageUrls}
          contentNextToGallery={contentNextToGallery}
          contentUnderGallery={contentUnderGallery}
        />
      </div>
      {content}
    </div>
  );
};

export default TripSummaryPage;
