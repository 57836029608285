import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { mutate } from 'swr';

import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';
import { Option } from '../../../types';
import useModalState from '../../../hooks/modals';
import Modal from '../../modal';
import { CalendarLock, CalendarType } from './types';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  destination: Option | null;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  handleCancelEditing: () => void;
  handleSaveEvents: () => Promise<void>;
  isLocked: boolean;
  acquireLock: () => Promise<CalendarLock>;
  calendarType: CalendarType;
}

const EditSaveButtons: React.FC<Props> = ({
  startDate,
  endDate,
  destination,
  isEditing,
  setIsEditing,
  handleCancelEditing,
  handleSaveEvents,
  isLocked,
  acquireLock,
  calendarType,
}) => {
  const { isOpen, openModal, closeModal } = useModalState();
  const [isLoading, setIsLoading] = useState(false);

  const handleStartEditing = async () => {
    setIsLoading(true);
    try {
      const lock = await acquireLock();
      setIsEditing(true);
      mutate(`/api/admin/calendar/lock/${calendarType}/`, lock, false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEditing) {
      const interval = setInterval(() => {
        acquireLock();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [acquireLock, isEditing]);

  return (
    <>
      {startDate && endDate && destination && (
        <div>
          {isEditing ? (
            <div className="flex gap-2">
              <SecondaryButton
                label="Cancel"
                onClick={() => {
                  handleCancelEditing();
                  closeModal();
                }}
                type="button"
              />
              <PrimaryButton label="Save" onClick={openModal} type="button" />
              <Modal isOpen={isOpen} onClose={closeModal}>
                <div>
                  <div className="flex flex-col mt-4 ml-4 mr-4 mb-4">
                    <Dialog.Title className="text-sh2 mb-4">
                      Are you sure you want to save?
                    </Dialog.Title>
                    <div className="inline-flex justify-end gap-4 mt-4 mb-4">
                      <SecondaryButton label="Close" onClick={closeModal} />
                      <PrimaryButton
                        label="Save"
                        type="button"
                        onClick={() => {
                          handleSaveEvents();
                          closeModal();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          ) : (
            <div className="">
              <PrimaryButton
                label="Edit mode"
                onClick={handleStartEditing}
                type="button"
                isLoading={isLoading}
                isDisabled={isLocked}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EditSaveButtons;
