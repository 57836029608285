import React, { useCallback, useContext } from 'react';

import { Option } from '../../../../types';
import { GENDER } from '../../../booking/booking-form/constants';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import Calendar from '../../../calendar/Calendar';
import SelectDates from '../SelectDates';
import EditSaveButtons from '../EditSaveButtons';
import { useCapacityCalendar } from '../hooks';
import { EventMetadata, SectionMetadata } from './types';
import { checkIsSectionDisabled } from './utils';
import { getEventForDay, onDropOnDay, getIsLocked } from '../utils';
import { Event } from '../../../calendar/types';
import { acquireCalendarLock } from '../actions';
import { CALENDAR_TYPES } from '../constants';
import { LoggedInUserContext } from '../../../../context-providers/logged-in-user';
import { CalendarLock } from '../types';

interface Props {
  dayQueryParam: string | null;
  monthQueryParam: string | null;
  yearQueryParam: string | null;
  period: string;
  destination: Option | null;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  calendarLock: CalendarLock | undefined;
}

const RoomCapacityCalendar: React.FC<Props> = ({
  dayQueryParam,
  monthQueryParam,
  yearQueryParam,
  period,
  destination,
  isEditing,
  setIsEditing,
  calendarLock,
}) => {
  const {
    selectedDates,
    setSelectedDates,
    events,
    setEvents,
    sections,
    errors,
    startDate,
    endDate,
    handleCancelEditing,
    handleSaveEvents,
    selectedEvent,
    setSelectedEvent,
  } = useCapacityCalendar<EventMetadata, SectionMetadata>(
    destination,
    dayQueryParam,
    monthQueryParam,
    yearQueryParam,
    period,
    `/api/admin/calendar/room-bookings/${destination?.value}/`,
    `/api/admin/calendar/room-sections/${destination?.value}/`,
    true,
    '/api/admin/calendar/update-room-bookings/',
    setIsEditing
  );

  const user = useContext(LoggedInUserContext);

  const handleAcquireLock = useCallback(async () => {
    return acquireCalendarLock(CALENDAR_TYPES.room);
  }, []);

  const getOverlayColour = useCallback(
    (event: Event<EventMetadata>, light: boolean) => {
      if (event.metadata?.gender === GENDER.FEMALE) {
        if (light) {
          return 'bg-pink-100';
        }
        return 'bg-pink-300';
      }
      if (light) {
        return 'bg-blue-100';
      }
      return 'bg-blue-300';
    },
    []
  );

  if (errors.events || errors.sections) {
    return <ErrorOverlay detail={errors.events || errors.sections} />;
  }

  const isLocked = getIsLocked(calendarLock, user);

  return (
    <div className="border border-gray-300 bg-white rounded-lg w-fit">
      <div className="flex flex-wrap justify-start items-end gap-4 p-4">
        <SelectDates
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          isDisabled={isEditing}
        />
        <EditSaveButtons
          startDate={startDate}
          endDate={endDate}
          destination={destination}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleCancelEditing={handleCancelEditing}
          handleSaveEvents={handleSaveEvents}
          isLocked={isLocked}
          acquireLock={handleAcquireLock}
          calendarType={CALENDAR_TYPES.room}
        />
      </div>
      {startDate && endDate && destination && (
        <div className="p-4">
          <Calendar<EventMetadata, SectionMetadata>
            isEditing={isEditing}
            startDate={startDate}
            endDate={endDate}
            events={events}
            setEvents={setEvents}
            sections={sections}
            getEventForDay={getEventForDay}
            onDropOnDay={onDropOnDay}
            checkIsSectionDisabled={checkIsSectionDisabled}
            getOverlayColour={getOverlayColour}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        </div>
      )}
      {!destination && (
        <div className="px-4 pb-4">Please select a location.</div>
      )}
      {(!startDate || !endDate) && (
        <div className="px-4 pb-4">Please select a year, month and period.</div>
      )}
    </div>
  );
};

export default RoomCapacityCalendar;
