import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';

import Modal from '../../../modal';
import QuickForm from '../../../../framework-components/quickform/QuickForm';
import { apiRequest } from '../../../../api/api';
import { CurrencyType } from '../../../booking/booking-form/types';
import { CURRENCY_TO_SYMBOL } from '../../../booking/booking-form/constants';
import { Option } from '../../../../types';
import { CHARGE_TYPE_LABELS } from '../../../../constants';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  bookingId: string;
  reloadData: () => void;
  currency: CurrencyType;
}

interface FormValues {
  date: Date | null;
  description: string;
  amount: number;
  charge_type: Option | null;
  invoice: Option | null;
  account_code: Option | null;
}

const ChargeTypeOptions: Option[] = Object.entries(CHARGE_TYPE_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  })
);

const RecordChargeModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  bookingId,
  reloadData,
  currency,
}) => {
  const focusRef = React.useRef(null);
  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 flex flex-col ml-4 mr-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Record charge
        </Dialog.Title>
        <QuickForm
          initialValues={{
            date: null,
            description: '',
            amount: 0,
            charge_type: null,
            invoice: null,
            account_code: null,
          }}
          fields={[
            {
              type: 'datetime',
              name: 'date',
              label: 'Charge Date',
            },
            {
              type: 'smalltext',
              name: 'amount',
              label: `Amount(${CURRENCY_TO_SYMBOL[currency]})`,
              extraProps: {
                type: 'number',
                step: '0.01',
              },
            },
            {
              type: 'smalltext',
              name: 'description',
              label: 'Description',
            },
            {
              type: 'local-dropdown',
              name: 'charge_type',
              label: 'Charge Type',
              options: ChargeTypeOptions,
            },
            {
              type: 'remote-dropdown',
              name: 'invoice',
              label: 'Invoice',
              path: `/api/admin/bookings/${bookingId}/get-invoice-options/`,
            },
            {
              type: 'remote-dropdown',
              name: 'account_code',
              label: 'Account',
              path: `/api/admin/bookings/get-xero-accounts/`,
            },
          ]}
          onSubmit={(data: FormValues) => {
            return apiRequest<FormValues>(
              `/api/admin/bookings/${bookingId}/create-ledger-charge/`,
              {
                method: 'POST',
                body: JSON.stringify({
                  ...data,
                  amount_pence: data.amount * 100,
                  charge_type: data.charge_type?.value,
                  invoice: data.invoice?.value,
                  account_code: data.account_code?.value,
                }),
              }
            );
          }}
          onSubmitSuccess={() => {
            toast.success('Successfully recorded payment');
            closeModal();
            reloadData();
          }}
          buttonLabel="Submit"
        />
      </div>
    </Modal>
  );
};

export default RecordChargeModal;
