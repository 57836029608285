import React from 'react';
import classNames from 'classnames';

import { ProgressTrackingData, ProgressTrackerVariant } from './types';
import Tooltip from '../../../../framework-components/tooltip/Tooltip';

const TrackerItem: React.FC<ProgressTrackingData> = ({
  label,
  detail,
  status,
  variant = 'default',
}) => {
  const tooltipId = `my-tooltip-${label}`;
  return (
    <div
      className={classNames(
        'tracker-item flex items-center justify-center rounded-full',
        {
          'bg-green-400': status === 'COMPLETE',
          'bg-red-400': status === 'OVERDUE',
          'bg-amber-400': status === 'DUE_SOON',
          'bg-gray-400': status === 'INCOMPLETE',
          'w-10 h-10': variant === 'default',
          'w-[18px] h-[18px] text-xs text-[9px] ml-1 mb-1': variant === 'small',
          'ml-2': variant !== 'small',
        }
      )}
      data-tooltip-id={tooltipId}
      data-tooltip-content={detail}
    >
      {label}
      <Tooltip id={tooltipId} />
    </div>
  );
};

interface ProgressTrackerProps {
  trackingData: ProgressTrackingData[];
  variant?: ProgressTrackerVariant;
}

const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  trackingData,
  variant = 'default',
}) => {
  return (
    <div className="flex flex-wrap">
      {trackingData.map((item: ProgressTrackingData) => (
        <TrackerItem
          key={item.label}
          label={item.label}
          detail={item.detail}
          status={item.status}
          variant={variant}
        />
      ))}
    </div>
  );
};

export default ProgressTracker;
