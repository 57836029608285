import React, { useCallback, useContext } from 'react';

import TwoColumns from '../../admin/common/layout/TwoColumns';
import {
  getMyHousePhoto,
  getMyIdPhoto,
  setHousePhoto,
  setIdPhotoImage,
} from './actions';
import ManageSingleImage from '../components/ManageSingleImage/ManageSingleImage';
import WidgetContainer from '../components/WidgetContainer';
import { MyBookingsContext } from '../context-providers';
import { GENDER } from '../../booking/booking-form/constants';

const MyPhotos: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const bookingId = activeBooking?.id || '';

  const onHousePhotoSuccessfulUpload = useCallback(
    async (fileId: string) => {
      await setHousePhoto(bookingId, fileId);
    },
    [bookingId]
  );

  const onIdPhotoSuccessfulUpload = useCallback(
    async (fileId: string) => {
      await setIdPhotoImage(bookingId, fileId);
    },
    [bookingId]
  );

  return (
    <WidgetContainer title="My Photos">
      <TwoColumns
        remainColumnsOnMobile
        className="mt-6"
        leftColumn={
          <div className="place-items-center">
            <ManageSingleImage
              resizeImage
              title="ID Photo"
              getCurrentImageUrl={() =>
                getMyIdPhoto(bookingId).then((photo) => photo.id_photo_url)
              }
              onUploadNewImage={onIdPhotoSuccessfulUpload}
              alternatePlaceHolderImageSrc={
                activeBooking?.applicant.gender === GENDER.MALE
                  ? '/static/unknown_M.gif'
                  : '/static/unknown_F.gif'
              }
            />
          </div>
        }
        rightColumn={
          <div className="place-items-center">
            <ManageSingleImage
              resizeImage
              title="House Photo"
              infoIconText="A photo you're happy to share with your housemates (you'll get to see their photos and details too)."
              getCurrentImageUrl={() =>
                getMyHousePhoto(bookingId).then(
                  (photo) => photo.house_photo_url
                )
              }
              onUploadNewImage={onHousePhotoSuccessfulUpload}
              alternatePlaceHolderImageSrc={
                activeBooking?.applicant.gender === GENDER.MALE
                  ? '/static/unknown_M.gif'
                  : '/static/unknown_F.gif'
              }
            />
          </div>
        }
      />
    </WidgetContainer>
  );
};

export default MyPhotos;
