import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import { useAPI } from '../../../../api/api';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import { ApplicantPhotoData } from './types';
import ApplicantFile from './ApplicantFile';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const showWarningIcon = (data: ApplicantPhotoData) => {
  return Object.values(data).some(
    (photo) => photo?.approval_status === 'PENDING'
  );
};

const ApplicantPhotos: React.FC<Props> = ({ bookingId, canEdit }) => {
  const getUrl = `/api/admin/bookings/${bookingId}/get-applicant-photos/`;
  const { data, isValidating, error } = useAPI<ApplicantPhotoData>(getUrl);

  return (
    <AdminWidgetContainer hasBorder hasShadow>
      {error && !isValidating && <ErrorOverlay />}
      {isValidating && <LoadingOverlay />}
      <div className="flex gap-2 items-center justify-start mb-4">
        <h4 className="text-sh2">Photos</h4>
        {data && showWarningIcon(data) && (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-red-500 text-sh2"
          />
        )}
      </div>
      {data && (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full mb-4">
          <ApplicantFile<ApplicantPhotoData>
            field="id_photo"
            title="ID photo:"
            file={data.id_photo}
            canEdit={canEdit}
            setUrl={`/api/admin/bookings/${bookingId}/set-applicant-photos/`}
            getUrl={getUrl}
            mutateTabUrl={`/api/admin/bookings/${bookingId}/get-info-tab-warning/`}
          />
          <ApplicantFile<ApplicantPhotoData>
            field="passport_photo"
            title="Passport scan:"
            file={data.passport_photo}
            canEdit={canEdit}
            setUrl={`/api/admin/bookings/${bookingId}/set-applicant-photos/`}
            getUrl={getUrl}
            mutateTabUrl={`/api/admin/bookings/${bookingId}/get-info-tab-warning/`}
          />
          <ApplicantFile<ApplicantPhotoData>
            field="house_photo"
            title="House photo:"
            file={data.house_photo}
            canEdit={canEdit}
            setUrl={`/api/admin/bookings/${bookingId}/set-applicant-photos/`}
            getUrl={getUrl}
            mutateTabUrl={`/api/admin/bookings/${bookingId}/get-info-tab-warning/`}
          />
        </div>
      )}
    </AdminWidgetContainer>
  );
};

export default ApplicantPhotos;
