import { DietaryRequirementsOptions, TShirtSize } from './types';
import { Option } from '../../../types';

export const TSHIRT_SIZE_OPTIONS: Option<TShirtSize>[] = [
  { label: 'Small', value: 'S' },
  { label: 'Medium', value: 'M' },
  { label: 'Large', value: 'L' },
  { label: 'Extra Large', value: 'XL' },
  { label: 'Extra Extra Large', value: 'XXL' },
];

export const DIETARY_REQUIREMENT_OPTIONS: Option<DietaryRequirementsOptions>[] =
  [
    { label: 'Vegetarian', value: 'VEGETARIAN' },
    { label: 'Vegan', value: 'VEGAN' },
    { label: 'Pescatarian', value: 'PESCATARIAN' },
    { label: 'Halal', value: 'HALAL' },
    { label: 'Gluten Free', value: 'GLUTEN FREE' },
    { label: 'Food Allergy', value: 'FOOD ALLERGY' },
  ];
