import React from 'react';

import { LocalMedicalExperienceCreateEditData } from './types';
import { TableField } from '../../../../manage-multiple-objects/types';
import { FieldType } from '../../../../../framework-components/quickform/Field';
import {
  addMedicalExperienceDetails,
  removeMedicalExperienceDetails,
  updateMedicalExperienceDetails,
} from './actions';
import ManageMultipleObjects from '../../../../manage-multiple-objects/ManageMultipleObjects';

interface Props {
  destinationId?: string;
}

const tableFields: ReadonlyArray<
  TableField<LocalMedicalExperienceCreateEditData>
> = [
  {
    name: 'discipline.label',
    header: 'Discipline',
    render: (data: LocalMedicalExperienceCreateEditData) =>
      data.discipline?.label,
  },
  {
    name: 'supervisor',
    header: 'Supervisor',
    render: (data: LocalMedicalExperienceCreateEditData) => data.supervisor,
  },
  {
    name: 'qualification',
    header: 'Qualification',
    render: (data: LocalMedicalExperienceCreateEditData) => data.qualification,
  },
  {
    name: 'years_of_study',
    header: 'Years of Study',
    render: (data: LocalMedicalExperienceCreateEditData) => data.years_of_study,
  },
];

const quickformFields: ReadonlyArray<FieldType> = [
  {
    type: 'remote-dropdown',
    name: 'discipline',
    label: 'Discipline',
    path: '/api/admin/destination/destination-profile/discipline-options/list/',
  },
  {
    type: 'smalltext',
    name: 'supervisor',
    label: 'Supervisor',
  },
  {
    type: 'largetext',
    name: 'qualification',
    label: 'Qualication',
  },
  {
    type: 'largetext',
    name: 'years_of_study',
    label: 'Years of Study',
  },
];

const LocalMedicalDetails: React.FC<Props> = ({ destinationId }) => {
  if (!destinationId) {
    return null;
  }

  return (
    <ManageMultipleObjects<LocalMedicalExperienceCreateEditData>
      title="Local Medical Experience:"
      tableFields={tableFields}
      quickFormFields={quickformFields}
      getUrl={`/api/admin/destination/destination-profile/${destinationId}/medical-experience/list/`}
      addHandler={(values) =>
        addMedicalExperienceDetails(destinationId, values)
      }
      editHandler={(values) =>
        updateMedicalExperienceDetails(destinationId, values)
      }
      deleteHandler={(values) =>
        removeMedicalExperienceDetails(destinationId, values.id)
      }
      noDataFoundMessage="There are currently no medical experiences for this destination."
      isFixedTableLayout
    />
  );
};

export default LocalMedicalDetails;
