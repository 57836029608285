import React, { useMemo, useCallback } from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { FormikValues } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

import { FieldType } from '../../framework-components/quickform/Field';
import Modal from '../modal';
import QuickForm from '../../framework-components/quickform/QuickForm';

interface Props<T> {
  isOpen: boolean;
  closeModal: () => void;
  submitItem: (data: T) => Promise<T>;
  onSubmitSuccess?: (data: T) => void;
  data: T | null;
  fields: ReadonlyArray<FieldType> | Array<FieldType>;
  title?: string;
  buttonLabel?: string;
  disable?: boolean;
  modalMaxWidth?: string;
  preSubmitModal?: JSX.Element;
  message?: string;
}

const EditDetailsModal = <T extends FormikValues>({
  isOpen,
  closeModal,
  submitItem,
  onSubmitSuccess,
  data,
  fields,
  title,
  buttonLabel,
  disable,
  preSubmitModal,
  message,
}: Props<T>) => {
  const focusRef = React.useRef(null);

  const handleSubmit = useCallback(
    async (values: T) => {
      return submitItem(values);
    },
    [submitItem]
  );

  const updateValues = useCallback(
    (values: T) => {
      if (onSubmitSuccess) {
        onSubmitSuccess(values);
      }
      toast.success('Item updated');
      closeModal();
    },
    [closeModal, onSubmitSuccess]
  );

  const initialValues: FormikValues = useMemo(
    () =>
      data
        ? data
        : fields
            .map((field) => field.name)
            .reduce((acc, key) => {
              acc[key] = '';
              return acc;
            }, {} as FormikValues),
    [data, fields]
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 relative" data-testid="edit-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          {title || 'Edit'}
        </Dialog.Title>
        <button
          onClick={closeModal}
          className="absolute -top-4 right-0 p-2 rounded-md"
        >
          <FontAwesomeIcon icon={faX} />
        </button>
        {message && <p className="text-sm">{message}</p>}
        <QuickForm
          onSubmit={handleSubmit}
          initialValues={initialValues as T}
          fields={fields}
          onSubmitSuccess={updateValues}
          buttonLabel={buttonLabel}
          disable={disable}
          preSubmitModal={preSubmitModal}
        />
      </div>
    </Modal>
  );
};

export default EditDetailsModal;
