import React, { useCallback } from 'react';

import { Option } from '../../../types';
import Checkbox from './Checkbox';

interface Props {
  name: string;
  label?: string;
  options: ReadonlyArray<Option>;
  onChange: (values: string[]) => void;
  currentValues: string[];
}

const MultiChoice: React.FC<Props> = ({
  name,
  label,
  options,
  onChange,
  currentValues,
}) => {
  const handleChange = useCallback(
    (value: string, checked: boolean) => {
      if (checked) {
        onChange([...currentValues, value]);
      } else {
        onChange(currentValues.filter((item) => item !== value));
      }
    },
    [onChange, currentValues]
  );

  return (
    <div className="space-y-3">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      )}
      {options.map((option) => (
        <Checkbox
          key={option.value}
          option={option}
          name={option.value}
          checked={currentValues?.includes(option.value)}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

export default MultiChoice;
