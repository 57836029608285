import { apiRequest } from '../../../../../api/api';
import {
  DestinationRequirements,
  KeyInfoManageSingleObjectData,
} from './types';

export const updateKeyInfo = (
  destinationId: string,
  keyInfo: KeyInfoManageSingleObjectData
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/key-info/update/`;
  return apiRequest<KeyInfoManageSingleObjectData>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...keyInfo,
      country: keyInfo.country.value,
    }),
  });
};

export const updateDestinationRequirements = async (
  destinationId: string,
  destinationRequirementsData: DestinationRequirements
) => {
  const url = `/api/admin/destination/destination-profile/${destinationId}/requirements/update/`;
  const response = await apiRequest<DestinationRequirements>(url, {
    method: 'POST',
    body: JSON.stringify(destinationRequirementsData),
  });
  return response;
};
