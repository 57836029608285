import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router';
import _ from 'underscore';
import { Link } from 'react-router-dom';

import { LoggedInUserContext } from '../../../context-providers/logged-in-user';
import { MyBookingsContext, Bookings, Booking } from '../context-providers';
import Navbar from '../../../framework-components/navbar/Navbar';
import { useSubmitDataToAPI } from '../../../api/submit';
import Logo from '../../Logo';
import PageContainer from './PageContainer';
import { useAPI } from '../../../api/api';
import TermsAndConditions from '../terms-and-conditions/TermsAndConditions';
import { SHOP_URL } from '../../../constants';

const MyTripContainer: React.FC = () => {
  const [activeBooking, setActiveBooking] = useState<Booking | null>(null);
  const user = useContext(LoggedInUserContext);
  const submitDataToAPI = useSubmitDataToAPI('/api/auth/logout/');
  const userIsImpersonate = user && user.is_impersonate;

  const { data: bookings } = useAPI<Bookings>('/api/my-trip/booking/list/');

  const onClickLogout = useCallback(async () => {
    const { succeeded } = await submitDataToAPI(undefined, _.noop);
    if (succeeded) {
      window.location.reload();
    }
  }, [submitDataToAPI]);

  useEffect(() => {
    if (bookings && bookings.length === 1) {
      setActiveBooking(bookings[0]);
    }
  }, [bookings]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (
    activeBooking &&
    new Date(activeBooking.end_date).getTime() < new Date().getTime() &&
    activeBooking.booking_confirmed_at
  ) {
    return <Navigate to="/alumni" />;
  }

  const stopImpersonate = () => {
    return (window.location.href = `/impersonate/stop/`);
  };

  return (
    <MyBookingsContext.Provider
      value={{
        bookings: bookings,
        activeBooking: activeBooking,
        setActiveBooking: setActiveBooking,
      }}
    >
      <div className="w-full min-w-0 flex flex-col shrink-0">
        <Navbar
          appLogo={
            <Link to="/my-trip">
              <Logo width={200} height={48} />
            </Link>
          }
          navigation={[
            {
              name: 'My profile',
              href: '/my-trip/about-me/',
              children: [
                {
                  name: 'About me',
                  href: '/my-trip/about-me/',
                },
                {
                  name: 'Clinical experience',
                  href: '/my-trip/clinical-experience/',
                },
                {
                  name: 'Clinical interests',
                  href: '/my-trip/clinical-interests/',
                },
                {
                  name: 'Placement objectives',
                  href: '/my-trip/placement-objectives/',
                },
                {
                  name: 'Travel details',
                  href: '/my-trip/travel-details/',
                },
                {
                  name: 'Downloads',
                  href: '/my-trip/downloads/',
                },
              ],
            },
            {
              name: 'My finances',
              href: '/my-trip/my-finances/',
            },
            {
              name: 'Shop',
              href: SHOP_URL,
            },
          ]}
          profileActions={[
            {
              label: userIsImpersonate ? 'Stop Impersonating' : 'Logout',
              onClick: userIsImpersonate ? stopImpersonate : onClickLogout,
            },
          ]}
        />
        <PageContainer>
          <Outlet />
        </PageContainer>
        <footer className="min-h-[60px] flex flex-col items-center mt-4">
          <TermsAndConditions />
        </footer>
      </div>
    </MyBookingsContext.Provider>
  );
};

export default MyTripContainer;
