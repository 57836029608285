import React from 'react';

import { SecondaryButton } from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import ArchiveHospitalModal from './ArchiveHospitalModal';

interface Props {
  hospitalId: string;
  isArchived: boolean;
}

const ArchiveHospital: React.FC<Props> = ({ hospitalId, isArchived }) => {
  const { isOpen, openModal, closeModal } = useModalState();
  return (
    <>
      <SecondaryButton paddingClassName="py-2 px-4" onClick={openModal}>
        {isArchived ? 'Unarchive hospital' : 'Archive hospital'}
      </SecondaryButton>
      <ArchiveHospitalModal
        isArchived={isArchived}
        hospitalId={hospitalId}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default ArchiveHospital;
