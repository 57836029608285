import React from 'react';

interface Props {
  title: string;
  icon?: React.ReactNode;
}

const SubTitle: React.FC<Props> = ({ title, icon }) => (
  <div className="flex items-center gap-2 mb-4">
    <h2 className="text-h5">{title}</h2>
    {icon}
  </div>
);

export default SubTitle;
