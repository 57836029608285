import React from 'react';

import BreadCrumbs from '../../../framework-components/breadcrumbs/Breadcrumbs';
import LoadingOverlay from '../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from './error/ErrorOverlay';
import PageContainer from '../../layout/PageContainer';

export interface AdminStandardPageProps {
  title: string;
  subTitle?: string;
  additionalHeaderContent?: React.ReactNode;
  breadcrumbs: ReadonlyArray<{ label: string; href: string | null }>;
  enableHome?: boolean;
  isLoading?: boolean;
  error?: string;
  subtitleSymbol?: React.ReactNode;
  banner?: React.ReactNode;
}

const AdminStandardPage: React.FC<
  React.PropsWithChildren<AdminStandardPageProps>
> = (props: React.PropsWithChildren<AdminStandardPageProps>) => {
  return (
    <PageContainer banner={props.banner}>
      {props.isLoading || props.error ? (
        <>
          {props.isLoading && <LoadingOverlay />}
          {props.error && <ErrorOverlay detail={props.error} />}
        </>
      ) : (
        <>
          <BreadCrumbs
            crumbs={props.breadcrumbs.map((crumb) => ({
              label: crumb.label,
              onClick: () => {
                return crumb.href ? (window.location.href = crumb.href) : {};
              },
            }))}
            enableHome={props.enableHome}
          />

          <div className="flex flex-wrap justify-between pt-2 pb-9">
            <h1 className="text-h3">
              {props.title}
              {props.subTitle && (
                <span className="text-h5 ml-2">
                  {props.subTitle}{' '}
                  {props.subtitleSymbol ? props.subtitleSymbol : <></>}
                </span>
              )}
            </h1>
            {props.additionalHeaderContent && (
              <div>{props.additionalHeaderContent}</div>
            )}
          </div>

          <div>{props.children}</div>
        </>
      )}
    </PageContainer>
  );
};

export default AdminStandardPage;
