import { EnquiryTableData } from './types';
import { SelectedFilters } from '../../../../framework-components/table/types';

export const getHighlightRowClass = (
  row: EnquiryTableData,
  filters: SelectedFilters
) => {
  if (!filters.show_clashes?.value) {
    return '';
  }
  if (row.capacity_conflict_status === 'ERROR') {
    return 'bg-wtw-trafficlight-red';
  }
  if (row.capacity_conflict_status === 'WARN') {
    return 'bg-wtw-trafficlight-amber';
  }
  return '';
};
