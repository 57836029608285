import React, { useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleSterling } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import {
  BookingCount,
  PendingBookingTableData,
  RegistrationFeePaymentMethod,
} from '../types';
import { apiRequest, useAPI } from '../../../../../api/api';
import MarkPaidButton from './MarkPaidButton';
import ConfirmBookingButton from './ConfirmBookingButton';
import Table from '../../../../../framework-components/table/Table';
import { formatDateToDayDateMonthYear } from '../../../../../utils/dates';
import {
  CombinedFilters,
  PartialFilterOptions,
  TableData,
} from '../../../../../framework-components/table/types';
import Tooltip from '../../../../../framework-components/tooltip/Tooltip';

const PendingBookings: React.FC = () => {
  const { data: count } = useAPI<BookingCount>('/api/admin/bookings/count/');

  const getPendingBookingTableData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<PendingBookingTableData>>(
        '/api/admin/bookings/pending-bookings/?' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const getFilterOptions = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<PartialFilterOptions<PendingBookingTableData>>(
      '/api/admin/bookings/pending-bookings-filters/?' +
        new URLSearchParams(selectedFilters).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const tableRef = useRef<{ reloadData: () => void }>(null);

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.reloadData();
    }
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: '/admin/bookings/pending',
        },
        {
          label: 'Pending',
          href: null,
        },
      ]}
      title="Pending"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: `Pending ${count ? `(${count.pending})` : ''}`,
              href: '/admin/bookings/pending',
              current: true,
            },
            {
              label: `Confirmed ${count ? `(${count.confirmed})` : ''}`,
              href: '/admin/bookings/confirmed',
              current: false,
            },
            {
              label: `On Trip ${count ? `(${count.on_trip})` : ''}`,
              href: '/admin/bookings/on-trip',
              current: false,
            },
            {
              label: `Postponed & archived ${
                count ? `(${count.postponed_and_archived})` : ''
              }`,
              href: '/admin/bookings/postponed',
              current: false,
            },
          ]}
          variant="pilled_tab"
        />

        <Table<PendingBookingTableData>
          ref={tableRef}
          columnsConfig={{
            full_name: {
              key: 'full_name',
              header: 'Name',
              enableSort: true,
              renderCell: (request) => (
                <a
                  href={`/admin/bookings/${request.id}/applicant-profile/`}
                  title={request.full_name}
                >
                  {request.full_name}
                </a>
              ),
            },
            destination: {
              key: 'destination',
              header: 'Destination',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (request) => request.destination.name,
            },
            discipline: {
              key: 'discipline',
              header: 'Discipline',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (request) => request.discipline.name,
            },
            start_date: {
              key: 'start_date',
              header: 'Travel Date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) =>
                formatDateToDayDateMonthYear(request.start_date),
            },
            registration_fee_payment_method: {
              key: 'registration_fee_payment_method',
              header: 'Deposit type',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (request) => (
                <>
                  <span title={request.registration_fee_payment_method}>
                    {request.registration_fee_payment_method}
                  </span>
                  <FontAwesomeIcon
                    className={classNames(
                      'pl-3 w-[15px] h-[15px]',
                      {
                        'text-wtw-deposit-paid':
                          request.registration_fee_paid_at ||
                          request.registration_fee_payment_method ===
                            RegistrationFeePaymentMethod.CARD,
                      },
                      {
                        'text-wtw-deposit-unpaid':
                          !request.registration_fee_paid_at &&
                          request.registration_fee_payment_method !==
                            RegistrationFeePaymentMethod.CARD,
                      }
                    )}
                    icon={faCircleSterling}
                  />
                </>
              ),
            },
            registration_fee_paid_at: {
              key: 'registration_fee_paid_at',
              header: 'Payment date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) => {
                if (request.registration_fee_paid_at) {
                  return formatDateToDayDateMonthYear(
                    request.registration_fee_paid_at
                  );
                }

                return (
                  <MarkPaidButton
                    reloadData={reloadData}
                    bookingId={request.id}
                  />
                );
              },
            },
            booking_confirmed_at: {
              key: 'booking_confirmed_at',
              header: 'Confirm Booking',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) => {
                if (request.booking_confirmed_at) {
                  return formatDateToDayDateMonthYear(
                    request.booking_confirmed_at
                  );
                } else if (request.registration_fee_paid_at) {
                  return (
                    <ConfirmBookingButton
                      reloadData={reloadData}
                      bookingId={request.id}
                    />
                  );
                }

                return '';
              },
            },
            capacity_details: {
              key: 'capacity_details',
              header: 'Capacity Details',
              renderCell: (request) => {
                const tooltipId = `my-tooltip-${request.id}`;
                return (
                  <div
                    className="flex justify-center"
                    data-tooltip-id={tooltipId}
                    data-tooltip-content={request.capacity_details}
                  >
                    <FontAwesomeIcon
                      icon={faHouse}
                      className={classNames({
                        'text-red-400':
                          request.capacity_conflict_status === 'ERROR',
                        'text-amber-500':
                          request.capacity_conflict_status === 'WARN',
                      })}
                    />
                    <Tooltip id={tooltipId} />
                  </div>
                );
              },
            },
          }}
          getData={getPendingBookingTableData}
          getFilterOptions={getFilterOptions}
          enablePagination
          enableSearch
          additionalFiltersConfig={{
            is_paid: {
              key: 'is_paid',
              label: 'Paid view',
              filterType: 'toggle',
              size: 'sm',
            },
            year: {
              key: 'year',
              label: 'Year',
              filterType: 'local-dropdown',
            },
            month: {
              key: 'month',
              label: 'Month',
              filterType: 'local-dropdown',
            },
            university: {
              key: 'university',
              label: 'University',
              filterType: 'local-dropdown',
            },
          }}
        />
      </div>
    </AdminStandardPage>
  );
};

export default PendingBookings;
