import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';
import AdminWidgetContainer from '../../../framework-components/card/AdminWidgetContainer';

interface NumLabel {
  num: number;
  label: string;
}

interface DashboardActionData extends NumLabel {
  description: string;
  action_label: string;
  action_url: string;
}

interface DashboardPropData {
  title: string;
  stats: Array<NumLabel>;
  actions: Array<DashboardActionData>;
  view_all_url: string;
}

const DashboardSection: React.FC<DashboardPropData> = ({
  title,
  stats,
  actions,
  view_all_url,
}) => {
  return (
    <AdminWidgetContainer
      className="bg-white max-w-[1120px] overflow-x-scroll sm:overflow-x-clip"
      title={title}
    >
      <div className="flex flex-col gap-4 pt-4">
        <div className="flex flex-row w-full justify-between my-4 space-x-4">
          {stats.map((stat, index) => {
            return (
              <div
                key={index}
                className="w-full bg-wtw-table-blue p-4 rounded-lg"
              >
                <h2 className="text-mob-h3 xs:text-h3">{stat.num}</h2>
                <p className="text-center text-sh3 font-thin sm:text-start">
                  {stat.label}
                </p>
              </div>
            );
          })}
        </div>
        <div className="w-full space-y-6 grow">
          {actions.map((action, index) => {
            return (
              <div
                key={index}
                className="flex gap-6 sm:gap-0 flex-col sm:flex-row w-full justify-between bg-wtw-gray-2 my-2 p-4 rounded-lg"
              >
                <div className="w-full flex-row sm:flex-col place-content-center items-center ">
                  <h2 className="text-mob-h3 xs:text-h3 text-center sm:text-start">
                    {action.num}
                  </h2>
                  <h2 className="text-center font-thin text-sh3 sm:text-start">
                    {action.label}
                  </h2>
                  <p className="sm:text-start">{action.description}</p>
                </div>
                <div className="w-fit-content flex flex-row justify-center items-center">
                  <PrimaryButton
                    paddingClassName="px-5 py-1"
                    onClick={() => {
                      window.location.href = action.action_url;
                    }}
                  >
                    <span className="flex flex-row items-center gap-1">
                      {action.action_label}
                      <FontAwesomeIcon color="white" icon={faArrowRight} />
                    </span>
                  </PrimaryButton>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex w-full place-content-center mt-4">
          <SecondaryButton
            label="View all"
            onClick={() => {
              window.location.href = view_all_url;
            }}
          />
        </div>
      </div>
    </AdminWidgetContainer>
  );
};

export default DashboardSection;
