import React, { useContext } from 'react';
import * as Yup from 'yup';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import AdminWidgetContainer from '../../../framework-components/card/AdminWidgetContainer';
import { MyBookingsContext } from '../context-providers';
import { useAPI } from '../../../api/api';
import { useSubmitFormToAPI } from '../../../api/forms';
import LoadingOverlay from '../components/LoadingOverlay';
import ErrorOverlay from '../components/ErrorOverlay';
import { PrimaryButton } from '../components/Button';
import Objective from './Objective';
import ErrorText from '../../error-text';

interface PlacementObjectivesData {
  placement_objectives: string[];
}

const validationSchema = Yup.object({
  placement_objectives: Yup.array().of(
    Yup.string().required('Objective is required')
  ),
});

const PlacementObjectivesForm: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);

  const { data, isValidating, error } = useAPI<PlacementObjectivesData>(
    activeBooking
      ? `/api/my-trip/placement-objectives/${activeBooking?.id}/get/`
      : null
  );

  const submitForm = useSubmitFormToAPI(
    `/api/my-trip/placement-objectives/${activeBooking?.id}/set/`
  );

  const handleSubmit = async (
    values: { placement_objectives: string[] },
    formHelpers: FormikHelpers<PlacementObjectivesData>
  ) => {
    const [hasSucceeded] = await submitForm(
      { placement_objectives: values.placement_objectives },
      formHelpers
    );

    if (hasSucceeded) {
      toast.success('Placement objectives updated successfully!');
    } else {
      toast.error('Failed to add placement objective. Please try again.');
    }
  };

  return (
    <div>
      <AdminWidgetContainer title="Placement objectives" className="space-y-6">
        {isValidating && <LoadingOverlay />}
        {error && <ErrorOverlay />}
        {data && (
          <Formik<PlacementObjectivesData>
            initialValues={{
              placement_objectives: data?.placement_objectives?.length
                ? data.placement_objectives
                : [''],
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors }) => {
              return (
                <Form>
                  <FieldArray
                    name="placement_objectives"
                    render={(arrayHelpers) => (
                      <div>
                        {values.placement_objectives.map((objective, index) => (
                          <Objective
                            key={index}
                            objective={objective}
                            index={index}
                            setFieldValue={setFieldValue}
                            removeObjective={() => arrayHelpers.remove(index)}
                            totalObjectivesCount={
                              values.placement_objectives.length
                            }
                          />
                        ))}
                        <div
                          onClick={() => arrayHelpers.push('')}
                          className="text-blue-500"
                        >
                          <FontAwesomeIcon
                            className="text-blue-500 mr-2"
                            icon={faPlus}
                          />
                          <span>add objective</span>
                        </div>
                      </div>
                    )}
                  />
                  {errors.placement_objectives && (
                    <ErrorText className="mt-4">
                      {errors.placement_objectives}
                    </ErrorText>
                  )}
                  <div>
                    <PrimaryButton
                      label="Save"
                      type="submit"
                      className="mt-4"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </AdminWidgetContainer>
    </div>
  );
};

export default PlacementObjectivesForm;
