import React from 'react';

const GreenCircleIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.98438 18C14.9549 18 18.9844 13.9706 18.9844 9C18.9844 4.02944 14.9549 0 9.98438 0C5.01381 0 0.984375 4.02944 0.984375 9C0.984375 13.9706 5.01381 18 9.98438 18Z"
        fill="#C6F6CA"
      />
      <path
        d="M6.98438 9L8.98438 11L12.9844 7"
        stroke="#111827"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GreenCircleIcon;
