import React from 'react';

const ReopenIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.48828 9.75L2.08203 13.9062V6.25C2.08203 5.29297 2.84766 4.5 3.83203 4.5H7.03125C7.49609 4.5 7.93359 4.69141 8.26172 5.01953L9 5.75781C9.32812 6.08594 9.76562 6.25 10.2305 6.25H13.457C14.4141 6.25 15.207 7.04297 15.207 8V8.875H6.01953C5.39062 8.875 4.81641 9.20312 4.48828 9.75ZM5.25391 10.1875C5.41797 9.91406 5.69141 9.75 6.01953 9.75H16.957C17.2578 9.75 17.5586 9.94141 17.6953 10.2148C17.8594 10.4883 17.8594 10.8164 17.6953 11.0898L14.6328 16.3398C14.4688 16.5859 14.1953 16.75 13.8945 16.75H2.95703C2.62891 16.75 2.35547 16.5859 2.19141 16.3125C2.02734 16.0391 2.02734 15.7109 2.19141 15.4375L5.25391 10.1875Z"
        fill="#2568EB"
      />
    </svg>
  );
};

export default ReopenIcon;
