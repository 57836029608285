import React from 'react';

import SetPasswordForm from './set-password-form';
import LoginWidgetContainer from '../my-trip/components/LoginWidgetContainer';

const ForgotPassword: React.FC = () => (
  <div className="flex items-center justify-center sm:mt-6">
    <LoginWidgetContainer>
      <div className="space-y-8 w-full md:w-1/2 px-6 py-8">
        <SetPasswordForm />
      </div>
    </LoginWidgetContainer>
  </div>
);

export default ForgotPassword;
