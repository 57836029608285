import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateGeneralInfo } from './actions';
import { GeneralInfo } from './types';
import { formatDateToReadableString, isSunday } from '../../../../utils/dates';
import { EDUCATION_STATUS } from '../../../booking/booking-form/constants';
import { EDUCATION_STATUS_TO_DISPLAY_NAME } from '../../../booking/booking-form/labels';

interface Props {
  enquiryId: string;
}

const GeneralInfo: React.FC<Props> = ({ enquiryId }) => {
  return (
    <ManageSingleObject<GeneralInfo>
      title="General Info:"
      getUrl={`/api/admin/enquiries/${enquiryId}/general-info/`}
      submitItem={(values) => updateGeneralInfo(enquiryId, values)}
      descriptionFields={[
        {
          label: 'Course',
          render: (data) => data.discipline?.label,
        },
        {
          label: 'Start Date',
          render: (data) =>
            data.start_date ? formatDateToReadableString(data.start_date) : '',
        },
        {
          label: 'Duration',
          render: (data) =>
            data.hospital_duration ? `${data.hospital_duration} weeks` : '',
        },
        {
          label: 'Interested in Village Experience',
          render: (data) =>
            data.experience_duration ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            ),
        },
        {
          label: 'Education Status',
          render: (data) => data.education_status?.label,
        },
      ]}
      formFields={[
        {
          type: 'remote-dropdown',
          name: 'discipline',
          label: 'Course',
          path: `/api/admin/enquiries/${enquiryId}/available-disciplines/`,
        },
        {
          type: 'datetime',
          name: 'start_date',
          label: 'Start Date',
          extraProps: {
            dateFormat: 'yyyy-MM-dd',
            filterDate: isSunday,
          },
        },
        {
          type: 'smalltext',
          name: 'hospital_duration',
          label: 'Duration (weeks)',
          extraProps: {
            type: 'number',
          },
        },
        {
          type: 'checkbox',
          name: 'experience_duration',
          option: {
            value: 'experience_duration',
            label: 'Experience',
          },
        },
        {
          type: 'local-dropdown',
          name: 'education_status',
          label: 'Education Status',
          options: [
            {
              value: EDUCATION_STATUS.STUDENT,
              label: EDUCATION_STATUS_TO_DISPLAY_NAME[EDUCATION_STATUS.STUDENT],
            },
            {
              value: EDUCATION_STATUS.PROFESSIONAL,
              label:
                EDUCATION_STATUS_TO_DISPLAY_NAME[EDUCATION_STATUS.PROFESSIONAL],
            },
          ],
        },
      ]}
    />
  );
};

export default GeneralInfo;
