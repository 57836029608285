import React from 'react';
import { useParams } from 'react-router';

import { useAPI } from '../../../../api/api';
import AdminStandardPage from '../../common/AdminStandardPage';
import TwoColumns from '../../common/layout/TwoColumns';
import EnquiryActions from './EnquiryActions';
import GeneralInfo from './GeneralInfo';
import { BasicInfo, Summary as SummaryType } from './types';
import Status from './Status';
import Notes from '../../../../framework-components/notes/Notes';
import { NoteEntityType } from '../../../../framework-components/notes/types';
import Contacted from './Contacted';
import QuickSetEnquiryPriority from '../common/QuickSetEnquiryPriority';
import Summary from './Summary';
import DesiredDestinations from './DesiredDestinations';
import Assignee from '../../../Assignee';
import CopyToClipboardButton from '../../../CopyClipboardButton';

const colorOverrideMap = {
  Sales: 'bg-blue-500',
  Marketing: 'bg-gray-500',
  Admin: 'bg-pink-400',
  System: 'bg-violet-400',
  Cusomer: 'bg-yellow-500',
  'Legacy import': 'bg-red-500',
};

const EnquiryProfile: React.FC = () => {
  const { enquiryId } = useParams<{ enquiryId: string }>();

  const { data, error, isValidating } = useAPI<BasicInfo>(
    `/api/admin/enquiries/${enquiryId}/basic-info/`
  );
  const referenceCopyText = (
    <CopyToClipboardButton textToCopy={data ? data.order_reference : ''} />
  );

  const {
    data: summaryData,
    isValidating: summaryIsValidating,
    error: summaryError,
  } = useAPI<SummaryType>(`/api/admin/enquiries/${enquiryId}/summary/`);

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'All Enquiries',
          href: '/admin/enquiries/new',
        },
        {
          label: 'Enquiry profile',
          href: null,
        },
      ]}
      title={data ? `${data.first_name} ${data.last_name}` : ''}
      subTitle={data?.order_reference ? `(${data?.order_reference})` : ''}
      subtitleSymbol={referenceCopyText}
      enableHome={false}
      isLoading={isValidating || summaryIsValidating}
      error={error || summaryError ? 'Failed to load page data' : undefined}
    >
      <div className="space-y-8">
        <div className="space-y-2">
          {data && enquiryId && (
            <div className="flex items-center justify-between">
              <Status data={data} />
              <Assignee
                initialValue={data.assignee ? data.assignee.id : null}
                dropdownOptionsEndpoint="/api/admin/enquiries/assignee-options/"
                setAssigneeEndpoint={`/api/admin/enquiries/${enquiryId}/set-assignee/`}
              />
            </div>
          )}
          {data && enquiryId && (
            <div className="flex items-center gap-2">
              <p>Priority:</p>
              <QuickSetEnquiryPriority
                enquiryId={enquiryId}
                initialValue={data.priority}
              />
            </div>
          )}
          {summaryData && enquiryId && (
            <Summary data={summaryData} enquiryId={enquiryId} />
          )}
          {data && enquiryId && (
            <div className="w-full flex justify-end">
              <Contacted
                enquiryId={enquiryId}
                contactedAt={data.contacted_at}
              />
            </div>
          )}
        </div>
        <TwoColumns
          leftColumn={
            <>
              {enquiryId && <DesiredDestinations enquiryId={enquiryId} />}{' '}
              {enquiryId && <GeneralInfo enquiryId={enquiryId} />}
            </>
          }
          rightColumn={
            <>
              <div className="space-y-7">
                {enquiryId && (
                  <Notes
                    initialEntityId={enquiryId}
                    initialEntityType={NoteEntityType.ENQUIRY}
                    colorOverrideMap={colorOverrideMap}
                  />
                )}
                {enquiryId && data && (
                  <EnquiryActions
                    enquiryId={enquiryId}
                    isArchived={!data.is_active}
                  />
                )}
              </div>
            </>
          }
        />
      </div>
    </AdminStandardPage>
  );
};
export default EnquiryProfile;
