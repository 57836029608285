import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { PrimaryButton, SecondaryButton } from './my-trip/components/Button';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose(): void;
  initialFocus?: React.MutableRefObject<null>;
  justifyCenter?: boolean;
  onConfirm?: () => Promise<void> | void;
  confirmLabel?: string;
  variant?: 'default' | 'small';
}

const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  onConfirm,
  confirmLabel = 'Confirm',
  variant = 'default',
}) => {
  const modalRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    if (isOpen && modalRef.current) {
      (modalRef.current as HTMLDivElement).focus();
    }
  }, [isOpen]);

  const handleConfirm = async () => {
    if (!onConfirm) return;

    setIsLoading(true);
    try {
      await Promise.resolve(onConfirm());
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={'relative z-50'}
        onClose={handleClose}
        ref={modalRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full transform transition-all flex flex-col items-center justify-center">
                <Dialog.Panel
                  className={classNames(
                    'relative flex w-full flex-col space-y-5 rounded-xl bg-white p-5 pb-11 shadow-xl',
                    {
                      'w-[90%] sm:w-[800px]': variant === 'default',
                      'w-[90%] sm:w-[400px]': variant === 'small',
                    }
                  )}
                >
                  <div className="flex flex-row justify-end">
                    <button
                      type="button"
                      className="table p-1 rounded-md hover:bg-gray-100 focus:outline-none disabled:cursor-not-allowed"
                      onClick={handleClose}
                      disabled={isLoading}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.86865 6.86853C7.49349 6.24369 8.50656 6.24369 9.1314 6.86853L16 13.7372L22.8687 6.86853C23.4935 6.24369 24.5066 6.24369 25.1314 6.86853C25.7562 7.49337 25.7562 8.50643 25.1314 9.13127L18.2628 15.9999L25.1314 22.8685C25.7562 23.4934 25.7562 24.5064 25.1314 25.1313C24.5066 25.7561 23.4935 25.7561 22.8687 25.1313L16 18.2626L9.1314 25.1313C8.50656 25.7561 7.49349 25.7561 6.86865 25.1313C6.24381 24.5064 6.24381 23.4934 6.86865 22.8685L13.7373 15.9999L6.86865 9.13127C6.24381 8.50643 6.24381 7.49337 6.86865 6.86853Z"
                          fill="#1F1F1F"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="flex flex-col space-y-10">
                    {children}

                    {typeof onConfirm !== 'undefined' && (
                      <div className="flex justify-center space-x-5">
                        <>
                          <SecondaryButton
                            label="Cancel"
                            onClick={handleClose}
                            isDisabled={isLoading}
                          />
                          <PrimaryButton
                            label={isLoading ? 'One moment...' : confirmLabel}
                            onClick={handleConfirm}
                            isDisabled={isLoading}
                          />
                        </>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
