import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../../../modal';

interface DietaryReqsModalProps {
  isOpen: boolean;
  onClose: () => void;
  dietary_requirements_extra_info: string;
}

const DietaryReqsModal: React.FC<DietaryReqsModalProps> = ({
  isOpen,
  onClose,
  dietary_requirements_extra_info,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Dialog.Title className="text-2xl font-bold">
        Dietary Requirements
      </Dialog.Title>
      <div className="mt-4 mb-4">{dietary_requirements_extra_info}</div>
    </Modal>
  );
};

export default DietaryReqsModal;
