import React from 'react';
import { mutate } from 'swr';

import ManageSingleObject from '../../manage-single-object/ManageSingleObject';
import { updateKeyDetails } from './actions';
import { jobTitleOptions } from './constants';
import { KeyDetailsData } from './types';
import Anchor from '../common/Anchor';
import { formatDateTimeShorthandMonthDayYear } from '../../../utils/dates';

interface Props {
  canEdit: boolean | null;
  userId: string;
}
const KeyDetails: React.FC<Props> = ({ canEdit, userId }) => {
  return (
    <ManageSingleObject<KeyDetailsData>
      title="Key details"
      getUrl={`/api/admin/staff/${userId}/user-key-details-info/get/`}
      descriptionFields={[
        {
          label: 'Job title',
          render: (item) => {
            if (item.job_title && typeof item.job_title !== 'string') {
              return item.job_title.label;
            }
            return item.job_title;
          },
        },
        {
          label: 'Work email',
          render: (item) => (
            <Anchor href={`mailto:${item.email}`}>{item.email}</Anchor>
          ),
        },
        {
          label: 'Work phone',
          render: (item) => {
            return item.work_telephone;
          },
        },
        {
          label: 'Destination',
          render: (item) => {
            if (!item.destination) {
              return '';
            }

            return (
              <Anchor
                href={`/admin/directories/destinations?destination=${item.destination.value}`}
              >
                {item.destination.label}
              </Anchor>
            );
          },
        },
        {
          label: 'Last login',
          render: (item) => {
            const dateTimeOfLastLogin = item.last_login;
            if (!dateTimeOfLastLogin) {
              return '-';
            }
            return formatDateTimeShorthandMonthDayYear(dateTimeOfLastLogin);
          },
        },
      ]}
      formFields={[
        {
          type: 'local-dropdown',
          options: jobTitleOptions,
          name: 'job_option',
          label: 'Job title',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'remote-dropdown',
          name: 'destination',
          label: 'Destination (Optional)',
          path: '/api/booking/destinations/',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'email',
          label: 'Work email',
          extraProps: {
            row: true,
          },
        },
        {
          type: 'smalltext',
          name: 'work_telephone',
          label: 'Work phone (Optional)',
          extraProps: {
            row: true,
          },
        },
      ]}
      submitItem={(data) => {
        return updateKeyDetails(userId, data);
      }}
      modalTitle="Edit key details"
      editButton={canEdit ? canEdit : false}
      onSubmitSuccess={() =>
        mutate(`/api/admin/staff/${userId}/user-key-details-info/get/`)
      }
    />
  );
};

export default KeyDetails;
