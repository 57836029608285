import React from 'react';

import EditableDescriptionList from './EditableDescriptionList';
import { ReviewStudyDetailsData } from './types';

interface Props {
  data: ReviewStudyDetailsData;
}

const ReviewStudyDetails: React.FC<Props> = ({ data }) => {
  return (
    <div className="space-y-8">
      <EditableDescriptionList
        enquiryId={data.id}
        title="Your studies"
        anchor="study"
        items={[
          {
            label: 'Your university',
            value: data.university.label,
          },
          {
            label: 'Your degree title',
            value: data.university_course,
          },
          {
            label: 'Your year of graduation',
            value: data.year_of_university_study,
          },
        ]}
      />
    </div>
  );
};

export default ReviewStudyDetails;
