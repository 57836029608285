import React, { useCallback, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router';
import _ from 'underscore';
import { Link } from 'react-router-dom';

import Navbar from '../../../framework-components/navbar/Navbar';
import { useSubmitDataToAPI } from '../../../api/submit';
import Logo from '../../Logo';
import RequirePermission from './RequirePermission';
import { LoggedInUserContext } from '../../../context-providers/logged-in-user';
import { apiRequest } from '../../../api/api';

interface SearchEverywhereProps {
  id: string;
  display_name: string;
  type: string;
}

const searchEverywhere = (
  query: string
): Promise<ReadonlyArray<SearchEverywhereProps>> => {
  return apiRequest<SearchEverywhereProps[]>(
    `/api/admin/bookings/search-everywhere/?search=${query}`
  );
};

const AdminPageContainer: React.FC = () => {
  const user = useContext(LoggedInUserContext);
  const staffId = user?.id;
  const submitDataToAPI = useSubmitDataToAPI('/api/auth/logout/');
  const userIsImpersonate = user && user.is_impersonate;

  const navigate = useNavigate();

  const onClickLogout = useCallback(async () => {
    const { succeeded } = await submitDataToAPI(undefined, _.noop);
    if (succeeded) {
      window.location.reload();
    }
  }, [submitDataToAPI]);

  const stopImpersonate = () => {
    return (window.location.href = `/impersonate/stop/`);
  };

  const handleSelectItem = useCallback(
    (selectedItem: SearchEverywhereProps) => {
      if (selectedItem.type === 'booking') {
        navigate(`/admin/bookings/${selectedItem.id}/applicant-profile/`);
      } else {
        navigate(`/admin/enquiries/${selectedItem.id}/enquiry-profile/`);
      }
    },
    [navigate]
  );

  const renderAction = useCallback(
    (item: SearchEverywhereProps, index: number) => {
      return (
        <div key={index}>
          {`${item.display_name} (${item.type})` ||
            'User display name not available'}
        </div>
      );
    },
    []
  );

  const search = {
    onSearch: searchEverywhere,
    onSelectItem: handleSelectItem,
    renderAction: renderAction,
  };

  return (
    <RequirePermission permissionName="General.ACCESS_ADMIN">
      <Navbar
        appLogo={
          <Link to="/admin/dashboard">
            <Logo width={200} height={48} />
          </Link>
        }
        navigation={[
          {
            name: 'Enquiries',
            href: '/admin/enquiries/new',
            children: [
              {
                name: 'New enquiries',
                href: '/admin/enquiries/new',
              },
              {
                name: 'Ongoing enquiries',
                href: '/admin/enquiries/ongoing',
              },
              {
                name: 'Archived enquiries',
                href: '/admin/enquiries/archived',
              },
            ],
          },
          {
            name: 'Bookings',
            href: '/admin/bookings/pending',
            children: [
              {
                name: 'Pending bookings',
                href: '/admin/bookings/pending',
              },
              {
                name: 'Confirmed bookings',
                href: '/admin/bookings/confirmed',
              },
              {
                name: 'On Trip bookings',
                href: '/admin/bookings/on-trip',
              },
              {
                name: 'Postponed & Archived bookings',
                href: '/admin/bookings/postponed',
              },
              {
                name: 'Arrivals',
                href: '/admin/bookings/arrivals',
              },
              {
                name: 'Departures',
                href: '/admin/bookings/departures',
              },
            ],
          },
          {
            name: 'Capacities',
            href: '#',
            children: [
              {
                name: 'Departments capacities',
                href: '/admin/capacities/department-capacities',
              },
              {
                name: 'Room capacities',
                href: '/admin/capacities/room-capacities',
              },
              {
                name: 'House & Village capacities',
                href: '/admin/capacities/house-village-capacities',
              },
            ],
          },
          {
            name: 'Alumni',
            href: '#',
            children: [
              {
                name: 'All alumni',
                href: '/admin/alumni/all',
              },
              {
                name: 'Survey results',
                href: '/admin/alumni/survey-results',
              },
              {
                name: 'Aggregate survey results',
                href: '/admin/alumni/aggregate-survey-results',
              },
            ],
          },
          {
            name: 'Reporting',
            href: '#',
            children: [
              {
                name: 'Email Log',
                href: '/admin/reporting/email-log',
              },
              {
                name: 'Booking Report',
                href: '/admin/reporting/booking-report',
              },
            ],
          },
          {
            name: 'Directories',
            href: '#',
            children: [
              {
                name: 'Documents',
                href: '/admin/directories/documents',
              },
              {
                name: 'Universities',
                href: '/admin/directories/universities',
              },
              {
                name: 'Destinations',
                href: '/admin/directories/destinations',
              },
              {
                name: 'Hospitals',
                href: '/admin/directories/hospitals',
              },
              {
                name: 'Information pack',
                href: '/admin/directories/info-pack',
              },
              {
                name: 'Templated resources',
                href: '/admin/directories/templated-resources',
              },
              {
                name: 'Staff',
                href: '/admin/staff/staff-listing',
              },
            ],
          },
          {
            name: 'Finance',
            href: '#',
          },
          {
            name: 'Admin',
            href: '#',
            children: [
              {
                name: 'Profile settings',
                href: `/admin/staff/${staffId}/profile`,
              },
              {
                name: 'Staff',
                href: '/admin/staff/staff-listing',
              },
            ],
          },
        ]}
        profileActions={[
          {
            label: 'Change Password',
            href: '/admin/change-password',
          },
          {
            label: userIsImpersonate ? 'Stop Impersonating' : 'Logout',
            onClick: userIsImpersonate ? stopImpersonate : onClickLogout,
          },
        ]}
        search={search}
      />
      <Outlet />
    </RequirePermission>
  );
};

export default AdminPageContainer;
