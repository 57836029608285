import React, { useCallback } from 'react';
import classNames from 'classnames';

import { Option } from '../../types';

interface Props {
  name: string;
  label: string | React.ReactNode;
  options: ReadonlyArray<Option>;
  onChange: (value: string | number) => void;
  row?: boolean;
  itemRow?: boolean;
  defaultValue?: string | number;
  fontWeight?: 'normal' | 'medium' | 'bold';
}

const Radio: React.FC<Props> = ({
  name,
  label,
  options,
  onChange,
  defaultValue,
  row = false,
  itemRow = false,
  fontWeight = 'medium',
}) => {
  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );
  return (
    <fieldset>
      <div
        className={classNames('flex gap-2', {
          'flex-col': !row,
          'flex-col sm:flex-row sm:items-center': row,
        })}
      >
        <label
          htmlFor={name}
          className={classNames(
            'block text-sm font-medium leading-6 text-gray-900',
            { 'w-1/2': row }
          )}
        >
          {label}
        </label>
        <div
          className={classNames('flex flex-wrap', {
            'gap-4': itemRow,
            'flex-col gap-2': !itemRow,
          })}
        >
          {options.map((option) => (
            <div key={option.value} className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={option.value}
                  aria-describedby={`${option.value}-description`}
                  name={name}
                  type="radio"
                  defaultChecked={option.value === defaultValue}
                  className="h-4 w-4 border-gray-300 text-wtw-logo-blue focus:ring-wtw-logo-blue"
                  onChange={changeHandler}
                  value={option.value}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor={option.value}
                  className={classNames('text-gray-900', {
                    'font-normal': fontWeight === 'normal',
                    'font-medium': fontWeight === 'medium',
                    'font-bold': fontWeight === 'bold',
                  })}
                >
                  {option.label}
                </label>
                <p id={`${option.value}-description`} className="text-gray-500">
                  {option.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </fieldset>
  );
};

export default Radio;
