import React from 'react';
import {
  faChartLine,
  faCheck,
  faChevronsLeft,
  faClipboard,
  faDollarSign,
  faFile,
  faGraduationCap,
  faPassport,
  faPhone,
  faPlane,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import VerticalNavBar, { NavItem } from '../common/VerticalNavBar';

interface Props {
  NavItemList: Array<NavItem>;
}

const DashboardNav: React.FC<Props> = ({ NavItemList }) => {
  const expandedNavItemClassName =
    'flex h-14 justify-self-center self-center cursor-pointer border border-gray-300 rounded-lg w-full justify-self-center justify-center hover:bg-wtw-dark-blue hover:text-white';
  const expandedNavItemTextClassName =
    'text-md-bold font-medium text-center pretty';
  const NavIconClassName =
    'm-2 h-8 w-8 justify-self-center self-center cursor-pointer text-wtw-secondary-text';

  function renderNavItems(ArrayToRender: Array<NavItem>) {
    const iconArray = ArrayToRender.map((ArrayItem, index) => {
      switch (ArrayItem.icon) {
        case 'plane':
          return (
            <FontAwesomeIcon
              key={index}
              icon={faPlane}
              className={NavIconClassName}
              onClick={() => {
                window.location.href = ArrayItem.url;
              }}
            />
          );
        case 'clipboard':
          return (
            <FontAwesomeIcon
              key={index}
              icon={faClipboard}
              className={NavIconClassName}
              onClick={() => {
                window.location.href = ArrayItem.url;
              }}
            />
          );
        case 'graph':
          return (
            <FontAwesomeIcon
              key={index}
              icon={faChartLine}
              className={NavIconClassName}
              onClick={() => {
                window.location.href = ArrayItem.url;
              }}
            />
          );
        case 'passport':
          return (
            <FontAwesomeIcon
              key={index}
              icon={faPassport}
              className={NavIconClassName}
              onClick={() => {
                window.location.href = ArrayItem.url;
              }}
            />
          );
        case 'telephone':
          return (
            <FontAwesomeIcon
              key={index}
              icon={faPhone}
              className={NavIconClassName}
              onClick={() => {
                window.location.href = ArrayItem.url;
              }}
            />
          );
        case 'graduation_cap':
          return (
            <FontAwesomeIcon
              key={index}
              icon={faGraduationCap}
              className={NavIconClassName}
              onClick={() => {
                window.location.href = ArrayItem.url;
              }}
            />
          );
        case 'file':
          return (
            <FontAwesomeIcon
              key={index}
              icon={faFile}
              className={NavIconClassName}
              onClick={() => {
                window.location.href = ArrayItem.url;
              }}
            />
          );
        case 'dollar':
          return (
            <FontAwesomeIcon
              key={index}
              icon={faDollarSign}
              className={NavIconClassName}
              onClick={() => {
                window.location.href = ArrayItem.url;
              }}
            />
          );
        default:
          return (
            <FontAwesomeIcon
              key={index}
              icon={faCheck}
              className={NavIconClassName}
            />
          );
      }
    });
    return iconArray;
  }

  return (
    <VerticalNavBar
      NavItemList={NavItemList}
      collapsible
      contentWhenCollapsed={renderNavItems(NavItemList)}
      contentWhenExpanded={(collapseFn) => {
        const NavItemIcon = NavItemList.map((item, index) => {
          return (
            <div
              key={index}
              className={expandedNavItemClassName}
              onClick={() => (window.location.href = item.url)}
            >
              {renderNavItems([item])}
              <div className="flex grow flex-col justify-center">
                <p className={expandedNavItemTextClassName}>{item.label}</p>
              </div>
            </div>
          );
        });
        return (
          <>
            <div
              onClick={collapseFn}
              className="flex h-14 justify-self-center self-center cursor-pointer w-full justify-self-center justify-center"
            >
              <FontAwesomeIcon
                className={NavIconClassName}
                icon={faChevronsLeft}
              />
              <div className="flex grow flex-col justify-center">
                <p className={expandedNavItemTextClassName}>Collapse</p>
              </div>
            </div>
            {NavItemIcon}
          </>
        );
      }}
    />
  );
};
export default DashboardNav;
