import React from 'react';

import WidgetContainer from '../components/WidgetContainer';

const PhotosAndVideos: React.FC = () => {
  return (
    <WidgetContainer className="space-y-6 pb-20" title="Photos & Videos">
      <div className="text-gray-500 space-y-6">
        <p className="text-gray-500">
          With the age of the smartphone upon us, we've no doubt that you took
          hundreds of photos and videos during your trip. If you're anything
          like us, you'll want to show off your best snaps and we'd love to
          help.
        </p>
        <p className="text-gray-500">
          You can send your photos directly to{' '}
          <a
            className="text-wtw-logo-blue hover:text-wtw-dark-blue"
            href="mailto:media@worktheworld.com"
          >
            media@worktheworld.com
          </a>
          , and if you've got more photos than will fit in one email (this is
          true for most people), get in touch and we'll show you how to send
          them in bulk.
        </p>
      </div>
    </WidgetContainer>
  );
};

export default PhotosAndVideos;
