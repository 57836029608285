import React, { useEffect } from 'react';

import PageWrapper from '../booking-form/PageWrapper';
import { SECTIONS } from '../booking-form/constants';
import OrderSummary from './OrderSummary';
import BookingFormContainer from '../../admin/common/BookingFormContainer';

const OrderSummaryPage: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem('enquiryId');
  }, []);
  return (
    <BookingFormContainer>
      <PageWrapper title="Order summary" section={SECTIONS.SUMMARY}>
        <OrderSummary />
      </PageWrapper>
    </BookingFormContainer>
  );
};

export default OrderSummaryPage;
