import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import Modal from '../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';
import { archiveHospital } from './actions';
import { HospitalBasicInfo } from './types';

interface Props {
  hospitalId: string;
  isArchived: boolean;
  isOpen: boolean;
  closeModal: () => void;
}

const ArchiveBookingModal: React.FC<Props> = ({
  hospitalId,
  isArchived,
  isOpen,
  closeModal,
}) => {
  const focusRef = React.useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleArchive = async (active: boolean) => {
    setIsLoading(true);
    try {
      await archiveHospital(hospitalId, { active });
      toast.success(
        active
          ? 'Hospital unarchived successfully'
          : 'Hospital archived successfully'
      );
      mutate(
        `/api/admin/hospital/${hospitalId}/basic-info/`,
        (data: HospitalBasicInfo) => ({
          ...data,
          active,
        }),
        false
      );
      closeModal();
    } catch (error) {
      toast.error(`Failed to ${active ? 'unarchive' : 'archive'} hospital`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4" data-testid="delete-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          {isArchived ? 'Unarchive hospital' : 'Archive hospital'}
        </Dialog.Title>
        {isArchived ? (
          <p>Are you sure you want to reactivate the hospital?</p>
        ) : (
          <p>Are you sure you want to archive the hospital?</p>
        )}
        <div className="flex justify-end space-x-4">
          <SecondaryButton onClick={closeModal} isDisabled={isLoading}>
            Cancel
          </SecondaryButton>

          <PrimaryButton
            isDisabled={isLoading}
            onClick={() => handleArchive(isArchived)}
          >
            {isArchived ? 'Unarchive hospital' : 'Archive hospital'}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ArchiveBookingModal;
