import React from 'react';
import classNames from 'classnames';

interface Props {
  leftColumn: React.ReactElement;
  rightColumn: React.ReactElement;
  className?: string;
  remainColumnsOnMobile?: boolean;
}

const TwoColumns: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={classNames(
        'flex gap-6 lg:gap-9',
        { 'flex-row': props.remainColumnsOnMobile },
        {
          'flex-col lg:flex-row': !props.remainColumnsOnMobile,
        },
        props.className
      )}
    >
      <div
        className={classNames('space-y-6', {
          'w-1/2': props.remainColumnsOnMobile,
          'w-full lg:w-1/2': !props.remainColumnsOnMobile,
        })}
      >
        {props.leftColumn}
      </div>

      <div
        className={classNames('space-y-6', {
          'w-1/2': props.remainColumnsOnMobile,
          'w-full lg:w-1/2': !props.remainColumnsOnMobile,
        })}
      >
        {props.rightColumn}
      </div>
    </div>
  );
};

export default TwoColumns;
