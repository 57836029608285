import { apiRequest } from '../../../../api/api';
import { UniversityBasicInfo, UniversityStaffData } from './types';

export const updateBasicInfo = async (basicInfo: UniversityBasicInfo) => {
  const url = `/api/admin/university/${basicInfo.id}/basic-profile-page-info/update/`;
  const response = await apiRequest<UniversityBasicInfo>(url, {
    method: 'POST',
    body: JSON.stringify({ ...basicInfo, country: basicInfo.country?.value }),
  });
  return response;
};

export const updateUniversityStaff = (
  universityId: string,
  data: UniversityStaffData
) => {
  const url = `/api/admin/university/${universityId}/university-profile/staff/${data.id}/update/`;
  return apiRequest<UniversityStaffData>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...data,
      university_department: data.university_department?.value,
    }),
  });
};

export const addUniversityStaff = (
  id: string,
  staffMember: UniversityStaffData
) => {
  const url = `/api/admin/university/${id}/university-profile/staff/create/`;
  return apiRequest<UniversityStaffData>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...staffMember,
      university_department: staffMember.university_department?.value,
    }),
  });
};

export const removeUniversityStaff = (
  universityId: string,
  data: UniversityStaffData
) => {
  const url = `/api/admin/university/${universityId}/university-profile/staff/${data.id}/delete/`;
  return apiRequest<UniversityStaffData>(url, {
    method: 'POST',
  });
};
