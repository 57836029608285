import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';
import Cookies from 'js-cookie';
import { useCallback } from 'react';

import { Paginated } from './types';
import { URLParams } from '../types';
import { formatUrlWithParams } from '../utils/urls';

export class APIError extends Error {
  public status: number;
  public text: string;
  public body: ReturnType<typeof JSON.parse> | null;

  constructor(status: number, statusText: string, content: string) {
    super(status + ' ' + statusText);
    this.status = status;
    this.text = content;

    try {
      this.body = JSON.parse(content);
    } catch (e) {
      this.body = null;
    }
  }
}

export async function apiRequest<Data>(
  path: string,
  init: RequestInit = { method: 'get' },
  onError?: (error: APIError) => void
): Promise<Data> {
  const csrfToken = Cookies.get('csrftoken');
  const headers = {
    'Content-Type': 'application/json',
    ...(init.headers || {}),
    ...(csrfToken ? { 'X-CSRFTOKEN': csrfToken } : {}),
  };
  const response = await fetch(path, {
    credentials: 'include',
    ...init,
    headers: { ...headers },
  });
  const body = await response.text();

  if (!response.ok) {
    if (onError) {
      onError(new APIError(response.status, response.statusText, body));
    }
    throw new APIError(response.status, response.statusText, body);
  }

  return body && JSON.parse(body);
}

export const useAPI = <Data>(
  path: string | null,
  params: URLParams = {},
  config?: SWRConfiguration<Data, APIError | Error>
): SWRResponse<Data, APIError | Error> => {
  const fullPath = path && formatUrlWithParams(path, params);
  const defaultConfig = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };
  return useSWR<Data, APIError | Error>(fullPath, apiRequest, {
    ...defaultConfig,
    ...config,
  });
};

export const useAPIInfinite = <Data>(
  path: string | null,
  params: URLParams = {},
  initialPagesToCrawl = 1
): { requestNextPage: () => Promise<void> } & SWRInfiniteResponse<
  Paginated<Data>,
  APIError | Error
> => {
  const query = useSWRInfinite<Paginated<Data>, APIError | Error>(
    (index: number, previousData: Paginated<Data> | null) => {
      params.page = String(index + 1);
      if (!path || (previousData && !previousData.next)) {
        return null;
      }
      return formatUrlWithParams(path, params);
    },
    apiRequest,
    { initialSize: initialPagesToCrawl }
  );
  const requestNextPage = useCallback(async () => {
    await query.setSize((size) => size + 1);
  }, [query]);

  return { ...query, requestNextPage };
};
