import React, { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';

import { SelectedDates } from './types';
import {
  getSelectedDates,
  calculateStartDate,
  calculateEndDate,
} from './utils';
import { Event, Sections } from '../../calendar/types';
import { Option } from '../../../types';
import { getEvents, getSections, saveEvents } from './actions';

const currentDay = new Date().getDate();
const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

export const useCapacityCalendar = <EventMetadata, SectionMetadata>(
  destination: Option | null,
  dayQueryParam: string | null,
  monthQueryParam: string | null,
  yearQueryParam: string | null,
  period: string,
  getEventsUrl: string,
  getSectionsUrl: string,
  getUnallocatedSection: boolean,
  saveEventsUrl?: string,
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [selectedDates, setSelectedDates] = useState<SelectedDates>(
    getSelectedDates(
      currentDay,
      currentMonth,
      currentYear,
      dayQueryParam,
      monthQueryParam,
      yearQueryParam,
      period
    )
  );

  useEffect(() => {
    setSelectedDates(
      getSelectedDates(
        currentDay,
        currentMonth,
        currentYear,
        dayQueryParam,
        monthQueryParam,
        yearQueryParam,
        period
      )
    );
  }, [dayQueryParam, monthQueryParam, yearQueryParam, period]);

  const [initialEvents, setInitialEvents] = useState<ReadonlyArray<
    Event<EventMetadata>
  > | null>(null);
  const [events, setEvents] = useState<ReadonlyArray<
    Event<EventMetadata>
  > | null>(null);

  const [sections, setSections] = useState<Sections<SectionMetadata> | null>(
    null
  );
  const [errors, setErrors] = useState<Record<string, string | undefined>>({
    events: undefined,
    sections: undefined,
  });
  const [selectedEvent, setSelectedEvent] =
    useState<Event<EventMetadata> | null>(null);

  const startDate = useMemo(
    () => calculateStartDate(selectedDates),
    [selectedDates]
  );

  const endDate = useMemo(
    () => calculateEndDate(startDate, selectedDates.period),
    [startDate, selectedDates.period]
  );

  const handleCancelEditing = () => {
    if (!setIsEditing) return;
    const eventsToCopy = initialEvents || [];
    const copiedEvents = [...cloneDeep(eventsToCopy)];
    setEvents(copiedEvents);
    setIsEditing(false);
    setSelectedEvent(null);
  };

  const handleSaveEvents = async () => {
    if (!events || !saveEventsUrl || !setIsEditing) return;
    try {
      await saveEvents(saveEventsUrl, events);
      const copiedEvents = [...cloneDeep(events)];
      setInitialEvents(copiedEvents);
      setIsEditing(false);
      setSelectedEvent(null);
      toast.success('Save successful');
    } catch {
      toast.error('There was a problem saving');
    }
  };

  useEffect(() => {
    if (!startDate || !endDate || !destination) return;
    const handleGetEvents = async () => {
      try {
        const bookingEvents = await getEvents<EventMetadata>(
          getEventsUrl,
          startDate,
          endDate
        );
        const copiedEvents = [...cloneDeep(bookingEvents)];
        setEvents(bookingEvents);
        setInitialEvents(copiedEvents);
      } catch (e) {
        setErrors((errs) => ({ ...errs, events: 'Failed to fetch calendar' }));
      }
    };

    handleGetEvents();
  }, [
    destination,
    getEventsUrl,
    setEvents,
    setErrors,
    setInitialEvents,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (!events || !destination) return;
    const handleGetSections = async () => {
      try {
        const deptSections = await getSections<EventMetadata, SectionMetadata>(
          getSectionsUrl,
          events,
          getUnallocatedSection
        );
        setSections(deptSections);
      } catch {
        setErrors((errs) => ({
          ...errs,
          sections: 'Failed to fetch calendar',
        }));
      }
    };

    handleGetSections();
  }, [
    getUnallocatedSection,
    getSectionsUrl,
    destination,
    events,
    setErrors,
    setSections,
  ]);

  return {
    selectedDates,
    setSelectedDates,
    events,
    setEvents,
    sections,
    errors,
    startDate,
    endDate,
    handleCancelEditing,
    handleSaveEvents,
    selectedEvent,
    setSelectedEvent,
  };
};
