import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';

import AdminStandardPage from '../../common/AdminStandardPage';
import ApplicantContactBox from './ApplicantContactBox';
import { BasicBookingApplicantProfileInfo } from './types';
import { useAPI } from '../../../../api/api';
import TwoColumns from '../../common/layout/TwoColumns';
import TabbedWidget from '../../../../framework-components/tabbedContainer/TabbedWidget';
import AccomodationPlacementDetailsSection from './AccomodationPlacementDetailsSection';
import HospitalPlacementDetailsSection from './HospitalPlacementDetailsSection';
import AddonDetailsSection from './AddonDetailsSection';
import TravelDetailsSection from './TravelDetailsSection';
import ImpersonateLoginSection from './ImpersonateLoginSection';
import { formatDateToDayDateMonthYear } from '../../../../utils/dates';
import ApplicantDownloadDocumentsSection from './ApplicantDownloadDocumentsSection';
import ApplicantProgressTracker from './ApplicantProgressTracker';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import { NoteEntityType } from '../../../../framework-components/notes/types';
import Notes from '../../../../framework-components/notes/Notes';
import MonitoringForms from './MonitoringForms';
import SendReminder from './SendReminder';
import LedgerTable from './LedgerTable';
import InvoiceTable from './InvoiceTable';
import CancellationSection from './CancellationSection';
import TripDetailsSection from './TripDetailsSection';
import ContactDetails from './ContactDetails';
import EmergencyContactDetails from './EmergencyContactDetails';
import ApplicantStatus from './ApplicantStatus';
import TravelInsuranceDetails from './TravelInsuranceDetails';
import IndemnityInsuranceDetails from './IndemnityInsuranceDetails';
import ApplicantPhotos from './ApplicantPhotos';
import ClinicalExperience from './ClinicalExperience';
import ClinicalInterests from './ClinicalInterests';
import BookingActions from './BookingActions';
import DietaryRequirements from './DietaryRequirements';
import TShirtSize from './TShirtSize';
import PlacementObjectivesForm from './PlacementObjectives';
import EmploymentLetter from './EmploymentLetter';
import Cv from './Cv';
import Assignee from '../../../Assignee';
import { LoggedInUserContext } from '../../../../context-providers/logged-in-user';
import CopyToClipboardButton from '../../../CopyClipboardButton';
import MigrationIssues from './MigrationIssues';
import BookingPrices from './BookingPrices';
import ImportedBookingMarker from './ImportedBookingMarker';

const colorOverrideMap = {
  Sales: 'bg-blue-500',
  Marketing: 'bg-cyan-500',
  'On placement': 'bg-cyan-300',
  Admin: 'bg-green-400',
  Visa: 'bg-indigo-500',
  System: 'bg-violet-400',
  Monitoring: 'bg-yellow-500',
  Incidents: 'bg-red-500',
  Flights: 'bg-pink-500',
  Clinical: 'bg-gray-500',
};

const ApplicantProfilePage = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [hasTravelConflict, setHasTravelConflict] = useState(false);
  const [hasAccomodationConflict, setHasAccomodationConflict] = useState(false);

  const loggedInUser = useContext(LoggedInUserContext);

  const { data: infoTabWarning } = useAPI<{ warning: boolean }>(
    `/api/admin/bookings/${bookingId}/get-info-tab-warning/`
  );

  const { data: educationTabWarning } = useAPI<{ warning: boolean }>(
    `/api/admin/bookings/${bookingId}/get-education-tab-warning/`
  );

  const { data: financeTabWarning } = useAPI<{ warning: boolean }>(
    `/api/admin/bookings/${bookingId}/get-finance-tab-warning/`
  );

  const { data, error, isValidating } =
    useAPI<BasicBookingApplicantProfileInfo>(
      `/api/admin/bookings/${bookingId}/basic-profile-page-info/`
    );

  const applicantNameAndReferenceOrBlank = (function () {
    if (!data) {
      return '';
    }

    return `${data.applicant.first_name} ${data.applicant.last_name}`;
  })();

  const renderPlacementDetailsText = () => {
    if (!data) {
      return '';
    }

    return `${data.basis_display} in ${
      data.discipline.name
    } (From ${formatDateToDayDateMonthYear(
      data.start_date
    )} to ${formatDateToDayDateMonthYear(data.end_date)})`;
  };

  const placementDetailsCopyText = (
    <CopyToClipboardButton textToCopy={renderPlacementDetailsText()} />
  );

  const isAlumni = data?.status === 'ALUMNI';
  const isCancelled = Boolean(data?.is_cancelled);
  const canEdit = !(isAlumni || isCancelled);
  const referenceCopyText = (
    <CopyToClipboardButton textToCopy={data ? data.applicant.reference : ''} />
  );

  return (
    <AdminStandardPage
      title={applicantNameAndReferenceOrBlank}
      subTitle={data ? `(${data?.applicant.reference})` : ''}
      subtitleSymbol={referenceCopyText}
      breadcrumbs={[
        { label: 'Bookings', href: '/admin/bookings/pending' },
        { label: 'Student profile', href: null },
      ]}
      isLoading={isValidating}
      error={error ? 'Failed to load page data' : undefined}
      enableHome={false}
      additionalHeaderContent={
        <ImpersonateLoginSection
          bookingId={bookingId}
          userName={applicantNameAndReferenceOrBlank}
        />
      }
    >
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          {data && bookingId && (
            <>
              <ApplicantStatus data={data} />
              <div className="flex inline space-x-2">
                <p>Sold by:</p>
                <Assignee
                  isDisabled={
                    !loggedInUser?.permissionsGranted.includes(
                      'StudentProfile.EDIT_STUDENT_PROFILE'
                    )
                  }
                  initialValue={data.sold_by ? data.sold_by.id : null}
                  dropdownOptionsEndpoint={'/api/admin/bookings/user-list/get/'}
                  setAssigneeEndpoint={`/api/admin/bookings/${bookingId}/sold-by/update/`}
                />
              </div>
            </>
          )}
        </div>
        {isAlumni ? (
          <div className="w-full flex justify-center">
            <div className="rounded p-4 text-white bg-pink-500 text-h3 w-fit">
              Alumni
            </div>
          </div>
        ) : (
          <>
            {data?.legacy_id && <ImportedBookingMarker />}
            {!isCancelled && (
              <div className="w-full flex justify-center gap-8">
                <ApplicantProgressTracker />
                {bookingId && (
                  <SendReminder
                    bookingId={bookingId}
                    last_sent={data?.last_reminder_sent_at ?? null}
                  />
                )}
              </div>
            )}
          </>
        )}

        {!isAlumni && bookingId && <MigrationIssues bookingId={bookingId} />}

        <ApplicantContactBox canEdit={canEdit} />

        <TwoColumns
          leftColumn={
            <TabbedWidget
              variant="pilled_tab"
              tabs={[
                {
                  title: 'Placement',
                  showWarningIcon: hasTravelConflict || hasAccomodationConflict,
                  content: (
                    <>
                      <AdminWidgetContainer
                        className="space-y-6 bg-white"
                        title="Placement details:"
                      >
                        <h2 className="text-sm">
                          {renderPlacementDetailsText()}{' '}
                          {placementDetailsCopyText}
                        </h2>

                        {bookingId && (
                          <TripDetailsSection
                            bookingId={bookingId}
                            canEdit={canEdit}
                          />
                        )}

                        {bookingId && (
                          <AccomodationPlacementDetailsSection
                            setHasAccomodationConflict={
                              setHasAccomodationConflict
                            }
                            bookingId={bookingId}
                            canEdit={canEdit}
                            destinationId={data?.destination.id}
                            startDate={data?.start_date}
                            endDate={data?.end_date}
                          />
                        )}
                        <HospitalPlacementDetailsSection
                          bookingId={bookingId}
                          destinationId={data?.destination.id}
                          startDate={data?.start_date}
                          endDate={data?.end_date}
                        />
                        {bookingId && (
                          <AddonDetailsSection
                            bookingId={bookingId}
                            canEdit={canEdit}
                          />
                        )}
                      </AdminWidgetContainer>

                      {bookingId && (
                        <TravelDetailsSection
                          setHasTravelConflict={setHasTravelConflict}
                          bookingId={bookingId}
                          canEdit={canEdit}
                        />
                      )}

                      <ApplicantDownloadDocumentsSection
                        bookingId={bookingId}
                      />
                    </>
                  ),
                },
                {
                  title: 'Education & Experience',
                  showWarningIcon: educationTabWarning?.warning ?? false,
                  content: (
                    <AdminWidgetContainer
                      className="space-y-6 bg-white"
                      title="Education & Experience:"
                    >
                      {bookingId && (
                        <ClinicalExperience
                          bookingId={bookingId}
                          canEdit={canEdit}
                        />
                      )}
                      {bookingId && data && (
                        <ClinicalInterests
                          bookingId={bookingId}
                          startDate={data.start_date}
                          endDate={data.end_date}
                          canEdit={canEdit}
                        />
                      )}
                      {bookingId && (
                        <PlacementObjectivesForm
                          bookingId={bookingId}
                          canEdit={canEdit}
                        />
                      )}
                      {bookingId && (
                        <EmploymentLetter
                          bookingId={bookingId}
                          canEdit={canEdit}
                        />
                      )}
                      {bookingId && (
                        <Cv bookingId={bookingId} canEdit={canEdit} />
                      )}
                    </AdminWidgetContainer>
                  ),
                },
                {
                  title: 'Finances',
                  showWarningIcon: financeTabWarning?.warning ?? false,
                  content: (
                    <div className="overflow-x-auto space-y-4">
                      {bookingId && <LedgerTable bookingId={bookingId} />}
                      {bookingId && data && (
                        <InvoiceTable
                          bookingId={bookingId}
                          currency={data.currency}
                        />
                      )}
                      {bookingId && (
                        <CancellationSection bookingId={bookingId} />
                      )}
                      {bookingId && <BookingPrices bookingId={bookingId} />}
                    </div>
                  ),
                },
                {
                  title: 'Info',
                  showWarningIcon: infoTabWarning?.warning ?? false,
                  content: (
                    <div className="overflow-x-auto space-y-4">
                      {bookingId && (
                        <ContactDetails
                          bookingId={bookingId}
                          canEdit={canEdit}
                        />
                      )}
                      {bookingId && (
                        <EmergencyContactDetails
                          bookingId={bookingId}
                          canEdit={canEdit}
                        />
                      )}
                      {bookingId && (
                        <ApplicantPhotos
                          bookingId={bookingId}
                          canEdit={canEdit}
                        />
                      )}
                      {bookingId && (
                        <AdminWidgetContainer
                          className="bg-white"
                          title="Insurance:"
                        >
                          <TravelInsuranceDetails
                            bookingId={bookingId}
                            canEdit={canEdit}
                          />
                          <IndemnityInsuranceDetails
                            bookingId={bookingId}
                            canEdit={canEdit}
                          />
                        </AdminWidgetContainer>
                      )}
                      {bookingId && (
                        <AdminWidgetContainer
                          className="bg-white"
                          title="Additional details:"
                        >
                          <DietaryRequirements
                            bookingId={bookingId}
                            canEdit={canEdit}
                          />
                          <TShirtSize bookingId={bookingId} canEdit={canEdit} />
                        </AdminWidgetContainer>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          }
          rightColumn={
            <div className="space-y-7">
              {bookingId && (
                <Notes
                  initialEntityId={bookingId}
                  initialEntityType={NoteEntityType.BOOKING}
                  colorOverrideMap={colorOverrideMap}
                  additionalEnquiryTypeId={data?.enquiry_set[0]?.id}
                />
              )}
              {bookingId && <MonitoringForms bookingId={bookingId} />}
              {bookingId && data && (
                <BookingActions
                  bookingId={bookingId}
                  registrationFeePaid={data?.registration_fee_paid}
                  isPostponed={data.is_postponed}
                  isArchived={!data.is_active}
                  isCancelled={data.is_cancelled}
                />
              )}
            </div>
          }
        />
      </div>
    </AdminStandardPage>
  );
};

export default ApplicantProfilePage;
