import React from 'react';
import classNames from 'classnames';

type Spacing = 'default';

interface Props {
  children: React.ReactNode;
  spacing?: Spacing;
}

const StackElements: React.FC<Props> = ({ children, spacing = 'default' }) => {
  return (
    <div
      className={classNames('flex flex-col', {
        'space-y-10': spacing === 'default',
      })}
    >
      {children}
    </div>
  );
};

export default StackElements;
