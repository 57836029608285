import React from 'react';

import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateTravelInsuranceDetails } from './actions';
import { TravelInsuranceDetailsData } from './types';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const TravelInsuranceDetails: React.FC<Props> = ({ bookingId, canEdit }) => {
  return (
    <ManageSingleObject<TravelInsuranceDetailsData>
      title="Travel insurance:"
      titleClassName="text-mob-sh2"
      getUrl={`/api/admin/bookings/${bookingId}/get-travel-insurance/`}
      descriptionFields={[
        {
          label: 'Insurance Company',
          render: (item) => item.travel_insurance_company,
        },
        {
          label: 'Policy Type',
          render: (item) => item.travel_insurance_policy_type,
        },
        {
          label: 'Policy number',
          render: (item) => item.travel_insurance_policy_number,
        },
        {
          label: 'Contact Tel',
          render: (item) => item.travel_insurance_contact_tel,
        },
      ]}
      formFields={[
        {
          type: 'smalltext',
          name: 'travel_insurance_company',
          label: 'Insurance Company',
        },
        {
          type: 'smalltext',
          name: 'travel_insurance_policy_type',
          label: 'Policy Type',
        },
        {
          type: 'smalltext',
          name: 'travel_insurance_policy_number',
          label: 'Policy Number',
        },
        {
          type: 'smalltext',
          name: 'travel_insurance_contact_tel',
          label: 'Contact Tel',
        },
      ]}
      insideCard={false}
      submitItem={(values) => updateTravelInsuranceDetails(bookingId, values)}
      editButton={canEdit}
    />
  );
};

export default TravelInsuranceDetails;
