import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import AdminStandardPage from '../../common/AdminStandardPage';
import { apiRequest } from '../../../../api/api';
import Table from '../../../../framework-components/table/Table';
import {
  CombinedFilters,
  TableData,
} from '../../../../framework-components/table/types';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import { RoundedSmallButtonOutline } from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import ResolveUniversityModal from './ResolveUniversityModal';
import { UnrecognisedUniversityTableBookingData } from './types';

const UnRecognisedUniversityTable: React.FC = () => {
  const { isOpen, openModal, closeModal } = useModalState();
  const [universityId, setUniversityId] = useState('');
  const [unrecognisedUniversityData, setUnrecognisedUniversityData] =
    useState<UnrecognisedUniversityTableBookingData>();
  const [refetch, setRefetch] = useState(0);

  const getUnRecognisedUniversityTableData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<UnrecognisedUniversityTableBookingData>>(
        '/api/admin/university/un-recognised-list/?' +
          'show_ignored=false&' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    [refetch] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const tableRef = useRef<{ reloadData: () => void }>(null);

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.reloadData();
    }
  };

  const getFilterOptions = useCallback(() => {
    return fetch(
      '/api/admin/university/filters/?' +
        new URLSearchParams({ type: 'unrecognised' })
    )
      .then((response) => response.json())
      .then((data) => data);
  }, []);

  const handleIgnore = (universityIdFromTable: string) => {
    return apiRequest(
      `/api/admin/university/${universityIdFromTable}/ignore-university/`,
      {
        method: 'POST',
      }
    )
      .then(() => {
        setRefetch((prev) => prev + 1);
        toast.success('University Ignored');
      })
      .catch(() => {
        toast.error('Error, could not ignore university');
      });
  };

  const handleUnIgnore = (universityIdFromTable: string) => {
    return apiRequest(
      `/api/admin/university/${universityIdFromTable}/un-ignore-university/`,
      {
        method: 'POST',
      }
    )
      .then(() => {
        setRefetch((prev) => prev + 1);
        toast.success('University no longer ignored');
      })
      .catch(() => {
        toast.error('Error, could not remove from ignored universities');
      });
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Un-recognised',
          href: null,
        },
      ]}
      title="Un-recognised"
      enableHome={false}
    >
      <div className="mt-4">
        <div className="mt-4">
          <BlockTabs
            tabs={[
              {
                label: `Universities`,
                href: '/admin/directories/universities',
                current: false,
              },
              {
                label: `Un-recognised`,
                href: '/admin/directories/un-recognised-universities',
                current: true,
              },
            ]}
            variant="pilled_tab"
          />
        </div>
        <Table<UnrecognisedUniversityTableBookingData>
          ref={tableRef}
          columnsConfig={{
            name: {
              key: 'name',
              header: 'Name',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (university) => (
                <a
                  href={`/admin/directories/universities/${university.id}/`}
                  title={university.name}
                >
                  {university.name}
                </a>
              ),
            },
            country: {
              key: 'country',
              header: 'Country',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (university) => university.country?.display_name,
            },
            applicant: {
              key: 'applicant',
              header: 'Student',
              enableSort: true,
              renderCell: (university) => {
                const applicant = university.applicants[0];
                const enquiry = university.enquiries[0];
                let url = null;
                let name = null;
                if (applicant) {
                  url = `/admin/bookings/${applicant.booking.id}/applicant-profile/`;
                  name = applicant.first_name + ' ' + applicant.last_name;
                } else if (enquiry) {
                  url = `/admin/enquiries/${enquiry.id}/enquiry-profile/`;
                  name = enquiry.first_name + ' ' + enquiry.last_name;
                }
                return (
                  url &&
                  name && (
                    <a href={url} title={name}>
                      {name}
                    </a>
                  )
                );
              },
            },
            actions: {
              key: 'actions',
              header: 'Actions',
              renderCell: (university) => {
                return (
                  <div className="flex gap-x-3">
                    <RoundedSmallButtonOutline
                      label="Resolve"
                      onClick={() => {
                        setUniversityId(university.id);
                        setUnrecognisedUniversityData(university);
                        openModal();
                      }}
                      title="resolve"
                      textClassName="text-sm-semibold"
                    />
                    <RoundedSmallButtonOutline
                      label={university.is_ignored ? 'Un-ignore' : 'Ignore'}
                      onClick={() => {
                        if (university.is_ignored) {
                          handleUnIgnore(university.id);
                        } else {
                          handleIgnore(university.id);
                        }
                        reloadData();
                      }}
                    />
                  </div>
                );
              },
            },
          }}
          getData={getUnRecognisedUniversityTableData}
          getFilterOptions={getFilterOptions}
          enablePagination
          enableSearch
          additionalFiltersConfig={{
            show_ignored: {
              key: 'show_ignored',
              label: 'Ignored',
              filterType: 'toggle',
            },
            market: {
              key: 'market',
              label: 'Market',
              filterType: 'local-dropdown',
            },
          }}
        />
        {universityId && unrecognisedUniversityData && (
          <ResolveUniversityModal
            university_id={universityId}
            unRecognisedUniversityData={unrecognisedUniversityData}
            isOpen={isOpen}
            closeModal={closeModal}
            reloadData={reloadData}
          />
        )}
      </div>
    </AdminStandardPage>
  );
};

export default UnRecognisedUniversityTable;
