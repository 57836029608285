import React, { useCallback, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import {
  Field,
  FieldInputProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { useNavigate } from 'react-router';

import Modal from '../../../modal';
import { Option, Uuid } from '../../../../types';
import { UnrecognisedUniversityTableBookingData } from './types';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';
import ErrorText from '../../../error-text';
import RemoteDropdown from '../../../../framework-components/dropdown/RemoteDropdown';
import { getFormikErrors } from '../../../../utils/forms';
import { useSubmitFormToAPI } from '../../../../api/forms';
import useModalState from '../../../../hooks/modals';
import Input from '../../../../framework-components/input/Input';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  university_id: string;
  unRecognisedUniversityData: UnrecognisedUniversityTableBookingData;
  reloadData: () => void;
}

interface FormValues {
  name_from_student: string;
  university_id: Uuid;
  pre_existing_university: string | { label: string; value: Uuid };
  accept_name_from_student: boolean;
}

export function useOnSubmit(
  university_id: string,
  openSecondaryModal: () => void
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI(
    `/api/admin/university/${university_id}/resolve-university/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded, errors] = await submitForm(
        {
          ...data,
          accept_name_from_student: data.pre_existing_university ? false : true,
        },
        formHelpers
      );
      if (hasSucceeded) {
        toast.success('University resolved');
        openSecondaryModal();
      }
      if (!hasSucceeded) {
        toast.error('Failed to resolve university');
      }
      if (errors) {
        toast.error('Failed to resolve university');
      }
    },
    [submitForm, openSecondaryModal]
  );
}

const ResolveUniversityModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  university_id,
  unRecognisedUniversityData,
  reloadData,
}) => {
  const navigate = useNavigate();
  const [createdByStudent, setCreatedByStudent] = useState(false);
  const focusRef = React.useRef(null);
  const {
    isOpen: isSecondaryOpen,
    openModal: openSecondaryModal,
    closeModal: closeSecondaryModal,
  } = useModalState();

  const onSubmit = useOnSubmit(university_id, openSecondaryModal);
  const submitForm = useCallback(
    (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );
  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="flex flex-col mt-4 ml-4 mr-4 mb-4">
        <Dialog.Title className="text-sh2 mb-4" ref={focusRef}>
          Resolve un-recognised item
        </Dialog.Title>

        <div className="w-full pt-6 mb-6">
          <Dialog.Description>
            Resolve by creating a new University using the name from the
            student, or select a pre-existing one.
          </Dialog.Description>
        </div>
        <Formik<FormValues>
          initialValues={{
            name_from_student: unRecognisedUniversityData.name,
            university_id: unRecognisedUniversityData.id,
            pre_existing_university: '',
            accept_name_from_student: false,
          }}
          onSubmit={submitForm}
        >
          {({ values, errors }) => (
            <Form className="flex flex-col mt-10">
              <div className="inline-flex mb-4 justify-center">
                <label
                  htmlFor="name_from_student"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:w-1/2"
                >
                  Name from student{' '}
                </label>
                <div className="w-full">
                  <Field name="name_from_student">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div>
                        <Input
                          id="name_from_student"
                          label=""
                          name={field.name}
                          defaultValue={field.value}
                          onChange={field.onChange}
                          extraClassNames={
                            values.pre_existing_university ? 'bg-gray-300' : ''
                          }
                        />
                        <ErrorText>{errors.name_from_student}</ErrorText>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <span className="pt-4 pb-4">OR</span>

              <div className="inline-flex mt-4 mb-4 justify-center">
                <label
                  htmlFor="pre_existing_university"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:w-1/2"
                >
                  Pre-existing university
                </label>
                <Field name="pre_existing_university">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<Option>;
                    form: FormikProps<FormValues>;
                  }) => (
                    <RemoteDropdown
                      id="pre_exisiting_name"
                      path="/api/booking/universities/"
                      errors={getFormikErrors(form, field.name)}
                      name={field.name}
                      value={field.value}
                      onSelect={(option: Option | null) => {
                        form.setFieldValue(field.name, option);
                      }}
                      placeholder="Type to search"
                      className="w-full z-50"
                    />
                  )}
                </Field>
                {errors.pre_existing_university && (
                  <ErrorText className="text-sm">
                    {errors.pre_existing_university}
                  </ErrorText>
                )}
              </div>
              <div className="inline-flex justify-end gap-4 mt-4 mb-4">
                <SecondaryButton label="Cancel" onClick={closeModal} />
                <PrimaryButton
                  label="Save"
                  isDisabled={
                    values.name_from_student !==
                      unRecognisedUniversityData.name &&
                    values.pre_existing_university
                      ? true
                      : false
                  }
                  onClick={() => {
                    if (
                      values.pre_existing_university &&
                      values.name_from_student ===
                        unRecognisedUniversityData.name
                    ) {
                      setCreatedByStudent(false);
                    } else {
                      setCreatedByStudent(true);
                    }
                  }}
                  type="submit"
                />
              </div>
              <Modal
                isOpen={isSecondaryOpen}
                onClose={() => {
                  reloadData();
                  closeSecondaryModal();
                }}
                initialFocus={focusRef}
              >
                <div>
                  {createdByStudent ? (
                    <div className="flex flex-col mt-4 ml-4 mr-4 mb-4">
                      <Dialog.Title className="text-sh2 mb-4" ref={focusRef}>
                        University created
                      </Dialog.Title>

                      <div className="w-full pt-6 mb-6 inline-flex">
                        <Dialog.Description className="font-bold pr-6">
                          University name:
                        </Dialog.Description>
                        {values.name_from_student}
                      </div>
                      <div className="inline-flex justify-end gap-4 mt-4 mb-4">
                        <SecondaryButton
                          label="Close"
                          onClick={() => {
                            reloadData();
                            closeSecondaryModal();
                            closeModal();
                          }}
                        />
                        <PrimaryButton
                          label="Go to university profile page"
                          type="button"
                          onClick={() =>
                            navigate(
                              `/admin/directories/universities/${unRecognisedUniversityData.id}/`
                            )
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex-col items-center align-center mt-4 ml-4 mr-4 mb-4">
                      <Dialog.Title
                        className="flex justify-center text-sh2 mb-4 w-full"
                        ref={focusRef}
                      >
                        Un-recognised item resolved!
                      </Dialog.Title>

                      <div className="flex justify-center w-full pt-6 mb-8 flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="h-[76px] text-wtw-logo-blue mb-4"
                          fill="#2568EB"
                        >
                          <path d="M256 16a240 240 0 1 1 0 480 240 240 0 1 1 0-480zm0 496A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM357.7 197.7c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L224 308.7l-58.3-58.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l64 64c3.1 3.1 8.2 3.1 11.3 0l128-128z" />{' '}
                        </svg>
                      </div>

                      <div className="flex justify-center mt-4">
                        <SecondaryButton
                          label="Close"
                          onClick={() => {
                            reloadData();
                            closeSecondaryModal();
                            closeModal();
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ResolveUniversityModal;
