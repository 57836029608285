import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';

interface Props {
  content: string;
  setContent: (value: string) => void;
}

const RichTextEditor: React.FC<Props> = ({ content, setContent }) => {
  const quillRef = useRef<ReactQuill>(null);

  // This is used to initialize the editor with HTML content - otherwise it reformats it and strips the HTML tags
  useEffect(() => {
    if (quillRef.current && content) {
      const editor = quillRef.current.getEditor();
      editor.clipboard.dangerouslyPasteHTML(content);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <ReactQuill ref={quillRef} value={content} onChange={setContent} />
    </div>
  );
};

export default RichTextEditor;
