import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { mutate } from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import WidgetContainer from '../../my-trip/components/WidgetContainer';
import AdminArticleDeleteConfirmationModal from './AdminArticleModal';
import { apiRequest } from '../../../api/api';
import { Article, InfoPackSection } from './types';

interface Props {
  sectionId: string;
  destinationId: string;
  title: string | undefined;
  articles: Article[];
  setEditingArticle: (article: Article | null) => void;
}

const deleteArticle = (sections: InfoPackSection[], articleId: string) => {
  return sections.map((section) => {
    const updatedArticles = section.articles.filter(
      (article) => article.id !== articleId
    );
    return {
      ...section,
      articles: updatedArticles,
    };
  });
};

const AdminInfoPackSection: React.FC<Props> = ({
  sectionId,
  destinationId,
  title,
  articles,
  setEditingArticle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [articleToDelete, setArticleToDelete] = useState<string | null>(null);

  const openModal = (article: Article) => {
    setArticleToDelete(article.id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setArticleToDelete(null);
  };

  const handleDelete = async () => {
    if (!articleToDelete) {
      return;
    }

    try {
      await apiRequest(
        `/api/admin/info-pack/delete-article/${articleToDelete}/`,
        {
          method: 'post',
        }
      );
      toast.success('Article deleted successfully');
    } catch (e) {
      toast.error('Failed to delete article');
    } finally {
      closeModal();
      mutate(
        `/api/admin/info-pack/list/${destinationId}/`,
        (sections: InfoPackSection[]) =>
          deleteArticle(sections, articleToDelete),
        false
      );
    }
  };

  return (
    <WidgetContainer className="h-full bg-wtw-gray-2" hasBorder hasShadow>
      <h4 className="text-sh2 mb-7">{title}</h4>
      {articles.map((article, index) => (
        <div
          key={index}
          className=" flex flex-wrap mt-2 bg-white border border-1 border-solid border-black py-3 px-6 flex flex-row items-center justify-left rounded-lg flex justify-between"
        >
          <div className="flex-row size-full flex-nowrap">
            <p>{article.title}</p>
          </div>
          <div className="flex flex-row gap-2">
            <button
              className="border border-1 border-solid px-4 py-1 border-wtw-blue-primary rounded-full w-fit text-sm text-wtw-logo-blue hover:text-wtw-dark-blue flex items-center gap-1"
              onClick={() => {
                setEditingArticle(article);
              }}
            >
              <PencilSquareIcon className="h-5 w-5" />
              Edit
            </button>

            <button
              key={index}
              className="border border-1 border-solid px-4 py-0 border-wtw-blue-primary rounded-full w-fit text-sm text-wtw-logo-blue hover:text-wtw-dark-blue flex items-center"
              onClick={() => openModal(article)}
            >
              {' '}
              Delete
            </button>
          </div>
        </div>
      ))}
      <AdminArticleDeleteConfirmationModal
        handleDelete={handleDelete}
        isOpen={isOpen}
        closeModal={closeModal}
      />

      <div className="flex justify-start mt-4">
        <button
          className="w-fit text-md-bold text-wtw-logo-blue hover:text-wtw-dark-blue flex items-center gap-1 text-lg pl-2"
          onClick={() => {
            navigate(
              `/admin/directories/info-pack/${sectionId}/${destinationId}/new`
            );
          }}
        >
          <div className="hover:text-wtw-dark-blue">
            <FontAwesomeIcon
              className="text-wtw-logo-blue text-center pr-1"
              icon={faPlus}
            />
          </div>
          Add new
        </button>
      </div>
    </WidgetContainer>
  );
};

export default AdminInfoPackSection;
