import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import { apiRequest, useAPI } from '../../../api/api';
import ReviewPersonalDetails from './ReviewPersonalDetails';
import Basket from '../booking-form/basket/Basket';
import Spinner from '../../spinner';
import {
  CurrencyType,
  GetEnquiryData,
  PaymentProcessorType,
} from '../booking-form/types';
import {
  CURRENCY,
  CURRENCY_TO_PAYMENT_PROCESSOR,
  REGION_TO_CURRENCY,
} from '../booking-form/constants';
import ReviewTripDetails from './ReviewTripDetails';
import ReviewContactDetails from './ReadonlyContactDetails';
import ReviewStudyDetails from './ReviewStudyDetails';
import ReviewOtherDetails from './ReviewOtherDetails';
import ReviewPaymentDetails from './ReviewPaymentDetails';
import ReviewTermsConditions from './ReviewTermsConditions';
import { ReviewBookingFormValues } from './types';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';

const ReviewBookingForm: React.FC = () => {
  const [currency, setCurrency] = useState<CurrencyType>(CURRENCY.GBP);
  const params = useParams();
  const { uid, region } = params;
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      region === 'en' ||
      region === 'eu' ||
      region === 'us' ||
      region === 'au' ||
      region === 'ca'
    ) {
      setCurrency(REGION_TO_CURRENCY[region]);
    }
  }, [region]);
  const createBookingImmediately = () => {
    return apiRequest(
      `/api/booking/create-booking/${uid}/`,
      {
        method: 'POST',
      },
      () => {
        toast.error(
          'Something went wrong while creating your booking. Please contact support.'
        );
        setSubmitting(false);
      }
    ).then(() => {
      localStorage.removeItem('enquiryId');
      setSubmitting(false);
    });
  };

  const getStripeHostedPaymentURL = () => {
    return apiRequest<{ redirect_to: string }>(
      `/api/stripe/checkout-session/registration-payment/${uid}/`
    );
  };

  const getPayPalHostedPaymentURL = () => {
    return apiRequest<{ redirect_to: string }>(
      `/api/paypal/order/registration-payment/${uid}/`
    );
  };

  const { data, isValidating, error } = useAPI<GetEnquiryData>(
    `/api/booking/enquiry/${uid}/`
  );

  const formik = useFormik<ReviewBookingFormValues>({
    initialValues: {
      uid: uid || null,
    },
    onSubmit: () => {
      setSubmitting(true);

      if (data) {
        if (data.payment_method === 'CARD') {
          const processor: PaymentProcessorType =
            CURRENCY_TO_PAYMENT_PROCESSOR[currency];
          if (processor === 'STRIPE') {
            getStripeHostedPaymentURL().then((responseData) => {
              window.location.href = responseData.redirect_to;
            });
          } else if (processor === 'PAYPAL') {
            getPayPalHostedPaymentURL().then((responseData) => {
              window.location.href = responseData.redirect_to;
            });
          }
        } else {
          createBookingImmediately().then(() => {
            navigate(`/order-summary/${uid}`);
          });
        }
      }
    },
  });

  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);

  const getExperienceData = useCallback(() => {
    if (!data?.experience_accomodation_type || !data?.experience_total_price) {
      return null;
    }

    return {
      type: data.experience_accomodation_type.type,
      price: data.experience_total_price,
    };
  }, [data]);

  const renderData = () => {
    if (isValidating) {
      return (
        <div className="p-4 flex justify-center">
          <Spinner className="h-6 w-6" />
        </div>
      );
    }
    if (error) {
      return (
        <div className="p-4 text-center">
          There was an error getting your enquiry
        </div>
      );
    }
    if (data) {
      return (
        <>
          <div className="space-y-8 w-full max-w-[768px] pr-12">
            <ReviewPersonalDetails data={data} />
            <ReviewTripDetails data={data} />
            <ReviewContactDetails data={data} />
            <ReviewStudyDetails data={data} />
            <ReviewOtherDetails data={data} />
            <ReviewPaymentDetails data={data} />
            <ReviewTermsConditions
              isAcceptedTerms={isAcceptedTerms}
              setIsAcceptedTerms={setIsAcceptedTerms}
            />
          </div>

          <Basket
            currency={currency}
            destination={data.destination}
            discipline={data.discipline.label}
            duration={data.hospital_duration}
            experience={getExperienceData()}
            start_date={data.start_date}
          />
        </>
      );
    }
  };

  return (
    <div>
      <div className="px-md xs:px-lg pb-lg flex flex-col-reverse lg:flex-row justify-start border-b border-wtw-gray-3">
        {renderData()}
      </div>
      <div className="p-lg">
        <div className="w-full flex justify-center gap-4">
          <Link to={`/booking-form/${uid}/${data?.currency}`}>
            <SecondaryButton type="button">
              <div className="flex gap-2">
                <ArrowLeftIcon className="h-6 w-6" />
                <span>Back</span>
              </div>
            </SecondaryButton>
          </Link>
          <PrimaryButton
            type="submit"
            onClick={() => formik.handleSubmit()}
            isDisabled={!isAcceptedTerms || isSubmitting}
          >
            <div className="flex gap-2">
              <span>Payment</span>
              <ArrowRightIcon className="h-6 w-6" />
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ReviewBookingForm;
