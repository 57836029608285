import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { DocumentTextIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { mutate } from 'swr';
import { useLocation } from 'react-router-dom';

import AdminWidgetContainer from '../../../framework-components/card/AdminWidgetContainer';
import { PrimaryButton } from '../components/Button';
import useModalState from '../../../hooks/modals';
import UploadModal from '../../admin/common/UploadModal';
import { useAPI } from '../../../api/api';
import { setCVUpload, setLetterOfEmploymentUpload } from './actions';
import LoadingOverlay from '../components/LoadingOverlay';
import ErrorOverlay from '../components/ErrorOverlay';
import { MyBookingsContext } from '../context-providers';

interface UploadedDocumentData {
  cv: string | null;
  cv_url: string | null | undefined;
  letter_of_employment: string | null;
  letter_of_employment_url: string | null | undefined;
}

const UploadDocument: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const bookingId = activeBooking?.id || '';

  const getUrl = `/api/my-trip/about-me/${activeBooking?.id}/documents/get/`;
  const { data, isValidating, error } = useAPI<UploadedDocumentData>(getUrl);

  const {
    isOpen: isCVOpen,
    openModal: openCVModal,
    closeModal: closeCVModal,
  } = useModalState();
  const {
    isOpen: isLetterOpen,
    openModal: openLetterModal,
    closeModal: closeLetterModal,
  } = useModalState();

  const handleCVUpload = useCallback(
    async (fileId: string) => {
      await setCVUpload(bookingId, fileId).then(() => {
        mutate(getUrl, data);
      });
    },
    [data, bookingId, getUrl]
  );

  const handleLetterUpload = useCallback(
    async (fileId: string) => {
      await setLetterOfEmploymentUpload(bookingId, fileId).then(() => {
        mutate(getUrl, data);
      });
    },
    [data, bookingId, getUrl]
  );

  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location.hash === '#upload-documents') {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location, data]);

  return (
    <AdminWidgetContainer title="Upload documents" hasBorder hasShadow>
      {isValidating && <LoadingOverlay />}
      {error && !isValidating && <ErrorOverlay />}
      {data && (
        <div className="w-full" ref={ref}>
          <div className="flex flex-col w-full my-4 align-center py-4">
            <div className="flex flex-row w-full justify-between align-center mb-4">
              <p>CV</p>
              <div>
                <a href={data.cv_url ? data.cv_url : undefined}>
                  <DocumentTextIcon
                    className={classNames('h-6 w-6', {
                      'fill-wtw-green-warning': data.cv,
                      'fill-wtw-secondary-text': !data.cv,
                    })}
                  />
                </a>
              </div>
            </div>
            <PrimaryButton
              className="w-full"
              onClick={openCVModal}
              label="Upload CV"
            />
          </div>
          <UploadModal
            resizeImage
            isOpen={isCVOpen}
            closeModal={closeCVModal}
            title="Upload CV"
            onSuccessfulUpload={(fileId) => {
              handleCVUpload(fileId);
            }}
            acceptedExtensions={['doc', 'pdf']}
          />
          <div className="flex flex-col w-full my-4 align-center py-4">
            <div className="flex flex-row w-full justify-between align-center mb-4">
              <p className="text-pretty">Employment or enrolment letter</p>
              <div>
                <a
                  href={
                    data.letter_of_employment_url
                      ? data.letter_of_employment_url
                      : undefined
                  }
                >
                  <DocumentTextIcon
                    className={classNames('h-6 w-6', {
                      'fill-wtw-green-warning': data?.letter_of_employment,
                      'fill-wtw-secondary-text': !data?.letter_of_employment,
                    })}
                  />
                </a>
              </div>
            </div>
            <PrimaryButton
              className="w-full"
              onClick={openLetterModal}
              label="Upload letter"
            />
          </div>
          <UploadModal
            isOpen={isLetterOpen}
            closeModal={closeLetterModal}
            title="Upload Letter of employment / enrolment"
            onSuccessfulUpload={(fileId) => {
              handleLetterUpload(fileId);
            }}
            acceptedExtensions={['doc', 'pdf']}
          />
        </div>
      )}
    </AdminWidgetContainer>
  );
};

export default UploadDocument;
