import React from 'react';

import AdminStandardPage from '../../admin/common/AdminStandardPage';
import AlumniListingTable from './AlumniListingTable';

const AllAlumniListing: React.FC = () => {
  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Alumni',
          href: null,
        },
        {
          label: 'All Alumni',
          href: null,
        },
      ]}
      title="Alumni"
      enableHome={false}
    >
      <AlumniListingTable />
    </AdminStandardPage>
  );
};

export default AllAlumniListing;
