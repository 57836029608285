import React from 'react';
import { Dialog } from '@headlessui/react';

import TermsAndConditionsText from '../../booking/booking-review/TermsAndConditionsText';
import Modal from '../../modal';
import useModalState from '../../../hooks/modals';
import { SecondaryButton } from '../components/Button';

const TermsAndConditions: React.FC = () => {
  const {
    isOpen: isTCModalOpen,
    closeModal: closeTCModal,
    setIsOpen: setIsTCModalOpen,
  } = useModalState();

  const focusRef = React.useRef(null);
  return (
    <>
      <button
        className="text-wtw-logo-blue hover:text-wtw-dark-blue text-xs"
        onClick={() => setIsTCModalOpen(true)}
      >
        Terms & conditions
      </button>
      <Modal
        isOpen={isTCModalOpen}
        onClose={closeTCModal}
        initialFocus={focusRef}
      >
        <div className="space-y-4">
          <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
            Terms and conditions
          </Dialog.Title>
          <Dialog.Description>
            <TermsAndConditionsText />
          </Dialog.Description>
          <div className="flex justify-center pt-4">
            <SecondaryButton onClick={closeTCModal}>Close</SecondaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TermsAndConditions;
