import React from 'react';
import { Link } from 'react-router-dom';

import LoginForm from './login-form';
import LoginWidgetContainer from '../my-trip/components/LoginWidgetContainer';

const Login: React.FC = () => (
  <div className="flex items-center justify-center sm:mt-6">
    <LoginWidgetContainer>
      <div className="space-y-8 w-full md:w-1/2 px-6 py-8">
        <LoginForm />
        <p className="text-center">
          <Link
            className="text-blue-600 hover:text-blue-900"
            to="/forgot-password"
          >
            Forgotten password?
          </Link>
        </p>
      </div>
    </LoginWidgetContainer>
  </div>
);

export default Login;
