import { createContext } from 'react';

import { CurrencyType } from '../booking/booking-form/types';

export interface Booking {
  id: string;
  start_date: string;
  end_date: string;
  booking_confirmed_at: string;
  destination: {
    id: string;
    name: string;
    has_wildlife_safari: boolean;
    operations_manager: {
      title: string;
      first_name: string;
      last_name: string;
      position: string;
      email: string;
      telephone: string;
    };
  };
  currency: CurrencyType;
  applicant: { gender: string };
  wildlife_safari_added: boolean;
}

export type Bookings = ReadonlyArray<Booking>;

export type BookingContext = {
  bookings: Bookings | undefined;
  activeBooking: Booking | null;
  setActiveBooking: (booking: Booking | null) => void;
};

export const MyBookingsContext = createContext<BookingContext>({
  bookings: undefined,
  activeBooking: null,
  setActiveBooking: () => {},
});
