import React, { useMemo, useState } from 'react';

import { CalendarSection, Event } from './types';
import CalendarDay from './CalendarDay';
import { UNALLOCATED_SECTION_ID } from './constants';
import { createUTCDateFromDate } from '../../utils/dates';

interface Props<T, U> {
  isEditing: boolean;
  sectionId: string;
  title: string;
  subsections: ReadonlyArray<CalendarSection<U>>;
  events: ReadonlyArray<Event<T>>;
  dates: ReadonlyArray<Date>;
  getEventForDay: (
    section: CalendarSection<U>,
    date: Date,
    events: readonly Event<T>[],
    rowIndex: number
  ) => Event<T> | null;
  checkIsSectionDisabled?: (
    event: Event<T>,
    events: ReadonlyArray<Event<T>>,
    section: CalendarSection<U>
  ) => boolean;
  activeEvent: Event<T> | null;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
  hideEmptyRows?: boolean;
  selectedEvent: Event<T> | null;
  setSelectedEvent: React.Dispatch<React.SetStateAction<Event<T> | null>>;
}

const getRowsInSection = <U,>(
  subsection: CalendarSection<U>,
  numberEventsInSection: number,
  hideEmptyRows: boolean
) => {
  if (hideEmptyRows) return Math.max(numberEventsInSection, 1);
  if (subsection.id === UNALLOCATED_SECTION_ID)
    return numberEventsInSection + 1;
  return Math.max(numberEventsInSection, subsection.capacity, 1);
};

const Section = <T, U>({
  isEditing,
  sectionId,
  title,
  subsections,
  events,
  dates,
  getEventForDay,
  checkIsSectionDisabled,
  activeEvent,
  getOverlayColour,
  hideEmptyRows,
  selectedEvent,
  setSelectedEvent,
}: Props<T, U>) => {
  const [collapsed, setCollapsed] = useState(false);

  const renderSection = useMemo(() => {
    if (!selectedEvent || !checkIsSectionDisabled) {
      return true;
    }
    return subsections.some((subsection) => {
      return !checkIsSectionDisabled(selectedEvent, events, subsection);
    });
  }, [selectedEvent, checkIsSectionDisabled, subsections, events]);

  if (!renderSection) {
    return null;
  }

  return (
    <div className="w-full border-l border-gray-300">
      <div
        className="bg-gray-300 w-full py-1 px-4 h-full text-sm border-b-2 border-gray-500 cursor-pointer"
        onClick={() => setCollapsed(!collapsed)}
      >
        {title}
      </div>
      {!collapsed &&
        subsections.map((subsection) => {
          const numberEventsInSection = new Set(
            events
              .filter((event) => event.sectionId === subsection.id)
              .map((event) => event.rowIndex)
          ).size;
          const rowsInSection = getRowsInSection<U>(
            subsection,
            numberEventsInSection,
            Boolean(hideEmptyRows)
          );
          const subtitleHeight = rowsInSection * 20;
          return (
            <>
              {(!selectedEvent ||
                !checkIsSectionDisabled ||
                !checkIsSectionDisabled(selectedEvent, events, subsection)) && (
                <div
                  key={subsection.id}
                  className="relative flex border-b-2 border-gray-500"
                >
                  <div
                    style={{ height: `${subtitleHeight}px` }}
                    className="flex items-center justify-start w-[235px] pl-4 border-t border-gray-300 sticky left-0 bg-white z-20"
                  >
                    {subsection.label}
                  </div>
                  <div>
                    {Array.from({
                      length: rowsInSection,
                    }).map((_, index) => (
                      <div
                        key={`${sectionId}-${index}`}
                        className="flex items-center justify-center border border-gray-300 h-5"
                      >
                        <div className="w-12 flex items-center justify-center border-r border-gray-300 h-5">
                          {index + 1}
                        </div>
                        {dates.map((date, idx) => (
                          <CalendarDay
                            key={`${sectionId}-${index}-${idx}`}
                            isEditing={isEditing}
                            parentSectionId={sectionId}
                            section={subsection}
                            date={date}
                            events={events}
                            rowIndex={index}
                            getEventForDay={getEventForDay}
                            sectionStartDate={createUTCDateFromDate(dates[0])}
                            sectionEndDate={createUTCDateFromDate(
                              dates[dates.length - 1]
                            )}
                            getOverlayColour={getOverlayColour}
                            activeEvent={activeEvent}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                          />
                        ))}
                      </div>
                    ))}
                    {hideEmptyRows && (
                      <div className="flex items-center justify-start border border-gray-300 text-sm pl-4 w-full grow">
                        {numberEventsInSection < subsection.capacity
                          ? `Places ${numberEventsInSection + 1} to ${
                              subsection.capacity
                            } are vacant`
                          : `The maximum capacity at any time is ${subsection.capacity}`}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          );
        })}
    </div>
  );
};

export default Section;
