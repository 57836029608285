import React from 'react';
import { Dialog } from '@headlessui/react';
import { mutate } from 'swr';

import Modal from '../../modal';
import { Article, InfoPackData } from './types';
import { useAPI } from '../../../api/api';
import Spinner from '../components/spinner';
import ErrorText from '../../error-text';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import { setArticleRead } from './actions';
import HtmlRenderer from '../../../framework-components/html-renderer/HtmlRenderer';
import { handlePrint } from '../../../utils/print';

interface ArticleData {
  pk: string;
  title: string;
  content: string;
  read_at: string;
}

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  article: Article;
  bookingId: string;
  sectionTitle: string;
}

const ArticleModal = ({
  isOpen,
  closeModal,
  article,
  bookingId,
  sectionTitle,
}: Props) => {
  const focusRef = React.useRef(null);

  const { data, isValidating, error } = useAPI<ArticleData>(
    isOpen ? `/api/my-trip/info-pack/${bookingId}/${article.pk}/get/` : null
  );

  const printRef = React.useRef<HTMLDivElement>(null);

  const setReadAndMutate = async () => {
    await setArticleRead(bookingId, article.pk);
    mutate(
      `/api/my-trip/info-pack/${bookingId}/list/`,
      (articles: InfoPackData) => {
        if (articles) {
          return {
            ...articles,
            [sectionTitle]: articles[sectionTitle].map((a) =>
              a.pk === article.pk
                ? {
                    ...a,
                    has_been_read: true,
                  }
                : a
            ),
          };
        }
        return articles;
      },
      false
    );
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="pl-4 pr-4" data-testid="manage-modal" ref={printRef}>
        <Dialog.Title className="text-2xl font-bold pb-2" ref={focusRef}>
          {article.title}
        </Dialog.Title>
        {isValidating && <Spinner className="h-6 w-6" />}
        {error && !isValidating && !data && (
          <ErrorText>Error getting article</ErrorText>
        )}
        {data && !isValidating && (
          <HtmlRenderer htmlString={data.content} className="space-y-2" />
        )}
      </div>
      {data && data.content && (
        <div className="sm:flex sticky bottom-0 bg-white pt-10 pb-6">
          <div className="w-1/2 sm:pl-20 sm:pr-20 pb-6">
            <SecondaryButton
              className="w-full"
              onClick={() => handlePrint(printRef)}
            >
              Print
            </SecondaryButton>
          </div>
          <div className="w-1/2 sm:pl-20 sm:pr-20">
            <PrimaryButton className="w-full" onClick={setReadAndMutate}>
              {article.has_been_read ? 'Close' : 'Mark read & understood'}
            </PrimaryButton>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ArticleModal;
