import React, { useState, useContext, useEffect } from 'react';

import Timeline from './Timeline';
import ToDoList from './ToDoList';
import { ListData } from './types';
import { useAPI } from '../../../api/api';
import { MyBookingsContext } from '../context-providers';
import MyTripStandardPage from '../layout/MyTripStandardPage';
import BlockTabs from '../../../framework-components/blocktabs/BlockTabs';
import SpanningContainer from '../layout/SpanningContainer';
import StackElements from '../layout/StackElements';
import AvailableAddons from '../components/available-addons/AvailableAddons';
import WelcomeModal from './WelcomeModal';
import useModalState from '../../../hooks/modals';

const ToDo: React.FC = () => {
  const [selectedDateIndex, setSelectedDateIndex] = useState(-1);

  const { activeBooking } = useContext(MyBookingsContext);

  const { isOpen, openModal, closeModal } = useModalState();

  const { data } = useAPI<ListData>(
    activeBooking ? `/api/my-trip/to-do/${activeBooking.id}/list/` : null
  );

  const { data: showWelcomeModalData } = useAPI<{
    show_welcome_modal: boolean;
  }>(
    activeBooking
      ? `/api/my-trip/to-do/${activeBooking.id}/show-welcome-modal/`
      : null
  );

  const selectedDate = data?.dates[selectedDateIndex];

  useEffect(() => {
    if (showWelcomeModalData?.show_welcome_modal) {
      openModal();
    }
  }, [showWelcomeModalData, openModal]);

  return (
    <MyTripStandardPage title="My account">
      <BlockTabs
        tabs={[
          {
            label: 'Tasks',
            href: '/my-trip/',
            current: true,
          },
          {
            label: 'My Trip Details',
            href: '/my-trip/trip-summary/',
          },
        ]}
        variant="pilled_tab_with_border_bottom"
      />
      <div className="w-full">
        {data && (
          <SpanningContainer variant="white">
            <StackElements>
              <Timeline
                dates={data.dates}
                selectedDateIndex={selectedDateIndex}
                setSelectedDateIndex={setSelectedDateIndex}
              />
              <ToDoList
                items={data.items}
                selectedDate={selectedDate ? new Date(selectedDate) : undefined}
              />
            </StackElements>
          </SpanningContainer>
        )}
        {activeBooking && (
          <SpanningContainer variant="clear">
            <AvailableAddons activeBooking={activeBooking} />
          </SpanningContainer>
        )}
      </div>
      {activeBooking && (
        <WelcomeModal
          bookingId={activeBooking.id}
          isOpen={isOpen}
          closeModal={closeModal}
        />
      )}
    </MyTripStandardPage>
  );
};

export default ToDo;
