import { toast } from 'react-toastify';
import { mutate } from 'swr';

import { DepartmentAndSupervisorData } from '../types';
import { removeDepartment } from '../actions';

export const useDeleteDepartment = (
  hospitalId: string,
  setLoading: (isLoading: boolean) => void
) => {
  const removeDepartmentHandler = async (departmentId: string) => {
    try {
      setLoading(true);
      await removeDepartment(departmentId);
      toast.success('Department removed successfully');
      mutate(
        `/api/admin/hospital/${hospitalId}/departments-and-supervisors/get/`,
        (data: Array<DepartmentAndSupervisorData>) =>
          data.filter((item) => item.id !== departmentId),
        false
      );
    } catch (error) {
      toast.error('Failed to remove department');
    } finally {
      setLoading(false);
    }
  };

  return { removeDepartmentHandler };
};
