import React from 'react';

import AdminStandardPage from '../common/AdminStandardPage';
import TwoColumns from '../common/layout/TwoColumns';
import AdminWidgetContainer from '../../../framework-components/card/AdminWidgetContainer';
import { useAPI } from '../../../api/api';
import { DashboardPageData } from './types';
import DashboardSection from './DashboardSection';
import DashboardNav from './DashboardNav';

const Dashboard: React.FC = () => {
  const { data, error, isValidating } = useAPI<Array<DashboardPageData>>(
    `/api/admin/dashboard/`
  );

  const renderDashboardSections = (
    dashboardItemList: Array<DashboardPageData>
  ) => {
    const leftColumn: Array<React.ReactElement<React.FC<DashboardPageData>>> =
      [];
    const rightColumn: Array<React.ReactElement<React.FC<DashboardPageData>>> =
      [];

    for (let i = 0; i < dashboardItemList.length; i++) {
      if (i % 2 === 0) {
        leftColumn.push(
          <DashboardSection
            key={i}
            title={dashboardItemList[i].title}
            stats={dashboardItemList[i].stats}
            actions={dashboardItemList[i].actions}
            view_all_url={dashboardItemList[i].view_all_url}
          />
        );
      } else {
        rightColumn.push(
          <DashboardSection
            key={i}
            title={dashboardItemList[i].title}
            stats={dashboardItemList[i].stats}
            actions={dashboardItemList[i].actions}
            view_all_url={dashboardItemList[i].view_all_url}
          />
        );
      }
    }
    return (
      <TwoColumns
        leftColumn={<>{leftColumn}</>}
        rightColumn={<>{rightColumn}</>}
      />
    );
  };
  return (
    <AdminStandardPage
      title="Dashboard"
      breadcrumbs={[{ label: 'Dashboard', href: null }]}
      isLoading={isValidating}
      error={error ? 'Failed to load page data' : undefined}
    >
      {
        <div className="flex flex-row gap-4">
          <div className="h-[900px]">
            <DashboardNav
              NavItemList={[
                {
                  icon: 'telephone',
                  label: 'Enquiries',
                  url: '/admin/enquiries/new',
                },
                {
                  icon: 'passport',
                  label: 'Bookings',
                  url: '/admin/bookings/pending',
                },
                {
                  icon: 'plane',
                  label: 'Arrivals & Departures',
                  url: '/admin/bookings/arrivals',
                },
                {
                  icon: 'clipboard',
                  label: 'Survey Results',
                  url: '/admin/alumni/survey-results',
                },
                {
                  icon: 'graduation_cap',
                  label: 'Alumni',
                  url: '/admin/alumni/all',
                },
                {
                  icon: 'file',
                  label: 'Documents',
                  url: '/admin/directories/documents',
                },
                {
                  icon: 'graph',
                  label: 'Reporting & Exports',
                  url: '',
                },
                {
                  icon: 'dollar',
                  label: 'Finance',
                  url: '',
                },
              ]}
            />
          </div>
          <div className="w-full">
            <AdminWidgetContainer className="bg-wtw-gray-3">
              {data ? renderDashboardSections(data) : ''}
            </AdminWidgetContainer>
          </div>
        </div>
      }
    </AdminStandardPage>
  );
};

export default Dashboard;
