import React from 'react';

import Modal from '../../../modal';
import AddNewDocumentForm from './AddNewDocumentForm';

interface AddNewDocumentModalProps {
  onClose: () => void;
  reloadData: () => void;
}

const AddNewDocumentModal: React.FC<AddNewDocumentModalProps> = ({
  onClose,
  reloadData,
}) => {
  return (
    <div>
      <Modal isOpen onClose={onClose}>
        <div>
          <AddNewDocumentForm reloadData={reloadData} onClose={onClose} />
        </div>
      </Modal>
    </div>
  );
};

export default AddNewDocumentModal;
