import { format, parseISO } from 'date-fns';

export const formatDateToReadableString = (date: string) => {
  return format(date, 'MMMM do, yyyy');
};

export const formatDateToReadableStringShorthandMonth = (date: string) => {
  return format(date, 'MMM do, yyyy');
};

export const dateToYearMonthDayFormat = (date: Date) => {
  return format(parseISO(date.toISOString()), 'yyyy-MM-dd');
};

export const formatDateToLongDateFormatWithOridinalDay = (date: string) => {
  return format(date, 'do MMMM yyyy');
};

export const formatDateToDayDateMonthYear = (date: string) => {
  return format(date, 'EEE dd MMM yyyy');
};

export const formatDateTimeShorthandMonthDayYear = (date: string) => {
  return format(date, 'MMM do, yyyy, HH:mm');
};

export const createUTCDateFromString = (dateString: string) => {
  const date = new Date(dateString);
  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  );
};

export const createUTCDateFromDate = (date: Date) => {
  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  );
};

export const isValidMonth = (month: number): boolean =>
  month >= 1 && month <= 12;

export const getDatesInRange = (start: Date, end: Date) => {
  const dates = [];

  while (start <= end) {
    dates.push(createUTCDateFromDate(start));
    start.setUTCDate(start.getUTCDate() + 1);
  }

  return dates;
};

export const sortDatesIntoMonths = (dates: ReadonlyArray<Date>) => {
  const months: Record<string, Date[]> = {};

  dates.forEach((date) => {
    const month = format(date, 'MMMM yyyy');
    if (!months[month]) {
      months[month] = [];
    }
    months[month].push(date);
  });

  return months;
};

export const calculateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const timeDifferenceMilliseconds: number = Math.abs(
    endDate.getTime() - startDate.getTime()
  );
  const daysDifference: number = Math.ceil(
    timeDifferenceMilliseconds / (1000 * 60 * 60 * 24)
  );
  return daysDifference;
};

export const addDays = (date: Date, days: number) => {
  const result = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate() + days,
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    )
  );
  return result;
};

export const isSunday = (date: Date) => {
  const today = new Date();
  return date > today && date.getDay() === 0;
};

export const dateToUkFormattedDayMonthYear = (date: string) => {
  return format(date, 'dd-MM-yy');
};

export const getBookingDateConfig = (startDate: string, endDate: string) => {
  const startDateObject = new Date(startDate);
  const endDateObject = new Date(endDate);
  const year = startDateObject.getFullYear();
  const month = startDateObject.getMonth() + 1;
  const day = startDateObject.getDate();
  const period = calculateDaysBetweenDates(startDateObject, endDateObject);
  return { month, year, day, period };
};
