import React from 'react';

const DocumentNegativeIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26215 10H10.9288M5.26215 13.7778H10.9288M12.8177 18.5H3.37326C2.33006 18.5 1.48438 17.6543 1.48438 16.6111V3.38889C1.48438 2.34568 2.33006 1.5 3.37326 1.5H8.64873C8.89921 1.5 9.13944 1.5995 9.31655 1.77662L14.43 6.89005C14.6071 7.06716 14.7066 7.30739 14.7066 7.55787V16.6111C14.7066 17.6543 13.8609 18.5 12.8177 18.5Z"
        stroke="#00DB3D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentNegativeIcon;
