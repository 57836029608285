import React, { useState } from 'react';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export interface NavItem {
  icon:
    | 'plane'
    | 'clipboard'
    | 'graph'
    | 'passport'
    | 'telephone'
    | 'graduation_cap'
    | 'file'
    | 'dollar';
  label: string;
  url: string;
}

export interface VerticalNavBarProps {
  NavItemList: Array<NavItem>;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  hasShadow?: boolean;
  hasBorder?: boolean;
  backgroundColor?: string;
  title?: string;
  collapsible?: boolean;
  contentWhenExpanded?: (collapseFn: () => void) => React.ReactNode;
  contentWhenCollapsed?: React.ReactNode;
  isCollapsedOnLoad?: boolean;
}

const VerticalNavBar: React.FC<VerticalNavBarProps> = ({
  children,
  className,
  hasShadow = true,
  hasBorder = true,
  backgroundColor,
  collapsible = false,
  contentWhenExpanded,
  contentWhenCollapsed,
  isCollapsedOnLoad = localStorage.getItem('isCollapsedOnLoad') === 'false',
}) => {
  const [isCollapsed, setIsCollapsed] = useState(
    collapsible ? !isCollapsedOnLoad : false
  );

  const expandContainer = () => {
    setIsCollapsed(false);
    localStorage.setItem('isCollapsedOnLoad', (!isCollapsed).toString());
  };

  const collapseContainer = () => {
    setIsCollapsed(true);
    localStorage.setItem('isCollapsedOnLoad', (!isCollapsed).toString());
  };

  return (
    <div
      className={classNames(
        'rounded-xl p-4 relative grid min-h-[800px] max-w-[250px] h-full',
        { 'shadow-md': hasShadow },
        { 'bg-white': !backgroundColor },
        { 'border border-wtw-gray-4': hasBorder },
        { 'w-20': isCollapsed },
        backgroundColor,
        className
      )}
    >
      {isCollapsed && (
        <FontAwesomeIcon
          className="m-2 h-8 w-8 justify-self-center self-center cursor-pointer text-wtw-secondary-text"
          icon={faChevronsRight}
          onClick={collapsible && isCollapsed ? expandContainer : undefined}
        />
      )}
      {children}
      {isCollapsed
        ? contentWhenCollapsed
        : contentWhenExpanded && contentWhenExpanded(collapseContainer)}
    </div>
  );
};

export default VerticalNavBar;
