import React from 'react';
import { toast } from 'react-toastify';

import { apiRequest } from '../../../../api/api';
import Modal from '../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';

interface ArchiveDocumentProps {
  documentId: string | null;
  onClose: () => void;
  reloadData: () => void;
}

const ArchiveDocument: React.FC<ArchiveDocumentProps> = ({
  documentId,
  onClose,
  reloadData,
}) => {
  const handleArchive = async () => {
    const url = `/api/admin/document/${documentId}/archive/`;

    try {
      await apiRequest(url, { method: 'POST' });
      toast.success('You have successfully archived this document.');
      reloadData();
      onClose();
    } catch (error) {
      toast.error('Failed to archive this document. Please try again.');
    }
  };

  return (
    <div>
      <Modal isOpen onClose={onClose}>
        <div className="flex flex-col gap-4">
          <h1 className="text-sh4">Archive Document</h1>
          <p className="text-sm">
            Are you sure you want to archive this document?
          </p>
          <div className="flex flex-row gap-4 justify-center mt-2">
            <PrimaryButton onClick={handleArchive}>Confirm</PrimaryButton>
            <SecondaryButton onClick={onClose}>Close</SecondaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ArchiveDocument;
