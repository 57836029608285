import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import { useAPI } from '../../../../api/api';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import { LetterOfEmploymentData } from './types';
import ApplicantFile from './ApplicantFile';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const showWarningIcon = (data: LetterOfEmploymentData) => {
  return data.letter_of_employment?.approval_status === 'PENDING';
};

const EmploymentLetter: React.FC<Props> = ({ bookingId, canEdit }) => {
  const getUrl = `/api/admin/bookings/${bookingId}/get-letter-of-employment/`;
  const { data, isValidating, error } = useAPI<LetterOfEmploymentData>(getUrl);

  return (
    <AdminWidgetContainer hasBorder hasShadow>
      {error && !isValidating && <ErrorOverlay />}
      {isValidating && <LoadingOverlay />}
      <div className="flex gap-2 items-center justify-start mb-4">
        <h4 className="text-sh2">Employment / Letter of Enrolment</h4>
        {data && showWarningIcon(data) && (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-red-500 text-sh2"
          />
        )}
      </div>
      {data && (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full mb-4">
          <ApplicantFile<LetterOfEmploymentData>
            field="letter_of_employment"
            file={data.letter_of_employment}
            canEdit={canEdit}
            setUrl={`/api/admin/bookings/${bookingId}/set-letter-of-employment/`}
            getUrl={getUrl}
            mutateTabUrl={`/api/admin/bookings/${bookingId}/get-education-tab-warning/`}
          />
        </div>
      )}
    </AdminWidgetContainer>
  );
};

export default EmploymentLetter;
