import { Event, CalendarSection } from '../../../calendar/types';
import { EventMetadata, SectionMetadata } from './types';
import { EventMetadata as HouseMetadata } from '../house/types';
import { DISCIPLINE_COLOUR_MAP } from './constants';

export const checkIsSectionFull = (
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  sectionId: string,
  capacity: number
) => {
  const eventsOnDateInSectionWithDiscipline = events.filter(
    (e) =>
      e.id !== event.id &&
      event.start_date <= e.end_date &&
      event.end_date >= e.start_date &&
      e.sectionId === sectionId &&
      e.metadata?.discipline.id === event.metadata?.discipline.id
  );
  return eventsOnDateInSectionWithDiscipline.length >= capacity;
};

export const checkIsSectionDisabled = (
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  section: CalendarSection<SectionMetadata>
) => {
  if (!section.metadata?.disciplines) {
    return false;
  }

  const disciplineInSection = section.metadata.disciplines.find(
    (discipline) => discipline.id === event.metadata?.discipline.id
  );

  if (!disciplineInSection) {
    return true;
  }

  return checkIsSectionFull(
    event,
    events,
    section.id,
    disciplineInSection.capacity
  );
};

export const getOverlayColour = (
  event: Event<EventMetadata | HouseMetadata>,
  light: boolean
) => {
  if (
    !event.metadata?.discipline.name ||
    !(event.metadata.discipline.name in DISCIPLINE_COLOUR_MAP)
  ) {
    return '';
  }
  const key = light
    ? `${event.metadata.discipline.name} light`
    : event.metadata.discipline.name;
  return DISCIPLINE_COLOUR_MAP[key];
};
