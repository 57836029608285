import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

import ErrorText from '../../components/error-text';

export interface InputProps {
  label?: string | React.ReactNode;
  id?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  value?: string | number;
  type?: string;
  placeholder?: string;
  errors?: Array<string>;
  disabled?: boolean;
  row?: boolean;
  highlightErrors?: boolean;
  extraClassNames?: string;
  step?: string;
  inputWidth?: string;
}

const Input: React.FC<InputProps> = ({
  label,
  id,
  name,
  onChange,
  onBlur,
  defaultValue,
  value,
  type = 'text',
  placeholder,
  errors = [],
  disabled,
  row,
  highlightErrors = false,
  extraClassNames = '',
  step,
  inputWidth,
}) => {
  const errorIds = errors.map((_, index) => `${name}-error-${index}`).join(' ');
  const hasErrors = errors.length > 0;

  const displayErrors = () => {
    if (!hasErrors) return;
    return <ErrorText className="text-sm">{errors}</ErrorText>;
  };

  const displayExclamationMarkIfError = () => {
    if (!hasErrors || !highlightErrors) return;

    return (
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-400"
          aria-hidden="true"
        />
      </div>
    );
  };

  return (
    <div>
      <div
        className={classNames('flex', {
          'flex-col': !row,
          'flex-row gap-2 items-center': row,
        })}
      >
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              'block text-sm font-medium leading-6 text-gray-900',
              { 'w-1/2': row }
            )}
          >
            {label}
          </label>
        )}
        <div className="relative rounded-md shadow-sm">
          <input
            type={type}
            name={name}
            id={id}
            className={classNames(
              `block ${
                inputWidth || 'w-full'
              } rounded-md border-0 py-1.5 pr-4 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:resize-none ${extraClassNames}`,
              {
                'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                  hasErrors && highlightErrors,
              },
              {
                'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600':
                  !hasErrors || !highlightErrors,
              }
            )}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            aria-invalid={hasErrors ? 'true' : 'false'}
            aria-describedby={errorIds}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            step={step}
          />

          {displayExclamationMarkIfError()}
        </div>
      </div>
      {displayErrors()}
    </div>
  );
};

export default Input;
