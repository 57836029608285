import React from 'react';

import AdminStandardPage from '../../common/AdminStandardPage';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import TabbedWidget from '../../../../framework-components/tabbedContainer/TabbedWidget';
import DestinationListingKeyInfo from './key-info/DestinationListingKeyInfo';
import LocalDetails from './local-details/LocalDetails';
import Admin from './admin/Admin';
import { useDestinationTabs } from '../../common/hooks';

const DestinationListing: React.FC = () => {
  const { destination, destinationTabs, isValidating, error } =
    useDestinationTabs();

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Destinations',
          href: null,
        },
        {
          label: destination ? destination.label : '',
          href: null,
        },
      ]}
      title={destination ? destination.label : ''}
      enableHome={false}
      isLoading={isValidating}
      error={error && 'Failed to load destinations'}
    >
      <BlockTabs
        tabs={destinationTabs}
        variant="pilled_tab_with_border_bottom"
      />

      <div className="py-12">
        <TabbedWidget
          tabs={[
            {
              title: 'Key Info',
              content: (
                <DestinationListingKeyInfo destinationId={destination?.value} />
              ),
            },
            {
              title: 'Local Details',
              content: <LocalDetails destinationId={destination?.value} />,
            },
            {
              title: 'Admin',
              content: <Admin destinationId={destination?.value} />,
            },
          ]}
          variant="pilled_secondary_action_tab"
        />
      </div>
    </AdminStandardPage>
  );
};

export default DestinationListing;
