import {
  faCircleCheck,
  faClock,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { ConfirmedBookingTableDataBalanceStatus } from './booking-list/types';
import { calculateDaysBetweenDates } from '../../../utils/dates';

export const balanceStatusToIcon = (
  balanceStatus: ConfirmedBookingTableDataBalanceStatus | undefined
) => {
  switch (balanceStatus) {
    case ConfirmedBookingTableDataBalanceStatus.PENDING:
      return (
        <FontAwesomeIcon
          icon={faClock}
          className="text-wtw-balance-pending text-lg"
        />
      );
    case ConfirmedBookingTableDataBalanceStatus.OVERDUE:
      return (
        <FontAwesomeIcon
          icon={faXmarkCircle}
          className="text-wtw-balance-overdue text-lg"
        />
      );
    case ConfirmedBookingTableDataBalanceStatus.COMPLETE:
      return (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-wtw-balance-complete text-lg"
        />
      );
    default:
      return null;
  }
};

export const getTripStatus = (start_date: string, end_date: string) => {
  const now = new Date();
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  if (startDate > now) {
    const leavesIn = calculateDaysBetweenDates(now, startDate);
    return `Leaves in ${leavesIn} days`;
  } else if (now >= startDate && now <= endDate) {
    return 'On-Trip';
  }
  return 'Alumni';
};
