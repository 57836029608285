import React from 'react';
import { mutate } from 'swr';

import { PublicDescriptionData } from './types';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updatePublicDescription } from './actions';
import HtmlRenderer from '../../../../framework-components/html-renderer/HtmlRenderer';

interface Props {
  hospitalId: string;
}

const PublicDescription: React.FC<Props> = ({ hospitalId }) => {
  return (
    <ManageSingleObject<PublicDescriptionData>
      title="Public Description"
      modalTitle="Edit public description"
      titleClassName="pb-4 text-sh2"
      simple
      getUrl={`/api/admin/hospital/${hospitalId}/public-description/get/`}
      descriptionFields={[
        {
          label: 'Public description',
          render: (item) => <HtmlRenderer htmlString={item.description_html} />,
        },
      ]}
      formFields={[
        {
          type: 'richtext',
          name: 'description_html',
          label: 'Public description',
        },
      ]}
      submitItem={(data) => updatePublicDescription(data, hospitalId)}
      onSubmitSuccess={() => {
        mutate(`/api/admin/hospital/${hospitalId}/public-description/get/`);
      }}
    />
  );
};

export default PublicDescription;
