import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

interface ButtonProps {
  onClick: () => void;
}

export const PencilEditButton: React.FC<ButtonProps> = ({ onClick }) => (
  <button
    className="w-fit text-md-bold text-wtw-logo-blue hover:text-wtw-dark-blue flex items-center gap-1"
    onClick={onClick}
    data-testid="edit-button"
  >
    <PencilSquareIcon className="h-5 w-5" />
    Edit details
  </button>
);

interface LinkProps {
  to: string;
}

export const PencilEditLink: React.FC<LinkProps> = ({ to }) => (
  <Link
    className="w-fit text-md-bold text-wtw-logo-blue hover:text-wtw-dark-blue flex items-center gap-1"
    to={to}
  >
    <PencilSquareIcon className="h-5 w-5" />
    Edit details
  </Link>
);
