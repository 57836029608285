import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { HomeIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface Crumb {
  label: string;
  onClick: () => void;
}

interface Props {
  crumbs: ReadonlyArray<Crumb>;
  enableHome?: boolean;
}

const BreadCrumbs: React.FC<Props> = ({ crumbs, enableHome = true }) => {
  const navigate = useNavigate();
  const navigateHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const renderChevron = (index: number) => {
    if (enableHome || (!enableHome && index !== 0)) {
      return (
        <ChevronRightIcon
          className="h-xl w-xl flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      );
    }
  };

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center">
        {enableHome && (
          <li className="flex">
            <div className="flex items-center">
              <button
                role="link"
                onClick={navigateHome}
                className="text-gray-400 hover:text-gray-500 mr-1"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </button>
            </div>
          </li>
        )}
        {crumbs.map((crumb, idx) => (
          <li key={crumb.label} className="flex">
            <div className="flex items-center">
              {renderChevron(idx)}
              <button
                role="link"
                onClick={crumb.onClick}
                className={classNames(
                  'text-sm font-medium text-gray-500 hover:text-gray-700',
                  {
                    'ml-1': (!enableHome && idx !== 0) || enableHome,
                    underline: idx < crumbs.length - 1,
                  }
                )}
                aria-current={idx === crumbs.length - 1 ? 'page' : undefined}
              >
                {crumb.label}
              </button>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
