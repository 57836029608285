import React from 'react';

import { useAPI } from '../../../../api/api';

interface Props {
  bookingId: string;
}

interface MigrationIssue {
  title: string;
  description: string;
}

const MigrationIssues: React.FC<Props> = ({ bookingId }) => {
  const { data } = useAPI<ReadonlyArray<MigrationIssue>>(
    `/api/admin/bookings/${bookingId}/get-migration-issues/`
  );

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div className="mt-4 rounded-lg border border-2 border-red-500 p-4">
      <h3 className="text-lg font-bold text-red-400">Migration Issues</h3>
      <div className="mt-2 text-sm space-y-2">
        {data.map((issue: MigrationIssue, index: number) => (
          <div key={index} className="rounded">
            <h4 className="font-bold">{issue.title}</h4>
            <p>{issue.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MigrationIssues;
