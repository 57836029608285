import React from 'react';

import { updateDietaryRequirements } from './actions';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { DIETARY_REQUIREMENT_OPTIONS } from '../../../my-trip/about-me/constants';
import { DietaryRequirementsData } from './types';

const DietaryRequirements: React.FC<{
  bookingId: string;
  canEdit: boolean;
}> = ({ bookingId, canEdit }) => {
  return (
    <ManageSingleObject<DietaryRequirementsData>
      title="Dietary requirements"
      titleClassName="text-mob-sh2"
      insideCard={false}
      getUrl={`/api/admin/bookings/${bookingId}/dietary-requirements/get/`}
      submitItem={(values) => updateDietaryRequirements(bookingId, values)}
      descriptionFields={[
        {
          label: 'Dietary requirements:',
          render: (item) => item.dietary_requirements_option?.label,
        },
        {
          label: 'Additional details:',
          render: (item) => item.dietary_requirements_extra_info,
        },
      ]}
      formFields={[
        {
          type: 'local-dropdown',
          name: 'dietary_requirements_option',
          label: 'Dietary requirements:',
          options: DIETARY_REQUIREMENT_OPTIONS,
        },
        {
          type: 'largetext',
          name: 'dietary_requirements_extra_info',
          label: 'Please specify requirements here:',
        },
      ]}
      buttonLabel="Update"
      editButton={canEdit}
    />
  );
};

export default DietaryRequirements;
