import React, { useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { Formik, Form, FormikHelpers } from 'formik';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

import Basket from './basket/Basket';
import TripDetails, { experienceOptions } from './TripDetails';
import PersonalDetails from './PersonalDetails';
import ContactDetails from './ContactDetails';
import StudyDetails from './StudyDetails';
import OtherDetails from './OtherDetails';
import PaymentDetails from './PaymentDetails';
import { Uuid } from '../../../types';
import {
  FormValues,
  BookingFormResponseData,
  BookingFormRequestData,
  GetEnquiryData,
  Experience,
  EducationStatusType,
  PaymentMethodType,
  RegionType,
} from './types';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../framework-components/button/Button';
import {
  EDUCATION_STATUS,
  CURRENCY,
  REGION_TO_CURRENCY,
  CURRENCY_TO_REGION,
  REGION,
} from './constants';
import { useSubmitFormToAPI } from '../../../api/forms';
import Checkbox from '../../../framework-components/select/Checkbox';
import { Validator } from '../../../framework-components/formik-helpers/Validator';

const validationSchema = Yup.object({
  discipline: Yup.object().required('Required'),
  destination: Yup.object().required('Required'),
  start_date: Yup.string().required('Required'),
  duration: Yup.number().min(1).required('Required'),
  experience: Yup.object().nullable(),
  confirm_experience: Yup.object().required('Required'),
  education_status: Yup.string().required('Required'),
  towards_degree: Yup.mixed().when('education_status', ([education_status]) => {
    return education_status === EDUCATION_STATUS.STUDENT
      ? Yup.boolean().required('Required')
      : Yup.boolean().nullable();
  }),
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  date_of_birth: Yup.date().required('Required'),
  gender: Yup.string().required('Required'),
  nationality: Yup.object().required('Required'),
  email: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  line1: Yup.string().required('Required'),
  line2: Yup.string(),
  city: Yup.string().required('Required'),
  county: Yup.string(),
  country: Yup.object().required('Required'),
  postcode: Yup.string().required('Required'),
  university: Yup.mixed().when('unknown_university', ([unknown_university]) => {
    return unknown_university
      ? Yup.object().nullable()
      : Yup.object().required('Required');
  }),
  unknown_university: Yup.string().nullable(),
  university_course: Yup.string().required('Required'),
  year_of_university_study: Yup.mixed().when(
    'education_status',
    ([education_status]) => {
      return education_status === EDUCATION_STATUS.STUDENT
        ? Yup.number().required('Required')
        : Yup.number().nullable();
    }
  ),
  year_of_graduation: Yup.mixed().when(
    'education_status',
    ([education_status]) => {
      return education_status !== EDUCATION_STATUS.STUDENT
        ? Yup.number().required('Required')
        : Yup.number().nullable();
    }
  ),
  notes: Yup.string(),
  payment_method: Yup.string().required('Required'),
  confirm_terms: Yup.boolean().required('Required'),
});

export function useOnSubmit(
  enquiryId?: string
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const navigate = useNavigate();
  const submitForm = useSubmitFormToAPI<
    BookingFormRequestData,
    BookingFormResponseData
  >(
    enquiryId
      ? `/api/booking/update-enquiry/${enquiryId}/`
      : '/api/booking/create-enquiry/'
  );

  return useCallback(
    async (data, formHelpers) => {
      const formData = {
        ...data,
        towards_degree: data.towards_degree ? true : false,
        discipline: data.discipline?.value as Uuid,
        destination: data.destination?.value as Uuid,
        experience: data.experience ? true : false,
        nationality: data.nationality?.value as Uuid,
        country: data.country?.value as Uuid,
        university: data.university ? (data.university.value as Uuid) : null,
        unknown_university: data.university ? '' : data.unknown_university,
      };
      const [hasSucceeded, response] = await submitForm(formData, formHelpers);
      if (hasSucceeded && response) {
        localStorage.setItem('enquiryId', response.id);
        navigate(
          `/booking-review/${response.id}/${
            CURRENCY_TO_REGION[formData.currency]
          }`
        );
      }
      if (!hasSucceeded) {
        toast.error('Failed to submit booking');
      }
    },
    [submitForm, navigate]
  );
}

interface Props {
  initialData?: GetEnquiryData;
}

const BookingForm: React.FC<Props> = ({ initialData }) => {
  const onSubmit = useOnSubmit(initialData?.id);
  const { region } = useParams();
  const navigate = useNavigate();

  const currency = useMemo(() => {
    if (region && region in REGION) {
      return REGION_TO_CURRENCY[region as RegionType];
    }
    if (initialData?.currency) {
      return initialData.currency;
    }
    return CURRENCY.GBP;
  }, [region, initialData?.currency]);

  useEffect(() => {
    const enquiryId = localStorage.getItem('enquiryId');
    if (enquiryId && !initialData?.id) {
      navigate(`/booking-form/${enquiryId}/${CURRENCY_TO_REGION[currency]}`);
    }
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [initialData?.id, navigate, currency]);

  const getConfirmExperienceInitialValue = () => {
    if (!initialData) {
      return null;
    }
    if (initialData.experience_accomodation_type) {
      return experienceOptions[0];
    }
    return experienceOptions[1];
  };

  const initialValues = {
    currency: currency,
    discipline: initialData?.discipline || null,
    destination: initialData?.destination || null,
    start_date: (initialData && new Date(initialData.start_date)) || null,
    duration: (initialData && initialData.hospital_duration) || null,
    experience:
      ((initialData?.experience_accomodation_type &&
        initialData?.experience_total_price && {
          type: initialData.experience_accomodation_type.type,
          price: initialData.experience_total_price,
        }) as Experience) || null,
    confirm_experience: getConfirmExperienceInitialValue(),
    education_status:
      (initialData?.education_status as EducationStatusType) || null,
    towards_degree: initialData?.towards_degree || null,
    first_name: initialData?.first_name || '',
    last_name: initialData?.last_name || '',
    date_of_birth: (initialData && new Date(initialData.date_of_birth)) || null,
    gender: initialData?.gender || 'MALE',
    nationality: initialData?.nationality || null,
    email: initialData?.email || '',
    phone: initialData?.phone || '',
    line1: initialData?.line1 || '',
    line2: initialData?.line2 || '',
    city: initialData?.city || '',
    county: initialData?.county || '',
    country: initialData?.country || null,
    postcode: initialData?.postcode || '',
    university: initialData?.university || null,
    unknown_university: '',
    university_course: initialData?.university_course || '',
    year_of_university_study: initialData?.year_of_university_study || null,
    year_of_graduation: initialData?.year_of_graduation || null,
    notes: initialData?.notes || '',
    payment_method: (initialData?.payment_method as PaymentMethodType) || null,
    confirm_terms: false,
    house: false,
    is_village_experience_village: false,
  };

  const submitForm = useCallback(
    (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
      onSubmit(values, formHelpers);
    },
    [onSubmit]
  );
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ values, setFieldValue, handleSubmit, errors }) => (
        <div>
          <div className="px-md xs:px-lg pb-lg flex flex-col-reverse lg:flex-row justify-start border-b border-wtw-gray-3">
            <>
              <Form className="space-y-8 w-full max-w-[768px] pr-12">
                <div className="px-4">
                  <p className="text-wtw-logo-blue text-md">
                    <span className="mr-2">*</span>Required fields
                  </p>
                </div>
                <Validator />
                <PersonalDetails />
                <TripDetails />
                <ContactDetails />
                <StudyDetails />
                <OtherDetails />
                <PaymentDetails currency={currency} />
                <Checkbox
                  name="confirm_terms"
                  label=""
                  option={{
                    value: 'confirm_terms',
                    label:
                      "By selecting this box, you're agreeing to allow Work the World staff to contact you via email or telephone to discuss your booking.",
                  }}
                  checked={values.confirm_terms}
                  onChange={(name, checked) => setFieldValue(name, checked)}
                />
              </Form>
              <Basket
                currency={currency}
                destination={values.destination}
                discipline={values.discipline?.label}
                duration={values.duration}
                experience={values.experience}
                start_date={
                  values.start_date ? values.start_date.toString() : null
                }
              />
            </>
          </div>
          <div className="p-lg">
            <div className="w-full flex flex-col xs:flex-row justify-center gap-4">
              <SecondaryButton type="button" label="Cancel" />
              <PrimaryButton
                type="button"
                isDisabled={!values.confirm_terms}
                onClick={() => handleSubmit()}
              >
                <div className="flex gap-2">
                  <span>Review order</span>
                  <ArrowRightIcon className="h-6 w-6" />
                </div>
              </PrimaryButton>
            </div>
            {Object.keys(errors).length !== 0 &&
              (!errors.email || errors.email === 'Required') && (
                <div className="text-red-400 font-bold text-sm text-center pt-1">
                  Please complete all required fields
                </div>
              )}
            {Object.keys(errors).length !== 0 &&
              errors.email &&
              values.email !== '' && (
                <div className="text-red-400 text-sm text-center pt-1">
                  {errors.email}
                </div>
              )}
            <p className="text-sm text-center pt-sm">
              You will have a chance to review all the information you've input
              before you make a payment.
            </p>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default BookingForm;
