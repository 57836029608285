import React from 'react';
import { Route } from 'react-router-dom';

import ForgotPassword from './components/auth/forgot-password';
import ForgotPasswordEmailSent from './components/auth/forgot-password-email-sent';
import Login from './components/auth/login';
import ResetPassword from './components/auth/reset-password';
import NotFound from './components/not-found';
import Landing from './components/landing/landing';
import LoggedInLayout from './components/logged-in-layout';
import LoggedOutLayout from './components/logged-out-layout';
import StyleGuide from './components/styleguide';
import UpdateEnquiryPage from './components/booking/booking-form/UpdateEnquiryPage';
import BookingReview from './components/booking/booking-review';
import BookingFormPage from './components/booking/booking-form';
import OrderSummaryPage from './components/booking/order-summary';
import AdminPageContainer from './components/admin/common/AdminPageContainer';
import NewEnquiryList from './components/admin/enquiries/tables/NewEnquiryList';
import OnGoingEnquiryList from './components/admin/enquiries/tables/OnGoingEnquiryList';
import ArchivedEnquiryList from './components/admin/enquiries/tables/ArchivedEnquiryList';
import ApplicantProfilePage from './components/admin/bookings/applicant-profile/ApplicantProfilePage';
import UnsubscribeReminder from './components/booking/unsubscribe-reminder';
import DepartmentCapacity from './components/admin/capacities/department';
import PendingBookings from './components/admin/bookings/booking-list/pending';
import ConfirmedBookings from './components/admin/bookings/booking-list/confirmed';
import OnTripBookings from './components/admin/bookings/booking-list/on-trip';
import MyTripContainer from './components/my-trip/layout/MyTripContainer';
import ClinicalExperience from './components/my-trip/clinical-experience';
import ApplicantArrivals from './components/admin/bookings/booking-list/arrivals';
import ApplicantDepartures from './components/admin/bookings/booking-list/departures';
import HouseAndVillageCapacities from './components/admin/capacities/house';
import UniversityDirectories from './components/admin/directories/universities/UniversityDirectories';
import UnRecognisedUniversityTable from './components/admin/directories/universities/UnRecognisedUniversityTable';
import DestinationsDirectories from './components/admin/directories/destinations/DestinationListing';
import StaffListing from './components/admin/staff/StaffListing';
import AllAlumniListing from './components/alumni/all/AllAlumniListing';
import AlumniSurveyResults from './components/alumni/survey-results/AlumniSurveyResults';
import AlumniAggregateSurveyResults from './components/alumni/aggregate-survey-results/AggregateSurveyResults';
import RoomCapacity from './components/admin/capacities/room';
import HouseDetailPage from './components/admin/directories/houses/HouseDetail';
import UniversityDetail from './components/admin/directories/universities/UniversityDetail';
import Downloads from './components/my-trip/downloads';
import TravelDetails from './components/my-trip/travel-details';
import ToDo from './components/my-trip/to-do';
import AboutMe from './components/my-trip/about-me';
import InfoPack from './components/my-trip/info-pack';
import ClinicalInterest from './components/my-trip/clinical-interest';
import ChangePassword from './components/change-password/ChangePasswordForm';
import InformationPackAdmin from './components/admin/info-pack/InformationPackAdmin';
import AdminNewInfoPackArticle from './components/admin/info-pack/AdminNewInfoPackArticle';
import TripSummaryPage from './components/my-trip/trip-summary/TripSummaryPage';
import MyFinancesPage from './components/my-trip/my-finances/MyFinancesPage';
import StaffProfilePage from './components/admin/staff/StaffProfilePage';
import DocumentsListingPage from './components/admin/directories/documents/DocumentsListingPage';
import EnquiryProfile from './components/admin/enquiries/profile/EnquiryProfile';
import TemplatedResourcesPage from './components/admin/directories/templated-resources/TemplatedResourcesPage';
import EmailLogPage from './components/admin/reporting/EmailLogPage';
import FinanceStatement from './components/admin/finance-statement/FinanceStatement';
import PlacementObjectives from './components/my-trip/placement-objectives';
import Dashboard from './components/admin/dashboard';
import PostponedBookings from './components/admin/bookings/booking-list/postponed';
import HospitalList from './components/admin/directories/hospitals/HospitalList';
import HospitalDetail from './components/admin/directories/hospitals/HospitalDetail';
import Alumni from './components/my-trip/alumni/Alumni';
import HospitalBadges from './components/admin/bookings/booking-list/arrivals/HospitalBadges';
import MyTripAlumniContainer from './components/my-trip/layout/MyTripAlumniContainer';
import SetPassword from './components/auth/set-password';
import BookingReport from './components/admin/reporting/booking-report';

const router = (
  <>
    <Route path="*" element={<NotFound />} />
    <Route path="booking-form" element={<BookingFormPage />} />
    <Route path="booking-form/:region" element={<BookingFormPage />} />
    <Route path="booking-form/:id/:region" element={<UpdateEnquiryPage />} />
    <Route path="booking-review/:uid/:region" element={<BookingReview />} />
    <Route path="order-summary/:id" element={<OrderSummaryPage />} />
    <Route path=":bookingId/finance-statement" element={<FinanceStatement />} />
    <Route path="hospital-badges" element={<HospitalBadges />} />
    <Route
      path="unsubscribe-enquiry-reminder/:id"
      element={<UnsubscribeReminder />}
    />
    <Route element={<LoggedOutLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route
        path="forgot-password-email-sent"
        element={<ForgotPasswordEmailSent />}
      />
      <Route path="reset-password/:uid/:token" element={<ResetPassword />} />
      <Route path="set-password/:uid/:token" element={<SetPassword />} />
    </Route>
    <Route element={<LoggedInLayout />}>
      <Route path="/" element={<Landing />} />
      <Route path="styleguide" element={<StyleGuide />} />
      <Route path="admin" element={<AdminPageContainer />}>
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="enquiries">
          <Route path="new" element={<NewEnquiryList />} />
          <Route path="ongoing" element={<OnGoingEnquiryList />} />
          <Route path="archived" element={<ArchivedEnquiryList />} />
          <Route
            path=":enquiryId/enquiry-profile"
            element={<EnquiryProfile />}
          />
        </Route>
        <Route path="bookings">
          <Route
            path=":bookingId/applicant-profile/"
            element={<ApplicantProfilePage />}
          />
          <Route path="pending" element={<PendingBookings />} />
          <Route path="confirmed" element={<ConfirmedBookings />} />
          <Route path="on-trip" element={<OnTripBookings />} />
          <Route path="postponed" element={<PostponedBookings />} />
          <Route path="arrivals" element={<ApplicantArrivals />} />
          <Route path="departures" element={<ApplicantDepartures />} />
        </Route>
        <Route path="capacities">
          <Route
            path="department-capacities"
            element={<DepartmentCapacity />}
          />
          <Route path="room-capacities" element={<RoomCapacity />} />
          <Route
            path="house-village-capacities"
            element={<HouseAndVillageCapacities />}
          />
        </Route>
        <Route path="reporting">
          <Route path="email-log" element={<EmailLogPage />} />
          <Route path="booking-report" element={<BookingReport />} />
        </Route>
        <Route path="directories">
          <Route path="documents" element={<DocumentsListingPage />} />
          <Route
            path="templated-resources"
            element={<TemplatedResourcesPage />}
          />
          <Route path="universities">
            <Route path="" element={<UniversityDirectories />} />
            <Route path=":id" element={<UniversityDetail />} />
          </Route>
          <Route path="un-recognised-universities">
            <Route path="" element={<UnRecognisedUniversityTable />} />
          </Route>
          <Route path="destinations" element={<DestinationsDirectories />} />
          <Route path="houses">
            <Route path=":id" element={<HouseDetailPage />} />
          </Route>
          <Route path="info-pack">
            <Route path="" element={<InformationPackAdmin />} />
            <Route
              path=":sectionId/:destinationId/new"
              element={<AdminNewInfoPackArticle />}
            />
          </Route>
          <Route path="hospitals">
            <Route path="" element={<HospitalList />} />
            <Route path=":id" element={<HospitalDetail />} />
          </Route>
        </Route>
        <Route path="alumni">
          <Route path="all" element={<AllAlumniListing />} />
          <Route path="survey-results" element={<AlumniSurveyResults />} />
          <Route
            path="aggregate-survey-results"
            element={<AlumniAggregateSurveyResults />}
          />
        </Route>
        <Route path="staff">
          <Route path="staff-listing" element={<StaffListing />} />
          <Route path=":userId/profile" element={<StaffProfilePage />} />
        </Route>
        <Route path="dashboard" element={<Dashboard />} />
      </Route>
      <Route path="my-trip" element={<MyTripContainer />}>
        <Route path="" element={<ToDo />} />
        <Route path="travel-details" element={<TravelDetails />} />
        <Route path="downloads" element={<Downloads />} />
        <Route path="about-me" element={<AboutMe />} />
        <Route path="info-pack" element={<InfoPack />} />
        <Route path="clinical-interests" element={<ClinicalInterest />} />
        <Route path="clinical-experience" element={<ClinicalExperience />} />
        <Route path="placement-objectives" element={<PlacementObjectives />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="trip-summary" element={<TripSummaryPage />} />
        <Route path="my-finances" element={<MyFinancesPage />} />
      </Route>
      <Route path="alumni" element={<MyTripAlumniContainer />}>
        <Route path="" element={<Alumni />} />
      </Route>
    </Route>
  </>
);

export default router;
