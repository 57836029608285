import classNames from 'classnames';
import React, { useState } from 'react';

export interface WidgetContainerProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  hasShadow?: boolean;
  hasBorder?: boolean;
  backgroundColor?: string;
  title?: string;
  collapsible?: boolean;
  contentWhenExpanded?: (collapseFn: () => void) => React.ReactNode;
  contentWhenCollapsed?: React.ReactNode;
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({
  children,
  className,
  hasShadow = true,
  hasBorder = true,
  backgroundColor,
  title,
  collapsible = false,
  contentWhenExpanded,
  contentWhenCollapsed,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsible ? true : false);

  const expandContainer = () => {
    setIsCollapsed(false);
  };

  const collapseContainer = () => {
    setIsCollapsed(true);
  };

  return (
    <div
      onClick={collapsible && isCollapsed ? expandContainer : undefined}
      className={classNames(
        'rounded-xl p-4 relative grid',
        { 'shadow-md': hasShadow },
        { 'bg-white': !backgroundColor },
        { 'border border-wtw-gray-4': hasBorder },
        { 'h-[110px]': isCollapsed },
        backgroundColor,
        className
      )}
    >
      {title && <h1 className="sm:text-sh2 text-sh3">{title}</h1>}
      {children}
      {isCollapsed
        ? contentWhenCollapsed
        : contentWhenExpanded && contentWhenExpanded(collapseContainer)}
    </div>
  );
};

export default WidgetContainer;
