import React from 'react';

import BookingForm from './BookingForm';
import PageWrapper from './PageWrapper';
import { SECTIONS } from './constants';
import BookingFormContainer from '../../admin/common/BookingFormContainer';

export const SUBTITLE_TEXT = `Complete all 5 sections, then move on to the next screens to choose your payment method and see a preview before submitting.
For help or enquiries call 01273 974634 or email info@worktheworld.co.uk
`;

const BookingFormPage: React.FC = () => {
  return (
    <BookingFormContainer>
      <PageWrapper title="BOOK NOW" section={SECTIONS.YOUR_DETAILS}>
        <BookingForm />
      </PageWrapper>
    </BookingFormContainer>
  );
};

export default BookingFormPage;
