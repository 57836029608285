import React, { useCallback } from 'react';

import { Option } from '../../../types';
import FormLabel from './FormLabel';

interface Props {
  name: string;
  label?: string;
  option: Option;
  checked: boolean;
  onChange: (value: string, checked: boolean) => void;
}

const Checkbox: React.FC<Props> = ({
  name,
  label,
  option,
  checked,
  onChange,
}) => {
  const handleChange = useCallback(() => {
    onChange(name, !checked);
  }, [onChange, name, checked]);

  return (
    <fieldset>
      <div className="space-y-2 my-4">
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id={name}
              aria-describedby={`${name}-description`}
              name={name}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-wtw-logo-blue focus:ring-wtw-logo-blue"
              onChange={handleChange}
              checked={checked}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <FormLabel htmlFor={name}>{option.label}</FormLabel>
            {option.description && (
              <p id={`${name}-description`} className="text-gray-500">
                {option.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default Checkbox;
