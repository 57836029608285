import React from 'react';

import ManageMultipleObjects from '../../../../manage-multiple-objects/ManageMultipleObjects';
import { LocationPrice } from './types';
import {
  createLocationPrice,
  updateLocationPrice,
  deleteLocationPrice,
} from './actions';
import Card from '../../../../../framework-components/card/AdminWidgetContainer';
import { FieldType } from '../../../../../framework-components/quickform/Field';
import TestPrice from './TestPrice';

interface Props {
  destinationId: string;
}

const quickFormFields: ReadonlyArray<FieldType> = [
  {
    type: 'smalltext',
    name: 'weeks_at_price',
    label: 'Week Number',
    extraProps: {
      type: 'number',
    },
  },
  {
    type: 'smalltext',
    name: 'gbp_price',
    label: 'Price GBP £',
    extraProps: {
      type: 'number',
      step: 'any',
    },
  },
  {
    type: 'smalltext',
    name: 'usd_price',
    label: 'Price USD $',
    extraProps: {
      type: 'number',
      step: 'any',
    },
  },
  {
    type: 'smalltext',
    name: 'eur_price',
    label: 'Price EUR €',
    extraProps: {
      type: 'number',
      step: 'any',
    },
  },
  {
    type: 'smalltext',
    name: 'aud_price',
    label: 'Price AUD $',
    extraProps: {
      type: 'number',
      step: 'any',
    },
  },
  {
    type: 'smalltext',
    name: 'cad_price',
    label: 'Price CAD $',
    extraProps: {
      type: 'number',
      step: 'any',
    },
  },
];

const LocationPrices: React.FC<Props> = ({ destinationId }) => {
  return (
    <Card hasBorder hasShadow className="overflow-auto">
      <ManageMultipleObjects<LocationPrice>
        title="Location prices:"
        getUrl={`/api/admin/destination/destination-profile/${destinationId}/location-prices/list/`}
        tableFields={[
          {
            name: 'weeks_at_price',
            header: 'Week Number',
            render: (item) => item.weeks_at_price,
          },
          {
            name: 'gbp_price',
            header: 'GBP £',
            render: (item) => item.gbp_price.toFixed(2),
          },
          {
            name: 'usd_price',
            header: 'USD $',
            render: (item) => item.usd_price.toFixed(2),
          },
          {
            name: 'eur_price',
            header: 'EUR €',
            render: (item) => item.eur_price.toFixed(2),
          },
          {
            name: 'aud_price',
            header: 'AUD $',
            render: (item) => item.aud_price.toFixed(2),
          },
          {
            name: 'cad_price',
            header: 'CAN $',
            render: (item) => item.cad_price.toFixed(2),
          },
        ]}
        quickFormFields={quickFormFields}
        addHandler={(values) => createLocationPrice(destinationId, values)}
        editHandler={(values) => updateLocationPrice(destinationId, values)}
        deleteHandler={(values) => deleteLocationPrice(destinationId, values)}
        noDataFoundMessage="No location prices found"
        insideCard={false}
        readonlyEditFields={['weeks_at_price']}
        isFixedTableLayout
        revalidateToMutate
      />
      <TestPrice destinationId={destinationId} />
    </Card>
  );
};

export default LocationPrices;
