import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAPI } from '../../../api/api';
import { Option } from '../../../types';
import LocalDropdown from '../../../framework-components/dropdown/LocalDropdown';
import { SelectedDates } from './types';

interface Props {
  selectedDates: SelectedDates;
  setSelectedDates: React.Dispatch<React.SetStateAction<SelectedDates>>;
  isDisabled: boolean;
}

const monthOptions = [
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  },
];

const periodOptions = [
  {
    value: 31,
    label: '1 month',
  },
  {
    value: 62,
    label: '2 months',
  },
  {
    value: 91,
    label: '3 months',
  },
];

const SelectDates: React.FC<Props> = ({
  selectedDates,
  setSelectedDates,
  isDisabled,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useAPI<ReadonlyArray<Option<number>>>(
    '/api/admin/calendar/date-year-filter-options/'
  );

  return (
    <div className="flex gap-4 flex-wrap">
      <div className="w-48">
        <LocalDropdown
          label="Year"
          name="year"
          isLoading={!data}
          options={data}
          value={
            data?.find((option) => option.value === selectedDates.year) || null
          }
          onSelect={(option: Option<number> | null) => {
            setSelectedDates({
              ...selectedDates,
              year: option?.value,
            });
            if (option) {
              searchParams.set('year', option.value.toString());
              if (searchParams.get('day')) {
                searchParams.set('day', '1');
              }
              setSearchParams(searchParams);
            }
          }}
          zIndex={30}
          isDisabled={isDisabled}
        />
      </div>
      <div className="w-48">
        <LocalDropdown
          label="Month"
          name="month"
          options={monthOptions}
          value={
            monthOptions.find(
              (option) => option.value === selectedDates.month
            ) || null
          }
          onSelect={(option: Option<number> | null) => {
            setSelectedDates({
              ...selectedDates,
              month: option?.value,
            });
            if (option) {
              searchParams.set('month', option.value.toString());
              if (searchParams.get('day')) {
                searchParams.set('day', '1');
              }
              setSearchParams(searchParams);
            }
          }}
          zIndex={30}
          isDisabled={isDisabled}
        />
      </div>
      <div className="w-48">
        <LocalDropdown
          label="Period"
          name="period"
          options={periodOptions}
          value={
            periodOptions.find(
              (option) => option.value === selectedDates.period
            ) || null
          }
          onSelect={(option: Option<number> | null) => {
            setSelectedDates({
              ...selectedDates,
              period: option?.value,
            });
            if (option) {
              searchParams.set('period', option.value.toString());
              setSearchParams(searchParams);
            }
          }}
          zIndex={30}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default SelectDates;
