import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { Option } from '../../types';

export interface QuickSetDropdownProps {
  initialValue?: string | null;
  options: ReadonlyArray<Option>;
  saveValue: (value: string) => Promise<void>;
  selectedStyling?: Record<string, string>;
  notSetLabel?: string;
  isDisabled?: boolean;
}

const DEFAULT_NOT_SET_VALUE = 'NOT_SET';

const QuickSetDropdown: React.FC<QuickSetDropdownProps> = ({
  saveValue,
  initialValue,
  options,
  selectedStyling,
  notSetLabel,
  isDisabled = false,
}: QuickSetDropdownProps) => {
  const [isDirty, setDirty] = useState(false);
  const [value, setValue] = useState(initialValue ?? DEFAULT_NOT_SET_VALUE);
  const [isSaving, setIsSaving] = useState(false);

  const onSelectValue = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setDirty(true);
      setIsSaving(true);

      const targetValue = event.target.value;

      saveValue(targetValue)
        .then(() => {
          toast.success('Successfully updated');
          setIsSaving(false);
          setValue(targetValue);
        })
        .catch(() => {
          toast.error('Could not update');
          setIsSaving(false);
        });
    },
    [setDirty, setIsSaving, setValue, saveValue]
  );

  return (
    <div className="table">
      <div className="relative">
        {isSaving && (
          <>
            <div className="absolute top-0 left-0 w-full h-full bg-blue-200 rounded-full" />
            <div className="absolute top-2 left-0 w-full">
              <p className="text-center">Saving...</p>
            </div>
          </>
        )}

        <select
          disabled={isDisabled}
          className={classNames(
            'rounded-full',
            'min-w-[80px] h-[28px] px-4 py-0 text-sm-semibold',
            selectedStyling?.[value] && selectedStyling[value]
          )}
          value={value}
          onChange={onSelectValue}
          data-testid="quick-set-dropdown"
        >
          {!initialValue && !isDirty && (
            <option disabled value={DEFAULT_NOT_SET_VALUE}>
              {notSetLabel ? notSetLabel : 'Set'}
            </option>
          )}

          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default QuickSetDropdown;
