import React, { useCallback } from 'react';

import { useAPI, apiRequest } from '../api/api';
import QuickSetDropdown from './quick-set-dropdown/QuickSetDropdown';
import { Option } from '../types';

interface Props {
  initialValue: string | null;
  dropdownOptionsEndpoint: string;
  setAssigneeEndpoint: string;
  isDisabled?: boolean;
}

const Assignee: React.FC<Props> = ({
  initialValue,
  dropdownOptionsEndpoint,
  setAssigneeEndpoint,
  isDisabled = false,
}) => {
  const { data } = useAPI<Option[]>(dropdownOptionsEndpoint);
  const saveValue = useCallback(
    (userId: string) =>
      new Promise<void>((resolve, reject) => {
        apiRequest(`${setAssigneeEndpoint}${userId}/`, {
          method: 'POST',
        })
          .then(() => resolve())
          .catch(() => reject());
      }),
    [setAssigneeEndpoint]
  );

  return (
    <QuickSetDropdown
      initialValue={initialValue}
      options={data ?? []}
      saveValue={saveValue}
      notSetLabel="Assign"
      isDisabled={isDisabled}
    />
  );
};

export default Assignee;
