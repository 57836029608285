import React from 'react';

import { AccomodationDetailsTableData } from './types';
import ManageMultipleObjects from '../../../../manage-multiple-objects/ManageMultipleObjects';

interface Props {
  destinationId?: string;
}

const tableFields = [
  {
    name: 'name',
    header: 'Name',
    render: (data: AccomodationDetailsTableData) => (
      <a
        className="text-table-xs"
        href={`/admin/directories/houses/${data.id}/`}
      >
        {data.name}
      </a>
    ),
  },
  {
    name: 'accomodation_type',
    header: 'Type',
    render: (data: AccomodationDetailsTableData) => data.accomodation_type.name,
  },
  {
    name: 'maximum_occupants',
    header: 'Capac.',
    render: (data: AccomodationDetailsTableData) => data.maximum_occupants,
  },
  {
    name: 'warn_at_occupants',
    header: 'Warn.',
    render: (data: AccomodationDetailsTableData) => data.warn_at_occupants,
  },
  {
    name: 'num_rooms',
    header: 'Rooms',
    render: (data: AccomodationDetailsTableData) => data.num_rooms,
  },
  {
    name: 'manager',
    header: 'Manager',
    render: (data: AccomodationDetailsTableData) => data.manager,
  },
  {
    name: 'telephone',
    header: 'Phone',
    render: (data: AccomodationDetailsTableData) => data.telephone,
  },
  {
    name: 'email',
    header: 'Email',
    render: (data: AccomodationDetailsTableData) => {
      return (
        <a
          className="text-table-xs"
          href={`mailto:${data.email}`}
          title={data.email}
        >
          {data.email}
        </a>
      );
    },
  },
];

const Accomodation: React.FC<Props> = ({ destinationId }) => {
  return (
    <ManageMultipleObjects<AccomodationDetailsTableData>
      title="Accomodation:"
      tableFields={tableFields}
      quickFormFields={[]}
      getUrl={`/api/admin/destination/destination-profile/${destinationId}/accommodation/list/`}
      noDataFoundMessage="There is currently no accomodation for this destination."
      hideManageButton
      isFixedTableLayout
    />
  );
};

export default Accomodation;
