import React from 'react';
import { format } from 'date-fns';

import AdaptiveText from '../../AdaptiveText';

interface Props {
  departmentName: string;
  startDate: Date;
  endDate: Date;
  primaryContact: string;
  details: string;
  generalInfo: string;
}

const DepartmentSummary: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <h1 className="text-sh2">My department</h1>
      <p className="text-lg mb-4">
        {props.departmentName} {format(props.startDate, 'MMM d')} to {''}
        {format(props.endDate, 'MMM d')}
      </p>
      <h2 className="text-lg-bold">Primary contact:</h2>
      <p className="text-md mb-4">{props.primaryContact}</p>
      <h2 className="text-lg-bold mb-2">Details:</h2>
      <p className="text-md mb-4">{props.details}</p>
      <h2 className="text-lg-bold mb-2">General info:</h2>
      <AdaptiveText>
        <p className="text-md mb-2">{props.generalInfo}</p>
        <p className="text-md mb-2">{props.generalInfo}</p>
        <p className="text-md mb-2">{props.generalInfo}</p>
      </AdaptiveText>
    </div>
  );
};

export default DepartmentSummary;
