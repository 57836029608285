import React, { useContext } from 'react';

import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { ContactDetailsData } from './types';
import { updateContactDetailsAdmin } from './actions';
import { LoggedInUserContext } from '../../../../context-providers/logged-in-user';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const ContactDetails: React.FC<Props> = ({ bookingId, canEdit }) => {
  const user = useContext(LoggedInUserContext);
  return (
    <div>
      <ManageSingleObject<ContactDetailsData>
        title="Contact Details"
        getUrl={`/api/admin/bookings/${bookingId}/get-contact-details/`}
        submitItem={(values) => updateContactDetailsAdmin(bookingId, values)}
        editButton={
          (canEdit &&
            user &&
            user.permissionsGranted.includes(
              'StudentProfile.EDIT_STUDENT_PROFILE'
            )) ??
          false
        }
        descriptionFields={[
          {
            label: 'Address line 1',
            render: (data: ContactDetailsData) =>
              data.address.line1 ? `${data.address.line1}` : '',
          },
          {
            label: 'Address line 2',
            render: (data: ContactDetailsData) =>
              data.address.line2 ? `${data.address.line2}` : '',
          },
          {
            label: 'City',
            render: (data: ContactDetailsData) =>
              data.address.city ? `${data.address.city}` : '',
          },
          {
            label: 'County',
            render: (data: ContactDetailsData) =>
              data.address.county ? `${data.address.county}` : '',
          },
          {
            label: 'Country',
            render: (data: ContactDetailsData) =>
              data.address.country && data.address.country.value
                ? `${data.address.country.label}`
                : '',
          },
          {
            label: 'Postcode',
            render: (data: ContactDetailsData) =>
              data.address.postcode ? `${data.address.postcode}` : '',
          },
          {
            label: 'Email',
            render: (data: ContactDetailsData) =>
              data.email ? `${data.email}` : '',
          },
          {
            label: 'Phone',
            render: (data: ContactDetailsData) =>
              data.phone ? `${data.phone}` : '',
          },
          {
            label: 'Local phone',
            render: (data: ContactDetailsData) =>
              data.local_phone ? `${data.local_phone}` : '',
          },
        ]}
        formFields={[
          {
            type: 'smalltext',
            name: 'address.line1',
            label: 'Address line 1',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'address.line2',
            label: 'Address line 2',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'address.city',
            label: 'City',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'address.county',
            label: 'County',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'remote-dropdown',
            name: 'address.country',
            label: 'Country',
            path: '/api/booking/countries/',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'address.postcode',
            label: 'Postcode',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'email',
            label: 'Email',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'phone',
            label: 'Phone',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'local_phone',
            label: 'Local phone',
            extraProps: {
              row: true,
            },
          },
        ]}
        buttonLabel="Update"
      />
    </div>
  );
};

export default ContactDetails;
