import {
  EXPERIENCE,
  EDUCATION_STATUS,
  GENDER,
  PAYMENT_METHOD,
} from './constants';
import {
  ExperienceType,
  EducationStatusType,
  GenderType,
  PaymentMethodType,
} from './types';

export const EXPERIENCE_TYPE_TO_DISPLAY_NAME: Record<ExperienceType, string> = {
  [EXPERIENCE.VILLAGE]: 'Village Healthcare Experience',
  [EXPERIENCE.AYURVEDA]: 'Ayurveda experience',
};

export const EDUCATION_STATUS_TO_DISPLAY_NAME: Record<
  EducationStatusType,
  string
> = {
  [EDUCATION_STATUS.STUDENT]: 'Student',
  [EDUCATION_STATUS.PROFESSIONAL]: 'Professional',
};

export const GENDER_TO_DISPLAY_NAME: Record<GenderType, string> = {
  [GENDER.MALE]: 'Male',
  [GENDER.FEMALE]: 'Female',
  [GENDER.OTHER]: 'Other/prefer not to say',
};

export const PAYMENT_METHOD_TO_DISPLAY_NAME: Record<PaymentMethodType, string> =
  {
    [PAYMENT_METHOD.BANK_TRANSFER]: 'Bank Transfer',
    [PAYMENT_METHOD.CARD]: 'Card Payment',
  };
