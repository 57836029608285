import React from 'react';

import { DepartmentAndSupervisorData } from '../types';
import DepartmentDetail from './DepartmentDetail';
import { Option } from '../../../../../types';

interface Props {
  hospitalId: string;
  departments: Array<DepartmentAndSupervisorData>;
  disciplines: Array<Option>;
}

const UpdateDepartments: React.FC<Props> = ({
  hospitalId,
  departments,
  disciplines,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {departments.map((department) => (
        <DepartmentDetail
          key={department.id}
          hospitalId={hospitalId}
          department={department}
          disciplines={disciplines}
        />
      ))}
    </div>
  );
};

export default UpdateDepartments;
