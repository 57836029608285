import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import Modal from '../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';
import { archiveBooking, unArchiveBooking } from './actions';
import { BasicBookingApplicantProfileInfo } from './types';

interface Props {
  bookingId: string;
  isArchived: boolean;
  isOpen: boolean;
  closeModal: () => void;
}

const ArchiveBookingModal: React.FC<Props> = ({
  bookingId,
  isArchived,
  isOpen,
  closeModal,
}) => {
  const focusRef = React.useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleArchive = async () => {
    setIsLoading(true);
    try {
      await archiveBooking(bookingId);
      toast.success('Booking postponed successfully');
      mutate(
        `/api/admin/bookings/${bookingId}/basic-profile-page-info/`,
        (data: BasicBookingApplicantProfileInfo) => ({
          ...data,
          is_active: false,
        }),
        false
      );
      closeModal();
    } catch (error) {
      toast.error('Failed to postpone booking');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnArchive = async () => {
    setIsLoading(true);
    try {
      await unArchiveBooking(bookingId);
      toast.success('Booking reactivated successfully');
      mutate(
        `/api/admin/bookings/${bookingId}/basic-profile-page-info/`,
        (data: BasicBookingApplicantProfileInfo) => ({
          ...data,
          is_active: true,
        }),
        false
      );
      closeModal();
    } catch (error) {
      toast.error('Failed to reactivate booking');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4" data-testid="delete-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          {isArchived ? 'Unarchive booking' : 'Archive booking'}
        </Dialog.Title>
        {isArchived ? (
          <p>Are you sure you want to reactivate the booking?</p>
        ) : (
          <p>Are you sure you want to archive the booking?</p>
        )}
        <div className="flex justify-end space-x-4">
          <SecondaryButton onClick={closeModal} isDisabled={isLoading}>
            Cancel
          </SecondaryButton>

          <PrimaryButton
            isDisabled={isLoading}
            onClick={isArchived ? handleUnArchive : handleArchive}
          >
            {isArchived ? 'Unarchive booking' : 'Archive booking'}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ArchiveBookingModal;
