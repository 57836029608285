import React from 'react';

import ManageMultipleObjects from '../../../../manage-multiple-objects/ManageMultipleObjects';
import { Capacity } from './types';
import { updateCapacity } from './actions';

interface Props {
  destinationId: string;
}

const Capacities: React.FC<Props> = ({ destinationId }) => {
  return (
    <ManageMultipleObjects<Capacity>
      title="Maximum capacities:"
      getUrl={`/api/admin/destination/destination-profile/${destinationId}/capacities/list/`}
      tableFields={[
        {
          name: 'discipline.name',
          header: 'Discipline',
          render: (item) => item.discipline.name,
        },
        {
          name: 'capacity',
          header: 'Capacity',
          render: (item) => item.capacity,
        },
      ]}
      quickFormFields={[
        {
          name: 'capacity',
          label: 'Capacity',
          type: 'smalltext',
          extraProps: {
            type: 'number',
          },
        },
      ]}
      editHandler={updateCapacity}
      noDataFoundMessage="No capacities found"
    />
  );
};

export default Capacities;
