import React from 'react';
import classNames from 'classnames';

import { GetUserIdData } from './types';
import { useAPI } from '../../../../api/api';
import ViewAsIcon from '../../../icons/ViewAsIcon';

interface Props {
  bookingId?: string;
  userName?: string;
}

const ImpersonateLoginSection: React.FC<Props> = ({ bookingId, userName }) => {
  const { data } = useAPI<GetUserIdData>(
    `/api/admin/bookings/${bookingId}/get-user-id/`
  );

  if (!data) return null;

  const userId = data.user_id;
  const impersonateUrl = userId ? `/impersonate/${userId}/` : null;

  return (
    <div
      className={classNames('flex flex-wrap space-x-2')}
      title={!impersonateUrl ? 'Applicant does not have a user.' : ''}
    >
      <div>View as: {userName}</div>
      <a href={impersonateUrl || '#'} className="flex">
        <ViewAsIcon />
      </a>
    </div>
  );
};

export default ImpersonateLoginSection;
