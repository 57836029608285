import React from 'react';
import { mutate } from 'swr';

import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { ClinicalExperienceData } from './types';
import { updateClinicalExperience } from './actions';

interface Props {
  bookingId: string;
  canEdit: boolean;
}

const ClinicalExperience: React.FC<Props> = ({ bookingId, canEdit }) => {
  return (
    <ManageSingleObject<ClinicalExperienceData>
      title="Clinical experience:"
      titleClassName="text-mob-sh2"
      getUrl={`/api/admin/bookings/${bookingId}/get-clinical-experience/`}
      onSubmitSuccess={() => {
        mutate(`/api/admin/bookings/${bookingId}/get-education-tab-warning/`);
      }}
      editButton={canEdit}
      alternativeRender={(data) => (
        <div className="flex flex-col min-h-[44px] bg-wtw-gray-2 p-4 rounded-lg">
          <div className="text-gray-900 text-xs whitespace-pre-line">
            {data.clinical_experience}
          </div>
        </div>
      )}
      formFields={[
        {
          type: 'largetext',
          name: 'clinical_experience',
          label: 'Clinical experience',
          extraProps: {
            rows: 5,
          },
        },
      ]}
      insideCard={false}
      submitItem={(values) => updateClinicalExperience(bookingId, values)}
    />
  );
};

export default ClinicalExperience;
