import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import classNames from 'classnames';

import { File, ApprovalStatus } from './types';
import { setApplicantFile, updateFileStatus, deleteFile } from './actions';
import ManageSingleImage from '../../../manage-single-image/ManageSingleImage';
import { SelectButton } from '../../../../framework-components/button/Button';
import UploadModal from '../../common/UploadModal';
import DeleteConfirmationModal from '../../common/modal/DeleteConfirmationModal';
import useModalState from '../../../../hooks/modals';
import { download } from '../../../../utils/download';

type Status = ApprovalStatus | 'NOT_UPLOADED';

const StatusLabelMap: Record<Status, string> = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  NOT_UPLOADED: 'Not uploaded',
};

const StatusPill = ({ status }: { status: Status }) => {
  return (
    <div
      className={classNames(
        'rounded-full w-full p-2 flex items-center justify-center text-sm',
        {
          'bg-green-200': status === 'APPROVED',
          'bg-red-200': status === 'PENDING',
          'bg-gray-200': status === 'NOT_UPLOADED',
        }
      )}
    >
      <p>{StatusLabelMap[status]}</p>
    </div>
  );
};

interface Props<T> {
  field: keyof T;
  title?: string;
  file: File | null;
  canEdit: boolean;
  setUrl: string;
  getUrl: string;
  mutateTabUrl?: string;
}

const ApplicantFile = <T,>({
  field,
  title,
  file,
  canEdit,
  setUrl,
  getUrl,
  mutateTabUrl,
}: Props<T>) => {
  const {
    isOpen: isUploadModalOpen,
    openModal: openUploadModal,
    closeModal: closeUploadModal,
  } = useModalState();

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModalState();

  const handleUploadFile = useCallback(
    async (fileId: string) => {
      try {
        await setApplicantFile(setUrl, String(field), fileId);
        mutate(getUrl);
        toast.success('File updated');
      } catch (e) {
        toast.error('Failed to update file');
      }
    },
    [setUrl, getUrl, field]
  );

  const handleUpdatePhotoStatus = useCallback(
    async (photoId: string, status: ApprovalStatus) => {
      try {
        await updateFileStatus(photoId, status);
        mutate(
          getUrl,
          (data: T) => ({
            ...data,
            [field]: {
              ...data[field],
              approval_status: status,
            },
          }),
          false
        );
        if (mutateTabUrl) {
          mutate(mutateTabUrl);
        }
        toast.success('File status updated');
      } catch (e) {
        toast.error('Failed to update file status');
      }
    },
    [getUrl, mutateTabUrl, field]
  );

  const handleDeletePhoto = useCallback(async () => {
    if (!file) {
      return;
    }
    try {
      await deleteFile(file.id);
      mutate(
        getUrl,
        (data: T) => ({
          ...data,
          [field]: null,
        }),
        false
      );
      toast.success('File deleted');
      closeDeleteModal();
    } catch (e) {
      toast.error('Failed to delete file');
    }
  }, [file, getUrl, field, closeDeleteModal]);

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'upload') {
        openUploadModal();
      } else if (event.target.value === 'download') {
        if (file) {
          download(file.url, file.filename);
        }
      } else if (event.target.value === 'approve') {
        if (file) {
          handleUpdatePhotoStatus(file.id, 'APPROVED');
        }
      } else if (event.target.value === 'deny') {
        if (file) {
          openDeleteModal();
        }
      }
      event.target.value = '';
    },
    [file, openUploadModal, openDeleteModal, handleUpdatePhotoStatus]
  );

  const renderOptions = () => (
    <>
      {canEdit && (
        <option value="upload" key={`${title}-upload`}>
          Upload
        </option>
      )}
      {file && (
        <>
          <option value="download" key={`${title}-download`}>
            Download
          </option>
          {canEdit && file.approval_status === 'PENDING' && (
            <>
              <option value="approve" key={`${title}-approve`}>
                Approve
              </option>
              <option value="deny" key={`${title}-deny`}>
                Deny
              </option>
            </>
          )}
          {canEdit && file.approval_status === 'APPROVED' && (
            <option value="deny" key={`${title}-deny`}>
              Delete
            </option>
          )}
        </>
      )}
      <UploadModal
        isOpen={isUploadModalOpen}
        closeModal={closeUploadModal}
        title="Upload file"
        onSuccessfulUpload={handleUploadFile}
        acceptedExtensions={['png', 'jpg', 'webp', 'pdf']}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        handleDelete={handleDeletePhoto}
      />
    </>
  );

  return (
    <div className="mt-4 sm:mt-0 flex flex-col gap-4">
      <ManageSingleImage
        title={title}
        getCurrentImageUrl={() => Promise.resolve(file?.url ?? null)}
        onUploadNewImage={handleUploadFile}
        canEdit={canEdit}
        resizeImage
      />
      {(file || canEdit) && (
        <SelectButton
          onChange={changeHandler}
          renderOptions={() => renderOptions()}
          defaultLabel="Actions"
        />
      )}
      <StatusPill status={file?.approval_status ?? 'NOT_UPLOADED'} />
    </div>
  );
};

export default ApplicantFile;
