import React, { useContext } from 'react';

import { CalendarLock } from './types';
import { LoggedInUserContext } from '../../../context-providers/logged-in-user';

interface Props {
  calendarLock: CalendarLock | undefined;
}

const LockBanner: React.FC<Props> = ({ calendarLock }) => {
  const user = useContext(LoggedInUserContext);
  const isLockedByCurrentUser = calendarLock?.user?.id === user?.id;
  if (!calendarLock?.is_locked) {
    return null;
  }
  return (
    <div className="bg-red-400 p-2 w-full text-center text-sm font-semibold">
      {isLockedByCurrentUser ? (
        <p>The calendar is locked to other users.</p>
      ) : (
        <p>
          This calendar is current being edited by {calendarLock.user?.name}.
          Refresh to see the final changes.
        </p>
      )}
    </div>
  );
};

export default LockBanner;
