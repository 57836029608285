import React, { useCallback } from 'react';
import { FormikProps } from 'formik';
import DatePicker from 'react-datepicker';

import { Option } from '../../../../types';
import Input from '../Input';
import TextArea from '../TextArea';
import LocalDropdown from '../dropdown/LocalDropdown';
import RemoteDropdown from '../dropdown/RemoteDropdown';
import Checkbox from '../Checkbox';
import MultiChoice from '../MultiChoice';
import Radio from '../Radio';
import ErrorText from '../../../error-text';

export interface FieldType {
  type:
    | 'smalltext'
    | 'largetext'
    | 'local-dropdown'
    | 'remote-dropdown'
    | 'checkbox'
    | 'multi-choice'
    | 'radio'
    | 'datetime';
  name: string;
  label?: string;
  option?: Option;
  options?:
    | ReadonlyArray<Option | Option<number>>
    | Array<Option | Option<number>>;
  path?: string;
  onSelect?: (option: Option) => void;
  extraProps?: Record<string, unknown>;
}

interface Props<T> extends FieldType {
  value: string | number | boolean | string[] | Option | Date | null;
  formik: FormikProps<T>;
  errors: string | string[];
}

function Field<T>({
  type,
  name,
  label,
  value,
  options,
  option,
  path,
  extraProps,
  formik,
  errors,
}: Props<T>) {
  const { handleChange } = formik;

  const dropdownSelect = useCallback(
    (item: Option | null) => {
      formik.setFieldValue(name, item);
    },
    [formik, name]
  );

  const checkboxSelect = useCallback(
    (_: string, checked: boolean) => {
      formik.setFieldValue(name, checked);
    },
    [formik, name]
  );

  const multiChoiceSelect = useCallback(
    (values: string[]) => {
      formik.setFieldValue(name, values);
    },
    [formik, name]
  );

  const radioSelect = useCallback(
    (radioValue: string | number) => {
      formik.setFieldValue(name, radioValue);
    },
    [formik, name]
  );

  const renderField = () => {
    switch (type) {
      case 'smalltext':
        return (
          <Input
            label={label}
            id={name}
            name={name}
            type="text"
            onChange={handleChange}
            defaultValue={value as string}
            {...extraProps}
          />
        );
      case 'largetext':
        return (
          <TextArea
            label={label}
            id={name}
            name={name}
            value={value as string}
            {...extraProps}
            onChange={handleChange}
          />
        );
      case 'local-dropdown':
        if (!options) {
          return null;
        }
        // eslint-disable-next-line
        const localDropdownValue =
          typeof value === 'object'
            ? value
            : options.find((o) => o.value === value);
        return (
          <LocalDropdown
            label={label}
            value={localDropdownValue as Option}
            id={name}
            name={name}
            options={options as Option[]}
            onSelect={dropdownSelect}
            {...extraProps}
          />
        );
      case 'remote-dropdown':
        if (!path) {
          return null;
        }
        return (
          <RemoteDropdown
            label={label}
            value={value as Option}
            id={name}
            name={name}
            path={path}
            onSelect={dropdownSelect}
            {...extraProps}
          />
        );
      case 'checkbox':
        if (!option) {
          return null;
        }
        const val = value as boolean; // eslint-disable-line
        return (
          <Checkbox
            name={name}
            label={label}
            option={option}
            checked={val}
            onChange={checkboxSelect}
            {...extraProps}
          />
        );
      case 'multi-choice':
        if (!options) {
          return null;
        }
        const vals = value as string[]; // eslint-disable-line
        return (
          <MultiChoice
            name={name}
            label={label}
            options={options as Option[]}
            onChange={multiChoiceSelect}
            currentValues={vals}
            {...extraProps}
          />
        );
      case 'radio':
        if (!options) {
          return null;
        }
        return (
          <Radio
            name={name}
            label={label}
            options={options as Option[]}
            onChange={radioSelect}
            {...extraProps}
          />
        );
      case 'datetime':
        if (typeof value === 'string') {
          value = new Date(value);
        }
        if (value !== null && value !== undefined && !(value instanceof Date)) {
          return null;
        }
        return (
          <>
            <label
              htmlFor={name}
              className="block text-sm font-medium text-gray-900 -mb-2"
            >
              {label}
            </label>
            <DatePicker
              id={name}
              selected={value}
              onChange={(date: Date) => {
                formik.setFieldValue(name, date);
              }}
              placeholderText="Please select"
              dateFormat={(extraProps?.dateFormat as string) ?? 'dd/MM/yyyy'}
              {...extraProps}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderField()}
      <ErrorText className="text-sm">{errors}</ErrorText>
    </>
  );
}

export default Field;
