import React from 'react';
import { mutate } from 'swr';
import { FormikValues } from 'formik';

import { useAPI } from '../../api/api';
import useModalState from '../../hooks/modals';
import ErrorOverlay from '../my-trip/components/ErrorOverlay';
import LoadingOverlay from '../../framework-components/loading/LoadingOverlay';
import { PencilEditButton } from '../../framework-components/button/PencilEditButton';
import EditDetailsModal from '../manage-single-object/EditDetailsModal';

interface Props<T> {
  getUrl: string | null;
  submitItem: (data: T) => Promise<T>;
  onSubmitSuccess?: (data: T) => void;
  fieldName: string;
  render: (item: T) => React.ReactNode;
}

const ManageLongText = <T extends FormikValues>({
  getUrl,
  submitItem,
  onSubmitSuccess,
  fieldName,
  render,
}: Props<T>) => {
  const { data, isValidating, error } = useAPI<T>(getUrl);
  const { isOpen, openModal, closeModal } = useModalState();

  const onSubmitSuccessHandler = (values: T) => {
    mutate(getUrl, values, false);
    if (onSubmitSuccess) {
      onSubmitSuccess(values);
    }
  };

  return (
    <div className="space-y-6 gap-6 relative w-full h-fit">
      {error && <ErrorOverlay />}
      {isValidating && <LoadingOverlay />}

      {data && (
        <div className="flex gap-6 items-center py-1">
          <dd className="text-gray-900 text-xs">{render(data)}</dd>
        </div>
      )}

      <PencilEditButton onClick={openModal} />

      {data && (
        <EditDetailsModal<T>
          isOpen={isOpen}
          closeModal={closeModal}
          submitItem={submitItem}
          onSubmitSuccess={onSubmitSuccessHandler}
          data={data}
          fields={[
            {
              name: fieldName,
              type: 'largetext',
            },
          ]}
        />
      )}
    </div>
  );
};

export default ManageLongText;
