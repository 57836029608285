import React, { useCallback, useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import UploadImageModal from '../../../admin/common/UploadModal';
import useModalState from '../../../../hooks/modals';
import LoadingOverlay from '../LoadingOverlay';
import ErrorOverlay from '../ErrorOverlay';
import PlaceholderImage from '../../../admin/common/ImageGallery/PlaceholderImage';
import Modal from '../../../modal';

interface Props {
  title?: string;
  infoIconText?: string;
  getCurrentImageUrl: () => Promise<string | null>;
  onUploadNewImage: (fileId: string) => Promise<void>;
  resizeImage: boolean;
  alternatePlaceHolderImageSrc?: string;
  canEdit?: boolean | null;
}

const ManageSingleImage = ({
  title,
  infoIconText,
  resizeImage,
  getCurrentImageUrl,
  onUploadNewImage,
  alternatePlaceHolderImageSrc,
  canEdit = true,
}: Props) => {
  const { isOpen, openModal, closeModal } = useModalState();
  const {
    isOpen: infoIconTextModalIsOpen,
    openModal: openInfoIconTextModal,
    closeModal: closeInfoIconTextModal,
  } = useModalState();

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [photo, setPhoto] = useState<string | null>(null);

  const handleGetImages = useCallback(async () => {
    return getCurrentImageUrl()
      .then(setPhoto)
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getCurrentImageUrl]);

  const handleUploadImage = useCallback(
    async (fileId: string) => {
      setIsLoading(true);
      return onUploadNewImage(fileId)
        .then(() => {
          handleGetImages();
        })
        .catch((e) => {
          setError(e.message);
          setIsLoading(false);
        })
        .finally(() => {
          closeModal();
        });
    },
    [onUploadNewImage, handleGetImages, closeModal]
  );

  useEffect(() => {
    handleGetImages();
  }, [handleGetImages]);

  const renderTitleAndInfoIcon = () => {
    return (
      <>
        {title && (
          <h1 className="text-sm mb-1">
            {title}{' '}
            {infoIconText && (
              <span
                className="text-gray-500 text-sm text-wtw-logo-blue"
                onClick={openInfoIconTextModal}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-gray-500 text-sm text-wtw-logo-blue"
                />
              </span>
            )}
          </h1>
        )}
      </>
    );
  };

  const renderImage = () => {
    if (isLoading) {
      return (
        <div className="relative h-full w-full overflow-auto">
          <LoadingOverlay />
        </div>
      );
    }

    if (error) {
      return (
        <div className="relative h-full w-full overflow-auto">
          <ErrorOverlay detail={error} />
        </div>
      );
    }
    if (photo) {
      return (
        <img
          className="rounded-lg h-full w-full object-cover cursor-pointer"
          src={photo}
          alt="a photo"
          onClick={openModal}
        />
      );
    }
    if (alternatePlaceHolderImageSrc) {
      return (
        <img
          src={alternatePlaceHolderImageSrc}
          height="100%"
          width="auto"
          className={classNames({ 'cursor-pointer': canEdit })}
          onClick={canEdit ? openModal : () => {}}
        />
      );
    }
    return (
      <PlaceholderImage
        className={classNames({ 'cursor-pointer': canEdit })}
        onClick={canEdit ? openModal : () => {}}
      />
    );
  };

  return (
    <>
      {renderTitleAndInfoIcon()}
      {renderImage()}

      <UploadImageModal
        resizeImage={resizeImage}
        title={title ?? 'Upload image'}
        isOpen={isOpen}
        closeModal={closeModal}
        onSuccessfulUpload={handleUploadImage}
      />

      {infoIconText && (
        <Modal
          isOpen={infoIconTextModalIsOpen}
          onClose={closeInfoIconTextModal}
        >
          <div className="space-y-4">
            <div className="text-sm">{infoIconText}</div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManageSingleImage;
