import React from 'react';
import { toast } from 'react-toastify';

import NotesPanel from '../../framework-components/notes-panel/NotesPanel';

const NotesPanelExample = () => {
  const onClickActionEventHandler = (message: string) => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        toast.success(message);
        resolve();
      }, 500);
    });
  };

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Notes Panel</h2>
      <NotesPanel onClickAction={onClickActionEventHandler} />
    </div>
  );
};

export default NotesPanelExample;
