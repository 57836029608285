import React from 'react';
import { CalculatorIcon, CheckIcon } from '@heroicons/react/24/outline';

import {
  Experience,
  CurrencyType,
  GetDestinationPriceResponse,
} from '../types';
import { Uuid } from '../../../../types';
import { useAPI } from '../../../../api/api';
import { EXPERIENCE_TYPE_TO_DISPLAY_NAME } from '../labels';
import { formatDateToReadableString } from '../../../../utils/dates';
import { CURRENCY_TO_SYMBOL } from '../constants';

const TripItem: React.FC<{ label: string }> = ({ label }) => (
  <div className="flex items-center justify-between">
    <p className="text-sm">{label}</p>
    <div className="rounded-full bg-wtw-logo-blue p-1">
      <CheckIcon className="w-3 h-3 text-white" strokeWidth={4} />
    </div>
  </div>
);

export const TripItems: React.FC<{
  experience: Experience | null;
  discipline: string | null | undefined;
  destination: string | null;
  placement: { duration: number; price: number } | null;
  start_date: string | null;
}> = ({ experience, discipline, destination, placement, start_date }) => {
  const totalDuration = (placement?.duration || 0) + (experience ? 1 : 0);
  const hospitalDurationIsOneWeek = placement?.duration === 1;

  return (
    <div className="space-y-2">
      <h3 className="text-h5">Placement summary:</h3>
      <div className="space-y-2">
        {discipline && <TripItem label={discipline} />}
        {destination && <TripItem label={destination} />}
        {start_date && (
          <TripItem
            label={`Arrival date: ${formatDateToReadableString(start_date)}`}
          />
        )}
        {placement && (
          <TripItem
            label={`Hospital placement: ${placement.duration} ${
              hospitalDurationIsOneWeek ? 'week' : 'weeks'
            }`}
          />
        )}
        {experience && (
          <TripItem
            label={`${
              EXPERIENCE_TYPE_TO_DISPLAY_NAME[experience.type]
            }: 1 week`}
          />
        )}
        {(destination || experience) && (
          <TripItem
            label={`Total duration: ${totalDuration} ${
              totalDuration === 1 ? 'week' : 'weeks'
            }`}
          />
        )}
      </div>
    </div>
  );
};

export const TripCosts: React.FC<{
  currency: CurrencyType;
  experience: Experience | null;
  placement: { duration: number; price: number } | null;
}> = ({ currency, experience, placement }) => {
  return (
    <div className="space-y-2">
      <h3 className="text-h5">Costs:</h3>
      {placement && (
        <div className="flex justify-between text-sm">
          <p>Hospital placement:</p>
          <p className="font-bold">
            {CURRENCY_TO_SYMBOL[currency]}
            {placement.price / 100}
          </p>
        </div>
      )}
      {experience && (
        <div className="flex justify-between text-sm">
          <p>{EXPERIENCE_TYPE_TO_DISPLAY_NAME[experience.type]}:</p>
          <p className="font-bold">
            {CURRENCY_TO_SYMBOL[currency]}
            {experience.price / 100}
          </p>
        </div>
      )}
    </div>
  );
};

const TripCalculator: React.FC<{
  currency: CurrencyType;
  destination: { label: string; value: Uuid } | null;
  discipline: string | undefined | null;
  duration: number | null;
  experience: Experience | null;
  start_date: string | null;
}> = ({
  currency,
  destination,
  discipline,
  duration,
  experience,
  start_date,
}) => {
  const { data, error } = useAPI<GetDestinationPriceResponse>(
    destination?.value && duration
      ? `/api/booking/destination/${destination.value}/price/?duration=${duration}&currency=${currency}`
      : null
  );

  const placement =
    data && duration
      ? {
          duration: duration,
          price: data.price,
        }
      : null;

  const totalCost =
    (placement?.price ? placement.price / 100 : 0) +
    (experience?.price ? experience.price / 100 : 0);

  return (
    <div className="rounded-t-lg md:sticky md:top-0 pt-9">
      <div className="border bg-wtw-gray-2 p-md rounded-t-lg space-y-2xl">
        <div className="flex gap-4 justify-between">
          <h2 className="text-h4">
            Your trip cost
            <br /> calculator
          </h2>
          <CalculatorIcon className="w-8 h-8 xs:w-9 xs:h-9 sm:w-10 sm:h-10" />
        </div>
        <p className="text-sm max-w-[300px]">
          This balance is not due until 90 days before your departure.
        </p>
        <div>
          {error ? (
            <p className="text-sm">There was an error fetching the price...</p>
          ) : (
            <div className="space-y-2xl">
              <TripItems
                discipline={discipline}
                destination={destination ? destination.label : null}
                placement={placement}
                experience={experience}
                start_date={start_date}
              />
              {(placement || experience) && (
                <TripCosts
                  currency={currency}
                  placement={placement}
                  experience={experience}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="p-md bg-wtw-gray-4 rounded-b-lg">
        <div className="flex justify-between text-h5">
          <h3>Total cost:</h3>
          <p>
            {CURRENCY_TO_SYMBOL[currency]}
            {totalCost}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TripCalculator;
