import React from 'react';

import { useAPI } from '../../../../api/api';
import { DepartmentAndSupervisorData } from './types';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import Table from '../../../manage-multiple-objects/Table';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import ManageDepartmentsModal from './manage-departments/ManageDepartmentsModal';
import { Option } from '../../../../types';

interface Props {
  hospitalId: string;
}

const DepartmentsAndSupervisors: React.FC<Props> = ({ hospitalId }) => {
  const { data, error, isValidating } = useAPI<
    Array<DepartmentAndSupervisorData>
  >(`/api/admin/hospital/${hospitalId}/departments-and-supervisors/get/`);

  const {
    data: disciplines,
    isValidating: isValidatingDisciplines,
    error: errorDisciplines,
  } = useAPI<Array<Option>>(`/api/booking/disciplines/`);

  const { isOpen, openModal, closeModal } = useModalState();

  const renderTable = () => {
    if (isValidating || isValidatingDisciplines) {
      return <LoadingOverlay />;
    }

    if (error || errorDisciplines) {
      return <ErrorOverlay />;
    }

    if (data) {
      return (
        <Table<DepartmentAndSupervisorData>
          data={data}
          tableFields={[
            {
              header: 'Department',
              name: 'name',
              render: (item) => (
                <span title={item.description}>{item.name}</span>
              ),
            },
            {
              header: 'Main supervisor',
              name: 'supervisor',
              render: (item) => item.supervisor,
            },
            {
              header: 'Capacity',
              name: 'capacity',
              render: (item) => item.capacity,
            },
          ]}
        />
      );
    }
  };

  return (
    <AdminWidgetContainer
      className="bg-white max-w-[1120px] overflow-x-scroll sm:overflow-x-clip"
      title="Departments & Supervisors"
    >
      <div className="mt-6">{renderTable()}</div>
      <div className="mt-6">
        <PrimaryButton onClick={openModal}>Manage</PrimaryButton>
      </div>
      {data && disciplines && (
        <ManageDepartmentsModal
          hospitalId={hospitalId}
          departments={data}
          isOpen={isOpen}
          closeModal={closeModal}
          disciplines={disciplines}
        />
      )}
    </AdminWidgetContainer>
  );
};

export default DepartmentsAndSupervisors;
