import React from 'react';
import classNames from 'classnames';

import { Note } from '../../components/admin/common/types';
import NoteTag from './NoteTag';
import { formatDateTimeShorthandMonthDayYear } from '../../utils/dates';
import { LabelValue } from '../../types';

type PickedNoteProps = Pick<Note, 'author' | 'created' | 'content'>;

export interface NotePreviewProps extends PickedNoteProps {
  isSelected?: boolean;
  tag?: LabelValue;
  onClick?: () => void;
  tagColorOverrideMap?: Record<string, string>;
}

const NotePreview: React.FC<NotePreviewProps> = ({
  isSelected,
  author,
  created,
  content,
  tag,
  onClick,
  tagColorOverrideMap,
}) => {
  const renderTags = () => {
    if (!tag) return null;

    return (
      <NoteTag
        tag={{
          ...tag,
          backgroundColor: tagColorOverrideMap?.[tag.label],
        }}
      />
    );
  };

  return (
    <div
      className={classNames(
        'relative p-3 rounded-lg after:content-[""] after:absolute after:w-3/4 after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:h-px after:bg-wtw-gray-4',
        {
          'cursor-pointer transition-all hover:bg-wtw-gray-3': !isSelected,
        },
        { 'bg-wtw-gray-3': isSelected }
      )}
      onClick={onClick}
    >
      <p className="text-xs-bold">
        From: {author ? `${author.first_name} ${author.last_name}` : 'Customer'}
      </p>
      <p className="text-xs-bold relative top-[-4px]">
        {formatDateTimeShorthandMonthDayYear(created)}
      </p>
      <p className="text-xs pb-1 text-ellipsis overflow-hidden line-clamp-4">
        {content}
      </p>

      {renderTags()}
    </div>
  );
};

export default NotePreview;
