import React from 'react';

import { RoundedSmallButtonOutline } from '../../../../../framework-components/button/Button';
import useModalState from '../../../../../hooks/modals';
import MarkPaidModal from './MarkPaidModal';
import { formatDateToDayDateMonthYear } from '../../../../../utils/dates';

interface Props {
  bookingId: string;
  reloadData: () => void;
}

const MarkPaidButton: React.FC<Props> = ({ bookingId, reloadData }) => {
  const { isOpen, openModal, closeModal } = useModalState();
  const [datePaid, setDatePaid] = React.useState<Date | null>(null);

  const labelText = 'Mark as paid';

  return (
    <div>
      {datePaid ? (
        formatDateToDayDateMonthYear(datePaid.toUTCString())
      ) : (
        <>
          <MarkPaidModal
            isOpen={isOpen}
            closeModal={closeModal}
            onPaid={(paymentDate) => setDatePaid(paymentDate)}
            bookingId={bookingId}
            reloadData={reloadData}
          />
          <RoundedSmallButtonOutline
            label={labelText}
            onClick={openModal}
            title={labelText}
            textClassName="text-sm-semibold"
          />
        </>
      )}
    </div>
  );
};

export default MarkPaidButton;
