import React from 'react';
import { FormikValues } from 'formik';
import classNames from 'classnames';

import { TableField } from './types';
import { FieldType } from '../../framework-components/quickform/Field';
import Row from './Row';

interface Props<T> {
  data: ReadonlyArray<T>;
  tableFields: ReadonlyArray<TableField<T>>;
  quickFormFields?: ReadonlyArray<FieldType>;
  editHandler?: (values: T) => Promise<T>;
  deleteHandler?: (values: T) => Promise<T>;
  onEditSuccess?: (data: T) => void;
  onDeleteSuccess?: (data: T) => void;
  renderRowColor?: (data: T) => string;
  isFixedTableLayout?: boolean;
}

const Table = <T extends FormikValues>({
  data,
  tableFields,
  quickFormFields,
  editHandler,
  deleteHandler,
  onEditSuccess,
  onDeleteSuccess,
  renderRowColor,
  isFixedTableLayout = false,
}: Props<T>) => {
  const numberOfTableFields = tableFields.length - 1;
  return (
    <div className="bg-wtw-gray-2 w-full overflow-x-auto">
      <table
        className={classNames(
          'w-full',
          { 'table-auto': !isFixedTableLayout },
          { 'table-fixed': isFixedTableLayout }
        )}
      >
        <thead className="items-center h-[44px] bg-wtw-table-blue">
          <tr>
            {tableFields.map(({ header, name }, index) => (
              <th
                key={name}
                title={header}
                className={classNames(
                  'text-table-xs-md text-left min-w-[150px] max-w-[250px] overflow-hidden text-ellipsis break-words align-middle py-2',
                  { 'pl-3': index === 0 },
                  { 'pr-3': index === numberOfTableFields },
                  { 'pr-4': index !== numberOfTableFields }
                )}
              >
                <div className="w-full overflow-hidden line-clamp-2">
                  {header}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item: T, index: number) => {
            return (
              <Row
                key={`${item}-${index}`}
                item={item}
                tableFields={tableFields}
                quickFormFields={quickFormFields}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
                onEditSuccess={onEditSuccess}
                onDeleteSuccess={onDeleteSuccess}
                renderRowColor={renderRowColor}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
