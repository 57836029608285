import React from 'react';

const SECTION_1 = `1.1 When the following words with capital letters are used in these Terms, this is what they will mean: -

a) Event Outside Our Control: is defined in clause 9.1;
b) Application: your application submitted on an application form or otherwise in respect of the Services;
c) First Placement: this is your Placement, or if you book more than one Placement the Placement with the start date closest to the date of your Application;
d) Placement: the placement that We are providing to you as set out in your Application or as otherwise agreed between Us and you;
e) Price: the charge that We make for providing Our services in respect of every Placement you book (this does not include the Registration Fee);
f) Registration Fee: the £300 charge that We make to register you to receive Our services;
g) Terms: the terms and conditions set out in this document; and
h) We/Our/Us: Work the World Limited with registered number 05657211 and registered address Work the World, 3rd Floor, 15 West Street, Brighton, East Sussex, BN1 2RL, UK.`;

const SECTION_2 = `2.1 These are the terms and conditions on which We supply the Placement to you.

2.2 Please ensure that you read these Terms carefully, and check that the details in your Application and in these Terms are complete and accurate, before you submit your Application. If you think that there is a mistake or require any changes, please contact Us to discuss. We will confirm any changes in writing to avoid any confusion between you and Us.

2.3 When you submit your Application to Us, this does not mean We have accepted your Application for the Placement. Our acceptance of your Application will take place as described in clause 2.4. If We are unable to supply you with the Placement, We will inform you of this either in writing or by telephone and We will not process your Application.

2.4 We will accept your Application and these Terms will become binding on you and Us at the point at which both a) and b) have taken place: -

a) You pay the Registration Fee to Us; and
b) We confirm in writing that We are able to provide you with the Placement

at which point a contract will come into existence between you and Us. If We are unable to provide you with the Placement as set out in your Application we will let you know and try to arrange an alternative Placement or refund of any registration fee paid.

2.5 We shall assign a reference number to your Placement and inform you of it when We confirm your Placement. Please quote the reference number in all subsequent correspondence with Us relating to your Placement.`;

const SECTION_3 = `3.1 We will provide you with accommodation and food at that accommodation for the period of your placement.

3.2 We are not able to: -

a) provide food away from the accommodation that we provide,
b) provide food or accommodation in the event that you travel away from your Placement, or
c) provide alternative accommodation unless the accommodation we provide is in Our opinion unsuitable.

If there are any problems with your accommodation, please follow the procedure set out in clause 10.

3.3 For security reasons and to comply with local authority restrictions you are not allowed to invite or allow any person who is not a current Work the World customer or staff member to enter your accommodation without the express consent of the Work the World in-country Programme Manager.`;

const SECTION_4 = `4.1 Undertaking one of Our placements overseas carries with it a degree of risk and you must pay for and be protected by comprehensive travel insurance and professional indemnity insurance cover.

4.2 We are happy to assist you with organising your insurance, but ensuring that you are covered is your responsibility and We accept no responsibility if for any reason you are not covered by your insurance in the event of any loss, illness, injury or otherwise (provided such injury does not relate to an act or omission by Us). In particular:

a) You must obtain appropriate travel insurance to cover you for the period of your placement and provide full details of this insurance to Us at least 4 weeks prior to the commencement of your placement. Although we strongly recommend that you obtain a fully comprehensive insurance policy, your policy must at least cover the following: -

i. your repatriation and that of your belongings to your home address in the country of your residence;
ii. medical cover for the period of the Placement and your travel to and from the Placement between the country of your residence and the country the Placement takes place in; 
iii. your death or personal injury;  
iv. loss, theft or damage of your belongings or money; 
v. cancelled or delayed flights; and

b) You must have in place appropriate professional indemnity insurance cover for the duration of the Placement that will indemnify and hold harmless Us, the hospital or other Placement setting and its employees, servants and agents against all liabilities, costs, expenses, losses including but not limited to any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses), damages, injuries or judgements in any way caused by your negligence or wrongdoing provided you are working under the supervision of an appropriately qualified practitioner and provide full details of this insurance to us at least 4 weeks prior to the commencement of your placement.

4.3 Whilst every effort is made to make sure all accommodation is safe and secure, We recommend that you do not have in excess of £300 of currency (either sterling or the equivalent in such other local currency) at any time either in your possession or kept at your accommodation and We recommend that you take no other valuables with you to your Placement. If you do decide to take valuables with you to your Placement, it is your responsibility to make sure that your insurance properly covers such valuables. There are cash machines located within a short distance of each of the locations of the Placements and we recommend that you take small amounts of cash out at regular intervals rather than carry excess amounts of cash with you whilst on your Placement. We accept no responsibility for loss or damage to your property during your Placement provided such loss or damage is not by reason of Our negligence.`;

const SECTION_5 = `5.1 We provide help and advice on obtaining relevant visas and work permits for your Placement, but the issuing of these is at the discretion of the relevant authority and is beyond Our control and We accept no responsibility if you are unable to obtain the relevant visas and work permits for your Placement. Requirements for visas and works permits may change without notice and it is your responsibility to make sure you have the correct and valid visas and work permits for your Placement and you are required to pay for the cost of any visas and/or work permits. We accept no responsibility for the consequences of a change in visa and/or work permit requirements.

5.2 You are responsible for ensuring that your passport, visa and any other travel documents are valid for the duration of your placement.

5.3 Please note that visas issued for Work the World placements generally do not allow you to undertake any kind of paid work overseas and it is your responsibility to check and understand the restrictions on any visas and/or work permits. Taking on paid work without the proper permissions may be a serious offence for which you may be prosecuted.`;

const SECTION_6 = `6.1 Cancellation, postponement or amendment of your Placement must be notified to us in writing, either by post or email to Our UK head office in accordance with clause 14. Our overseas staff can provide help and advice on amendments and cancellations, but a verbal agreement will not represent a change to your contract with Us and you must contact the UK head office.

6.2 Our charge for an amendment to your Placement is normally £40 (to cover Our administration costs) plus any additional costs incurred by Us. If the total amendment cost is estimated to exceed £70, We will provide you with a quotation before proceeding.

6.3 A significant part of the fee paid by you will be spent or committed to budgets prior to the start date of your Placement, and We are therefore unable to offer a full refund of the Price if you choose to cancel. In the event of a cancellation by you the Registration Fee is non-refundable and we will refund part of the Price after deducting a cancellation charge. The cancellation charges set out below fairly reflect Our expenditure depending on how far in advance of your original Placement start date We receive your written notice to cancel as follows: -

a) Notice of between 60 and 90 days: 50% of Price;
b) Notice of less than 60 days: 100% of Price (no refund).

6.4 You may request a single postponement of your Placement. Your notification must reach us no less than 24 weeks before the Placement start date. If We agree to your request to vary the dates of your Placement We may need to charge you because a significant part of the fee paid by you will be spent or committed to budgets prior to the start date of your original Placement. Exceptions are entirely at Our discretion and must be agreed in writing at the time when the postponement is made. We will not accept responsibility for costs you may incur as a result of any postponement of your Placement start date such as, but not limited to, flight ticket penalties and insurance, vaccination and visa fees.

6.5 Any requests to amend your Placement after your Placement has started are entirely at Our discretion.

6.6 If you curtail your Placement and do not undertake the whole of your Placement, We cannot refund you any of the fee paid for your Placement. You will be responsible for any additional costs involved with the curtailment of your Placement, including the cost of repatriation.  If we incur any costs by reason of your curtailment of your Placement, We reserve the right to charge you a reasonable administration fee.

6.7 For the avoidance of doubt the calculation of time periods in accordance with this Clause 6 shall be calculated by reference to the original, unmodified, Placement start date.`;

const SECTION_7 = `7.1 Subject to these Terms, We will supply the Placement to you on the date agreed between Us.

7.2 We will make every effort to provide the Placement on the dates agreed. However, there may be delays or cancellations: -

(i) due to an Event Outside Our Control (see clause 9 for Our responsibilities when an Event Outside Our Control happens); or
(ii) due to other events (see clause 8).

7.3 If you do not pay Us for the Placement when you are supposed to as set out in clause 11 We may at Our discretion either cancel the Placement in its entirety or suspend the Placement with immediate effect until you have paid Us the outstanding amounts. We will contact you to tell you this.`;

const SECTION_8 = `8.1 The nature of Our overseas programmes is such that it may be necessary to change the details of your Placement from those originally given to you or cancel the Placement altogether. 

8.2 Where there is not an Event Outside Our Control, these changes might include, for example: -

a) the hospital in which you are placed,
b) the departments in the hospital to which you are allocated, or
c) the type of accommodation. 

Our variations under this clause 8.2 are not limited to these kinds of changes. We will use Our reasonable endeavours to minimise such changes and will only do this for a reason that we think is valid. Any changes made in accordance with this clause 8.2 do not require Your consent and We will inform you promptly if changes do arise. 

8.3 Where there is not an Event Outside Our Control, in very occasional circumstances We may need to cancel Your Placement. We do not need Your consent to do this and we will inform you not less than 4 weeks before your Placement start date and refund the Registration Fee and Price. This will be the full extent of Our liability and we will not accept responsibility for other costs you may incur for example but not limited to flight ticket penalties and insurance, vaccination and visa fees.

8.4 If there is an Event Outside Our Control clause 9 will apply. If we are able to do so, We will use our reasonable endeavours to agree variations to the Placement with You so that clause 9 no longer applies. This will probably require radically changing the Placement and each part of it. For example, if we are able to change the Country the Placement takes place in, this would change the accommodation, hospital and type of work. The variations permitted by this clause 8.4 are not limited to these kinds of changes.`;

const SECTION_9 = `9.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of Our obligations under these Terms that is caused by an Event Outside Our Control. An Event Outside Our Control means any act or event beyond Our reasonable control, including without limitation: -

a) epidemic or pandemic;
b) terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed conflict, imposition of sanctions, embargo, or breaking off of diplomatic relations;
c) nuclear, chemical or biological contamination or sonic boom;
d) any law or any action taken by a government or public authority, including without limitation imposing an export or import restriction, quota or prohibition, or failing to grant a necessary licence or consent;
e) collapse of buildings, fire, explosion or accident; 
f) any labour or trade dispute, strikes, industrial action or lockouts;
g) non-performance by suppliers or subcontractors (other than by companies in the same group as the party seeking to rely on this clause); and
h) interruption or failure of utility service.

9.2 In such circumstances: -

a) the time for the performance of Our obligations under these Terms shall be extended by a period equivalent to the period during which performance of such obligations has been delayed or failed to be performed; and
b) if We reasonably consider that the period of such delay shall exceed one calendar month (or such period does exceed one calendar month) we may terminate this contract on 21 days’ notice.

9.3 We will not accept responsibility for any costs you may incur as a result of Our failure to perform, or delay in Our performance of, any of Our obligations under these Terms including but not limited to the costs of flight tickets, vaccinations, insurance and visas. 

9.4 If an Event Outside Our Control takes place that affects the performance of Our obligations under these Terms We will contact you as soon as reasonably possible to notify you.`;

const SECTION_10 = `10.1 We are here to help you, and Our staff in the UK head office and overseas will do their very best to provide the highest level of service. However, a formal procedure for complaints is set out below.

10.2 We are providing you with an opportunity to gain real life work experience in a working hospital or other placement, and as such you will need to show a high level of independence and initiative, especially in dealing with problems as they arise. If you have any problem that you find difficult to deal with, or which you feel should be Our responsibility to resolve, you should follow this procedure: -

a) Talk to the Work the World in-country Programme Manager responsible for your placement. This is the person with the greatest understanding of your local situation, and he or she will do their best to help you;
b) If you are unhappy with the immediate response, or you feel the time taken to resolve the problem is too great, you should present your complaint to the in-country Programme Manager in writing;
c) If you are still unhappy with the response to your complaint, you should put it in writing to Our UK head office either by email or post as soon as possible;
d) In the event of a claim for compensation, you must promptly inform us verbally or by email of your intention to claim for compensation and then full details of the claim must be received by us in writing no more than 90 days after the end date of your Placement.

10.3 The above procedure exists to enable us to identify and deal with problems and complaints as quickly and efficiently as possible. Failure to follow this procedure may compromise Our ability to help you. We will not be responsible for problems or complaints not brought to Our attention as above.`;

const SECTION_11 = `11.1 The cost of the Placement will be set out in Our price list in force at the time We confirm your Placement. Please note that the charges quoted in Our price list are for one-week placements and rise incrementally depending on the duration of your Placement. We will confirm the Price for your Placement when we confirm your Placement in accordance with clause 2.4. Our prices may change at any time, but any price changes will not affect a Placement that we have confirmed in accordance with clause 2.4. The cost of the Placement does not include your flights which you must book and pay for separately.

11.2 When you pay the Price: -

a) if your First Placement begins within the 12-week period which starts on the day immediately following the day when these Terms become binding in accordance with Clause 2.4, you will pay the Price within 7 days of these Terms becoming binding; or
b) if your First Placement begins after the 12-week period that starts on the day immediately following the day when these Terms become binding in accordance with Clause 2.4, you will pay the Price no later than 12 weeks before the Placement begins.

11.3 Your rights to a refund on cancellation are set out in clause 6.

11.4 For the avoidance of doubt, in this Clause 11 time periods relating to Placement start dates shall be calculated by reference to the original, unmodified, Placement start date.`;

const SECTION_12 = `12.1 Clauses 12.2, 12.3 and 12.4 are subject to Clause 12.5.

12.2 If We fail to comply with these Terms, We are responsible for loss or damage you suffer that is a foreseeable result of Our breach of the Terms or Our negligence, but We are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if they were an obvious consequence of Our breach or if they were contemplated by you and Us at the time we entered into this contract.

12.3 We have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.

12.4. Please note that your booking is accepted on the understanding that you come on the programme at your own risk. It is not possible for us to be responsible for the actions or omissions of any party involved in your programme over which we have no direct control, such as governments, airlines, local transportation services and others. Equally we are not responsible for loss or expense due to war, riots, strikes, terrorist activities, natural disasters, or bankruptcies (or similar) of unconnected third parties. We are not liable for any injury, damage, loss, accident, delay, or other irregularity which may be caused by or resulting from:

a) any defect of any vehicle or other equipment (other than Our own);
b) the negligence or default of any person, company or other legal entity engaged in carrying out or performing any of the services involved (other than Our negligence or default or that of own employees); or
c) any accommodation or other travel arrangements you undertake or arrange to undertake that do not form a part of the services We provide.

12.5 We do not exclude or limit in any way Our liability for:

a) death or personal injury caused by Our negligence or the negligence of Our employees, agents or subcontractors;
b) fraud or fraudulent misrepresentation;
c) breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title and quiet possession)
d) breach of the terms implied by sections 3, 4 and 5 of the Supply of Goods and Services Act 1982 (description, satisfactory quality, fitness for purpose and samples); and
e) any liability that cannot be limited or excluded by law.`;

const SECTION_13 = `13.1 You agree to keep confidential all sensitive information and patient records disclosed to you during your Placement.

13.2 Whilst at the hospitals and/or other institutions hosting your Placement you agree to be bound by their rules, regulations and policies and any other reasonable directions provided by the hospitals and/or other institutions hosting your Placement.

13.3 We reserve the right to take appropriate action in the event that during your Placement:

a) Your actions or omissions would reasonably be expected to give offence to hosts, programme managers, work colleagues, patients or others (for example by reason of serious rowdiness, disregard of cultural differences at the location of your Placement or drunkenness);
b) Your actions or omissions would reasonably be expected to breach the rules, regulations, policies or other reasonable and lawful directions of the hospitals and/or other institutions;
c) Your actions or omissions would reasonably be expected to endanger the welfare and/or safety of other persons or risk damage to property;
d) You are guilty of a criminal offence or any fraud or dishonesty or act in any manner which in Our reasonable opinion brings or is likely to bring you or Us into disrepute;
e) You are, in Our reasonable opinion, negligent and incompetent in the performance of your duties, you fail to turn up to your Placement or leave your Placement without an adequate reason.

13.4 In the event that one or more of the matters set out above occurs, We will consider the seriousness of your actions or omissions taking into account all the circumstances. A problem that we consider serious would be brought to your attention in writing and/or verbally either personally or delivering it to your accommodation address.

13.5 We shall take appropriate action depending on the seriousness of your actions or omissions which may include:

(a) Suspending you from your work at your Placement either temporarily whilst we investigate further or permanently; or
(b) Notifying you by letter that, unless the matter is resolved in accordance with Our reasonable and lawful instructions, within five working days (or such shorter period as we in Our reasonable opinion deem appropriate), we will suspend you from your work at your Placement either temporarily whilst we investigate further or permanently.

13.6 In the event that your Placement is suspended permanently, should you wish to stay in your destination country beyond that date, We would have no further responsibility for your food, accommodation, insurance or travel arrangements and you would have to find and pay for your own accommodation, insurance and travel. If you choose to travel home if your Placement is suspended and this means you require a different flight home to the one you have already booked, you are responsible for the cost of any changes made to that flight or the cost of a new flight should you not be able to change your original flight.

13.7 Please note that we reserve the right to decline to accept any person onto a Placement, or to require any participant to withdraw at any time, when such action is determined by the appropriate Work the World staff representative to be in the best interests of the health, safety, and general welfare of the programme or of the individual participant. In such a case, we accept no responsibility for any airline cancellation penalty incurred by the purchase of a non-refundable ticket or any other cost that you may incur.`;

const SECTION_14 = `We are a company registered in England and Wales. Our company registration number is 05657211 and Our address is Work the World, 3rd Floor, 15 West Street, Brighton, East Sussex, BN1 2RL, UK. Our registered VAT number is 995971638.

14.1 If you have any questions or if you have any complaints, please contact Us. You can contact Us by telephoning Our customer service team at +44 (0)1273 974634 or by e-mailing Us at team@worktheworld.com.

If you wish to contact Us in writing, or if any clause in these Terms requires you to give Us notice in writing (for example, to cancel the contract), you can send this to Us by e-mail, by hand, or by pre-paid post to Work the World, 3rd Floor, 15 West Street, Brighton, East Sussex, BN1 2RL, UK and/or team@worktheworld.com. We will confirm receipt of this by contacting you in writing. If We have to contact you or give you notice in writing, We will do so by e-mail, by hand, or by pre-paid post to the address you provide to Us in your Application.`;

const SECTION_15 = `We will only use your personal information as set out in Our Privacy Notice.`;

const SECTION_16 = `16.1 We may transfer Our rights and obligations under these Terms to another organisation, and We will always notify you in writing if this happens, but this will not affect your rights or Our obligations under these Terms.

16.2 This contract is between you and Us. No other person shall have any rights to enforce any of its terms.

16.3 Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.

16.4 If We fail to insist that you perform any of your obligations under these Terms, or if We do not enforce Our rights against you, or if We delay in doing so, that will not mean that We have waived Our rights against you and will not mean that you do not have to comply with those obligations. If We do waive a default by you, We will only do so in writing, and that will not mean that We will automatically waive any later default by you.

16.5 These Terms are governed by English law. You and We both agree to submit to the non-exclusive jurisdiction of the English courts.`;

const Section: React.FC<{
  title: string;
  text: string;
}> = ({ title, text }) => (
  <div className="space-y-4">
    <h3 className="text-base font-semibold">{title}</h3>
    <p className="text-sm whitespace-pre-line">{text}</p>
  </div>
);

const TermsAndConditionsText: React.FC = () => (
  <>
    <Section title="1. DEFINITIONS" text={SECTION_1} />
    <Section title="2. OUR CONTRACT WITH YOU" text={SECTION_2} />
    <Section title="3. ACCOMMODATION AND FOOD" text={SECTION_3} />
    <Section title="4. INSURANCE" text={SECTION_4} />
    <Section title="5. VISAS" text={SECTION_5} />
    <Section
      title="6. AMENDMENTS, CANCELLATION AND CURTAILMENT BY YOU TO THE PLACEMENT"
      text={SECTION_6}
    />
    <Section title="7. PROVIDING SERVICES" text={SECTION_7} />
    <Section title="8. AMENDMENTS AND CANCELLATIONS BY US" text={SECTION_8} />
    <Section title="9. EVENTS OUTSIDE OUR CONTROL" text={SECTION_9} />
    <Section
      title="10. IF THERE IS A PROBLEM WITH THE SERVICES"
      text={SECTION_10}
    />
    <Section title="11. PRICE AND PAYMENT" text={SECTION_11} />
    <Section title="12. OUR LIABILITY TO YOU" text={SECTION_12} />
    <Section title="13. YOUR RESPONSIBILITY" text={SECTION_13} />
    <Section
      title="14. INFORMATION ABOUT US AND HOW TO CONTACT US"
      text={SECTION_14}
    />
    <Section
      title="15. HOW WE MAY USE YOUR PERSONAL INFORMATION"
      text={SECTION_15}
    />
    <Section title="16. OTHER IMPORTANT TERMS" text={SECTION_16} />
  </>
);

export default TermsAndConditionsText;
