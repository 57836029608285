import React from 'react';
import { useParams } from 'react-router';
import { mutate } from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBadgeCheck,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';

import AdminStandardPage from '../../common/AdminStandardPage';
import ThreeColumns from '../../common/layout/ThreeColumns';
import TwoColumns from '../../common/layout/TwoColumns';
import CardDetail from '../../../../framework-components/card/CardDetail';
import { useAPI } from '../../../../api/api';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import { UniversityBasicInfo } from './types';
import UniversityBookingTable from './UniversityBookingTable';
import UniversityEnquiryTable from './UniversityEnquiryTable';
import UniversityAlumniTable from './UniversityAlumniTable';
import { PencilEditButton } from '../../../../framework-components/button/PencilEditButton';
import EditDetailsModal from '../../../manage-single-object/EditDetailsModal';
import useModalState from '../../../../hooks/modals';
import { updateBasicInfo } from './actions';
import { NoteEntityType } from '../../../../framework-components/notes/types';
import Notes from '../../../../framework-components/notes/Notes';
import UniversityStaff from './UniversityStaff';
import Anchor from '../../common/Anchor';

const UniversityDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const basicInfoUrl = `/api/admin/university/${id}/basic-profile-page-info/`;
  const {
    data: universityInformation,
    isValidating,
    error,
  } = useAPI<UniversityBasicInfo>(basicInfoUrl);

  const { isOpen, openModal, closeModal } = useModalState();

  const onSubmitSuccessHandler = () => {
    mutate(basicInfoUrl);
  };

  const renderHouseCard = (
    data: Record<string, string | React.JSX.Element | undefined>
  ) => {
    return <CardDetail className="h-full" data={data} />;
  };

  const UniversityName = universityInformation?.name ?? '';

  const filterName = JSON.stringify({
    name: {
      label: universityInformation?.name,
      value: universityInformation?.id,
    },
  });
  const encodedURI = encodeURI(filterName);

  const renderFirstColumn = () => {
    return renderHouseCard({
      Name: UniversityName,
      Status: universityInformation?.created_by_student ? (
        <span>
          <Anchor
            href={`/admin/directories/un-recognised-universities?filters=${encodedURI}`}
          >
            Inactive
          </Anchor>{' '}
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            flip="horizontal"
            style={{ color: '#ff0000' }}
          />
        </span>
      ) : (
        <div className="inline">
          <p>
            Key Partner{'     '}
            <FontAwesomeIcon
              icon={faBadgeCheck}
              style={{ color: '#63E6BE' }}
            />{' '}
          </p>
        </div>
      ),
    });
  };

  const renderSecondColumn = () => {
    return renderHouseCard({
      Phone: universityInformation?.telephone ?? '-',
      Website: universityInformation?.website ?? '-',
      Status: universityInformation?.status ?? '_',
    });
  };

  const renderThirdColumn = () => {
    return renderHouseCard({
      Address: universityInformation?.address ?? '-',
      Country: universityInformation?.country?.label ? (
        <span>
          {universityInformation.country.label},{' '}
          {universityInformation.country.market_code}{' '}
        </span>
      ) : (
        '-'
      ),
    });
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Universities',
          href: '/admin/directories/universities',
        },
        {
          label: UniversityName,
          href: null,
        },
      ]}
      title={UniversityName}
      enableHome={false}
    >
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      {universityInformation && (
        <div className="mt-8">
          <div className="pb-9 space-y-6">
            <ThreeColumns
              leftColumn={renderFirstColumn()}
              middleColumn={renderSecondColumn()}
              rightColumn={renderThirdColumn()}
            />
            <div className="w-full flex justify-end">
              <PencilEditButton onClick={openModal} />
            </div>
            <EditDetailsModal<UniversityBasicInfo>
              isOpen={isOpen}
              closeModal={closeModal}
              submitItem={updateBasicInfo}
              onSubmitSuccess={onSubmitSuccessHandler}
              data={universityInformation}
              fields={[
                {
                  type: 'smalltext',
                  name: 'name',
                  label: 'Name',
                },
                {
                  type: 'smalltext',
                  name: 'address',
                  label: 'Address',
                },
                {
                  type: 'remote-dropdown',
                  path: '/api/admin/destination/country-options/list/',
                  name: 'country',
                  label: 'Country',
                },
                {
                  type: 'smalltext',
                  name: 'telephone',
                  label: 'Telephone',
                },
                {
                  type: 'smalltext',
                  name: 'website',
                  label: 'Website',
                },
              ]}
            />
          </div>
          <TwoColumns
            leftColumn={
              <>
                {id && (
                  <>
                    <UniversityEnquiryTable id={id} name={UniversityName} />{' '}
                    <UniversityBookingTable id={id} name={UniversityName} />{' '}
                    <UniversityAlumniTable id={id} name={UniversityName} />{' '}
                  </>
                )}
              </>
            }
            rightColumn={
              <>
                {id && (
                  <Notes
                    initialEntityId={id}
                    initialEntityType={NoteEntityType.UNIVERSITY}
                  />
                )}
                <UniversityStaff universityId={id} />
              </>
            }
          />
        </div>
      )}
    </AdminStandardPage>
  );
};

export default UniversityDetail;
