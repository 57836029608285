import React from 'react';

import {
  addLocalContact,
  removeLocalContact,
  updateLocalContactDetails,
} from './actions';
import { LocalContactCreateEditData } from './types';
import { FieldType } from '../../../../../framework-components/quickform/Field';
import { TableField } from '../../../../manage-multiple-objects/types';
import ManageMultipleObjects from '../../../../manage-multiple-objects/ManageMultipleObjects';

interface Props {
  destinationId?: string;
}

const tableFields: ReadonlyArray<TableField<LocalContactCreateEditData>> = [
  {
    name: 'firstname',
    header: 'Name',
    render: (data: LocalContactCreateEditData) =>
      `${data.first_name} ${data.last_name}`,
  },
  {
    name: 'role',
    header: 'Role',
    render: (data: LocalContactCreateEditData) => data.position,
  },
  {
    name: 'address',
    header: 'Address',
    render: (data: LocalContactCreateEditData) => data.address,
    renderInModalOnly: true,
  },
  {
    name: 'telephone',
    header: 'Telephone',
    render: (data: LocalContactCreateEditData) => data.telephone,
  },
  {
    name: 'email',
    header: 'Email',
    render: (data: LocalContactCreateEditData) => data.email,
  },
];

const quickFormFields: ReadonlyArray<FieldType> = [
  {
    type: 'smalltext',
    name: 'first_name',
    label: 'First Name',
  },
  {
    type: 'smalltext',
    name: 'last_name',
    label: 'Last Name',
  },
  {
    type: 'smalltext',
    name: 'position',
    label: 'Role',
  },
  {
    type: 'largetext',
    name: 'address',
    label: 'Address',
  },
  {
    type: 'smalltext',
    name: 'telephone',
    label: 'Telephone',
  },
  {
    type: 'smalltext',
    name: 'email',
    label: 'Email',
  },
];

const LocalContacts: React.FC<Props> = ({ destinationId }) => {
  if (!destinationId) {
    return null;
  }

  const getLocalContactsDetailsURL = `/api/admin/destination/destination-profile/${destinationId}/local-contacts/list/`;

  return (
    <ManageMultipleObjects<LocalContactCreateEditData>
      title="Local contacts:"
      tableFields={tableFields}
      quickFormFields={quickFormFields}
      getUrl={getLocalContactsDetailsURL}
      addHandler={(values) => addLocalContact(destinationId, values)}
      editHandler={(values) => updateLocalContactDetails(destinationId, values)}
      deleteHandler={(values) => removeLocalContact(destinationId, values.id)}
      noDataFoundMessage="There are currently no local contacts for this destination."
      isFixedTableLayout
    />
  );
};

export default LocalContacts;
