export const DISCIPLINE_COLOUR_MAP: Record<string, string> = {
  // Regular versions
  Medicine: 'bg-wtw-disciplines-medicine',
  Nursing: 'bg-wtw-disciplines-nursing',
  'Adult Nursing': 'bg-wtw-disciplines-adult-nursing',
  'Child Nursing': 'bg-wtw-disciplines-child-nursing',
  'Mental Health Nursing': 'bg-wtw-disciplines-mental-health-nursing',
  'Learning Disability Nursing':
    'bg-wtw-disciplines-learning-disability-nursing',
  'Paramedic Science': 'bg-wtw-disciplines-paramedic-science',
  'Biomedical Science': 'bg-wtw-disciplines-biomedical-science',
  Midwifery: 'bg-wtw-disciplines-midwifery',
  Physiotherapy: 'bg-wtw-disciplines-physiotherapy',
  Dentistry: 'bg-wtw-disciplines-dentistry',
  Pharmacy: 'bg-wtw-disciplines-pharmacy',
  Radiography: 'bg-wtw-disciplines-radiography',
  'Occupational Therapy': 'bg-wtw-disciplines-occupational-therapy',

  // Light versions
  'Medicine light': 'bg-wtw-disciplines-light-medicine',
  'Nursing light': 'bg-wtw-disciplines-light-nursing',
  'Adult Nursing light': 'bg-wtw-disciplines-light-adult-nursing',
  'Child Nursing light': 'bg-wtw-disciplines-light-child-nursing',
  'Mental Health Nursing light':
    'bg-wtw-disciplines-light-mental-health-nursing',
  'Learning Disability Nursing light':
    'bg-wtw-disciplines-light-learning-disability-nursing',
  'Paramedic Science light': 'bg-wtw-disciplines-light-paramedic-science',
  'Biomedical Science light': 'bg-wtw-disciplines-light-biomedical-science',
  'Midwifery light': 'bg-wtw-disciplines-light-midwifery',
  'Physiotherapy light': 'bg-wtw-disciplines-light-physiotherapy',
  'Dentistry light': 'bg-wtw-disciplines-light-dentistry',
  'Pharmacy light': 'bg-wtw-disciplines-light-pharmacy',
  'Radiography light': 'bg-wtw-disciplines-light-radiography',
  'Occupational Therapy light': 'bg-wtw-disciplines-light-occupational-therapy',
};
