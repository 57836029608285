import React from 'react';
import { mutate } from 'swr';
import { Link } from 'react-router-dom';

import { Summary as SummaryType, ENQUIRY_STATUS_LABELS } from './types';
import ThreeColumns from '../../common/layout/ThreeColumns';
import { PencilEditButton } from '../../../../framework-components/button/PencilEditButton';
import EditDetailsModal from '../../../manage-single-object/EditDetailsModal';
import CardDetail from '../../../../framework-components/card/CardDetail';
import useModalState from '../../../../hooks/modals';
import { updateSummary } from './actions';
import {
  GENDER,
  yearOfStudyOptions,
  getYearOfGraduationOptions,
} from '../../../booking/booking-form/constants';
import { GENDER_TO_DISPLAY_NAME } from '../../../booking/booking-form/labels';

const yearOfGraduationOptions = getYearOfGraduationOptions();

interface Props {
  data: SummaryType;
  enquiryId: string;
}

const renderCard = (
  data: Record<string, string | React.JSX.Element | undefined>
) => {
  return <CardDetail className="h-full" data={data} />;
};

const Summary: React.FC<Props> = ({ data, enquiryId }) => {
  const { isOpen, openModal, closeModal } = useModalState();

  const renderFirstColumn = () =>
    renderCard({
      Name: `${data.first_name} ${data.last_name}`,
      Status: ENQUIRY_STATUS_LABELS[data.status],
    });

  const renderSecondColumn = () =>
    renderCard({
      Gender: data.gender?.label,
      Nationality: data.country?.label,
      Mobile: data.phone ?? '-',
      Email: data.email ?? '-',
    });

  const renderThirdColumn = () => {
    return renderCard({
      University: (
        <>
          {data.university ? (
            <Link
              className="text-blue-500"
              to={`/admin/directories/universities/${data.university.value}`}
            >
              {data.university.label}
            </Link>
          ) : (
            '-'
          )}
        </>
      ),
      Course: data.university_course ?? '-',
      'Year of study': data.year_of_university_study?.label ?? '-',
      'Year of graduation': data.year_of_graduation?.label ?? '-',
    });
  };

  return (
    <div className="pb-9 space-y-6">
      <ThreeColumns
        leftColumn={renderFirstColumn()}
        middleColumn={renderSecondColumn()}
        rightColumn={renderThirdColumn()}
      />
      <div className="w-full flex justify-end">
        <PencilEditButton onClick={openModal} />
      </div>
      <EditDetailsModal<SummaryType>
        isOpen={isOpen}
        closeModal={closeModal}
        submitItem={(values) => updateSummary(data.id, values)}
        onSubmitSuccess={() =>
          mutate(`/api/admin/enquiries/${enquiryId}/summary/`)
        }
        data={data}
        fields={[
          {
            type: 'smalltext',
            name: 'first_name',
            label: 'First name',
          },
          {
            type: 'smalltext',
            name: 'last_name',
            label: 'Last name',
          },
          {
            type: 'local-dropdown',
            name: 'gender',
            label: 'Gender',
            options: [
              {
                value: GENDER.MALE,
                label: GENDER_TO_DISPLAY_NAME[GENDER.MALE],
              },
              {
                value: GENDER.FEMALE,
                label: GENDER_TO_DISPLAY_NAME[GENDER.FEMALE],
              },
            ],
          },
          {
            type: 'remote-dropdown',
            name: 'country',
            label: 'Nationality',
            path: '/api/booking/countries/',
          },
          {
            type: 'smalltext',
            name: 'phone',
            label: 'Mobile',
          },
          {
            type: 'smalltext',
            name: 'email',
            label: 'Email',
          },
          {
            type: 'remote-dropdown',
            name: 'university',
            label: 'University',
            path: '/api/booking/universities/',
          },
          {
            type: 'local-dropdown',
            name: 'year_of_graduation',
            label: 'Year of graduation',
            options: yearOfGraduationOptions,
          },
          {
            type: 'local-dropdown',
            name: 'year_of_university_study',
            label: 'Year of study',
            options: yearOfStudyOptions,
          },
          {
            type: 'smalltext',
            name: 'university_course',
            label: 'Course',
          },
        ]}
      />
    </div>
  );
};

export default Summary;
