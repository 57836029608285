import React from 'react';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import useModalState from '../../../../../hooks/modals';
import Modal from '../../../../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../framework-components/button/Button';
import { removeBookingHouse } from '../actions';

interface Props {
  bookingId: string;
  bookingHouseId: string;
}

const DeleteBookingHouse: React.FC<Props> = ({ bookingId, bookingHouseId }) => {
  const focusRef = React.useRef(null);

  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <>
      <FontAwesomeIcon
        className="text-wtw-logo-blue cursor-pointer pl-2"
        icon={faTrashAlt}
        onClick={openModal}
      />

      <Modal
        isOpen={isOpen ?? false}
        onClose={closeModal}
        initialFocus={focusRef}
      >
        <div className="space-y-6 flex flex-col items-center">
          <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
            Remove accomodation
          </Dialog.Title>
          <div className="flex justify-end space-x-4">
            <SecondaryButton onClick={closeModal}>Close</SecondaryButton>
            <PrimaryButton
              onClick={() => {
                removeBookingHouse(bookingHouseId).then(
                  () => {
                    toast.success('Accomodation removed from booking');
                    closeModal();
                    mutate(
                      `/api/admin/bookings/${bookingId}/get-trip-details/`
                    );
                    mutate(
                      `/api/admin/bookings/${bookingId}/get-accomodation-details/`
                    );
                  },
                  () => {
                    toast.error('Unable to remove accomodation from booking');
                  }
                );
              }}
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteBookingHouse;
