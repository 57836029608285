import React from 'react';
import { Dialog } from '@headlessui/react';

import Modal from '../../../modal';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import HtmlRenderer from '../../../../framework-components/html-renderer/HtmlRenderer';

interface Props {
  name: string;
  content: string;
  isOpen: boolean;
  closeModal: () => void;
}

const TemplateModal: React.FC<Props> = ({
  name,
  content,
  isOpen,
  closeModal,
}) => {
  const focusRef = React.useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4" data-testid="delete-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          {name}
        </Dialog.Title>
        <HtmlRenderer htmlString={content} />
        <div className="flex justify-end space-x-4">
          <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default TemplateModal;
