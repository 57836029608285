import React, { useCallback, useContext, useRef } from 'react';

import AdminStandardPage from '../common/AdminStandardPage';
import Table from '../../../framework-components/table/Table';
import { apiRequest } from '../../../api/api';
import {
  CombinedFilters,
  TableData,
} from '../../../framework-components/table/types';
import { StaffListingTableData } from './types';
import { Option } from '../../../types';
import Anchor from '../common/Anchor';
import { PrimaryButton } from '../../../framework-components/button/Button';
import AddNewIconOutlined from '../common/AddNewIconOutlined';
import useModalState from '../../../hooks/modals';
import AddNewStaffMemberModal from './AddNewStaffMemberModal';
import { hasPermission } from '../../../utils/permissions';
import { LoggedInUserContext } from '../../../context-providers/logged-in-user';

interface StaffListingFilters extends Record<string, Option[]> {
  destination: Option[];
}

const StaffListing: React.FC = () => {
  const { isOpen, setIsOpen, closeModal } = useModalState();
  const loggedInUser = useContext(LoggedInUserContext);

  const tableRef = useRef<{ reloadData: () => void }>(null);

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.reloadData();
    }
  };

  const getStaffListingTableData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<StaffListingTableData>>(
        '/api/admin/staff/list/?' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const additionalHeaderContent = () => (
    <div className="flex justify-end">
      <PrimaryButton onClick={() => setIsOpen(true)}>
        <span className="flex items-center">
          <AddNewIconOutlined className="mr-3" />
          Add new
        </span>
      </PrimaryButton>
      <AddNewStaffMemberModal
        reloadData={reloadData}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </div>
  );

  const getFilterOptions = useCallback(() => {
    return apiRequest<StaffListingFilters>(
      '/api/admin/staff/list/filters/'
    ).then((response) => {
      return response;
    });
  }, []);

  return (
    <AdminStandardPage
      additionalHeaderContent={
        loggedInUser ? (
          loggedInUser &&
          hasPermission(loggedInUser, 'General.ACCESS_ADMIN') &&
          hasPermission(loggedInUser, 'Staff.ADD_USER') &&
          additionalHeaderContent()
        ) : (
          <></>
        )
      }
      breadcrumbs={[
        {
          label: 'Admin',
          href: null,
        },
        {
          label: 'Staff',
          href: null,
        },
      ]}
      title="Staff"
      enableHome={false}
    >
      <Table<StaffListingTableData>
        ref={tableRef}
        columnsConfig={{
          user: {
            key: 'user',
            header: 'Name',
            enableSort: true,
            renderCell: (request) => (
              <a href={`/admin/staff/${request.id}/profile`}>{request.name}</a>
            ),
          },
          email: {
            key: 'email',
            header: 'Email',
            enableSort: true,
            renderCell: (request) => request.email,
          },
          destination: {
            key: 'destination',
            header: 'Destination',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (request) =>
              request.destination ? (
                <Anchor
                  href={`/admin/directories/destinations?destination=${request.destination.id}`}
                >
                  {request.destination.name}
                </Anchor>
              ) : (
                ''
              ),
          },
          status: {
            key: 'status',
            header: 'Status',
            enableSort: true,
            renderCell: (request) => request.status,
          },
          last_login: {
            key: 'last_login',
            header: 'Last login',
            enableSort: true,
            renderCell: (request) => {
              if (request.last_login) {
                const date = new Date(request.last_login);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');

                return `${day}-${month}-${year}, ${hours}:${minutes}`;
              }
              return '';
            },
          },
        }}
        getData={getStaffListingTableData}
        getFilterOptions={getFilterOptions}
        enablePagination
        enableSearch
      />
    </AdminStandardPage>
  );
};

export default StaffListing;
