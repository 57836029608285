import React from 'react';

import FinancesToDateRow, { FinancesToDateRowProps } from './FinancesToDateRow';
import { SecondaryButton } from '../components/Button';
import { useAPI } from '../../../api/api';
import LoadingOverlay from '../components/LoadingOverlay';
import ErrorOverlay from '../components/ErrorOverlay';
import { CurrencyType } from '../../booking/booking-form/types';
import { CURRENCY_TO_SYMBOL } from '../../booking/booking-form/constants';
import useModalState from '../../../hooks/modals';
import BacsModal from './BacsModal';
import StripeModal from './StripeModal';

interface FinancesToDateData {
  transactions: FinancesToDateRowProps[];
  balance: number;
}

interface Props {
  booking_id: string;
  currency: CurrencyType;
}

const FinancesToDateList: React.FC<Props> = ({ booking_id, currency }) => {
  const {
    isOpen: isOpenStripe,
    openModal: openStripeModal,
    closeModal: closeStripeModal,
  } = useModalState();

  const {
    isOpen: isOpenBacs,
    openModal: openBacsModal,
    closeModal: closeBacsModal,
  } = useModalState();

  const {
    data: financeData,
    isValidating,
    error,
  } = useAPI<FinancesToDateData>(
    `/api/my-trip/my-finances/${booking_id}/get-finances-to-date/`
  );

  const amountInPounds = (Number(financeData?.balance) / 100).toFixed(2);

  return (
    <div>
      <h1 className="text-sh3 mb-4">Finances to date</h1>
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      {financeData && (
        <>
          {financeData.transactions.map((item) => (
            <FinancesToDateRow
              id={item.id}
              key={item.id}
              reference={item.reference}
              amount_pence={item.amount_pence}
              tx_type={item.tx_type}
              currency={currency}
            />
          ))}
          <div className="rounded-lg mb-6 bg-gray-100 pt-10 flex justify-between px-5 py-2">
            <h1 className="text-sm">Balance</h1>
            <h1 className="text-sm">
              {CURRENCY_TO_SYMBOL[currency]}
              {amountInPounds}
            </h1>
          </div>
        </>
      )}
      <div className="flex justify-end gap-4">
        <SecondaryButton label="Pay by bank transfer" onClick={openBacsModal} />
        <SecondaryButton label="Make a payment" onClick={openStripeModal} />
      </div>
      <StripeModal
        bookingId={booking_id}
        isOpen={isOpenStripe}
        closeModal={closeStripeModal}
      />
      <BacsModal
        bookingId={booking_id}
        isOpen={isOpenBacs}
        closeModal={closeBacsModal}
      />
    </div>
  );
};

export default FinancesToDateList;
