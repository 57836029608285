import React from 'react';

import ManageLongText from '../manage-long-text/ManageLongText';

const ManageLongTextExample = () => {
  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">ManageLongText</h2>

      <ManageLongText
        getUrl="https://jsonplaceholder.typicode.com/posts/1"
        submitItem={async (data) => {
          console.log(data); // eslint-disable-line no-console
          return data;
        }}
        fieldName="title"
        render={(item) => item.title}
      />
    </div>
  );
};

export default ManageLongTextExample;
