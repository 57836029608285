import React from 'react';
import { useFormik, FormikProps, FormikErrors, FormikValues } from 'formik';
import { get } from 'lodash';

import Field, { FieldType } from './Field';
import { PrimaryButton } from '../Button';
import ErrorText from '../../../error-text';
import { APIError } from '../../../../api/api';

interface Props<T> {
  onSubmit: (data: T) => Promise<T>;
  initialValues: T;
  fields: ReadonlyArray<FieldType>;
  onSubmitSuccess?: (values: T) => void;
  buttonLabel?: string;
  hideButtonOnClean?: boolean;
}

const QuickForm = <T extends FormikValues>({
  onSubmit,
  initialValues,
  fields,
  onSubmitSuccess,
  buttonLabel,
  hideButtonOnClean = false,
}: Props<T>) => {
  const formik: FormikProps<T> = useFormik<T>({
    initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        const response = await onSubmit(values);
        if (onSubmitSuccess) {
          onSubmitSuccess({ ...values, ...response });
        }
      } catch (error: unknown) {
        setErrors((error as APIError).body as FormikErrors<T>);
      }
    },
    enableReinitialize: true,
  });

  const onSubmitForm = formik.handleSubmit;

  return (
    <form onSubmit={onSubmitForm} className="space-y-2">
      {fields.map((field) => {
        const value = get(formik.values, field.name);
        const errors = get(formik.errors, field.name) as string | string[];
        return (
          <Field
            key={field.name}
            {...field}
            value={value}
            formik={formik}
            errors={errors}
          />
        );
      })}
      {(formik.dirty || !hideButtonOnClean) && (
        <PrimaryButton
          isDisabled={formik.isSubmitting}
          isLoading={formik.isSubmitting}
          label={buttonLabel ? buttonLabel : 'Submit'}
          type="submit"
          className="w-full"
        />
      )}
      {formik.errors && formik.errors.non_field_errors && (
        <ErrorText className="text-sm">
          {formik.errors.non_field_errors as string | string[]}
        </ErrorText>
      )}
    </form>
  );
};

export default QuickForm;
