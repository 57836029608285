import React from 'react';

import WidgetContainer from '../components/WidgetContainer';

const ShareYourJourney: React.FC = () => {
  return (
    <WidgetContainer className="space-y-6 pb-10" title="Share your journey">
      <div className="space-y-6 text-gray-500">
        <p>
          Before you booked your placement, you probably read through the
          reviews on our website.
        </p>
        <p>
          Reading about the amazing experiences people have on their Work the
          World placements is endlessly beneficial for people trying to make a
          decision about where to go. The stories give future travellers an
          authentic and personal window into the kind of experiences they could
          have themselves.
        </p>
        <p>
          If the reviews helped you make a decision to travel overseas, why not
          pay it forward by writing one for future travellers. We've even got a
          team of expert writers who'll professionally edit your review.
        </p>
        <p>
          <a
            className="text-wtw-logo-blue hover:text-wtw-dark-blue"
            href="https://www.worktheworld.co.uk/documenting-your-story-guidelines"
          >
            Our handy Review Guide document
          </a>{' '}
          will explain the quick and easy process or alternatively you can send
          an email to our team at{' '}
          <a
            className="text-wtw-logo-blue hover:text-wtw-dark-blue"
            href="mailto:media@worktheworld.com"
          >
            media@worktheworld.com
          </a>{' '}
          who can guide you through the next steps.
        </p>
      </div>
    </WidgetContainer>
  );
};

export default ShareYourJourney;
