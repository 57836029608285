import React from 'react';

import { SearchDropOptionsProps } from './types';

const SearchDropdownOptions = <T,>({
  options = [],
  renderAction,
  onClick,
  ulClassName,
  liClassName,
}: SearchDropOptionsProps<T> & {
  ulClassName?: string;
  liClassName?: string;
}) => {
  const onClickHandler =
    (option: T) => (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      onClick(option);
    };

  return (
    <ul
      className={`w-full max-h-[18.375rem] divide-y divide-slate-200 overflow-y-auto rounded-b-lg border-t border-slate-200 text-sm leading-6 ${ulClassName}`}
      role="listbox"
      aria-labelledby="search-dropdown-options"
    >
      {options.map((option, index) => {
        return (
          <li
            key={`${index}`}
            onClick={onClickHandler(option)}
            role="option"
            className={liClassName}
          >
            {renderAction(option, index)}
          </li>
        );
      })}
    </ul>
  );
};

export default SearchDropdownOptions;
