import { apiRequest } from '../../../../api/api';
import {
  OccupancyDetails,
  OtherNotes,
  PublicDescription,
  Room,
  BasicInformation,
  QuickFacts,
} from './types';

export const updateOccupancyDetails = (
  id: string,
  occupanyDetails: OccupancyDetails
) => {
  const url = `/api/admin/house/${id}/occupancy-stats/update/`;
  return apiRequest<OccupancyDetails>(url, {
    method: 'POST',
    body: JSON.stringify(occupanyDetails),
  });
};

export const updatePublicDescription = async (
  id: string,
  description: PublicDescription
) => {
  const url = `/api/admin/house/${id}/description/update/`;
  return apiRequest<PublicDescription>(url, {
    method: 'POST',
    body: JSON.stringify(description),
  });
};

export const updateOtherNotes = async (id: string, notes: OtherNotes) => {
  const url = `/api/admin/house/${id}/notes/update/`;
  return apiRequest<OtherNotes>(url, {
    method: 'POST',
    body: JSON.stringify(notes),
  });
};

export const addRoom = (id: string, room: Room) => {
  const url = `/api/admin/house/${id}/room/create/`;
  return apiRequest<Room>(url, {
    method: 'POST',
    body: JSON.stringify(room),
  });
};

export const updateRoom = (room: Room) => {
  const url = `/api/admin/house/room/${room.id}/update/`;
  return apiRequest<Room>(url, {
    method: 'POST',
    body: JSON.stringify(room),
  });
};

export const deleteRoom = (room: Room) => {
  const url = `/api/admin/house/room/${room.id}/delete/`;
  return apiRequest<Room>(url, {
    method: 'DELETE',
  });
};

export const updateBasicInfo = async (basicInfo: BasicInformation) => {
  const url = `/api/admin/house/${basicInfo.id}/basic-information/update/`;
  const response = await apiRequest<BasicInformation>(url, {
    method: 'POST',
    body: JSON.stringify(basicInfo),
  });
  return response;
};

export const updateQuickFacts = (houseId: string, quickFacts: QuickFacts) => {
  const url = `/api/admin/house/${houseId}/quick-facts/update/`;
  return apiRequest<QuickFacts>(url, {
    method: 'POST',
    body: JSON.stringify(quickFacts),
  });
};
