import classNames from 'classnames';
import React from 'react';

import { TAG_DEFAULT_COLOR_CLASS } from './constants';
import { TagOption } from './types';

export interface NoteTagProps {
  tag: TagOption;
}

const NoteTag: React.FC<NoteTagProps> = ({ tag }) => {
  return (
    <span
      className={classNames(
        'flex items-center justify-center text-white text-xs-semibold rounded-md px-1 py-0.5 text-center w-full min-w-[77px] max-w-fit h-full leading-normal px-2',
        tag.backgroundColor || TAG_DEFAULT_COLOR_CLASS
      )}
    >
      {tag.label}
    </span>
  );
};

export default NoteTag;
