import React from 'react';

import Modal from '../../modal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  title?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  email?: string;
  telephone?: string;
}

const ContactOpsRepModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  title,
  firstName,
  lastName,
  position,
  email,
  telephone,
}) => {
  return (
    <Modal variant="small" isOpen={isOpen} onClose={closeModal}>
      <div className="space-y-2">
        <h1 className="pb-2 text-md-bold">Contact Operations Representative</h1>
        <p>Title: {title}</p>
        <p>
          Name: {firstName} {lastName}
        </p>
        <p>Position: {position}</p>
        <p>
          Email:
          <a
            className="text-wtw-logo-blue hover:text-wtw-dark-blue"
            href={`mailto:${email}`}
          >
            {' '}
            {email}
          </a>
        </p>
        <p>Telephone: {telephone}</p>
      </div>
    </Modal>
  );
};

export default ContactOpsRepModal;
