import classNames from 'classnames';
import React from 'react';

interface Props {
  children?: string | string[];
  className?: string;
}

const ERROR_MESSAGE_MAP: Record<string, string> = {
  'This field may not be blank.': 'This field is required.',
  'This field may not be null.': 'This field is required.',
};

const mapErrorMessage = (error: string | undefined): string | undefined => {
  if (!error) {
    return undefined;
  }

  return ERROR_MESSAGE_MAP[error] || error;
};

interface RedTextProps {
  children?: string;
  className?: string;
}

const RedText: React.FC<RedTextProps> = ({ className, children }) => (
  <p className={classNames('text-red-400', className)}>
    {mapErrorMessage(children)}
  </p>
);

const ErrorText: React.FC<Props> = ({ className, children }) => {
  if (typeof children === 'string') {
    return <RedText className={className}>{children as string}</RedText>;
  }
  return (
    <ul>
      {children &&
        children.map((child) => {
          return (
            <li key={child}>
              <RedText className={className}>{child as string}</RedText>
            </li>
          );
        })}
    </ul>
  );
};

export default ErrorText;
