export const DEPARTMENT_OPTIONS = [
  'Anaesthesiology',
  'Biomedicine',
  'Burns Unit',
  'Cardiology',
  'Cardiothoracic Surgery',
  'Casualty / Outpatient',
  'Dentistry',
  'Dermatology',
  'Digestive Surgery',
  'Emergency',
  'ENT',
  'ETU (Emergency Treatment Unit)',
  'Family Medicine',
  'Gastroenterology - Medicine and Surgery',
  'Gastrointestinal - Medicine and Surgery',
  'General Medicine',
  'General Paediatrics - Infectious Diseases and Malnutrition',
  'General Practice',
  'General Surgery',
  'General surgery and wards',
  'ICU',
  'Infectious Diseases Department',
  'Internal Medicine',
  'Laboratory',
  'Labour Suite',
  'Learning Disability Classes',
  'Maternity',
  'Neonatal',
  'Neonatal ICU',
  'Nephrology',
  'Neurology',
  'Neurology and Urology',
  'Neurosurgery',
  'Obstetrics & Gynaecology',
  'Occupational Therapy',
  'Oncology',
  'Opthalmology',
  'Orthopaedic and Plastic Surgery',
  'Orthopaedics',
  'Outpatients',
  'Paediatric Medicine',
  'Paediatric MICU',
  'Paediatric Oncology',
  'Paediatric Orthodontic Clinic',
  'Paediatric SICU',
  'Paediatric Speech Therapy',
  'Paediatric Surgery',
  'Paediatric Theatre',
  'Paediatrics',
  'Pathology',
  'Pharmacy',
  'Physiotherapy',
  'PICU/Burns Unit',
  'Plastic Surgery',
  'Psychiatry',
  'Radiography',
  'Rehabilitative Medicine',
  'Reproductive and Child Health',
  'Respiratory',
  'Sports and Exercise Medicine Clinic',
  'Surgical Gastroenterology',
  'Tropical Diseases',
  'Urology',
  'Urology and Renal Transplant',
];
