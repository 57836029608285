import React from 'react';
import DOMPurify from 'dompurify';

interface Props {
  className?: string;
  htmlString: string;
}

const HtmlRenderer: React.FC<Props> = ({ className, htmlString }) => {
  const config = {
    ADD_ATTR: ['class'],
  };

  const addHeadingClasses = (dirty: string): string => {
    return dirty
      .replace(/<h1/g, '<h1 class="text-4xl font-bold mb-4"')
      .replace(/<h2/g, '<h2 class="text-3xl font-bold mb-3"')
      .replace(/<h3/g, '<h3 class="text-2xl font-bold mb-2"')
      .replace(/<h4/g, '<h4 class="text-xl font-bold mb-2"')
      .replace(/<h5/g, '<h5 class="text-lg font-bold mb-1"')
      .replace(/<h6/g, '<h6 class="text-base font-bold mb-1"');
  };

  const processedContent = addHeadingClasses(htmlString);
  const sanitizedContent = DOMPurify.sanitize(processedContent, config);

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  );
};

export default HtmlRenderer;
