import React from 'react';

const ForgotPasswordEmailSent: React.FC = () => (
  <div className="flex flex-col items-center space-y-6 text-center mt-10">
    <h1 className="font-semibold tracking-wide text-indigo-600 uppercase">
      Forgot password email sent
    </h1>
    <div className="w-2/3 space-y-4 text-l text-gray-500 md:w-full md:space-y-2">
      <p>We've sent an email to the address you provided.</p>
      <p>Click on the link in the email to set a new password.</p>
    </div>
  </div>
);

export default ForgotPasswordEmailSent;
