import React from 'react';
import { DragOverEvent } from '@dnd-kit/core';

import { Event } from './types';
import {
  addDays,
  calculateDaysBetweenDates,
  createUTCDateFromDate,
  createUTCDateFromString,
} from '../../utils/dates';
import { CELL_WIDTH } from './constants';
import { EventMetadata as RoomEventMetadata } from '../admin/capacities/room/types';

export const getDaysBetweenClickAndEventStart = (dragEvent: DragOverEvent) => {
  const eventClickedXPos = (dragEvent.activatorEvent as unknown as MouseEvent)
    .clientX;
  const eventOverRectStartXPos = dragEvent.over?.rect.left;

  if (!eventOverRectStartXPos) return;

  const daysBetweenClickAndEventStart = Math.floor(
    (eventClickedXPos - eventOverRectStartXPos) / CELL_WIDTH
  );
  return daysBetweenClickAndEventStart;
};

const hasVillageMetadata = (
  metadata: unknown
): metadata is RoomEventMetadata => {
  return (
    typeof metadata === 'object' && metadata !== null && 'village' in metadata
  );
};

const clickedOnVillageDates = (
  event_start_date: Date,
  village: {
    start_date: string;
    end_date: string;
  } | null,
  daysBetweenClickAndEventStart: number
) => {
  if (!village) return false;
  const dateClicked = addDays(event_start_date, daysBetweenClickAndEventStart);
  const villageStartDate = createUTCDateFromString(village.start_date);
  const villageEndDate = createUTCDateFromString(village.end_date);
  return dateClicked >= villageStartDate && dateClicked <= villageEndDate;
};

const createNewEvents = <T>(
  event: Event<T>,
  numDays: number,
  endOffset: number
) => {
  const events: Event<T>[] = [];
  const bookingId = event.bookingId;
  const sectionId = event.sectionId;

  // Ensure dates are in UTC
  const event1_startDate = createUTCDateFromDate(event.start_date);
  const event1_endDate = addDays(event1_startDate, numDays - 1);
  events.push({
    ...event,
    start_date: event1_startDate,
    end_date: event1_endDate,
  });

  const event2_startDate = addDays(event1_endDate, 1 - endOffset);
  const event2_endDate = createUTCDateFromDate(event.end_date);
  const event2_id = `${bookingId}|${event2_startDate.toISOString()}|${sectionId}`;
  events.push({
    ...event,
    id: event2_id,
    start_date: event2_startDate,
    end_date: event2_endDate,
  });

  return { events: events, active: events[0] };
};

const createNewEventsWithVillage = <T>(
  event: Event<T>,
  village_start: Date,
  village_end: Date
) => {
  const events: Event<T>[] = [];
  const bookingId = event.bookingId;
  const sectionId = event.sectionId;

  if (village_start > event.start_date) {
    const firstEvent = {
      ...event,
      start_date: createUTCDateFromDate(event.start_date),
      end_date: createUTCDateFromDate(village_start),
    };
    firstEvent.end_date.setDate(firstEvent.end_date.getDate() - 1);
    events.push(firstEvent);
  }

  const villageEvent = {
    ...event,
    label: `${event.label} (Village)`,
    id: `${bookingId}|${village_start.toISOString()}|${sectionId}`,
    start_date: village_start,
    end_date: village_end,
  };
  villageEvent.end_date.setDate(villageEvent.end_date.getDate() - 1);
  events.push(villageEvent);

  if (village_end < event.end_date) {
    const finalEvent = {
      ...event,
      id: `${bookingId}|${village_end.toISOString()}|${sectionId}`,
      start_date: createUTCDateFromDate(village_end),
      end_date: createUTCDateFromDate(event.end_date),
    };
    finalEvent.start_date.setDate(finalEvent.start_date.getDate() + 1);
    events.push(finalEvent);
  }
  return {
    events: events,
    active: villageEvent,
  };
};

export const splitEvent = <T>(
  event: Event<T>,
  daysBetweenClickAndEventStart: number,
  endOffset = 0
): { events: Event<T>[]; active: Event<T> } => {
  // Split when clicked on a room booking's village dates
  if (
    hasVillageMetadata(event.metadata) &&
    event.metadata.village &&
    clickedOnVillageDates(
      event.start_date,
      event.metadata.village,
      daysBetweenClickAndEventStart
    )
  ) {
    const village = event.metadata.village;
    const village_start = createUTCDateFromString(village.start_date);
    const village_end = createUTCDateFromString(village.end_date);
    const events = createNewEventsWithVillage(
      event,
      village_start,
      village_end
    );
    return events;
  }

  // Split when not clicked on a room booking's village dates
  const splitAtDay =
    Math.floor((daysBetweenClickAndEventStart - endOffset) / 7) * 7 +
    (7 + endOffset);
  const events = createNewEvents(event, splitAtDay, endOffset);
  return events;
};

export const splitAndUpdateEvents = <T>(
  dragEvent: DragOverEvent,
  activeEvent: Event<T>,
  events: ReadonlyArray<Event<T>>,
  setEvents: React.Dispatch<
    React.SetStateAction<ReadonlyArray<Event<T>> | null>
  >,
  endOffset: number
) => {
  const daysBetweenClickAndEventStart =
    getDaysBetweenClickAndEventStart(dragEvent);

  const totalDaysInEvent = calculateDaysBetweenDates(
    activeEvent.start_date,
    activeEvent.end_date
  );

  if (
    daysBetweenClickAndEventStart &&
    daysBetweenClickAndEventStart < totalDaysInEvent - 6
  ) {
    const { events: splitEvents, active } = splitEvent(
      activeEvent,
      daysBetweenClickAndEventStart,
      endOffset
    );
    activeEvent.start_date = active.start_date;
    activeEvent.end_date = active.end_date;
    activeEvent.label = active.label;
    activeEvent.id = active.id;

    const eventsExcludingActive = splitEvents.filter(
      (event) => event.id !== activeEvent.id
    );
    setEvents([...events, ...eventsExcludingActive]);
  }
};
