import React from 'react';

interface CheckListIconProps {
  checked: boolean;
  size?: number;
}

const CheckListIcon: React.FC<CheckListIconProps> = ({
  checked,
  size = 18,
}) => {
  return checked ? (
    <svg
      className="is-enabled"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 2.9375C0.5 1.63867 1.53906 0.5625 2.875 0.5625H14.75C16.0488 0.5625 17.125 1.63867 17.125 2.9375V14.8125C17.125 16.1484 16.0488 17.1875 14.75 17.1875H2.875C1.53906 17.1875 0.5 16.1484 0.5 14.8125V2.9375ZM4.0625 10.0625C4.69336 10.0625 5.25 9.54297 5.25 8.875C5.25 8.24414 4.69336 7.6875 4.0625 7.6875C3.39453 7.6875 2.875 8.24414 2.875 8.875C2.875 9.54297 3.39453 10.0625 4.0625 10.0625ZM5.25 5.3125C5.25 4.68164 4.69336 4.125 4.0625 4.125C3.39453 4.125 2.875 4.68164 2.875 5.3125C2.875 5.98047 3.39453 6.5 4.0625 6.5C4.69336 6.5 5.25 5.98047 5.25 5.3125ZM4.0625 13.625C4.69336 13.625 5.25 13.1055 5.25 12.4375C5.25 11.8066 4.69336 11.25 4.0625 11.25C3.39453 11.25 2.875 11.8066 2.875 12.4375C2.875 13.1055 3.39453 13.625 4.0625 13.625ZM7.625 4.42188C7.10547 4.42188 6.73438 4.83008 6.73438 5.3125C6.73438 5.83203 7.10547 6.20312 7.625 6.20312H13.5625C14.0449 6.20312 14.4531 5.83203 14.4531 5.3125C14.4531 4.83008 14.0449 4.42188 13.5625 4.42188H7.625ZM7.625 7.98438C7.10547 7.98438 6.73438 8.39258 6.73438 8.875C6.73438 9.39453 7.10547 9.76562 7.625 9.76562H13.5625C14.0449 9.76562 14.4531 9.39453 14.4531 8.875C14.4531 8.39258 14.0449 7.98438 13.5625 7.98438H7.625ZM7.625 11.5469C7.10547 11.5469 6.73438 11.9551 6.73438 12.4375C6.73438 12.957 7.10547 13.3281 7.625 13.3281H13.5625C14.0449 13.3281 14.4531 12.957 14.4531 12.4375C14.4531 11.9551 14.0449 11.5469 13.5625 11.5469H7.625Z"
        fill="#414141"
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 6.5C4.58203 6.5 4.0625 5.98047 4.0625 5.3125C4.0625 4.68164 4.58203 4.125 5.25 4.125C5.88086 4.125 6.4375 4.68164 6.4375 5.3125C6.4375 5.98047 5.88086 6.5 5.25 6.5ZM7.92188 5.3125C7.92188 4.83008 8.29297 4.42188 8.8125 4.42188H12.375C12.8574 4.42188 13.2656 4.83008 13.2656 5.3125C13.2656 5.83203 12.8574 6.20312 12.375 6.20312H8.8125C8.29297 6.20312 7.92188 5.83203 7.92188 5.3125ZM7.92188 8.875C7.92188 8.39258 8.29297 7.98438 8.8125 7.98438H12.375C12.8574 7.98438 13.2656 8.39258 13.2656 8.875C13.2656 9.39453 12.8574 9.76562 12.375 9.76562H8.8125C8.29297 9.76562 7.92188 9.39453 7.92188 8.875ZM7.92188 12.4375C7.92188 11.9551 8.29297 11.5469 8.8125 11.5469H12.375C12.8574 11.5469 13.2656 11.9551 13.2656 12.4375C13.2656 12.957 12.8574 13.3281 12.375 13.3281H8.8125C8.29297 13.3281 7.92188 12.957 7.92188 12.4375ZM5.25 7.6875C5.88086 7.6875 6.4375 8.24414 6.4375 8.875C6.4375 9.54297 5.88086 10.0625 5.25 10.0625C4.58203 10.0625 4.0625 9.54297 4.0625 8.875C4.0625 8.24414 4.58203 7.6875 5.25 7.6875ZM5.25 13.625C4.58203 13.625 4.0625 13.1055 4.0625 12.4375C4.0625 11.8066 4.58203 11.25 5.25 11.25C5.88086 11.25 6.4375 11.8066 6.4375 12.4375C6.4375 13.1055 5.88086 13.625 5.25 13.625ZM0.5 2.9375C0.5 1.63867 1.53906 0.5625 2.875 0.5625H14.75C16.0488 0.5625 17.125 1.63867 17.125 2.9375V14.8125C17.125 16.1484 16.0488 17.1875 14.75 17.1875H2.875C1.53906 17.1875 0.5 16.1484 0.5 14.8125V2.9375ZM2.28125 2.9375V14.8125C2.28125 15.1465 2.54102 15.4062 2.875 15.4062H14.75C15.0469 15.4062 15.3438 15.1465 15.3438 14.8125V2.9375C15.3438 2.64062 15.0469 2.34375 14.75 2.34375H2.875C2.54102 2.34375 2.28125 2.64062 2.28125 2.9375Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default CheckListIcon;
