import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import DescriptionList, {
  DescriptionListProps,
} from '../../../framework-components/descriptionList/DescriptionList';
import { PencilEditLink } from '../../../framework-components/button/PencilEditButton';
import {
  CURRENCY,
  CURRENCY_TO_REGION,
  REGION_TO_CURRENCY,
} from '../booking-form/constants';
import { CurrencyType } from '../booking-form/types';

type PickedDescriptionListProps = Pick<
  DescriptionListProps,
  'items' | 'title' | 'subtitle'
>;

interface Props extends PickedDescriptionListProps {
  enquiryId?: string;
  anchor?: string;
}

const EditableDescriptionList: React.FC<Props> = ({
  enquiryId,
  anchor,
  ...props
}) => {
  const [currency, setCurrency] = useState<CurrencyType>(CURRENCY.GBP);
  const params = useParams();
  const { region } = params;
  useEffect(() => {
    if (
      region === 'en' ||
      region === 'eu' ||
      region === 'us' ||
      region === 'au' ||
      region === 'ca'
    ) {
      setCurrency(REGION_TO_CURRENCY[region]);
    }
  }, [region]);
  return (
    <>
      <DescriptionList {...props} />
      <PencilEditLink
        to={
          anchor
            ? `/booking-form/${enquiryId}/${CURRENCY_TO_REGION[currency]}#${anchor}`
            : `/booking-form/${enquiryId}/${CURRENCY_TO_REGION[currency]}`
        }
      />
    </>
  );
};

export default EditableDescriptionList;
