import React from 'react';
import { mutate } from 'swr';

import { LocationData } from './types';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateLocation } from './actions';
import HtmlRenderer from '../../../../framework-components/html-renderer/HtmlRenderer';

interface Props {
  hospitalId: string;
}

const Location: React.FC<Props> = ({ hospitalId }) => {
  return (
    <ManageSingleObject<LocationData>
      title="Location"
      modalTitle="Edit location"
      titleClassName="pb-4 text-sh2"
      simple
      getUrl={`/api/admin/hospital/${hospitalId}/location/get/`}
      descriptionFields={[
        {
          label: 'Location',
          render: (item) => <HtmlRenderer htmlString={item.location_html} />,
        },
      ]}
      formFields={[
        {
          type: 'richtext',
          name: 'location_html',
          label: 'Location',
        },
      ]}
      submitItem={(data) => updateLocation(data, hospitalId)}
      onSubmitSuccess={() => {
        mutate(`/api/admin/hospital/${hospitalId}/location/get/`);
      }}
    />
  );
};

export default Location;
