import React, { useCallback } from 'react';
import classNames from 'classnames';

import { Option } from '../../types';
import Checkbox from './Checkbox';

interface Props {
  name: string;
  label?: string;
  options: ReadonlyArray<Option>;
  onChange: (values: string[]) => void;
  currentValues: string[];
  row?: boolean;
  fontWeight?: 'normal' | 'medium' | 'bold';
  selectAll?: boolean;
}

const MultiChoice: React.FC<Props> = ({
  name,
  label,
  options,
  onChange,
  currentValues,
  row = false,
  fontWeight = 'medium',
  selectAll = false,
}) => {
  const handleChange = useCallback(
    (value: string, checked: boolean) => {
      if (checked) {
        onChange([...currentValues, value]);
      } else {
        onChange(currentValues.filter((item) => item !== value));
      }
    },
    [onChange, currentValues]
  );

  const handleSelectAllChange = (value: string, checked: boolean) => {
    if (value === `${name}-selectAll`) {
      if (checked) {
        onChange(options.map((option) => option.value)); // Select all
      } else {
        onChange([]); // Deselect all
      }
    }
  };

  const allSelected = options.every((option) =>
    currentValues.includes(option.value)
  );

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-8">
        {label && (
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
        )}
        {selectAll && (
          <div className="flex items-center">
            <Checkbox
              name={`${name}-selectAll`}
              option={{ label: 'Select All', value: 'selectAll' }}
              checked={allSelected}
              onChange={handleSelectAllChange}
              fontWeight="normal"
            />
          </div>
        )}
      </div>
      <div
        className={classNames('flex flex-wrap', {
          'gap-4': row,
          'flex-col gap-2': !row,
        })}
      >
        {options.map((option) => (
          <Checkbox
            key={option.value}
            option={option}
            name={option.value}
            checked={currentValues.includes(option.value)}
            onChange={handleChange}
            fontWeight={fontWeight}
          />
        ))}
      </div>
    </div>
  );
};

export default MultiChoice;
