import { Event, CalendarSection } from '../../../calendar/types';
import { EventMetadata, SectionMetadata, HouseType } from './types';
import { UNALLOCATED_SECTION_ID } from '../../../calendar/constants';

export const checkIsSectionFull = (
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  sectionId: string,
  capacity: number,
  houseType: HouseType | undefined
) => {
  if (sectionId === UNALLOCATED_SECTION_ID) {
    return false;
  }
  const eventsOnDateInSection = events.filter(
    (e) =>
      e.id !== event.id &&
      event.start_date <= e.end_date &&
      event.end_date >= e.start_date &&
      e.sectionId === sectionId
  );
  const sectionHasOtherGender =
    houseType === 'HOUSE'
      ? eventsOnDateInSection.some(
          (e) =>
            e.metadata &&
            event.metadata &&
            e.metadata.gender !== event.metadata.gender
        )
      : false;

  const sectionIsWrongType =
    houseType === 'HOUSE' && !!event.label.includes('(Village)');

  return (
    eventsOnDateInSection.length >= capacity ||
    sectionHasOtherGender ||
    sectionIsWrongType
  );
};

export const checkIsSectionDisabled = (
  event: Event<EventMetadata>,
  events: ReadonlyArray<Event<EventMetadata>>,
  section: CalendarSection<SectionMetadata>
) => {
  return checkIsSectionFull(
    event,
    events,
    section.id,
    section.capacity,
    section.metadata?.house_type
  );
};
