import React from 'react';
import { useParams } from 'react-router';
import { mutate } from 'swr';
import { Link } from 'react-router-dom';

import { useAPI } from '../../../../api/api';
import AdminStandardPage from '../../common/AdminStandardPage';
import ImageGallery from '../../common/ImageGallery/ImageGallery';
import {
  updateOccupancyDetails,
  addRoom,
  updateRoom,
  deleteRoom,
  updatePublicDescription,
  updateOtherNotes,
  updateBasicInfo,
  updateQuickFacts,
} from './actions';
import {
  createImage,
  getImages,
  setMainImage,
  deleteImage,
} from '../../common/ImageGallery/actions';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import ThreeColumns from '../../common/layout/ThreeColumns';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import ManageMultipleObjects from '../../../manage-multiple-objects/ManageMultipleObjects';
import {
  BasicInformation,
  OccupancyDetails,
  OtherNotes,
  PublicDescription,
  Room,
  QuickFacts,
} from './types';
import TwoColumns from '../../common/layout/TwoColumns';
import { PencilEditButton } from '../../../../framework-components/button/PencilEditButton';
import EditDetailsModal from '../../../manage-single-object/EditDetailsModal';
import useModalState from '../../../../hooks/modals';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import CardDetail from '../../../../framework-components/card/CardDetail';
import HtmlRenderer from '../../../../framework-components/html-renderer/HtmlRenderer';

const HouseDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const basicInfoUrl = `/api/admin/house/${id}/basic-information/`;
  const {
    data: houseInformation,
    isValidating,
    error,
  } = useAPI<BasicInformation>(basicInfoUrl);
  const { isOpen, openModal, closeModal } = useModalState();

  const onSubmitSuccessHandler = (basicInfo: BasicInformation) => {
    mutate(basicInfoUrl, basicInfo, false);
  };

  const renderHouseCard = (
    title: string,
    data: Record<string, string | undefined>
  ) => {
    return <CardDetail data={data} title={title} />;
  };

  const renderFirstColumn = () => {
    return renderHouseCard('Overview:', {
      Name: houseInformation?.name,
      Manager: houseInformation?.manager,
      Location: houseInformation?.destination?.label,
    });
  };
  const renderSecondColumn = () => {
    return renderHouseCard('Contact details:', {
      'House telephone': houseInformation?.telephone,
      'House email': houseInformation?.email,
    });
  };
  const renderThirdColumn = () => {
    return renderHouseCard('Address:', {
      Address: houseInformation?.address,
    });
  };

  const updateDescriptionSuccess = (description: PublicDescription) => {
    mutate(`/api/admin/house/${id}/description/`, description, false);
  };
  const updateNotesSuccess = (notes: OtherNotes) => {
    mutate(`/api/admin/house/${id}/notes/`, notes, false);
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Houses',
          href: null,
        },
        {
          label: houseInformation ? houseInformation.name : '',
          href: null,
        },
      ]}
      title={houseInformation ? houseInformation.name : ''}
      enableHome={false}
    >
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      {houseInformation && (
        <div className="mt-8">
          <div className="pb-9 space-y-6">
            <ThreeColumns
              leftColumn={renderFirstColumn()}
              middleColumn={renderSecondColumn()}
              rightColumn={renderThirdColumn()}
            />
            <div className="w-full flex justify-end">
              <PencilEditButton onClick={openModal} />
            </div>
            <EditDetailsModal<BasicInformation>
              isOpen={isOpen}
              closeModal={closeModal}
              submitItem={updateBasicInfo}
              onSubmitSuccess={onSubmitSuccessHandler}
              data={houseInformation}
              fields={[
                {
                  type: 'smalltext',
                  name: 'name',
                  label: 'Name',
                },
                {
                  type: 'smalltext',
                  name: 'manager',
                  label: 'Accomodation manager',
                },
                {
                  type: 'remote-dropdown',
                  name: 'destination',
                  label: 'Location',
                  path: '/api/booking/destinations/',
                },
                {
                  type: 'smalltext',
                  name: 'telephone',
                  label: 'Telephone',
                },
                {
                  type: 'smalltext',
                  name: 'email',
                  label: 'Email',
                },
                {
                  type: 'smalltext',
                  name: 'address',
                  label: 'Address',
                },
              ]}
            />
          </div>
          <TwoColumns
            leftColumn={
              <>
                {id && (
                  <ManageSingleObject<OccupancyDetails>
                    title="Occupancy details:"
                    getUrl={`/api/admin/house/${id}/occupancy-stats/`}
                    submitItem={(values) => updateOccupancyDetails(id, values)}
                    descriptionFields={[
                      {
                        label: 'Max occupancy',
                        render: (item: OccupancyDetails) =>
                          item['maximum_occupants'],
                      },
                      {
                        label: 'Warn level',
                        render: (item: OccupancyDetails) =>
                          item['warn_at_occupants'],
                      },
                      {
                        label: 'Rooms',
                        render: (item: OccupancyDetails) =>
                          item['number_of_rooms'],
                      },
                    ]}
                    formFields={[
                      {
                        type: 'smalltext',
                        name: 'maximum_occupants',
                        label: 'Max occupancy',
                        extraProps: { type: 'number' },
                      },
                      {
                        type: 'smalltext',
                        name: 'warn_at_occupants',
                        label: 'Warn level',
                        extraProps: { type: 'number' },
                      },
                    ]}
                  />
                )}
                {id && (
                  <ManageMultipleObjects<Room>
                    title="Rooms:"
                    getUrl={`/api/admin/house/${id}/room/list/`}
                    tableFields={[
                      {
                        name: 'name',
                        header: 'Name',
                        render: (item: Room) => item['name'],
                      },
                      {
                        name: 'location',
                        header: 'Location',
                        render: (item: Room) => item['location'],
                      },
                      {
                        name: 'capacity',
                        header: 'Capacity',
                        render: (item: Room) => item['capacity'],
                      },
                    ]}
                    quickFormFields={[
                      {
                        type: 'smalltext',
                        name: 'name',
                        label: 'Name',
                      },
                      {
                        type: 'smalltext',
                        name: 'location',
                        label: 'Location',
                      },
                      {
                        type: 'smalltext',
                        name: 'capacity',
                        label: 'Capacity',
                        extraProps: { type: 'number' },
                      },
                    ]}
                    addHandler={(values) => addRoom(id, values)}
                    editHandler={updateRoom}
                    deleteHandler={deleteRoom}
                    onAddSuccess={() =>
                      mutate(
                        `/api/admin/house/${id}/occupancy-stats/`,
                        (stats: OccupancyDetails) => ({
                          ...stats,
                          number_of_rooms: stats.number_of_rooms + 1,
                        }),
                        false
                      )
                    }
                    onDeleteSuccess={() =>
                      mutate(
                        `/api/admin/house/${id}/occupancy-stats/`,
                        (stats: OccupancyDetails) => ({
                          ...stats,
                          number_of_rooms: stats.number_of_rooms - 1,
                        }),
                        false
                      )
                    }
                    noDataFoundMessage="There are currently no rooms for this house."
                  />
                )}
                <AdminWidgetContainer hasBorder hasShadow>
                  <div className="flex items-center justify-between flex-wrap gap-4">
                    <h4 className="text-h4">Link to House Capacity Cals:</h4>
                    <Link to="/admin/capacities/house-village-capacities">
                      <PrimaryButton label="View Calendar" />
                    </Link>
                  </div>
                </AdminWidgetContainer>
              </>
            }
            rightColumn={
              <>
                {id && (
                  <ManageSingleObject<PublicDescription>
                    title="Public Description:"
                    getUrl={`/api/admin/house/${id}/description/`}
                    submitItem={(values) => updatePublicDescription(id, values)}
                    onSubmitSuccess={updateDescriptionSuccess}
                    descriptionFields={[
                      {
                        label: 'Description',
                        render: (item: PublicDescription) => (
                          <HtmlRenderer htmlString={item.description} />
                        ),
                      },
                    ]}
                    formFields={[
                      {
                        type: 'richtext',
                        name: 'description',
                        label: 'Description',
                        extraProps: {
                          placeholder: 'Enter a description',
                          rows: 6,
                        },
                      },
                    ]}
                    simple
                  />
                )}
                {id && (
                  <ManageSingleObject<OtherNotes>
                    title="Other Notes:"
                    getUrl={`/api/admin/house/${id}/notes/`}
                    submitItem={(values) => updateOtherNotes(id, values)}
                    onSubmitSuccess={updateNotesSuccess}
                    descriptionFields={[
                      {
                        label: 'Notes',
                        render: (item: OtherNotes) => (
                          <HtmlRenderer htmlString={item['house_notes']} />
                        ),
                      },
                    ]}
                    formFields={[
                      {
                        type: 'richtext',
                        name: 'house_notes',
                        label: 'Other Notes',
                        extraProps: {
                          placeholder: 'Your other notes will show here',
                          rows: 6,
                        },
                      },
                    ]}
                    simple
                  />
                )}
                {id && (
                  <ImageGallery
                    title="Main house photo:"
                    getImages={() => getImages('house_id', id)}
                    setMainImage={(imageId: string) =>
                      setMainImage('house_id', id, imageId)
                    }
                    deleteImage={(imageId: string) =>
                      deleteImage('house_id', id, imageId)
                    }
                    onSuccessfulUpload={(file_id: string) =>
                      createImage('house_id', id, file_id)
                    }
                  />
                )}
                {id && (
                  <ManageSingleObject<QuickFacts>
                    title="Quick facts:"
                    getUrl={`/api/admin/house/${id}/quick-facts/`}
                    submitItem={(values) => updateQuickFacts(id, values)}
                    descriptionFields={[
                      {
                        label: 'Population',
                        render: (item: QuickFacts) => item.population,
                      },
                      {
                        label: 'Distance to town',
                        render: (item: QuickFacts) => item.distance,
                      },
                      {
                        label: 'Travel time',
                        render: (item: QuickFacts) => item.travel_time,
                      },
                      {
                        label: 'Travel method',
                        render: (item: QuickFacts) => item.travel_method,
                      },
                      {
                        label: 'Local language',
                        render: (item: QuickFacts) => item.language,
                      },
                      {
                        label: 'Main local activity',
                        render: (item: QuickFacts) => item.activity,
                      },
                    ]}
                    formFields={[
                      {
                        type: 'smalltext',
                        name: 'population',
                        label: 'Population',
                        extraProps: { type: 'number' },
                      },
                      {
                        type: 'smalltext',
                        name: 'distance',
                        label: 'Distance to town',
                      },
                      {
                        type: 'smalltext',
                        name: 'travel_time',
                        label: 'Travel time',
                      },
                      {
                        type: 'smalltext',
                        name: 'travel_method',
                        label: 'Travel method',
                      },
                      {
                        type: 'smalltext',
                        name: 'language',
                        label: 'Local language',
                      },
                      {
                        type: 'smalltext',
                        name: 'activity',
                        label: 'Main local activity',
                      },
                    ]}
                  />
                )}
              </>
            }
          />
        </div>
      )}
    </AdminStandardPage>
  );
};

export default HouseDetail;
