import React, { useCallback } from 'react';

import AdminStandardPage from '../../common/AdminStandardPage';
import Table from '../../../../framework-components/table/Table';
import { HospitalTableData } from './types';
import { getHospitalTableData } from './actions';

const HospitalList: React.FC = () => {
  const getFilterOptions = useCallback(() => {
    return fetch('/api/admin/hospital/filters/?')
      .then((response) => response.json())
      .then((data) => data);
  }, []);

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Directories',
          href: null,
        },
        {
          label: 'Hospitals',
          href: null,
        },
      ]}
      title="Hospitals"
      enableHome={false}
    >
      <div className="mt-4">
        <Table<HospitalTableData>
          columnsConfig={{
            name: {
              key: 'name',
              header: 'Name',
              enableSort: true,
              renderCell: (hospital) => (
                <a
                  href={`/admin/directories/hospitals/${hospital.id}/`}
                  title={hospital.name}
                >
                  {hospital.name}
                </a>
              ),
            },
          }}
          getData={getHospitalTableData}
          getFilterOptions={getFilterOptions}
          enablePagination
          enableSearch
          additionalFiltersConfig={{
            archived: {
              key: 'archived',
              label: 'Archived',
              filterType: 'toggle',
              size: 'sm',
            },
            destination: {
              key: 'destination',
              label: 'Destination',
              filterType: 'local-dropdown',
            },
          }}
        />
      </div>
    </AdminStandardPage>
  );
};

export default HospitalList;
