import React from 'react';

import { SecondaryButton } from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import ArchiveBookingModal from './ArchiveBookingModal';

interface Props {
  bookingId: string;
  isArchived: boolean;
}

const ArchiveBooking: React.FC<Props> = ({ bookingId, isArchived }) => {
  const { isOpen, openModal, closeModal } = useModalState();
  return (
    <>
      <SecondaryButton paddingClassName="py-2 px-4" onClick={openModal}>
        {isArchived ? 'Unarchive student' : 'Archive student'}
      </SecondaryButton>
      <ArchiveBookingModal
        isArchived={isArchived}
        bookingId={bookingId}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default ArchiveBooking;
