import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import Modal from '../../../modal';
import QuickForm from '../../../../framework-components/quickform/QuickForm';
import { apiRequest } from '../../../../api/api';
import {
  Option,
  XeroCreateStatus,
  XERO_CREATE_STATUS,
} from '../../../../types';
import { XERO_CREATE_STATUS_LABELS } from '../../../../constants';
import { InvoiceType } from './types';
import { FieldType } from '../../../../framework-components/quickform/Field';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  bookingId: string;
  invoiceId: string;
  type: InvoiceType;
  initialValues: FormValues;
}

interface FormValues {
  xero_invoice_id: string;
  xero_invoice_number: string;
  xero_create_status: Option<XeroCreateStatus> | null;
  current_holiday_invoice?: boolean;
  current_village_invoice?: boolean;
  current_addon_invoice?: boolean;
}

const getFields = (type: InvoiceType) => {
  const fields: ReadonlyArray<FieldType> = [
    {
      type: 'smalltext',
      name: 'xero_invoice_id',
      label: 'Invoice ID',
    },
    {
      type: 'smalltext',
      name: 'xero_invoice_number',
      label: 'Invoice number',
    },
    {
      type: 'local-dropdown',
      name: 'xero_create_status',
      label: 'Status',
      options: [
        {
          value: XERO_CREATE_STATUS.NOT_STARTED,
          label: XERO_CREATE_STATUS_LABELS[XERO_CREATE_STATUS.NOT_STARTED],
        },
        {
          value: XERO_CREATE_STATUS.IN_PROGRESS,
          label: XERO_CREATE_STATUS_LABELS[XERO_CREATE_STATUS.IN_PROGRESS],
        },
        {
          value: XERO_CREATE_STATUS.COMPLETED,
          label: XERO_CREATE_STATUS_LABELS[XERO_CREATE_STATUS.COMPLETED],
        },
        {
          value: XERO_CREATE_STATUS.FAILED,
          label: XERO_CREATE_STATUS_LABELS[XERO_CREATE_STATUS.FAILED],
        },
      ],
    },
  ];
  if (type === 'INVOICE') {
    return fields.concat([
      {
        type: 'checkbox',
        name: 'current_holiday_invoice',
        label: 'Current holiday invoice',
        option: {
          value: 'current_holiday_invoice',
          label:
            'Check this box if this invoice is the current active invoice containing the main holiday line items',
        },
      },
      {
        type: 'checkbox',
        name: 'current_village_invoice',
        label: 'Current village invoice',
        option: {
          value: 'current_village_invoice',
          label:
            'Check this box if this invoice is the current active invoice containing the village experience line item',
        },
      },
      {
        type: 'checkbox',
        name: 'current_addon_invoice',
        label: 'Current addon invoice',
        option: {
          value: 'current_addon_invoice',
          label:
            'Check this box if this invoice is the current active invoice containing the addons (e.g. safari) line item',
        },
      },
    ]);
  }
  return fields;
};

const UpdateInvoiceModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  bookingId,
  invoiceId,
  type,
  initialValues,
}) => {
  const focusRef = React.useRef(null);
  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 flex flex-col ml-4 mr-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Edit invoice
        </Dialog.Title>
        <QuickForm
          initialValues={initialValues}
          fields={getFields(type)}
          onSubmit={(data: FormValues) => {
            return apiRequest<FormValues>(
              `/api/admin/bookings/invoice/${invoiceId}/update/`,
              {
                method: 'POST',
                body: JSON.stringify({
                  ...data,
                  xero_create_status: data.xero_create_status?.value,
                }),
              }
            );
          }}
          onSubmitSuccess={() => {
            toast.success('Successfully updated invoice');
            closeModal();
            mutate(
              `/api/admin/bookings/${bookingId}/get-invoice-and-credit-note-list/`
            );
            mutate(`/api/admin/bookings/${bookingId}/get-migration-issues/`);
          }}
          buttonLabel="Submit"
        />
      </div>
    </Modal>
  );
};

export default UpdateInvoiceModal;
