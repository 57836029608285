import React from 'react';
import {
  FastField,
  useFormikContext,
  FieldInputProps,
  FormikProps,
} from 'formik';

import { Option } from '../../../types';
import { GENDER } from './constants';
import { GENDER_TO_DISPLAY_NAME } from './labels';
import Input from '../../../framework-components/input/Input';
import { FormValues, GenderType } from './types';
import { FormLabel } from './FormLabel';
import { useAPI } from '../../../api/api';
import LocalDropdown from '../../../framework-components/dropdown/LocalDropdown';
import Radio from '../../../framework-components/select/Radio';
import ErrorText from '../../error-text';
import { getFormikErrors } from '../../../utils/forms';
import UKFormattedDatePicker from '../../../framework-components/date-picker/UKFormattedDatePicker';

const genderOptions: ReadonlyArray<Option<GenderType>> = [
  { value: GENDER.MALE, label: GENDER_TO_DISPLAY_NAME[GENDER.MALE] },
  { value: GENDER.FEMALE, label: GENDER_TO_DISPLAY_NAME[GENDER.FEMALE] },
];

const ContactDetails: React.FC = () => {
  const formik = useFormikContext<FormValues>();

  const { data: countryOptions } = useAPI<ReadonlyArray<Option>>(
    `/api/booking/countries/`
  );

  const { data: nationalityOptions } = useAPI<ReadonlyArray<Option>>(
    `/api/booking/nationalities/`
  );

  return (
    <div className="space-y-8" id="contact">
      <h2 className="text-mob-h3 xs:text-h3">Your contact details</h2>
      <div>
        <div className="flex flex-col sm:flex-row sm:gap-2 sm:items-center">
          <label
            htmlFor="date_of_birth"
            className="block text-sm font-medium leading-6 text-gray-900 sm:w-1/2"
          >
            <FormLabel label="Date of birth" required />
          </label>
          <FastField name="date_of_birth">
            {({
              field,
              form,
            }: {
              field: FieldInputProps<Date>;
              form: FormikProps<FormValues>;
            }) => {
              const nowMinusEighteen = new Date();
              nowMinusEighteen.setUTCFullYear(
                nowMinusEighteen.getUTCFullYear() - 18,
                nowMinusEighteen.getUTCMonth(),
                nowMinusEighteen.getUTCDate()
              );
              const minimumDate = new Date();
              minimumDate.setUTCFullYear(
                minimumDate.getUTCFullYear() - 80,
                minimumDate.getUTCMonth(),
                minimumDate.getUTCDate()
              );
              return (
                <UKFormattedDatePicker
                  autoComplete="off"
                  maxDate={nowMinusEighteen}
                  minDate={minimumDate}
                  id="date_of_birth"
                  selected={field.value}
                  onChange={(date: Date) => {
                    form.setFieldValue(field.name, date);
                  }}
                  placeholderText="Please select"
                  showYearDropdown
                  dropdownMode="select"
                  wrapperClassName="w-booking-form-input"
                />
              );
            }}
          </FastField>
        </div>
        {formik.touched.date_of_birth && formik.errors.date_of_birth && (
          <ErrorText className="text-sm">
            {formik.errors.date_of_birth}
          </ErrorText>
        )}
      </div>
      <FastField name="gender">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<GenderType>;
          form: FormikProps<FormValues>;
        }) => (
          <Radio
            label={<FormLabel label="Gender" required />}
            options={genderOptions}
            name={field.name}
            onChange={(value: string | number) => {
              form.setFieldValue(field.name, value);
            }}
            defaultValue={undefined}
            row
          />
        )}
      </FastField>
      <div>
        <LocalDropdown
          id="nationality"
          label={<FormLabel label="Nationality" required />}
          options={nationalityOptions}
          name="nationality"
          value={formik.values.nationality}
          onSelect={(option: Option | null) => {
            formik.setFieldValue('nationality', option);
          }}
          errors={getFormikErrors(formik, 'nationality')}
          row
          labelClassName="font-medium"
          inputWidth="w-booking-form-input"
        />
        <p className="mt-2 ml-2 sm:mt-0 text-wtw-secondary-text text-sm">
          As shown on your passport.
        </p>
      </div>
      <FastField name="phone">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="phone"
            label={<FormLabel label="Phone number" required />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter phone number"
            row
            inputWidth="w-booking-form-input"
          />
        )}
      </FastField>
      <FastField name="postcode">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="postcode"
            label={<FormLabel label="Postcode/Zip" required />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter postcode/zip"
            row
            inputWidth="w-booking-form-input"
          />
        )}
      </FastField>
      <FastField name="line1">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="line1"
            label={<FormLabel label="Address line 1" required />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter address"
            row
            inputWidth="w-booking-form-input"
          />
        )}
      </FastField>
      <FastField name="line2">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="line2"
            label={<FormLabel label="Address line 2" />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter address"
            row
            inputWidth="w-booking-form-input"
          />
        )}
      </FastField>
      <FastField name="city">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="city"
            label={<FormLabel label="City" required />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter city"
            row
            inputWidth="w-booking-form-input"
          />
        )}
      </FastField>
      <FastField name="county">
        {({
          field,
          form,
        }: {
          field: FieldInputProps<string>;
          form: FormikProps<FormValues>;
        }) => (
          <Input
            id="county"
            label={<FormLabel label="County/State" />}
            name={field.name}
            defaultValue={field.value}
            onChange={field.onChange}
            errors={getFormikErrors(form, field.name)}
            placeholder="Enter county/state"
            row
            inputWidth="w-booking-form-input"
          />
        )}
      </FastField>
      <LocalDropdown
        id="country"
        label={<FormLabel label="Country" required />}
        options={countryOptions}
        errors={getFormikErrors(formik, 'country')}
        name="country"
        value={formik.values.country}
        onSelect={(option: Option | null) => {
          formik.setFieldValue('country', option);
        }}
        row
        allowDropdownAutoComplete
        labelClassName="font-medium"
        inputWidth="w-booking-form-input"
      />
    </div>
  );
};

export default ContactDetails;
