import React from 'react';

const ViewAsIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.5607 10.9393L13.0607 6.43934C12.4749 5.85355 11.5251 5.85355 10.9393 6.43934C10.3536 7.02513 10.3536 7.97487 10.9393 8.56066L12.8787 10.5L7.5 10.5C6.67157 10.5 6 11.1716 6 12C6 12.8284 6.67157 13.5 7.5 13.5H12.8787L10.9393 15.4393C10.3536 16.0251 10.3536 16.9749 10.9393 17.5607C11.5251 18.1464 12.4749 18.1464 13.0607 17.5607L17.5607 13.0607C18.1464 12.4749 18.1464 11.5251 17.5607 10.9393Z"
        fill="#2568EB"
      />
    </svg>
  );
};

export default ViewAsIcon;
