import React from 'react';

import { Option } from '../../../../types';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import Calendar from '../../../calendar/Calendar';
import SelectDates from '../SelectDates';
import { useCapacityCalendar } from '../hooks';
import { getEventForDay } from '../utils';
import { EventMetadata } from './types';
import { getOverlayColour } from '../department/utils';

interface Props {
  dayQueryParam: string | null;
  monthQueryParam: string | null;
  yearQueryParam: string | null;
  period: string;
  destination: Option | null;
}

const HouseVillageCapacityCalendar: React.FC<Props> = ({
  dayQueryParam,
  monthQueryParam,
  yearQueryParam,
  destination,
  period,
}) => {
  const {
    selectedDates,
    setSelectedDates,
    events,
    sections,
    errors,
    startDate,
    endDate,
    selectedEvent,
    setSelectedEvent,
  } = useCapacityCalendar<EventMetadata, null>(
    destination,
    dayQueryParam,
    monthQueryParam,
    yearQueryParam,
    period,
    `/api/admin/calendar/house-bookings/${destination?.value}/`,
    `/api/admin/calendar/house-sections/${destination?.value}/`,
    false
  );

  if (errors.events || errors.sections) {
    return <ErrorOverlay detail={errors.events || errors.sections} />;
  }

  return (
    <div className="border border-gray-300 bg-white rounded-lg w-fit">
      <div className="flex flex-wrap justify-start items-end gap-4 p-4">
        <SelectDates
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          isDisabled={false}
        />
      </div>
      {startDate && endDate && destination && (
        <div className="p-4">
          <Calendar<EventMetadata, null>
            isEditing={false}
            startDate={startDate}
            endDate={endDate}
            events={events}
            sections={sections}
            getEventForDay={getEventForDay}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            getOverlayColour={getOverlayColour}
            hideEmptyRows
          />
        </div>
      )}
      {!destination && (
        <div className="px-4 pb-4">Please select a location.</div>
      )}
      {(!startDate || !endDate) && (
        <div className="px-4 pb-4">Please select a year, month and period.</div>
      )}
    </div>
  );
};

export default HouseVillageCapacityCalendar;
