import React, { useCallback } from 'react';

import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import { apiRequest } from '../../../../../api/api';
import Table from '../../../../../framework-components/table/Table';
import {
  CombinedFilters,
  TableData,
} from '../../../../../framework-components/table/types';
import {
  formatDateTimeShorthandMonthDayYear,
  formatDateToReadableStringShorthandMonth,
} from '../../../../../utils/dates';
import { Option } from '../../../../../types';
import Tooltip from '../../../../../framework-components/tooltip/Tooltip';

interface DepartureListData {
  id: string;
  applicant: string;
  destination: {
    id: string;
    name: string;
  };
  end_date: string;
  flight_departure: string;
  flight_departure_number: string;
  independant_travel_departure: string;
  independant_travel_departure_notes: string;
}

interface DepartureFilters extends Record<string, Option[]> {
  destination: Option[];
  year: Option[];
  month: Option[];
}

const ApplicantDepartures: React.FC = () => {
  const getDepartureListData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<DepartureListData>>(
        '/api/admin/bookings/departures/list/?' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const getFilterOptions = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<DepartureFilters>(
      '/api/admin/bookings/departures/filters/?' +
        new URLSearchParams(selectedFilters).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: '/admin/bookings/pending',
        },
        {
          label: 'Departures',
          href: null,
        },
      ]}
      title="Departures"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: 'Arrivals',
              href: '/admin/bookings/arrivals',
              current: false,
            },
            {
              label: 'Departures',
              href: '/admin/bookings/departures',
              current: true,
            },
          ]}
          variant="pilled_tab"
        />
      </div>
      <Table
        getData={getDepartureListData}
        getFilterOptions={getFilterOptions}
        enablePagination
        enableSearch
        canPrint
        columnsConfig={{
          applicant: {
            key: 'applicant',
            header: 'Name',
            enableSort: true,
            renderCell: (data) => (
              <a
                href={`/admin/bookings/${data.id}/applicant-profile/`}
                title={data.applicant}
              >
                {data.applicant}
              </a>
            ),
          },
          destination: {
            key: 'destination',
            header: 'Destination',
            enableSort: true,
            filterType: 'local-dropdown',
            renderCell: (data) => data.destination.name,
          },
          end_date: {
            key: 'end_date',
            header: 'House departure',
            enableSort: true,
            renderCell: (data) => {
              return formatDateToReadableStringShorthandMonth(data.end_date);
            },
          },
          flight_details: {
            key: 'flight_departure',
            header: 'Flight details',
            enableSort: true,
            renderCell: (data) => {
              const arrival = data.flight_departure
                ? formatDateTimeShorthandMonthDayYear(data.flight_departure)
                : '';
              const number = data.flight_departure_number
                ? data.flight_departure_number
                : '';
              const tooltipId = `flight-departure-${data.id}`;
              return data.independant_travel_departure ? (
                <p
                  data-tooltip-id={tooltipId}
                  data-tooltip-content={data.independant_travel_departure_notes}
                >
                  Alternative transport
                  <Tooltip id={tooltipId} />
                </p>
              ) : (
                `${arrival} ${number}`
              );
            },
          },
        }}
        additionalFiltersConfig={{
          year: {
            key: 'year',
            label: 'Year',
            filterType: 'local-dropdown',
          },
          month: {
            key: 'month',
            label: 'Month',
            filterType: 'local-dropdown',
          },
        }}
      />
    </AdminStandardPage>
  );
};

export default ApplicantDepartures;
