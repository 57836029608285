import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import Modal from '../../../modal';
import { changeTripStartDate } from './actions';
import QuickForm from '../../../../framework-components/quickform/QuickForm';
import useModalState from '../../../../hooks/modals';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import { isSunday } from '../../../../utils/dates';

interface Props {
  bookingId: string;
  currentStartDate: string;
}

const MoveTripStartDate = ({ bookingId, currentStartDate }: Props) => {
  const focusRef = React.useRef(null);
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <>
      <PrimaryButton className="w-full" onClick={openModal}>
        Change trip start date
      </PrimaryButton>
      <Modal
        isOpen={isOpen ?? false}
        onClose={closeModal}
        initialFocus={focusRef}
      >
        <div className="space-y-6 flex flex-col items-center">
          <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
            Change trip start date
          </Dialog.Title>
          <Dialog.Description>
            <div className="flex flex-col gap-2 text-sm">
              <QuickForm
                onSubmit={(data) => {
                  return changeTripStartDate(bookingId, data.start_date);
                }}
                onSubmitSuccess={() => {
                  toast.success('Trip start date changed successfully');
                  closeModal();
                  mutate(`/api/admin/bookings/${bookingId}/get-trip-details/`);
                  mutate(
                    `/api/admin/bookings/${bookingId}/get-accomodation-details/`
                  );
                }}
                onSubmitFailure={() => {
                  toast.error('Unable to change trip start date');
                }}
                initialValues={{
                  start_date: currentStartDate,
                }}
                fields={[
                  {
                    type: 'datetime',
                    name: 'start_date',
                    label: `Start date`,
                    extraProps: {
                      filterDate: isSunday,
                      dateFormat: 'yyyy-MM-dd',
                    },
                  },
                ]}
              />
            </div>
          </Dialog.Description>
        </div>
      </Modal>
    </>
  );
};

export default MoveTripStartDate;
