import React from 'react';

import AdminStandardPage from '../../common/AdminStandardPage';
import DocumentsListingTable from './DocumentsListingTable';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';

const DocumentsListingPage: React.FC = () => {
  return (
    <div>
      <AdminStandardPage
        breadcrumbs={[
          {
            label: 'Directories',
            href: null,
          },
          {
            label: 'Documents',
            href: null,
          },
        ]}
        title="Documents"
        enableHome={false}
      >
        <BlockTabs
          tabs={[
            {
              label: 'Documents',
              href: '/admin/directories/documents',
              current: true,
            },
            {
              label: 'Information Pack',
              href: '/admin/directories/info-pack',
              current: false,
            },
            {
              label: 'Templated Resources',
              href: '/admin/directories/templated-resources',
              current: false,
            },
          ]}
          variant="pilled_tab"
        />
        <DocumentsListingTable />
      </AdminStandardPage>
    </div>
  );
};

export default DocumentsListingPage;
