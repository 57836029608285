import React from 'react';

import { Bookings, Booking } from '../context-providers';

interface SelectBookingsProps {
  bookings: Bookings;
  setActiveBooking: (booking: Booking) => void;
}
const SelectBooking: React.FC<SelectBookingsProps> = ({
  bookings,
  setActiveBooking,
}) => {
  return (
    <>
      {bookings.map((booking: Booking) => (
        <button key={booking.id} onClick={() => setActiveBooking(booking)}>
          {booking.id}
        </button>
      ))}
    </>
  );
};

export default SelectBooking;
