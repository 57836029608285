import React from 'react';

const ClinicalExperienceLeftSideText: React.FC = () => {
  return (
    <div className="space-y-6 text-md">
      <p>
        This page provides the opportunity to outline the clinical placements
        you will have completed on your course prior to departing, including the
        duration and any skills learned or practiced.
      </p>
      <p>
        The information you provide here will be discussed in full with your
        Operations Manager during the clinical interest call. They will email
        you around 6-8 weeks before your departure to arrange this call. You
        will discuss your clinical interests and the hospital and department(s)
        where you'll be placed. It is therefore important you complete this
        section ahead of the call, to ensure the discussion is focused on your
        aims for the placement.
      </p>
      <p>
        The space below is limited, so please document clinical and theoretical
        areas that are most relevant to the placement you are looking to
        undertake in Hue.
      </p>
      <p>
        Whilst we endeavour to ensure supervising staff have reviewed your
        information prior to your arrival, with the heavy work load hospital
        staff have, it is always wise to revisit these points once you begin
        placement.
      </p>
    </div>
  );
};

export default ClinicalExperienceLeftSideText;
