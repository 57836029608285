import React from 'react';

import useModalState from '../../../hooks/modals';
import TermsAndConditionsModal from './TermsAndConditionsModal';

interface Props {
  isAcceptedTerms: boolean;
  setIsAcceptedTerms: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReviewTermsConditions: React.FC<Props> = ({
  isAcceptedTerms,
  setIsAcceptedTerms,
}) => {
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <div className="space-y-8">
      <h3 className="text-h3 text-gray-900">Terms and conditions</h3>
      <div className="flex gap-4 items-center">
        <input
          type="checkbox"
          readOnly
          onClick={openModal}
          checked={isAcceptedTerms}
        />
        <p className="text-sm">
          <span aria-hidden className="text-red-400">
            *
          </span>
          <span>
            Please read and accept our{' '}
            <button className="text-wtw-logo-blue" onClick={openModal}>
              terms & conditions
            </button>{' '}
            including the cancellation and refund terms.
          </span>
        </p>
      </div>
      <TermsAndConditionsModal
        isOpen={isOpen}
        closeModal={closeModal}
        setIsAcceptedTerms={setIsAcceptedTerms}
      />
    </div>
  );
};

export default ReviewTermsConditions;
