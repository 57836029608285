import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import { DEPARTMENT_OPTIONS } from '../constants';
import Checkbox from '../../../../../framework-components/select/Checkbox';
import { DepartmentAndSupervisorData } from '../types';
import { addDepartment } from '../actions';
import { useDeleteDepartment } from './hooks';

interface Props {
  hospitalId: string;
  departments: Array<DepartmentAndSupervisorData>;
}

const SelectDepartments: React.FC<Props> = ({ hospitalId, departments }) => {
  const [loading, setLoading] = useState(false);

  const departmentMap = useMemo(
    () => new Map(departments.map((item) => [item.name, item.id])),
    [departments]
  );

  const addDepartmentHandler = async (department: string) => {
    try {
      setLoading(true);
      const { id } = await addDepartment(hospitalId, department);
      toast.success('Department added successfully');
      mutate(
        `/api/admin/hospital/${hospitalId}/departments-and-supervisors/get/`,
        (data: Array<DepartmentAndSupervisorData>) => {
          const updatedData = [
            ...data,
            {
              id,
              name: department,
              supervisor: '',
              capacity: 0,
              description: '',
              discipline_capacities: [],
            },
          ];
          updatedData.sort((a, b) => a.name.localeCompare(b.name));
          return updatedData;
        },
        false
      );
    } catch (error) {
      toast.error('Failed to add department');
    } finally {
      setLoading(false);
    }
  };

  const { removeDepartmentHandler } = useDeleteDepartment(
    hospitalId,
    setLoading
  );

  const selectHandler = (department: string, checked: boolean) => {
    if (checked) {
      addDepartmentHandler(department);
    } else {
      const departmentId = departmentMap.get(department);
      if (!departmentId) return;
      removeDepartmentHandler(departmentId);
    }
  };

  return (
    <div>
      {DEPARTMENT_OPTIONS.map((department) => (
        <Checkbox
          key={department}
          name={department}
          option={{
            value: departmentMap.get(department) ?? '',
            label: department,
          }}
          checked={departmentMap.has(department)}
          onChange={selectHandler}
          disabled={loading}
        />
      ))}
    </div>
  );
};

export default SelectDepartments;
