import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import MyTripStandardPage from '../layout/MyTripStandardPage';
import WidgetContainer from '../components/WidgetContainer';
import FinancesToDateList from './FinancesToDateList';
import { MyBookingsContext } from '../context-providers';
import AvailableAddons from '../components/available-addons/AvailableAddons';

const MyFinancesPage: React.FC = () => {
  const location = useLocation();
  const { activeBooking } = useContext(MyBookingsContext);
  const currency = activeBooking?.currency;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get('payment_status');

    if (paymentStatus === 'success') {
      toast.success('Your payment has been successful.');
    } else if (paymentStatus === 'failure') {
      toast.error('Your payment has failed. Please try again!');
    }
  }, [location]);

  const isWildlifeSafariAvailable =
    activeBooking?.destination.has_wildlife_safari;

  return (
    <div>
      <MyTripStandardPage title="My Finances" showDashLink />
      {activeBooking && currency && (
        <WidgetContainer className="w-full h-full mb-10">
          <FinancesToDateList
            currency={currency}
            booking_id={activeBooking.id}
          />
        </WidgetContainer>
      )}
      {activeBooking && isWildlifeSafariAvailable && (
        <WidgetContainer className="w-full h-full">
          <AvailableAddons activeBooking={activeBooking} />
        </WidgetContainer>
      )}
    </div>
  );
};

export default MyFinancesPage;
