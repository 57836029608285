import React from 'react';

import DepartmentSummary from '../my-trip/components/properties/hospitals/DepartmentSummary';

const Hospitals: React.FC = () => {
  const startDate = new Date(2023, 5, 10);
  const endDate = new Date(2024, 6, 12);
  return (
    <DepartmentSummary
      departmentName="Physiotherapy"
      startDate={startDate}
      endDate={endDate}
      primaryContact="Dr Ha Chan Nhan"
      details="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia
        odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.
        Nulla facilisi."
      generalInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
          lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod
          malesuada. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.
          Cras venenatis euismod malesuada. Nulla facilisi. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae
          vestibulum vestibulum."
    />
  );
};

export default Hospitals;
