import { Image } from '../admin/common/ImageGallery/types';

export const images: Image[] = [
  {
    id: '1',
    source:
      'https://media.istockphoto.com/id/2150594663/photo/exterior-of-a-white-house-home.jpg?s=1024x1024&w=is&k=20&c=CU1jnpv7r-xfkY5zMokVn0a7f_Pq5-y-5QhZ821UcPU=',
    alt: 'Main house photo',
    is_main: true,
  },
  {
    id: '2',
    source:
      'https://media.istockphoto.com/id/1410620286/photo/semi-detached-brick-house-in-london.jpg?s=1024x1024&w=is&k=20&c=fO4-WbxtyMP5OBFXu2GWc5oInZbO7qU_sZJppylAPN8=',
    alt: 'Another house',
    is_main: false,
  },
  {
    id: '3',
    source:
      'https://media.istockphoto.com/id/1314814500/photo/houses-in-england-with-typical-red-bricks-at-sunset-main-street-in-a-new-estate-with-typical.jpg?s=1024x1024&w=is&k=20&c=5TbNmyQNNQHM_va0zt_yXIfrQLoM2EjZ01R1cL9ZtwU=',
    alt: 'Another house 2',
    is_main: false,
  },
];
