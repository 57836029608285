import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { LoggedInUserContext } from '../context-providers/logged-in-user';
import Navbar from '../framework-components/navbar/Navbar';
import Logo from './Logo';

const LoggedOutLayout: React.FC = () => {
  const user = useContext(LoggedInUserContext);

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Navbar navigation={[]} appLogo={<Logo width={200} height={48} />} />
      <Outlet />
    </>
  );
};

export default LoggedOutLayout;
