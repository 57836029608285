import React from 'react';

interface Props {
  name: string;
  role: string | null;
  imageUrl: string;
  description: string | null;
  isStaff: boolean;
  isHousemate: boolean;
}

const Profile: React.FC<Props> = (props: Props) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <img
        src={props.imageUrl}
        className={'object-cover h-56 w-56 md:h-36 md:w-36 rounded-lg'}
        alt={`Profile image of ${props.name}`}
        role="img"
      />
      <p className="text-sm-bold w-56 md:w-36 wrap">{props.name}</p>
      {props.role && <p className="text-sm">{props.role}</p>}
    </div>
  );
};

export default Profile;
