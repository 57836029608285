import React, { useCallback } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';

import { useSubmitFormToAPI } from '../../api/forms';
import WrappedField from '../forms/wrapped-field';
import ErrorText from '../error-text';
import { PrimaryButton } from '../../framework-components/button/Button';

interface Values {
  email: string;
  password: string;
}

export function useOnSubmit(): (
  data: Values,
  formHelpers: Pick<
    FormikHelpers<Values>,
    'setSubmitting' | 'setErrors' | 'setStatus'
  >
) => void {
  const submitForm = useSubmitFormToAPI<Values, null>('/api/auth/login/');

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        window.location.replace('/api/auth/post-login/');
      }
    },
    [submitForm]
  );
}

const LoginForm: React.FC = () => {
  const onSubmit = useOnSubmit();

  return (
    <Formik<Values>
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <div className="space-y-8 flex items-center flex-col sm:px-4 justify-around">
            <h1 className="text-h4 sm:text-h3 mt-12 md:mt-0 sm:mb-2 mb-6">
              Log in
            </h1>
            <div className="w-full md:w-full space-y-6 pb-4">
              <WrappedField
                name="email"
                type="email"
                label="Email:"
                placeholder="Enter email"
              />
              <WrappedField
                name="password"
                type="password"
                label="Password:"
                placeholder="Enter password"
              />
            </div>
            <PrimaryButton
              type="submit"
              className="w-full px-4 py-2 border rounded-full focus:outline-none focus:ring-1 hover:opacity-80 bg-slate-200"
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </PrimaryButton>
            {status && <ErrorText>{status}</ErrorText>}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
