import React from 'react';
import { Dialog } from '@headlessui/react';
import { FormikValues } from 'formik';

import Modal from '../modal';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../framework-components/button/Button';
import Table from './Table';
import { TableField } from './types';
import { FieldType } from '../../framework-components/quickform/Field';
import useModalState from '../../hooks/modals';
import EditDetailsModal from '../manage-single-object/EditDetailsModal';

interface Props<T> {
  isOpen: boolean;
  closeModal: () => void;
  data: ReadonlyArray<T>;
  tableFields: ReadonlyArray<TableField<T>>;
  quickFormFields: ReadonlyArray<FieldType>;
  addHandler?: (values: T) => Promise<T>;
  editHandler?: (values: T) => Promise<T>;
  deleteHandler?: (values: T) => Promise<T>;
  onAddSuccess?: (data: T) => void;
  onEditSuccess?: (data: T) => void;
  onDeleteSuccess?: (data: T) => void;
  readonlyEditFields?: ReadonlyArray<string>;
  renderRowColor?: (data: T) => string;
  justifyCenter?: boolean;
}

const ManageModal = <T extends FormikValues>({
  isOpen,
  closeModal,
  data,
  tableFields,
  quickFormFields,
  addHandler,
  editHandler,
  deleteHandler,
  onAddSuccess,
  onEditSuccess,
  onDeleteSuccess,
  readonlyEditFields,
  renderRowColor,
  justifyCenter = true,
}: Props<T>) => {
  const focusRef = React.useRef(null);
  const {
    isOpen: isAddModalOpen,
    openModal: openAddModal,
    closeModal: closeAddModal,
  } = useModalState();

  const initialData = quickFormFields
    .map((field) => field.name)
    .reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {} as FormikValues);

  const editQuickFormFields = quickFormFields.map((field) => {
    if (readonlyEditFields && readonlyEditFields.includes(field.name)) {
      return {
        ...field,
        extraProps: {
          ...field.extraProps,
          disabled: true,
        },
      };
    }
    return field;
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      initialFocus={focusRef}
      justifyCenter={justifyCenter}
    >
      <div className="space-y-4" data-testid="manage-modal">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Manage
        </Dialog.Title>
        <Table
          data={data}
          tableFields={tableFields}
          quickFormFields={editQuickFormFields}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          onEditSuccess={onEditSuccess}
          onDeleteSuccess={onDeleteSuccess}
          renderRowColor={renderRowColor}
        />
        <div className="flex justify-between">
          {addHandler && (
            <SecondaryButton onClick={openAddModal}>Add item</SecondaryButton>
          )}
          <PrimaryButton onClick={closeModal}>Ok</PrimaryButton>
        </div>

        {addHandler && (
          <EditDetailsModal
            isOpen={isAddModalOpen}
            closeModal={closeAddModal}
            submitItem={addHandler}
            onSubmitSuccess={onAddSuccess}
            fields={quickFormFields}
            data={initialData as T}
          />
        )}
      </div>
    </Modal>
  );
};

export default ManageModal;
