import React from 'react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import { archiveEnquiry } from './actions';
import { BasicInfo } from './types';

interface Props {
  enquiryId: string;
  isArchived: boolean;
}

const EnquiryActions: React.FC<Props> = ({ enquiryId, isArchived }) => {
  const [isValidating, setIsValidating] = React.useState(false);

  const handleArchive = async () => {
    try {
      setIsValidating(true);
      await archiveEnquiry(enquiryId, { is_active: isArchived });
      toast.success(
        `Enquiry ${isArchived ? 'unarchived' : 'archived'} successfully`
      );
      mutate(
        `/api/admin/enquiries/${enquiryId}/basic-info/`,
        (data: BasicInfo) => ({ ...data, is_active: isArchived }),
        false
      );
    } catch {
      toast.error(
        `Failed to ${isArchived ? 'unarchived' : 'archived'} enquiry`
      );
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <AdminWidgetContainer className="space-y-6 bg-white" title="Actions">
      <PrimaryButton
        onClick={handleArchive}
        isDisabled={isValidating}
        isLoading={isValidating}
      >
        {isArchived ? 'Unarchive' : 'Archive'}
      </PrimaryButton>
    </AdminWidgetContainer>
  );
};

export default EnquiryActions;
