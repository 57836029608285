import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { HospitalDetailsRequestData } from './types';
import {
  formatDateToLongDateFormatWithOridinalDay,
  getBookingDateConfig,
} from '../../../../utils/dates';
import ManageMultipleObjects from '../../../manage-multiple-objects/ManageMultipleObjects';
import CopyToClipboardButton from '../../../CopyClipboardButton';

interface Props {
  bookingId?: string;
  destinationId?: string;
  startDate?: string;
  endDate?: string;
}

const HospitalPlacementDetailsSection: React.FC<Props> = ({
  bookingId,
  destinationId,
  startDate,
  endDate,
}) => {
  const bookingDateConfig =
    startDate && endDate ? getBookingDateConfig(startDate, endDate) : undefined;
  return (
    <>
      <ManageMultipleObjects<HospitalDetailsRequestData>
        title="Hospitals:"
        titleClassName="text-mob-sh2"
        getUrl={`/api/admin/bookings/${bookingId}/get-hospital-details/`}
        tableFields={[
          {
            name: 'hospital_name',
            header: 'Hospital',
            render: (item) => (
              <Link
                className="text-table-xs"
                to={`/admin/directories/hospitals/${item.hospital_id}/`}
              >
                {item.hospital_name}
              </Link>
            ),
          },
          {
            name: 'department_name',
            header: 'Department',
            render: (item) => item.department_name,
          },
          {
            name: 'from_date',
            header: 'Start',
            render: (item) =>
              formatDateToLongDateFormatWithOridinalDay(item.from_date),
          },
          {
            name: 'to_date',
            header: 'End',
            render: (item) => {
              const hospitalDetailsCopyText = (
                <CopyToClipboardButton
                  textToCopy={
                    item
                      ? `${item.hospital_name}, ${item.department_name}, ${item.from_date}, ${item.to_date}`
                      : ''
                  }
                />
              );
              return (
                <div className="flex flex-inline justify-between">
                  {formatDateToLongDateFormatWithOridinalDay(item.to_date)}
                  {'      '}
                  {hospitalDetailsCopyText}
                </div>
              );
            },
          },
        ]}
        quickFormFields={[]}
        hideManageButton
        noDataFoundMessage="No hospital details found"
        insideCard={false}
      />

      {destinationId && bookingDateConfig && (
        <Link
          className="text-blue-500"
          to={`/admin/capacities/department-capacities?destination=${destinationId}&year=${bookingDateConfig.year}&month=${bookingDateConfig.month}&day=${bookingDateConfig.day}&period=${bookingDateConfig.period}`}
        >
          See hospital capacity calendar
        </Link>
      )}

      <div className="flex">
        <p className="text-xs w-[185px]">Details sheet for hospitals</p>
        <Link
          reloadDocument
          to={`/letters/${bookingId}/hospital-placement-sheet/`}
        >
          <FontAwesomeIcon icon={faPrint} />
        </Link>
      </div>

      <div className="flex">
        <p className="text-xs w-[185px]">Print hospital badge</p>
        <Link to={`/hospital-badges?bookingIds=${bookingId}`}>
          <FontAwesomeIcon icon={faCreditCard} />
        </Link>
      </div>
    </>
  );
};

export default HospitalPlacementDetailsSection;
