import React from 'react';
import { useFormik, FormikProps, FormikErrors, FormikValues } from 'formik';
import { get } from 'lodash';

import Field, { FieldType } from './Field';
import { PrimaryButton } from '../button/Button';
import ErrorText from '../../components/error-text';
import { APIError } from '../../api/api';
import useModalState from '../../hooks/modals';

interface Props<T> {
  onSubmit: (data: T) => Promise<T>;
  initialValues: T;
  fields: ReadonlyArray<FieldType>;
  onSubmitSuccess?: (values: T) => void;
  onSubmitFailure?: (values: T) => void;
  buttonLabel?: string;
  hideButtonOnClean?: boolean;
  cleanOnSubmitSuccess?: boolean;
  disable?: boolean;
  preSubmitModal?: JSX.Element;
}

const QuickForm = <T extends FormikValues>({
  onSubmit,
  initialValues,
  fields,
  onSubmitSuccess,
  onSubmitFailure,
  buttonLabel,
  hideButtonOnClean = false,
  cleanOnSubmitSuccess = false,
  disable,
  preSubmitModal,
}: Props<T>) => {
  const { isOpen, openModal, closeModal } = useModalState();
  const formik: FormikProps<T> = useFormik<T>({
    initialValues,
    onSubmit: async (values, { setErrors, resetForm }) => {
      try {
        const response = await onSubmit(values);
        if (onSubmitSuccess) {
          onSubmitSuccess({ ...values, ...response });
        }
        if (cleanOnSubmitSuccess) {
          resetForm({ values: { ...values, ...response } });
        }
      } catch (error: unknown) {
        setErrors((error as APIError).body as FormikErrors<T>);
        if (onSubmitFailure) {
          onSubmitFailure(values);
        }
      }
    },
    enableReinitialize: true,
  });

  const onSubmitForm = formik.handleSubmit;

  return (
    <form onSubmit={onSubmitForm} className="sm:space-y-4">
      {fields.map((field) => {
        const value = get(formik.values, field.name);
        const errors = get(formik.errors, field.name) as string | string[];
        if (field.extraProps && !('disabled' in field.extraProps)) {
          field.extraProps.disabled = disable;
        }
        return (
          <Field
            key={field.name}
            {...field}
            value={value}
            formik={formik}
            errors={errors}
            extraProps={{
              ...field.extraProps,
            }}
          />
        );
      })}
      {formik.errors && formik.errors.non_field_errors && (
        <ErrorText className="text-sm">
          {formik.errors.non_field_errors as string | string[]}
        </ErrorText>
      )}
      {(formik.dirty || !hideButtonOnClean) && (
        <PrimaryButton
          isDisabled={formik.isSubmitting || disable}
          isLoading={formik.isSubmitting}
          label={buttonLabel ? buttonLabel : 'Submit'}
          type="submit"
          className="w-full"
          onClick={(e) => {
            if (preSubmitModal) {
              e.preventDefault();
              openModal();
            }
          }}
        />
      )}

      {preSubmitModal &&
        isOpen &&
        React.cloneElement(preSubmitModal, {
          isOpen: isOpen,
          closeModal: closeModal,
          data: formik.values,
          onSubmit: onSubmitForm,
        })}
    </form>
  );
};

export default QuickForm;
