import React from 'react';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { mutate } from 'swr';

import { useSubmitFormToAPI } from '../../../../api/forms';
import { useAPI } from '../../../../api/api';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import TextArea from '../../../my-trip/components/TextArea';
import ErrorText from '../../../error-text';
import { PrimaryButton } from '../../../../framework-components/button/Button';

const validationSchema = Yup.object({
  placement_objectives: Yup.array().of(
    Yup.string().required('Objective is required')
  ),
});

interface PlacementObjectivesData {
  placement_objectives: string[];
}

interface ObjectiveProps {
  objective: string;
  index: number;
  setFieldValue: (field: string, value: string) => void;
  removeObjective: () => void;
  error: string | undefined;
  totalObjectivesCount: number;
  canEdit?: boolean;
}

const Objective: React.FC<ObjectiveProps> = ({
  objective,
  index,
  setFieldValue,
  removeObjective,
  error,
  totalObjectivesCount,
  canEdit = false,
}) => {
  const name = `placement_objectives[${index}]`;
  return (
    <div key={index} className="mb-4">
      <div>
        <label htmlFor={name}>Objective {index + 1}:</label>
        <TextArea
          name={name}
          disabled={!canEdit}
          value={objective}
          onChange={(e) => setFieldValue(name, e.target.value)}
          placeholder={objective || 'Enter details'}
          classNameOverride="block bg-wtw-gray-2 resize-none w-full border-0 py-3 text-gray-900 shadow-sm placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:resize-none h-12"
        />
        {error && <ErrorText>{error}</ErrorText>}
        {totalObjectivesCount > 1 && (
          <FontAwesomeIcon
            className={classNames(
              'text-red-400 ml-2 hover:cursor-pointer mt-1',
              { hidden: !canEdit }
            )}
            icon={faTrash}
            onClick={removeObjective}
          />
        )}
      </div>
    </div>
  );
};

const PlacementObjectivesForm: React.FC<{
  bookingId: string;
  canEdit: boolean;
}> = ({ bookingId, canEdit }) => {
  const { data, isValidating, error } = useAPI<PlacementObjectivesData>(
    `/api/admin/bookings/${bookingId}/placement-objectives/get/`
  );

  const submitForm = useSubmitFormToAPI(
    `/api/admin/bookings/${bookingId}/placement-objectives/set/`
  );

  const handleSubmit = async (
    values: { placement_objectives: string[] },
    formHelpers: FormikHelpers<PlacementObjectivesData>
  ) => {
    const [hasSucceeded] = await submitForm(
      { placement_objectives: values.placement_objectives },
      formHelpers
    );

    if (hasSucceeded) {
      toast.success('Placement objectives updated successfully!');
      mutate(`/api/admin/bookings/${bookingId}/get-education-tab-warning/`);
    } else {
      toast.error('Failed to add placement objective. Please try again.');
    }
  };

  return (
    <div>
      <AdminWidgetContainer title="Placement objectives" className="space-y-6">
        {isValidating && <LoadingOverlay />}
        {error && <ErrorOverlay />}
        {data && (
          <Formik<PlacementObjectivesData>
            initialValues={{
              placement_objectives: data?.placement_objectives?.length
                ? data.placement_objectives
                : [''],
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <FieldArray
                  name="placement_objectives"
                  render={(arrayHelpers) => (
                    <div>
                      {values.placement_objectives.map((objective, index) => (
                        <Objective
                          key={index}
                          objective={objective}
                          index={index}
                          setFieldValue={setFieldValue}
                          removeObjective={() => arrayHelpers.remove(index)}
                          error={errors.placement_objectives?.[index]}
                          totalObjectivesCount={
                            values.placement_objectives.length
                          }
                          canEdit={canEdit}
                        />
                      ))}
                      <div
                        onClick={() => arrayHelpers.push('')}
                        className={classNames('text-blue-500', {
                          hidden: !canEdit,
                        })}
                      >
                        <FontAwesomeIcon
                          className="text-blue-500 mr-2"
                          icon={faPlus}
                        />
                        <span>add objective</span>
                      </div>
                    </div>
                  )}
                />
                <div>
                  <PrimaryButton
                    label="Save"
                    type="submit"
                    className={classNames('mt-4', { hidden: !canEdit })}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </AdminWidgetContainer>
    </div>
  );
};

export default PlacementObjectivesForm;
