import React from 'react';

import TextArea from '../components/TextArea';

interface ObjectiveProps {
  objective: string;
  index: number;
  setFieldValue: (field: string, value: string) => void;
  removeObjective: () => void;
  totalObjectivesCount: number;
}

const Objective: React.FC<ObjectiveProps> = ({
  objective,
  index,
  setFieldValue,
  removeObjective,
  totalObjectivesCount,
}) => {
  const name = `placement_objectives[${index}]`;
  return (
    <>
      <div key={index} className="mb-4 w-full inline-flex place-items-center">
        <div className="w-5/6">
          <label htmlFor={name}>Objective {index + 1}:</label>
          <TextArea
            grayBorderless
            rows={2}
            name={name}
            value={objective}
            onChange={(e) => setFieldValue(name, e.target.value)}
            placeholder={objective || 'Enter details'}
          />
        </div>
        <div className="w-1/6 h-full flex align-center justify-center items-center justify-center place-items-center pt-4">
          <div className="">
            {totalObjectivesCount > 1 && (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={removeObjective}
                data-testid="delete-icon"
                className="text-wtw-logo-blue hover:cursor-pointer"
              >
                <path
                  d="M15.8335 5.83333L15.1107 15.9521C15.0484 16.8243 14.3227 17.5 13.4483 17.5H6.55203C5.67763 17.5 4.9519 16.8243 4.8896 15.9521L4.16683 5.83333M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M12.5002 5.83333V3.33333C12.5002 2.8731 12.1271 2.5 11.6668 2.5H8.3335C7.87326 2.5 7.50016 2.8731 7.50016 3.33333V5.83333M3.3335 5.83333H16.6668"
                  stroke="#2568EB"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="text-wtw-logo-blue hover:cursor-pointer"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Objective;
