import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';

import { Option } from '../../types';
import DropdownInner from './DropdownInner';
import { useAPI } from '../../api/api';
import ErrorText from '../../components/error-text';

interface Props<T> {
  path: string;
  onSelect: (option: Option<T> | null) => void;
  id?: string;
  name: string;
  label?: string | React.ReactNode;
  row?: boolean;
  placeholder?: string;
  errors?: Array<string>;
  value: Option<T> | null;
  inputOnChange?: (query: string) => void;
  zIndex?: number;
  className?: string;
  isDisabled?: boolean;
  allowDropdownAutoComplete?: boolean;
  labelClassName?: string;
  inputClassNameOverride?: string;
  inputWidth?: string;
}

const RemoteDropdown = <T,>({
  path,
  onSelect,
  id,
  name,
  label,
  labelClassName,
  row,
  placeholder,
  errors = [],
  value,
  inputOnChange,
  zIndex,
  className,
  isDisabled = false,
  allowDropdownAutoComplete = false,
  inputClassNameOverride,
  inputWidth,
}: Props<T>) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [localOptions, setLocalOptions] = useState<ReadonlyArray<Option<T>>>(
    []
  );
  const hasErrors = errors.length > 0;

  useEffect(() => {
    const handler = debounce((nextQuery) => {
      setDebouncedQuery(nextQuery);
    }, 500);
    handler(query);
    return () => {
      handler.cancel();
    };
  }, [query]);

  const {
    data: options,
    error,
    isValidating,
  } = useAPI<ReadonlyArray<Option<T>>>(path, {
    search: debouncedQuery,
  });

  if (options && JSON.stringify(options) !== JSON.stringify(localOptions)) {
    setLocalOptions(options);
  }

  const displayErrors = () => {
    if (!hasErrors) return;

    const allErrors = [...errors];
    if (error) {
      allErrors.push('An error occurred while fetching the data');
    }
    return <ErrorText className="text-sm">{allErrors}</ErrorText>;
  };

  const inputHandler = (queryString: string) => {
    setQuery(queryString);
    if (inputOnChange) {
      inputOnChange(queryString);
    }
    if (allowDropdownAutoComplete) {
      const queryMatch = localOptions?.find((option) => {
        return queryString === option.label;
      });

      if (queryMatch) {
        const queryValue = { value: queryMatch.value, label: queryMatch.label };
        onSelect(queryValue);
      }
    }
  };

  return (
    <div className={className}>
      <DropdownInner
        query={query}
        setQuery={inputHandler}
        filteredOptions={localOptions}
        selectedOption={value}
        selectHandler={onSelect}
        id={id}
        name={name}
        label={label}
        labelClassName={labelClassName}
        isLoading={isValidating}
        row={row}
        placeholder={placeholder}
        zIndex={zIndex}
        isDisabled={isDisabled}
        inputClassNameOverride={inputClassNameOverride}
        inputWidth={inputWidth}
      />
      {displayErrors()}
      {error && <ErrorText />}
    </div>
  );
};

export default RemoteDropdown;
