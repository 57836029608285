import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { Link } from 'react-router-dom';

import { AccomodationDetailsRequestData, TripDetailsData } from './types';
import {
  formatDateToLongDateFormatWithOridinalDay,
  getBookingDateConfig,
} from '../../../../utils/dates';
import Table from '../../../manage-multiple-objects/Table';
import {
  addVillageExperience,
  removeVillageExperience,
  updateVillageDates,
} from './actions';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import { useAPI } from '../../../../api/api';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import Tooltip from '../../../../framework-components/tooltip/Tooltip';
import EditDetailsModal from '../../../manage-single-object/EditDetailsModal';
import useModalState from '../../../../hooks/modals';
import ExtendBookingHouse from './accomodation-actions/ExtendBookingHouse';
import ReduceBookingHouse from './accomodation-actions/ReduceBookingHouse';
import DeleteBookingHouse from './accomodation-actions/DeleteBookingHouse';

interface Props {
  bookingId: string;
  setHasAccomodationConflict: React.Dispatch<React.SetStateAction<boolean>>;
  canEdit: boolean;
  destinationId?: string;
  startDate?: string;
  endDate?: string;
}

const AccomodationPlacementDetailsSection: React.FC<Props> = ({
  bookingId,
  setHasAccomodationConflict,
  canEdit,
  destinationId,
  startDate,
  endDate,
}) => {
  const bookingDateConfig =
    startDate && endDate ? getBookingDateConfig(startDate, endDate) : undefined;

  const { data, isValidating, error } = useAPI<
    Array<AccomodationDetailsRequestData>
  >(`/api/admin/bookings/${bookingId}/get-accomodation-details/`);

  const { isOpen, openModal, closeModal } = useModalState();

  const handleAddVillageExperience = async () => {
    try {
      const house = await addVillageExperience(bookingId);
      mutate(
        `/api/admin/bookings/${bookingId}/get-accomodation-details/`,
        (houses: Array<AccomodationDetailsRequestData>) => [...houses, house],
        false
      );
      mutate(
        `/api/admin/bookings/${bookingId}/get-trip-details/`,
        (tripDetails: TripDetailsData) => ({
          ...tripDetails,
          number_of_weeks: tripDetails.number_of_weeks + 1,
        }),
        false
      );
      toast.success('Village experience added');
    } catch {
      toast.error('Failed to add village experience');
    }
  };

  const handleRemoveVillageExperience = async () => {
    try {
      const response = await removeVillageExperience(bookingId);
      mutate(
        `/api/admin/bookings/${bookingId}/get-accomodation-details/`,
        (houses: Array<AccomodationDetailsRequestData>) =>
          houses.filter((house) => house.id !== response.id),
        false
      );
      mutate(
        `/api/admin/bookings/${bookingId}/get-trip-details/`,
        (tripDetails: TripDetailsData) => ({
          ...tripDetails,
          number_of_weeks: tripDetails.number_of_weeks - 1,
        }),
        false
      );
      toast.success('Village experience removed');
    } catch {
      toast.error('Failed to remove village experience');
    }
  };

  const villageExperience = data?.find(
    (item) => item.accomodation_type.type === 'VILLAGE'
  );

  if (data && !isValidating && !error) {
    const checkForConflicts = data.find((item) => {
      return item.capacity_conflict_status === 'ERROR';
    });
    if (checkForConflicts) {
      setHasAccomodationConflict(true);
    }
  }

  return (
    <div className="relative space-y-6 grid">
      {isValidating && <LoadingOverlay />}
      {error && !isValidating && <ErrorOverlay />}
      <h1 className="text-mob-sh2">Accommodation</h1>
      <div className="flex flex-col gap-2">
        {destinationId && bookingDateConfig && (
          <Link
            className="text-blue-500"
            to={`/admin/capacities/house-village-capacities?destination=${destinationId}&year=${bookingDateConfig.year}&month=${bookingDateConfig.month}&day=${bookingDateConfig.day}&period=${bookingDateConfig.period}`}
          >
            See house capacity calendar
          </Link>
        )}
        {destinationId && bookingDateConfig && (
          <Link
            className="text-blue-500"
            to={`/admin/capacities/room-capacities?destination=${destinationId}&year=${bookingDateConfig.year}&month=${bookingDateConfig.month}&day=${bookingDateConfig.day}&period=${bookingDateConfig.period}`}
          >
            See room capacity calendar
          </Link>
        )}
      </div>

      {data && (
        <Table<AccomodationDetailsRequestData>
          data={data}
          tableFields={[
            {
              name: 'accomodation_type',
              header: 'Type',
              render: (item: AccomodationDetailsRequestData) =>
                item.accomodation_type.name,
            },
            {
              name: 'house',
              header: 'House',
              render: (item: AccomodationDetailsRequestData) => (
                <Link
                  className="text-table-xs"
                  to={`/admin/directories/houses/${item.house?.id}/`}
                >
                  {item.house?.name}
                </Link>
              ),
            },
            {
              name: 'start_date',
              header: 'Start date',
              render: (item: AccomodationDetailsRequestData) =>
                formatDateToLongDateFormatWithOridinalDay(item.start_date),
            },
            {
              name: 'end_date',
              header: 'End date',
              render: (item: AccomodationDetailsRequestData) =>
                formatDateToLongDateFormatWithOridinalDay(item.end_date),
            },
            {
              name: 'conflict',
              header: 'Conflict',
              render: (item: AccomodationDetailsRequestData) => {
                const tooltipId = item.id;
                return (
                  <>
                    <FontAwesomeIcon
                      icon={faHouse}
                      data-tooltip-id={tooltipId}
                      data-tooltip-content={item.capacity_details}
                      className={classNames({
                        'text-red-400':
                          item.capacity_conflict_status === 'ERROR',
                        'text-amber-500':
                          item.capacity_conflict_status === 'WARN',
                      })}
                    />{' '}
                    <Tooltip id={tooltipId} />
                  </>
                );
              },
            },
            {
              name: 'extend',
              header: 'Extend',
              render: (item: AccomodationDetailsRequestData) => {
                return (
                  item.accomodation_type.type === 'HOUSE' && (
                    <ExtendBookingHouse
                      bookingId={bookingId}
                      bookingHouseId={item.id}
                    />
                  )
                );
              },
            },
            {
              name: 'reduce',
              header: 'Reduce',
              render: (item: AccomodationDetailsRequestData) => {
                const hasSeveralHospitalBookingHouses = data.length >= 3;
                const isFinalAccomodationAndHouse =
                  hasSeveralHospitalBookingHouses
                    ? item.id === data.slice(-1)[0].id &&
                      item.accomodation_type.type === 'HOUSE'
                    : false;
                if (
                  item.accomodation_type.type === 'HOUSE' &&
                  hasSeveralHospitalBookingHouses &&
                  isFinalAccomodationAndHouse
                ) {
                  return (
                    <>
                      <ReduceBookingHouse
                        bookingId={bookingId}
                        bookingHouseId={item.id}
                      />
                      <DeleteBookingHouse
                        bookingId={bookingId}
                        bookingHouseId={item.id}
                      />
                    </>
                  );
                }
                return (
                  item.accomodation_type.type === 'HOUSE' && (
                    <ReduceBookingHouse
                      bookingId={bookingId}
                      bookingHouseId={item.id}
                    />
                  )
                );
              },
            },
          ]}
          isFixedTableLayout
        />
      )}
      {data && !villageExperience && canEdit && (
        <PrimaryButton
          onClick={handleAddVillageExperience}
          label="Add village experience"
        />
      )}
      {data && villageExperience && canEdit && (
        <div className="flex flex-col gap-2">
          <PrimaryButton onClick={openModal} label="Edit village dates" />
          <PrimaryButton
            onClick={handleRemoveVillageExperience}
            label="Remove village experience"
          />
          <EditDetailsModal
            isOpen={isOpen}
            closeModal={closeModal}
            onSubmitSuccess={() =>
              mutate(
                `/api/admin/bookings/${bookingId}/get-accomodation-details/`
              )
            }
            submitItem={(values) =>
              updateVillageDates(
                villageExperience.id,
                values.start_date,
                values.end_date
              )
            }
            data={villageExperience}
            fields={[
              {
                name: 'start_date',
                label: 'Start date',
                type: 'datetime',
              },
              {
                name: 'end_date',
                label: 'End date',
                type: 'datetime',
              },
            ]}
            title="Update village dates"
            buttonLabel="Update"
            message="Note that this will automatically update the dates of any rooms booked."
          />
        </div>
      )}
    </div>
  );
};

export default AccomodationPlacementDetailsSection;
