import React from 'react';
import { Link } from 'react-router-dom';

import { apiRequest } from '../../../api/api';
import Table from '../../../framework-components/table/Table';
import {
  CombinedFilters,
  FilterOption,
  TableData,
} from '../../../framework-components/table/types';
import { formatDateToDayDateMonthYear } from '../../../utils/dates';
import { truncate } from '../../../utils/text';

interface AlumniListingTableData {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  applicant: {
    university: {
      id: string;
      name: string;
    };
  };
}

const getFilterOptions = (
  selectedFilters: CombinedFilters
): Promise<Record<string, FilterOption[]>> => {
  return fetch(
    '/api/admin/alumni/filters/?' +
      new URLSearchParams(selectedFilters).toString()
  )
    .then((response) => response.json())
    .then((data) => data);
};

const getData = (selectedFilters: CombinedFilters) => {
  return apiRequest<TableData<AlumniListingTableData>>(
    '/api/admin/alumni/list/?' + new URLSearchParams(selectedFilters).toString()
  ).then((response) => {
    return response;
  });
};

const AlumniListingTable: React.FC = () => {
  return (
    <div>
      <Table<AlumniListingTableData>
        columnsConfig={{
          name: {
            key: 'name',
            header: 'Name',
            enableSort: false,
            renderCell: (alumni) => (
              <Link to={`/admin/bookings/${alumni.id}/applicant-profile`}>
                {alumni.name}
              </Link>
            ),
          },
          university: {
            key: 'university',
            header: 'University',
            enableSort: false,
            renderCell: (alumni) => (
              <a
                href={`/admin/directories/universities/${alumni.applicant.university.id}/`}
                title={alumni.id}
              >
                {alumni.applicant.university.name.length > 43
                  ? truncate(alumni.applicant.university.name, 43)
                  : alumni.applicant.university.name}
              </a>
            ),
          },
          start_date: {
            key: 'start_date',
            header: 'Start Date',
            enableSort: false,
            renderCell: (alumni) =>
              formatDateToDayDateMonthYear(alumni.start_date),
          },
          end_date: {
            key: 'end_date',
            header: 'End Date',
            enableSort: false,
            renderCell: (alumni) =>
              formatDateToDayDateMonthYear(alumni.end_date),
          },
        }}
        getData={getData}
        getFilterOptions={getFilterOptions}
        additionalFiltersConfig={{
          university: {
            key: 'university',
            label: 'University',
            filterType: 'local-dropdown',
          },
          is_archived: {
            key: 'is_archived',
            label: 'Archived',
            filterType: 'toggle',
          },
          year: {
            key: 'year',
            label: 'Year',
            filterType: 'local-dropdown',
          },
          month: {
            key: 'month',
            label: 'Month',
            filterType: 'local-dropdown',
          },
        }}
        enablePagination
        enableSearch
      />
    </div>
  );
};

export default AlumniListingTable;
