import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import AdminStandardPage from '../../common/AdminStandardPage';
import BlockTabs from '../../../../framework-components/blocktabs/BlockTabs';
import { apiRequest, useAPI } from '../../../../api/api';
import Table from '../../../../framework-components/table/Table';
import { formatDateToDayDateMonthYear } from '../../../../utils/dates';
import {
  CombinedFilters,
  FilterOption,
  TableData,
} from '../../../../framework-components/table/types';
import { SecondaryButton } from '../../../../framework-components/button/Button';
import ArchiveIcon from '../../../icons/ArchiveIcon';
import { EnquiryListingCountData } from '../../dashboard/types';
import { ArchivedEnquiryTableData } from './types';
import Spinner from '../../../spinner';
import { unArchiveEnquiry } from '../actions';
import { truncate } from '../../../../utils/text';

const DashboardArchivedEnquiryPage: React.FC = () => {
  const [isUnarchiveLoading, setIsUnarchiveLoading] = useState(false);
  const tableRef = useRef<{ reloadData: () => void }>(null);

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.reloadData();
    }
  };
  const {
    data,
    isValidating,
    error: countError,
  } = useAPI<EnquiryListingCountData>('/api/admin/enquiries/count/');

  const getFilterOptions = useCallback(
    (
      selectedFilters: CombinedFilters
    ): Promise<Record<string, FilterOption[]>> => {
      return fetch(
        '/api/admin/enquiries/enquiries-table-filters/?' +
          new URLSearchParams({
            ...selectedFilters,
            type: 'archived',
          }).toString()
      ).then((response) => response.json());
    },
    []
  );

  const getDataPromise = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<TableData<ArchivedEnquiryTableData>>(
      '/api/admin/enquiries/archived-enquiries/?' +
        new URLSearchParams({
          ...selectedFilters,
          type: 'archived',
        }).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const handleUnArchive = async (enquiry: ArchivedEnquiryTableData) => {
    setIsUnarchiveLoading(true);
    try {
      await unArchiveEnquiry(enquiry.id);
      toast.success('Enquiry reactivated successfully');
      reloadData();
      mutate('/api/admin/enquiries/count/');
    } catch (error) {
      toast.error('Failed to reactivate enquiry');
    }
    setIsUnarchiveLoading(false);
  };

  const renderTabs = useCallback(() => {
    if (isValidating) {
      return <Spinner className="h-6 w-6" />;
    }

    if (countError || !data) {
      return (
        <div className="p-4 text-center">
          There was an error fetching the archived enquiries
        </div>
      );
    }

    return (
      <BlockTabs
        tabs={[
          {
            label: `New (${data.new})`,
            href: '/admin/enquiries/new',
          },
          {
            label: `Ongoing (${data.ongoing})`,
            href: '/admin/enquiries/ongoing',
          },
          {
            label: `Archived (${data.archived})`,
            href: '/admin/enquiries/archived',
            current: true,
          },
        ]}
        variant="pilled_tab"
      />
    );
  }, [isValidating, countError, data]);

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Enquiries',
          href: '/admin/enquiries/new',
        },
        {
          label: 'Archived enquiries',
          href: null,
        },
      ]}
      title={'Archived enquiries'}
      enableHome={false}
    >
      <div className="mt-4">
        <div className="w-full">{renderTabs()}</div>

        <Table<ArchivedEnquiryTableData>
          ref={tableRef}
          columnsConfig={{
            name: {
              key: 'name',
              header: 'Name',
              enableSort: true,
              renderCell: (enquiry) => {
                if (enquiry.id) {
                  return (
                    <a
                      href={`/admin/enquiries/${enquiry.id}/enquiry-profile/`}
                      title={enquiry.name}
                    >
                      {enquiry.name}
                    </a>
                  );
                }

                return enquiry.name;
              },
            },
            created: {
              key: 'created',
              header: 'Enquiry Date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (enquiry) =>
                formatDateToDayDateMonthYear(enquiry.created),
            },
            university: {
              key: 'university',
              header: 'University',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (enquiry) => {
                return enquiry.university ? (
                  <a
                    href={`/admin/directories/universities/${enquiry.university.id}/`}
                    title={enquiry.university.name}
                  >
                    {enquiry.university.name.length > 30
                      ? truncate(enquiry.university.name, 30)
                      : enquiry.university.name}
                  </a>
                ) : (
                  ''
                );
              },
            },
            university_course: {
              key: 'university_course',
              header: 'Course title',
              enableSort: true,
              filterType: 'local-dropdown',
            },
            destination: {
              key: 'destination',
              header: 'Destination',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (enquiry) => enquiry.destination?.name,
            },
            source: {
              key: 'source',
              header: 'Source',
              enableSort: true,
              filterType: 'local-dropdown',
            },
            re_open: {
              key: 're_open',
              header: 'Re-open',
              enableSort: false,
              renderCell: (enquiry) => {
                return (
                  <SecondaryButton
                    isDisabled={isUnarchiveLoading}
                    onClick={() => handleUnArchive(enquiry)}
                  >
                    {<ArchiveIcon />}
                  </SecondaryButton>
                );
              },
            },
          }}
          getFilterOptions={getFilterOptions}
          getData={getDataPromise}
          enablePagination
          enableSearch
        />
      </div>
    </AdminStandardPage>
  );
};

export default DashboardArchivedEnquiryPage;
