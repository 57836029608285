import {
  TxType,
  ChargeType,
  CHARGE_TYPE,
  PaymentType,
  BookingStatus,
  XERO_CREATE_STATUS,
  XeroCreateStatus,
} from './types';

export const BOOKING_STATUS_LABELS: Record<BookingStatus, string> = {
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  ON_TRIP: 'On Trip',
  ALUMNI: 'Alumni',
  ARCHIVED: 'Archived',
  CANCELLED: 'Cancelled',
  POSTPONED: 'Postponed',
};

export const TRANSACTION_TYPE_LABELS: Record<TxType, string> = {
  DEBIT: 'Payment(s)',
  CREDIT: 'Charge(s)',
};

export const CHARGE_TYPE_LABELS: Record<ChargeType, string> = {
  [CHARGE_TYPE.REGISTRATION_FEE]: 'Registration Fee',
  [CHARGE_TYPE.HOLIDAY]: 'Holiday',
  [CHARGE_TYPE.VILLAGE]: 'Village',
  [CHARGE_TYPE.ADDON]: 'Addon',
  [CHARGE_TYPE.OTHER]: 'Other',
  [CHARGE_TYPE.ADMIN]: 'Admin',
  [CHARGE_TYPE.INSURANCE]: 'Insurance',
  [CHARGE_TYPE.REFUND]: 'Refund',
};

export const PAYMENT_TYPE_LABELS: Record<PaymentType, string> = {
  BACS: 'BACS',
  STRIPE: 'Stripe',
  PAYPAL: 'PayPal',
  CHARGE_REMOVED: 'Charge Removed',
};

export const XERO_CREATE_STATUS_LABELS: Record<XeroCreateStatus, string> = {
  [XERO_CREATE_STATUS.NOT_STARTED]: 'Not Started',
  [XERO_CREATE_STATUS.IN_PROGRESS]: 'In Progress',
  [XERO_CREATE_STATUS.COMPLETED]: 'Completed',
  [XERO_CREATE_STATUS.FAILED]: 'Failed',
};

export const SHOP_URL = 'https://shop.worktheworld.co.uk/';
