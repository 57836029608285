import React from 'react';
import { mutate } from 'swr';
import { Link } from 'react-router-dom';

import { HospitalBasicInfo } from './types';
import ThreeColumns from '../../common/layout/ThreeColumns';
import { PencilEditButton } from '../../../../framework-components/button/PencilEditButton';
import EditDetailsModal from '../../../manage-single-object/EditDetailsModal';
import CardDetail from '../../../../framework-components/card/CardDetail';
import useModalState from '../../../../hooks/modals';
import { updateBasicInfo } from './actions';

interface Props {
  data: HospitalBasicInfo;
}

const renderHouseCard = (
  data: Record<string, string | React.JSX.Element | undefined>
) => {
  return <CardDetail className="h-full" data={data} />;
};

const BasicInfo: React.FC<Props> = ({ data }) => {
  const { isOpen, openModal, closeModal } = useModalState();

  const renderFirstColumn = () =>
    renderHouseCard({
      Name: data.formal_name,
      'Alternative name': data.display_name ?? '',
      Destination: data.destination ? (
        <Link
          className="text-blue-500"
          to={`/admin/directories/destinations/?destination=${data.destination.value}`}
        >
          {data.destination.label}
        </Link>
      ) : (
        ''
      ),
    });

  const renderSecondColumn = () =>
    renderHouseCard({
      'Main contact': data.main_contact,
      'Main contact role': data.main_contact_role ?? '',
      Telephone: data.telephone,
    });

  const renderThirdColumn = () => {
    const address = {
      ...data.address,
      country: data.address.country?.label ?? '',
    };
    const addressItems = Object.values(address).filter((item) => item);
    return renderHouseCard({
      Email: data.email,
      Website: data.website,
      Address: addressItems.join(', '),
    });
  };

  return (
    <div className="pb-9 space-y-6">
      <ThreeColumns
        leftColumn={renderFirstColumn()}
        middleColumn={renderSecondColumn()}
        rightColumn={renderThirdColumn()}
      />
      <div className="w-full flex justify-end">
        <PencilEditButton onClick={openModal} />
      </div>
      <EditDetailsModal<HospitalBasicInfo>
        isOpen={isOpen}
        closeModal={closeModal}
        submitItem={(values) => updateBasicInfo(data.id, values)}
        onSubmitSuccess={() =>
          mutate(`/api/admin/hospital/${data.id}/basic-info/`)
        }
        data={data}
        fields={[
          {
            type: 'smalltext',
            name: 'formal_name',
            label: 'Name',
          },
          {
            type: 'smalltext',
            name: 'display_name',
            label: 'Alternative name',
          },
          {
            type: 'remote-dropdown',
            name: 'destination',
            label: 'Destination',
            path: '/api/booking/destinations/',
          },
          {
            type: 'smalltext',
            name: 'main_contact',
            label: 'Main contact',
          },
          {
            type: 'smalltext',
            name: 'main_contact_role',
            label: 'Main contact role',
          },
          {
            type: 'smalltext',
            name: 'telephone',
            label: 'Telephone',
          },
          {
            type: 'email',
            name: 'email',
            label: 'Email',
          },
          {
            type: 'smalltext',
            name: 'website',
            label: 'Website',
          },
          {
            type: 'smalltext',
            name: 'address.line1',
            label: 'Address line 1',
          },
          {
            type: 'smalltext',
            name: 'address.line2',
            label: 'Address line 2',
          },
          {
            type: 'smalltext',
            name: 'address.city',
            label: 'City',
          },
          {
            type: 'smalltext',
            name: 'address.county',
            label: 'County',
          },
          {
            type: 'remote-dropdown',
            name: 'address.country',
            label: 'Country',
            path: '/api/booking/countries/',
          },
          {
            type: 'smalltext',
            name: 'address.postcode',
            label: 'Postcode',
          },
        ]}
      />
    </div>
  );
};

export default BasicInfo;
