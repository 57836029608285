import React from 'react';

import TwoColumns from '../../../common/layout/TwoColumns';
import LocationPrices from './LocationPrices';
import Capacities from './Capacities';
import AccountCodes from './AccountCodes';

interface Props {
  destinationId?: string;
}

const Admin: React.FC<Props> = ({ destinationId }) => {
  return (
    <TwoColumns
      leftColumn={
        <>
          {destinationId && <Capacities destinationId={destinationId} />}
          {destinationId && <AccountCodes destinationId={destinationId} />}
        </>
      }
      rightColumn={
        <>{destinationId && <LocationPrices destinationId={destinationId} />}</>
      }
    />
  );
};

export default Admin;
