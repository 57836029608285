import React, { useCallback } from 'react';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { balanceStatusToIcon } from '../../utils';
import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import { BookingCount, ConfirmedBookingTableData } from '../types';
import { apiRequest, useAPI } from '../../../../../api/api';
import Table from '../../../../../framework-components/table/Table';
import { formatDateToDayDateMonthYear } from '../../../../../utils/dates';
import {
  CombinedFilters,
  TableData,
  SelectedFilters,
  PartialFilterOptions,
} from '../../../../../framework-components/table/types';
import ProgressTracker from '../../applicant-profile/ProgressTracker';
import SendReminder from '../../applicant-profile/SendReminder';

const ConfirmedBookings: React.FC = () => {
  const { data: count } = useAPI<BookingCount>('/api/admin/bookings/count/');

  const getConfirmBookingTableData = useCallback(
    (selectedFilters: CombinedFilters) => {
      return apiRequest<TableData<ConfirmedBookingTableData>>(
        '/api/admin/bookings/confirmed-bookings/?' +
          new URLSearchParams(selectedFilters).toString()
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const getFilterOptions = useCallback((selectedFilters: CombinedFilters) => {
    return apiRequest<PartialFilterOptions<ConfirmedBookingTableData>>(
      '/api/admin/bookings/confirmed-bookings-filters/?' +
        new URLSearchParams(selectedFilters).toString()
    ).then((response) => {
      return response;
    });
  }, []);

  const getHighlightRowClass = useCallback(
    (row: ConfirmedBookingTableData, filters: SelectedFilters) => {
      if (
        (row.start_conflict || row.end_conflict) &&
        filters.show_clashes?.value
      ) {
        return 'bg-wtw-trafficlight-red';
      }
      return '';
    },
    []
  );

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: '/admin/bookings/pending',
        },
        {
          label: 'Confirmed',
          href: null,
        },
      ]}
      title="Confirmed"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: `Pending ${count ? `(${count.pending})` : ''}`,
              href: '/admin/bookings/pending',
              current: false,
            },
            {
              label: `Confirmed ${count ? `(${count.confirmed})` : ''}`,
              href: '/admin/bookings/confirmed',
              current: true,
            },
            {
              label: `On Trip ${count ? `(${count.on_trip})` : ''}`,
              href: '/admin/bookings/on-trip',
              current: false,
            },
            {
              label: `Postponed & archived ${
                count ? `(${count.postponed_and_archived})` : ''
              }`,
              href: '/admin/bookings/postponed',
              current: false,
            },
          ]}
          variant="pilled_tab"
        />

        <Table<ConfirmedBookingTableData>
          columnsConfig={{
            applicant: {
              key: 'applicant',
              header: 'Name',
              enableSort: true,
              renderCell: (request) => (
                <a
                  href={`/admin/bookings/${request.id}/applicant-profile/`}
                  title={request.applicant}
                >
                  {request.applicant}
                </a>
              ),
            },
            destination: {
              key: 'destination',
              header: 'Destination',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (request) => request.destination.name,
            },
            discipline: {
              key: 'discipline',
              header: 'Discipline',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (request) => request.discipline.name,
            },
            start_date: {
              key: 'start_date',
              header: 'Start Date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) => (
                <div>
                  {formatDateToDayDateMonthYear(request.start_date)}
                  {request.start_conflict ? (
                    <FontAwesomeIcon icon={faPlane} className="ml-1" />
                  ) : (
                    ''
                  )}
                </div>
              ),
            },
            end_date: {
              key: 'end_date',
              header: 'End date',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) => (
                <div className="flex items-center gap-1">
                  {formatDateToDayDateMonthYear(request.end_date)}
                  {request.end_conflict ? (
                    <FontAwesomeIcon icon={faPlane} />
                  ) : (
                    ''
                  )}
                </div>
              ),
            },
            balance_status: {
              key: 'balance_status',
              header: 'Balance',
              enableSort: true,
              filterType: 'local-dropdown',
              renderCell: (request) => {
                return (
                  <div className="flex gap-2 text-center justify-center">
                    <p>
                      {balanceStatusToIcon(
                        request.balance_status.balance_status
                      )}
                    </p>
                    <p className="text-red-400">
                      {request.balance_status.days_overdue
                        ? `${request.balance_status.days_overdue} days`
                        : ''}
                    </p>
                  </div>
                );
              },
            },
            flags: {
              key: 'flags',
              header: 'Flags',
              filterType: 'local-dropdown',
              renderCell: (booking) => (
                <ProgressTracker trackingData={booking.flags} variant="small" />
              ),
            },
            last_reminder_sent_at: {
              key: 'last_reminder_sent_at',
              header: 'Last reminder sent',
              enableSort: true,
              filterType: 'calendar',
              renderCell: (request) =>
                request.last_reminder_sent_at &&
                formatDateToDayDateMonthYear(request.last_reminder_sent_at),
            },
            send_reminders: {
              key: 'send_reminders',
              header: 'Send reminder',
              renderCell: (request) => (
                <div className="text-center">
                  <SendReminder
                    bookingId={request.id}
                    last_sent={request.last_reminder_sent_at ?? null}
                  />
                </div>
              ),
            },
          }}
          getData={getConfirmBookingTableData}
          getFilterOptions={getFilterOptions}
          enablePagination
          enableSearch
          additionalFiltersConfig={{
            show_clashes: {
              key: 'show_clashes',
              label: 'Flight clashes',
              filterType: 'toggle',
            },
            year: {
              key: 'year',
              label: 'Year',
              filterType: 'local-dropdown',
            },
            month: {
              key: 'month',
              label: 'Month',
              filterType: 'local-dropdown',
            },
            photo_upload_status: {
              key: 'photo_upload_status',
              label: 'Photos awaiting approval',
              filterType: 'local-dropdown',
            },
            university: {
              key: 'university',
              label: 'University',
              filterType: 'local-dropdown',
            },
          }}
          getHighlightRowClass={getHighlightRowClass}
        />
      </div>
    </AdminStandardPage>
  );
};

export default ConfirmedBookings;
