import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '../../../framework-components/button/Button';
import AdminWidgetContainer from '../../../framework-components/card/AdminWidgetContainer';

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const onClickRedirectToChangePasswordScreen = () =>
    navigate('/my-trip/change-password/');

  return (
    <AdminWidgetContainer title="Password" className="space-y-6">
      <PrimaryButton
        label="Change Password"
        onClick={onClickRedirectToChangePasswordScreen}
      />
    </AdminWidgetContainer>
  );
};

export default ChangePassword;
