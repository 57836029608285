import React from 'react';

import AdaptiveText from '../AdaptiveText';

const SafariContent: React.FC = () => {
  return (
    <div className="p-4">
      <h1 className="text-sh3 mb-2">Safari</h1>
      <AdaptiveText>
        <div className="space-y-2">
          <p className="font-semibold">
            Whatever you get up to during your African experience, there's just
            no substitute for getting up close to elephants, lions, giraffes and
            buffalo from an open-top 4x4.
          </p>
          <p>
            We're often told that a big game safari is the highlight of the
            trip, and it's no wonder-Tanzania has some of the most breathtaking
            landscapes and wildlife in the World.
          </p>
          <p>
            Book your safari through Work the World, and we'll deal with all of
            the logistics at a competitive price.
          </p>
          <p>
            This safari is available exclusively to those travelling with Work
            the World and is tailored to ensure you see the very best of the
            local area.
          </p>
          <p>
            The package is inclusive of 4x4 safari vehicles with
            English-speaking driver guides, accommodation is on a full board
            basis and includes all park entry fees and soft drinks. Unlike some
            other safaris, there are no hidden costs.
          </p>
          <p>
            To start your adventure, you'll travel through the majestic Uluguru
            Mountains to reach Mikumi National Park. Mikumi is referred to as
            the hidden gem of Tanzania by those in the know, as it's tucked away
            from the tourist trail of the Northern Circuit yet abundant with
            wildlife. You'll have the chance to see lions, leopards, giraffes,
            elephants, hippos, zebra, buffalo and impala, crocs and hippos, to
            name a few.
          </p>
          <p className="font-semibold">The Safari</p>
          <p>
            Your 4x4 safari vehicle will pick you up from the Work the World
            house early on Friday morning, and take you directly to Mikumi
            National Park. Following an exciting afternoon game drive, you'll
            arrive at your camp to enjoy a relaxing evening. Over the weekend,
            your expert safari guide will take you to see the best landscapes
            and a diversity of wildlife throughout the area.
          </p>
          <p>
            Your camp is a mid-range luxury camp with fully furnished cottages.
            Security is on-site 24 hours a day, and you'll be able to socialise
            in the evenings with other travellers (often around a campfire)!
            Breakfast and dinner are normally served buffet style, and packed
            lunches will be supplied when you're out on safari. Dietary
            requirements can, of course be catered for.
          </p>
          <p>
            On Sunday morning, you will head back to the Work the World house,
            through the mountains and may even spot an animal or two on the
            journey home.
          </p>
          <p>
            Secure your space by clicking the{' '}
            <span className="font-semibold">'Add to my booking'</span> button.
            The safari will be charged separately from your main placement fee.
            Once we have received your payment, your status (top right of this
            page) will change to{' '}
            <span className="font-semibold">'You have booked this add-on'</span>
            .
          </p>
          <p>
            Before your departure to Dar es Salaam, a member of our team will
            confirm the weekend you will be going on safari. Although we are
            unable to take requests for specific dates, we will make sure that
            your safari experience is arranged to fit your placement details.
          </p>
          <p>
            Please note that interest is high, and safari group sizes are
            limited to twelve people per weekend. Early booking is strongly
            recommended to avoid disappointment. Availability cannot be
            guaranteed if you choose to sign up after you have arrived in
            Tanzania, and will also incur an additional administration charge.
          </p>
          <p>
            Please also note that if you are undertaking a 1-week placement, you
            will not be able to book a safari through Work the World. If you are
            undertaking a 1-week placement and wish to independently organise a
            safari, please seek guidance from the house team after you have
            arrived in the country.
          </p>
        </div>
      </AdaptiveText>
    </div>
  );
};

export default SafariContent;
