import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { useCallback } from 'react';

export type AlertMessage = string | (() => React.JSX.Element);
export type AlertVariant = 'error' | 'warning' | 'info' | 'success';

export interface AlertProps {
  variant?: AlertVariant;
  title?: string;
  message: AlertMessage;
  dismissable?: boolean;
  actionLabel?: string;
  onClickAction?: () => void;
}

const Alert: React.FC<AlertProps> = ({
  title,
  variant = 'info',
  message,
  dismissable,
  actionLabel,
  onClickAction,
}) => {
  const [isVisibile, setIsVisible] = React.useState(true);

  const getBackgroundColor = () => {
    switch (variant) {
      case 'error':
        return 'bg-red-50';
      case 'warning':
        return 'bg-yellow-50';
      case 'info':
        return 'bg-blue-50';
      case 'success':
        return 'bg-green-50';
      default:
        return 'bg-blue-50';
    }
  };

  const renderIcon = () => {
    switch (variant) {
      case 'error':
        return (
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        );
      case 'warning':
        return (
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        );
      case 'info':
        return (
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        );
      case 'success':
        return (
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        );
      default:
        return (
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        );
    }
  };

  const renderMessage = () => {
    if (typeof message === 'string') {
      return (
        <p
          className={classNames('text-sm', {
            'text-yellow-700': variant === 'warning',
            'text-red-700': variant === 'error',
            'text-green-700': variant === 'success',
            'text-blue-700': variant === 'info',
          })}
        >
          {message}
        </p>
      );
    }

    return message();
  };

  const dismissAlert = useCallback(() => setIsVisible(false), []);

  const renderDismissButtonIfDismissable = () => {
    if (!dismissable) return null;

    return (
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            type="button"
            className={classNames(
              'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
              {
                'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50':
                  variant === 'success',
              },
              {
                'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50':
                  variant === 'warning',
              },
              {
                'bg-red-50 text-red-400 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50':
                  variant === 'error',
              },
              {
                'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50':
                  variant === 'info',
              }
            )}
            onClick={dismissAlert}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  };

  const renderActionLabel = () => {
    if (!actionLabel) return null;

    return (
      <div className="ml-3 flex-1 justify-end flex md:justify-items-end items-center">
        <p className="mt-3 text-sm md:ml-6 md:mt-0">
          <button
            type="button"
            className={classNames(
              'whitespace-nowrap font-medium',
              {
                'text-yellow-700 hover:text-yellow-600': variant === 'warning',
              },
              { 'text-red-700 hover:text-red-600': variant === 'error' },
              { 'text-green-700 hover:text-green-600': variant === 'success' },
              { 'text-blue-700 hover:text-blue-600': variant === 'info' }
            )}
            onClick={onClickAction}
          >
            {actionLabel}
            <span aria-hidden="true"> &rarr;</span>
          </button>
        </p>
      </div>
    );
  };

  const renderTitle = useCallback(() => {
    return (
      <h3
        className={classNames('font-medium', {
          'text-yellow-800': variant === 'warning',
          'text-red-800': variant === 'error',
          'text-green-800': variant === 'success',
          'text-blue-800': variant === 'info',
        })}
      >
        {title}
      </h3>
    );
  }, [title, variant]);

  if (!isVisibile) return null;

  return (
    <div className={classNames('rounded-md 50 p-4', getBackgroundColor())}>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="flex flex-wrap">
            <div>{renderIcon()}</div>
            <div className="ml-2">{renderTitle()}</div>
            {renderDismissButtonIfDismissable()}
          </div>
          <div className="mt-2 text-sm">{renderMessage()}</div>
        </div>

        {renderActionLabel()}
      </div>
    </div>
  );
};

export default Alert;
