import React from 'react';

const ArchiveIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.83203 4.5H16.082C16.5469 4.5 16.957 4.91016 16.957 5.375V6.25C16.957 6.74219 16.5469 7.125 16.082 7.125H3.83203C3.33984 7.125 2.95703 6.74219 2.95703 6.25V5.375C2.95703 4.91016 3.33984 4.5 3.83203 4.5ZM3.83203 8H16.082V15C16.082 15.9844 15.2891 16.75 14.332 16.75H5.58203C4.59766 16.75 3.83203 15.9844 3.83203 15V8ZM7.33203 10.1875C7.33203 10.4336 7.52344 10.625 7.76953 10.625H12.1445C12.3633 10.625 12.582 10.4336 12.582 10.1875C12.582 9.96875 12.3633 9.75 12.1445 9.75H7.76953C7.52344 9.75 7.33203 9.96875 7.33203 10.1875Z"
        fill="#2568EB"
      />
    </svg>
  );
};

export default ArchiveIcon;
