import { apiRequest } from '../../../../api/api';
import { AddSafari } from './types';

export const addSafari = (bookingId: string, values: AddSafari) => {
  const url = `/api/my-trip/booking/${bookingId}/add-safari/`;
  return apiRequest<AddSafari>(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};
