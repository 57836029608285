import { apiRequest } from '../../../../api/api';
import { Archive, GeneralInfo, Summary } from './types';

export const archiveEnquiry = (enquiryId: string, values: Archive) => {
  const url = `/api/admin/enquiries/${enquiryId}/archive/`;
  return apiRequest<Archive>(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};

export const updateGeneralInfo = (enquiryId: string, values: GeneralInfo) => {
  const url = `/api/admin/enquiries/${enquiryId}/general-info/update/`;
  return apiRequest<GeneralInfo>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...values,
      discipline: values.discipline?.value,
      experience_duration: values.experience_duration ? 1 : 0,
      education_status: values.education_status?.value,
    }),
  });
};

export const setEnquiryContacted = (enquiryId: string) => {
  const url = `/api/admin/enquiries/${enquiryId}/set-contacted/`;
  return apiRequest(url, {
    method: 'POST',
  });
};

export const updateSummary = (enquiryId: string, values: Summary) => {
  const url = `/api/admin/enquiries/${enquiryId}/summary/update/`;
  return apiRequest<Summary>(url, {
    method: 'POST',
    body: JSON.stringify({
      ...values,
      gender: values.gender?.value,
      country: values.country?.value,
      university: values.university?.value,
      year_of_university_study: values.year_of_university_study?.value,
      year_of_graduation: values.year_of_graduation?.value,
    }),
  });
};
