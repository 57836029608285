import React from 'react';

import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import ArchiveHospital from './ArchiveHospital';

interface Props {
  hospitalId: string;
  isArchived: boolean;
}

const HospitalActions: React.FC<Props> = ({ hospitalId, isArchived }) => {
  return (
    <AdminWidgetContainer className="bg-white" title="Actions:">
      <div className="flex flex-wrap gap-4 mt-6">
        <ArchiveHospital hospitalId={hospitalId} isArchived={isArchived} />
      </div>
    </AdminWidgetContainer>
  );
};

export default HospitalActions;
