import React from 'react';

interface PageContainerProps {
  banner?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
}

const PageContainer: React.FC<PageContainerProps> = ({ banner, children }) => {
  return (
    <>
      {banner}
      <div className="mx-auto w-full md:max-w-[1440px] min-h-[500px] flex flex-col p-12 pt-9 relative">
        {children}
      </div>
    </>
  );
};

export default PageContainer;
