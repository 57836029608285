import React from 'react';

import { TSHIRT_SIZE_OPTIONS } from '../../../my-trip/about-me/constants';
import ManageSingleObject from '../../../manage-single-object/ManageSingleObject';
import { updateTShirtSize } from './actions';
import { TShirtSizeData } from './types';

const TShirtSize: React.FC<{ bookingId: string; canEdit: boolean }> = ({
  bookingId,
  canEdit,
}) => {
  return (
    <ManageSingleObject<TShirtSizeData>
      title="T-shirt size"
      titleClassName="text-mob-sh2"
      insideCard={false}
      getUrl={`/api/admin/bookings/${bookingId}/tshirt-size/get/`}
      submitItem={(values) => updateTShirtSize(bookingId, values)}
      descriptionFields={[
        {
          label: 'T-shirt size',
          render: (item) => item.tshirt_size?.label,
        },
      ]}
      formFields={[
        {
          type: 'local-dropdown',
          name: 'tshirt_size',
          label: 'T-shirt size (unisex)',
          options: TSHIRT_SIZE_OPTIONS,
        },
      ]}
      buttonLabel="Update"
      editButton={canEdit}
    />
  );
};

export default TShirtSize;
