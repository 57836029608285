import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAPI } from '../../../../../api/api';
import Logo from '../../../../Logo';
import { dateToUkFormattedDayMonthYear } from '../../../../../utils/dates';
import { GenderType } from '../../../../booking/booking-form/types';

interface HospitalBadgeData {
  id: string;
  applicant: {
    first_name: string;
    last_name: string;
    nationality: {
      nationality_name: string;
    };
    university_course: string;
    year_of_university_study: number | null;
    year_of_graduation: number | null;
    id_photo: string | null;
    gender: GenderType;
  };
  discipline: {
    name: string;
  };
  hospitaldepartmentbooking_set: ReadonlyArray<{
    hospital_department: {
      display_name: string;
    };
    from_date: string;
    to_date: string;
  }>;
}

const getYear = (
  year_of_university_study: number | null,
  year_of_graduation: number | null
) => {
  if (year_of_university_study) {
    return `Year ${year_of_university_study}`;
  } else if (year_of_graduation) {
    return `Graduated ${year_of_graduation}`;
  }
  return null;
};

const getImageSrc = (id_photo: string | null, gender: GenderType) => {
  if (id_photo) {
    return id_photo;
  } else if (gender === 'MALE') {
    return '/static/unknown_M.gif';
  }
  return '/static/unknown_F.gif';
};

const HospitalBadge: React.FC<{ booking: HospitalBadgeData }> = ({
  booking,
}) => {
  const year = getYear(
    booking.applicant.year_of_university_study,
    booking.applicant.year_of_graduation
  );
  const imageSrc = getImageSrc(
    booking.applicant.id_photo,
    booking.applicant.gender
  );
  return (
    <div className="w-full h-full border p-4 flex flex-col items-center justify-between border">
      <div className="flex gap-4 items-center justify-start w-full">
        <img src={imageSrc} className="w-[32mm] shrink-0" />
        <div>
          <p className="font-bold mb-1">{`${booking.applicant.first_name} ${booking.applicant.last_name}`}</p>
          <p className="text-[14px] font-semibold">
            {booking.applicant.university_course}
          </p>
          <p className="text-[14px] font-semibold">
            {booking.applicant.nationality.nationality_name}
          </p>
          {year && <p className="text-[14px] font-semibold">{year}</p>}
          {booking.hospitaldepartmentbooking_set.map((department, index) => (
            <p
              key={`${department.hospital_department.display_name}-${index}`}
              className="text-[12px]"
            >
              {`${
                department.hospital_department.display_name
              } ${dateToUkFormattedDayMonthYear(
                department.from_date
              )} - ${dateToUkFormattedDayMonthYear(department.to_date)}`}
            </p>
          ))}
        </div>
      </div>
      <Logo className="mt-2 w-48" />
    </div>
  );
};

const HospitalBadges: React.FC = () => {
  const [searchParams] = useSearchParams();
  const bookingIds = searchParams.get('bookingIds');
  const url = 'api/admin/bookings/hospital-badges/?bookingIds=' + bookingIds;

  const { data, isValidating, error } = useAPI<
    ReadonlyArray<HospitalBadgeData>
  >(bookingIds ? url : null);

  const bookings = data || [];
  return (
    <div className="w-[210mm] grid grid-cols-2 grid-rows-4 gap-2">
      {isValidating && <div>Loading...</div>}
      {error && !isValidating && <div>Error getting hospital badges.</div>}
      {bookings.map((booking) => (
        <HospitalBadge key={booking.id} booking={booking} />
      ))}
    </div>
  );
};

export default HospitalBadges;
