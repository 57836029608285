import React from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import Modal from '../../../modal';
import QuickForm from '../../../../framework-components/quickform/QuickForm';
import { apiRequest } from '../../../../api/api';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  bookingId: string;
}

interface FormValues {
  xero_invoice_id: string;
  xero_invoice_number: string;
}

const CreateCreditNoteModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  bookingId,
}) => {
  const focusRef = React.useRef(null);
  return (
    <Modal isOpen={isOpen} onClose={closeModal} initialFocus={focusRef}>
      <div className="space-y-4 flex flex-col ml-4 mr-4">
        <Dialog.Title className="text-2xl font-bold" ref={focusRef}>
          Create Invoice
        </Dialog.Title>
        <QuickForm
          initialValues={{
            xero_invoice_id: '',
            xero_invoice_number: '',
          }}
          fields={[
            {
              type: 'smalltext',
              name: 'xero_credit_note_id',
              label: 'Credit note ID',
            },
            {
              type: 'smalltext',
              name: 'xero_credit_note_number',
              label: 'Credit note number',
            },
          ]}
          onSubmit={(data: FormValues) => {
            return apiRequest<FormValues>(
              `/api/admin/bookings/${bookingId}/create-credit-note/`,
              {
                method: 'POST',
                body: JSON.stringify(data),
              }
            );
          }}
          onSubmitSuccess={() => {
            toast.success('Successfully created invoice');
            closeModal();
            mutate(
              `/api/admin/bookings/${bookingId}/get-invoice-and-credit-note-list/`
            );
          }}
        />
      </div>
    </Modal>
  );
};

export default CreateCreditNoteModal;
