import React, { useState, useRef } from 'react';
import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';

import { Event } from './types';
import { calculateDaysBetweenDates } from '../../utils/dates';
import { CELL_WIDTH } from './constants';
import RightClickMenu from './RightClickMenu';
import { EventMetadata as DepartmentEventMetadata } from '../admin/capacities/department/types';

const hasDisciplineSymbol = (
  metadata: unknown
): metadata is DepartmentEventMetadata => {
  return (
    typeof metadata === 'object' &&
    metadata !== null &&
    'discipline' in metadata &&
    typeof (metadata as DepartmentEventMetadata).discipline?.symbol === 'string'
  );
};

const EditableOverlay = <T,>({
  style,
  event,
  getOverlayColour,
  selectedEvent,
  numLabelsToRender,
}: {
  style: React.CSSProperties;
  event: Event<T>;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
  selectedEvent: Event<T> | null;
  numLabelsToRender: number;
}) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${event.id}`,
  });
  const overlayColour = getOverlayColour
    ? getOverlayColour(event, false)
    : 'bg-blue-300';

  const borderColour =
    selectedEvent?.id === event.id ? 'border border-2 border-blue-500' : '';

  return (
    <div
      data-testid="event-overlay"
      style={style}
      className={classNames(
        'absolute top-0 left-0 h-full z-10 opacity-100 cursor-pointer flex items-center justify-center',
        overlayColour,
        borderColour
      )}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {Array.from({ length: numLabelsToRender }).map((_, index) => {
        if (event.metadata !== undefined) {
          return (
            <div
              key={index}
              className="truncate"
              style={{ width: CELL_WIDTH * 14 }}
            >
              {event.metadata &&
                hasDisciplineSymbol(event.metadata) &&
                `[${event.metadata.discipline.symbol}] `}
              {event.label}
            </div>
          );
        }
        return (
          <div
            key={index}
            className="truncate"
            style={{ width: CELL_WIDTH * 14 }}
          >
            {event.label}
          </div>
        );
      })}
    </div>
  );
};

const ReadonlyOverlay = <T,>({
  style,
  event,
  getOverlayColour,
  selectedEvent,
  setSelectedEvent,
  numLabelsToRender,
}: {
  style: React.CSSProperties;
  event: Event<T>;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
  selectedEvent: Event<T> | null;
  setSelectedEvent: React.Dispatch<
    React.SetStateAction<Event<T> | null>
  > | null;
  numLabelsToRender: number;
}) => {
  const overlayColour = getOverlayColour
    ? getOverlayColour(event, false)
    : 'bg-blue-300';

  const borderColour =
    selectedEvent?.id === event.id ? 'border border-2 border-blue-500' : '';

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (setSelectedEvent) {
      setSelectedEvent(event);
    }
  };
  return (
    <div
      data-testid="event-overlay"
      style={style}
      className={classNames(
        'absolute top-0 left-0 h-full z-10 opacity-100 overflow-hidden flex items-center justify-center',
        { 'cursor-pointer': setSelectedEvent },
        { 'cursor-default': !setSelectedEvent },
        overlayColour,
        borderColour
      )}
      onClick={handleClick}
    >
      {Array.from({ length: numLabelsToRender }).map((_, index) => {
        if (event.metadata !== undefined) {
          return (
            <div
              key={index}
              className="truncate"
              style={{ width: CELL_WIDTH * 14 }}
            >
              {event.metadata &&
                hasDisciplineSymbol(event.metadata) &&
                `[${event.metadata.discipline.symbol}] `}
              {event.label}
            </div>
          );
        }
        return (
          <div
            key={index}
            className="truncate"
            style={{ width: CELL_WIDTH * 14 }}
          >
            {event.label}
          </div>
        );
      })}
    </div>
  );
};

const EventOverlay = <T,>({
  isEditing,
  event,
  sectionStartDate,
  sectionEndDate,
  getOverlayColour,
  selectedEvent,
  setSelectedEvent,
}: {
  isEditing: boolean;
  event: Event<T>;
  sectionStartDate: Date;
  sectionEndDate: Date;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
  selectedEvent: Event<T> | null;
  setSelectedEvent: React.Dispatch<React.SetStateAction<Event<T> | null>>;
}) => {
  const eventOverlapsStart = event && event.start_date < sectionStartDate;
  const eventOverlapsEnd = event && event.end_date > sectionEndDate;

  const isEventPartial = eventOverlapsStart || eventOverlapsEnd;

  const relevantStartDate = eventOverlapsStart
    ? sectionStartDate
    : event.start_date;
  const relevantEndDate = eventOverlapsEnd ? sectionEndDate : event.end_date;

  const eventDuration =
    calculateDaysBetweenDates(relevantStartDate, relevantEndDate) + 1;

  const eventCellWidth = eventDuration * CELL_WIDTH;
  const numLabelsToRender = Math.ceil(eventDuration / 14);

  const style = { width: `${eventCellWidth}px` };
  const overlayColour = getOverlayColour
    ? getOverlayColour(event, true)
    : 'bg-blue-100';

  const containerRef = useRef<HTMLDivElement>(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
  });

  const handleContextMenu: React.MouseEventHandler<HTMLDivElement> = (
    contextEvent
  ) => {
    contextEvent.preventDefault();
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = contextEvent.clientX - rect.left;
      const y = contextEvent.clientY - rect.top;

      setContextMenu({
        visible: true,
        x,
        y,
      });
    }
  };

  return (
    <div ref={containerRef} onContextMenu={handleContextMenu}>
      <RightClickMenu
        event={event}
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
      />
      {isEventPartial ? (
        <div
          data-testid="event-overlay"
          style={style}
          className={classNames(
            'absolute top-0 left-0 h-full z-10 opacity-100 flex items-center',
            overlayColour
          )}
          onClick={selectedEvent ? () => setSelectedEvent(null) : undefined}
        >
          <div className="truncate">{event.label}</div>
        </div>
      ) : (
        <>
          {isEditing && selectedEvent?.id === event.id ? (
            <EditableOverlay
              style={style}
              event={event}
              getOverlayColour={getOverlayColour}
              selectedEvent={selectedEvent}
              numLabelsToRender={numLabelsToRender}
            />
          ) : (
            <ReadonlyOverlay
              style={style}
              event={event}
              getOverlayColour={getOverlayColour}
              selectedEvent={selectedEvent}
              setSelectedEvent={isEditing ? setSelectedEvent : null}
              numLabelsToRender={numLabelsToRender}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EventOverlay;
