import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import ManageSingleObject from '../components/ManageSingleObject/ManageSingleObject';
import { updateTravelInsurance } from './actions';
import { TravelInsurance } from './types';
interface Props {
  booking_id: string;
}

const TravelInsuranceForm: React.FC<Props> = ({ booking_id }) => {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location.hash === '#travel-insurance') {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);
  return (
    <div ref={ref}>
      <ManageSingleObject<TravelInsurance>
        submitItem={(values) => updateTravelInsurance(booking_id, values)}
        insideCard={false}
        buttonLabel="Update"
        title="Already have travel insurance?"
        getUrl={`/api/my-trip/travel-details/${booking_id}/get-travel-insurance/`}
        subtitle={
          <>
            <p className="mb-4">
              If you already have a travel insurance policy in place please
              ensure it meets the minimum requirements that can be found in your{' '}
              <a href="/my-trip/info-pack/" className="underline">
                information pack
              </a>
              .
            </p>
            <p>
              You must obtain travel insurance to cover you for the period of
              your placement. Whilst we strongly recommend that you obtain a
              fully comprehensive insurance policy, your policy must at least
              cover the following:
            </p>
            <ol className="bg-red-200 rounded-xl p-5 mx-2 my-6">
              <li>
                1. Your repatriation and that of your belongings to your home
                address in the country of your residence
              </li>
              <li>
                2. Medical cover for the period of the Placement and your travel
                to and from the Placement between the country of your residence
                and the country the Placement takes place in
              </li>
              <li>3. Your death or personal injury</li>
              <li>4. Loss, theft or damage of your belongings or money</li>
              <li>5. Cancelled or delayed flights</li>
            </ol>
          </>
        }
        formFields={[
          {
            type: 'smalltext',
            name: 'travel_insurance_company',
            label: 'Insurance Company',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'travel_insurance_policy_type',
            label: 'Policy type',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'travel_insurance_policy_number',
            label: 'Policy Number',
            extraProps: {
              row: true,
            },
          },
          {
            type: 'smalltext',
            name: 'travel_insurance_contact_tel',
            label: 'Contact tel',
            extraProps: {
              row: true,
            },
          },
        ]}
      />
    </div>
  );
};

export default TravelInsuranceForm;
