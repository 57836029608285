import React from 'react';

import Modal from '../../../modal';
import useModalState from '../../../../hooks/modals';

const ImportedBookingMarker: React.FC = () => {
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <div
      className="flex justify-center w-[200px] bg-yellow-200 p-1 border-2 border-yellow-300 rounded-lg cursor-pointer hover:bg-yellow-300"
      onClick={openModal}
    >
      <h3 className="text-md-bold">(!) Migrated Booking</h3>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} variant={'small'}>
          <div className="flex flex-col items-center">
            <p>
              <span className="text-md-bold">CAUTION:</span> This booking was
              migrated. Prices and other details should be double-checked before
              making changes, as unexpected side effects may occur. The pricing
              table may not match up with the items found in the ledger.
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ImportedBookingMarker;
