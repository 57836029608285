import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';

import Card from '../../../../framework-components/card/AdminWidgetContainer';
import { SelectButton } from '../../../../framework-components/button/Button';
import useModalState from '../../../../hooks/modals';
import UploadModal from '../../common/UploadModal';
import PreviewModal from '../../common/PreviewModal';
import {
  deleteMonitoringForm,
  updateTripAsContactedByMarketing,
  updateTripWithPositiveFeedback,
  uploadMonitoringForm,
} from './actions';
import { useAPI } from '../../../../api/api';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import { GetMonitoringForms } from './types';
import { download } from '../../../../utils/download';
import InstantSaveCheckbox from '../../../instant-save-checkbox/InstantSaveCheckbox';
import MonitoringFormDeleteModal from './MonitoringFormDeleteModal';

type Field =
  | 'monitoring_form_first'
  | 'monitoring_form_second'
  | 'monitoring_form_debrief';

type Status = 'INCOMPLETE' | 'COMPLETE';

const StatusLabelMap: Record<Status, string> = {
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete',
};

const StatusPill = ({ status }: { status: Status }) => {
  return (
    <div
      className={classNames(
        'rounded-full w-[86px] flex items-center justify-center',
        {
          'bg-red-200': status === 'INCOMPLETE',
          'bg-green-200': status === 'COMPLETE',
        }
      )}
    >
      <p>{StatusLabelMap[status]}</p>
    </div>
  );
};

const fileIsImage = (fileExtension?: string) => {
  return (
    fileExtension &&
    ['png', 'jpg', 'jpeg'].includes(fileExtension.toLowerCase())
  );
};

interface RowProps {
  field: Field;
  title: string;
  status: Status;
  index: number;
  bookingId: string;
  source?: string;
  fileName?: string;
  fileExtension?: string;
  onDelete?: (source: string, field: Field) => void;
  openDeleteModal?: (source: string, field: Field) => void;
  closeDeleteModal?: () => void;
}

const Row: React.FC<RowProps> = ({
  field,
  title,
  status,
  index,
  bookingId,
  source,
  fileName,
  fileExtension,
  onDelete,
}) => {
  const {
    isOpen: isUploadModalOpen,
    openModal: openUploadModal,
    closeModal: closeUploadModal,
  } = useModalState();

  const {
    isOpen: isPreviewModalOpen,
    openModal: openPreviewModal,
    closeModal: closePreviewModal,
  } = useModalState();

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'upload') {
        openUploadModal();
      } else if (event.target.value === 'preview') {
        openPreviewModal();
      } else if (event.target.value === 'download') {
        if (source && fileName) {
          download(source, fileName);
        }
      }
      event.target.value = '';
    },
    [openUploadModal, openPreviewModal, source, fileName]
  );

  const successfulUploadHandler = useCallback(
    async (fileId: string) => {
      closeUploadModal();
      try {
        await uploadMonitoringForm(bookingId, {
          [field]: fileId,
        });
        toast.success('File uploaded successfully');
        mutate(`/api/admin/bookings/${bookingId}/get-monitoring-forms/`);
      } catch {
        toast.error('Failed to upload file');
      }
    },
    [field, bookingId, closeUploadModal]
  );

  const renderOptions = () => (
    <>
      <option value="upload" key={`${title}-upload`}>
        Upload
      </option>
      {source &&
        (fileIsImage(fileExtension) ? (
          <option value="preview" key={`${title}-preview`}>
            Preview
          </option>
        ) : (
          <option value="download" key={`${title}-download`}>
            Download
          </option>
        ))}
      <UploadModal
        isOpen={isUploadModalOpen}
        closeModal={closeUploadModal}
        title="Upload file"
        onSuccessfulUpload={successfulUploadHandler}
        acceptedExtensions={['png', 'jpg', 'webp', 'pdf']}
      />
      <PreviewModal
        isOpen={isPreviewModalOpen}
        closeModal={closePreviewModal}
        title="Preview file"
        source={source}
      />
    </>
  );
  return (
    <div
      className={classNames('p-4 flex items-center justify-between text-xs', {
        'bg-wtw-gray-1': index % 2 === 0,
        'bg-wtw-gray-3': index % 2 === 1,
      })}
    >
      <p className="w-[165px]">{title}</p>
      <StatusPill status={status} />
      {source && onDelete ? (
        <FontAwesomeIcon
          icon={faTrashAlt}
          onClick={() => {
            onDelete(source, field);
          }}
          className="text-red-500"
        />
      ) : (
        <FontAwesomeIcon icon={faTrashAlt} className={'text-gray-400'} />
      )}
      <SelectButton
        onChange={changeHandler}
        renderOptions={() => renderOptions()}
        defaultLabel="Actions"
      />
    </div>
  );
};

interface Props {
  bookingId: string;
}

const MonitoringForms: React.FC<Props> = ({ bookingId }) => {
  const { data, isValidating, error } = useAPI<GetMonitoringForms>(
    `/api/admin/bookings/${bookingId}/get-monitoring-forms/`
  );

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModalState();

  const [deleteData, setDeleteData] = useState<{
    field: Field;
    source: string;
  } | null>(null);

  const handleOpenDeleteModal = (source: string, field: Field) => {
    setDeleteData({ source, field });
    openDeleteModal();
  };

  const handleDeleteMonitoringForm = useCallback(
    async (field: Field | undefined, source: string | undefined) => {
      if (!bookingId) {
        return;
      }
      if (!source || !field) {
        return;
      }
      try {
        await deleteMonitoringForm(bookingId, field);
        mutate(`/api/admin/bookings/${bookingId}/get-monitoring-forms/`);
        toast.success('Monitoring form deleted successfully!');
        closeDeleteModal();
      } catch (e) {
        toast.error('Failed to delete monitoring form');
      }
    },
    [bookingId, closeDeleteModal]
  );

  const toggleContactedChecked = useCallback(async () => {
    const updatedState = !data?.contacted_for_marketing_purposes;

    try {
      await updateTripAsContactedByMarketing(bookingId, updatedState);
      mutate(
        `/api/admin/bookings/${bookingId}/get-monitoring-forms/`,
        (monitoring_forms: GetMonitoringForms) => ({
          ...monitoring_forms,
          contacted_for_marketing_purposes: updatedState,
        }),
        false
      );
      toast.success('Trip marked as contacted by marketing');
    } catch {
      toast.error('Failed to update trip as contacted');
    }
  }, [bookingId, data]);

  const togglePositiveExperienceChecked = useCallback(async () => {
    const updatedState = !data?.has_positive_feedback;

    try {
      await updateTripWithPositiveFeedback(bookingId, updatedState);
      mutate(
        `/api/admin/bookings/${bookingId}/get-monitoring-forms/`,
        (monitoring_forms: GetMonitoringForms) => ({
          ...monitoring_forms,
          has_positive_feedback: updatedState,
        }),
        false
      );
      toast.success('Trip experience updated successfully');
    } catch {
      toast.error('Failed to update trip experience');
    }
  }, [bookingId, data]);

  return (
    <Card title="Monitoring and debrief forms">
      {isValidating && <LoadingOverlay />}
      {error && !isValidating && <ErrorOverlay />}
      {data && (
        <div className="mt-4">
          <Row
            field="monitoring_form_first"
            title="1st week monitoring form"
            status={data.monitoring_form_first?.id ? 'COMPLETE' : 'INCOMPLETE'}
            index={0}
            bookingId={bookingId}
            source={data.monitoring_form_first?.source}
            fileName={data.monitoring_form_first?.file_name}
            fileExtension={data.monitoring_form_first?.file_extension}
            onDelete={handleOpenDeleteModal}
          />
          <Row
            field="monitoring_form_second"
            title="2nd week monitoring form"
            status={data.monitoring_form_second?.id ? 'COMPLETE' : 'INCOMPLETE'}
            index={1}
            bookingId={bookingId}
            source={data.monitoring_form_second?.source}
            fileName={data.monitoring_form_second?.file_name}
            fileExtension={data.monitoring_form_second?.file_extension}
            onDelete={handleOpenDeleteModal}
          />
          <Row
            field="monitoring_form_debrief"
            title="Debrief monitoring form"
            status={
              data.monitoring_form_debrief?.id ? 'COMPLETE' : 'INCOMPLETE'
            }
            index={2}
            bookingId={bookingId}
            source={data.monitoring_form_debrief?.source}
            fileName={data.monitoring_form_debrief?.file_name}
            fileExtension={data.monitoring_form_debrief?.file_extension}
            onDelete={handleOpenDeleteModal}
          />
          <InstantSaveCheckbox
            className="mt-6"
            title="Mark trip experience as positive:"
            name="positive_experience"
            description="(This will allow marketing to contact them for on-trip content)"
            onClick={togglePositiveExperienceChecked}
            checked={data.has_positive_feedback}
          />
          <InstantSaveCheckbox
            className="mt-6"
            title="Mark as contacted by marketing"
            name="positive_experience"
            description=""
            onClick={toggleContactedChecked}
            checked={data.contacted_for_marketing_purposes}
          />
          <MonitoringFormDeleteModal
            isDeleteModalOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            field={deleteData?.field}
            source={deleteData?.source}
            onDelete={handleDeleteMonitoringForm}
          />
        </div>
      )}
    </Card>
  );
};

export default MonitoringForms;
