import { apiRequest } from '../../../api/api';
import { Image } from '../common/types';
import { KeyDetailsData, StaffPhoto } from './types';

export const setStaffProfilePhoto = async (fileId: string, userId: string) => {
  const url = `/api/admin/staff/${userId}/profile-picture/set/`;
  return apiRequest<Image>(url, {
    method: 'POST',
    body: JSON.stringify({ profile_picture: fileId }),
  });
};

export const getStaffPublicFacingPicture = async (userId: string) => {
  return apiRequest<StaffPhoto>(
    `/api/admin/staff/${userId}/public-facing-picture/get/`
  );
};

export const setStaffPublicFacingPicture = async (
  fileId: string,
  userId: string
) => {
  const url = `/api/admin/staff/${userId}/public-facing-picture/set/`;
  return apiRequest<Image>(url, {
    method: 'POST',
    body: JSON.stringify({ public_facing_picture: fileId }),
  });
};

export const updateKeyDetails = async (
  userId: string,
  formValues: KeyDetailsData
) => {
  const url = `/api/admin/staff/${userId}/user-key-details-info/set/`;
  const submissionData = {
    job_title: formValues.job_option.value,
    email: formValues.email,
    work_telephone: formValues.work_telephone,
    destination: formValues.destination ? formValues.destination.value : null,
  };
  return apiRequest<KeyDetailsData>(url, {
    method: 'POST',
    body: JSON.stringify(submissionData),
  });
};
