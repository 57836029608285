import React from 'react';

import { KeyInfoManageSingleObjectData } from './types';
import ManageSingleObject from '../../../../manage-single-object/ManageSingleObject';
import { updateKeyInfo } from './actions';

interface Props {
  destinationId?: string;
}

const KeyInfo: React.FC<Props> = ({ destinationId }) => {
  if (!destinationId) {
    return null;
  }

  return (
    <ManageSingleObject<KeyInfoManageSingleObjectData>
      title="Key Info:"
      getUrl={`/api/admin/destination/destination-profile/${destinationId}/key-info/details/`}
      descriptionFields={[
        {
          label: 'Public name',
          render: (data: KeyInfoManageSingleObjectData) => data.name,
        },
        {
          label: 'Country',
          render: (data: KeyInfoManageSingleObjectData) => data.country.label,
        },
        {
          label: 'Airport name',
          render: (data: KeyInfoManageSingleObjectData) => data.airport,
        },
        {
          label: 'Location',
          render: (data: KeyInfoManageSingleObjectData) => data.location,
        },
        {
          label: 'Wildlife safari is available',
          render: (data: KeyInfoManageSingleObjectData) =>
            data.has_wildlife_safari ? 'Yes' : 'No',
        },
        {
          label: 'Clinical interests call booking link',
          render: (data: KeyInfoManageSingleObjectData) =>
            data.clinical_interests_call_booking_link,
        },
      ]}
      formFields={[
        {
          type: 'smalltext',
          name: 'name',
          label: 'Public name',
        },
        {
          type: 'remote-dropdown',
          name: 'country',
          label: 'Country',
          path: '/api/admin/destination/country-options/list/',
        },
        {
          type: 'smalltext',
          name: 'airport',
          label: 'Airport name',
        },
        {
          type: 'smalltext',
          name: 'location',
          label: 'Location',
        },
        {
          type: 'checkbox',
          name: 'has_wildlife_safari',
          option: {
            value: 'has_wildlife_safari',
            label: 'Has Wildlife Safari?',
          },
        },
        {
          type: 'smalltext',
          name: 'clinical_interests_call_booking_link',
          label: 'Clinical interests call booking link',
        },
      ]}
      submitItem={(values) => updateKeyInfo(destinationId, values)}
    />
  );
};

export default KeyInfo;
