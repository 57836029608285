import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import useModalState from '../../../hooks/modals';
import { Article } from './types';
import ArticleModal from './ArticleModal';

interface Props {
  bookingId: string;
  article: Article;
  sectionTitle: string;
}

const ArticleButton: React.FC<Props> = ({
  bookingId,
  article,
  sectionTitle,
}) => {
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <button
      className={classNames(
        'border-2 rounded-full py-4 px-6 flex flex-col items-center justify-center',
        {
          'border-wtw-logo-blue': !article.has_been_read,
          'border-wtw-green-warning': article.has_been_read,
          'bg-wtw-green-trafficlight': article.has_been_read,
        }
      )}
      onClick={openModal}
    >
      <p>{article.title}</p>
      {article.has_been_read ? (
        <div className="flex items-center">
          <p className="text-xs pr-[6px]">Read</p>
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-wtw-green-warning text-md bg-white rounded-full"
          />
        </div>
      ) : (
        <p className="text-xs">{article.read_time} read time</p>
      )}
      <ArticleModal
        isOpen={isOpen}
        closeModal={closeModal}
        article={article}
        bookingId={bookingId}
        sectionTitle={sectionTitle}
      />
    </button>
  );
};

export default ArticleButton;
