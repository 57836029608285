import React from 'react';

import AdminStandardPage from '../common/AdminStandardPage';
import EmailLogTable from './EmailLogTable';

const EmailLogPage: React.FC = () => {
  return (
    <div>
      <AdminStandardPage
        breadcrumbs={[
          {
            label: 'Reporting',
            href: null,
          },
          {
            label: 'Email Log',
            href: null,
          },
        ]}
        title="Email Log"
        enableHome={false}
      >
        <EmailLogTable />
      </AdminStandardPage>
    </div>
  );
};

export default EmailLogPage;
