import React, { useContext } from 'react';

import { MyBookingsContext } from '../context-providers';
import SelectBooking from './SelectBooking';

interface PageContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  const { bookings, activeBooking, setActiveBooking } =
    useContext(MyBookingsContext);

  const showSelectBooking = bookings && bookings.length > 1 && !activeBooking;

  return (
    <div className="mx-auto w-full md:max-w-[1440px] min-h-[500px] flex flex-col p-4 sm:p-12 relative">
      {showSelectBooking ? (
        <SelectBooking
          bookings={bookings}
          setActiveBooking={setActiveBooking}
        />
      ) : (
        children
      )}
    </div>
  );
};

export default PageContainer;
