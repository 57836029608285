import { apiRequest } from '../../../../api/api';
import {
  CombinedFilters,
  TableData,
} from '../../../../framework-components/table/types';
import {
  HospitalTableData,
  HospitalBasicInfo,
  QuickFacts,
  GeneralHospitalInfoData,
  LocationData,
  PublicDescriptionData,
  CapacitiesAndStaffData,
  DisciplineCapacity,
  ArchiveData,
} from './types';

export const getHospitalTableData = (selectedFilters: CombinedFilters) => {
  return apiRequest<TableData<HospitalTableData>>(
    '/api/admin/hospital/list/?' +
      new URLSearchParams(selectedFilters).toString()
  );
};

export const updateBasicInfo = (id: string, data: HospitalBasicInfo) => {
  return apiRequest<HospitalBasicInfo>(
    `/api/admin/hospital/${id}/basic-info/update/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
};

export const updateQuickFacts = (id: string, data: QuickFacts) => {
  return apiRequest<QuickFacts>(
    `/api/admin/hospital/${id}/quick-facts/update/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
};

export const updateGeneralHospitalInfo = (
  hospitalInfo: GeneralHospitalInfoData,
  hospitalId: string
) => {
  const url = `/api/admin/hospital/${hospitalId}/general-hospital-info/set/`;
  return apiRequest<GeneralHospitalInfoData>(url, {
    method: 'POST',
    body: JSON.stringify(hospitalInfo),
  });
};

export const updatePublicDescription = (
  publicDescription: PublicDescriptionData,
  hospitalId: string
) => {
  const url = `/api/admin/hospital/${hospitalId}/public-description/set/`;
  return apiRequest<PublicDescriptionData>(url, {
    method: 'POST',
    body: JSON.stringify(publicDescription),
  });
};

export const updateLocation = (location: LocationData, hospitalId: string) => {
  const url = `/api/admin/hospital/${hospitalId}/location/set/`;
  return apiRequest<LocationData>(url, {
    method: 'POST',
    body: JSON.stringify(location),
  });
};

export const updateCapacitiesAndStaff = (
  capacitiesAndStaff: CapacitiesAndStaffData,
  hospitalId: string
) => {
  const url = `/api/admin/hospital/${hospitalId}/capacities-and-staff/set/`;
  return apiRequest<CapacitiesAndStaffData>(url, {
    method: 'POST',
    body: JSON.stringify(capacitiesAndStaff),
  });
};

export const addDepartment = (id: string, name: string) => {
  return apiRequest<{ id: string }>(
    `/api/admin/hospital/${id}/add-department/`,
    {
      method: 'POST',
      body: JSON.stringify({
        display_name: name,
      }),
    }
  );
};

export const removeDepartment = (departmentId: string) => {
  return apiRequest<{ id: string }>(
    `/api/admin/hospital/${departmentId}/delete/`,
    {
      method: 'DELETE',
    }
  );
};

export const addDisciplineCapacity = (
  departmentId: string,
  data: DisciplineCapacity
) => {
  return apiRequest<DisciplineCapacity>(
    `/api/admin/hospital/${departmentId}/add-discipline-capacity/`,
    {
      method: 'POST',
      body: JSON.stringify({ ...data, discipline: data.discipline.value }),
    }
  );
};

export const deleteDisciplineCapacity = (data: DisciplineCapacity) => {
  return apiRequest<DisciplineCapacity>(
    `/api/admin/hospital/discipline-capacity/${data.id}/delete/`,
    {
      method: 'DELETE',
    }
  );
};

export const updateDisciplineCapacity = (data: DisciplineCapacity) => {
  return apiRequest<DisciplineCapacity>(
    `/api/admin/hospital/discipline-capacity/${data.id}/update/`,
    {
      method: 'POST',
      body: JSON.stringify({ ...data, discipline: data.discipline.value }),
    }
  );
};

export const archiveHospital = (hospitalId: string, values: ArchiveData) => {
  const url = `/api/admin/hospital/${hospitalId}/archive/`;
  return apiRequest<void>(url, {
    method: 'POST',
    body: JSON.stringify(values),
  });
};
