import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { useAPI } from '../../../../api/api';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';

interface PriceItem {
  num_weeks: number;
  price: number;
}

interface PriceDetailsData {
  week_prices: PriceItem[];
  safari_price: number | null;
  village_price: number | null;
  currency_symbol: string;
}

interface RowProps {
  index: number;
  title: string;
  value: ReactNode;
}

const Row: React.FC<RowProps> = ({ index, title, value }) => {
  return (
    <div
      className={classNames('p-4 flex items-center justify-between text-xs', {
        'bg-wtw-gray-1': index % 2 === 0,
        'bg-wtw-gray-3': index % 2 === 1,
      })}
    >
      <p>{title}</p>
      <p>{value}</p>
    </div>
  );
};

interface Props {
  bookingId: string;
}

const BookingPrices: React.FC<Props> = ({ bookingId }) => {
  const { data, isValidating, error } = useAPI<PriceDetailsData>(
    `/api/admin/bookings/${bookingId}/price-details/`
  );

  return (
    <AdminWidgetContainer title="Booking Prices">
      {isValidating && <LoadingOverlay />}
      {error && !isValidating && <ErrorOverlay />}

      <p className="mt-4 text-sm">
        These are the prices that will be used when making changes to this
        booking.
      </p>

      {data && (
        <div className="mt-2">
          {data.week_prices.map((priceItem, index) => (
            <Row
              key={`week-price-${index}`}
              index={index}
              title={`Weekly price (${priceItem.num_weeks} week${
                priceItem.num_weeks > 1 ? 's' : ''
              })`}
              value={`${data.currency_symbol}${(priceItem.price / 100).toFixed(
                2
              )}`}
            />
          ))}

          <Row
            index={data.week_prices.length}
            title="Safari price"
            value={
              data.safari_price !== null
                ? `${data.currency_symbol}${data.safari_price.toFixed(2)}`
                : 'N/A'
            }
          />

          <Row
            index={data.week_prices.length + 1}
            title="Village experience price"
            value={
              data.village_price !== null
                ? `${data.currency_symbol}${data.village_price.toFixed(2)}`
                : 'N/A'
            }
          />
        </div>
      )}
    </AdminWidgetContainer>
  );
};

export default BookingPrices;
