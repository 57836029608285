import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { mutate } from 'swr';

import AdminStandardPage from '../../../common/AdminStandardPage';
import BlockTabs from '../../../../../framework-components/blocktabs/BlockTabs';
import { BookingCount, PostponedBookingTableData } from '../types';
import { apiRequest, useAPI } from '../../../../../api/api';
import Table from '../../../../../framework-components/table/Table';
import { formatDateToDayDateMonthYear } from '../../../../../utils/dates';
import {
  CombinedFilters,
  PartialFilterOptions,
  TableData,
} from '../../../../../framework-components/table/types';
import { SecondaryButton } from '../../../../../framework-components/button/Button';
import ArchiveIcon from '../../../../icons/ArchiveIcon';
import {
  unArchiveBooking,
  unPostponeBooking,
} from '../../applicant-profile/actions';
import { truncate } from '../../../../../utils/text';
import { BOOKING_STATUS_LABELS } from '../../../../../constants';

const getPostponedBookingTableData = (selectedFilters: CombinedFilters) => {
  return apiRequest<TableData<PostponedBookingTableData>>(
    '/api/admin/bookings/postponed-booking-list/get/?' +
      new URLSearchParams(selectedFilters).toString()
  ).then((response) => {
    return response;
  });
};

const getFilterOptions = () => {
  return new Promise<PartialFilterOptions<PostponedBookingTableData>>(
    (resolve) => {
      resolve({
        status: [
          { value: 'POSTPONED', label: BOOKING_STATUS_LABELS.POSTPONED },
          { value: 'ARCHIVED', label: BOOKING_STATUS_LABELS.ARCHIVED },
          { value: 'CANCELLED', label: BOOKING_STATUS_LABELS.CANCELLED },
        ],
      });
    }
  );
};

const PostponedBookings: React.FC = () => {
  const { data: count } = useAPI<BookingCount>('/api/admin/bookings/count/');

  const tableRef = useRef<{ reloadData: () => void }>(null);

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.reloadData();
    }
  };

  const handleUnPostpone = async (booking: PostponedBookingTableData) => {
    try {
      if (booking.is_postponed) {
        await unPostponeBooking(booking.id);
      }
      if (!booking.is_active) {
        await unArchiveBooking(booking.id);
      }
      toast.success('Booking reactivated successfully');
      reloadData();
      mutate('/api/admin/bookings/count/');
    } catch (error) {
      toast.error('Failed to reactivate booking');
    }
  };

  return (
    <AdminStandardPage
      breadcrumbs={[
        {
          label: 'Bookings',
          href: '/admin/bookings/pending',
        },
        {
          label: 'Postponed & archived',
          href: null,
        },
      ]}
      title="Postponed & archived"
      enableHome={false}
    >
      <div className="mt-4">
        <BlockTabs
          tabs={[
            {
              label: `Pending ${count ? `(${count.pending})` : ''}`,
              href: '/admin/bookings/pending',
              current: false,
            },
            {
              label: `Confirmed ${count ? `(${count.confirmed})` : ''}`,
              href: '/admin/bookings/confirmed',
              current: false,
            },
            {
              label: `On Trip ${count ? `(${count.on_trip})` : ''}`,
              href: '/admin/bookings/on-trip',
              current: false,
            },
            {
              label: `Postponed & archived ${
                count ? `(${count.postponed_and_archived})` : ''
              }`,
              href: '/admin/bookings/postponed',
              current: true,
            },
          ]}
          variant="pilled_tab"
        />

        <Table<PostponedBookingTableData>
          ref={tableRef}
          columnsConfig={{
            applicant: {
              key: 'applicant',
              header: 'Name',
              enableSort: true,
              renderCell: (booking) => (
                <a
                  href={`/admin/bookings/${booking.id}/applicant-profile/`}
                  title={booking.id}
                >
                  {`${booking.applicant.first_name} ${booking.applicant.last_name}`}
                </a>
              ),
            },
            status: {
              key: 'status',
              header: 'Status',
              enableSort: true,
              renderCell: (booking) => {
                return BOOKING_STATUS_LABELS[booking.status];
              },
            },
            university: {
              key: 'university',
              header: 'University',
              enableSort: true,
              renderCell: (booking) => (
                <div className="max-h-[60px]">
                  <a
                    href={`/admin/directories/universities/${booking.applicant.university.id}/`}
                    title={booking.id}
                  >
                    {booking.applicant.university.name.length > 43
                      ? truncate(booking.applicant.university.name, 43)
                      : booking.applicant.university.name}
                  </a>
                  {booking.applicant.university.created_by_student ||
                    (booking.applicant.university.is_ignored && (
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faExclamationTriangle}
                        style={{ color: '#ff0000' }}
                      />
                    ))}
                </div>
              ),
            },
            destination: {
              key: 'destination',
              header: 'Destination',
              enableSort: true,
              renderCell: (booking) => (
                <a
                  href={`/admin/directories/destinations?destination_id=${booking.destination.id}`}
                  title={booking.destination.id}
                >
                  {booking.destination.name}
                </a>
              ),
            },
            contact_date: {
              key: 'start_date',
              header: 'Placement date',
              enableSort: true,
              renderCell: (booking) => {
                if (booking.start_date)
                  return formatDateToDayDateMonthYear(booking.start_date);
                return '-';
              },
            },
            re_open: {
              key: 're_open',
              header: 'Re-open',
              enableSort: false,
              renderCell: (booking) => {
                return (
                  <SecondaryButton
                    isDisabled={!booking.is_postponed && booking.is_active}
                    onClick={() => handleUnPostpone(booking)}
                  >
                    {<ArchiveIcon />}
                  </SecondaryButton>
                );
              },
            },
          }}
          getData={getPostponedBookingTableData}
          getFilterOptions={getFilterOptions}
          enablePagination
          enableSearch
          additionalFiltersConfig={{
            is_postponed: {
              key: 'status',
              label: 'Status',
              filterType: 'local-dropdown',
            },
          }}
        />
      </div>
    </AdminStandardPage>
  );
};

export default PostponedBookings;
