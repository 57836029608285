import React from 'react';

import ManageMultipleObjects from '../../../manage-multiple-objects/ManageMultipleObjects';
import { UniversityStaffData } from './types';
import { TableField } from '../../../manage-multiple-objects/types';
import { FieldType } from '../../../../framework-components/quickform/Field';
import {
  addUniversityStaff,
  removeUniversityStaff,
  updateUniversityStaff,
} from './actions';

interface Props {
  universityId?: string;
}

const tableFields: ReadonlyArray<TableField<UniversityStaffData>> = [
  {
    name: 'name',
    header: 'Name',
    render: (data: UniversityStaffData) =>
      `${data.first_name} ${data.last_name}`,
  },
  {
    name: 'department',
    header: 'Department',
    render: (data: UniversityStaffData) => data.university_department?.label,
  },
  {
    name: 'position',
    header: 'Position',
    render: (data: UniversityStaffData) => data.position,
  },
  {
    name: 'contact_details',
    header: 'Contact Details',
    render: (data: UniversityStaffData) => (
      <>
        <p>Tel: {data.telephone}</p>
        <p>Mob: {data.mobile}</p>
        <a href={`mailto:${data.email}`}>{data.email}</a>
      </>
    ),
  },
];

const UniversityStaff: React.FC<Props> = ({ universityId }) => {
  if (!universityId) {
    return null;
  }

  const quickFormFields: ReadonlyArray<FieldType> = [
    {
      type: 'smalltext',
      name: 'first_name',
      label: 'First Name',
    },
    {
      type: 'smalltext',
      name: 'last_name',
      label: 'Last Name',
    },
    {
      type: 'smalltext',
      name: 'position',
      label: 'Position',
    },
    {
      type: 'remote-dropdown',
      name: 'university_department',
      label: 'Department',
      path: `/api/admin/university/${universityId}/get-departments/`,
    },
    {
      type: 'largetext',
      name: 'address',
      label: 'Address',
    },
    {
      type: 'smalltext',
      name: 'telephone',
      label: 'Telephone',
    },
    {
      type: 'smalltext',
      name: 'mobile',
      label: 'Mobile',
    },
    {
      type: 'smalltext',
      name: 'email',
      label: 'Email',
    },
  ];

  const getUniversityStaffDetailsURL = `/api/admin/university/${universityId}/university-profile/staff/details/`;

  return (
    <ManageMultipleObjects<UniversityStaffData>
      title="University Staff:"
      tableFields={tableFields}
      quickFormFields={quickFormFields}
      getUrl={getUniversityStaffDetailsURL}
      addHandler={(values) => addUniversityStaff(universityId, values)}
      editHandler={(values) => updateUniversityStaff(universityId, values)}
      deleteHandler={(values) => removeUniversityStaff(universityId, values)}
      noDataFoundMessage="There are currently no university staff for this destination."
      isFixedTableLayout
    />
  );
};

export default UniversityStaff;
