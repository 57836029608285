import { Option } from '../../../types';

export const CURRENCY = {
  GBP: 'GBP' as const,
  USD: 'USD' as const,
  EUR: 'EUR' as const,
  AUD: 'AUD' as const,
  CAD: 'CAD' as const,
};

export const EXPERIENCE = {
  VILLAGE: 'VILLAGE' as const,
  AYURVEDA: 'AYURVEDA' as const,
};

export const EDUCATION_STATUS = {
  STUDENT: 'STUDENT' as const,
  PROFESSIONAL: 'PROFESSIONAL' as const,
};

export const GENDER = {
  MALE: 'MALE' as const,
  FEMALE: 'FEMALE' as const,
  OTHER: 'OTHER' as const,
};

export const PAYMENT_METHOD = {
  BANK_TRANSFER: 'BANK_TRANSFER' as const,
  CARD: 'CARD' as const,
};

export const PAYMENT_PROCESSOR = {
  STRIPE: 'STRIPE' as const,
  PAYPAL: 'PAYPAL' as const,
};

export const SECTIONS = {
  YOUR_DETAILS: 'YOUR_DETAILS' as const,
  REVIEW: 'REVIEW' as const,
  SUMMARY: 'SUMMARY' as const,
};

export const REGION = {
  en: 'en' as const,
  eu: 'eu' as const,
  us: 'us' as const,
  au: 'au' as const,
  ca: 'ca' as const,
};

export const MAX_YEARS_OF_GRADUATION = 50;

export const getYearOfGraduationOptions = () => {
  const yearOfGraduationOptions: Array<Option<number>> = [];
  const currentYear = new Date().getFullYear();
  for (let i = 0; i < MAX_YEARS_OF_GRADUATION; i++) {
    yearOfGraduationOptions.push({
      value: currentYear - i,
      label: (currentYear - i).toString(),
    });
  }
  return yearOfGraduationOptions;
};

export const yearOfStudyOptions: Array<Option<number>> = [
  { value: 1, label: '1st' },
  { value: 2, label: '2nd' },
  { value: 3, label: '3rd' },
  { value: 4, label: '4th' },
  { value: 5, label: '5th' },
  { value: 6, label: '6th' },
  { value: 7, label: '7th' },
];

export const REGISTRATION_FEE = {
  [CURRENCY.GBP]: 300,
  [CURRENCY.USD]: 300,
  [CURRENCY.EUR]: 300,
  [CURRENCY.AUD]: 300,
  [CURRENCY.CAD]: 300,
};

export const SAFARI_PRICE = {
  [CURRENCY.GBP]: 600,
  [CURRENCY.USD]: 600,
  [CURRENCY.EUR]: 600,
  [CURRENCY.AUD]: 600,
  [CURRENCY.CAD]: 600,
};

export const CURRENCY_TO_SYMBOL = {
  [CURRENCY.GBP]: '£',
  [CURRENCY.USD]: '$',
  [CURRENCY.EUR]: '€',
  [CURRENCY.AUD]: 'A$',
  [CURRENCY.CAD]: 'C$',
};

export const CURRENCY_TO_PAYMENT_PROCESSOR = {
  [CURRENCY.GBP]: PAYMENT_PROCESSOR.STRIPE,
  [CURRENCY.USD]: PAYMENT_PROCESSOR.STRIPE,
  [CURRENCY.EUR]: PAYMENT_PROCESSOR.PAYPAL,
  [CURRENCY.AUD]: PAYMENT_PROCESSOR.PAYPAL,
  [CURRENCY.CAD]: PAYMENT_PROCESSOR.PAYPAL,
};

export const REGION_TO_CURRENCY = {
  [REGION.en]: CURRENCY.GBP,
  [REGION.us]: CURRENCY.USD,
  [REGION.eu]: CURRENCY.EUR,
  [REGION.au]: CURRENCY.AUD,
  [REGION.ca]: CURRENCY.CAD,
};

export const CURRENCY_TO_REGION = {
  [CURRENCY.GBP]: 'en',
  [CURRENCY.USD]: 'us',
  [CURRENCY.EUR]: 'eu',
  [CURRENCY.AUD]: 'au',
  [CURRENCY.CAD]: 'ca',
};
