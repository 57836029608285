import React from 'react';
import { toast } from 'react-toastify';

import Modal from '../../modal';
import QuickForm from '../../../framework-components/quickform/QuickForm';
import { apiRequest } from '../../../api/api';

interface PaymentData {
  amount: number;
  redirect_to: string;
}

interface Props {
  bookingId: string;
  isOpen: boolean;
  closeModal: () => void;
}

const StripeModal: React.FC<Props> = ({ bookingId, isOpen, closeModal }) => {
  const onSubmit = (data: PaymentData): Promise<PaymentData> => {
    const url = `/api/stripe/checkout-session/payment/${bookingId}/`;
    return apiRequest<PaymentData>(url, {
      method: 'POST',
      body: JSON.stringify({ ...data, amount: data.amount * 100 }),
    });
  };

  const onSubmitSuccess = (response: PaymentData) => {
    if (response && response.redirect_to) {
      window.location.href = response.redirect_to;
    } else {
      toast.error('Could not redirect to the checkout page. Please try again.');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className="flex flex-col gap-4">
        <h1>Enter your payment amount</h1>
        <QuickForm
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
          initialValues={{ amount: 0, redirect_to: '' }}
          fields={[
            {
              type: 'smalltext',
              name: 'amount',
              label: 'Amount:',
            },
          ]}
          buttonLabel="Pay with card"
        />
      </div>
    </Modal>
  );
};

export default StripeModal;
