import React from 'react';

import { useAPI } from '../../../../api/api';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import { PrimaryButton } from '../../../../framework-components/button/Button';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';
import Table from '../../../manage-multiple-objects/Table';

interface Props {
  id: string;
  name: string;
}

interface Discipline {
  name: string;
  num_enquiries: number;
}

interface EnquiryCounts {
  total_enquiries: number;
  disciplines: Array<Discipline>;
}

const UniversityEnquiryTable: React.FC<Props> = ({ id, name }) => {
  const { data, isValidating, error } = useAPI<EnquiryCounts>(
    `/api/admin/university/${id}/get-enquiry-counts/`
  );
  const filteredUni = JSON.stringify({
    university: { label: name, value: id },
  });
  const encodedUni = encodeURIComponent(filteredUni);
  return (
    <div className="relative min-h-[250px]">
      {isValidating && <LoadingOverlay />}
      {error && <ErrorOverlay />}
      <AdminWidgetContainer title="Enquiries" hasBorder hasShadow>
        <div className="space-y-6">
          <p className="mt-6">
            This is all active enquiries from this university.
          </p>
          <div className="flex items-center justify-between flex-wrap gap-4">
            <p>
              Total number of enquiries:{' '}
              {data && <strong>{data.total_enquiries}</strong>}
            </p>

            <a href={`/admin/enquiries/ongoing?filters=${encodedUni}`}>
              <PrimaryButton label="View all enquiries" />
            </a>
          </div>
          {data && (
            <Table
              data={data.disciplines}
              tableFields={[
                {
                  name: 'department',
                  header: 'Department',
                  render: (item: Discipline) => item['name'],
                },
                {
                  name: 'num_bookings',
                  header: 'Number of Enquiries',
                  render: (item: Discipline) => item['num_enquiries'],
                },
              ]}
            />
          )}
        </div>
      </AdminWidgetContainer>
    </div>
  );
};

export default UniversityEnquiryTable;
