import React from 'react';

import { Experience, CurrencyType } from '../types';
import { Uuid } from '../../../../types';
import PaymentDue from './PaymentDue';
import TripCalculator from './TripCalculator';

const Basket: React.FC<{
  currency: CurrencyType;
  destination: { label: string; value: Uuid } | null;
  discipline: string | undefined | null;
  duration: number | null;
  experience: Experience | null;
  start_date: string | null;
}> = (props) => {
  return (
    <div className="h-content xs:min-w-fit pb-8 pt-9 lg:pb-0">
      <TripCalculator {...props} />
      <PaymentDue currency={props.currency} />
    </div>
  );
};

export default Basket;
