import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';

import SendReminderModal from './SendReminderModal';
import useModalState from '../../../../hooks/modals';

interface Props {
  bookingId: string;
  last_sent: string | null;
}

const SendReminder: React.FC<Props> = ({ last_sent, bookingId }) => {
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <div className="flex items-center">
      <button onClick={openModal} className="text-blue-600 text-semibold">
        <FontAwesomeIcon icon={faPaperPlane} />
      </button>
      <SendReminderModal
        isOpen={isOpen}
        closeModal={closeModal}
        bookingId={bookingId}
        lastSent={last_sent}
      />
    </div>
  );
};

export default SendReminder;
