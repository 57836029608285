import React from 'react';
import { FormikValues } from 'formik';
import { mutate } from 'swr';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { useAPI } from '../../../../api/api';
import ErrorOverlay from '../ErrorOverlay';
import LoadingOverlay from '../LoadingOverlay';
import QuickForm from '../QuickForm/QuickForm';
import { FieldType } from '../QuickForm/Field';
import AdminWidgetContainer from '../../../../framework-components/card/AdminWidgetContainer';

interface ConditionalField<T> {
  name: string;
  condition: (data: T) => boolean;
}

interface Props<T> {
  title: string | React.ReactNode;
  titleClassName?: string;
  subtitle?: string | React.ReactNode;
  getUrl: string | null;
  submitItem: (data: T) => Promise<T>;
  onSubmitSuccess?: (data: T) => void;
  formFields: ReadonlyArray<FieldType>;
  insideCard?: boolean;
  buttonLabel?: string;
  conditionalFields?: ReadonlyArray<ConditionalField<T>>;
  hideButtonOnClean?: boolean;
}

const ManageSingleObject = <T extends FormikValues>({
  title,
  titleClassName = 'text-sh2',
  subtitle,
  getUrl,
  submitItem,
  onSubmitSuccess,
  formFields,
  insideCard = true,
  buttonLabel,
  conditionalFields,
}: Props<T>) => {
  const { data, isValidating, error } = useAPI<T>(getUrl);

  const onSubmitSuccessHandler = (values: T) => {
    mutate(getUrl, values, false);
    if (onSubmitSuccess) {
      onSubmitSuccess(values);
    }
    toast.success('Changes saved successfully');
  };

  const fieldsToShow =
    conditionalFields && data
      ? formFields.filter((field) => {
          const condition = conditionalFields.find(
            (cf) => cf.name === field.name
          );
          return condition ? condition.condition(data) : true;
        })
      : formFields;

  const render = () => {
    return (
      <div
        className={classNames('w-full h-fit py-2 space-y-6', {
          'relative grid': !insideCard,
        })}
      >
        {error && !isValidating && <ErrorOverlay />}
        {isValidating && <LoadingOverlay />}
        <h4 className={titleClassName}>{title}</h4>
        <p>{subtitle}</p>
        {data && (
          <QuickForm
            initialValues={data}
            fields={fieldsToShow}
            onSubmit={submitItem}
            onSubmitSuccess={onSubmitSuccessHandler}
            buttonLabel={buttonLabel}
            hideButtonOnClean
          />
        )}
      </div>
    );
  };

  return (
    <>
      {insideCard ? (
        <AdminWidgetContainer hasBorder hasShadow>
          {render()}
        </AdminWidgetContainer>
      ) : (
        render()
      )}
    </>
  );
};

export default ManageSingleObject;
