import React from 'react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import { Bookings } from '../../context-providers';
import Modal from '../../../modal';
import { Addon } from './types';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  addon: Addon;
  bookingId: string;
  price: string;
}

const AddModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  addon,
  bookingId,
  price,
}) => {
  const addHandler = async () => {
    try {
      await addon.addToBooking(bookingId, { wildlife_safari_added: true });
      toast.success('Safari added to booking');
      mutate(
        '/api/my-trip/booking/list/',
        (data: Bookings) =>
          data.map((booking) => {
            if (booking.id === bookingId) {
              return {
                ...booking,
                wildlife_safari_added: true,
              };
            }
            return booking;
          }),
        false
      );
      mutate(`api/my-trip/my-finances/${bookingId}/get-finances-to-date/`);
      closeModal();
    } catch {
      toast.error('Failed to add safari to booking');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      onConfirm={addHandler}
      confirmLabel="Add to Booking"
    >
      <p>
        Great! Safari selected. Confirm you'd like to add this to your booking
        for {price}
      </p>
    </Modal>
  );
};

export default AddModal;
