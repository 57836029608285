import React, { useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../framework-components/button/Button';
import { DepartmentAndSupervisorData } from '../types';
import Input from '../../../../../framework-components/input/Input';
import TextArea from '../../../../../framework-components/textarea/TextArea';
import { useSubmitFormToAPI } from '../../../../../api/forms';

interface FormValues {
  primary_contact: string;
  capacity: number;
  description_html: string;
}

interface Props {
  hospitalId: string;
  setShowForm: (showForm: boolean) => void;
  department: DepartmentAndSupervisorData;
}

function useOnSubmit(
  hospitalId: string,
  departmentId: string,
  setShowForm: (showForm: boolean) => void
): (
  data: FormValues,
  formHelpers: Pick<
    FormikHelpers<FormValues>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI(
    `/api/admin/hospital/${departmentId}/update/`
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        toast.success('Department updated successfully');
        setShowForm(false);
        mutate(
          `/api/admin/hospital/${hospitalId}/departments-and-supervisors/get/`,
          (departments: Array<DepartmentAndSupervisorData>) => {
            return departments.map((department) => {
              if (department.id === departmentId) {
                return {
                  ...department,
                  supervisor: data.primary_contact,
                  capacity: data.capacity,
                  description: data.description_html,
                };
              }
              return department;
            });
          },
          false
        );
      }
      if (!hasSucceeded) {
        toast.error('Failed to update department');
      }
    },
    [submitForm, setShowForm, hospitalId, departmentId]
  );
}

const EditDepartmentForm: React.FC<Props> = ({
  hospitalId,
  setShowForm,
  department,
}) => {
  const initialValues: FormValues = {
    primary_contact: department.supervisor,
    capacity: department.capacity,
    description_html: department.description,
  };

  const onSubmit = useOnSubmit(hospitalId, department.id, setShowForm);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <div className="space-y-4">
            <div className="flex flex-col gap-4">
              <Input
                name="display_name"
                label="Name"
                value={department.name}
                onChange={(event) =>
                  setFieldValue('display_name', event.target.value)
                }
                disabled
              />
              <Input
                name="primary_contact"
                label="Main supervisor"
                value={values.primary_contact}
                onChange={(event) =>
                  setFieldValue('primary_contact', event.target.value)
                }
              />
              <Input
                name="capacity"
                label="Capacity"
                type="number"
                value={values.capacity}
                onChange={(event) =>
                  setFieldValue('capacity', event.target.value)
                }
              />
              <TextArea
                name="description_html"
                label="Description"
                value={values.description_html}
                onChange={(event) =>
                  setFieldValue('description_html', event.target.value)
                }
                rows={5}
              />
            </div>
            <div className="flex justify-end gap-4">
              <SecondaryButton onClick={() => setShowForm(false)}>
                Cancel
              </SecondaryButton>
              <PrimaryButton type="submit" isDisabled={isSubmitting}>
                Submit
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditDepartmentForm;
