import React from 'react';

interface Props {
  leftColumn: React.ReactElement;
  middleColumn: React.ReactElement;
  rightColumn: React.ReactElement;
}

const ThreeColumns: React.FC<Props> = (props: Props) => {
  return (
    <div className="flex flex-col lg:flex-row gap-6 lg:gap-9 items-stretch">
      <div className="w-full lg:w-1/3 space-y-6">{props.leftColumn}</div>
      <div className="w-full lg:w-1/3 space-y-6">{props.middleColumn}</div>
      <div className="w-full lg:w-1/3 space-y-6">{props.rightColumn}</div>
    </div>
  );
};

export default ThreeColumns;
