import React, { useState } from 'react';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
}

const AdaptiveText: React.FC<Props> = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div
        className={classNames({
          'h-[160px] overflow-y-hidden': !isExpanded,
          'h-auto overflow-y-visible': isExpanded,
        })}
      >
        {props.children}
      </div>
      <button
        onClick={toggleExpanded}
        className="mt-4 text-md-bold text-blue-600"
      >
        See {isExpanded ? 'less' : 'more'}
      </button>
    </div>
  );
};

export default AdaptiveText;
