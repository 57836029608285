import classNames from 'classnames';
import React from 'react';
import { useParams } from 'react-router';
import { mutate } from 'swr';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { GetProfileData, UpdateBasicInfoFormData } from './types';
import { useAPI } from '../../../../api/api';
import LoadingOverlay from '../../../../framework-components/loading/LoadingOverlay';
import ErrorOverlay from '../../common/error/ErrorOverlay';
import CopyToClipboardButton from '../../../CopyClipboardButton';
import ThreeColumns from '../../common/layout/ThreeColumns';
import CardDetail from '../../../../framework-components/card/CardDetail';
import EditDetailsModal from '../../../manage-single-object/EditDetailsModal';
import useModalState from '../../../../hooks/modals';
import { FieldType } from '../../../../framework-components/quickform/Field';
import { PencilEditButton } from '../../../../framework-components/button/PencilEditButton';
import { updateApplicantBasicInfo } from './actions';
import {
  GENDER,
  yearOfStudyOptions,
  getYearOfGraduationOptions,
} from '../../../booking/booking-form/constants';
import { BOOKING_STATUS_LABELS } from '../../../../constants';
import { GENDER_TO_DISPLAY_NAME } from '../../../booking/booking-form/labels';
import { GenderType } from '../../../booking/booking-form/types';
import { formatDateToDayDateMonthYear } from '../../../../utils/dates';
import { getTripStatus } from '../utils';

const yearOfGraduationOptions = getYearOfGraduationOptions();

interface Props {
  canEdit: boolean;
}

const ApplicantContactBox: React.FC<Props> = ({ canEdit }) => {
  const params = useParams();
  const { bookingId } = params;
  const url = `/api/admin/bookings/${bookingId}/profile-page-contact-info/`;

  const { data, error, isValidating } = useAPI<GetProfileData>(url);
  const { isOpen, openModal, closeModal } = useModalState();

  const applicantName = (function () {
    if (!data) {
      return '';
    }
    return `${data.first_name} ${data.last_name}`;
  })();

  const mobileCopyText = (
    <CopyToClipboardButton textToCopy={data ? data.phone : ''} />
  );
  const mobileEmailText = (
    <CopyToClipboardButton textToCopy={data ? data.email : ''} />
  );
  const applicantNameCopyText = (
    <CopyToClipboardButton
      textToCopy={data ? `${data.first_name} ${data.last_name}` : ''}
    />
  );
  const applicantReferenceCopyText = (
    <CopyToClipboardButton textToCopy={data ? data.reference : ''} />
  );

  if (!data && (isValidating || error)) {
    return (
      <div className="p-4 space-y-8">
        <>
          {isValidating && <LoadingOverlay />}
          {error && (
            <ErrorOverlay
              detail={error ? 'Failed to load page data' : undefined}
            />
          )}
        </>
      </div>
    );
  } else if (!data) {
    return null;
  }

  const onSubmitSuccessHandler = () => {
    mutate(url);
  };

  const fields: Array<FieldType> = [
    {
      type: 'smalltext',
      name: 'first_name',
      label: 'First name',
    },
    {
      type: 'smalltext',
      name: 'last_name',
      label: 'Last name',
    },
    {
      type: 'local-dropdown',
      name: 'gender',
      label: 'Gender',
      options: [
        {
          value: GENDER.MALE,
          label: GENDER_TO_DISPLAY_NAME[GENDER.MALE],
        },
        {
          value: GENDER.FEMALE,
          label: GENDER_TO_DISPLAY_NAME[GENDER.FEMALE],
        },
      ],
    },
    {
      type: 'remote-dropdown',
      name: 'nationality',
      label: 'Nationality',
      path: '/api/booking/nationalities/',
    },
    {
      type: 'smalltext',
      name: 'phone',
      label: 'Mobile',
    },
    {
      type: 'smalltext',
      name: 'email',
      label: 'Email',
    },
    { type: 'smalltext', name: 'local_phone', label: 'Local Phone' },
    {
      type: 'remote-dropdown',
      name: 'university',
      label: 'University',
      path: '/api/booking/universities/',
    },
    {
      type: 'smalltext',
      name: 'university_course',
      label: 'Course',
    },
  ];

  const labels: Record<string, string> = {
    name: 'Name',
    nationality: 'Nationality',
    mobile: 'Mobile',
    email: 'Email',
    university: 'University',
    university_course: 'University course',
    local_phone: 'Local Phone',
  };

  const initialValues: UpdateBasicInfoFormData = {
    first_name: data.first_name,
    last_name: data.last_name,
    gender: data.gender,
    nationality: data.nationality
      ? { label: data.nationality.name, value: data.nationality.id }
      : null,
    phone: data.phone,
    email: data.email,
    local_phone: data.local_phone,
    university: { label: data.university.name, value: data.university.id },
    university_course: data.university_course,
  };

  const tripDetailsData: Record<string, string | React.ReactNode> = {
    Destination: data.destination ? data.destination : 'Destination unknown',
    Discipline: data.university_course
      ? data.university_course
      : 'Discipline unknown',
    Dates:
      data.start_date && data.end_date
        ? `${formatDateToDayDateMonthYear(
            data.start_date
          )} to ${formatDateToDayDateMonthYear(data.end_date)}`
        : 'Dates unknown',
    Status:
      data.start_date && data.end_date
        ? getTripStatus(data.start_date, data.end_date)
        : 'Status unknown',
  };

  const universityData: Record<string, string | React.ReactNode> = {
    University: (
      <Link
        to={`/admin/directories/universities/${data.university.id}`}
        className="text-wtw-logo-blue hover:text-wtw-dark-blue"
      >
        {data.university.name}
        {data.university.created_by_student ? (
          <span className="ml-2">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className={classNames({ 'text-red-500': true })}
            />
          </span>
        ) : null}
      </Link>
    ),
    Course: data.university_course,
  };
  if (data.year_of_university_study) {
    universityData['Year of Study'] = data.year_of_university_study.toString();
    initialValues.year_of_university_study = yearOfStudyOptions.find(
      (options) => options.value === data.year_of_university_study
    );
    fields.push({
      type: 'local-dropdown',
      name: 'year_of_university_study',
      label: 'Year of Study',
      options: yearOfStudyOptions,
    });
    labels.year_of_university_study = 'Year of study';
  }
  if (data.year_of_graduation) {
    universityData['Year of Graduation'] = data.year_of_graduation.toString();
    initialValues.year_of_graduation = yearOfStudyOptions.find(
      (options) => options.value === data.year_of_graduation
    );
    fields.push({
      type: 'local-dropdown',
      name: 'year_of_graduation',
      label: 'Year of Graduation',
      options: yearOfGraduationOptions,
    });
    labels.year_of_graduation = 'Year of graduation';
  }

  const getImageSource = (id_photo_url: string | null, gender: GenderType) => {
    if (id_photo_url) {
      return id_photo_url;
    } else if (gender === 'MALE') {
      return '/static/unknown_M.gif';
    } else if (gender === 'FEMALE') {
      return '/static/unknown_F.gif';
    }
    return '/static/unknown_N.gif';
  };

  const imageSource = getImageSource(data.id_photo_url, data.gender.value);

  return (
    <div className="space-y-4">
      <ThreeColumns
        leftColumn={
          <CardDetail
            data={{
              Name: (
                <>
                  {applicantName} {applicantNameCopyText}
                </>
              ),
              Gender: data.gender.label,
              Reference: (
                <>
                  {data.reference} {applicantReferenceCopyText}
                </>
              ),
              Status: BOOKING_STATUS_LABELS[data.status],
            }}
            imageSource={imageSource}
            imageAlt="Profile"
            labelWidth="w-24"
            title="Student:"
          />
        }
        middleColumn={
          <CardDetail
            data={{
              Nationality: data.nationality ? data.nationality.name : '',
              Mobile: (
                <>
                  {data.phone} {mobileCopyText}
                </>
              ),
              Email: (
                <>
                  <a
                    href={`mailto:${data.email}`}
                    className="hover:text-wtw-logo-blue"
                  >
                    {data.email}
                  </a>{' '}
                  {mobileEmailText}
                </>
              ),
              'Local Phone': <> {data.local_phone} </>,
            }}
            labelWidth="w-24"
            title="Details:"
          />
        }
        rightColumn={
          <CardDetail
            data={tripDetailsData}
            labelWidth="w-24"
            title="Trip Details:"
          />
        }
      />
      {canEdit && (
        <div className="w-full flex justify-end">
          <PencilEditButton onClick={openModal} />
        </div>
      )}
      {bookingId && (
        <EditDetailsModal<UpdateBasicInfoFormData>
          isOpen={isOpen}
          closeModal={closeModal}
          submitItem={(values) => updateApplicantBasicInfo(bookingId, values)}
          onSubmitSuccess={onSubmitSuccessHandler}
          data={initialValues}
          fields={fields}
        />
      )}
    </div>
  );
};

export default ApplicantContactBox;
