import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Field,
  FieldInputProps,
  Formik,
  FormikHelpers,
  Form,
  FastField,
  FormikProps,
} from 'formik';

import { useAPI } from '../../../../api/api';
import { useSubmitFormToAPI } from '../../../../api/forms';
import { FormLabel } from '../../../booking/booking-form/FormLabel';
import ErrorText from '../../../error-text';
import LocalDropdown from '../../../../framework-components/dropdown/LocalDropdown';
import Input from '../../../../framework-components/input/Input';
import LoadingOverlay from '../../../../framework-components/loading/Loading';
import Checkbox from '../../../../framework-components/select/Checkbox';
import { Option } from '../../../../types';
import { getFormikErrors } from '../../../../utils/forms';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../framework-components/button/Button';
import UploadModal from '../../common/UploadModal';

interface AddNewDocumentFormProps {
  onClose: () => void;
  reloadData: () => void;
}

interface FormData {
  document_id: string;
  section: string;
  category: string;
  name: string;
  description: string;
  destinations: ReadonlyArray<string>;
  available_to_all_destinations: boolean;
}

export function useOnSubmit(
  onClose: () => void,
  reloadData: () => void
): (
  data: FormData,
  formHelpers: Pick<
    FormikHelpers<FormData>,
    'setSubmitting' | 'setErrors' | 'setStatus' | 'resetForm'
  >
) => void {
  const submitForm = useSubmitFormToAPI<FormData, FormData>(
    '/api/admin/document/create/'
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        toast.success('New document has been added successfully.');
        reloadData();
        onClose();
      } else {
        toast.error('Failed to add new document. Please try again.');
      }
    },
    [submitForm, onClose, reloadData]
  );
}

const AddNewDocumentForm: React.FC<AddNewDocumentFormProps> = ({
  onClose,
  reloadData,
}) => {
  const onSubmit = useOnSubmit(onClose, reloadData);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const { data: destinationOptions, isValidating: isFetchingDestinations } =
    useAPI<ReadonlyArray<Option>>('/api/booking/destinations/');

  const categoryOptions: ReadonlyArray<Option<string>> = [
    { value: 'LANGUAGE_GUIDES', label: 'Language Guides' },
    { value: 'SUPPORT_DOCUMENTS', label: 'Support Documents' },
    { value: 'VISA_REQUIREMENTS', label: 'Visa Requirements' },
    { value: 'SPECIAL_DOCUMENTS', label: 'Special Documents' },
  ];

  const sectionOptions: ReadonlyArray<Option<string>> = [
    { value: 'MY_TRIP', label: 'My Trip' },
    { value: 'ALUMNI', label: 'Alumni' },
  ];

  if (isFetchingDestinations) {
    return <LoadingOverlay />;
  }
  return (
    <div>
      <Formik<FormData>
        initialValues={{
          document_id: '',
          section: '',
          category: '',
          name: '',
          description: '',
          destinations: [],
          available_to_all_destinations: false,
        }}
        onSubmit={onSubmit}
      >
        {({ errors, values, isValid, setFieldValue, isSubmitting }) => {
          const handleSuccessfulUpload = (documentId: string) => {
            setFieldValue('document_id', documentId);
            toast.success('You have successfully uploaded a new file.');
            setIsUploadModalOpen(false);
          };

          return (
            <Form>
              <div className="flex flex-col gap-4">
                <h1 className="text-sh2">Add new document</h1>
                <p>File:</p>
                <div>
                  <SecondaryButton
                    label="Upload file"
                    onClick={() => setIsUploadModalOpen(true)}
                  />
                </div>
                <UploadModal
                  isOpen={isUploadModalOpen}
                  closeModal={() => setIsUploadModalOpen(false)}
                  title="Upload file"
                  onSuccessfulUpload={handleSuccessfulUpload}
                  acceptedExtensions={['pdf', 'jpg', 'png', 'webp']}
                />
                <FastField name="section">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<string>;
                    form: FormikProps<FormData>;
                  }) => (
                    <LocalDropdown
                      id="section"
                      label={<FormLabel label="Section" required />}
                      options={sectionOptions}
                      name={field.name}
                      value={
                        field.value
                          ? sectionOptions.find(
                              (option) => option.value === field.value
                            )
                          : null
                      }
                      onSelect={(option: Option<string> | null) => {
                        form.setFieldValue(field.name, option?.value);
                      }}
                      errors={getFormikErrors(form, field.name)}
                    />
                  )}
                </FastField>

                <FastField name="category">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<string>;
                    form: FormikProps<FormData>;
                  }) => (
                    <LocalDropdown
                      id="category"
                      label={<FormLabel label="Category" required />}
                      options={categoryOptions}
                      name={field.name}
                      value={
                        field.value
                          ? categoryOptions.find(
                              (option) => option.value === field.value
                            )
                          : null
                      }
                      onSelect={(option: Option<string> | null) => {
                        form.setFieldValue(field.name, option?.value);
                      }}
                      errors={getFormikErrors(form, field.name)}
                    />
                  )}
                </FastField>
                <Field name="name">
                  {({ field }: { field: FieldInputProps<string> }) => (
                    <div>
                      <Input
                        id="name"
                        label="Name:"
                        name={field.name}
                        defaultValue={field.value}
                        onChange={field.onChange}
                        placeholder="Enter text here"
                      />
                      <ErrorText>{errors.name}</ErrorText>
                    </div>
                  )}
                </Field>
                <Field name="description">
                  {({ field }: { field: FieldInputProps<string> }) => (
                    <div>
                      <Input
                        id="description"
                        label="Description:"
                        name={field.name}
                        defaultValue={field.value}
                        onChange={field.onChange}
                        placeholder="Enter text here"
                      />
                      <ErrorText>{errors.description}</ErrorText>
                    </div>
                  )}
                </Field>
                <h1 className="text-sh4 mt-4">Document Destinations:</h1>
                <p>
                  What these destinations are and how they affect the document.
                </p>
                <Checkbox
                  name="available_to_all_destinations"
                  label=""
                  option={{
                    value: 'available_to_all_destinations',
                    label: 'All destinations',
                  }}
                  checked={values.available_to_all_destinations}
                  onChange={(name, checked) => {
                    setFieldValue(name, checked);
                    setFieldValue('destinations', []);
                  }}
                />
                {destinationOptions?.map((destination) => (
                  <Checkbox
                    key={destination.value}
                    name="destination"
                    label=""
                    option={{
                      value: destination.value,
                      label: destination.label,
                    }}
                    checked={values.destinations.includes(destination.value)}
                    disabled={values.available_to_all_destinations}
                    onChange={(_, checked) => {
                      if (checked) {
                        const newDestinations = [
                          ...values.destinations,
                          destination.value,
                        ];
                        setFieldValue('destinations', newDestinations);
                      } else {
                        const newDestinations = values.destinations.filter(
                          (thisDestination) =>
                            destination.value !== thisDestination
                        );
                        setFieldValue('destinations', newDestinations);
                      }
                    }}
                  />
                ))}
                <div className="flex justify-end gap-4">
                  <PrimaryButton
                    label="Save"
                    type="submit"
                    isDisabled={
                      isSubmitting ||
                      !isValid ||
                      !values.section ||
                      !values.name ||
                      // !values.document ||
                      !values.destinations ||
                      !values.description ||
                      !values.category ||
                      (!values.available_to_all_destinations &&
                        values.destinations.length === 0)
                    }
                  />
                  <SecondaryButton label="Close" onClick={onClose} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddNewDocumentForm;
