import React, { useCallback } from 'react';

import QuickSetDropdown from '../../../quick-set-dropdown/QuickSetDropdown';
import { apiRequest } from '../../../../api/api';
import { EnquiryPriority } from '../profile/types';

interface Props {
  enquiryId: string;
  initialValue?: EnquiryPriority | null;
}

const QuickSetEnquiryPriority: React.FC<Props> = ({
  enquiryId,
  initialValue,
}) => {
  const saveValue = useCallback(
    (selectedValue: string) =>
      new Promise<void>((resolve, reject) => {
        apiRequest(`/api/admin/enquiries/${enquiryId}/set-priority/`, {
          method: 'POST',
          body: JSON.stringify({ priority: selectedValue }),
        })
          .then(() => resolve())
          .catch(() => reject());
      }),
    [enquiryId]
  );

  return (
    <QuickSetDropdown
      initialValue={initialValue}
      options={[
        {
          label: 'High',
          value: 'HIGH',
        },
        {
          label: 'Med',
          value: 'MEDIUM',
        },
        {
          label: 'Low',
          value: 'LOW',
        },
      ]}
      saveValue={saveValue}
      selectedStyling={{
        HIGH: 'bg-wtw-enquiry-priority-high',
        MEDIUM: 'bg-wtw-enquiry-priority-medium',
        LOW: 'bg-wtw-enquiry-priority-low',
        NOT_SET:
          'text-wtw-enquiry-priority-notset border-wtw-enquiry-priority-notset',
      }}
    />
  );
};

export default QuickSetEnquiryPriority;
