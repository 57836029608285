import React, { useRef, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import classNames from 'classnames';

import { CalendarSection, Event, Sections } from './types';
import {
  getDatesInRange,
  sortDatesIntoMonths,
  calculateDaysBetweenDates,
  createUTCDateFromDate,
} from '../../utils/dates';
import Dates from './Dates';
import Section from './Section';
import { splitAndUpdateEvents } from './utils';
import { CELL_WIDTH } from './constants';

interface CalendarProps<T = null, U = null> {
  isEditing: boolean;
  startDate: Date;
  endDate: Date;
  events: ReadonlyArray<Event<T>> | null;
  setEvents?: React.Dispatch<
    React.SetStateAction<ReadonlyArray<Event<T>> | null>
  >;
  sections: Sections<U> | null;
  getEventForDay: (
    section: CalendarSection<U>,
    date: Date,
    events: ReadonlyArray<Event<T>>,
    rowIndex: number
  ) => Event<T> | null;
  onDropOnDay?: (
    dragEvent: DragEndEvent,
    events: ReadonlyArray<Event<T>>,
    setEvents: React.Dispatch<
      React.SetStateAction<ReadonlyArray<Event<T>> | null>
    >,
    checkIsSectionDisabled: (
      event: Event<T>,
      events: ReadonlyArray<Event<T>>,
      section: CalendarSection<U>
    ) => boolean,
    resetSelectedEvent: () => void,
    endOffset?: number
  ) => void;
  checkIsSectionDisabled?: (
    event: Event<T>,
    events: ReadonlyArray<Event<T>>,
    section: CalendarSection<U>
  ) => boolean;
  getOverlayColour?: (event: Event<T>, light: boolean) => string;
  hideEmptyRows?: boolean;
  selectedEvent: Event<T> | null;
  setSelectedEvent: React.Dispatch<React.SetStateAction<Event<T> | null>>;
  splitEndOffset?: number;
}

const Calendar = <T, U>({
  isEditing,
  startDate,
  endDate,
  events,
  setEvents,
  sections,
  getEventForDay,
  onDropOnDay,
  checkIsSectionDisabled,
  getOverlayColour,
  hideEmptyRows,
  selectedEvent,
  setSelectedEvent,
  splitEndOffset = 0,
}: CalendarProps<T, U>) => {
  const dates = getDatesInRange(
    createUTCDateFromDate(startDate),
    createUTCDateFromDate(endDate)
  );
  const datesByMonth = sortDatesIntoMonths(dates);

  const [isDragging, setIsDragging] = useState(false);
  const activeEvent = useRef<Event<T> | null>(null);
  const isInitialDrag = useRef(true);

  const handleDragStart = (dragEvent: DragStartEvent) => {
    if (!isEditing) return;
    setIsDragging(true);
    const event = events?.find((e) => e.id === dragEvent.active.id);
    activeEvent.current = event || null;
  };

  const handleDragEnd = (dragEvent: DragEndEvent) => {
    if (!events || !onDropOnDay || !setEvents || !checkIsSectionDisabled)
      return;
    onDropOnDay(
      dragEvent,
      events,
      setEvents,
      checkIsSectionDisabled,
      () => setSelectedEvent(null),
      splitEndOffset
    );
    setIsDragging(false);
    activeEvent.current = null;
    isInitialDrag.current = true;
  };

  const handleDragOver = (dragEvent: DragOverEvent) => {
    if (isInitialDrag.current && activeEvent.current && events && setEvents) {
      isInitialDrag.current = false;
      splitAndUpdateEvents(
        dragEvent,
        activeEvent.current,
        events,
        setEvents,
        splitEndOffset
      );
    }
  };

  const overlayWidth = activeEvent.current
    ? (calculateDaysBetweenDates(
        activeEvent.current.start_date,
        activeEvent.current.end_date
      ) +
        1) *
      CELL_WIDTH
    : 0;

  const overlayColour =
    getOverlayColour && activeEvent.current
      ? getOverlayColour(activeEvent.current, false)
      : 'bg-blue-300';

  return (
    <DndContext
      autoScroll={{ threshold: { x: 0, y: 0.2 } }}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <div
        className={classNames(
          {
            'overflow-x-auto': !isDragging,
            'overflow-x-hidden': isDragging,
          },
          'text-xs'
        )}
      >
        <Dates datesByMonth={datesByMonth} />
        {sections &&
          events &&
          Object.entries(sections).map(([parentSectionId, parentSection]) => (
            <Section<T, U>
              key={parentSectionId}
              isEditing={isEditing}
              sectionId={parentSectionId}
              title={parentSection.label}
              subsections={parentSection.sections}
              events={events}
              dates={dates}
              getEventForDay={getEventForDay}
              checkIsSectionDisabled={checkIsSectionDisabled}
              activeEvent={activeEvent.current}
              getOverlayColour={getOverlayColour}
              hideEmptyRows={hideEmptyRows}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            />
          ))}
        <DragOverlay>
          {activeEvent.current && (
            <div
              className={classNames(
                'opacity-50 h-5 flex items-center',
                overlayColour
              )}
              style={{
                width: overlayWidth,
              }}
            >
              {activeEvent.current.label}
            </div>
          )}
        </DragOverlay>
      </div>
    </DndContext>
  );
};

export default Calendar;
