import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

import NotePreview from './NotePreview';
import { RoundedSmallButtonPrimary } from '../button/Button';
import TextArea from '../textarea/TextArea';
import { apiRequest, useAPI } from '../../api/api';
import NewNotesButton from './NewNotesButton';
import { Note, NoteFilters, Notes } from '../../components/admin/common/types';
import LoadingOverlay from '../loading/LoadingOverlay';
import ErrorOverlay from '../../components/admin/common/error/ErrorOverlay';
import { LoggedInUserContext } from '../../context-providers/logged-in-user';
import CopyToClipboardButton from '../../components/CopyClipboardButton';
import { NoteEntityType, TagOption } from './types';
import SearchButton from '../search-button/SearchButton';
import { SearchButtonProps, SelectedFilters } from '../search-button/types';
import { LabelValue, Option } from '../../types';
import AdminWidgetContainer from '../card/AdminWidgetContainer';
import LocalDropdown from '../dropdown/LocalDropdown';

type PickedSearchButtonProps = Partial<
  Pick<SearchButtonProps<Note>, 'renderAction'>
>;

const renderNotePreview = (note: Note) => (
  <NotePreview
    key={note.id}
    author={note.author}
    created={note.created}
    content={note.content}
    tag={note.tag}
  />
);

export interface NotesProps extends PickedSearchButtonProps {
  initialEntityId: string;
  initialEntityType: NoteEntityType;
  searchDebounceTime?: number;
  colorOverrideMap?: Record<string, string>;
  additionalEnquiryTypeId?: string;
}

type EndpointFilters = {
  search?: string;
} & SelectedFilters;

export function deleteNote(noteId: string | null, onSuccess: () => void) {
  if (noteId) {
    const confirmedDelete = confirm(
      'Are you sure you want to delete this note.'
    );
    if (confirmedDelete) {
      apiRequest<Response>(`/api/admin/notes/${noteId}/delete/`, {
        method: 'POST',
        body: '',
      })
        .then(() => {
          toast.success('deleted note');
          onSuccess();
        })
        .catch((e) => {
          if (e.body && e.body.detail) {
            toast.error(e.body.detail);
            return;
          }
          toast.error('failed to delete note');
        });
    }
  }
}

const Notes: React.FC<NotesProps> = ({
  initialEntityId,
  initialEntityType,
  renderAction = renderNotePreview,
  colorOverrideMap,
  additionalEnquiryTypeId,
}) => {
  const [entityType, setEntityType] =
    useState<NoteEntityType>(initialEntityType);
  const [entityId, setEntityId] = useState(initialEntityId);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [notes, setNotes] = useState<Note[]>([]);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [selectedTag, setSelectedTag] = useState<TagOption | undefined>(
    undefined
  );
  const [isTemporaryNote, setIsTemporaryNote] = useState<boolean>(false);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    author: {
      label: '',
      value: '',
    },
    tag: {
      label: '',
      value: '',
    },
  });

  const loggedInUser = useContext(LoggedInUserContext);

  const buildApplicantNotesURL = useCallback(
    (filters: EndpointFilters) => {
      const url = new URL(
        `/api/admin/notes/${entityType}/${entityId}/`,
        window.location.origin
      );

      Object.entries(filters).map(([filterName, filterValue]) => {
        if (!filterValue) return;

        if (typeof filterValue === 'string') {
          url.searchParams.set(filterName, filterValue);
        } else {
          url.searchParams.set(filterName, filterValue.value);
        }
      });

      return url.search ? `${url.pathname}${url.search}` : url.pathname;
    },
    [entityType, entityId]
  );

  const {
    data: noteFilters,
    error: noteFiltersError,
    isValidating: noteFiltersIsValidating,
  } = useAPI<NoteFilters>(
    `/api/admin/notes/${entityType}/${entityId}/filters/`
  );

  const {
    data: tags,
    error: tagsError,
    isValidating: tagsIsValidating,
  } = useAPI<ReadonlyArray<LabelValue>>(
    `/api/admin/tags/get-tags-for-category/${entityType}/`
  );

  const getFilteredNotes = useCallback(
    async (filters: SelectedFilters) => {
      const requestURL = buildApplicantNotesURL(filters);

      return apiRequest<Note[]>(requestURL).then(() => {
        setSelectedFilters(filters);
      });
    },
    [buildApplicantNotesURL]
  );

  const getNotes = useCallback(
    async (filters: SelectedFilters) => {
      setIsLoading(true);

      const requestURL = buildApplicantNotesURL(filters);

      return apiRequest<Note[]>(requestURL)
        .then((data) => {
          const firstItemOrNull = data.length > 0 ? data[0] : null;
          mutate(`/api/admin/notes/${entityType}/${entityId}/filters/`);
          setIsTemporaryNote(false);
          setSelectedFilters(filters);
          setSelectedNote(firstItemOrNull);
          setSelectedTag(undefined);
          setNotes(data);
          setIsLoading(false);
        })
        .catch(() => setError('Failed to fetch notes'));
    },
    [buildApplicantNotesURL, entityId, entityType]
  );

  useEffect(() => {
    if (!isInitialized) {
      getNotes(selectedFilters).then(() => {
        setIsInitialized(true);
      });
    }
  }, [getNotes, selectedFilters, isInitialized, entityId, entityType]);

  const renderOverlays = () => {
    if (
      !isInitialized ||
      isLoading ||
      noteFiltersIsValidating ||
      tagsIsValidating
    ) {
      return <LoadingOverlay />;
    }

    if (error || !notes) {
      return <ErrorOverlay detail="Error fetching notes" />;
    }

    if (noteFiltersError || !noteFilters) {
      return <ErrorOverlay detail="Error fetching note filters" />;
    }

    if (tagsError || !tags) {
      return <ErrorOverlay detail="Error fetching tags" />;
    }
  };

  const filterByAuthor = (option: Option<string> | null) => {
    setSelectedNote(null);
    getFilteredNotes({
      ...selectedFilters,
      author: option,
    });
  };

  const filterByTag = (option: Option<string> | null) => {
    setSelectedNote(null);
    getFilteredNotes({
      ...selectedFilters,
      tag: option,
    });
  };

  const filterByType = async (option: Option<string> | null) => {
    if (option) {
      setEntityType(option.label.toLowerCase() as NoteEntityType);
      setEntityId(option.value);
      setIsInitialized(false);
      await getFilteredNotes(selectedFilters);
      setIsInitialized(true);
    }
  };

  const hasNotes = notes && notes.length > 0;

  const onNoteClickHandler = (note: Note) => {
    setSelectedNote(note);
    setIsTemporaryNote(false);
  };

  const renderNotePreviewListing = () => {
    if (!hasNotes) {
      return <p>No notes</p>;
    }

    return notes.map((note) => {
      return (
        <NotePreview
          key={note.id}
          author={note.author}
          created={note.created}
          content={note.content}
          isSelected={selectedNote?.id === note.id}
          onClick={() => onNoteClickHandler(note)}
          tag={note.tag}
          tagColorOverrideMap={colorOverrideMap}
        />
      );
    });
  };

  const createTemporaryNote = () => {
    if (!loggedInUser) return;

    setIsTemporaryNote(true);

    const blankNoteObject = {
      id: '',
      created: new Date().toISOString(),
      author: {
        first_name: loggedInUser.firstName,
        last_name: loggedInUser.lastName,
      },
      content: '',
      tag: undefined,
    };
    setSelectedNote(blankNoteObject);
  };

  const onSearchChange = (query: string) => {
    const requestURL = buildApplicantNotesURL({
      ...selectedFilters,
      search: query,
    });

    return apiRequest<Note[]>(requestURL);
  };

  const handleUpdateNoteTag = () => {
    if (!selectedNote || !selectedTag) return;

    apiRequest(`/api/admin/notes/${selectedNote.id}/add-tag/`, {
      method: 'POST',
      body: JSON.stringify({
        tag: selectedTag.value,
      }),
    })
      .then(() => {
        toast.success('Updated note with tag successfully!');
        getNotes(selectedFilters);
      })
      .catch(() => toast.error('Failed to update note with tag'));
  };

  const handleCreateNewNote = () => {
    if (!selectedNote || selectedNote.content === '') return;

    apiRequest<Note>(`/api/admin/notes/${entityType}/${entityId}/add/`, {
      method: 'POST',
      body: JSON.stringify({
        content: selectedNote.content,
        tag: selectedTag?.value,
      }),
    })
      .then(() => {
        toast.success('Note saved successfully');
        getNotes({
          author: {
            label: '',
            value: '',
          },
          tag: { label: '', value: '' },
        });
        setSelectedTag(undefined);
      })
      .catch(() => toast.error('Failed to save note'));
  };

  const saveNewNote = () => {
    if (!selectedNote) return;

    if (!isTemporaryNote) {
      handleUpdateNoteTag();
      return;
    }

    handleCreateNewNote();
  };

  const renderNoteTagOptions = () => {
    if (!tags || !tags.length) return null;

    return (
      <>
        {Object.entries(tags).map(([key, tagOptions]) => {
          return (
            <option value={tagOptions.value} key={`note-tag-option-${key}`}>
              {tagOptions.label}
            </option>
          );
        })}
      </>
    );
  };

  return (
    <AdminWidgetContainer className="space-y-3 border p-6 pb-9 items-stretch w-full relative rounded-lg">
      {renderOverlays()}
      <div className="flex flex-wrap justify-between items-end">
        <div className="w-1/5">
          <h1 className="text-sh2">Notes:</h1>
        </div>
        <div className="w-4/5 flex flex-wrap justify-end relative space-x-1.5 items-center">
          {additionalEnquiryTypeId && (
            <div className="flex items-center">
              <p className="mr-1">Type: </p>
              <LocalDropdown
                options={[
                  { label: 'Booking', value: initialEntityId },
                  {
                    label: 'Enquiry',
                    value: additionalEnquiryTypeId,
                  },
                ]}
                onSelect={async (option: Option<string> | null) => {
                  if (option) {
                    setEntityType(option.label.toLowerCase() as NoteEntityType);
                    setEntityId(option.value);
                    setIsInitialized(false);
                  }
                }}
                name="type"
                value={{
                  label:
                    String(entityType).charAt(0).toLocaleUpperCase() +
                    String(entityType).slice(1),
                  value: entityId,
                }}
              />
            </div>
          )}
          <NewNotesButton onClick={createTemporaryNote} />
          <SearchButton
            onSearch={onSearchChange}
            renderAction={renderAction}
            onSelectItem={onNoteClickHandler}
            setSelectedNote={setSelectedNote}
            setSelectedFilters={setSelectedFilters}
            getNotes={getNotes}
            filters={
              additionalEnquiryTypeId
                ? [
                    {
                      label: 'Added by',
                      name: 'author',
                      options:
                        noteFilters?.added_by.map((addedBy) => ({
                          label: addedBy.label,
                          value: addedBy.value,
                        })) || [],
                      value: selectedFilters.author,
                      onSelect: filterByAuthor,
                    },
                    {
                      label: 'Type',
                      name: 'type',
                      options: [
                        { label: 'Booking', value: initialEntityId },
                        {
                          label: 'Enquiry',
                          value: additionalEnquiryTypeId,
                        },
                      ],
                      value: {
                        label:
                          String(entityType).charAt(0).toLocaleUpperCase() +
                          String(entityType).slice(1),
                        value: entityId,
                      },
                      onSelect: filterByType,
                    },
                    {
                      label: 'Tag',
                      name: 'tag',
                      options: noteFilters?.tags
                        ? noteFilters.tags.map((tag) => ({
                            label: tag.label,
                            value: tag.value,
                          }))
                        : [],
                      value: selectedFilters.tag,
                      onSelect: filterByTag,
                    },
                  ]
                : [
                    {
                      label: 'Added by',
                      name: 'author',
                      options:
                        noteFilters?.added_by.map((addedBy) => ({
                          label: addedBy.label,
                          value: addedBy.value,
                        })) || [],
                      value: selectedFilters.author,
                      onSelect: filterByAuthor,
                    },
                    {
                      label: 'Tag',
                      name: 'tag',
                      options: noteFilters?.tags
                        ? noteFilters.tags.map((tag) => ({
                            label: tag.label,
                            value: tag.value,
                          }))
                        : [],
                      value: selectedFilters.tag,
                      onSelect: filterByTag,
                    },
                  ]
            }
          />
        </div>
      </div>
      <div className="flex flex-wrap max-h-[485px] h-screen mt-0 max-w-[600px]">
        <div
          className={classNames(
            'w-2/5-minus-24px pr-6 overflow-y-auto h-full',
            {
              'flex justify-center items-center': !hasNotes,
            }
          )}
        >
          {renderNotePreviewListing()}
        </div>
        <div className="w-3/5 h-full flex flex-1 flex-col rounded-lg">
          <TextArea
            containerClassName="note-textarea"
            name="note"
            placeholder="Type your note here"
            onChange={(e) => {
              if (!selectedNote || !isTemporaryNote) return;

              setSelectedNote({
                ...selectedNote,
                content: e.target.value,
              });
            }}
            disabled={!isTemporaryNote}
            value={selectedNote?.content || ''}
          />

          <div className="flex flex-wrap flex-1 p-2.5 border border-wtw-gray-1 justify-end text-sm h-full items-center space-x-4 bg-wtw-gray-1">
            <select
              value={selectedTag?.value || ''}
              className="rounded-3xl text-xs leading-none py-0.5 px-2 m-0 h-[28px] max-w-[100px] w-full text-wtw-logo-blue border-wtw-logo-blue"
              onChange={(e) =>
                setSelectedTag(
                  tags?.find((tag) => tag.value === e.target.value)
                )
              }
            >
              <option value="">Add Tag</option>
              {renderNoteTagOptions()}
            </select>
            <select className="rounded-3xl text-xs leading-none py-0.5 px-2 m-0 h-[28px] max-w-[100px] w-full text-wtw-logo-blue border-wtw-logo-blue">
              <option value="">Templates</option>
            </select>
            <CopyToClipboardButton
              textToCopy={selectedNote?.content || ''}
              className="text-wtw-logo-blue"
            />
            {loggedInUser?.permissionsGranted &&
              loggedInUser.permissionsGranted.includes('Note.DELETE_NOTES') && (
                <button
                  aria-label="Delete note"
                  className="text-wtw-logo-blue"
                  onClick={() =>
                    deleteNote(selectedNote ? selectedNote.id : null, () =>
                      getNotes(selectedFilters)
                    )
                  }
                >
                  <FontAwesomeIcon icon={faTrashAlt} width={22} height={22} />
                </button>
              )}
          </div>

          <div className="flex justify-end pt-6">
            <RoundedSmallButtonPrimary
              minHeightClassName="min-h-[34px]"
              onClick={saveNewNote}
            >
              Save note
            </RoundedSmallButtonPrimary>
          </div>
        </div>
      </div>
    </AdminWidgetContainer>
  );
};

export default Notes;
