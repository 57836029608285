import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToBracket } from '@fortawesome/pro-light-svg-icons';

import { useAPI } from '../../../api/api';
import { MyBookingsContext } from '../context-providers';
import MyTripStandardPage from '../layout/MyTripStandardPage';
import WidgetContainer from '../components/WidgetContainer';
import { Download, DownloadsData } from './types';
import { setDocumentAsDownloaded } from './actions';

interface DownloadDocumentProps {
  bookingId: string | undefined;
  download: Download;
}

const DownloadDocument: React.FC<DownloadDocumentProps> = ({
  bookingId,
  download,
}) => {
  return (
    <div className="mt-3">
      <a
        onClick={() =>
          bookingId ? setDocumentAsDownloaded(bookingId, download.id) : {}
        }
        href={download.download_link}
        className="text-sh4 mb-3 text-wtw-logo-blue hover:text-wtw-dark-blue flex items-center gap-1"
      >
        <FontAwesomeIcon className="mr-2" icon={faArrowDownToBracket} />
        {download.name}
      </a>
      <p className="mb-4">{download.description}</p>
    </div>
  );
};

const Downloads: React.FC = () => {
  const { activeBooking } = useContext(MyBookingsContext);
  const { data, isValidating, error } = useAPI<DownloadsData>(
    activeBooking ? `/api/my-trip/downloads/${activeBooking.id}/list/` : null
  );
  return (
    <div className="relative">
      <MyTripStandardPage
        title="Downloads"
        isLoading={isValidating}
        error={error && 'Error loading downloads'}
        showDashLink
      >
        <div className="space-y-4">
          <h2 className="text-sh2">About downloads</h2>
          <p className="lg:pr-64 md:pr-32 sm:pr-16">
            The information in these documents may be required by your
            university to approve the placement. If you require any further
            details that are not available here or in the{' '}
            <a href="/my-trip/info-pack/" className="underline">
              information pack
            </a>
            , please get in touch (using the link at the top of this page) and
            we'll be happy to help.
          </p>
          <div className="flex flex-col lg:flex-row pt-10 gap-4">
            {data &&
              Object.entries(data).map(([category, downloads]) => (
                <WidgetContainer
                  key={category}
                  hasBorder
                  hasShadow
                  className="h-full lg:w-1/2 mb-10"
                >
                  <div className="mx-5">
                    <h1 className="text-sh3 font-bold">{category}</h1>
                    {downloads.map((download) => (
                      <DownloadDocument
                        bookingId={activeBooking?.id}
                        download={download}
                        key={download.name}
                      />
                    ))}
                  </div>
                </WidgetContainer>
              ))}
          </div>
        </div>
      </MyTripStandardPage>
    </div>
  );
};

export default Downloads;
