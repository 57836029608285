import React from 'react';

import InstantSaveCheckbox from '../instant-save-checkbox/InstantSaveCheckbox';

const InstantSaveCheckboxExample: React.FC = () => {
  return (
    <div className="space-y-4 my-8">
      <h1 className="text-2xl font-bold">Instant Save Checkbox</h1>
      <InstantSaveCheckbox
        name="some-name"
        title="Some Title"
        description="Some really long description that goes here"
        onClick={() => new Promise((resolve) => setTimeout(resolve, 2000))}
      />
    </div>
  );
};

export default InstantSaveCheckboxExample;
