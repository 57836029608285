import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { mutate } from 'swr';

import { PrimaryButton } from '../../../../framework-components/button/Button';
import { setEnquiryContacted } from './actions';
import { BasicInfo } from './types';

interface Props {
  enquiryId: string;
  contactedAt: string | null;
}

const Contacted: React.FC<Props> = ({ enquiryId, contactedAt }) => {
  const [isValidating, setIsValidating] = React.useState(false);

  const handleSetContacted = () => {
    try {
      setIsValidating(true);
      setEnquiryContacted(enquiryId);
      toast.success('Enquiry marked as contacted successfully');
      mutate(
        `/api/admin/enquiries/${enquiryId}/basic-info/`,
        (data: BasicInfo) => ({
          ...data,
          contacted_at: new Date().toISOString(),
        }),
        false
      );
    } catch {
      toast.error('Failed to mark enquiry as contacted');
    } finally {
      setIsValidating(false);
    }
  };

  const renderButtonContent = () => {
    return contactedAt ? (
      <div className="flex gap-2 items-center">
        Contacted <FontAwesomeIcon icon={faCheck} />
      </div>
    ) : (
      'Mark as contacted'
    );
  };

  return (
    <PrimaryButton
      onClick={handleSetContacted}
      isDisabled={isValidating || Boolean(contactedAt)}
      isLoading={isValidating}
    >
      {renderButtonContent()}
    </PrimaryButton>
  );
};

export default Contacted;
